import { useTranslation } from 'react-i18next'
import { Button, DotsHorizontal, Menu, MenuItem, ModalPresetConfirm, Popper, useModal } from '@dashboard/ui'
import {
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { ItemRarityRead } from '@/api/dashboard'
import { useQuery } from '@tanstack/react-query'
import { Edit03, Plus, Trash } from '@/icons'
import { rarityQuery } from '@/layouts/sku-settings/api/rarityQuery'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useGenerateCompanyPath } from '@/libs'
import { EditItemRarityModal } from '@/layouts/sku-settings/widgets/EditItemRarityModal'
import { useItemRarityDeleteMutate } from '@/layouts/sku-settings/api/useItemRarityDeleteMutate'

export default function RarityTable() {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { canEdit } = useCurrentUser()
  const { page, query, onPageChange, needShowPagination } = usePagination(10)
  const { data: items = [], isLoading } = useQuery({
    ...rarityQuery(companyId, gameId, query),
  })
  const { mutateAsync: deleteMutateAsync } = useItemRarityDeleteMutate(companyId, gameId)
  const { t } = useTranslation('lootboxes')
  const { t: tMain } = useTranslation()

  const openDeleteModal = useModal<{ item: ItemRarityRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: tMain('confirm.title'),
        subtitle: tMain('confirm.text', { name: props.item.name }),
      }}
      cancel={{
        label: tMain('Cancel'),
      }}
      submit={{
        callback: () => {
          onDeleteGameItemClick(props.item)
          props.onClose?.()
        },
        color: 'danger',
        label: tMain('remove'),
      }}
    />
  ))

  const openEditModal = useModal<{ item: ItemRarityRead }>(props => <EditItemRarityModal {...props} />)

  const onDeleteGameItemClick = async (item: ItemRarityRead) => {
    await deleteMutateAsync({ id: item.id })
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="200%">{t('item.rarity.name')}</TableCell>
        <TableCell width="50%">{t('item.rarity.color_background_badge')}</TableCell>
        <TableCell width="20%" />
      </TableRow>
    )
  }

  const onCreateClick = () => {
    openEditModal({
      item: {
        id: '',
        name: '',
        image_url: '',
      } as ItemRarityRead,
    })
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit &&
          (!!items.length || page > 1) && (
            <Button size="sm" onClick={onCreateClick}>
              {t('item.rarity.create')}
            </Button>
          )
        }
      >
        {tMain('sidebar.item-rarity')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : items.length === 0 && page == 1 ? (
        <TableZeroState
          title={t('item.rarity.empty-table.title')}
          message={t('item.rarity.empty-table')}
          buttons={
            canEdit && (
              <Button size="sm" onClick={onCreateClick}>
                <Plus size={16} />
                <span>{t('item.rarity.create')}</span>
              </Button>
            )
          }
        />
      ) : (
        <>
          <Table>
            {renderDefaultHeader()}
            {items.map(it => (
              <TableRow key={it.id}>
                <TableCell width="200%">{it.name}</TableCell>
                <TableCell width="50%" className="flex items-center gap-3">
                  <div
                    className="size-[18px] rounded-sm border"
                    style={{
                      backgroundColor: it.color_background_badge,
                      borderColor: 'rgba(0, 0, 0, 0.2)',
                    }}
                  />
                  {it.color_background_badge}
                </TableCell>

                <TableCell width="20%">
                  {canEdit && (
                    <Popper
                      popper={props => (
                        <Menu {...props}>
                          <MenuItem
                            icon={Edit03}
                            onClick={() => {
                              openEditModal({ item: it })
                              props.onClose()
                            }}
                          >
                            {tMain('edit')}
                          </MenuItem>
                          <MenuItem
                            icon={Trash}
                            variant="danger"
                            onClick={() => {
                              openDeleteModal({ item: it })
                              props.onClose()
                            }}
                          >
                            {tMain('delete')}
                          </MenuItem>
                        </Menu>
                      )}
                    >
                      <Button variant="tertiary" color="secondary">
                        <DotsHorizontal />
                      </Button>
                    </Popper>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {needShowPagination(isLoading, items) && (
            <Pagination
              hasNext={items.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={items.length}
            />
          )}
        </>
      )}
    </div>
  )
}
