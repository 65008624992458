import { useParams } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { ResponseError, dashboardClient, getErrorText } from '@/api'
import dayjs from 'dayjs'
import { useState } from 'react'
import {
  Button,
  FormErrorMessage,
  InputDate,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { FieldGroup } from '@/ui'
import { InfoCircleIcon } from '../../../icons/Icons'

export interface GenerateReportDialogProps extends ModalProps {}

const GenerateReportDialog = (props: GenerateReportDialogProps) => {
  const { ...rest } = props
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const theme = useTheme()
  const [from, setFrom] = useState<Date>(dayjs(new Date()).add(-1, 'month').toDate())
  const [to, setTo] = useState<Date>(new Date())
  const [step, setStep] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const okClick = async () => {
    try {
      if (from.getTime() > to.getTime()) {
        setErrorMessage(t('validation.start_date'))
        return
      }

      await dashboardClient.v1.exportTransactions(companyId, gameId, {
        start: dayjs(from).format('YYYY-MM-DD'),
        end: dayjs(to).format('YYYY-MM-DD'),
      })
      setStep(1)
    } catch (e) {
      let b = e as ResponseError
      if (b.status == 429) {
        setErrorMessage(t('generate-transaction.error.429'))
      } else {
        setErrorMessage(getErrorText(b))
      }
      setStep(1)
    }
  }

  const renderStep0 = () => {
    return (
      <div className="flex flex-col">
        <div className="flex gap-4">
          <FieldGroup label={t('transactions-table.generate-report.from')}>
            <InputDate hideClearButton={true} value={from} onChange={value => setFrom(value!)} />
          </FieldGroup>

          <FieldGroup label={t('transactions-table.generate-report.to')}>
            <InputDate hideClearButton={true} value={to} onChange={value => setTo(value!)} />
          </FieldGroup>
        </div>
        {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        <div className="flex items-start justify-start gap-2 text-text-secondary">
          <div>
            <InfoCircleIcon />
          </div>
          <div className="text-caption-md font-normal">{t('generate-transaction.desc')}</div>
        </div>
      </div>
    )
  }

  const renderStep1 = () => {
    let color = errorMessage ? theme.palette.warning.dark : theme.palette.primary.main

    return (
      <div className="flex w-full flex-col items-center justify-center gap-5 text-center">
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path
              d="M6 23C6 13.6112 13.6112 6 23 6C32.3888 6 40 13.6112 40 23C40 32.3888 32.3888 40 23 40C13.6112 40 6 32.3888 6 23Z"
              stroke={color}
              strokeWidth="2"
            />
          </g>
          <g opacity="0.1">
            <path
              d="M1 23C1 10.8497 10.8497 1 23 1C35.1503 1 45 10.8497 45 23C45 35.1503 35.1503 45 23 45C10.8497 45 1 35.1503 1 23Z"
              stroke={color}
              strokeWidth="2"
            />
          </g>
          <path
            d="M22.9999 18.3333V22.9999M22.9999 27.6666H23.0116M34.6666 22.9999C34.6666 29.4432 29.4432 34.6666 22.9999 34.6666C16.5566 34.6666 11.3333 29.4432 11.3333 22.9999C11.3333 16.5566 16.5566 11.3333 22.9999 11.3333C29.4432 11.3333 34.6666 16.5566 34.6666 22.9999Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div>
          {errorMessage ? (
            <>
              <div className="text-caption-lg font-semibold">{t('generate-transaction.error.429')}</div>
              <div className="mt-2 text-caption-lg font-normal">{t('generate-transaction.error.429.desc')}</div>
            </>
          ) : (
            <>
              <div className="text-caption-lg font-semibold">{t('generate-transaction.generating-report')}</div>
              <div className="mt-2 text-caption-lg font-normal">{t('generate-transaction.check-email')}</div>
            </>
          )}
        </div>
      </div>
    )
  }

  return (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle>{t('generate-transaction-registry')}</ModalTitle>

        {step == 0 && renderStep0()}
        {step == 1 && renderStep1()}
      </ModalContent>
      {step == 0 && (
        <ModalFooter>
          <Button onClick={() => props.onClose?.()} size="sm" variant="outline" color="secondary">
            {t('Cancel')}
          </Button>
          <Button onClick={okClick} size="sm">
            {t('transactions-table.generate-report')}
          </Button>
        </ModalFooter>
      )}
      {step == 1 && (
        <ModalFooter>
          <Button onClick={() => props.onClose?.()} size="sm">
            {t('Close')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default GenerateReportDialog
