import { useMutation, useQueryClient } from '@tanstack/react-query'
import { LootboxUpdate } from '@/api/dashboard'
import { dashboardClient } from '@/api'

interface LootboxUpdateMutate {
  data: LootboxUpdate
  id: string
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useLootboxUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data, id }: LootboxUpdateMutate) => dashboardClient.v1.updateLootbox(companyId, gameId, id, data),
    onSuccess: async _ => {
      await queryClient.refetchQueries({ queryKey: ['lootbox-item', gameId], exact: false, stale: false })
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false, stale: false })
    },
  })
}
