import { ButtonIcon, FieldGroup, Header, ImageUploader } from '@/ui'

import { Controller, useForm } from 'react-hook-form'
import { RangeOfferRead } from '@/api/dashboard'
import { FieldSection } from 'src/components/ui/FieldSection'
import { ErrorMessage } from '@hookform/error-message'
import { SelectDateTime } from '@/components/ui/SelectDateTime'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormErrorMessage,
  Input,
  InputColor,
  Select,
  Textarea,
  formatHookFormErrors,
  isHexColor,
} from '@dashboard/ui'
import { CloseOutline } from '@/icons'
import Divider from '@/components/Divider'
import { MAX_DESCRIPTION_LENGTH, MAX_NAME_LENGTH } from '@/Settings'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { validateInterval } from '@/layouts/components/ItemTimelimit'
import { DollarRounded } from '@/layouts/cashback-rewards/icons'

interface GeneralSettingsProps {
  onClose: () => void
  onApply: (data: RangeOfferRead) => void
  offer: RangeOfferRead
}

export const GeneralSettings = (props: GeneralSettingsProps) => {
  const { t } = useTranslation('offers')
  const { t: tMain } = useTranslation()

  const {
    register,
    formState: { errors, isSubmitting, isDirty },
    setError,
    clearErrors,
    control,
    handleSubmit,
  } = useForm<RangeOfferRead>({
    values: {
      ...props.offer,
    },
  })

  const onSubmit = (data: RangeOfferRead) => {
    if (data.end_at && !props.offer.id && data.end_at < new Date().getTime() / 1000) {
      setError('end_at', { message: tMain('validation.end_at.error') })
      return
    }
    clearErrors('start_at')

    const err = validateInterval(data.start_at, data.end_at, tMain)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    props.onClose()
    props.onApply(data)
  }

  const renderGeneral = () => {
    return (
      <FieldSection label={t('offer-details')} caption={t('range.desc')}>
        <FieldGroup label={t('name')}>
          <Input
            {...register('name', {
              required: tMain('validation.required'),
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('name'),
                  value: MAX_NAME_LENGTH,
                }),
                value: MAX_NAME_LENGTH,
              },
              setValueAs: v => v?.trim(),
            })}
            autoFocus
            {...formatHookFormErrors(errors, 'name')}
          />
        </FieldGroup>
        <FieldGroup label={t('description')}>
          <Textarea
            rows={2}
            {...register('description', {
              required: tMain('validation.required'),
              maxLength: {
                message: tMain('validation.maxLength', {
                  field: t('description'),
                  value: MAX_DESCRIPTION_LENGTH,
                }),
                value: MAX_DESCRIPTION_LENGTH,
              },
              setValueAs: v => v?.trim(),
            })}
            {...formatHookFormErrors(errors, 'description')}
          />
        </FieldGroup>

        <FieldGroup label={t('background_image_url')}>
          <Controller
            control={control}
            name="settings.background_image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="settings.background_image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('background_image.size')}>
          <Controller
            control={control}
            name="settings.background_size"
            render={({ field }) => (
              <Select
                {...field}
                {...formatHookFormErrors(errors, 'settings.background_size')}
                items={[
                  {
                    value: 'cover',
                    children: t('background_image.size.cover'),
                  },
                  {
                    value: 'repeat',
                    children: t('background_image.size.repeat'),
                  },
                ]}
              />
            )}
          />
          <ErrorMessage
            name="settings.background_size"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('color-of-range-offer-list')}>
          <Controller
            name="settings.background_tier_list_color"
            control={control}
            rules={{
              validate: v => {
                if (!v) {
                  return true
                }

                if (!isHexColor(v)) {
                  return t('validation.invalid_hex_color')
                }

                return true
              },
            }}
            render={({ field }) => (
              <InputColor {...field} {...formatHookFormErrors(errors, 'settings.background_tier_list_color')} />
            )}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <FieldSection label={t('time-limitations')} caption={t('time-limitations.desc')}>
        <FieldGroup label={tMain('datetime.start_at')}>
          <Controller control={control} name="start_at" render={({ field }) => <SelectDateTime {...field} />} />
          <ErrorMessage
            name="start_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
        <FieldGroup label={tMain('datetime.end_at')}>
          <Controller control={control} name="end_at" render={({ field }) => <SelectDateTime {...field} />} />
          <ErrorMessage
            name="end_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderItem = () => {
    return (
      <FieldSection label={t('linked-item')} caption={t('range.linked-sku.desc')}>
        <FieldGroup label={tMain('select-sku')}>
          <Controller
            control={control}
            name="item_id"
            rules={{ required: tMain('validation.required') }}
            render={({ field }) => (
              <SelectSkuItemV2
                isStackable={true}
                hideQuantity={true}
                hidePrice={true}
                value={field.value}
                onChange={v => {
                  field.onChange(v)
                }}
              />
            )}
          />
          <ErrorMessage
            name="item_id"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderRate = () => {
    return (
      <FieldSection label={t('range.rate')} caption={t('range.rate.desc')}>
        <div className="flex flex-col gap-3 rounded-md bg-fg-secondary p-3">
          <div className="grid grid-cols-2 gap-4 ">
            <div>
              <FieldGroup label={'USD'}>
                <Input disabled extraLeft={{ icon: () => <DollarRounded /> }} value={0.01} />
              </FieldGroup>
            </div>
            <div>
              <FieldGroup label={t('range.rate.quantity_for_one_cent')}>
                <Controller
                  control={control}
                  name="settings.quantity_for_one_cent"
                  rules={{
                    required: tMain('validation.required'),
                    min: {
                      value: 1,
                      message: tMain('validation.min', {
                        field: t('form.lp.points_rate'),
                        value: 1,
                      }),
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="number"
                      onChange={e => {
                        let num = parseInt(e.target.value)
                        field.onChange(e.target.value == '' || isNaN(num) ? null : num)
                      }}
                      value={field.value}
                    />
                  )}
                />
              </FieldGroup>
            </div>
          </div>
          <ErrorMessage
            name="settings.quantity_for_one_cent"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </div>
      </FieldSection>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex h-full flex-col bg-fg-primary">
        <Header title={t('general-settings')} className="sticky top-0 z-50 p-4" minWidth="auto">
          <div className="flex items-center gap-4">
            <Button disabled={!isDirty} loading={isSubmitting} type="submit">
              {tMain('apply-changes')}
            </Button>
            <ButtonIcon onClick={props.onClose} type="button" size="xs" variant="secondary-gray">
              <CloseOutline />
            </ButtonIcon>
          </div>
        </Header>

        <div className="flex size-full flex-col p-4">
          {renderGeneral()}
          <Divider />

          {renderItem()}
          <Divider />

          {renderRate()}
          <Divider />

          {renderTimeLimitations()}
        </div>
      </div>
    </form>
  )
}
