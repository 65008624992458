import { Toggle, cn, getMotionProps } from '@dashboard/ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

interface TogglePropProps {
  label: string
  initialValue: boolean
  onChange: (value: boolean) => void
  children?: React.ReactNode
}

const entryMotionProps = getMotionProps({
  initial: { paddingTop: 0, height: 0, opacity: 0 },
  animate: { paddingTop: 8, height: 'auto', opacity: 1 },
  exit: { paddingTop: 0, height: 0, opacity: 0 },
})

export const ToggleProp = ({ label, onChange, initialValue, children }: TogglePropProps) => {
  const [isOpened, setIsOpened] = useState(initialValue)

  return (
    <div className="border-b border-border-secondary">
      <div className="inline-flex w-full items-center justify-between py-3">
        <span className={cn('text-caption-md  text-text-secondary', isOpened ? 'font-bold' : 'font-medium')}>
          {label}
        </span>
        <Toggle
          size="sm"
          checked={isOpened}
          onChange={e => {
            onChange(e.target.checked)
            setIsOpened(e.target.checked)
          }}
        />
      </div>
      {children && (
        <AnimatePresence>{isOpened && <motion.div {...entryMotionProps}>{children}</motion.div>}</AnimatePresence>
      )}
    </div>
  )
}
