import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { postLogin } from '../api/postLogin'
import { getUrlParams, useAuthToken } from '@/auth/useAuthToken'
import ErrorBlock from '@/layouts/errors/components/ErrorBlock'
import ThemeLangOutlet from '@/auth/ThemeLangOutlet'

const Callback = () => {
  const navigate = useNavigate()
  const { authDone, isLoading, user, targetUrl } = useAuthToken()
  const [error, setError] = useState<string | null>(null)

  const load = async () => {
    postLogin(user?.name, user?.picture)

    navigate(targetUrl || '/')
  }

  useEffect(() => {
    const params = getUrlParams()

    if (params['error'] || params['error_description']) {
      setError(params['error_description'] || params['error'])
      return
    }
    if (authDone) {
      load()
    }
  }, [authDone])

  if (error) {
    return (
      <ThemeLangOutlet>
        <ErrorBlock status={'Oops'} title={'Error'} extraText={error} />
      </ThemeLangOutlet>
    )
  }

  if (isLoading) {
    return (
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  return <></>
}

export default Callback
