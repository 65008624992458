import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const RefreshIcon2: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22775 2.31537C8.53691 2.0062 9.03817 2.0062 9.34733 2.31537L11.4848 4.45287C11.794 4.76203 11.794 5.26329 11.4848 5.57246L9.34733 7.70996C9.03817 8.01912 8.53691 8.01912 8.22775 7.70996C7.91858 7.40079 7.91858 6.89953 8.22775 6.59037L9.01379 5.80433H7.36254C5.04525 5.80433 3.16671 7.68287 3.16671 10.0002C3.16671 11.6727 4.14534 13.1183 5.56474 13.7926C5.95967 13.9802 6.12773 14.4525 5.94012 14.8474C5.75251 15.2423 5.28027 15.4104 4.88534 15.2228C2.93469 14.2961 1.58337 12.3067 1.58337 10.0002C1.58337 6.80842 4.1708 4.221 7.36254 4.221H9.01379L8.22775 3.43496C7.91858 3.12579 7.91858 2.62453 8.22775 2.31537ZM13.06 5.15293C13.2476 4.758 13.7198 4.58993 14.1147 4.77754C16.0654 5.7042 17.4167 7.69358 17.4167 10.0002C17.4167 13.1919 14.8293 15.7793 11.6375 15.7793H9.98629L10.7723 16.5654C11.0815 16.8745 11.0815 17.3758 10.7723 17.685C10.4632 17.9941 9.96191 17.9941 9.65275 17.685L7.51525 15.5475C7.20608 15.2383 7.20608 14.737 7.51525 14.4279L9.65275 12.2904C9.96191 11.9812 10.4632 11.9812 10.7723 12.2904C11.0815 12.5995 11.0815 13.1008 10.7723 13.41L9.98629 14.196H11.6375C13.9548 14.196 15.8334 12.3175 15.8334 10.0002C15.8334 8.32758 14.8547 6.88199 13.4353 6.20771C13.0404 6.0201 12.8723 5.54786 13.06 5.15293Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

RefreshIcon2.type = 'aghanim-icon';
