import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { SequentialOfferUpdate } from '@/api/dashboard'

interface SequentialOfferMutate {
  id: string
  update: SequentialOfferUpdate
}

export const useSequentialOfferUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: SequentialOfferMutate) =>
      dashboardClient.v1.updateSequentialOffer(companyId, gameId, id, update),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['sequential_offer', gameId, variables.id], data.data)
      queryClient.invalidateQueries({ queryKey: ['sequential_offers', gameId], exact: false })
    },
  })
}
