import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { SequentialOfferCreate } from '@/api/dashboard'

interface SequentialOfferCreateMutate {
  create: SequentialOfferCreate
}

export const useSequentialOfferCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: SequentialOfferCreateMutate) =>
      dashboardClient.v1.createSequentialOffer(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['sequential_offer', gameId, data.data.id], data.data)
      queryClient.invalidateQueries({ queryKey: ['sequential_offers', gameId], exact: false })
    },
  })
}
