import { Box } from '@mui/material'
import { Position } from 'reactflow'

import { useTheme } from '@mui/material/styles'
import { DescBlock, IOHandle, getNodeHoverStyle, hasNextNode } from './common'
import { getNodeColor, getNodeIcon } from '../icons'
import { NextNode } from '../components/NextNode/NextNode'
import { IBlockProps } from '../types'

export default function StoreUserNode(props: IBlockProps) {
  const theme = useTheme()
  const nodeColor = getNodeColor(props.data.node.model_type)
  return (
    <>
      <IOHandle color={theme.palette.purple['A100']} position={Position.Top} type="target" id="input" />
      <Box
        sx={{
          border: `2px solid ${nodeColor}`,
          cursor: 'pointer',
          backgroundColor: theme.palette.background.content,
          position: 'relative',
          padding: theme.spacing(1.75),
          borderRadius: theme.spacing(0.75),
          boxShadow: props.selected ? `1px 4px 22px 0px ${nodeColor}` : '',
          '&:hover': {
            boxShadow: `1px 4px 22px 0px ${nodeColor}`,
          },
          ...getNodeHoverStyle(nodeColor),
        }}
        width={theme.spacing(8.5)}
        height={theme.spacing(8.5)}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        {getNodeIcon(props.data.node.model_type)}
        <DescBlock node={props.data.node} width={'224px'} selected={props.selected} />
      </Box>
      <NextNode {...props} />
      <IOHandle
        color={theme.palette.purple['A100']}
        hasNext={hasNextNode(props.data.graph, props.data.node)}
        position={Position.Bottom}
        type="source"
        id="output"
      />
    </>
  )
}
