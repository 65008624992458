import { Controller, useFormContext } from 'react-hook-form'
import { Block, CarouselBlockType } from '@/api/dashboard'
import { FieldGroup } from '@/ui'

import { useTranslation } from 'react-i18next'
import { Checkbox, Input, Select, formatHookFormErrors } from '@dashboard/ui'

const CarouselBlockEditor = () => {
  const { t } = useTranslation()

  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext<Block>()
  const autoplay = watch('autoplay')
  return (
    <div>
      <FieldGroup label={t('hub-editor.carousel.type')}>
        <Controller
          control={control}
          name="type"
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <Select
              {...field}
              {...formatHookFormErrors(errors, 'type')}
              placeholder={t('hub-editor.carousel.type-placeholder')}
              items={[{ children: t('hub-editor.carousel.type.offers'), value: CarouselBlockType.Offers }]}
            />
          )}
        />
      </FieldGroup>
      <Checkbox className="mt-2" {...register('autoplay')}>
        {t('hub-editor.carousel.autoplay')}
      </Checkbox>
      {autoplay && (
        <FieldGroup className="mt-2" label={t('hub-editor.carousel.delay')}>
          <Input
            defaultValue={5000}
            type="number"
            {...register('delay', {
              min: { value: 1000, message: t('validation.min-no-field', { value: 1000 }) },
              valueAsNumber: true,
              required: t('validation.required'),
              setValueAs: value => value || undefined,
            })}
            {...formatHookFormErrors(errors, 'delay')}
          />
        </FieldGroup>
      )}

      <Checkbox className="mt-2" {...register('loop')}>
        {t('hub-editor.carousel.loop')}
      </Checkbox>
      <Checkbox className="mt-2" {...register('show_dots')}>
        {t('hub-editor.carousel.show_dots')}
      </Checkbox>
    </div>
  )
}

export default CarouselBlockEditor
