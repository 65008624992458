import i18next from 'i18next'

import { MobilePopupActionNode, MobilePushActionNode, Node } from '../../../api/dashboard'
import { BlockError } from '../types'
import { InputVars } from '../components/InputVars/InputVars'
import { TEMPLATE_PROPS, findPrevEntityNode } from '../util'
import { EMPTY_VALUE } from '../expr/types'
import styled from '@emotion/styled'
import SelectMessageContentType from '../components/SelectMessageContentType/SelectMessageContentType'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'
import { useContext, useMemo } from 'react'
import { CampaignContext, ICampaignContext } from '../Context'

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const MobileActionEditor = (props: {
  error?: BlockError | null
  node: MobilePushActionNode | MobilePopupActionNode
  setNode: (node: Node) => void
}) => {
  let typeItem = EMPTY_VALUE
  if (props.node.item_id) {
    typeItem = 'item'
  } else if (props.node.link_created_offer) {
    typeItem = 'offer'
  }

  const context = useContext(CampaignContext) as ICampaignContext
  const prevEntityNode = useMemo(
    () => findPrevEntityNode(context.graph.nodes!, props.node.id),
    [context.graph.nodes, props.node.id],
  )

  return (
    <StyledBox>
      <InputVars
        value={props.node.title || ''}
        onChange={e => {
          props.setNode({
            ...props.node,
            title: e.target.value,
          } as Node)
        }}
        items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
        label={i18next.t('campaign.block.EmailActionNode.title')}
      />

      <InputVars
        value={props.node.body || ''}
        onChange={e => {
          props.setNode({
            ...props.node,
            body: e.target.value,
          } as Node)
        }}
        items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
        label={i18next.t('campaign.block.EmailActionNode.body')}
      />

      <SelectMessageContentType node={props.node} setNode={props.setNode} showLinkCreatedOffer={!!prevEntityNode} />

      {typeItem == 'item' && (
        <SelectSkuItem
          onChange={v => {
            props.setNode({
              ...props.node,
              item_id: v as string,
            } as Node)
          }}
          value={props.node.item_id}
        />
      )}
    </StyledBox>
  )
}
