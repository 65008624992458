import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  FormErrorMessage,
  Input,
  Modal,
  ModalBaseProps,
  ModalContent,
  ModalFooter,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { ItemCategoryRead, StoreCategoryRenderType } from '@/api/dashboard'
import { ResponseError, getErrorText } from '@/api'
import { FieldGroup, SelectIcon } from '@/ui'
import { prepareSLUG } from '@/util'
import { HUB_SECTION_AVAILABLE_ICONS, getHubSectionIcon } from '@/layouts/hub'
import { useItemCategoryCreateMutate, useItemCategoryUpdateMutate } from '../api'

interface EditItemCategoryDialogProps extends ModalBaseProps {
  item: ItemCategoryRead
  created?: (item: ItemCategoryRead) => void
}

export const EditItemCategoryDialog = ({ item, created, ...rest }: EditItemCategoryDialogProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemCategoryCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemCategoryUpdateMutate(companyId, gameId)

  const {
    formState: { isSubmitting, errors },
    control,
    setValue,
    register,
    handleSubmit,
    watch,
  } = useForm<ItemCategoryRead>({
    values: { ...item, render_type: item.render_type || StoreCategoryRenderType.Default },
  })

  const renderType = watch('render_type')

  const autoplay = watch('render_options.autoplay')

  const onSubmit = handleSubmit(async data => {
    try {
      if (item.id) {
        await updateMutateAsync({
          id: item.id,
          update: data,
        })
      } else {
        let { data: newCat } = await createMutateAsync({
          create: data,
        })

        created?.(newCat)
      }
    } catch (e) {
      setErrorMessage(getErrorText(e as ResponseError))
      return
    }

    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle>{t(item.id ? 'item-category.edit' : 'item-category.create')}</ModalTitle>

          <FieldGroup>
            <div className="flex gap-3">
              <div className="shrink-0">
                <FieldGroup label={t('item-category.icon')}>
                  <Controller
                    control={control}
                    name="icon"
                    render={({ field }) => (
                      <SelectIcon
                        {...field}
                        allowEmpty={true}
                        items={HUB_SECTION_AVAILABLE_ICONS.map(icon => ({
                          children: <img className="size-[18px]" src={getHubSectionIcon(icon) as string} alt={icon} />,
                          value: icon,
                        }))}
                      />
                    )}
                  />
                </FieldGroup>
              </div>
              <div className="w-full">
                <FieldGroup label={t('item-category.name')}>
                  <Input
                    {...register('name', { required: t('validation.required') })}
                    {...formatHookFormErrors(errors, 'name')}
                    autoFocus={true}
                  />
                </FieldGroup>
              </div>
            </div>
          </FieldGroup>

          <FieldGroup label={t('item-category.description')}>
            <Input {...register('description')} {...formatHookFormErrors(errors, 'description')} />
          </FieldGroup>

          <FieldGroup label={t('item-category.slug')}>
            <Controller
              control={control}
              name="slug"
              render={({ field }) => (
                <Input
                  {...field}
                  {...formatHookFormErrors(errors, 'slug')}
                  maxLength={100}
                  onChange={e => setValue('slug', prepareSLUG(e.target.value))}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup label={t('item-category.render_type')}>
            <Controller
              control={control}
              name="render_type"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'render_type')}
                  items={[
                    { children: t('item-category.render_type.default'), value: StoreCategoryRenderType.Default },
                    { children: t('item-category.render_type.carousel'), value: StoreCategoryRenderType.Carousel },
                  ]}
                />
              )}
            />
          </FieldGroup>

          {renderType === StoreCategoryRenderType.Carousel && (
            <>
              <Checkbox className="mt-2" {...register('render_options.autoplay')}>
                {t('item-category.render_options.autoplay')}
              </Checkbox>
              {autoplay && (
                <FieldGroup className="mt-2" label={t('item-category.render_options.delay')}>
                  <Input
                    defaultValue={5000}
                    type="number"
                    {...register('render_options.delay', {
                      min: { value: 1000, message: t('validation.min-no-field', { value: 1000 }) },
                      valueAsNumber: true,
                      required: t('validation.required'),
                      setValueAs: value => value || undefined,
                    })}
                    {...formatHookFormErrors(errors, 'render_options.delay')}
                  />
                </FieldGroup>
              )}

              <Checkbox className="mt-2" {...register('render_options.loop')}>
                {t('item-category.render_options.loop')}
              </Checkbox>
              <Checkbox className="mt-2" {...register('render_options.show_dots')}>
                {t('item-category.render_options.show_dots')}
              </Checkbox>
            </>
          )}

          {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
            {t('Cancel')}
          </Button>
          <Button type="submit" size="sm" loading={isSubmitting}>
            {t(item.id ? 'Save2' : 'Add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
