import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const NumberedList: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M3.6665 5.33301V6.16634H4.08317H4.49984V8.24967V8.66634H5.33317V8.24967V5.33301H4.08317H3.6665ZM7.83317 6.16634V7.83301H20.3332V6.16634H7.83317ZM3.6665 10.333V11.1663H4.08317H5.16064L3.6665 12.6605V13.6663H5.74984H6.1665V12.833H5.74984H4.67236L6.1665 11.3389V10.333H4.08317H3.6665ZM7.83317 11.1663V12.833H20.3332V11.1663H7.83317ZM3.6665 15.333V16.1663H4.08317H5.33317V16.583H4.9165H4.49984V17.4163H4.9165H5.33317V17.833H4.08317H3.6665V18.6663H4.08317H6.1665V17.4163V16.583V15.333H4.08317H3.6665ZM7.83317 16.1663V17.833H20.3332V16.1663H7.83317Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

NumberedList.type = 'aghanim-icon';
