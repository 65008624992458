import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputDateRange,
  InputDateRangePreset,
  InputSearch,
  SearchFilterGeneric,
  SelectFilter,
  SelectFilterItem,
} from '@dashboard/ui'
import { SendStatus, WebhookEventTrigger, WebhookRedacted } from '@/api/dashboard'
import { WebhookSearchFilter } from '../types'

interface WebhookSearchFilterToolbarProps {
  webhook: WebhookRedacted
  filter: SearchFilterGeneric<WebhookSearchFilter>
  presets: InputDateRangePreset[]
}

export const WebhookSearchFilterToolbar = ({ webhook, filter, presets }: WebhookSearchFilterToolbarProps) => {
  const { t } = useTranslation()

  const triggersItems: SelectFilterItem[] = useMemo(() => {
    return Object.values(WebhookEventTrigger)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map(it => ({
        children: <span className="text-nowrap">{it}</span>,
        value: it,
      }))
  }, [])

  const eventTypeItems: SelectFilterItem[] = useMemo(() => {
    return webhook.events.sort().map(it => ({
      children: <span className="text-nowrap">{it}</span>,
      value: it,
    }))
  }, [webhook.events])

  const statusItems: SelectFilterItem[] = [
    { children: t('webhook.filters.success'), value: SendStatus.Success },
    { children: t('webhook.filters.failed'), value: SendStatus.Error },
  ]

  return (
    <div className="flex items-center gap-2">
      <InputSearch {...filter.registerInput('search_string')} placeholder={t('search')} />
      <div className="flex gap-2">
        {!!webhook.events.length && (
          <SelectFilter {...filter.registerSelect('event_type')} items={eventTypeItems}>
            {t('webhook.filters.event-type')}
          </SelectFilter>
        )}
        <SelectFilter {...filter.registerSelect('status')} items={statusItems}>
          {t('webhook.filters.status')}
        </SelectFilter>
        <SelectFilter {...filter.registerSelect('trigger')} items={triggersItems}>
          {t('webhook.filters.trigger')}
        </SelectFilter>
        <InputDateRange
          clearable
          value={
            filter.values.start_at && filter.values.end_at
              ? {
                  from: new Date(parseInt(filter.values.start_at as string)),
                  to: new Date(parseInt(filter.values.end_at as string)),
                }
              : null
          }
          onChange={v => {
            filter.onChange({
              ...filter.values,
              start_at: v?.from ? v.from.getTime() : undefined,
              end_at: v?.to ? v.to.getTime() : undefined,
            })
          }}
          presets={presets}
        />
      </div>
    </div>
  )
}
