import { formatNumber } from '@/util'
import { SKUItem } from '@/api/types'

export function getItemName(item: SKUItem | undefined | null) {
  if (item) {
    return item.is_stackable && item.quantity ? `${formatNumber(item.quantity)} · ${item.name}` : item.name
  }

  return ''
}
