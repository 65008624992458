import { useEffect, useState } from 'react'
import { Controller, useController, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { useTranslation } from 'react-i18next'
import { Checkbox, FormErrorMessage } from '@dashboard/ui'
import { getErrorText } from '@/api'
import { Expression, LoyaltySettingsUpdate } from '@/api/dashboard'
import {
  Button,
  ButtonIcon,
  FieldGroup,
  Header,
  ImageUploader,
  Input,
  InputExtraSection,
  Textarea,
  ToastSeverity,
  useToast,
} from '@/ui'
import { CloseOutline } from '@/icons'
import { useLoyaltySettingsUpdate } from '@/layouts/engagement/hooks'
import { EngagementInfoBlock } from '@/layouts/engagement/component'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { validateConditionTokens } from '@/layouts/campaigns/validation'
import { getTokenGroups } from '@/layouts/campaigns/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'
import { S3Bucket } from '@/types'
import { ImageSize } from '@/components/ui/ImageUploader/ValueContainer'

const MAX_NAME_LENGTH = 255
const MAX_DESC_LENGTH = 512
const [PERIOD_MIN, PERIOD_MAX] = [1, 365]

type LoyaltySettingsFormInput = Omit<LoyaltySettingsUpdate, 'active'>

interface LoyaltySettingsFormProps {
  uid: string
  settings: LoyaltySettingsUpdate
  onClose: () => void
}

export const LoyaltySettingsForm = ({ uid, settings, onClose }: LoyaltySettingsFormProps) => {
  const { t } = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<LoyaltySettingsFormInput>({ defaultValues: settings })
  useEffect(() => reset(settings), [reset, settings])

  const { field: image } = useController({ name: 'image_url', control })

  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const showToast = useToast()
  const { mutateAsync: updateProgramAsync } = useLoyaltySettingsUpdate(uid)
  const onSubmit = handleSubmit(async update => {
    if (update.requirements_expression?.tokens?.length) {
      const { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(update.requirements_expression?.tokens || []),
        getCampaignContext(gameSettings),
      )
      if (error) {
        setError('requirements_expression', { message: error })
        return
      }

      if (errTokens?.length) {
        return
      }
    } else {
      update.requirements_expression = null as unknown as Expression
    }

    try {
      await updateProgramAsync(update)
      onClose()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  const submitDisabled = !isDirty || imageLoading

  return (
    <form onSubmit={onSubmit}>
      <Header
        title={<span className="font-nohemi text-paragraph-md font-medium">{t('loyalty.program-edit.title')}</span>}
        className="p-4"
        minWidth="auto"
      >
        <div className="flex gap-4">
          <Button variant="primary" disabled={submitDisabled} type="submit" isLoading={isSubmitting}>
            {t('loyalty.program-edit.save')}
          </Button>
          <ButtonIcon
            onClick={() => {
              onClose()
              reset()
            }}
            type="button"
            size="sm"
            variant="secondary-gray"
          >
            <CloseOutline />
          </ButtonIcon>
        </div>
      </Header>

      <div className="flex size-full flex-col gap-4 p-4">
        <div className="flex flex-col">
          <FieldGroup
            size="sm"
            label={<span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.summary.name')}</span>}
          >
            <Input
              size="sm"
              disabled={isSubmitting}
              errors={errors}
              {...register('name', {
                required: t('validation.required'),
                validate: value => (value && value.trim().length > 0) || t('validation.required'),
                maxLength: {
                  value: MAX_NAME_LENGTH,
                  message: t('loyalty.summary.name.validation', { max: MAX_NAME_LENGTH }),
                },
              })}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.summary.description')}</span>
            }
          >
            <Textarea
              size="sm"
              rows={4}
              disabled={isSubmitting}
              {...register('description', {
                maxLength: {
                  value: MAX_DESC_LENGTH,
                  message: t('loyalty.summary.description.validation', { max: MAX_DESC_LENGTH }),
                },
              })}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.summary.background')}</span>
            }
          >
            <ImageUploader
              {...image}
              onLoadingChanged={setImageLoading}
              imageSize={ImageSize.cover}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              bucket={S3Bucket.hub}
            />
          </FieldGroup>
        </div>

        <div className="divider" />

        <div className="flex gap-16">
          <div className="flex-[2_2_0%]">
            <EngagementInfoBlock title={t('loyalty.info.period.title')} desc={t('loyalty.info.period.description')} />
          </div>

          <div className="flex-[3_3_0%]">
            <FieldGroup
              size="sm"
              label={
                <span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.summary.period')}</span>
              }
            >
              <Input
                size="sm"
                type="number"
                disabled={isSubmitting}
                extraRight={
                  <InputExtraSection side="right">
                    <span className="text-paragraph-xs">{t('loyalty.summary.period.suffix')}</span>
                  </InputExtraSection>
                }
                errors={errors}
                {...register('period', {
                  required: t('validation.required'),
                  min: {
                    value: PERIOD_MIN,
                    message: t('loyalty.summary.period.validation', { min: PERIOD_MIN, max: PERIOD_MAX }),
                  },
                  max: {
                    value: PERIOD_MAX,
                    message: t('loyalty.summary.period.validation', { min: PERIOD_MIN, max: PERIOD_MAX }),
                  },
                  valueAsNumber: true,
                })}
              />
            </FieldGroup>

            <div className="flex gap-3">
              <Checkbox {...register('repeatable')} disabled={isSubmitting} />
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1.5">
                  <span className="text-paragraph-sm font-medium text-text-primary">
                    {t('loyalty.summary.repeatable')}
                  </span>
                  <span className="text-paragraph-sm text-text-secondary">
                    {t('loyalty.summary.repeatable.description')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divider" />

        <FieldGroup label={t('daily-rewards.settings.limitations')}>
          <Controller
            control={control}
            name="requirements_expression"
            render={({ field }) => (
              <ExpressionEditor id={uid} errorTokens={[]} value={field.value} onChange={field.onChange} />
            )}
          />
          <ErrorMessage
            name="requirements_expression"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </div>
    </form>
  )
}
