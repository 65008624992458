import { useTranslation } from 'react-i18next'
import {
  CountryFlag,
  InputSearch,
  SearchFilterGeneric,
  SelectFilter,
  SelectFilterItem,
  cn,
  useIsMobile,
} from '@dashboard/ui'
import { ExpertListFilter } from '../types'

interface ExpertsListToolbarProps {
  filter: SearchFilterGeneric<ExpertListFilter>
}

export const ExpertListToolbar = ({ filter }: ExpertsListToolbarProps) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const certificationOptions: SelectFilterItem[] = [
    { children: t('experts.filter.certification.expert'), value: 'expert' },
    { children: t('experts.filter.certification.basic'), value: 'basic' },
    { children: t('experts.filter.certification.clear'), value: '' },
  ]

  const languagesOptions: SelectFilterItem[] = [
    { children: t('locales.en'), value: 'en', icon: () => <CountryFlag country={'us'} /> },
    { children: t('locales.de'), value: 'de', icon: () => <CountryFlag country={'de'} /> },
    { children: t('locales.fr'), value: 'fr', icon: () => <CountryFlag country={'fr'} /> },
    { children: t('locales.ru'), value: 'ru', icon: () => <CountryFlag country={'ru'} /> },
    { children: t('locales.pt'), value: 'pt', icon: () => <CountryFlag country={'pt'} /> },
    { children: t('locales.es'), value: 'es', icon: () => <CountryFlag country={'es'} /> },
    { children: t('locales.it'), value: 'it', icon: () => <CountryFlag country={'it'} /> },
    { children: t('locales.zh'), value: 'zh', icon: () => <CountryFlag country={'cn'} /> },
    { children: t('locales.zh-Hant'), value: 'zh-Hant', icon: () => <CountryFlag country={'cn'} /> },
    { children: t('locales.ja'), value: 'ja', icon: () => <CountryFlag country={'ja'} /> },
    { children: t('locales.ko'), value: 'ko', icon: () => <CountryFlag country={'ko'} /> },
    { children: t('locales.cs'), value: 'cs', icon: () => <CountryFlag country={'cs'} /> },
    { children: t('locales.hi'), value: 'hi', icon: () => <CountryFlag country={'hi'} /> },
    { children: t('locales.id'), value: 'id', icon: () => <CountryFlag country={'id'} /> },
    { children: t('locales.pl'), value: 'pl', icon: () => <CountryFlag country={'pl'} /> },
    { children: t('locales.tr'), value: 'tr', icon: () => <CountryFlag country={'tr'} /> },
    { children: t('locales.uk'), value: 'uk', icon: () => <CountryFlag country={'uk'} /> },
    { children: t('locales.vi'), value: 'vi', icon: () => <CountryFlag country={'vi'} /> },
    { children: t('locales.th'), value: 'th', icon: () => <CountryFlag country={'th'} /> },
  ]

  const featuresOptions: SelectFilterItem[] = [
    { children: t('experts.feature.design'), value: 'design' },
    { children: t('experts.feature.marketing'), value: 'marketing' },
    { children: t('experts.feature.integration'), value: 'integration' },
    { children: t('experts.feature.monetization'), value: 'monetization' },
    { children: t('experts.feature.development'), value: 'development' },
    { children: t('experts.feature.migration'), value: 'migration' },
    { children: t('experts.feature.customization'), value: 'customization' },
    { children: t('experts.feature.3rd-party-integrations'), value: '3rd-party-integrations' },
  ]

  const typesOptions: SelectFilterItem[] = [
    { children: t('experts.filters.type.personal'), value: 'individual' },
    { children: t('experts.filters.type.studio'), value: 'company' },
  ]

  return (
    <div className={cn('mb-4 flex w-full  gap-2', isMobile ? 'flex-col' : 'items-center')}>
      <InputSearch {...filter.registerInput('q')} placeholder={t('search')} />

      <div className={cn('flex gap-2', isMobile ? 'flex-col' : '')}>
        <SelectFilter {...filter.registerSelect('type')} items={typesOptions}>
          {t('experts.filter.type')}
        </SelectFilter>

        <SelectFilter {...filter.registerSelect('features', true)} items={featuresOptions}>
          {t('experts.filter.feature')}
        </SelectFilter>

        <SelectFilter {...filter.registerSelect('languages')} items={languagesOptions}>
          {t('experts.filter.languages')}
        </SelectFilter>

        <SelectFilter {...filter.registerSelect('certification')} items={certificationOptions}>
          {t('experts.filter.certification')}
        </SelectFilter>
      </div>
    </div>
  )
}
