import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const useSequentialOfferQuery = (companyId: string, gameId: string, id: string, enabled: boolean = true) => {
  return useQuery({
    enabled: enabled,
    queryKey: ['sequential_offer', gameId, id],
    select: response => response.data,
    queryFn: () => dashboardClient.v1.getSequentialOffer(companyId, gameId, id),
  })
}
