import { AnimatePresence, motion } from 'framer-motion'
import { StepCard, StepNext, StepNextSkeleton } from '../components'
import { useGenerationProgressListener } from '../hooks'
import { calcStatesProgress } from '../libs'
import { useSteps } from '../hooks/useSteps'

export const GetStartedStepper = () => {
  useGenerationProgressListener()

  const states = useSteps()

  const loading = Object.values(states).some(s => s.loading)
  const progress = calcStatesProgress(states)
  const next = Object.values(states).find(state => state.status === 'idle')

  return (
    <>
      <motion.div layout="position" className="mb-12">
        <AnimatePresence initial={false}>
          {!loading ? <StepNext progress={progress} next={next} /> : <StepNextSkeleton />}
        </AnimatePresence>
      </motion.div>

      <motion.div layout="position" className="relative">
        <div className="flex flex-col gap-6">
          {Object.values(states).map((state, index, array) => (
            <StepCard
              key={`step-card-${state.id}`}
              pos={index + 1}
              isCurrent={!loading && next?.id === state.id}
              step={state}
              nextStep={array[index + 1]}
            />
          ))}
        </div>
      </motion.div>
    </>
  )
}
