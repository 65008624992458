import { useNavigate } from 'react-router-dom'
import {
  Button,
  DotsHorizontal,
  Edit03,
  Menu,
  MenuItem,
  ModalPresetConfirm,
  Popper,
  Trash,
  useModal,
} from '@dashboard/ui'
import { PageHeader, Table, TableCell, TableRow, TableRowSkeleton } from '@/ui'
import { RangeOfferRead, SequentialOfferRead } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'
import { TimeLimitInfo } from '@/components/TimeLimitInfo'
import { OFFERS_RANGE_PATH_FORM, OFFERS_SEQUENTIAL_PATH_FORM, useGenerateCompanyPath } from '@/libs'
import { URL_NEW } from '@/types'
import { TableZeroStateFilter } from '@/layouts/components/TableZeroStateFilter'
import { useSequentialOffersQuery } from './sequential-api'
import { useSequentialOfferDeleteMutate } from '@/layouts/offers/sequential-api/useSequentialOfferDeleteMutate'
import { DEFAULT_LOAD_LIMIT } from '@/api/useGameItems'
import { SkuImageName } from '@/layouts/game-items'
import { useRangeOffersQuery } from '@/layouts/offers/range-api'
import { SelectOfferTypeModal } from '@/layouts/offers/SelectOfferTypeModal'
import { useRangeOfferDeleteMutate } from '@/layouts/offers/range-api/useRangeOfferDeleteMutate'

export default function OffersPage() {
  const { companyId, gameId } = useGenerateCompanyPath()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { t: tOffer } = useTranslation('offers')
  const { canEdit } = useCurrentUser()
  const { generatePath } = useGenerateCompanyPath()

  const { mutateAsync: deleteSeqMutateAsync } = useSequentialOfferDeleteMutate(companyId, gameId)
  const { mutateAsync: deleteRangeMutateAsync } = useRangeOfferDeleteMutate(companyId, gameId)

  const { data: seqItems = [], isLoading: isSeqLoging } = useSequentialOffersQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const { data: rangeItems = [], isLoading: isRangeLoading } = useRangeOffersQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const getType = (item: SequentialOfferRead | RangeOfferRead) => {
    if (rangeItems.includes(item as RangeOfferRead)) {
      return 'range'
    }

    return 'sequential'
  }

  const renderType = (item: SequentialOfferRead | RangeOfferRead) => {
    if (rangeItems.includes(item as RangeOfferRead)) {
      return tOffer('type.range')
    }

    return tOffer('type.sequential-offer')
  }

  const openConfirmDelete = useModal<{ item: SequentialOfferRead | RangeOfferRead }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: props.item.name }),
      }}
      cancel={{
        label: t('confirm.no'),
      }}
      submit={{
        color: 'danger',
        callback: async () => {
          props.onClose()
          if (getType(props.item) == 'range') {
            deleteRangeMutateAsync({ id: props.item.id })
          } else {
            deleteSeqMutateAsync({ id: props.item.id })
          }
        },
        label: t('remove'),
      }}
    />
  ))

  const openSelectType = useModal(props => (
    <SelectOfferTypeModal
      {...props}
      onSubmit={(type: 'range' | 'sequential') => {
        props.onClose()
        navigate(
          generatePath(
            type === 'range' ? OFFERS_RANGE_PATH_FORM : OFFERS_SEQUENTIAL_PATH_FORM,
            { offerId: URL_NEW },
            true,
          ),
        )
      }}
    />
  ))

  const openEditForm = (item: SequentialOfferRead | RangeOfferRead) => {
    navigate(
      generatePath(
        getType(item) == 'range' ? OFFERS_RANGE_PATH_FORM : OFFERS_SEQUENTIAL_PATH_FORM,
        { offerId: item.id },
        true,
      ),
    )
  }

  const renderDefaultHeader = () => {
    return (
      <TableRow variant="header">
        <TableCell width="100%">{tOffer('name')}</TableCell>
        <TableCell width={200}>{tOffer('item')}</TableCell>
        <TableCell width={160}>{tOffer('type')}</TableCell>
        <TableCell width={160}>{t('datetime.start_at')}</TableCell>
        <TableCell width={160}>{t('datetime.end_at')}</TableCell>

        <TableCell width={80} />
      </TableRow>
    )
  }

  const renderZeroState = () => {
    return (
      <TableZeroStateFilter
        hasFilter={false}
        page={1}
        items={items}
        createClick={() => {
          openSelectType()
        }}
        zeroStateMessage={tOffer('zero.title')}
        zeroStateSubMessage={tOffer('zero.message')}
        addButtonText={tOffer('create')}
        clearFilterClick={() => {}}
      />
    )
  }

  const renderRow = (it: SequentialOfferRead | RangeOfferRead) => {
    return (
      <TableRow
        key={it.id}
        to={generatePath(
          getType(it) == 'range' ? OFFERS_RANGE_PATH_FORM : OFFERS_SEQUENTIAL_PATH_FORM,
          { offerId: it.id },
          true,
        )}
      >
        <TableCell width="100%">{it.name}</TableCell>
        <TableCell width={200}>
          <SkuImageName item={it.item} />
        </TableCell>
        <TableCell width={160}>{renderType(it)}</TableCell>
        <TableCell width={160}>
          <TimeLimitInfo value={it.start_at} />
        </TableCell>
        <TableCell width={160}>
          <TimeLimitInfo value={it.end_at} />
        </TableCell>

        <TableCell width={80}>
          {canEdit && (
            <div
              className="text-right"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              <Popper
                popper={props => (
                  <Menu {...props}>
                    <MenuItem
                      icon={Edit03}
                      onClick={() => {
                        openEditForm(it)
                        props.onClose()
                      }}
                    >
                      {t('edit')}
                    </MenuItem>
                    <MenuItem
                      icon={Trash}
                      variant="danger"
                      onClick={() => {
                        openConfirmDelete({
                          item: it,
                        })
                        props.onClose()
                      }}
                    >
                      {t('delete')}
                    </MenuItem>
                  </Menu>
                )}
              >
                <Button variant="tertiary" color="secondary">
                  <DotsHorizontal />
                </Button>
              </Popper>
            </div>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const items = [...seqItems, ...rangeItems]
  const isLoading = isSeqLoging || isRangeLoading

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit && (
            <>
              {!!items.length && (
                <Button size="sm" onClick={() => openSelectType()}>
                  {tOffer('create')}
                </Button>
              )}
            </>
          )
        }
      >
        {tOffer('title')}
      </PageHeader>

      {isLoading ? (
        <TableRowSkeleton rowCount={10} columnCount={5} />
      ) : items.length === 0 ? (
        renderZeroState()
      ) : (
        <>
          <Table>
            {renderDefaultHeader()}
            {items.map(it => renderRow(it))}
          </Table>
        </>
      )}
    </div>
  )
}
