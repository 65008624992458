import { Badge, TableCell, TableCellBulkCheckbox, TableRow } from '@/ui'
import { DotsGridSix } from '@/icons'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { ConditionNode, ResourceState, StoreCardType, StoreItemRead } from '@/api/dashboard'
import { PriceView } from '@/layouts/game-items/components/PriceView'
import { getDescConditionNode } from '@/layouts/campaigns/blocks/descriptions/getDescConditionNode'
import { DescContext } from '@/layouts/campaigns/blocks/descriptions/types'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { itemCategoriesQuery } from '@/layouts/item-category/api'
import { useParams } from 'react-router-dom'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { useSegmentsQuery } from '@/layouts/segment/api/useSegmentsQuery'
import { TimeLimitInfo } from '@/components/TimeLimitInfo'

export interface StoreItemRowProps {
  storeItem: StoreItemRead
  isWeightRotation: boolean
  showTimelimit: boolean
  hasSegmentationRules: boolean
  enableDrag?: boolean
  onEdit?: () => void
  onSelect?: () => void
  selected?: boolean
}

export const StoreItemRow = ({
  storeItem,
  showTimelimit,
  isWeightRotation,
  hasSegmentationRules,
  enableDrag,
  onEdit,
  onSelect,
  selected,
}: StoreItemRowProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })
  const { data: allItems = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const renderTimeLimit = (storeItem: StoreItemRead) => {
    if (storeItem.start_at || storeItem.end_at) {
      return (
        <div className="flex flex-col">
          <TimeLimitInfo value={storeItem.start_at} />
          <TimeLimitInfo value={storeItem.end_at} />
        </div>
      )
    }
    return ''
  }

  const renderCondition = (storeItem: StoreItemRead) => {
    if (!storeItem.requirements_expression) {
      return ''
    }

    let [, subTitle] = getDescConditionNode(
      {
        expr: storeItem.requirements_expression,
      } as ConditionNode,
      {
        items: allItems,
        segments: segments,
      } as unknown as DescContext,
    )
    return subTitle
  }

  const getCategoryName = (s: StoreItemRead) => {
    const noCat = t('store.no-category')
    let c = s.item?.categories?.length ? categories.find(c => s.item.categories?.includes(c.id)) : null
    return c?.name || noCat
  }

  const renderBenefits = (storeItem: StoreItemRead) => {
    const nodes = []

    if (storeItem.discount_percent) {
      nodes.push(<div>{t('store.item.discount1') + ' ' + storeItem.discount_percent + '%'}</div>)
    }

    if (storeItem.bonus_percent) {
      nodes.push(<div>{t('store.item.bonus1') + ' ' + storeItem.bonus_percent + '%'}</div>)
    }

    if (storeItem.reward_points_percent) {
      nodes.push(<div>{t('store.item.rp1') + ' ' + storeItem.reward_points_percent + '%'}</div>)
    }

    return nodes
  }

  return (
    <TableRow
      key={storeItem.item_id}
      datatestid={`store-editor/item/${storeItem.id}`}
      variant={onEdit ? 'clickable' : undefined}
      onClick={onEdit}
    >
      {enableDrag && onEdit && (
        <TableCell width={42} className="flex justify-end">
          <DotsGridSix className="cursor-move" />
        </TableCell>
      )}
      {onSelect && <TableCellBulkCheckbox selected={!!selected} onChange={onSelect} />}
      <TableCell width="50%" className="flex items-center gap-3">
        <SkuImageName item={storeItem.item} />
        {storeItem.card_type == StoreCardType.Featured && (
          <Badge variant="gray-secondary">{t('store.item.featured_type.featured')}</Badge>
        )}
      </TableCell>
      <TableCell width="15%" className="truncate">
        {storeItem.is_free_item ? t('store.item.free_item') : <PriceView item={storeItem.item} />}
      </TableCell>
      <TableCell width="20%">{getCategoryName(storeItem)}</TableCell>
      <TableCell width="20%">{renderBenefits(storeItem)}</TableCell>
      {showTimelimit && <TableCell width="30%">{renderTimeLimit(storeItem)}</TableCell>}
      {hasSegmentationRules && <TableCell width="50%">{renderCondition(storeItem)}</TableCell>}
      {isWeightRotation && <TableCell width="12%">{storeItem.rotation_weight}</TableCell>}
    </TableRow>
  )
}
