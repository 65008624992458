import { Trans, useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Role } from '@/api/dashboard'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { ImageCompany } from '@/components/ui/AppSidebar/ImageCompany'
import { Button, Checkbox, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle, Select } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'

interface ConfirmDialogProps extends ModalProps {}

export function CreateGameModalConfirm({ ...rest }: ConfirmDialogProps) {
  const { isStaff } = useCurrentUser()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: dataCompanies } = useCompaniesQuery()
  let companies = dataCompanies?.data || []
  const { companyId } = useParams() as {
    companyId: string
  }

  companies = companies.filter(it => it.role == Role.Admin && !it.is_demo)

  let cId = null

  if (companies.length) {
    cId = companies.find(c => c.id == companyId)?.id || companies[0].id
  }

  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      company_id: cId,
    },
  })

  const company_id = watch('company_id')

  const onSubmit = handleSubmit(data => {
    navigate('/onboarding', { state: { company_id: data.company_id == 'new' ? null : data.company_id } })
    rest.onClose?.()
  })

  const onCreateAdvanced = () => {
    navigate('/onboarding_advanced', { state: { company_id: company_id == 'new' ? null : company_id } })
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle>{t('onboarding.confirm.title')}</ModalTitle>

          {t('onboarding.confirm.message')}

          <div className="mt-6 flex flex-col gap-4">
            <Checkbox type="radio" checked={company_id == 'new'} onClick={() => setValue('company_id', 'new')}>
              {t('onboarding.confirm.create-new-company')}
            </Checkbox>
            <Checkbox
              type="radio"
              checked={company_id != 'new'}
              onClick={() => setValue('company_id', companies[0].id)}
            >
              {companies.length > 1 ? (
                t('onboarding.confirm.existing-company')
              ) : (
                <Trans
                  i18nKey="onboarding.confirm.add-to"
                  components={{ b: <b className="ml-1" /> }}
                  values={{ value: companies[0].name }}
                />
              )}
            </Checkbox>

            {companies.length > 1 && (
              <Controller
                control={control}
                name="company_id"
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Select
                    disabled={company_id == 'new'}
                    {...field}
                    items={companies
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map(company => ({
                        icon: () => <ImageCompany company={company} className="size-[20px]" />,
                        children: company.name,
                        value: company.id,
                      }))}
                    search={{
                      placeholder: t('SearchInputPlaceHolder'),
                      fn: (search, option) => {
                        const text = option.children as string
                        return text.toLowerCase().trim().includes(search.toLowerCase().trim())
                      },
                    }}
                  />
                )}
              />
            )}
          </div>
        </ModalContent>

        <ModalFooter>
          {isStaff && (
            <Button type="button" onClick={onCreateAdvanced} color="primary" variant="outline" className="mr-auto">
              Advanced
            </Button>
          )}
          <Button type="button" onClick={rest.onClose} color="secondary" variant="outline">
            {t('Cancel')}
          </Button>
          <Button type="submit" color={'primary'}>
            {t('Create')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
