import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

export const useStoreItemsQuery = (companyId: string, gameId: string, storeId: string) => {
  return useQuery({
    queryKey: ['store-items', gameId, storeId],
    queryFn: () => {
      if (!storeId) {
        return Promise.resolve([])
      }
      return dashboardClient.v1.getStoreItems(companyId, gameId, storeId).then(r => r.data)
    },
  })
}
