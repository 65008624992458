import RevenueChart from './components/RevenueChart/RevenueChart'
import { ReactNode, useEffect, useState } from 'react'
import { t } from 'i18next'
import SingleValue, { SingleValueType } from './components/SingleValue/SingleValue'
import styled from '@emotion/styled'
import PaymentsSF from './components/PaymentsSF/PaymentsSF'
import RevenueByDevice from './components/RevenueByDevice/RevenueByDevice'
import ConversionGameHubChart from './components/ConversionGameHubChart/ConversionGameHubChart'
import RevenueMap from './components/RevenueMap/RevenueMap'
import SalesChart from './components/SalesChart/SalesChart'
import TopSkuItems from '@/layouts/dashboard/components/TopCharts/TopSkuItems'
import BestTriggers from './components/BestTriggers/BestTriggers'
import SankeyTopSku from './components/SankeyTopSku/SankeyTopSku'
import { BalanceCard } from './components'
import { HUB_API, URL_HUB_API } from '../../HubAPI'
import { AllData, AnalyticsDataContext } from './Context'
import { FilterType, ResourceState } from '../../api/dashboard'
import { createPresets, getGranularity, getUrlParamsFromDateRange } from './util'
import { Page } from '@/ui'
import { useMediaQuery } from '../../libs/hooks/useMediaQuery'
import { createAllData } from '@/layouts/dashboard/demo_data'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '@/layouts/campaigns/api'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { useBanner } from '@/libs/hooks/useBanner'
import TopClaimedSkuItems from '@/layouts/dashboard/components/TopCharts/TopClaimedSkuItems'
import TopCodes from '@/layouts/dashboard/components/TopCharts/TopCodes'
import HubVisitChart from '@/layouts/dashboard/components/HubVisitChart/HubVisitChart'
import { InputDateRange, InputDateRangePreset, Select, cn, useIsMobile } from '@dashboard/ui'
import { getUtcRange } from '@/libs/dates'
import { useGenerateCompanyPath } from '@/libs'

const DashboardTitle = styled.span`
  font-family: Nohemi;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
`

const SingleValueContainer = styled.div`
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 12px;
  align-items: center;
  width: 100%;
  border: 1px solid ${p => p.theme.palette.grey.borderSecondary};
  min-height: 121px;
`

const SingleValueSeparator = styled.div`
  width: 1px;
  height: 78px;
  background-color: ${p => p.theme.palette.background.fgskeleton};
  margin-right: 20px;
`

const Row = ({ children }: { children: ReactNode }) => {
  return <div className="flex items-center gap-5">{children}</div>
}

let dashPresets: InputDateRangePreset[] | null = null

const createDashPresets = () => {
  if (!dashPresets) {
    dashPresets = createPresets()
  }
  return dashPresets
}

export default function Dashboard() {
  const { companyId, gameId } = useGenerateCompanyPath()
  const [presets] = useState(createDashPresets())
  const [selectedFilter, setSelectedFilter] = useState<InputDateRangePreset>(presets[4])
  const [demoSelectedFilter, setDemoSelectedFilter] = useState<'7' | '30'>('7')
  const [dataByGame, setDataByGame] = useState<Record<string, AllData>>({})
  const [currentXhr, setCurrentXhr] = useState<XMLHttpRequest | null>(null)
  const { getStickyTop } = useBanner()
  const { data: campaigs = [] } = useQuery(campaignsQuery(companyId, gameId))
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const isMobile = useIsMobile()
  const is1200 = useMediaQuery('(min-width: 1400px)')

  const loadAllData = async () => {
    if (currentXhr) {
      currentXhr.abort()
    }
    let xhr = new XMLHttpRequest()
    xhr._gameId = gameId
    const utcRange = selectedFilter.isHourly ? selectedFilter.range : getUtcRange(selectedFilter.range)

    xhr.open(
      'GET',
      `${URL_HUB_API}/dashboard/v1/companies/${companyId}/games/${gameId}/reports/all_data?${getUrlParamsFromDateRange(utcRange)}`,
      true,
    )

    xhr.setRequestHeader('Authorization', 'Bearer ' + HUB_API.getToken())
    xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8')

    xhr.onprogress = function () {
      if (xhr.readyState >= 3) {
        let lastMessage = xhr.responseText
        let jsons = lastMessage.split('data: ')
        let data = jsons
          .filter(it => !!it.trim() && it.indexOf('DONE_MESSAGE') == -1)
          .map(it => {
            try {
              return JSON.parse(it)
            } catch (e) {
              //console.error(e)
              //console.log(it)
            }
          })

        let obj = {}
        data.forEach(d => {
          obj = { ...obj, ...d }
        })

        setDataByGame(prev => ({
          ...prev,
          [xhr._gameId]: {
            ...prev[xhr._gameId],
            ...obj,
          },
        }))

        if (lastMessage.indexOf('DONE_MESSAGE') != -1) {
          xhr.onprogress = null
          xhr.abort()
          setCurrentXhr(null)
        }
      }
    }

    xhr.send()
  }

  useEffect(() => {
    if (import.meta.env.VITE_DEMO_GAME_ID == gameId) {
      currentXhr?.abort()
      setDataByGame({
        [gameId]: createAllData(demoSelectedFilter, campaigs, items),
      })
    }
  }, [gameId, demoSelectedFilter, campaigs, items])

  useEffect(() => {
    if (import.meta.env.VITE_DEMO_GAME_ID != gameId) {
      loadAllData()
    }
  }, [companyId, gameId, selectedFilter])

  let dauText = t('dashboard.dau')
  let dauToolTip = t('dashboard.dau.tooltip')

  if (selectedFilter.isHourly) {
    dauText = presets[0].label
    dauToolTip = t('dashboard.dau.desc')
  }

  const width = '453px'
  const height = '460px'

  const renderCharts = () => {
    if (isMobile) {
      return (
        <section className="flex flex-col gap-3">
          <SankeyTopSku style={{ width: '100%', flexGrow: 3 }} />
          <BestTriggers style={{ width: '100%', height: height, flexGrow: 2 }} />
          <HubVisitChart />
          <PaymentsSF style={{ width: '100%', height: height, flexGrow: 1 }} />
          <ConversionGameHubChart style={{ width: '100%', height: height, flexGrow: 1 }} />
          <RevenueByDevice style={{ width: '100%', height: height, flexGrow: 1 }} />
          <TopSkuItems style={{ width: '100%', height: height, flexGrow: 1, overflow: 'hidden' }} />
          <TopClaimedSkuItems style={{ width: '100%', height: height, flexGrow: 1, overflow: 'hidden' }} />
          <TopCodes style={{ width: '100%', height: height, flexGrow: 1, overflow: 'hidden' }} />
        </section>
      )
    }

    if (is1200) {
      return (
        <>
          <Row>
            <SankeyTopSku style={{ flexGrow: 3 }} />
            <BestTriggers style={{ width: width, height: height, flexGrow: 2 }} />
          </Row>
          <Row>
            <HubVisitChart />
          </Row>
          <Row>
            <PaymentsSF style={{ width: width, height: height, flexGrow: 1 }} />
            <ConversionGameHubChart style={{ width: width, height: height, flexGrow: 1 }} />
            <RevenueByDevice style={{ width: width, height: height, flexGrow: 1 }} />
          </Row>
          <Row>
            <TopSkuItems style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
            <TopClaimedSkuItems style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
            <TopCodes style={{ width: width, height: height, flexGrow: 1, overflow: 'hidden' }} />
          </Row>
        </>
      )
    }

    return (
      <>
        <Row>
          <SankeyTopSku style={{ flexGrow: 1 }} />
        </Row>
        <Row>
          <BestTriggers style={{ width: width, height: height, flexGrow: 1 }} />
          <PaymentsSF style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <HubVisitChart />
        </Row>
        <Row>
          <ConversionGameHubChart style={{ width: width, height: height, flexGrow: 1 }} />
          <RevenueByDevice style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <TopSkuItems style={{ width: width, height: height, flexGrow: 1 }} />
          <TopClaimedSkuItems style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
        <Row>
          <TopCodes style={{ width: width, height: height, flexGrow: 1 }} />
          <div style={{ width: width, height: height, flexGrow: 1 }} />
        </Row>
      </>
    )
  }

  const data = dataByGame[gameId] || ({} as AllData)

  return (
    <AnalyticsDataContext.Provider
      value={{
        type: FilterType.Period,
        data: data,
        selectedFilter: selectedFilter.range,
        demoSelectedFilter: demoSelectedFilter,
        granularity: getGranularity(selectedFilter.range),
      }}
    >
      <Page className="gap-5 bg-fg-primary">
        <div className={cn('flex gap-5', isMobile ? 'flex-col' : '')}>
          <BalanceCard />
          <div className="grow overflow-x-hidden">
            <RevenueChart />
          </div>
        </div>

        <div
          className={cn(
            'sticky z-[2] flex gap-3 bg-fg-primary',
            !isMobile ? getStickyTop() : '',
            isMobile ? 'flex-col' : 'h-[72px] items-center justify-between',
          )}
        >
          <DashboardTitle>{t('dashboard.title')}</DashboardTitle>
          <div>
            {import.meta.env.VITE_DEMO_GAME_ID == gameId ? (
              <Select
                items={[
                  { children: t('filter.7days'), value: '7' },
                  { children: t('filter.30days'), value: '30' },
                ]}
                value={demoSelectedFilter}
                onChange={v => setDemoSelectedFilter(v as '7' | '30')}
              />
            ) : isMobile ? (
              <Select
                items={presets.map((preset, index: number) => {
                  return { children: preset.label, value: index }
                })}
                value={presets.findIndex(item => {
                  return item.label === selectedFilter.label
                })}
                onChange={v => setSelectedFilter(presets[v as number])}
              />
            ) : (
              <InputDateRange
                value={selectedFilter.range}
                onChange={v => {
                  setSelectedFilter(
                    presets.find(it => it.range == v) || {
                      range: v!,
                      label: '',
                    },
                  )
                }}
                presets={presets}
              />
            )}
          </div>
        </div>

        <SingleValueContainer>
          <SingleValue
            width="20%"
            value={
              data.total_usd_revenue && {
                value: data.total_usd_revenue?.value
                  ? Math.max(data.total_usd_revenue.value, 0)
                  : data.total_usd_revenue?.value,
                prev_value: data.total_usd_revenue?.prev_value
                  ? Math.max(data.total_usd_revenue.prev_value, 0)
                  : data.total_usd_revenue?.prev_value,
              }
            }
            label={t('dashboard.revenue')}
            tooltip={t('dashboard.revenue.tooltip')}
            type={SingleValueType.money}
          />

          {!isMobile && (
            <>
              <SingleValueSeparator />
              <SingleValue width="20%" value={data.dau} label={dauText} tooltip={dauToolTip} />
              <SingleValueSeparator />
              <SingleValue
                width="20%"
                value={data.mau}
                label={t('dashboard.mau')}
                tooltip={t('dashboard.mau.tooltip')}
              />

              <SingleValueSeparator />
              <SingleValue
                width="20%"
                value={data.arppu}
                label={t('dashboard.arppu')}
                type={SingleValueType.money}
                tooltip={t('dashboard.arppu.tooltip')}
              />
              <SingleValueSeparator />
              <SingleValue
                width="20%"
                value={data.conversion}
                label={t('dashboard.conversion')}
                tooltip={t('dashboard.conversion.tooltip')}
                type={SingleValueType.percent}
              />
            </>
          )}
        </SingleValueContainer>

        {isMobile && (
          <>
            <SingleValueContainer>
              <SingleValue
                width="20%"
                value={data.mau}
                label={t('dashboard.mau')}
                tooltip={t('dashboard.mau.tooltip')}
              />

              <SingleValueSeparator />
              <SingleValue width="20%" value={data.dau} label={dauText} tooltip={dauToolTip} />
            </SingleValueContainer>
            <SingleValueContainer>
              <SingleValue
                width="20%"
                value={data.arppu}
                label={t('dashboard.arppu')}
                type={SingleValueType.money}
                tooltip={t('dashboard.arppu.tooltip')}
              />
              <SingleValueSeparator />
              <SingleValue
                width="20%"
                value={data.conversion}
                label={t('dashboard.conversion')}
                tooltip={t('dashboard.conversion.tooltip')}
                type={SingleValueType.percent}
              />
            </SingleValueContainer>
          </>
        )}

        <SalesChart />

        {renderCharts()}

        {!isMobile && (
          <>
            <RevenueMap />
            <div style={{ height: '100px' }} />
          </>
        )}
      </Page>
    </AnalyticsDataContext.Provider>
  )
}
