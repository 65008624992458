import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactSortable } from 'react-sortablejs'
import { ErrorMessage } from '@hookform/error-message'
import { Button, FormErrorMessage, Input, ModalProps, useModal } from '@dashboard/ui'
import { ItemType, NestedItem, ResourceState } from '@/api/dashboard'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal'
import { ButtonIcon, TableZeroState } from '@/ui'
import { DotsGridSix, Plus, Trash } from '@/icons'
import { dashboardClient } from '@/api'
import { FormSection } from '../components'
import { SkuFormData } from '../types'
import { SKUItem } from '@/api/types'

const SkuFormBundleItems = (props: {
  children?: React.ReactNode
  onChange: (value: NestedItem[]) => void
  value: NestedItem[]
  currentItemId?: string
}) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { value, onChange } = props
  const [items, setItems] = useState<SKUItem[]>([])

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
        state: ResourceState.Active,
        ids: value
          .map(it => it.id)
          .sort()
          .join(','),
      })
      setItems(data)

      value.forEach(it => {
        if (it.count != undefined) {
          return
        }

        const item = data.find(itemRead => itemRead.id == it.id)
        if (item) {
          onChange(
            value.map(nestedItem => (nestedItem.id == it.id ? { ...nestedItem, count: item.quantity } : nestedItem)),
          )
        }
      })
    }

    loadItems()
  }, [value])

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      hideIds={props.currentItemId ? [props.currentItemId] : undefined}
      size="sm"
      title={t('game-item-edit-dialog.bundle.add-items')}
      buttonText={t('apply')}
      types={[ItemType.Item, ItemType.Currency]}
      picked={value.map(it => items.find(itemRead => itemRead.id == it.id)).filter(it => !!it)}
      onSubmit={items => {
        onChange(
          items.map(
            it =>
              value.find(v => v.id == it.id) || { id: it.id, count: it.is_stackable ? it.quantity : 1, sku: it.sku },
          ),
        )
        rest?.onClose?.()
      }}
    />
  ))

  const renderNestedItem = (nestedItem: NestedItem) => {
    const item = items.find(it => it.id == nestedItem.id)
    if (!item) {
      return null
    }

    return (
      <div key={nestedItem.id} className="flex h-[62px] w-full items-center gap-3 border-b border-border-secondary">
        <div className="min-w-[32px]">
          <DotsGridSix className="cursor-move" />
        </div>
        <div className="flex w-full items-center gap-3 truncate">
          {item && <SkuImageName item={item} hideQuantity={true} />}
        </div>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className="non-drag ml-auto mr-3 flex items-center gap-2">
          {item?.is_stackable && (
            <>
              <span className="text-caption-md text-text-quarterary-hover">{t('game-item-edit-dialog.quantity')}:</span>
              <Input
                className="w-[190px]"
                type="number"
                value={nestedItem.count}
                onChange={e =>
                  onChange(
                    value.map(it =>
                      it.id == nestedItem.id
                        ? {
                            ...it,
                            count: (e.target.value == '' ? '' : parseInt(e.target.value)) as unknown as number,
                          }
                        : it,
                    ),
                  )
                }
              />
            </>
          )}
          <ButtonIcon
            variant="tertiary-destructive"
            onClick={() => onChange(value.filter(it => it.id !== nestedItem.id))}
          >
            <Trash />
          </ButtonIcon>
        </div>
      </div>
    )
  }

  return (
    <FormSection
      label={t('sku.item.contain-items')}
      action={
        value.length > 0 && (
          <Button
            variant="link"
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              openPicker()
            }}
          >
            {t('game-item-edit-dialog.bundle.add-items')}
          </Button>
        )
      }
    >
      {value.length == 0 ? (
        <TableZeroState
          className="h-[195px]"
          title={t('game-item-edit-dialog.bundle.no-items')}
          buttons={
            <Button
              size="sm"
              color="primary"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                openPicker()
              }}
            >
              <Plus size={14} />
              <span>{t('game-item-edit-dialog.bundle.add-items')}</span>
            </Button>
          }
        />
      ) : (
        <ReactSortable
          dragClass="cursor-move"
          filter=".non-drag"
          animation={200}
          delay={2}
          preventOnFilter={false}
          list={value as { id: string }[]}
          setList={v => {
            let changed = false
            for (let i = 0; i < v.length; i++) {
              if (v[i].id != value[i].id) {
                changed = true
                break
              }
            }
            if (changed) {
              onChange(v)
            }
          }}
        >
          {value.map(it => renderNestedItem(it)).filter(it => it)}
        </ReactSortable>
      )}
      {props.children}
    </FormSection>
  )
}

export const SkuFormBundleForm = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<SkuFormData>()
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name="nested_items"
      rules={{
        required: t('validation.required'),
      }}
      render={({ field }) => (
        <SkuFormBundleItems
          currentItemId={watch('id')}
          value={field.value || []}
          onChange={items => field.onChange(items.length ? items : null)}
        >
          <ErrorMessage
            name="nested_items"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </SkuFormBundleItems>
      )}
    />
  )
}
