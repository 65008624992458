import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useKey } from 'react-use'
import { ErrorMessage } from '@hookform/error-message'
import { Button, FormErrorMessage, Input, RewardPoint, RewardPointOutline, XClose } from '@dashboard/ui'
import { GameSettingsRead } from '@/api/dashboard'
import Divider from '@/components/Divider'
import {
  Drawer,
  DrawerContent,
  DrawerTopbar,
  FieldGroup,
  FieldSection,
  InputExtraSection,
  ToastSeverity,
  useToast,
} from '@/ui'
import { useFeatureFlags, useGenerateCompanyPath, usePydenticForm } from '@/libs/hooks'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { CASHBACK_REWARDS_PATH } from 'src/layouts/cashback-rewards'
import { generatePath, useNavigate } from 'react-router-dom'
import { useGameSettingsCreateMutate } from '@/api/useGameSettingsCreateMutate'
import { useGameSettingsUpdateMutate } from '@/api/useGameSettingsUpdateMutate'
import { ToggleSection } from '@/layouts/components/ToggleSection'
import { useCurrentUser } from '@/api/useCurrentUser'

export const RewardPointsEditForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('sku-cashback')
  const { canEdit } = useCurrentUser()
  const { t: tDefault } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const showToast = useToast()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useGameSettingsCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useGameSettingsUpdateMutate(companyId, gameId)

  const {
    control,
    watch,
    handleSubmit,
    register,
    clearErrors,
    formState: { errors, isSubmitting, isDirty },
  } = usePydenticForm<Omit<GameSettingsRead, 'id'>>({
    mode: 'onChange',
    values: { ...settings },
  })
  const { featureFlags } = useFeatureFlags()
  const [reward_points_rate, enable_reward_points] = watch([
    'reward_points_settings.reward_points_rate',
    'enable_reward_points',
  ])

  const onClose = () => {
    navigate(generatePath(CASHBACK_REWARDS_PATH, { companyId, gameId }))
  }

  useKey('Escape', onClose, undefined, [onClose])

  const onFormSubmit = handleSubmit(async (data: Omit<GameSettingsRead, 'id'>) => {
    if (!data.enable_reward_points) {
      data.reward_points_settings = {
        reward_points_rate: null as unknown as undefined,
      }
    }

    const payload = {
      reward_points_settings: data.reward_points_settings,
      enable_reward_points: data.enable_reward_points,
    }

    if (!settings) {
      await createMutateAsync({
        data: payload,
      })
    } else {
      await updateMutateAsync({
        settingsId: settings.id,
        data: payload,
      })
    }
    showToast({ message: tDefault('saved'), severity: ToastSeverity.success })
    onClose()
  })

  const renderRewardPointSettings = () => {
    return (
      <FieldSection label={t('form.rp')} caption={t('form.rp.reward_points.desc')}>
        <FieldGroup label={t('form.reward_points.perc-rate')}>
          <Input
            placeholder={t('form.reward_points.place-holder')}
            type="number"
            autoFocus
            disabled={!enable_reward_points || !canEdit}
            extraLeft={{
              icon: () => <InputExtraSection side="left">%</InputExtraSection>,
            }}
            {...register('reward_points_settings.reward_points_rate', {
              required: tDefault('validation.required'),
              min: {
                value: 0.01,
                message: tDefault('validation.min', {
                  field: t('form.reward_points_rate'),
                  value: 0.01,
                }),
              },
            })}
            step={0.01}
          />
          <div className="mt-1.5 flex items-start gap-1 text-text-secondary">
            <div className="text-caption-md font-normal">
              {t('form.reward_points.input-desc', { value: reward_points_rate ? reward_points_rate : 0 })}
            </div>
          </div>

          <ErrorMessage
            name="reward_points_settings.reward_points_rate"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
        {featureFlags.reward_points_pay_enabled && (
          <FieldGroup
            label={t('form.rp.max_applicable_reward_points_percent')}
            caption={t('form.rp.max_applicable_reward_points_percent.caption')}
          >
            <Input
              type="number"
              disabled={!enable_reward_points || !canEdit}
              extraLeft={{
                icon: () => <InputExtraSection side="left">%</InputExtraSection>,
              }}
              {...register('reward_points_settings.max_applicable_reward_points_percent', {
                required: false,
                min: {
                  value: 1,
                  message: tDefault('validation.min', {
                    field: t('form.rp.max_applicable_reward_points_percent'),
                    value: 1,
                  }),
                },
                max: {
                  value: 100,
                  message: tDefault('validation.max', {
                    field: t('form.rp.max_applicable_reward_points_percent'),
                    value: 100,
                  }),
                },
              })}
            />
            <ErrorMessage
              name="reward_points_settings.max_applicable_reward_points_percent"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        )}
      </FieldSection>
    )
  }

  return (
    <Drawer open={!!settings}>
      <form className="flex h-full flex-col overflow-y-auto" onSubmit={onFormSubmit}>
        <DrawerTopbar>
          <div className="mr-auto font-nohemi text-[20px] font-medium tracking-[1px]">{t('form.rp.title')}</div>
          <div className="flex items-start gap-3">
            <Button type="submit" loading={isSubmitting} disabled={!isDirty}>
              {tDefault('Save')}
            </Button>
            <Button className="w-7 px-0" variant="outline" color="secondary" onClick={onClose}>
              <XClose />
            </Button>
          </div>
        </DrawerTopbar>

        <DrawerContent>
          <Controller
            control={control}
            name="enable_reward_points"
            render={({ field }) => (
              <ToggleSection
                disabled={!canEdit}
                label={t('form.rp.enable')}
                value={field.value}
                onChange={v => {
                  clearErrors()
                  field.onChange(v)
                }}
              />
            )}
          />

          <FieldSection label={t('form.section.exchange-rate.label')}>
            <div className="flex flex-col gap-3 rounded-md bg-fg-secondary p-3">
              <div className="grid grid-cols-2 gap-4 ">
                <div>
                  <FieldGroup label={t('form.exchange-rate.from.label')}>
                    <Input
                      extraRight={{ icon: () => <span>USD</span> }}
                      disabled
                      extraLeft={{ icon: () => <span className="font-bold">$</span> }}
                      value={1}
                    />
                  </FieldGroup>
                </div>
                <div>
                  <FieldGroup label={t('form.exchange-rate.to.label')}>
                    <Input
                      extraRight={{ icon: () => <span>RP</span> }}
                      disabled
                      extraLeft={{ icon: () => <RewardPoint /> }}
                      value={100}
                    />
                  </FieldGroup>
                </div>
              </div>
              <div className="flex items-center gap-3 rounded-md border border-border-disabled p-1.5 text-caption-sm text-text-disabled">
                <div className="rounded-sm bg-fg-secondary-hover p-1.5">
                  <RewardPointOutline size={22} />
                </div>
                <div>
                  <div>{t('form.rp.desc2')}</div>
                  <div>{t('form.rp.desc3')}</div>
                </div>
              </div>
              <span className="text-caption-sm font-normal text-text-tertiary">{t('form.rp.desc')}</span>
            </div>
          </FieldSection>

          <Divider />
          {renderRewardPointSettings()}
        </DrawerContent>
      </form>
    </Drawer>
  )
}
