import { ReactNode, forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { SelectFilterMultipleMenu } from '@dashboard/ui/src/components/SelectFilter/SelectFilterMultipleMenu'
import {
  FormErrorMessage,
  SelectBaseButton,
  SelectBaseButtonProps,
  SelectFilterItem,
  SelectFilterValue,
  UseFloatingMenuState,
  getFloatingMotionProps,
  useFloatingMenu,
} from '@dashboard/ui'
import { ControlHookFormError } from '@dashboard/ui/src/types'
import { AnimatePresence } from 'framer-motion'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'

export interface SelectMultiProps
  extends Pick<SelectBaseButtonProps, 'placeholder' | 'disabled'>,
    ControlHookFormError {
  /**
   * Items for the select filter.
   */
  items: SelectFilterItem[]
  /**
   * Title of menu in multiple select.
   */
  title?: ReactNode

  enableSearch?: boolean

  /**
   * Value for the select filter.
   */
  value: SelectFilterValue[] | null | undefined
  /**
   * Callback when the select filter value changes.
   * @param value
   */
  onChange: (value: SelectFilterValue[] | null) => void

  renderMenuFooter?: (state: UseFloatingMenuState) => ReactNode
}

/**
 * @deprecated need delete & add support to base select.
 */
export const SelectMulti = forwardRef<HTMLButtonElement, SelectMultiProps>(
  ({ items, title, placeholder, value, onChange, renderMenuFooter, error, enableSearch = true, ...rest }, ref) => {
    const { t } = useTranslation('ui')

    const {
      state,
      floating: { context, placement },
      getReferenceProps,
      getFloatingProps,
    } = useFloatingMenu(ref)

    const renderValue = () => {
      if (!value || !value.length) {
        return null
      }

      if (value.length === 1) {
        return <span className="text-text-secondary">{items.find(item => item.value === value[0])?.children}</span>
      }
      return (
        <span className="text-text-secondary">
          <Trans
            t={t}
            i18nKey="select-filter.multiple.value"
            components={{ num: <span className="tabular-nums" /> }}
            values={{ count: value?.length }}
          />
        </span>
      )
    }

    return (
      <>
        <SelectBaseButton ref={ref} {...rest} placeholder={placeholder} {...getReferenceProps()}>
          {value && value.length > 0 && renderValue()}
        </SelectBaseButton>

        <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
        <AnimatePresence>
          {state.isOpen && (
            <FloatingFocusManager context={context}>
              <FloatingPortal>
                <SelectFilterMultipleMenu
                  {...getFloatingProps()}
                  className="absolute z-[3000] w-max"
                  motionProps={{
                    ...getFloatingMotionProps(placement),
                    className: 'max-w-none',
                  }}
                  multiple={true}
                  value={value || []}
                  items={items || []}
                  title={title}
                  onChange={onChange}
                  params={{ search: enableSearch, size: 'md' }}
                  onClose={() => {
                    state.setIsOpen(false)
                  }}
                  footer={renderMenuFooter && <div>{renderMenuFooter(state)}</div>}
                />
              </FloatingPortal>
            </FloatingFocusManager>
          )}
        </AnimatePresence>
      </>
    )
  },
)
