import { useEffect, useMemo, useState } from 'react'
import { Controller, useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getErrorText } from '@/api'
import { DailyRewardMode, DailyRewardRead, DailyRewardType, DailyRewardUpdate, Expression } from '@/api/dashboard'
import { CloseOutline } from '@/icons'
import { Button, ButtonIcon, FieldGroup, Header, ImageUploader, Input, ToastSeverity, useToast } from '@/ui'
import { useDailyRewardsUpdate } from '@/layouts/engagement/hooks'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'
import { validateConditionTokens } from '@/layouts/campaigns/validation'
import { getTokenGroups } from '@/layouts/campaigns/util'
import { LexicalController } from '@/components/lexical'
import { MAX_NAME_LENGTH } from '@/Settings'
import { ErrorMessage } from '@hookform/error-message'
import { PropertyControlSwitch } from '@/layouts/engagement/component'
import { FormErrorMessage, InputDateRange } from '@dashboard/ui'
import { FieldSection } from 'src/components/ui/FieldSection'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { ImageSize } from '@/components/ui/ImageUploader/ValueContainer'
import { S3Bucket } from '@/types'

type DailyRewardsSettingsFormInput = Omit<DailyRewardUpdate, 'enabled'>

interface DailyRewardsSettingsFormProps {
  campaign: DailyRewardRead
  onClose: () => void
}

export const DailyRewardsSettingsForm = ({ campaign, onClose }: DailyRewardsSettingsFormProps) => {
  const { t } = useTranslation()
  const editable = useMemo(
    () => ({
      mode: campaign.type === DailyRewardType.Seasonal,
      period: campaign.type === DailyRewardType.Seasonal,
    }),
    [campaign.type],
  )
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const [imageLoading, setImageLoading] = useState<boolean>(false)

  const {
    watch,
    control,
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    reset,
    setError,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<DailyRewardsSettingsFormInput>({
    defaultValues: {
      ...campaign,
      mode: campaign?.mode || DailyRewardMode.Soft,
    },
  })
  const [startAt, endAt] = watch(['start_at', 'end_at'])
  const submitDisabled = useMemo(() => !isDirty || imageLoading, [isDirty, imageLoading])

  useEffect(() => {
    reset(campaign)

    switch (campaign.type) {
      case DailyRewardType.Basic:
        unregister('start_at')
        unregister('end_at')
        break
      case DailyRewardType.Seasonal:
        register('start_at', {
          required: t('validation.required'),
        })
        register('end_at', {
          required: t('validation.required'),
          validate: value => {
            const start_at = getValues('start_at')
            if (!value || !start_at || value <= start_at) {
              return t('daily-rewards.settings.period.invalid')
            }
          },
        })
        break
    }
  }, [reset, register, unregister, campaign])

  const { field: image } = useController({ name: 'image_url', control })
  const { field: mode } = useController({ name: 'mode', control })

  const showToast = useToast()
  const { mutateAsync } = useDailyRewardsUpdate(campaign.id)
  const onSubmit = handleSubmit(async data => {
    if (data.requirements_expression?.tokens?.length) {
      const { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(gameSettings),
      )
      if (error) {
        setError('requirements_expression', { message: error })
        return
      }

      if (errTokens?.length) {
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
    }

    try {
      await mutateAsync({ ...data })
      onClose()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <Header
        title={
          <span className="font-nohemi text-lg font-medium tracking-wider">{`${t('daily-rewards.settings.edit.title')} · ${campaign.type === DailyRewardType.Basic ? t('daily-rewards.summary.type.basic') : t('daily-rewards.summary.type.seasonal')}`}</span>
        }
        className="p-4"
        minWidth="auto"
      >
        <div className="flex gap-4">
          <Button variant="primary" disabled={submitDisabled} isLoading={isSubmitting} type="submit">
            {t('daily-rewards.settings.edit.save')}
          </Button>
          <ButtonIcon
            onClick={() => {
              onClose()
              reset()
            }}
            type="button"
            size="sm"
            variant="secondary-gray"
          >
            <CloseOutline />
          </ButtonIcon>
        </div>
      </Header>

      <div className="flex size-full flex-col gap-4 p-4">
        <FieldSection label={t('daily-rewards.settings.general')}>
          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">{t('daily-rewards.settings.name')}</span>
            }
          >
            <Input
              size="sm"
              disabled={isSubmitting}
              errors={errors}
              {...register('name', {
                required: t('validation.required'),
                validate: value => (value && value.trim().length > 0) || t('validation.required'),
                maxLength: {
                  value: MAX_NAME_LENGTH,
                  message: t('daily-rewards.settings.name.validation', { max: MAX_NAME_LENGTH }),
                },
              })}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">
                {t('daily-rewards.settings.description')}
              </span>
            }
          >
            <LexicalController
              control={control}
              layout="compact"
              name="description"
              rules={{ required: t('validation.required') }}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup
            size="sm"
            label={
              <span className="text-caption-sm font-normal text-text-tertiary">
                {t('daily-rewards.settings.image')}
              </span>
            }
          >
            <ImageUploader
              {...image}
              onLoadingChanged={setImageLoading}
              imageSize={ImageSize.cover}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              bucket={S3Bucket.hub}
            />
          </FieldGroup>
        </FieldSection>

        <div className="divider" />

        <FieldSection label={t('daily-rewards.settings.mode')}>
          {editable.mode ? (
            <PropertyControlSwitch
              {...mode}
              orientation="vertical"
              left={{
                label: t('daily-rewards.settings.mode.soft'),
                desc: t('daily-rewards.settings.mode.soft.description'),
                value: DailyRewardMode.Soft,
              }}
              right={{
                label: t('daily-rewards.settings.mode.hard'),
                desc: t('daily-rewards.settings.mode.hard.description'),
                value: DailyRewardMode.Hard,
              }}
            />
          ) : (
            <div className="flex w-full flex-col gap-1">
              <span className="text-caption-md">{t('daily-rewards.settings.mode.soft')}</span>
              <span className="text-paragraph-sm text-text-secondary">
                {t('daily-rewards.settings.mode.soft.description')}
              </span>
            </div>
          )}
        </FieldSection>

        <div className="divider" />

        <FieldSection label={t('daily-rewards.settings.period_conds')}>
          {editable.period ? (
            <>
              <InputDateRange
                value={
                  startAt || endAt
                    ? {
                        from: startAt ? new Date((startAt as number) * 1000) : undefined,
                        to: endAt ? new Date((endAt as number) * 1000) : undefined,
                      }
                    : null
                }
                onChange={value => {
                  setValue('start_at', value?.from ? value.from.getTime() / 1000 : undefined, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  setValue('end_at', value?.to ? value.to.getTime() / 1000 : undefined, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                }}
              />
              {(errors.start_at || errors.end_at) && (
                <FormErrorMessage>{errors.start_at?.message || errors.end_at?.message}</FormErrorMessage>
              )}
            </>
          ) : (
            <div className="flex w-full flex-col gap-1">
              <span className="text-caption-md">{t('daily-rewards.settings.repeatable.title')}</span>
              <span className="text-paragraph-sm text-text-secondary">
                {t('daily-rewards.settings.repeatable.desc')}
              </span>
            </div>
          )}
        </FieldSection>

        <div className="divider" />

        <FieldGroup label={t('daily-rewards.settings.limitations')}>
          <Controller
            control={control}
            name="requirements_expression"
            render={({ field }) => (
              <ExpressionEditor id={campaign.id} errorTokens={[]} value={field.value} onChange={field.onChange} />
            )}
          />
          <ErrorMessage
            name="requirements_expression"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </div>
    </form>
  )
}
