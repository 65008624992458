import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const LanguageIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M6.40549 3C5.919 5.66667 6.40549 13 12.2433 21M3 5.5C6.24324 6.33333 15.1622 6 21 3M16.621 9C16.4186 9.77975 16.2006 10.7054 15.8634 11.7012M15.8634 11.7012C15.1196 13.8979 13.7961 16.4357 10.7831 18.5C2.02671 24 1.05405 13.5 7.37771 11C10.1768 9.8493 13.8454 9.791 15.8634 11.7012ZM15.8634 11.7012C17.3529 13.111 17.9432 15.5932 16.621 19.5"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </IconBase>
  )
})
