import { Button, MOTION_ANIMATION_SPEED_MD, Select, SelectProps, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { rarityQuery } from '@/layouts/sku-settings/api/rarityQuery'
import { useGenerateCompanyPath } from '@/libs'
import { EditItemRarityModal } from '@/layouts/sku-settings/widgets/EditItemRarityModal'
import { ItemRarityRead } from '@/api/dashboard'

interface SelectRariryProps extends Omit<SelectProps, 'items'> {}

export const SelectRarity = (props: SelectRariryProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { data: items = [] } = useQuery({
    ...rarityQuery(companyId, gameId),
  })

  const openCreateRarityModal = useModal(p => (
    <EditItemRarityModal
      {...p}
      item={{} as ItemRarityRead}
      created={rarity => {
        props.onChange?.(rarity.id)
      }}
    />
  ))

  return (
    <Select
      {...props}
      items={items.map(it => ({
        value: it.id,
        children: (
          <div className="flex items-center">
            <div
              className="size-[18px] rounded-sm border"
              style={{
                backgroundColor: it.color_background_badge,
                borderColor: 'rgba(0, 0, 0, 0.2)',
              }}
            />
            <span className="ml-2">{it.name}</span>
          </div>
        ),
      }))}
      placeholder={t('item-props.sku.properties.rarity.place-holder')}
      renderMenuFooter={state => (
        <Button
          className="w-full"
          variant="outline"
          size="md"
          onClick={() => {
            state.setIsOpen(false)
            setTimeout(() => {
              openCreateRarityModal()
            }, MOTION_ANIMATION_SPEED_MD * 500)
          }}
        >
          {t('Create')}
        </Button>
      )}
    />
  )
}
