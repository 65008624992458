import { useEffect } from 'react'
import { dashboardClient } from '@/api'
import { useParams } from 'react-router-dom'

import {
  Callout,
  FieldGroup,
  ImageList,
  ImageUploader,
  Input,
  ModalConfirm,
  PageHeader,
  Select,
  SelectOption,
  SpinnerAghanim,
  Textarea,
  ToastSeverity,
  Toggle,
  useModal,
  useToast,
} from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import {
  Mode,
  NewsCategory,
  StoreCardVersion,
  StoreImageSize,
  VerifyState,
  WebsiteFont,
  WebsiteRead,
} from '@/api/dashboard'
import DescBlock from '../game-settings/components/DescBlock'
import Block from '../game-settings/components/Block'
import { ErrorMessage } from '@hookform/error-message'
import DangerousButton from '../../components/DangerousButton'
import { formatDecimalAspectRatio, getRecommendedSizeByRatio } from '@/libs'
import { getNow } from '@/util'
import { KeyValue } from '@/types'
import { invalidateWebsitesQuery } from '@/api/useWebsitesQuery'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { accountDetailsQuery } from '@/layouts/company'
import { AlertHexagon } from '@/icons'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { Button, FormErrorMessage } from '@dashboard/ui'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'

const imageRatioOptions: SelectOption[] = [
  1,
  3 / 2,
  5 / 2,
  7 / 5,
  10 / 8,
  16 / 9,
  2 / 3,
  2 / 5,
  5 / 7,
  8 / 10,
  9 / 16,
].map(aspectRatio => ({
  value: aspectRatio,
  children: formatDecimalAspectRatio(aspectRatio),
}))

const FONT_NAMES = {
  'concert-one': 'Concert One',
  inter: 'Inter',
  lato: 'Lato',
  merriweather: 'Merriweather',
  montserrat: 'Montserrat',
  'noto-sans': 'Noto Sans',
  'open-sans': 'Open Sans',
  oswald: 'Oswald',
  prompt: 'Prompt',
  raleway: 'Raleway',
  roboto: 'Roboto',
  rubik: 'Rubik',
  'russo-one': 'Russo One',
  'work-sans': 'Work Sans',
  outfit: 'Outfit',
}

export const HubSettingsPage = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const queryClient = useQueryClient()
  const { canEdit, isSuperAdmin, isAdmin } = useCurrentUser()
  const showToast = useToast()
  const { game } = useCurrentGame()
  const { data: accountDetails, isLoading } = useQuery(accountDetailsQuery(companyId, isAdmin))

  const {
    setValue: setValueWebsite,
    watch: watchWebsite,
    control: controlWebsite,
    reset: resetWebSite,
    formState: { errors: errorsWebSite, isDirty: isDirtyWebSite, dirtyFields: dirtyGameFields },
    register: registerWebSite,
    handleSubmit: handleSubmitWebSite,
  } = useForm<WebsiteRead>({})

  const loadSite = async () => {
    let { data } = await dashboardClient.v1.getWebsites(companyId, gameId)
    if (data.length) {
      let { data: ws } = await dashboardClient.v1.getWebsite(companyId, gameId, data[0].id)
      if (ws) {
        resetWebSite(ws)
      }
    }
  }

  useEffect(() => {
    loadSite()
  }, [gameId])

  const [avatars, leaderboardIcons, imageRatio, published_at] = watchWebsite([
    'config.avatars',
    'config.leaderboard_icons',
    'config.store_settings.image_ratio',
    'published_at',
  ])

  const handleSaveClick = async () => {
    if (isDirtyWebSite) {
      await handleSubmitWebSite(saveWebSite)()
    }

    if (Object.keys(errorsWebSite).length === 0) {
      showToast({ message: t('saved'), severity: ToastSeverity.success })

      invalidateWebsitesQuery(queryClient, companyId, gameId)
    }
  }

  const isDirty = () => {
    if (Object.keys(dirtyGameFields).length) {
      return true
    }

    return false
  }

  const onDiscardClick = () => {
    resetWebSite()
  }

  const saveWebSite = async (data: WebsiteRead) => {
    if (data.config?.news_settings) {
      let keys = [NewsCategory.Event, NewsCategory.Update, NewsCategory.CompanyNews]
      let default_image_urls = data.config?.news_settings.default_image_urls as KeyValue
      for (let key of keys) {
        if (!default_image_urls[key]) {
          delete default_image_urls[key]
        }
      }
    }

    if (data.config?.store_settings) {
      if (!data.config.store_settings.card_version) {
        delete data.config.store_settings.card_version
      }
    }

    await dashboardClient.v1.updateWebsite(companyId, gameId, data.id, data)

    resetWebSite(data)
  }

  const renderGeneral = () => {
    return (
      <DescBlock title={t('hub-settings.title')} description={t('hub-settings.general.desc')}>
        <Block>
          <FieldGroup label={t('website.title')}>
            <Input {...registerWebSite('title', { required: t('validation.required') })} disabled={!canEdit} />
            <ErrorMessage
              name="title"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('website.description')}>
            <Textarea {...registerWebSite('description', { required: t('validation.required') })} disabled={!canEdit} />
            <ErrorMessage
              name="description"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.image_url')}>
            <Controller
              control={controlWebsite}
              name="image_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="image_url"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderMiscSettings = () => {
    return (
      <DescBlock title={t('hub-settings.misc')} description={t('hub-settings.misc.desc')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-0">
                <FieldGroup label={t('hub-settings.cookie-banner')} caption={t('hub-settings.cookie-banner.desc')}>
                  {' '}
                </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.cookie_banner_enabled"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}
        </Block>
      </DescBlock>
    )
  }

  const renderLinksSettings = () => {
    return (
      <DescBlock title={t('hub-settings.game-hub-links')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <FieldGroup label={t('hub-settings.support-url')}>
              <Input
                disabled={!canEdit}
                {...registerWebSite('config.website_links.customer_support_link', {
                  pattern: {
                    value: /^(https?:\/\/)/,
                    message: t('validation.invalid_url'),
                  },
                })}
              />
              <ErrorMessage
                name="url"
                errors={errorsWebSite}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>
          )}
        </Block>
      </DescBlock>
    )
  }

  const renderDemoSettings = () => {
    if (game?.sandbox || !isAdmin) {
      return <></>
    }

    return (
      <DescBlock title={t('hub-settings.danger-zone')} description={t('hub-settings.danger-zone.desc')}>
        <Block>
          {isLoading ? (
            <SpinnerAghanim />
          ) : (
            <>
              {published_at ? (
                <DangerousButton
                  onClick={() => openConfirmUnPublish()}
                  btnText={t('game-edit-dialog.website.unpublish.button')}
                  title={t('game-edit-dialog.website.unpublish.title')}
                  subTitle={t('game-edit-dialog.website.unpublish.subtitle')}
                  color={'error'}
                />
              ) : accountDetails?.verify_state === VerifyState.Verified ? (
                <DangerousButton
                  color={'primary'}
                  onClick={() => openConfirmPublish()}
                  btnText={t('game-edit-dialog.website.publish.button')}
                  title={t('game-edit-dialog.website.publish.title')}
                  subTitle={t('game-edit-dialog.website.publish.subtitle')}
                />
              ) : (
                <Callout icon={AlertHexagon} variant="error">
                  {t('game-edit-dialog.website.publish.unverified')}
                </Callout>
              )}
            </>
          )}
        </Block>
      </DescBlock>
    )
  }

  const openConfirmPublish = useModal(props => (
    <ModalConfirm
      color={'primary'}
      cancelButtonText={t('game-edit-dialog.website.publish-dialog.cancel')}
      confirmButtonText={t('game-edit-dialog.website.publish-dialog.confirm')}
      subMessage={t('game-edit-dialog.website.publish-dialog.subtitle')}
      message={t('game-edit-dialog.website.publish-dialog.title')}
      onConfirm={() => {
        setValueWebsite('published_at', getNow(), { shouldDirty: true })
      }}
      showClose={true}
      {...props}
    />
  ))

  const openConfirmUnPublish = useModal(props => (
    <ModalConfirm
      color="danger"
      cancelButtonText={t('game-edit-dialog.website.unpublish-dialog.cancel')}
      confirmButtonText={t('game-edit-dialog.website.unpublish-dialog.confirm')}
      subMessage={t('game-edit-dialog.website.unpublish-dialog.subtitle')}
      message={t('game-edit-dialog.website.unpublish-dialog.title')}
      onConfirm={() => {
        setValueWebsite('published_at', null as unknown as undefined, { shouldDirty: true })
      }}
      showClose={true}
      {...props}
    />
  ))

  const renderStyle = () => {
    return (
      <DescBlock title={t('hub-settings.style')} description={t('hub-settings.style.desc')}>
        <Block>
          <FieldGroup label={t('hub-settings.theme')} tooltip={t('hub-settings.theme.tooltip')}>
            <Controller
              control={controlWebsite}
              name="config.mode"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(Mode)
                    .filter(it => it !== Mode.Auto)
                    .map(it => ({
                      children: t(`hub-settings.theme.${it}`),
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.favicon_url')}>
            <Controller
              control={controlWebsite}
              name="config.favicon_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.favicon_url"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
          <FieldGroup label={t('hub-settings.base_bg_image')}>
            <Controller
              control={controlWebsite}
              name="config.base_bg_image"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.base_bg_image"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.font')}>
            <Controller
              control={controlWebsite}
              name="config.font"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(WebsiteFont)
                    .sort()
                    .map(it => ({
                      children: FONT_NAMES[it],
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>
          <FieldGroup label={t('hub-settings.events-widget-category')}>
            <Controller
              control={controlWebsite}
              name="config.events_widget_category"
              render={({ field }) => (
                <Select
                  disabled={!canEdit}
                  options={Object.values(NewsCategory)
                    .sort()
                    .map(it => ({
                      children: t(`edit-news-dialog.category.${it}`),
                      value: it,
                    }))}
                  {...field}
                />
              )}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderAgeSettings = () => {
    return (
      <DescBlock title={t('hub-settings.age')} description={t('hub-settings.age-desc')}>
        <Block>
          <FieldGroup label={t('hub-settings.image_url')}>
            <Controller
              control={controlWebsite}
              name="config.age_settings.icon_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="image_url"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('age-settings.min_age')}>
            <Input
              min={0}
              type="number"
              {...registerWebSite('config.age_settings.min_age', {
                setValueAs: value => {
                  if (value == null) {
                    return null
                  }
                  return parseInt(value)
                },
              })}
              disabled={!canEdit}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderTrademarks = () => {
    const recommendedSizeByRatio = getRecommendedSizeByRatio(imageRatio || 0)

    return (
      <DescBlock title={t('hub-settings.trademarks')} description={t('hub-settings.trademarks.desc')}>
        <Block>
          <FieldGroup label={t('hub-settings.trademarks.dev_logo')}>
            <Controller
              control={controlWebsite}
              name="config.developer.logo_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  recommendedSize={recommendedSizeByRatio}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.developer.logo_url"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.trademarks.lines')}>
            <Textarea {...registerWebSite('config.trademarks')} disabled={!canEdit} />
            <ErrorMessage
              name="config.trademarks"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderStoreSettings = () => {
    const recommendedSizeByRatio = getRecommendedSizeByRatio(imageRatio || 0)

    return (
      <DescBlock title={t('hub-settings.store-settings.title')} description={t('hub-settings.store-settings.desc')}>
        <Block>
          <FieldGroup label={t('hub-settings.store-settings.image-ratio')}>
            <Controller
              control={controlWebsite}
              name="config.store_settings.image_ratio"
              render={({ field }) => <Select options={imageRatioOptions} {...field} disabled={!canEdit} />}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.store-settings.image-place-holder')}>
            <Controller
              control={controlWebsite}
              name="config.store_settings.default_image_url"
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  recommendedSize={recommendedSizeByRatio}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name="config.store_settings.default_image_url"
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          {isSuperAdmin && (
            <FieldGroup label={'Store Item View'}>
              <Controller
                control={controlWebsite}
                name="config.store_settings.card_version"
                render={({ field }) => (
                  <Select
                    options={[
                      { value: StoreCardVersion.V1, children: 'V1' },
                      { value: StoreCardVersion.V2, children: 'V2' },
                      { value: StoreCardVersion.V3, children: 'V3' },
                    ]}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
          )}

          {canEdit && (
            <FieldGroup label={t('hub-settings.store-settings.image-size')}>
              <Controller
                control={controlWebsite}
                name="config.store_settings.image_size"
                render={({ field }) => (
                  <Select
                    options={[
                      { value: StoreImageSize.Cover, children: 'Cover' },
                      { value: StoreImageSize.Contain, children: 'Contain' },
                      { value: StoreImageSize.Fill, children: 'Fill' },
                      { value: StoreImageSize.None, children: 'None' },
                      { value: StoreImageSize.ScaleDown, children: 'Scale Down' },
                    ]}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
          )}

          {isSuperAdmin && (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-2">
                <FieldGroup caption={t('hub-settings.store-settings.show-description')}> </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.store_settings.show_item_description"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}

          {isSuperAdmin && (
            <div className="flex w-full justify-between">
              <div className="mr-4 pb-2">
                <FieldGroup caption={t('hub-settings.store-settings.show-reward-points')}> </FieldGroup>
              </div>
              <Controller
                control={controlWebsite}
                name="config.store_settings.show_reward_points_label"
                render={({ field }) => (
                  <Toggle checked={field.value} onChange={e => field.onChange(e.target.checked)} disabled={!canEdit} />
                )}
              />
            </div>
          )}

          {isSuperAdmin && (
            <>
              <FieldGroup label={t('hub-settings.store-settings.mobile-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.mobile_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={t('hub-settings.store-settings.tablet-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.tablet_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={t('hub-settings.store-settings.desktop-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.desktop_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>

              <FieldGroup label={t('hub-settings.store-settings.large-desktop-columns')}>
                <Input
                  min={0}
                  type="number"
                  {...registerWebSite('config.store_settings.large_desktop_grid_columns', {
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseInt(value)
                    },
                  })}
                />
              </FieldGroup>
            </>
          )}
          {isSuperAdmin && (
            <FieldGroup label={t('hub-settings.store-settings.store-version')}>
              <Input placeholder="v1" {...registerWebSite('config.store_settings.store_version')} disabled={!canEdit} />
            </FieldGroup>
          )}
        </Block>
      </DescBlock>
    )
  }

  const renderGlobalImageRatioSettings = () => {
    return (
      <DescBlock
        title={t('hub-settings.news-settings.image-ratio.title')}
        description={t('hub-settings.news-settings.image-ratio.desc')}
      >
        <Block>
          <FieldGroup label={t('hub-settings.news-settings.image-ratio')}>
            <Controller
              control={controlWebsite}
              name="config.news_settings.image_ratio"
              render={({ field }) => <Select options={imageRatioOptions} {...field} disabled={!canEdit} />}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderNewsSettings = () => {
    return (
      <DescBlock title={t('hub-settings.news-settings.title')} description={t('hub-settings.news-settings.desc')}>
        <Block>
          <FieldGroup label={t('hub-settings.news-settings.news-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.CompanyNews}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.CompanyNews}`}
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.news-settings.update-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.Update}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.Update}`}
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('hub-settings.news-settings.event-image')}>
            <Controller
              control={controlWebsite}
              name={`config.news_settings.default_image_urls.${NewsCategory.Event}`}
              render={({ field }) => (
                <ImageUploader
                  disabled={!canEdit}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              name={`config.news_settings.default_image_urls.${NewsCategory.Event}`}
              errors={errorsWebSite}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </Block>
      </DescBlock>
    )
  }

  const renderLeaderboardIcons = () => {
    return (
      <DescBlock title={t('hub-settings.leaderboard-icons')} description={t('hub-settings.leaderboard-icons.desc')}>
        <ImageList
          urls={leaderboardIcons || []}
          onDelete={
            canEdit
              ? delItem => {
                  setValueWebsite(
                    'config.leaderboard_icons',
                    leaderboardIcons?.filter(it => it !== delItem),
                    { shouldDirty: true },
                  )
                }
              : undefined
          }
          onAdd={
            canEdit
              ? newItem => {
                  setValueWebsite('config.leaderboard_icons', [...(leaderboardIcons || []), ...newItem], {
                    shouldDirty: true,
                  })
                }
              : undefined
          }
        />
      </DescBlock>
    )
  }

  const renderAvatars = () => {
    return (
      <DescBlock title={t('hub-settings.player-avatars')} description={t('hub-settings.player-avatars.desc')}>
        <ImageList
          urls={avatars || []}
          onDelete={
            canEdit
              ? delItem => {
                  setValueWebsite(
                    'config.avatars',
                    avatars?.filter(it => it !== delItem),
                    { shouldDirty: true },
                  )
                }
              : undefined
          }
          onAdd={
            canEdit
              ? newItem => {
                  setValueWebsite('config.avatars', [...(avatars || []), ...newItem], { shouldDirty: true })
                }
              : undefined
          }
        />
      </DescBlock>
    )
  }

  return (
    <div>
      <PageHeader
        variant="bordered"
        sticky={true}
        extra={
          canEdit && (
            <>
              {isDirty() && (
                <Button variant="outline" color="secondary" size="sm" onClick={onDiscardClick}>
                  {t('Discard')}
                </Button>
              )}
              <Button size="sm" disabled={!isDirty()} onClick={handleSaveClick}>
                {t('Save')}
              </Button>
            </>
          )
        }
      >
        {t('hub-settings')}
      </PageHeader>

      <div className="flex w-full justify-center ">
        <div style={{ maxWidth: '1164px' }} className="mt-5 flex w-full flex-col gap-12">
          {renderGeneral()}
          {renderStyle()}
          {renderAgeSettings()}
          {renderTrademarks()}
          {renderGlobalImageRatioSettings()}
          {renderStoreSettings()}
          {renderNewsSettings()}
          {renderLeaderboardIcons()}
          {renderAvatars()}
          {renderMiscSettings()}
          {renderLinksSettings()}
          {renderDemoSettings()}
          <div className="w-[200px]" />
        </div>
      </div>
    </div>
  )
}
