import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const LoyaltyPoint: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0312 16.0537C12.5747 16.0537 12.3115 15.7637 12.3115 15.2695V9.72656C12.3115 8.96387 12.6016 8.66846 13.3589 8.66846H15.2119C16.7373 8.66846 17.7202 9.62451 17.7202 11.0747C17.7202 12.5894 16.7266 13.4863 15.1528 13.4863H13.7617V15.2695C13.7617 15.7637 13.4932 16.0537 13.0312 16.0537ZM13.7617 12.3584H14.6909C15.8188 12.3584 16.2754 12.0308 16.2754 11.0962C16.2754 10.2905 15.8242 9.82861 15.0293 9.82861H13.7617V12.3584Z"
        fill="currentColor"
      />
      <path
        d="M10.8076 16C11.189 16 11.4575 15.7529 11.4575 15.3823C11.4575 15.0171 11.189 14.7646 10.8076 14.7646H8.26172V9.39893C8.26172 8.90479 7.99316 8.61475 7.53662 8.61475C7.07471 8.61475 6.81152 8.90479 6.81152 9.39893V15.0708C6.81152 15.6938 7.12842 16 7.77832 16H10.8076Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6985 1.51682C11.5211 1.1207 12.4793 1.1207 13.3018 1.51682L19.3846 4.44613C20.2072 4.84226 20.8046 5.59139 21.0077 6.48148L22.5101 13.0636C22.7132 13.9537 22.5 14.8878 21.9308 15.6016L17.7214 20.8801C17.1521 21.5938 16.2888 22.0096 15.3759 22.0096H8.62448C7.71151 22.0096 6.84822 21.5938 6.27899 20.8801L2.06957 15.6016C1.50034 14.8878 1.28713 13.9537 1.49028 13.0636L2.9926 6.48148C3.19576 5.59139 3.79318 4.84226 4.61574 4.44613L10.6985 1.51682ZM12.4341 3.31876C12.1599 3.18672 11.8405 3.18672 11.5663 3.31876L5.4835 6.24807C5.20932 6.38011 5.01018 6.62982 4.94246 6.92652L3.44014 13.5086C3.37242 13.8053 3.44349 14.1167 3.63323 14.3546L7.84265 19.6331C8.03239 19.871 8.32016 20.0096 8.62448 20.0096H15.3759C15.6802 20.0096 15.9679 19.871 16.1577 19.6331L20.3671 14.3546C20.5568 14.1167 20.6279 13.8053 20.5602 13.5086L19.0579 6.92652C18.9902 6.62982 18.791 6.38011 18.5168 6.24807L12.4341 3.31876Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

LoyaltyPoint.type = 'aghanim-icon';
