import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const RewardPointOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4999 0.711494C11.4281 0.175596 12.5717 0.175596 13.4999 0.711494L21.0262 5.05679C21.9544 5.59269 22.5262 6.58307 22.5262 7.65487V16.3455C22.5262 17.4173 21.9544 18.4076 21.0262 18.9435L13.4999 23.2888C12.5717 23.8247 11.4281 23.8247 10.4999 23.2888L2.97363 18.9435C2.04543 18.4076 1.47363 17.4173 1.47363 16.3455V7.65487C1.47363 6.58307 2.04543 5.59269 2.97363 5.05679L10.4999 0.711494ZM12.4999 2.44354C12.1905 2.26491 11.8093 2.26491 11.4999 2.44354L3.97363 6.78884C3.66423 6.96748 3.47363 7.2976 3.47363 7.65487V16.3455C3.47363 16.7027 3.66423 17.0329 3.97363 17.2115L11.4999 21.5568C11.8093 21.7354 12.1905 21.7354 12.4999 21.5568L20.0262 17.2115C20.3356 17.0329 20.5262 16.7027 20.5262 16.3455V7.65487C20.5262 7.2976 20.3356 6.96748 20.0262 6.78884L12.4999 2.44354ZM13.665 16.0537C13.2085 16.0537 12.9453 15.7637 12.9453 15.2695V9.72656C12.9453 8.96387 13.2354 8.66846 13.9927 8.66846H15.8457C17.3711 8.66846 18.354 9.62451 18.354 11.0747C18.354 12.5894 17.3604 13.4863 15.7866 13.4863H14.3955V15.2695C14.3955 15.7637 14.127 16.0537 13.665 16.0537ZM14.3955 12.3584H15.3247C16.4526 12.3584 16.9092 12.0308 16.9092 11.0962C16.9092 10.2905 16.458 9.82861 15.6631 9.82861H14.3955V12.3584ZM6.89746 16.0537C7.354 16.0537 7.61719 15.7637 7.61719 15.2695V13.2715H8.94922L10.0288 15.3984C10.2705 15.8711 10.4746 16.0322 10.856 16.0322C11.2695 16.0322 11.5757 15.7529 11.5757 15.3608C11.5757 15.2051 11.5273 15.0225 11.4468 14.8774L10.3833 12.9277C11.1997 12.584 11.6777 11.8428 11.6777 10.9136C11.6777 9.52783 10.7056 8.66846 9.13721 8.66846H7.2627C6.5 8.66846 6.16699 9.01221 6.16699 9.7749V15.2695C6.16699 15.7637 6.43555 16.0537 6.89746 16.0537ZM8.92773 12.1865H7.61719V9.85547H8.94385C9.74414 9.85547 10.2222 10.2798 10.2222 11.0264C10.2222 11.7783 9.74951 12.1865 8.92773 12.1865Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

RewardPointOutline.type = 'aghanim-icon';
