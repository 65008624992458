import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import i18next from 'i18next'
import {
  Button,
  ButtonIcon,
  ConfirmDialog,
  DrawerContent,
  DrawerFooter,
  DrawerTopbar,
  FieldGroup,
  FieldValidationMessage,
  ImageUploader,
  Input,
  Textarea,
  ToastSeverity,
  Tooltip,
  useToast,
} from '@/ui'
import { NewsSlugCaption } from '../NewsSlugCaption'
import { NewsCategory, NewsCreate, NewsRead, NewsUpdate, Website } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import { LexicalController } from '@/components/lexical'
import { getNow, getNowStartOfHour, prepareSLUG } from '@/util'
import { XClose } from '@/icons'
import { getRecommendedSizeByRatio, slugifyString, truncateString, useFeatureFlags } from '@/libs'
import { Checkbox, Select } from '@dashboard/ui'
import { SelectDateTime } from '@/components/ui/SelectDateTime'

interface NewsFormProps {
  website?: Website
  item?: NewsRead
  onSubmit: (news: NewsUpdate | NewsCreate) => void
  onClose: () => void
  createCategory?: NewsCategory
}

export const NewsForm = ({ website, item, onClose, onSubmit, createCategory }: NewsFormProps) => {
  const errorScrollRef = useRef<HTMLDivElement>(null)
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { t } = useTranslation()
  const [deleteConfirmItem, setDeleteConfirmItem] = useState<NewsRead | undefined>(undefined)
  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)
  const showToast = useToast()
  const { featureFlags } = useFeatureFlags()

  const {
    reset,
    setValue,
    formState: { errors, isDirty, isSubmitting },
    control,
    register,
    watch,
    handleSubmit,
  } = useForm<NewsRead>({
    defaultValues: {
      title: '',
      slug: '',
      datetime: getNowStartOfHour(),
      category: createCategory || NewsCategory.CompanyNews,
      html: '',
      description: '',
      image_url: '',
      tags: '',
      text: '',
      ...item,
    },
  })

  useEffect(() => {
    if (Object.keys(errors).length) {
      errorScrollRef?.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [errorScrollRef, errors])

  useEffect(() => {
    reset(item)
  }, [item])

  const [id, title, description, slug, published_at] = watch(['id', 'title', 'description', 'slug', 'published_at'])

  const recommendedSizeByRatio = getRecommendedSizeByRatio(website?.config?.news_settings?.image_ratio || 1.77)

  const publishClickConfirm = async () => {
    setConfirmPublish(null)
    let p = published_at ? undefined : getNow()
    setValue('published_at', p, { shouldDirty: false })
    await dashboardClient.v1.updateNews(companyId, gameId, item!.id, {
      published_at: p ? p : null,
    } as NewsUpdate)
  }

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<NewsRead, 'title'>) => {
    if (!item?.id) {
      setValue('slug', slugifyString(e.target.value))
    }
    field.onChange(e.target.value)
  }

  const onFormSubmit = async (data: NewsRead) => {
    try {
      await onSubmit(data)
      reset(data)
    } catch (err) {
      if ((err as { status: number }).status == 409) {
        showToast({ message: i18next.t('news.slug.error', { value: data.slug }), severity: ToastSeverity.error })
      } else {
        showToast({ message: getErrorText(err), severity: ToastSeverity.error })
      }
    }
  }

  const onSaveAndPublish = async (data: NewsRead) => {
    data.published_at = getNow()
    await onFormSubmit(data)
  }

  const onConfirmDelete = async () => {
    if (deleteConfirmItem) {
      await dashboardClient.v1.deleteNews(companyId, gameId, deleteConfirmItem.id)
      setDeleteConfirmItem(undefined)
      onClose()
    }
  }

  return (
    <>
      {confirmPublish !== null && (
        <ConfirmDialog
          color={published_at ? 'error' : 'primary'}
          message={i18next.t(published_at ? 'news.confirm.unpublish' : 'news.confirm.publish')}
          confirmButtonText={i18next.t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
          subMessage={i18next.t(published_at ? 'news.confirm.unpublish.message' : 'news.confirm.publish.message', {
            name: title || truncateString(description, 40),
          })}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={publishClickConfirm}
        />
      )}

      {deleteConfirmItem && (
        <ConfirmDialog
          color={'error'}
          confirmButtonText={i18next.t('remove')}
          subMessage={i18next.t('confirm.text', { name: item?.title })}
          onCancel={() => setDeleteConfirmItem(undefined)}
          onConfirm={onConfirmDelete}
        />
      )}

      <DrawerTopbar>
        <ButtonIcon variant="secondary-gray" size="sm" type="button" onClick={onClose}>
          <XClose />
        </ButtonIcon>
        <h2 className="mr-auto text-title-t5">{i18next.t(item?.id ? 'news.edit' : 'news-title')}</h2>
        <div className="flex gap-2.5">
          {item?.id ? (
            <Button
              variant="secondary"
              onClick={() => (published_at ? setConfirmPublish(false) : publishClickConfirm())}
              disabled={isDirty}
            >
              {i18next.t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
            </Button>
          ) : (
            <Tooltip message={i18next.t('edit-news-dialog.save-publish')}>
              <Button
                variant="secondary-brand"
                onClick={handleSubmit(onSaveAndPublish)}
                disabled={!isDirty}
                isLoading={isSubmitting}
              >
                {i18next.t('edit-news-dialog.publish')}
              </Button>
            </Tooltip>
          )}

          <Button variant="primary" onClick={handleSubmit(onFormSubmit)} disabled={!isDirty} isLoading={isSubmitting}>
            {i18next.t(item?.id ? 'Save2' : 'edit-news-dialog.save-draft')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <div ref={errorScrollRef} />

        <FieldGroup label={i18next.t('edit-news-dialog.title')}>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Input
                autoFocus
                color={errors.title && 'error'}
                value={field.value}
                onChange={e => onChangeTitle(e, field)}
              />
            )}
          />
          <ErrorMessage
            name="title"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup label={i18next.t('edit-news-dialog.description')}>
          <Textarea {...register('description')} color={errors.description && 'error'} />
        </FieldGroup>

        <FieldGroup
          tooltip={i18next.t('sku.sku-desc')}
          label={i18next.t('edit-news-dialog.slug')}
          caption={<NewsSlugCaption base={website?.url} slug={slug} />}
        >
          <Controller
            control={control}
            name="slug"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Input
                color={errors.slug && 'error'}
                value={field.value}
                onChange={e => {
                  field.onChange(prepareSLUG(e.target.value))
                }}
              />
            )}
          />
          <ErrorMessage
            name="slug"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            control={control}
            name="image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                recommendedSize={recommendedSizeByRatio}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
          />
        </FieldGroup>

        <div className="mb-5 flex gap-2">
          <div className="w-1/3">
            <FieldGroup label={t('edit-news-dialog.category')}>
              <Controller
                control={control}
                name="category"
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Select
                    {...field}
                    items={[
                      {
                        children: (
                          <>
                            <span className="text-text-quarterary-hover">{t('edit-news-dialog.category.news')} |</span>
                            &nbsp;
                            {t('edit-news-dialog.category.company_news')}
                          </>
                        ),
                        value: NewsCategory.CompanyNews,
                      },
                      {
                        children: (
                          <>
                            <span className="text-text-quarterary-hover">{t('edit-news-dialog.category.news')} |</span>
                            &nbsp;
                            {t('edit-news-dialog.category.maintenance')}
                          </>
                        ),
                        value: NewsCategory.Maintenance,
                      },
                      {
                        children: (
                          <>
                            <span className="text-text-quarterary-hover">{t('edit-news-dialog.category.news')} |</span>
                            &nbsp;
                            {t('edit-news-dialog.category.bugfix')}
                          </>
                        ),
                        value: NewsCategory.Bugfix,
                      },
                      {
                        children: t('edit-news-dialog.category.update'),
                        value: NewsCategory.Update,
                      },
                      {
                        children: t('edit-news-dialog.category.event'),
                        value: NewsCategory.Event,
                      },
                    ]}
                  />
                )}
              />
              <ErrorMessage
                name="category"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          </div>
          <div className="w-2/3">
            <FieldGroup label={t('edit-news-dialog.date')}>
              <Controller
                control={control}
                name="datetime"
                rules={{ required: t('validation.required') }}
                render={({ field }) => <SelectDateTime {...field} />}
              />
              <ErrorMessage
                name="date"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </FieldGroup>
          </div>
        </div>
        {featureFlags.article_as_promo_store_enabled && (
          <Checkbox className="mb-4" {...register('render_options.is_promo_store')}>
            {t('edit-news-dialog.is-promo-store')}
          </Checkbox>
        )}
        <FieldGroup label={i18next.t('news.content')}>
          <ErrorMessage
            name="html"
            errors={errors}
            render={({ message }) => (
              <div className="mb-1">
                <FieldValidationMessage>{message}</FieldValidationMessage>
              </div>
            )}
          />
          <LexicalController control={control} name="html" stickyTopOffset={-20} />
        </FieldGroup>
      </DrawerContent>

      {id && (
        <DrawerFooter>
          <Button onClick={() => setDeleteConfirmItem(item)} variant="error">
            {i18next.t('delete')}
          </Button>
        </DrawerFooter>
      )}
    </>
  )
}
