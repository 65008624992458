import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const InfoCircle: AghanimIcon = forwardRef(function InfoCircle({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        id="Icon"
        d="M8.99996 11.8337V9.00033M8.99996 6.16699H9.00704M16.0833 9.00033C16.0833 12.9123 12.912 16.0837 8.99996 16.0837C5.08794 16.0837 1.91663 12.9123 1.91663 9.00033C1.91663 5.08831 5.08794 1.91699 8.99996 1.91699C12.912 1.91699 16.0833 5.08831 16.0833 9.00033Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});

InfoCircle.type = 'aghanim-icon';
