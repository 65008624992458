import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { cn, useCountryGroups, useSearchFilter } from '@dashboard/ui'
import { Platform, PriceTemplateRead } from '@/api/dashboard'
import {
  PriceTemplateExtendedPrice,
  PriceTemplateFormData,
  PriceTemplateRowDivider,
  PriceTemplateRowPrice,
  PriceTemplateRowPriceHeader,
  PriceTemplateTableFilters,
  PriceTemplatesEditFilter,
  findPriceIndex,
  getCurrencyName,
  getExtendedPrices,
} from 'src/layouts/sku-price-templates'
import { dashboardClientV1 } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { getPriceMinorUnitFactor } from '@/util'
import { getTaxesOptions } from '@/layouts/tax'

interface PriceTemplateTableProps {
  template: PriceTemplateRead
}

export const PriceTemplateTable = ({ template }: PriceTemplateTableProps) => {
  const { t } = useTranslation('countries')
  const { companyId, gameId } = useGenerateCompanyPath()
  const countryGroups = useCountryGroups()
  const filter = useSearchFilter<PriceTemplatesEditFilter>(undefined, { type: 'memory', throttleMs: 0 })

  const { data: taxes = [] } = useQuery(getTaxesOptions())
  const { watch, control, setValue } = useFormContext<PriceTemplateFormData>()

  const prices = useWatch({ control, name: 'prices' })
  const [_state, price, category] = watch(['_state', 'price', 'category'])

  const countries = useMemo(() => prices.map(price => price.country), [prices])
  const currencies = useMemo(() => [...new Set(prices.map(price => price.currency))], [prices])

  const extendedPrices = useMemo(
    () =>
      getExtendedPrices(prices, { initial: _state.initialPrices, configured: template.prices, category })
        .filter(price => {
          if (filter.values.q) {
            return (
              price.country?.toLowerCase().includes(filter.values.q.toString().toLowerCase()) ||
              t(price.country as string)
                .toLowerCase()
                .includes(filter.values.q.toString().toLowerCase()) ||
              price.currency?.toLowerCase().includes(filter.values.q.toString().toLowerCase()) ||
              getCurrencyName(price.currency, 'en')?.toLowerCase().includes(filter.values.q.toString().toLowerCase()) ||
              (price.price / getPriceMinorUnitFactor(price.currency)).toString().startsWith(filter.values.q.toString())
            )
          }
          return true
        })
        .filter(price => {
          if (filter.values.country) {
            return filter.values.country
              .toString()
              .toLowerCase()
              .includes(price.country?.toString().toLowerCase() || '')
          }
          return true
        })
        .filter(price => {
          if (filter.values.currency) {
            return filter.values.currency
              .toString()
              .toLowerCase()
              .includes(price.currency?.toString().toLowerCase() || '')
          }
          return true
        })
        .filter(price => {
          if (filter.values.tax) {
            const taxJson = (filter.values.tax as string).split('|')
            return taxJson.some(country => price.country === country)
          }
          return true
        }),
    [t, _state.initialPrices, template, filter.values, prices, category],
  )

  /**
   * Change the price value.
   * @param price
   */
  const onChangePrice = useCallback(
    (price: PriceTemplateExtendedPrice) => (value: number, platform?: Platform) => {
      const foundPriceIndex = prices.findIndex(findPriceIndex(price, platform))
      if (foundPriceIndex > -1) {
        setValue(`prices.${foundPriceIndex}.price`, value, { shouldDirty: true })
        setValue(`prices.${foundPriceIndex}.is_custom`, true, {
          shouldDirty: true,
        })
      }
    },
    [prices, setValue],
  )

  /**
   * Reset the price to the default (`getDefaultPriceTemplate()`) value.
   * @param price
   */
  const onResetPrice = useCallback(
    (editedPrice: PriceTemplateExtendedPrice) => async () => {
      const { data } = await dashboardClientV1.v1.getDefaultTemplatePrices(companyId, gameId, {
        price: Math.trunc(price * 100),
        category,
      })
      const replace = data.find(price => price.country === editedPrice.country)
      const foundPriceIndex = prices.findIndex(findPriceIndex(editedPrice))

      if (foundPriceIndex > -1) {
        replace && setValue(`prices.${foundPriceIndex}.price`, replace?.price, { shouldDirty: true })
        setValue(`prices.${foundPriceIndex}.is_custom`, false, { shouldDirty: true })
      }
    },
    [companyId, gameId, prices, price, category, setValue],
  )

  /**
   * Render the price row.
   * @param price
   * @returns
   */
  const renderPriceRow = useCallback(
    (price: PriceTemplateExtendedPrice) => {
      return (
        <PriceTemplateRowPrice
          price={price}
          view={{ category, showCustomBadge: _state.adjusts }}
          tax={taxes.find(tax => tax.country === price.country)}
          onChange={onChangePrice(price)}
          onReset={onResetPrice(price)}
        />
      )
    },
    [taxes, _state.adjusts, category, onChangePrice, onResetPrice],
  )

  /**
   * Render the table with the prices grouped by country.
   * @returns
   */
  const renderCountryTable = useCallback(() => {
    const regionPriceGroups = countryGroups
      .map(group => ({
        ...group,
        values: extendedPrices
          .filter(price => group.values.includes(price.country))
          .sort((a, b) => {
            return a.currency.localeCompare(b.currency)
          }),
      }))
      .filter(region => region.values.length > 0)

    return regionPriceGroups.map(region => (
      <Fragment key={`item-region-${region.label}`}>
        <PriceTemplateRowDivider>{region.label}</PriceTemplateRowDivider>
        {region.values.map(price => (
          <Fragment key={`item-region-price-row-${price.country}-${price.currency}`}>{renderPriceRow(price)}</Fragment>
        ))}
      </Fragment>
    ))
  }, [countryGroups, extendedPrices, renderPriceRow])

  return (
    <div className={cn(_state.loading && 'animate-pulse cursor-progress')}>
      <div className={cn('transition-opacity', _state.loading && 'pointer-events-none opacity-20')}>
        <PriceTemplateTableFilters countries={countries} currencies={currencies} filter={filter} />
        <PriceTemplateRowPriceHeader category={category} />
        <div role="listbox">{renderCountryTable()}</div>
      </div>
    </div>
  )
}
