import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

interface RangeOfferMutate {
  id: string
}

export const useRangeOfferDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: RangeOfferMutate) => dashboardClient.v1.deleteRangeOffer(companyId, gameId, id),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['range_offer', gameId, variables.id] })
      queryClient.invalidateQueries({ queryKey: ['range_offers', gameId], exact: false })
    },
  })
}
