import { Skeleton } from '@dashboard/ui'

export const ExpertItemsSkeleton = () => {
  return (
    <>
      {new Array(3).fill('').map((_, index) => (
        <section key={`skeleton-${index}`} className="mb-4 flex flex-row gap-4">
          <Skeleton className="h-48" />
          <Skeleton className="h-48" />
        </section>
      ))}
    </>
  )
}
