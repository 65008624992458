import { AghanimIcon, IconBase } from '../IconBase';
import { forwardRef } from 'react';

export const FolderOutlineIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.96 10.25C21.91 9.69 21.81 9.17 21.56 8.68C21.18 7.93 20.57 7.32 19.82 6.94C19.33 6.69 18.81 6.59 18.25 6.54C17.71 6.5 17.05 6.5 16.24 6.5H13.35L12.81 5.16L12.79 5.12L12.74 5.02C12.5 4.55 12.28 4.12 11.94 3.79C11.65 3.5 11.29 3.28 10.9 3.15C10.45 3 9.97 3 9.44 3H5.84C5.37 3 4.96 3 4.62 3.03C4.26 3.06 3.89 3.12 3.53 3.3C3.01 3.57 2.58 3.99 2.31 4.51C2.12 4.87 2.06 5.24 2.03 5.59C2 5.93 2 6.34 2 6.81V15.24C2 16.05 2 16.71 2.04 17.25C2.09 17.81 2.19 18.33 2.44 18.82C2.82 19.57 3.43 20.18 4.18 20.56C4.67 20.81 5.19 20.91 5.75 20.96C6.29 21 6.95 21 7.76 21H16.24C17.05 21 17.71 21 18.25 20.96C18.81 20.91 19.33 20.81 19.82 20.56C20.57 20.18 21.18 19.57 21.56 18.82C21.81 18.33 21.91 17.81 21.96 17.25C22 16.71 22 16.05 22 15.24V12.26C22 11.45 22 10.79 21.96 10.25ZM4 6.81C4 6.31 4 6 4.02 5.76C4.04 5.53 4.07 5.45 4.08 5.43C4.16 5.28 4.28 5.16 4.43 5.09C4.46 5.07 4.54 5.04 4.78 5.02C5.02 5 5.34 5 5.84 5H9.44C10.04 5 10.18 5.02 10.25 5.04C10.37 5.08 10.47 5.15 10.55 5.23C10.61 5.28 10.68 5.39 10.95 5.92L10.98 5.99L11.19 6.5H7.76C6.95 6.5 6.29 6.5 5.75 6.54C5.19 6.59 4.67 6.69 4.18 6.94C4.12 6.97 4.06 7 4 7.04V6.81ZM20 15.24C20 16.08 20 16.65 19.96 17.09C19.93 17.53 19.86 17.75 19.78 17.91C19.59 18.28 19.28 18.59 18.91 18.78C18.75 18.86 18.53 18.93 18.09 18.96C17.65 19 17.08 19 16.24 19H7.76C6.92 19 6.35 19 5.91 18.96C5.47 18.93 5.25 18.86 5.09 18.78C4.72 18.59 4.41 18.28 4.22 17.91C4.14 17.75 4.07 17.53 4.04 17.09C4 16.65 4 16.08 4 15.24V12.26C4 11.42 4 10.85 4.04 10.41C4.07 9.97 4.14 9.75 4.22 9.59C4.41 9.22 4.72 8.91 5.09 8.72C5.25 8.64 5.47 8.57 5.91 8.54C6.35 8.5 6.92 8.5 7.76 8.5H16.24C17.08 8.5 17.65 8.5 18.09 8.54C18.53 8.57 18.75 8.64 18.91 8.72C19.28 8.91 19.59 9.22 19.78 9.59C19.86 9.75 19.93 9.97 19.96 10.41C20 10.85 20 11.42 20 12.26V15.24Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

FolderOutlineIcon.type = 'aghanim-icon';
