import { ReactNode } from 'react'
import { cn } from '@dashboard/ui'

interface FieldSectionProps {
  children: ReactNode
  label: ReactNode
  caption?: ReactNode
  hasGap?: boolean
}

// TODO: move to @dashboard/ui
export const FieldSection = ({ children, label, caption, hasGap = false }: FieldSectionProps) => {
  return (
    <div className="flex justify-between gap-5">
      <div className="flex w-[280px] shrink-0 flex-col">
        <span className="mb-0.5 text-caption-md font-semibold text-text-secondary">{label}</span>
        {caption && <span className="text-caption-sm font-normal text-text-tertiary">{caption}</span>}
      </div>
      <div className={cn('flex w-full flex-1 flex-col overflow-hidden pl-0.5', hasGap && 'gap-3')}>{children}</div>
    </div>
  )
}
