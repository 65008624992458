import { HTMLAttributes, ReactNode } from 'react'
import { cn } from '@dashboard/ui'

interface ContrastSurfaceProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

export const ContrastSurface = ({ children, ...rest }: ContrastSurfaceProps) => {
  return (
    <div {...rest} className={cn('rounded-lg bg-black/50 p-1 text-text-inverse backdrop-blur-md', rest.className)}>
      {children}
    </div>
  )
}
