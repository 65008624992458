import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from '@dashboard/ui'
import { TableZeroState } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'
import { SKU_PRICE_TEMPLATES_CREATE_PATH } from '../paths'

export const PriceTemplateZeroState = () => {
  const { generatePath } = useGenerateCompanyPath()
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'zero-state' })
  return (
    <TableZeroState
      title={t('title')}
      message={t('text')}
      buttons={
        <Link to={generatePath(SKU_PRICE_TEMPLATES_CREATE_PATH)}>
          <Button>{t('action-add')}</Button>
        </Link>
      }
    />
  )
}
