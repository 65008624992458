import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemRead, ItemType } from '@/api/dashboard'
import { PaginationInfiniteObserver, Table, TableCell, TableCellBulkCheckbox, TableRow, TableZeroState } from '@/ui'
import {
  Button,
  CloseOutline,
  InputSearch,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  SelectFilter,
  Skeleton,
  Spinner,
  cn,
} from '@dashboard/ui'
import { useDebounce } from '@/hooks'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { useBulkSelectGeneric } from '@/libs/hooks/useBulkSelectGeneric'
import { useInfiniteGameItems } from '@/api/useInfiniteGameItems'
import { motion } from 'framer-motion'
import { dashboardClient } from '@/api'
import { SKUItem } from '@/api/types'
import { useQuery } from '@tanstack/react-query'
import { itemCategoriesQuery } from '@/layouts/item-category'
import { useGenerateCompanyPath } from '@/libs'
import { rarityQuery } from '@/layouts/sku-settings/api/rarityQuery'

// @ts-ignore
interface ItemsPickerProps extends ModalProps {
  title: string
  limit?: number
  picked?: SKUItem[]
  onSubmit: (picked: SKUItem[]) => void
  types?: ItemType[]
  buttonText?: string
  hideIds?: string[]
  isStackable?: boolean
  withPrice?: boolean
}

export const SelectSkuModal = ({
  title,
  limit,
  types,
  picked = [],
  onSubmit,
  buttonText,
  hideIds,
  isStackable,
  withPrice,
  ...rest
}: ItemsPickerProps) => {
  const { t } = useTranslation()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { companyId, gameId } = useGenerateCompanyPath()

  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
    refetchOnMount: false,
  })

  const [isAllFetching, setIsAllFetching] = useState<boolean | null>(null)
  const [filter, setFilter] = useState<{
    search: string
    rarity: string
  }>({
    search: '',
    rarity: '',
  })

  const hasFilter = filter.search || filter.rarity

  const debouncedSearch = useDebounce(filter.search, 500)

  const moveFocus = (direction: 'up' | 'down') => {
    const focusedElement = document.activeElement

    const rows = document.querySelectorAll('div[id^="sku_row/"]') as unknown as HTMLDivElement[]

    const idx = Array.from(rows).findIndex(row => row.id === focusedElement?.id)
    if (idx === -1) {
      rows[0]?.focus()
    } else {
      if (direction === 'up') {
        rows[idx - 1]?.focus()
      } else {
        rows[idx + 1]?.focus()
      }
    }
  }

  const {
    items: allItems,
    isLoading,
    fetchNextPage,
    hasNext,
    loadAll,
  } = useInfiniteGameItems(debouncedSearch, types, isStackable, withPrice, filter.rarity)

  const { selected, onSelect, onReset } = useBulkSelectGeneric(
    picked?.map(it => it.id),
    allItems.map(it => it.id),
  )

  const { data: rarityItems = [] } = useQuery({
    ...rarityQuery(companyId, gameId),
  })

  const onKeyDown = (ev: KeyboardEvent) => {
    switch (ev.key) {
      case 'ArrowDown':
        moveFocus('down')
        break
      case 'ArrowUp':
        moveFocus('up')
        break
      case ' ':
        const focusedElement = document.activeElement
        if (!focusedElement) {
          return
        }
        const arr = focusedElement.id.split('/')
        const id = arr[arr.length - 1]
        if (!id) {
          return
        }
        onSelect(id)
        ev.preventDefault()
        break
      case 'Enter':
        onSaveClick()
        break
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)

    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  const getCategoryName = (item: SKUItem) => {
    if (isLoading) {
      return <div className="h-[24px] w-1/2 animate-pulse rounded bg-fg-primary-alt" />
    }

    if (!item.categories?.length) {
      return '-'
    }

    return categories
      .filter(category => item.categories?.includes(category.id))
      .map(category => category.name)
      .join(', ')
  }

  const confirmSubmit = async () => {
    const allItemsWithPicked = [...picked, ...allItems]

    const notFindedItems = selected.filter(id => !allItemsWithPicked.find(it => it.id === id))
    if (notFindedItems.length > 0) {
      const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
        ids: notFindedItems.join(','),
      })

      allItemsWithPicked.push(...data)
    }

    let update = selected.map(id => allItemsWithPicked.find(it => it.id === id)).filter(it => it) as ItemRead[]

    onSubmit(update)
    rest.onClose?.()
  }

  const onSaveClick = () => {
    setIsSubmitting(true)
    confirmSubmit()
  }

  const isAllSelected = () => {
    if (selected.length == 0) {
      return false
    }

    if (hasFilter) {
      const notExists = allItems.find(it => !selected.includes(it.id))
      if (notExists) {
        return false
      }
      return true
    }
    return allItems.length > 0 && selected.length == allItems.length
  }

  const onClickAllCheckBox = async () => {
    if (isAllSelected()) {
      if (hasFilter) {
        onReset(selected.filter(id => !allItems.find(it => it.id === id)))
      } else {
        onReset([])
      }
      return
    }

    if (isAllFetching === null) {
      setIsAllFetching(true)
    }
    try {
      const all = await loadAll()
      if (hasFilter) {
        const set = new Set([...selected, ...all.map(it => it.id)])
        onReset([...set])
      } else {
        onReset(all.map(it => it.id))
      }
    } finally {
      setIsAllFetching(false)
    }
  }

  const renderTable = () => {
    if (isLoading && allItems.length == 0) {
      return new Array(10).fill('').map((_, index) => <Skeleton className="mb-2 h-[32px]" key={index} />)
    }

    return (
      <Table>
        <TableRow variant="header" className="mb-1 focus-visible:outline-none">
          {isAllFetching ? (
            <TableCell width="auto">
              <Spinner className="text-border-brand" size={18} />
            </TableCell>
          ) : (
            <TableCellBulkCheckbox
              selected={isAllSelected()}
              disabled={allItems.length === 0}
              onChange={onClickAllCheckBox}
            />
          )}
          <TableCell width="100%">{t('sku.item.product-name')}</TableCell>
          <TableCell width="25%">{t('sku.item.category')}</TableCell>
          {rarityItems.length > 0 && <TableCell width="25%">{t('item-props.sku.properties.rarity')}</TableCell>}
        </TableRow>
        {allItems
          .filter(it => !hideIds?.includes(it.id))
          .map((it, idx) => {
            const checked = !!selected.find(pickedItem => pickedItem === it.id)
            const disabled = !checked && !!(limit && selected.length >= limit)

            return (
              <TableRow
                tabIndex={idx}
                key={it.id}
                data-testid={`select-sku-modal/${it.id}`}
                onClick={() => {
                  onSelect(it.id)
                }}
                id={`sku_row/${it.id}`}
                className={cn('cursor-pointer select-none ', checked && 'bg-[#0EA5E91A]')}
              >
                <TableCellBulkCheckbox
                  selected={checked}
                  disabled={disabled}
                  onChange={() => {
                    onSelect(it.id)
                  }}
                />
                <TableCell className="flex items-center gap-3" width="100%">
                  <SkuImageName item={it} />
                </TableCell>
                <TableCell width="25%">{getCategoryName(it)}</TableCell>
                {rarityItems.length > 0 && (
                  <TableCell width="25%">{(it as ItemRead).item_rarity?.name || '-'}</TableCell>
                )}
              </TableRow>
            )
          })}
      </Table>
    )
  }

  return (
    <Modal {...rest} size="xl">
      <ModalContent className="h-[600px]">
        <ModalTitle>{title}</ModalTitle>
        <div className="sticky top-[55px] z-10 flex h-fit w-full gap-2 bg-fg-primary py-3">
          <InputSearch
            autoFocus
            value={filter.search}
            onChange={e => {
              setFilter({
                ...filter,
                search: e.target.value,
              })
            }}
            placeholder={t('search')}
          />
          {rarityItems.length > 0 && (
            <SelectFilter
              items={rarityItems.map(r => ({
                value: r.id,
                children: r.name,
              }))}
              multiple={false}
              params={{ search: false }}
              value={filter.rarity}
              onChange={value => {
                setFilter({
                  ...filter,
                  rarity: value ? (value as string) : '',
                })
              }}
            >
              {t('item-props.sku.properties.rarity')}
            </SelectFilter>
          )}
        </div>

        {allItems.length === 0 && !isLoading && hasFilter ? (
          <TableZeroState
            contentClassName="py-4 "
            className="h-[450px] py-4"
            title={t('sku.search.zero.title')}
            message={t('sku.search.zero.text')}
            buttons={
              <Button
                size="sm"
                color="secondary"
                variant="outline"
                onClick={() => {
                  setFilter({
                    rarity: '',
                    search: '',
                  })
                }}
              >
                <CloseOutline size={14} />
                <span>{t('sku.search.clear-filters')}</span>
              </Button>
            }
          />
        ) : (
          renderTable()
        )}

        {isLoading && allItems.length > 0 && (
          <motion.div className="flex shrink-0 justify-center py-4 text-text-disabled">
            <Spinner size={16} />
          </motion.div>
        )}

        {hasNext && !isLoading && <PaginationInfiniteObserver onNextPage={fetchNextPage} />}
      </ModalContent>
      <ModalFooter>
        <Button
          size="sm"
          type="button"
          color="secondary"
          variant="outline"
          onClick={() => {
            rest.onClose?.()
          }}
        >
          {t('Cancel')}
        </Button>
        <Button size="sm" onClick={onSaveClick} loading={isSubmitting}>
          {buttonText || t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
