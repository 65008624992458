import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Country: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M5.20711 2.87868C5.76972 2.31607 6.53278 2 7.32843 2H9C9.55228 2 10 2.44772 10 3C10 3.55228 9.55228 4 9 4H7.32843C7.06321 4 6.80886 4.10536 6.62132 4.29289L4.29289 6.62132C4.10536 6.80886 4 7.06321 4 7.32843V11.1716C4 11.4368 4.10536 11.6911 4.29289 11.8787L5.12132 12.7071C5.68393 13.2697 6 14.0328 6 14.8284V17.6716C6 17.9368 6.10536 18.1911 6.29289 18.3787L7.62132 19.7071C7.80886 19.8946 8.06321 20 8.32843 20H15.5585C15.9889 20 16.3711 19.7246 16.5072 19.3162L16.8343 18.3348C17.0453 17.7019 17.4612 17.1574 18.0163 16.7873L19.5547 15.7617C19.8329 15.5762 20 15.264 20 14.9296V11C20 10.4477 20.4477 10 21 10C21.5523 10 22 10.4477 22 11V14.9296C22 15.9327 21.4987 16.8694 20.6641 17.4258L19.1257 18.4514C18.9406 18.5748 18.802 18.7563 18.7317 18.9672L18.4045 19.9487C17.9962 21.1737 16.8498 22 15.5585 22H8.32843C7.53278 22 6.76972 21.6839 6.20711 21.1213L4.87868 19.7929C4.31607 19.2303 4 18.4672 4 17.6716V14.8284C4 14.5632 3.89464 14.3089 3.70711 14.1213L2.87868 13.2929C2.31607 12.7303 2 11.9672 2 11.1716V7.32843C2 6.53278 2.31607 5.76972 2.87868 5.20711L5.20711 2.87868Z"
        fill="currentColor"
      />
      <path
        d="M11 15.5C11 16.3284 10.3284 17 9.5 17C8.67157 17 8 16.3284 8 15.5C8 14.6716 8.67157 14 9.5 14C10.3284 14 11 14.6716 11 15.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.5C20 8.62461 18.5261 10.1965 17.2558 11.5512C16.6867 12.1581 16.1584 12.7214 15.822 13.2713C15.7133 13.4489 15.2867 13.4489 15.178 13.2713C14.8416 12.7214 14.3133 12.1581 13.7442 11.5512C12.4739 10.1965 11 8.62461 11 6.5C11 4.01472 13.0147 2 15.5 2C17.9853 2 20 4.01472 20 6.5ZM17 6.5C17 7.32843 16.3284 8 15.5 8C14.6716 8 14 7.32843 14 6.5C14 5.67157 14.6716 5 15.5 5C16.3284 5 17 5.67157 17 6.5Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Country.type = 'aghanim-icon';
