import { useCallback } from 'react'
import { Blocker } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'
import { useGenerateCompanyPath } from '@/libs'
import { deletePriceTemplateItemMutation } from '../api'

interface PriceTemplateModalConfirmCloseProps extends ModalProps {
  blocker: Blocker
}

export const PriceTemplateModalConfirmClose = ({ blocker, ...rest }: PriceTemplateModalConfirmCloseProps) => {
  const ql = useQueryClient()
  const { t } = useTranslation('ui', { keyPrefix: 'unsaved-changes-modal' })
  const { companyId, gameId } = useGenerateCompanyPath()

  const { mutateAsync } = useMutation(deletePriceTemplateItemMutation(companyId, gameId, ql))

  const onClickCancel = useCallback(() => {
    blocker.reset?.()
    rest.onClose()
  }, [blocker, mutateAsync])

  const onClickConfirm = useCallback(() => {
    blocker.proceed?.()
    rest.onClose()
  }, [blocker, mutateAsync])

  return (
    <ModalPresetConfirm
      {...rest}
      title={{ children: t('title'), subtitle: t('subtitle') }}
      cancel={{ label: t('cancel'), callback: onClickCancel }}
      submit={{ label: t('submit'), color: 'danger', callback: onClickConfirm }}
    />
  )
}
