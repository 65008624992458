import { KeyboardEvent, MouseEvent, ReactNode, forwardRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { cva } from 'class-variance-authority'
import { cn } from '@dashboard/ui'

interface TableRowProps {
  children: ReactNode
  variant?: 'default' | 'header' | 'clickable'
  to?: string
  id?: string
  className?: string
  a11y?: {
    onClick: (e: MouseEvent) => void
  }
  onClick?: () => void
  datatestid?: string
  tabIndex?: number
}

const variants = cva('group/table-row flex items-center border-b border-border-secondary text-left transition-colors', {
  variants: {
    variant: {
      default: "font-medium text-text-secondary last:border-none [&[href*='/']]:hover:bg-fg-secondary",
      clickable: 'cursor-pointer hover:bg-fg-secondary',
      header: 'sticky left-0 top-0 z-[1] -mx-2 h-11 bg-fg-primary px-2 text-xs font-semibold text-text-primary',
    },
    defaultVariants: {
      variant: 'default',
    },
  },
})

export const TableRow = forwardRef<HTMLDivElement | HTMLAnchorElement, TableRowProps>(
  ({ children, datatestid, variant = 'default', to, className, a11y, id, onClick, tabIndex }, ref) => {
    const a11yProps = useMemo(() => {
      return a11y
        ? {
            tabIndex: 0,
            role: 'option',
            onClick: a11y?.onClick,
            onKeyDown: (e: KeyboardEvent) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                a11y?.onClick(e as unknown as MouseEvent)
              }
            },
          }
        : {}
    }, [a11y])

    if (to) {
      return (
        <Link
          data-testid={datatestid}
          className={cn(
            variants({ variant }),
            'focus-visible:outline-border-brand',
            'focus-visible:z-10',
            'focus-visible:rounded-md',
            'focus-visible:border-transparent',
            className,
          )}
          to={to}
          tabIndex={tabIndex || -1}
          id={id}
          onClick={onClick}
          ref={ref as React.Ref<HTMLAnchorElement>}
          {...a11yProps}
        >
          {children}
        </Link>
      )
    }

    return (
      <div
        data-testid={datatestid}
        className={cn(
          variants({ variant }),
          'outline-none outline-2 outline-offset-0 transition-all',
          'focus-visible:outline-border-brand',
          'focus-visible:z-10',
          'focus-visible:rounded-md',
          'focus-visible:border-transparent',
          'has-[input:focus]:outline-border-brand',
          'has-[input:focus]:z-10',
          'has-[input:focus]:rounded-md',
          'has-[input:focus]:border-transparent',
          className,
        )}
        tabIndex={tabIndex || -1}
        id={id}
        onClick={onClick}
        ref={ref as React.Ref<HTMLDivElement>}
        {...a11yProps}
      >
        {children}
      </div>
    )
  },
)

TableRow.displayName = 'TableRow'
