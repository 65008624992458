import { forwardRef } from 'react'
import { AghanimGameSchemasGame } from '@/api/dashboard'
import { ChevronSelectorVertical } from '@/icons'
import { Skeleton, cn } from '@dashboard/ui'

interface SidebarGameButtonProps {
  game?: AghanimGameSchemasGame
  variant?: 'default' | 'disabled'
}

export const SidebarGameButton = forwardRef<HTMLDivElement, SidebarGameButtonProps>(function SidebarGameButton(
  { game, variant = 'default', ...rest },
  ref,
) {
  const interactProps =
    variant === 'default'
      ? "cursor-pointer transition-all [&:not([data-open='true'])]:hover:shadow-xs [&:not([data-open='true'])]:hover:ring-1 [&:not([data-open='true'])]:hover:ring-border-primary-hover [&[data-open='true']]:shadow-sm [&[data-open='true']]:ring-1"
      : 'border border-primary-hover'

  return (
    <div
      data-testid="game-switch-menu"
      {...rest}
      ref={ref}
      className={cn(
        'group flex h-10 w-full  items-center overflow-hidden rounded-md bg-bg-secondary p-1',
        interactProps,
      )}
    >
      {game ? (
        <>
          <div className="relative mr-2 size-8 shrink-0">
            <img
              className=" size-8 shrink-0 rounded-md object-cover"
              src={game.logo_url}
              data-categories="essential"
              alt=""
            />
            {!!game.published_at && (
              <div className={cn('absolute bottom-0.5 right-0.5 size-1.5 rounded-full', 'bg-fg-lime-primary')} />
            )}
          </div>
          <div className="truncate font-medium leading-normal text-text-secondary">{game.name}</div>
          {variant === 'default' && (
            <div className="ml-auto text-text-quarterary group-hover:text-text-quarterary-hover">
              <ChevronSelectorVertical size={16} />
            </div>
          )}
        </>
      ) : (
        <>
          <Skeleton className="mr-2 size-8 shrink-0 rounded-md" />
          <Skeleton className="h-5 grow rounded-md" />
        </>
      )}
    </div>
  )
})
