import { useTranslation } from 'react-i18next'

export function ByAghanim() {
  const { t } = useTranslation()
  return (
    <div className="mt-auto flex items-center gap-2.5 text-caption-sm text-text-tertiary">
      <img className="size-[12px]" src="/icons/logo.svg" alt="logo" />
      <span>{t('campaign.by-aghanim')}</span>
    </div>
  )
}
