import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ItemSolid: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        id="Vector"
        d="M21.438 7.8754C21.2522 7.06772 20.7359 6.35853 19.9511 5.92514L13.5489 2.39892C12.5989 1.86703 11.4217 1.86703 10.4511 2.39892L4.04891 5.92514C3.09891 6.45703 2.5 7.42231 2.5 8.48609V15.5385C2.5 16.5826 3.09891 17.5676 4.04891 18.0798L10.4511 21.606C10.9467 21.8818 11.463 22 12 22C12.3304 22 12.6402 21.9606 12.95 21.8621C13.1565 21.803 13.363 21.7242 13.5489 21.606L19.9511 18.0798C20.9217 17.5676 21.5 16.5826 21.5 15.5385V8.48609C21.5 8.26939 21.4793 8.0724 21.438 7.8754ZM13.0326 11.441C12.7228 11.6183 12.3304 11.6183 12 11.441L7.04348 8.70278C6.79565 8.56489 6.46521 8.64368 6.32065 8.88008C6.19674 9.11647 6.27935 9.41197 6.52717 9.54986L11.4837 12.2881C11.7935 12.4654 12 12.7806 12 13.1352V19.1436C12 19.5967 11.4837 19.8921 11.05 19.6557L5.10217 16.3659C4.77174 16.1886 4.56522 15.8537 4.56522 15.5188V8.46639C4.56522 8.1118 4.77174 7.7769 5.08152 7.59961L11.4837 4.09308C11.6489 3.99458 11.8348 3.95518 12 3.95518C12.1859 3.95518 12.3717 3.99458 12.5163 4.07338L18.4848 7.38291C18.8978 7.61931 18.8978 8.19059 18.4848 8.42699L13.0326 11.4213V11.441Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

ItemSolid.type = 'aghanim-icon';
