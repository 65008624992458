import { useRef } from 'react'
import { LexicalEditor } from 'lexical'
import { calculateZoomLevel } from '@lexical/utils'
import './resizer.css'

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

const Direction = {
  east: 1 << 0,
  north: 1 << 3,
  south: 1 << 1,
  west: 1 << 2,
}

interface ImageResizerProps {
  editor: LexicalEditor
  imageRef: { current: null | HTMLElement }
  onResizeEnd: (width: string | number, height: string | number) => void
  onResizeStart: () => void
}

const innerDimensions = (node: HTMLElement) => {
  let computedStyle = getComputedStyle(node)

  let width = node.clientWidth // width with padding
  let height = node.clientHeight // height with padding

  height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
  width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
  return { height, width }
}

export const ImageResizer = ({ onResizeStart, onResizeEnd, imageRef, editor }: ImageResizerProps): JSX.Element => {
  const controlWrapperRef = useRef<HTMLDivElement>(null)
  const userSelect = useRef({ priority: '', value: 'default' })
  const positioningRef = useRef<{
    currentHeight: '100%' | number
    currentWidth: 'auto' | number
    direction: number
    isResizing: boolean
    ratio: number
    startHeight: number
    startWidth: number
    startX: number
    startY: number
  }>({
    currentHeight: 0,
    currentWidth: 0,
    direction: 0,
    isResizing: false,
    ratio: 0,
    startHeight: 0,
    startWidth: 0,
    startX: 0,
    startY: 0,
  })

  const editorRootElement = editor.getRootElement()
  const maxWidthContainer = editorRootElement ? innerDimensions(editorRootElement).width : 200
  const minWidth = 24

  const setStartCursor = (direction: number) => {
    const ew = direction === Direction.east || direction === Direction.west
    const ns = direction === Direction.north || direction === Direction.south
    const nwse =
      (direction & Direction.north && direction & Direction.west) ||
      (direction & Direction.south && direction & Direction.east)

    const cursorDir = ew ? 'ew' : ns ? 'ns' : nwse ? 'nwse' : 'nesw'

    if (editorRootElement !== null) {
      editorRootElement.style.setProperty('cursor', `${cursorDir}-resize`, 'important')
    }
    if (document.body !== null) {
      document.body.style.setProperty('cursor', `${cursorDir}-resize`, 'important')
      userSelect.current.value = document.body.style.getPropertyValue('-webkit-user-select')
      userSelect.current.priority = document.body.style.getPropertyPriority('-webkit-user-select')
      document.body.style.setProperty('-webkit-user-select', 'none', 'important')
    }
  }

  const setEndCursor = () => {
    if (editorRootElement !== null) {
      editorRootElement.style.setProperty('cursor', 'text')
    }
    if (document.body !== null) {
      document.body.style.setProperty('cursor', 'default')
      document.body.style.setProperty('-webkit-user-select', userSelect.current.value, userSelect.current.priority)
    }
  }

  const handlePointerDown = (event: React.PointerEvent<HTMLDivElement>, direction: number) => {
    if (!editor.isEditable()) {
      return
    }

    const image = imageRef.current
    const controlWrapper = controlWrapperRef.current

    if (image !== null && controlWrapper !== null) {
      event.preventDefault()
      const { width, height } = image.getBoundingClientRect()
      const zoom = calculateZoomLevel(image)
      const positioning = positioningRef.current
      positioning.startWidth = width
      positioning.startHeight = height
      positioning.ratio = width / height
      positioning.currentWidth = width
      positioning.currentHeight = height
      positioning.startX = event.clientX / zoom
      positioning.startY = event.clientY / zoom
      positioning.isResizing = true
      positioning.direction = direction

      setStartCursor(direction)
      onResizeStart()

      controlWrapper.classList.add('image-control-wrapper--resizing')
      image.style.height = `${height}px`
      image.style.width = `${width}px`

      document.addEventListener('pointermove', handlePointerMove)
      document.addEventListener('pointerup', handlePointerUp)
    }
  }

  const handlePointerMove = (event: PointerEvent) => {
    const image = imageRef.current
    const positioning = positioningRef.current

    const isHorizontal = positioning.direction & (Direction.east | Direction.west)
    const isVertical = positioning.direction & (Direction.south | Direction.north)

    if (image !== null && positioning.isResizing) {
      const zoom = calculateZoomLevel(image)
      // Corner cursor
      if (isHorizontal && isVertical) {
        let diff = Math.floor(positioning.startX - event.clientX / zoom)
        diff = positioning.direction & Direction.east ? -diff : diff

        const width = clamp(positioning.startWidth + diff, minWidth, maxWidthContainer)

        const height = width / positioning.ratio
        image.style.width = `${width}px`
        image.style.height = `auto`
        positioning.currentWidth = width
        positioning.currentHeight = height
      }
    }
  }

  const handlePointerUp = () => {
    const image = imageRef.current
    const positioning = positioningRef.current
    const controlWrapper = controlWrapperRef.current

    if (image !== null && controlWrapper !== null && positioning.isResizing) {
      const width = positioning.currentWidth
      const height = 'auto'
      positioning.startWidth = 0
      positioning.startHeight = 0
      positioning.ratio = 0
      positioning.startX = 0
      positioning.startY = 0
      positioning.currentWidth = 0
      positioning.currentHeight = 0
      positioning.isResizing = false

      controlWrapper.classList.remove('image-control-wrapper--resizing')

      setEndCursor()
      onResizeEnd(width, height)

      document.removeEventListener('pointermove', handlePointerMove)
      document.removeEventListener('pointerup', handlePointerUp)
    }
  }

  return (
    <div ref={controlWrapperRef}>
      <div
        className="image-resizer image-resizer-ne"
        onPointerDown={event => {
          handlePointerDown(event, Direction.north | Direction.east)
        }}
      />
      <div
        className="image-resizer image-resizer-se"
        onPointerDown={event => {
          handlePointerDown(event, Direction.south | Direction.east)
        }}
      />
      <div
        className="image-resizer image-resizer-sw"
        onPointerDown={event => {
          handlePointerDown(event, Direction.south | Direction.west)
        }}
      />
      <div
        className="image-resizer image-resizer-nw"
        onPointerDown={event => {
          handlePointerDown(event, Direction.north | Direction.west)
        }}
      />
    </div>
  )
}
