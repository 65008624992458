import { useIsMobile } from '@dashboard/ui'
import {
  useHubStep,
  useInviteStep,
  useLiveopsStep,
  useSkuStep,
  useVerifyAccountStep,
  useWebhookStep,
  useWebhookTestStep,
} from '../hooks'
import { GetStartedStepState } from '../types'

export const useSteps = (): Record<string, GetStartedStepState> => {
  const isMobile = useIsMobile()

  const states = {
    hub: useHubStep(),
    invite: useInviteStep(),
    sku: useSkuStep(),
    liveops: useLiveopsStep(),
    webhook: useWebhookStep(),
    webhookTest: useWebhookTestStep(),
    verifyAccount: useVerifyAccountStep(),
  }
  return Object.values(states).filter(item => {
    return item.available_on.includes(isMobile ? 'mobile' : 'desktop')
  }) as unknown as Record<string, GetStartedStepState>
}
