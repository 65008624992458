import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const DribbleIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="6 6 15 15" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 7.25C10.2736 7.25 7.25 10.2736 7.25 14C7.25 17.7264 10.2736 20.75 14 20.75C17.7191 20.75 20.75 17.7264 20.75 14C20.75 10.2736 17.7191 7.25 14 7.25ZM18.4585 10.3614C19.2638 11.3425 19.747 12.5944 19.7617 13.9488C19.5713 13.9121 17.6678 13.5241 15.7497 13.7657C15.7058 13.6706 15.6692 13.5681 15.6253 13.4656C15.5081 13.1874 15.3764 12.9018 15.2446 12.631C17.3677 11.7671 18.3341 10.5225 18.4585 10.3614ZM14 8.24566C15.4642 8.24566 16.804 8.79474 17.8216 9.69523C17.7191 9.84165 16.8479 11.0057 14.798 11.7744C13.8536 10.0393 12.8067 8.61904 12.6456 8.3994C13.0775 8.29691 13.5315 8.24566 14 8.24566ZM11.5475 8.78742C11.7012 8.9924 12.7261 10.42 13.6852 12.1185C10.9911 12.8359 8.61172 12.8213 8.35548 12.8213C8.72884 11.035 9.93682 9.54881 11.5475 8.78742ZM8.23102 14.0073C8.23102 13.9488 8.23102 13.8902 8.23102 13.8316C8.47994 13.8389 11.2766 13.8755 14.1537 13.0117C14.3221 13.3338 14.4759 13.6632 14.6223 13.9927C14.5491 14.0146 14.4685 14.0366 14.3953 14.0586C11.423 15.0176 9.84165 17.6386 9.70988 17.8582C8.79474 16.8406 8.23102 15.4862 8.23102 14.0073ZM14 19.769C12.6676 19.769 11.4376 19.3151 10.4639 18.5537C10.5664 18.3414 11.7378 16.0865 14.9883 14.9517C15.003 14.9444 15.0103 14.9444 15.0249 14.9371C15.8376 17.0382 16.167 18.8026 16.2549 19.3078C15.5594 19.6079 14.798 19.769 14 19.769ZM17.2139 18.7806C17.1554 18.4292 16.8479 16.7454 16.0938 14.6735C17.9021 14.388 19.4835 14.8566 19.6811 14.9225C19.4322 16.5258 18.5098 17.9094 17.2139 18.7806Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
