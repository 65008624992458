import { useContext, useEffect, useState } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { cn, useIsMobile, useModal } from '@dashboard/ui'
import ProfileButton from './ProfileButton'
import { canManageUsers } from '../../security'
import { CompanyGame, InviteRead, Role } from '../../api/dashboard'
import { useAuth0 } from '@auth0/auth0-react'
import { useBanner } from '@/libs/hooks'
import { MobileContext } from '@/providers'
import { AuthContext, IAuthContext } from '../../Context'
import { ToastSeverity, Toggle, Tooltip, useToast } from '@/ui'
import EditInviteDialog from '../users/EditInviteDialog'
import { initFeedbackIntegration } from '@/libs/sentry'
import { addCompanyToCache, useCurrentGame } from '@/api/useCompaniesQuery'
import { dashboardClient, getErrorText } from '@/api'
import { useQueryClient } from '@tanstack/react-query'
import { HOME_PATH } from '@/libs'
import { setLastGameId } from '@/Settings'
import { useHubGeneratingStatus } from '@/layouts/get-started/hooks'
import { useTranslation } from 'react-i18next'
import { useFeatureFlags } from '@/libs/hooks/useFeatureFlags'
import { NotificationButton } from '@/layouts/notification/NotificationButton'
import { Hamburger } from '@/components/icons/Hamburger'

const AppHeader = (props: { className?: string; hideMenuButton?: boolean }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { featureFlags } = useFeatureFlags()
  const context = useContext(AuthContext) as IAuthContext
  const { user } = useAuth0()
  const { gameId, companyId } = useParams() as { companyId: string; gameId: string }
  const { company, game, getSandboxGame, setSandboxGameState } = useCurrentGame()
  const queryClient = useQueryClient()
  const showToast = useToast()
  const location = useLocation()
  const [loadingSandbox, setLoadingSandbox] = useState(false)
  const openInviteModal = useModal<{ item: InviteRead }>(props => <EditInviteDialog {...props} />)
  const [sandBoxMode, setSandboxMode] = useState(game?.sandbox)
  const { isHubGenerated } = useHubGeneratingStatus()
  const { bannerMode } = useBanner()

  const isGetStartedPage = location.pathname.includes('get-started')
  const isMobile = useIsMobile()

  const { setLeftOpened } = useContext(MobileContext)

  useEffect(() => {
    initFeedbackIntegration(user?.email, context.customer?.locale, '#bug-report')
  }, [])

  useEffect(() => {
    setSandboxMode(!!game?.sandbox)
  }, [game])

  const handleMenuOpen = () => {
    setLeftOpened(true)
  }

  const onSandboxChanged = async (v: boolean) => {
    setSandboxMode(v)

    if (!v) {
      if (game?.live_game_id) {
        navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: game.live_game_id }))
        setSandboxGameState(game.live_game_id, false)
        setLastGameId(game.live_game_id)
      }
      return
    }

    let sandgame = getSandboxGame()
    if (sandgame) {
      setSandboxGameState(gameId, true)
      setLastGameId(sandgame.id)
      navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: sandgame.id }))
      return
    }

    try {
      setLoadingSandbox(true)
      const { data: newGame } = await dashboardClient.v1.createSandbox(companyId, gameId)
      if (game) {
        game.sandbox_game_id = newGame.id
      }
      addCompanyToCache(queryClient, {
        ...company,
        games: [newGame],
      } as CompanyGame)
      setSandboxGameState(gameId, true)
      setLastGameId(newGame.id)
      navigate(generatePath(HOME_PATH, { companyId: companyId, gameId: newGame.id }))
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
      setSandboxMode(false)
    } finally {
      setLoadingSandbox(false)
    }
  }

  const renderToggleSandbox = () => {
    return (
      <div className="flex items-center gap-2 px-4">
        {t('header.sandbox')}
        <Toggle
          date-testid="header/toggle-sanbox"
          date-testvalue={sandBoxMode}
          disabled={!isHubGenerated}
          loading={loadingSandbox}
          sizev="md"
          variant={isGetStartedPage ? 'dark-secondary' : 'secondary'}
          checked={sandBoxMode}
          onChange={e => onSandboxChanged(e.target.checked)}
        />
      </div>
    )
  }

  const InviteUserBlock = () => (
    <div
      className={cn('cursor-pointer', !isMobile ? 'px-4' : '')}
      date-testid="header/invite-to-team"
      onClick={() => openInviteModal({ item: { role: Role.User } as InviteRead })}
    >
      {t('header.invite-friend')}
    </div>
  )

  useEffect(() => {
    const headerEL = document.getElementById('header-bg')
    function handleHeaderOpacity() {
      const scrollPosition = window.scrollY
      const opacity = Math.min(1, scrollPosition / 100)
      if (headerEL) {
        headerEL.style.backgroundColor = `rgba(255, 255, 255, ${opacity})`
      }
    }
    window.addEventListener('scroll', handleHeaderOpacity)
    return () => {
      window.removeEventListener('scroll', handleHeaderOpacity)
    }
  }, [window.scrollY, isMobile])

  return (
    <div
      className={cn(
        'relative z-10 flex h-[56px] shrink-0 items-center justify-center',
        props.className,
        isMobile ? 'sticky inset-x-0 top-0 z-40 w-screen px-3' : 'w-full px-9 ',
        (bannerMode === 'test' || bannerMode === 'demo') && isMobile ? 'top-[25px]' : '',
      )}
    >
      {isMobile && <div id="header-bg" className="absolute left-0 top-0 z-30 size-full" />}
      <div className={'relative z-50 w-full'}>
        <div className="grow" />
        <div
          className={cn(
            'flex items-center  gap-2 text-caption-md font-medium text-text-primary',
            isMobile && gameId ? 'justify-between' : 'justify-end',
          )}
        >
          {isMobile && gameId ? (
            !props.hideMenuButton ? (
              <Hamburger onClick={handleMenuOpen} size={32} className="cursor-pointer text-text-secondary" />
            ) : (
              <div />
            )
          ) : (
            <>
              {gameId && canManageUsers(context.customer) && <InviteUserBlock />}

              <div id="bug-report" date-testid="header/bug-report" className="cursor-pointer px-4">
                {t('header.bug-report')}
              </div>

              <div
                className="cursor-pointer px-4"
                date-testid="header/documentation"
                onClick={() => window.open('https://docs.aghanim.com/')}
              >
                {t('header.documentation')}
              </div>

              {game &&
                featureFlags.sandbox_enabled &&
                !company?.is_demo &&
                (isHubGenerated ? (
                  renderToggleSandbox()
                ) : (
                  <Tooltip message={isHubGenerated ? '' : t('header.sandbox.toggle')}>{renderToggleSandbox()}</Tooltip>
                ))}

              {isMobile && gameId && canManageUsers(context.customer) && <InviteUserBlock />}
            </>
          )}

          <div>
            <div className="flex items-center gap-3">
              {game && !company?.is_demo && <NotificationButton />}
              <ProfileButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppHeader
