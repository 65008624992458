import { MouseEvent, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGenerateCompanyPath } from '@/libs'

interface UseNavigateBackOptions {
  /**
   * Fallback url, in case there is no browsing history, an url with mask is supported.
   */
  fallback: string
}

export interface UseNavigateBackValue {
  to: string
  onClick: (e?: MouseEvent) => void
}

/**
 *
 * @param fallback
 */
export const useNavigateBack = ({ fallback }: UseNavigateBackOptions): UseNavigateBackValue => {
  const location = useLocation()
  const navigate = useNavigate()

  const { generatePath } = useGenerateCompanyPath()

  /**
   * Navigate back to the previous page or fallback url.
   * > (!) It cannot be used inside pages with nested routes, as back will return to the previous route.
   */
  const onClick = useCallback(
    (e?: MouseEvent) => {
      if (location.key !== 'default' && !location.state?.skipGoBack) {
        e?.preventDefault()
        navigate(-1)
      } else {
        navigate(generatePath(fallback))
      }
    },
    [navigate, location],
  )

  return {
    to: generatePath(fallback),
    onClick,
  }
}
