import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const XClose: AghanimIcon = forwardRef(function ({ strokeWidth = 2, ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});

XClose.type = 'aghanim-icon';
