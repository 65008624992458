import { useEffect, useId } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { LocaleRead, State, TranslationRead } from '@/api/dashboard'
import { getMotionProps } from '@/libs'
import { translationCreateOrUpdateMutation } from '../api'

interface TranslationFormAiProps {
  locale: LocaleRead
  translation: TranslationRead
  isTruncated: boolean
  parts: string[]
  state: { parts: string[]; isDone: boolean; isError: boolean }
  onDone: () => void
}

const getPartMotionProps = (index: number) =>
  getMotionProps(
    {
      initial: { x: index === 0 ? 0 : -2, opacity: 0 },
      animate: { x: 0, opacity: 1 },
      exit: {},
    },
    { duration: 0.3 },
  )

const dotsMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { x: 4, opacity: 0 },
})

export const TranslationFormAi = ({ locale, translation, isTruncated, state, onDone }: TranslationFormAiProps) => {
  const id = useId()
  const { t } = useTranslation()
  const { mutateAsync } = translationCreateOrUpdateMutation()

  useEffect(() => {
    if (state.isDone) {
      mutateAsync({
        localeId: locale.id,
        data: {
          ...translation,
          content: state.parts
            .filter(part => part !== '[done]')
            .map(part => (part === '' ? ' ' : part))
            .join(''),
          locale: locale.locale,
          state: State.Done,
        },
      }).then(() => {
        onDone()
      })
    }

    if (state.isError) {
      setTimeout(() => {
        onDone()
      }, 2500)
    }
  }, [state, mutateAsync])

  /**
   *
   * on message -> update part
   * on done -> update translation
   * on error -> close
   *
   */
  return (
    <div className="truncate whitespace-pre text-left text-text-secondary">
      <AnimatePresence initial={false} mode="popLayout">
        {!isTruncated &&
          state.parts
            .filter(part => part !== '[done]')
            .map((part, index) => (
              <motion.span
                {...getPartMotionProps(index)}
                key={`part-${id}-${part}-${index}`}
                className="inline-flex h-[17px]"
              >
                {part === '' ? <>&nbsp;</> : part}
              </motion.span>
            ))}
      </AnimatePresence>
      <AnimatePresence>
        {!state.isDone && (
          <motion.span {...dotsMotionProps} className="inline-flex animate-pulse">
            ...
          </motion.span>
        )}
      </AnimatePresence>
      {state.isError && (
        <span className="inline-flex animate-pulse text-text-error-primary">{t('localization.ai.error')}</span>
      )}
    </div>
  )
}
