import { createElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowDownRight, ArrowUpRight, cn } from '@dashboard/ui'
import { TemplatePrice } from '@/api/dashboard'
import { Tooltip } from '@/ui'
import { CheckLevel, CheckResult } from '../libs'

const fmt = (value: number) => (value / 100).toFixed(2)

interface PriceTemplateRowPriceCheckMessageProps {
  price: Pick<TemplatePrice, 'price' | 'currency' | 'is_custom'>
  check: CheckResult
}

export const PriceTemplateRowPriceCheckMessage = ({ price, check }: PriceTemplateRowPriceCheckMessageProps) => {
  const { t } = useTranslation('sku-price-templates')

  return (
    <Tooltip
      message={
        <>
          <p className="mb-1 text-paragraph-xs font-semibold">
            {t(`checks.${check.name}.tooltip.title`, {
              ...check,
              target: fmt(check.target ?? 0),
              current: fmt(price.price ?? 0),
              currency: price.currency,
            })}
          </p>
          <p className="text-paragraph-xs font-normal">
            {t(`checks.${check.name}.tooltip.message`, {
              ...check,
              target: fmt(check.target ?? 0),
              current: fmt(price.price ?? 0),
              currency: price.currency,
            })}
          </p>
        </>
      }
      options={{ supportingText: true }}
    >
      <div
        className={cn('inline-flex w-fit items-center gap-0.5 text-xs font-normal leading-[14px] transition-colors', {
          'text-text-tertiary': check.level === CheckLevel.Info,
          'text-text-error-primary': check.level === CheckLevel.Warn,
        })}
      >
        {createElement(check.deviation >= 0 ? ArrowUpRight : ArrowDownRight, { size: 12 })}
        {t(`checks.${check.name}.short`, {
          ...check,
          target: fmt(check.target ?? 0),
          current: fmt(price.price ?? 0),
          currency: price.currency,
        })}
      </div>
    </Tooltip>
  )
}
