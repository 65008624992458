import '../ToolTip/ToolTip.css'
import { HTMLAttributes, useContext } from 'react'
import DashboardCard from '../../../../components/shared/DashboardCard'
import ReactECharts from 'echarts-for-react'
import { useTheme } from '@mui/material'
import SingleValue, { SingleValueType } from '../SingleValue/SingleValue'
import Loader from '../Loader/Loader'
import { LoaderContainer } from '../Loader/LoaderContainer'
import { AnalyticsDataContext, IAnalyticsDataContext } from '../../Context'
import { Legend } from '../Legend'
import { useTranslation } from 'react-i18next'
import { getDateCategoryParams } from '@/libs/dates'
import { useIsMobile } from '@dashboard/ui'

interface PaymentsSFProps extends HTMLAttributes<HTMLDivElement> {}

const PaymentsSF = (props: PaymentsSFProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const context = useContext(AnalyticsDataContext) as IAnalyticsDataContext
  const total = context.data.payments_success_percent
  const payments = context.data.payments_success_fail_by_dates || []
  const loading = !context.data.payments_success_fail_by_dates
  const isMobile = useIsMobile()

  const options = {
    title: {
      show: false,
    },
    tooltip: {
      backgroundColor: '#000000CC',
      borderColor: 'transparent',
      trigger: 'axis',
      extraCssText: isMobile ? 'z-index: 1' : '',
      formatter: function (params: { axisValue: string; value: number; dataIndex: number }[]) {
        if (!payments?.length) {
          return ''
        }

        let failedItem = params[0]
        let failValue = failedItem ? failedItem.value : 0
        let successItem = params[1]
        let successValue = successItem ? successItem.value : 0
        let sum = successValue + failValue

        if (!sum) {
          return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--title">${params[0].axisValue}</div>
                  <div class="chart-tooltip--separator"></div>
                  <div class="chart-tooltip--subtitle">
                      <span>${t('dashboard.chart.no-data')}</span>
                  </div>
              </div>`
        }

        let failPercent = Math.round((100 * failValue) / sum)

        return `<div class="chart-tooltip" style="height: auto">
                  <div class="chart-tooltip--title">${params[0].axisValue}</div>
                  <div class="chart-tooltip--separator"></div>
                  <div class="chart-tooltip--subtitle">
                      <div class='size-2 rounded-sm' style="background: #F87171"></div>
                      <span>${t('dashboard.payments-success.failed')}:</span>
                      <span>${failPercent}%</span>
                  </div>
                  <div class="chart-tooltip--subtitle">
                    <div class='size-2 rounded-sm' style="background: #86EFAC"></div>
                    <span>${t('dashboard.payments-success.successful')}:</span>
                    <span>${100 - failPercent}%</span>
                  </div>
              </div>`
      },
    },
    legend: {
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 24,
      containLabel: false,
    },
    xAxis: [
      {
        axisLabel: {
          fontSize: 12,
          color: theme.palette.text.tertiary,
          fontWeight: '500',
          fontFamily: 'InterAg',
        },
        axisPointer: {
          type: 'line',
          label: {
            show: false,
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
          },
        },
        axisTick: {
          show: false,
        },
        type: 'category',
        boundaryGap: true,
        data: payments.map(it => t('intl.DateTime', getDateCategoryParams(it.date, context.granularity))),
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.grey.borderSecondary,
            type: 'solid',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
      },
    ],
    axisPointer: {
      lineStyle: {
        color: '#000',
        width: 1,
        type: 'solid',
      },
    },
    series: [
      {
        stack: 'a',
        name: 'failed',
        type: 'bar',
        data: payments.map(it => ({
          value: it.failed_payment! * 100,
          itemStyle: {
            borderRadius: [0, 0, 2, 2],
            emphasis: {
              color: '#F87171',
            },
          },
        })),
        color: '#FCA5A5',
      },
      {
        stack: 'a',
        name: 'success',
        type: 'bar',
        data: payments.map(it => ({
          value: it.success_payment! * 100,
          itemStyle: {
            borderRadius: [2, 2, 0, 0],
            emphasis: {
              color: '#4ADE80',
            },
          },
        })),
        color: '#86EFAC',
      },
    ],
  }

  return (
    <DashboardCard
      title={t('dashboard.payments-success')}
      tooltip={t('dashboard.payments-success.tooltip')}
      style={props.style}
      data-testid="dashboard/chart/payment-sf"
    >
      {loading ? (
        <LoaderContainer style={{ height: '382px' }}>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <SingleValue value={total} label={''} type={SingleValueType.percent} />
          <div>
            <ReactECharts
              option={options}
              style={{
                height: '290px',
                width: '100%',
              }}
            />
            <Legend
              className="mt-2"
              items={[
                {
                  name: t('dashboard.payments-success.successful'),
                  classColor: 'bg-fg-success-primary',
                  type: 'bar',
                },
                {
                  name: t('dashboard.payments-success.failed'),
                  classColor: 'bg-fg-error-secondary',
                  type: 'bar',
                },
              ]}
            />
          </div>
        </>
      )}
    </DashboardCard>
  )
}

export default PaymentsSF
