import { ReactNode } from 'react';
import { cn, tv } from '../../libs';

interface CheckboxLabelProps {
  children: ReactNode;
  checkbox: ReactNode;
  htmlFor: string;
  direction?: 'ltr' | 'rtl';
  size?: 'sm' | 'md';
  caption?: ReactNode;
}

const checkboxLabel = tv({
  base: ['group/checkbox-label flex items-start', 'has-[:disabled]:pointer-events-none'],
  slots: {
    entry: 'flex w-full flex-col items-start gap-1',
    label: [
      'inline-flex cursor-pointer select-none items-center text-text-secondary transition-colors',
      'group-has-[:checked]/checkbox-label:text-text-primary',
      'group-has-[:disabled]/checkbox-label:text-text-disabled',
    ],
    caption: ['text-text-tertiary transition-colors', 'group-has-[:disabled]/checkbox-label:text-text-disabled'],
  },
  variants: {
    direction: {
      ltr: { base: 'flex-row' },
      rtl: { base: 'flex-row-reverse', label: 'w-full' },
    },
    size: {
      sm: { base: 'gap-3', label: 'text-caption-sm', caption: 'text-paragraph-xs' },
      md: { base: 'gap-[18px]', label: 'text-caption-md', caption: 'text-paragraph-sm' },
    },
  },
});

export const CheckboxLabel = ({
  children,
  checkbox,
  htmlFor,
  direction = 'ltr',
  size = 'md',
  caption,
}: CheckboxLabelProps) => {
  const { base, entry, label, caption: captionSlot } = checkboxLabel({ direction, size });
  return (
    <div className={cn(base())}>
      <div className="flex shrink-0">{checkbox}</div>
      <div className={cn(entry())}>
        <label className={cn(label())} htmlFor={htmlFor}>
          <span>{children}</span>
        </label>
        {caption && <div className={cn(captionSlot())}>{caption}</div>}
      </div>
    </div>
  );
};
