import { ReactNode, createElement } from 'react';
import { motion } from 'framer-motion';
import { VariantProps, tv } from 'tailwind-variants';
import { AghanimIcon } from '../../icons';

export interface ToggleTabsButtonProps extends VariantProps<typeof toggleTabsButtonStyles> {
  children?: ReactNode;
  icon?: AghanimIcon;
  selected?: boolean;
  onClick?: () => void;
}

const toggleTabsButtonStyles = tv({
  base: [
    'relative flex w-full select-none flex-col items-center justify-center gap-0.5 whitespace-nowrap rounded-md font-semibold text-text-tertiary transition-all',
    'hover:text-text-tertiary-hover',
  ],
  slots: {
    selectedIndicator:
      'absolute left-0 top-0 size-full rounded-md border border-border-primary bg-fg-primary shadow-xs',
    icon: 'relative z-10',
    label: 'relative z-10 truncate',
  },
  variants: {
    size: {
      md: {
        base: 'min-h-8 min-w-8 p-1.5',
        label: 'text-caption-md',
      },
    },
    selected: {
      true: {
        base: 'pointer-events-none text-text-primary',
        label: 'font-semibold',
      },
      false: '',
    },
    onlyIcon: {
      true: {
        base: 'px-0',
      },
    },
  },
});

export const ToggleTabsButton = ({ children, icon, size = 'md', selected = false, onClick }: ToggleTabsButtonProps) => {
  const {
    base,
    selectedIndicator,
    icon: iconSlot,
    label,
  } = toggleTabsButtonStyles({ size, selected, onlyIcon: !children });
  return (
    <button className={base()} type="button" onClick={onClick}>
      {selected && <motion.div className={selectedIndicator()} layoutId="indicator" />}
      {icon && createElement(icon, { className: iconSlot() })}
      <div className={label()}>{children}</div>
    </button>
  );
};
