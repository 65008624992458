import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ItemRarityRead, ItemRarityUpdate } from '@/api/dashboard'
import { removeItemQueries } from '@/layouts/game-items/api'

interface ItemRarityUpdateQuery {
  id: string
  update: ItemRarityUpdate
}

export const useItemRarityUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: ItemRarityUpdateQuery) =>
      dashboardClient.v1.updateItemRarity(companyId, gameId, id, update),
    onSuccess: (_, variables) => {
      queryClient.setQueryData<ItemRarityRead>(
        ['item-rarity', gameId, variables.id],
        old =>
          ({
            ...old,
            ...variables.update,
          }) as ItemRarityRead,
      )
      queryClient.invalidateQueries({ queryKey: ['item-rarity', gameId] })
      removeItemQueries(queryClient, gameId)
    },
  })
}
