import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export const usePaletteQuery = () => {
  return useQuery({
    queryKey: ['palettes'],
    queryFn: () => dashboardClient.v1.getPalettes(),
    select: response => response.data,
  })
}
