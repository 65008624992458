import { useTranslation } from 'react-i18next'
import { PriceCategory } from '@/api/dashboard'
import { TableCell, TableRow, TooltipAlertCircle } from '@/ui'

interface PriceTemplateRowPriceHeaderProps {
  category: PriceCategory
}

export const PriceTemplateRowPriceHeader = ({ category }: PriceTemplateRowPriceHeaderProps) => {
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'table.header' })

  return (
    <TableRow className="top-[48px]" variant="header">
      <TableCell width="100%">{t('type.country')}</TableCell>
      {category === PriceCategory.None ? (
        <TableCell width="40%">{t('price')}</TableCell>
      ) : (
        <>
          <TableCell width="40%">{t('platforms.ios')}</TableCell>
          <TableCell width="40%">{t('platforms.android')}</TableCell>
          <TableCell width="40%">{t('platforms.other')}</TableCell>
        </>
      )}
      <TableCell width="35%">
        <div className="inline-flex items-center gap-1">
          <span>{t('tax.title')}</span>
          <TooltipAlertCircle size="sm" message={t('tax.tooltip')} />
        </div>
      </TableCell>
    </TableRow>
  )
}
