import { FocusEvent, KeyboardEvent, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputNumberFormat, unformat } from '@react-input/number-format'
import { Edit03, XCircle, cn } from '@dashboard/ui'
import { TemplatePrice } from '@/api/dashboard'
import { Badge } from '@/ui'
import { CheckResult } from '../libs'
import { PriceTemplateRowPriceCheckMessage } from './PriceTemplateRowPriceCheckMessage'
import { getPriceMinorFraction, getPriceMinorUnitFactor } from '@/util'

interface PriceTemplateRowPriceInlineEditProps {
  price: Pick<TemplatePrice, 'price' | 'currency' | 'is_custom'>
  check?: CheckResult | undefined
  onChange: (value: number | undefined, focusRow?: 'next' | 'prev', focusCell?: 'next' | 'prev') => void
  onDiscard: () => void
  onReset: () => Promise<void>
}

export const PriceTemplateRowPriceInlineEdit = ({
  price,
  check,
  onChange,
  onDiscard,
  onReset,
}: PriceTemplateRowPriceInlineEditProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('sku-price-templates')

  const [editable, setEditable] = useState(false)
  const [isResetLoading, setIsResetLoading] = useState(false)

  const minorUnit = useMemo(() => getPriceMinorFraction(price.currency), [price.currency])
  const numberFormatOptions = {
    maximumFractionDigits: minorUnit,
    minimumFractionDigits: minorUnit,
  }
  const minorFactor = useMemo(() => getPriceMinorUnitFactor(price.currency), [price.currency])

  const onChangeWithValidation = useCallback(
    (rawValue: string, focusRow: 'next' | 'prev' | undefined, focusCell?: 'next' | 'prev') => {
      const value = parseFloat(unformat(rawValue, 'en-US'))
      if (value && value > 0) {
        onChange(value, focusRow, focusCell)
      } else {
        onChange(undefined, focusRow, focusCell)
      }
    },
    [onChange],
  )

  const onClickCell = useCallback(() => {
    setEditable(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 10)
  }, [])

  const onInputKeydown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        e.stopPropagation()
        e.preventDefault()
        setEditable(false)
        onDiscard()
        return
      }

      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        onChangeWithValidation(e.currentTarget.value, undefined)
        setEditable(false)
        return
      }

      if (e.key === 'Tab') {
        e.stopPropagation()
        e.preventDefault()
        onChangeWithValidation(e.currentTarget.value, undefined, e.shiftKey ? 'prev' : 'next')
        setEditable(false)
        return
      }

      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        e.stopPropagation()
        e.preventDefault()
        onChangeWithValidation(e.currentTarget.value, e.key === 'ArrowUp' ? 'prev' : 'next')
        setEditable(false)
        return
      }
    },
    [onChangeWithValidation, onDiscard],
  )

  const onInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      /**
       * ! This is a hack to determine if the escape key was pressed during focus change
       */
      // @ts-ignore
      if (e.nativeEvent.sourceCapabilities) {
        onChangeWithValidation(e.currentTarget.value, undefined)
      }
      setEditable(false)
    },
    [onChangeWithValidation],
  )

  const onClickReset = useCallback(
    async (e: MouseEvent) => {
      e.stopPropagation()
      setIsResetLoading(true)
      await onReset()
      setIsResetLoading(false)
    },
    [onReset],
  )

  return (
    <div
      className={cn(
        'group/inline-edit flex h-full flex-col justify-center gap-0.5 transition-opacity',
        isResetLoading && 'pointer-events-none animate-pulse opacity-25',
      )}
      onClick={onClickCell}
    >
      {editable ? (
        <div className="flex items-center gap-1 text-text-primary">
          <div className="flex items-center gap-1 font-medium">
            <span className="font-medium">{price.currency}</span>
            <InputNumberFormat
              {...numberFormatOptions}
              ref={inputRef}
              className={cn(
                'h-4 w-full border-none border-border-primary bg-transparent p-0 font-medium text-inherit',
                'focus:text-text-primary',
              )}
              locales="en-US"
              maximumFractionDigits={minorUnit}
              minimumFractionDigits={minorUnit}
              placeholder={(price.price / minorFactor).toLocaleString('en-US', numberFormatOptions)}
              defaultValue={price.price / minorFactor}
              onKeyDown={onInputKeydown}
              onBlur={onInputBlur}
              data-1p-ignore={true}
            />
          </div>
        </div>
      ) : (
        <div
          className={cn(
            'flex items-center gap-1.5 text-text-secondary transition-all',
            'hover:text-text-secondary-hover',
            price.is_custom && 'text-text-primary',
          )}
        >
          <div className="flex items-center gap-1 font-medium">
            {price.price ? (
              <>
                <span>{price.currency}</span>
                <span>{(price.price / minorFactor).toLocaleString('en-US', numberFormatOptions)}</span>
              </>
            ) : (
              <>–</>
            )}
          </div>
          {price.is_custom && (
            <Badge variant="gray-secondary" size="sm">
              {t('form-edit.custom-badge')}
            </Badge>
          )}
          {price.is_custom ? (
            <XCircle
              className={cn(
                'size-4 shrink-0 text-text-quarterary-hover opacity-0 transition-all',
                'group-hover/inline-edit:opacity-100',
                'group-focus/inline-edit:opacity-100',
                'hover:text-text-brand-primary',
              )}
              onClick={onClickReset}
            />
          ) : (
            <Edit03
              className={cn(
                'size-4 shrink-0 text-text-quarterary-hover opacity-0 transition-all',
                'group-hover/inline-edit:opacity-100',
                'group-focus/inline-edit:opacity-100',
              )}
            />
          )}
        </div>
      )}

      {check && check.value && <PriceTemplateRowPriceCheckMessage price={price} check={check} />}
    </div>
  )
}
