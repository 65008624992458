import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ArrowDownRight: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6569 17.6568C17.8504 17.4633 17.9561 17.1989 17.9495 16.9253L17.7727 9.67744C17.7592 9.12532 17.3007 8.68865 16.7486 8.70212C16.1965 8.71559 15.7598 9.17408 15.7733 9.7262L15.8891 14.4748L7.75736 6.34306C7.36684 5.95254 6.73367 5.95254 6.34315 6.34306C5.95262 6.73359 5.95262 7.36675 6.34315 7.75728L14.4749 15.889L9.72629 15.7732C9.17417 15.7597 8.71567 16.1964 8.7022 16.7485C8.68874 17.3006 9.1254 17.7591 9.67752 17.7726L16.9254 17.9494C17.199 17.956 17.4633 17.8503 17.6569 17.6568Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

ArrowDownRight.type = 'aghanim-icon';
