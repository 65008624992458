import { DrawerContent, DrawerTopbar, FieldGroup, InputExtraMark } from '@/ui'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler } from 'react-hook-form'
import { CouponRead, CouponType, CreatorProgramBase, Expression } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { FieldSection } from 'src/components/ui/FieldSection'
import Divider from '../../../components/Divider'
import { useCouponUpdateMutate } from '../api/useCouponUpdateMutate'
import { useCouponCreateMutate } from '../api/useCouponCreateMutate'
import { useCouponDeleteMutate } from '../api/useCouponDeleteMutate'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { ErrorMessage } from '@hookform/error-message'
import { validateConditionTokens } from '../../campaigns/validation'
import { getTokenGroups } from '../../campaigns/util'
import { InputCopyButton } from '@/components/ui/InputCopyButton'
import { MAX_NAME_LENGTH } from '@/Settings'
import { Trash } from '@/icons'
import { SelectDateTime } from '@/components/ui/SelectDateTime'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Button, Card, FormErrorMessage, Input, ModalPresetConfirm, Select, Textarea, useModal } from '@dashboard/ui'
import { useCreators } from '@/layouts/coupon/hooks'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { useFeatureFlags } from '@/libs'
import { validateInterval } from '@/layouts/components/ItemTimelimit'
import { CouponStatChart } from '@/layouts/coupon/components/CouponStatChart'
import { usePydenticForm } from '@/libs/hooks/usePydenticForm'
import { useCouponQuery } from '@/layouts/coupon/api/useCouponQuery'
import { URL_NEW } from '@/types'
import { ExpressionEditor, getCampaignContext } from '@/layouts/components/ExpressionEditor'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { SelectSkuTable } from '@/layouts/components/SelectSkuTable'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { InputList } from '@/layouts/components/InputList'

const MAX_CREATOR_PROGRAM_MESSAGE_LENGTH = 100

const defaultValue: CouponRead = {
  code: '',
  type: CouponType.Discount,
  name: '',
} as CouponRead

interface CouponSettingsProps {
  onClose: (saved: boolean) => void
}

export default function CouponBaseSettings(props: CouponSettingsProps) {
  const { t } = useTranslation()
  const { companyId, gameId, codeId } = useParams() as { companyId: string; gameId: string; codeId: string }
  const {
    featureFlags: { creator_program_enabled = false },
  } = useFeatureFlags()

  const { data: coupon, isLoading } = useCouponQuery(companyId, gameId, codeId)

  const [errorMessage, setErrorMessage] = useState<string>('')

  const [timeLimitChecked, setTimeLimitChecked] = useState<boolean>(false)
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const { creators } = useCreators()
  const { mutateAsync: updateMutateAsync } = useCouponUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useCouponCreateMutate(companyId, gameId)
  const { mutateAsync: deleteMutateAsync } = useCouponDeleteMutate(companyId, gameId)

  const onConfirmDelete = async () => {
    await deleteMutateAsync({ id: codeId })
    reset()
    props.onClose(true)
    setErrorMessage('')
  }

  const {
    register,
    reset,
    watch,
    formState: { errors, dirtyFields },
    control,
    handleSubmit,
    clearErrors,
    setValue,
    setError,
  } = usePydenticForm<CouponRead>({
    mode: 'onChange',
    defaultValues: { ...defaultValue },
  })

  useEffect(() => {
    if (isLoading || !coupon) {
      return
    }

    reset(coupon)

    if (coupon?.start_at || coupon?.end_at) {
      setTimeLimitChecked(true)
    }
  }, [coupon])

  const onSubmit: SubmitHandler<CouponRead> = async data => {
    setErrorMessage('')

    const err = validateInterval(data.start_at, data.end_at, t)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    if (data.requirements_expression?.tokens?.length) {
      let { error, errorTokens: errTokens } = validateConditionTokens(
        getTokenGroups(data.requirements_expression?.tokens || []),
        getCampaignContext(gameSettings),
      )
      if (error) {
        setError('requirements_expression', { message: error })
        return
      }

      if (errTokens?.length) {
        return
      }
    } else {
      data.requirements_expression = null as unknown as Expression
    }

    switch (data.type) {
      case CouponType.FreeItem:
        data.bonus_percent = undefined
        data.discount_percent = undefined
        data.applicable_item_ids = undefined
        data.bonus_percent = undefined
        data.discount_percent = undefined
        data.min_amount = undefined
        data.is_stackable_with_bonus_coupons = undefined
        data.stackable_bonus_limit_percent = undefined
        data.is_stackable_with_discount_coupons = undefined
        data.stackable_discount_limit_percent = undefined
        data.creator_program = undefined
        break
      case CouponType.Bonus:
        data.discount_percent = undefined
        data.item_id = undefined
        data.is_stackable_with_discount_coupons = undefined
        data.stackable_discount_limit_percent = undefined
        if (data.creator_program) {
          if (!data.creator_program.creator_id) {
            data.creator_program = undefined
          } else {
            data.creator_program.verbal_terms = data.creator_program.verbal_terms ?? ''
            data.creator_program.message = data.creator_program.message ?? ''
          }
        }
        break
      case CouponType.Discount:
        data.bonus_percent = undefined
        data.item_id = undefined
        data.is_stackable_with_bonus_coupons = undefined
        data.stackable_bonus_limit_percent = undefined
        if (data.creator_program) {
          if (!data.creator_program.creator_id) {
            data.creator_program = undefined
          } else {
            data.creator_program.verbal_terms = data.creator_program.verbal_terms ?? ''
          }
        }
        break
      case CouponType.NonBenefit:
        data.bonus_percent = undefined
        data.discount_percent = undefined
        data.item_id = undefined
        data.is_stackable_with_bonus_coupons = undefined
        data.stackable_bonus_limit_percent = undefined
        data.is_stackable_with_discount_coupons = undefined
        data.stackable_discount_limit_percent = undefined
        if (data.creator_program) {
          data.creator_program.verbal_terms = data.creator_program.verbal_terms ?? ''
        }
        break
    }

    if (!data.is_stackable_with_discount_coupons) {
      data.stackable_discount_limit_percent = undefined
    }

    if (!data.is_stackable_with_bonus_coupons) {
      data.stackable_bonus_limit_percent = undefined
    }

    if (coupon?.id) {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } else {
      await createMutateAsync({
        create: data,
      })
    }

    setErrorMessage('')
    props.onClose(true)
  }

  const onClose = () => {
    setErrorMessage('')
    props.onClose(false)
  }

  const openDeleteModal = useModal<NonNullable<unknown>>(rest => (
    <ModalPresetConfirm
      {...rest}
      title={{
        children: t('confirm.title'),
        subtitle: t('confirm.text', { name: coupon?.name }),
      }}
      cancel={{
        label: t('Cancel'),
        callback: rest.onClose,
      }}
      submit={{
        callback: onConfirmDelete,
        color: 'danger',
        label: t('remove'),
      }}
    />
  ))

  const [
    player_ids,
    creator_program,
    applicable_item_ids,
    min_amount,
    max_redemptions,
    max_redemptions_per_user,
    code,
  ] = watch([
    'player_ids',
    'creator_program',
    'applicable_item_ids',
    'min_amount',
    'max_redemptions',
    'max_redemptions_per_user',
    'code',
  ])

  const type = watch('type') || coupon?.type || defaultValue.type

  const renderGeneral = () => {
    return (
      <FieldSection label={t('coupon.general')}>
        <FieldGroup label={t('coupon.name')}>
          <Input
            {...register('name', {
              required: t('validation.required'),
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('coupon.name'),
                  value: MAX_NAME_LENGTH,
                }),
                value: MAX_NAME_LENGTH,
              },
              setValueAs: v => v?.trim(),
            })}
            autoFocus={true}
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('coupon.code')} caption={coupon?.id ? '' : t('coupon.code.validation')}>
          {coupon?.id ? (
            <InputCopyButton value={code} disabled={true} />
          ) : (
            <>
              <Controller
                control={control}
                name="code"
                rules={{
                  required: t('validation.required'),
                  pattern: { value: /^[A-Z0-9]+$/g, message: t('coupon.code.validation') },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={v => {
                      field.onChange(v.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''))
                    }}
                    disabled={!!coupon?.id}
                    autoComplete="off"
                  />
                )}
              />
              <ErrorMessage
                name="code"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </>
          )}
        </FieldGroup>

        <FieldGroup label={t('coupon.type')}>
          <Controller
            disabled={!!coupon?.id}
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                data-testid={'coupon-type'}
                {...field}
                onChange={v => {
                  if (v === CouponType.NonBenefit) {
                    if (!creator_program) {
                      setValue('creator_program', {} as CreatorProgramBase, { shouldDirty: true })
                    }
                  } else if (type == CouponType.NonBenefit) {
                    setValue('creator_program', null as unknown as undefined, { shouldDirty: true })
                  }

                  setValue('applicable_item_ids', null as unknown as undefined, { shouldDirty: true })

                  field.onChange(v)
                }}
                items={Object.values(CouponType)
                  .filter(it => it !== CouponType.NonBenefit || creator_program_enabled)
                  .map(it => ({
                    value: it,
                    children: t(`coupon.type.${it}`),
                  }))}
              />
            )}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderBenefits = () => {
    return (
      <FieldSection label={t('coupon.benefits')}>
        {type == CouponType.Bonus && (
          <FieldGroup label={t('coupon.bonus')}>
            <Input
              disabled={!!coupon?.id}
              type="number"
              {...register('bonus_percent', {
                required: t('validation.required'),
                min: 1,
                max: 1000,
              })}
              extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
            />
            <ErrorMessage
              name="bonus_percent"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        )}

        {type == CouponType.Discount && (
          <FieldGroup label={t('coupon.discount')}>
            <Input
              disabled={!!coupon?.id}
              type="number"
              {...register('discount_percent', {
                required: t('validation.required'),
                min: { value: 1, message: t('coupon.discount_percent.validation') },
                max: { value: 99, message: t('coupon.discount_percent.validation') },
              })}
              extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
            />
            <ErrorMessage
              name="discount_percent"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        )}

        {type == CouponType.FreeItem && (
          <FieldGroup label={t('coupon.item')}>
            <Controller
              control={control}
              name="item_id"
              rules={{ required: t('validation.required') }}
              render={({ field }) => <SelectSkuItemV2 {...field} />}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        )}
      </FieldSection>
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <FieldSection label={t('coupon.benefits-compatibility')}>
        {type == CouponType.Bonus && (
          <Controller
            name="is_stackable_with_bonus_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={t('coupon.benefits.stackable')}
              >
                <FieldGroup caption={t('coupon.stackable-limit-desc')}>
                  <Input
                    extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
                    type="number"
                    {...register('stackable_bonus_limit_percent', {
                      required: value ? t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                  />
                  <ErrorMessage
                    name="stackable_bonus_limit_percent"
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}

        {type == CouponType.Discount && (
          <Controller
            name="is_stackable_with_discount_coupons"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CheckBoxContainer
                checked={value}
                onChange={e => onChange(e.target.checked)}
                label={t('coupon.stackable-discount-code')}
              >
                <FieldGroup caption={t('coupon.stackable-discount-limit-desc')}>
                  <Input
                    extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
                    {...register('stackable_discount_limit_percent', {
                      required: value ? t('validation.required') : false,
                      min: 1,
                      max: 100,
                    })}
                  />
                  <ErrorMessage
                    name="stackable_discount_limit_percent"
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FieldGroup>
              </CheckBoxContainer>
            )}
          />
        )}
      </FieldSection>
    )
  }

  const renderCreatorProgram = () => {
    return (
      <FieldSection label={t('coupon.creator')}>
        <Controller
          control={control}
          name="creator_program"
          render={({ field: { value, onChange } }) => (
            <CheckBoxContainer
              disabled={!!coupon?.id || !creators.length || type === CouponType.NonBenefit}
              checked={!!value}
              onChange={e => {
                if (e.target.checked) {
                  onChange({
                    creator_id: undefined,
                    reward: null,
                    verbal_terms: null,
                    message: null,
                  })
                } else {
                  setValue('creator_program', null as unknown as undefined, { shouldDirty: true })
                  clearErrors(['creator_program.creator_id', 'creator_program.reward'])
                }
              }}
              label={t('coupon.creator.checkbox')}
            >
              <Card color="secondary">
                <FieldGroup label={t('coupon.creator.creator')}>
                  <Controller
                    disabled={!!coupon?.id}
                    control={control}
                    name="creator_program.creator_id"
                    rules={{
                      required: creator_program ? t('validation.required') : false,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || undefined}
                        items={creators.map(creator => ({
                          value: creator.id,
                          children: (
                            <div className="inline-flex items-center gap-2">
                              <img
                                src={creator.avatar_url || '/icons/default-avatar.svg'}
                                alt="img"
                                className="size-[24px] rounded-full object-cover"
                              />
                              <span className="text-caption-md text-text-secondary">{creator.name}</span>
                            </div>
                          ),
                        }))}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="creator_program.creator_id"
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FieldGroup>

                <FieldGroup label={t('coupon.creator.reward')}>
                  <Controller
                    control={control}
                    name="creator_program.reward"
                    rules={{
                      required: creator_program ? t('validation.required') : false,
                      min: { value: 1, message: t('coupon.reward_percent.validation') },
                      max: { value: 99, message: t('coupon.reward_percent.validation') },
                    }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        value={field.value || ''}
                        disabled={!!coupon?.id}
                        type="number"
                        extraLeft={{ icon: <InputExtraMark>%</InputExtraMark> }}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="creator_program.reward"
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </FieldGroup>

                <FieldGroup label={t('coupon.creator.tracking-link')}>
                  <InputCopyButton value={`${websites?.[0]?.url}/c/${code}`} />
                </FieldGroup>

                <FieldGroup label={t('coupon.creator.terms')} tooltip={t('coupon.creator.terms.tooltip')}>
                  <Controller
                    control={control}
                    name="creator_program.verbal_terms"
                    render={({ field }) => <Textarea {...field} value={field.value || ''} rows={4} />}
                  />
                </FieldGroup>

                <FieldGroup label={t('coupon.creator.message')} tooltip={t('coupon.creator.message.tooltip')}>
                  <Controller
                    control={control}
                    name="creator_program.message"
                    rules={{
                      required: creator_program ? t('validation.required') : false,
                      maxLength: {
                        message: t('validation.maxLength', {
                          field: t('coupon.creator.message'),
                          value: MAX_CREATOR_PROGRAM_MESSAGE_LENGTH,
                        }),
                        value: MAX_CREATOR_PROGRAM_MESSAGE_LENGTH,
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <Textarea {...field} error={error?.message} value={field.value || ''} rows={4} />
                    )}
                  />
                </FieldGroup>
              </Card>
            </CheckBoxContainer>
          )}
        />
      </FieldSection>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <FieldSection label={t('coupon.general-limitations')}>
        <Controller
          name="max_redemptions"
          control={control}
          rules={{ required: max_redemptions != undefined ? t('validation.required') : false }}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              label={t('coupon.reusable')}
              checked={value != undefined}
              onChange={v => {
                onChange(v.target.checked ? 1 : null)
              }}
            >
              <Input
                extraRight={{ icon: <InputExtraMark>{t('times')}</InputExtraMark> }}
                type="number"
                {...register('max_redemptions', {
                  min: { value: 1, message: t('coupon.max_redemptions.validation') },
                  max: { value: 100_000_000, message: t('coupon.max_redemptions.validation') },
                })}
              />
              <ErrorMessage
                name="max_redemptions"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </CheckBoxContainer>
          )}
        />
        <Divider />

        <Controller
          name="max_redemptions_per_user"
          control={control}
          rules={{ required: max_redemptions_per_user != undefined ? t('validation.required') : false }}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              label={t('coupon.reusable-per-user')}
              checked={value != undefined}
              onChange={v => {
                onChange(v.target.checked ? 1 : null)
              }}
            >
              <Input
                {...register('max_redemptions_per_user', {
                  min: { value: 1, message: t('coupon.max_redemptions.validation') },
                  max: { value: 100_000_000, message: t('coupon.max_redemptions.validation') },
                })}
                extraRight={{ icon: <InputExtraMark>{t('times')}</InputExtraMark> }}
                type="number"
              />
              <ErrorMessage
                name="max_redemptions_per_user"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </CheckBoxContainer>
          )}
        />

        {type != CouponType.FreeItem && (
          <>
            <Divider />
            <CheckBoxContainer
              checked={!!applicable_item_ids}
              onChange={v => {
                // @ts-ignore
                setValue('applicable_item_ids', v.target.checked ? [] : (null as unknown as undefined), {
                  shouldDirty: true,
                })
              }}
              label={t('coupon.sku-bound')}
              caption={t('coupon.sku-bound-desc')}
            >
              <Controller
                name="applicable_item_ids"
                control={control}
                rules={{ required: applicable_item_ids ? t('validation.required') : false }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <SelectSkuTable
                      isStackable={type === CouponType.Bonus ? true : undefined}
                      onChange={v => {
                        clearErrors('applicable_item_ids')
                        onChange(v)
                      }}
                      value={value || []}
                      withPrice={true}
                    />
                    <ErrorMessage
                      name="applicable_item_ids"
                      errors={errors}
                      render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                    />
                  </>
                )}
              />
            </CheckBoxContainer>
          </>
        )}

        <Divider />
        <CheckBoxContainer
          checked={!!player_ids}
          onChange={v => {
            setValue('player_ids', v.target.checked ? [] : (null as unknown as undefined), {
              shouldDirty: true,
            })
          }}
          label={t('coupon.player-bond')}
          caption={t('coupon.player-ids.desc')}
        >
          <Controller
            name="player_ids"
            control={control}
            rules={{ required: player_ids ? t('validation.required') : false }}
            render={({ field: { onChange, value } }) => (
              <>
                <InputList modalTitle={t('coupon.edit-player-list')} value={value || []} onChange={v => onChange(v)} />
                <ErrorMessage
                  name="player_ids"
                  errors={errors}
                  render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                />
              </>
            )}
          />
        </CheckBoxContainer>

        {type != CouponType.FreeItem && (
          <>
            <Divider />
            <Controller
              name="min_amount"
              rules={{ required: min_amount == null ? false : t('validation.required') }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <CheckBoxContainer
                  checked={value != null}
                  onChange={v => {
                    onChange(v.target.checked ? 100 : null)
                  }}
                  label={t('coupon.min_amount')}
                >
                  <Input
                    type="number"
                    value={isNaN(value!) ? '' : value! / 100}
                    extraLeft={{ icon: <InputExtraMark>$</InputExtraMark> }}
                    onChange={e => {
                      let v = parseFloat(e.target.value)
                      onChange(Math.max(1, v * 100))
                    }}
                  />
                  <ErrorMessage
                    name="min_amount"
                    errors={errors}
                    render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                  />
                </CheckBoxContainer>
              )}
            />
          </>
        )}
      </FieldSection>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <FieldSection label={t('campaign.settings.limit-limitations')}>
        <CheckBoxContainer
          checked={timeLimitChecked}
          onChange={v => {
            setTimeLimitChecked(v.target.checked)
            if (v.target.checked) {
              const startDate = dayjs().startOf('day').toDate().getTime() / 1000
              setValue('start_at', startDate, { shouldDirty: true })
              setValue('end_at', startDate + 86400 * 30)
            } else {
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined, { shouldDirty: true })
            }
          }}
          label={t('campaign.virtual-sku-editor.limit-by-dates')}
        >
          <FieldGroup label={t('datetime.start_at')}>
            <Controller control={control} name="start_at" render={({ field }) => <SelectDateTime {...field} />} />
            <ErrorMessage
              name="start_at"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
          <FieldGroup label={t('datetime.end_at')}>
            <Controller control={control} name="end_at" render={({ field }) => <SelectDateTime {...field} />} />
            <ErrorMessage
              name="end_et"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderConditions = () => {
    if (!coupon && codeId != URL_NEW) {
      return null
    }

    return (
      <FieldGroup label={t('store.item.requirements_expression')}>
        <Controller
          control={control}
          name="requirements_expression"
          render={({ field }) => (
            <ExpressionEditor id={codeId} errorTokens={[]} value={field.value} onChange={field.onChange} />
          )}
        />
        <ErrorMessage
          name="requirements_expression"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
    )
  }

  return (
    <div className="flex h-full flex-col bg-fg-primary">
      <DrawerTopbar>
        <h2 className="mr-auto text-title-t5">{t('coupon.settings')}</h2>
        <div className="flex gap-2.5">
          <Button size="sm" variant="outline" color="primary" onClick={onClose}>
            {t('close')}
          </Button>
          <Button size="sm" disabled={Object.keys(dirtyFields).length == 0} onClick={handleSubmit(onSubmit)}>
            {t('Save2')}
          </Button>
        </div>
      </DrawerTopbar>

      <div className="flex-1">
        <DrawerContent>
          {codeId != URL_NEW && <CouponStatChart couponId={codeId} />}

          {renderGeneral()}

          {type != CouponType.NonBenefit && (
            <>
              <Divider />
              {renderBenefits()}
            </>
          )}

          {type != CouponType.NonBenefit && type != CouponType.FreeItem && (
            <>
              <Divider />
              {renderBenefitsCompatibility()}
            </>
          )}

          {creator_program_enabled &&
            (type === CouponType.Discount || type == CouponType.Bonus || type == CouponType.NonBenefit) && (
              <>
                <Divider />

                {renderCreatorProgram()}
              </>
            )}

          {type != CouponType.NonBenefit && (
            <>
              <Divider />
              {renderGeneralLimitations()}
            </>
          )}
          <Divider />
          {renderTimeLimitations()}

          {type != CouponType.NonBenefit && (
            <>
              <Divider />
              {renderConditions()}
            </>
          )}

          {errorMessage && <FormErrorMessage> {errorMessage} </FormErrorMessage>}
        </DrawerContent>
      </div>

      {coupon?.id && (
        <div className="mt-auto flex justify-end gap-2 p-6">
          <Button onClick={() => openDeleteModal({})} variant="outline" color="danger" size="sm">
            <Trash />
            {t('delete')}
          </Button>
        </div>
      )}
    </div>
  )
}
