import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Segment: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <rect width="17" height="17" transform="translate(0 0.5)" fill="white" />
      <path
        d="M8.14996 1.91717C8.34543 1.90739 8.50421 2.06698 8.50421 2.2627V5.09771C8.50421 5.29343 8.34515 5.45026 8.15041 5.46979C7.57452 5.52758 7.01961 5.72578 6.53539 6.04932C5.95262 6.43872 5.49841 6.99218 5.23019 7.63972C4.96197 8.28726 4.89179 8.9998 5.02853 9.68722C5.16526 10.3746 5.50277 11.0061 5.99838 11.5017C6.49399 11.9973 7.12543 12.3348 7.81285 12.4715C8.50027 12.6083 9.21281 12.5381 9.86035 12.2699C10.5079 12.0017 11.0614 11.5475 11.4507 10.9647C11.7743 10.4805 11.9725 9.92555 12.0303 9.34966C12.0498 9.15492 12.2066 8.99586 12.4024 8.99586H15.2374C15.4331 8.99586 15.5927 9.15464 15.5829 9.35012C15.519 10.6276 15.1103 11.8664 14.3973 12.9335C13.6185 14.099 12.5116 15.0075 11.2165 15.5439C9.92141 16.0803 8.49634 16.2207 7.12149 15.9472C5.74664 15.6738 4.48377 14.9987 3.49255 14.0075C2.50134 13.0163 1.82632 11.7534 1.55284 10.3786C1.27937 9.00373 1.41973 7.57866 1.95617 6.28358C2.49261 4.9885 3.40103 3.88157 4.56658 3.10279C5.63372 2.38974 6.87251 1.98109 8.14996 1.91717Z"
        fill="currentColor"
      />
      <path
        d="M9.92171 2.48553C9.92171 2.26133 10.1275 2.09308 10.344 2.15128C10.6404 2.23092 10.9318 2.3299 11.2165 2.44782C12.0764 2.804 12.8577 3.32607 13.5159 3.98421C14.174 4.64235 14.6961 5.42367 15.0522 6.28357C15.1702 6.56826 15.2691 6.85971 15.3488 7.15602C15.407 7.37254 15.2387 7.57835 15.0145 7.57835H10.2761C10.0804 7.57835 9.92171 7.41969 9.92171 7.22397V2.48553Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Segment.type = 'aghanim-icon';
