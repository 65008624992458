import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { KeyValue } from '@/types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/ko'
import 'dayjs/locale/zh'

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: true,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'zh', 'ko'],
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
    debug: window.localStorage.getItem('i18nextDebug') === 'true',
    ns: [
      'translation',
      'transaction-error-codes',
      'countries',
      'ui',
      'sku-cashback',
      'sku-price-templates',
      'lootboxes',
      'offers',
    ],
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      reloadInterval: false,
    },
  })

i18n.on('initialized', () => {
  dayjs.extend(relativeTime)
  const currentLang = i18n.language
  dayjs.locale(currentLang)
})

export default i18n

const banCountries = ['AF', 'BY', 'BI', 'CU', 'IR', 'KP', 'LY', 'MM', 'RU', 'SO', 'SS', 'SD', 'SY', 'VE', 'YE', 'ZW']

function filterCountries(countries: KeyValue): KeyValue {
  return Object.fromEntries(Object.entries(countries).filter(([key]) => !banCountries.includes(key)))
}

export function getCountries(useBanlist = false): KeyValue {
  const items = (i18n.getResourceBundle(i18n.language, 'countries') || {}) as KeyValue
  return useBanlist ? filterCountries(items) : items
}
