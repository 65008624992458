import { AttrType } from '@/api/dashboard'
import { EMPTY_VALUE, TokenValue } from '@/layouts/campaigns/expr/types'
import { InputDate, Select } from '@dashboard/ui'
import { InputList } from '@/layouts/components/InputList'
import { Input } from '@/ui'

interface PlayerAttributeValueEditorProps {
  type: AttrType
  value: TokenValue
  onChange: (value: TokenValue) => void
}

export const PlayerAttributeValueEditor = ({ type, value, onChange }: PlayerAttributeValueEditorProps) => {
  switch (type) {
    case AttrType.Boolean:
      return (
        <Select
          value={value == undefined ? EMPTY_VALUE : value == true ? 'True' : 'False'}
          onChange={v => onChange(v == EMPTY_VALUE ? undefined : v == 'True')}
          items={[
            { children: 'None', value: EMPTY_VALUE },
            { children: 'True', value: 'True' },
            { children: 'False', value: 'False' },
          ]}
        />
      )
    case AttrType.Date:
      return (
        <InputDate
          value={value ? new Date((value as number) * 1000) : null}
          onChange={value => {
            onChange(value ? value.getTime() / 1000 : (null as unknown as undefined))
          }}
        />
      )
    case AttrType.List:
      return <InputList value={Array.isArray(value) ? (value as string[]) : []} onChange={v => onChange(v)} />
    case AttrType.String:
    case AttrType.Number:
    default:
      return (
        <Input
          size="sm"
          type={type == AttrType.Number ? 'number' : 'text'}
          value={value as string}
          onChange={e => {
            if (type == AttrType.Number) {
              if (e.target.value) {
                let v = parseInt(e.target.value)
                if (e.target.value && isNaN(v)) {
                  return false
                }
                onChange(v)
              } else {
                onChange(undefined)
              }
            } else {
              onChange(e.target.value)
            }
          }}
        />
      )
  }
}
