import { Box } from '@mui/material'
import { Handle, Position } from 'reactflow'
import { useContext, useMemo } from 'react'

import { useTheme } from '@mui/material/styles'
import {
  DescBlock,
  IOHandle,
  IOHandleSize,
  getIOStyle,
  getLineColor,
  getNodeHoverStyle,
  getWarningLineColor,
} from './common'
import { getNodeColor, getNodeIcon } from '../icons'
import EdgeLabel from './EdgeLabel'
import PlusButton from './PlusButton'
import { ADD_NEXT_PREFIX_ID } from '../Settings'
import { ConditionNode, GraphRoot } from '../../../api/dashboard'
import { ExitNode } from '../components/ExitNode/ExitNode'
import { IBlockProps } from '../types'
import { calculateWarning } from '../util'
import { BranchLeft } from '../components/BranchLeft/BranchLeft'
import { CampaignContext, ICampaignContext } from '../Context'
import { BranchRight } from '../components/BranchRight/BranchRight'

export const CONDITION_NODE_WIDTH = 332

function hasTrueNextNode(graph: GraphRoot, node: ConditionNode): boolean {
  if (!graph.nodes) {
    return false
  }
  return !!(node.true_node_id && graph.nodes[node.true_node_id])
}

function hasFalseNextNode(graph: GraphRoot, node: ConditionNode): boolean {
  if (!graph.nodes) {
    return false
  }
  return !!(node.false_node_id && graph.nodes[node.false_node_id])
}

export default function ConditionBlock(props: IBlockProps) {
  const theme = useTheme()
  const color = theme.palette.text.input
  const nodeColor = getNodeColor(props.data.node.model_type)
  const context = useContext(CampaignContext) as ICampaignContext

  const [warningText, warningDescText] =
    useMemo(() => {
      return calculateWarning(props.data.node, props.data.graph)
    }, [props.data.graph]) || []

  return (
    <>
      <IOHandle color={color} position={Position.Top} type="target" id="input" />

      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          ...getNodeHoverStyle(nodeColor),
        }}
        width={theme.spacing(8.5)}
        height={theme.spacing(8.5)}
        alignItems="center"
        justifyContent="center"
        display="flex"
      >
        <Box
          sx={{
            transform: 'rotate(45deg)',
            position: 'absolute',
            backgroundColor: theme.palette.background.content,
            border: `2px solid ${nodeColor}`,
            boxShadow: props.selected ? `1px 4px 22px 0px ${nodeColor}` : '',
          }}
          width="75%"
          height="75%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        />

        <Box
          sx={{ position: 'absolute' }}
          component="span"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {getNodeIcon(props.data.node.model_type)}
        </Box>

        <DescBlock node={props.data.node} width={'auto'} selected={props.selected} />
      </Box>
      <IOHandle color={color} position={Position.Bottom} type="source" id="output" isConnectable={false} />

      {!hasFalseNextNode(props.data.graph, props.data.node as ConditionNode) && (
        <Box position="absolute" top={`calc(100% + ${theme.spacing(IOHandleSize / 3)})`} left="-149px">
          <BranchLeft
            animate={context.historyMode && props.data.node.result === 'False'}
            style={{ color: warningText ? getWarningLineColor(theme) : getLineColor(theme) }}
          />
          <EdgeLabel
            value={'FALSE'}
            sx={{
              top: '82px',
              left: '66px',
            }}
          />
          <PlusButton
            visible={true}
            id={ADD_NEXT_PREFIX_ID + props.data.node.id}
            color={warningText ? getWarningLineColor(theme) : getLineColor(theme)}
            style={{
              position: 'absolute',
              top: '128px',
              left: '-5px',
            }}
            onClick={(node: HTMLElement) => props.data.addClick(node, false)}
          />
          <Handle
            type={'source'}
            id={'output-false'}
            position={Position.Bottom}
            isConnectable={true}
            style={{
              ...getIOStyle(theme),
              borderColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
              left: theme.spacing(1),
              bottom: theme.spacing(-1),
              zIndex: 0,
            }}
          />
          <ExitNode
            uiHandle={false}
            warningText={warningText}
            warningDescText={warningDescText}
            style={{
              left: '-27px',
              position: 'absolute',
              bottom: '-42px',
            }}
          />
        </Box>
      )}

      {!hasTrueNextNode(props.data.graph, props.data.node as ConditionNode) && (
        <Box position="absolute" top={`calc(100% + ${theme.spacing(IOHandleSize / 3)})`} right="-149px">
          <BranchRight
            animate={context.historyMode && props.data.node.result === 'True'}
            style={{ color: warningText ? getWarningLineColor(theme) : getLineColor(theme) }}
          />
          <EdgeLabel
            value={'TRUE'}
            sx={{
              top: '82px',
              left: '66px',
            }}
          />
          <PlusButton
            visible={true}
            color={warningText ? getWarningLineColor(theme) : getLineColor(theme)}
            id={ADD_NEXT_PREFIX_ID + props.data.node.id}
            style={{
              position: 'absolute',
              top: '128px',
              right: '-5px',
            }}
            onClick={(node: HTMLElement) => props.data.addClick(node, true)}
          />
          <Handle
            type={'source'}
            id={'output-true'}
            position={Position.Bottom}
            isConnectable={true}
            style={{
              ...getIOStyle(theme),
              borderColor: warningText ? getWarningLineColor(theme) : getLineColor(theme),
              left: 'auto',
              right: theme.spacing(-1),
              bottom: theme.spacing(-1),
              zIndex: 0,
            }}
          />
          <ExitNode
            uiHandle={false}
            warningText={warningText}
            warningDescText={warningDescText}
            style={{
              right: '-27px',
              position: 'absolute',
              bottom: '-42px',
            }}
          />
        </Box>
      )}
    </>
  )
}
