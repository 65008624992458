import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { INSERT_PARAGRAPH_COMMAND, LexicalEditor } from 'lexical'
import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  formatHookFormErrors,
} from '@dashboard/ui'
import { FieldGroup } from '@/ui'
import { youtubeLinkRegexp } from '../../../../libs/string'
import { INSERT_YOUTUBE_COMMAND } from './YouTubePlugin'

interface YouTubeInsertModalProps extends ModalProps {
  editor: LexicalEditor
}

interface YouTubeInsertData {
  url: string
}

const parseYouTubeUrl = (url: string) => {
  const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url)
  return match ? (match?.[2].length === 11 ? match[2] : null) : null
}

export const YouTubeInsertModal = ({ editor, ...rest }: YouTubeInsertModalProps) => {
  const { t } = useTranslation()

  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
  } = useForm<YouTubeInsertData>({
    defaultValues: {
      url: '',
    },
  })

  const onSubmit = handleSubmit((data: YouTubeInsertData) => {
    const id = parseYouTubeUrl(data.url)
    if (id) {
      editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, id)
      editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined)
      rest.onClose?.()
    }
  })

  return (
    <Modal {...rest}>
      <ModalTitle>{t('lexical.insert-youtube-modal.title')}</ModalTitle>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <FieldGroup label={t('lexical.insert-youtube-modal.label')}>
            <Input
              {...register('url', {
                required: t('validation.required'),
                pattern: { value: youtubeLinkRegexp, message: t('validation.invalid_url') },
              })}
              {...formatHookFormErrors(errors, 'url')}
              autoFocus={true}
              placeholder="https://www.youtube.com/watch?v="
            />
          </FieldGroup>
        </ModalContent>
        <ModalFooter>
          <Button variant="outline" color="secondary" type="button" onClick={rest.onClose}>
            {t('lexical.insert-youtube-modal.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('lexical.insert-youtube-modal.save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
