import ThemeLangOutlet from '@/auth/ThemeLangOutlet'
import ErrorBlock from './components/ErrorBlock'

export default function ErrorPage() {
  return (
    <ThemeLangOutlet>
      <ErrorBlock title="Error" text="Unexpected application error" />
    </ThemeLangOutlet>
  )
}
