import { FormSection } from '@/layouts/game-items'
import { FieldDivider, FieldGroup, SelectMulti } from '@/ui'
import { Button, Input, MOTION_ANIMATION_SPEED_MD, Textarea, formatHookFormErrors, useModal } from '@dashboard/ui'
import { Controller, useFormContext } from 'react-hook-form'
import { ItemCategoryRead } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import { SkuFormData } from '@/layouts/game-items/types'
import { useTranslation } from 'react-i18next'
import { EditItemCategoryDialog, itemCategoriesQuery } from '@/layouts/item-category'
import { useQuery } from '@tanstack/react-query'
import { DEFAULT_LOAD_LIMIT } from '@/api/useGameItems'
import { prepareSLUG } from '@/util'

export const SkuGeneralFields = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<SkuFormData>()

  const { data: allCategories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: DEFAULT_LOAD_LIMIT }),
    refetchOnMount: true,
  })

  const openCreateCategoryModal = useModal<{ item: ItemCategoryRead }>(props => (
    <EditItemCategoryDialog
      {...props}
      created={newCat => {
        props.onClose()
        // @ts-ignore
        setValue('categories', categories?.length ? [...categories, newCat.id] : [newCat.id], {
          shouldDirty: true,
        })
      }}
    />
  ))

  return (
    <FormSection>
      <FieldGroup label={t('game-item-edit-dialog.name')}>
        <Input
          {...register('name', {
            required: t('validation.required'),
            maxLength: {
              message: t('validation.maxLength', {
                field: t('game-item-edit-dialog.name'),
                value: 255,
              }),
              value: 255,
            },
          })}
          {...formatHookFormErrors(errors, 'name')}
          placeholder={t('sku.item.name.placeholder')}
          autoFocus={true}
        />
      </FieldGroup>

      <FieldGroup label={t('game-item-edit-dialog.description')}>
        <Textarea
          {...register('description', {
            maxLength: {
              message: t('validation.maxLength', {
                field: t('game-item-edit-dialog.description'),
                value: 255,
              }),
              value: 255,
            },
          })}
          {...formatHookFormErrors(errors, 'description')}
          placeholder={t('sku.item.description.placeholder')}
          rows={6}
        />
      </FieldGroup>

      <FieldDivider />

      <div className="flex w-full gap-[18px]">
        <FieldGroup label={t('sku.item.category')} className="w-1/2">
          <Controller
            control={control}
            name="categories"
            render={({ field }) => (
              <SelectMulti
                items={allCategories.map(item => ({
                  children: item.name,
                  value: item.id,
                }))}
                value={field.value || []}
                onChange={v => {
                  field.onChange(v)
                }}
                placeholder={t('sku.item.select-category')}
                renderMenuFooter={state => (
                  <Button
                    className="w-full"
                    variant="outline"
                    size="md"
                    onClick={() => {
                      state.setIsOpen(false)
                      setTimeout(() => {
                        openCreateCategoryModal({
                          item: {
                            name: '',
                            description: '',
                            position: allCategories.length,
                          } as ItemCategoryRead,
                        })
                      }, MOTION_ANIMATION_SPEED_MD * 500)
                    }}
                  >
                    {t('item-category.add')}
                  </Button>
                )}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={t('sku.item.sku')} tooltip={t('sku.sku-desc')} className="w-1/2">
          <Controller
            control={control}
            name="sku"
            rules={{
              required: t('validation.required'),
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('sku.item.sku'),
                  value: 100,
                }),
                value: 100,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'sku')}
                onChange={e => field.onChange(prepareSLUG(e.target.value))}
              />
            )}
          />
        </FieldGroup>
      </div>
    </FormSection>
  )
}
