import { AddStoreItemsNode, Node, ResourceState, StoreItemCreate } from '../../../api/dashboard'
import { BlockError } from '../../campaigns/types'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'
import i18next from 'i18next'
import { FieldGroup, FieldValidationMessage, Input, InputExtraMark } from '@/ui'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../api/useGameItems'
import { useParams } from 'react-router-dom'
import { FieldSection } from '../../../components/ui/FieldSection'
import Divider from '../../../components/Divider'
import { Controller, useForm } from 'react-hook-form'
import SettingsHeader from '../../campaigns/SettingsHeader'
import { ErrorMessage } from '@hookform/error-message'

export default function AddStoreItemsNodeEditor(props: {
  node: AddStoreItemsNode
  error?: BlockError | null
  setNode: (node: Node) => void
  onClose: () => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  const {
    register,
    watch,
    formState: { errors },
    control,
    handleSubmit,
  } = useForm<StoreItemCreate>({
    defaultValues: {
      ...props.node.item,
    },
  })

  const onSaveClick = (data: StoreItemCreate) => {
    if (!data.bonus_percent) {
      data.bonus_percent = null as unknown as number
    }

    if (!data.discount_percent) {
      data.discount_percent = null as unknown as number
    }

    props.setNode({
      ...props.node,
      item: data,
    } as Node)
    props.onClose()
  }

  const itemId = watch('item_id')
  const currentItem = items?.find(item => item.id == itemId)

  return (
    <>
      <SettingsHeader node={props.node as Node} onClose={props.onClose} onSave={handleSubmit(onSaveClick)} />

      <div className="p-6">
        <FieldSection label={i18next.t('store.AddStoreItemsNode.general')}>
          <FieldGroup label={i18next.t('store.AddStoreItemsNode.select')}>
            <Controller
              control={control}
              name="item_id"
              rules={{ required: i18next.t('validation.required') }}
              render={({ field }) => <SelectSkuItem {...field} />}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
            />
          </FieldGroup>
        </FieldSection>

        <Divider />

        <FieldSection label={i18next.t('store.AddStoreItemsNode.benefits')}>
          {currentItem && (
            <>
              <FieldGroup label={i18next.t('store.AddStoreItemsNode.discount_percent')}>
                <Input
                  type={'number'}
                  {...register('discount_percent', {
                    min: { value: 1, message: i18next.t('coupon.discount_percent.validation') },
                    max: { value: 99, message: i18next.t('coupon.discount_percent.validation') },
                  })}
                  extraRight={<InputExtraMark>%</InputExtraMark>}
                />
                <ErrorMessage
                  name="discount_percent"
                  errors={errors}
                  render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                />
              </FieldGroup>

              {currentItem.is_stackable && (
                <FieldGroup label={i18next.t('store.AddStoreItemsNode.bonus_percent')}>
                  <Input
                    type={'number'}
                    {...register('bonus_percent', {
                      min: 1,
                      max: 1000,
                    })}
                    extraRight={<InputExtraMark>%</InputExtraMark>}
                  />
                  <ErrorMessage
                    name="bonus_percent"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </FieldGroup>
              )}
            </>
          )}
        </FieldSection>
      </div>
    </>
  )
}
