import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

interface SequentialOfferMutate {
  id: string
}

export const useSequentialOfferDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: SequentialOfferMutate) => dashboardClient.v1.deleteSequentialOffer(companyId, gameId, id),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ['sequential_offer', gameId, variables.id] })
      queryClient.invalidateQueries({ queryKey: ['sequential_offers', gameId], exact: false })
    },
  })
}
