import { dashboardClient } from '@/api'
import { infiniteQueryOptions } from '@tanstack/react-query'

export const getPriceTemplatesInfinityOptions = (companyId: string, gameId: string) =>
  infiniteQueryOptions({
    queryKey: [gameId, 'sku-price-templates'],
    queryFn: async () => {
      const { data } = await dashboardClient.v1.getPriceTemplates(companyId, gameId, { limit: 100 })
      return data
    },
    initialPageParam: null,
    getNextPageParam: lastPage => {
      if (lastPage.has_more) {
        return null
      }
      return lastPage.next_cursor
    },
    select: data => data.pages.flatMap(page => page.data),
  })
