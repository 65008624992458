import { MouseEvent, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CountryFlag, Skeleton } from '@dashboard/ui'
import { PriceTemplateRowPriceInlineEdit } from '@/layouts/sku-price-templates'
import { Platform, PriceCategory, TaxRead } from '@/api/dashboard'
import { TableCell, TableRow } from '@/ui'
import { PriceTemplateExtendedPrice } from '../types'
import { getPriceMinorUnitFactor } from '@/util'

interface PriceTemplateRowPriceProps {
  price: PriceTemplateExtendedPrice
  view: {
    category: PriceCategory
    showCustomBadge: boolean
  }
  /**
   * Tax by country
   * - `undefined` if tax is loading
   * - `null` if tax is not available
   */
  tax?: TaxRead | null
  onChange: (value: number, platform?: Platform) => void
  onReset: () => Promise<void>
}

const clickFirstCellChild = (element: Element | ChildNode | undefined | null) => {
  if (element) {
    ;(element.firstChild as HTMLDivElement | null)?.click()
  }
}

export const PriceTemplateRowPrice = ({ price, view, tax, onChange, onReset }: PriceTemplateRowPriceProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('countries')

  const minorFactor = useMemo(() => getPriceMinorUnitFactor(price.currency), [price.currency])

  const onClickRow = useCallback(
    (e: MouseEvent) => {
      // @ts-ignore
      if (e.type === 'keydown' && e.key === 'Enter') {
        if (view.category === PriceCategory.Platform) {
          clickFirstCellChild(e.currentTarget?.querySelector('[data-platform]'))
        } else {
          clickFirstCellChild(e.currentTarget?.querySelector('[data-price=true]'))
        }
      }
    },
    [view.category],
  )

  const onChangePrice = useCallback(
    (platform?: Platform) =>
      (value: number | undefined, focusRow: 'next' | 'prev' | undefined, focusCell?: 'next' | 'prev') => {
        if (value) {
          onChange(value * minorFactor, platform)
        }

        if (focusRow) {
          ref.current?.focus()
          ref.current?.dispatchEvent(
            focusRow === 'next'
              ? new KeyboardEvent('keydown', { key: 'ArrowDown', code: 'ArrowDown', bubbles: true })
              : new KeyboardEvent('keydown', { key: 'ArrowUp', code: 'ArrowUp', bubbles: true }),
          )
          return
        }

        if (focusCell && platform) {
          const platformCell = (
            focusCell === 'next'
              ? ref.current?.querySelector(`[data-platform="${platform}"]`)?.nextSibling
              : ref.current?.querySelector(`[data-platform="${platform}"]`)?.previousSibling
          ) as HTMLDivElement | undefined
          clickFirstCellChild(platformCell)
          return
        }

        ref.current?.focus()
      },
    [onChange],
  )

  const onDiscard = useCallback(() => {
    ref.current?.focus()
  }, [])

  const renderPriceCells = useCallback(() => {
    if (view.category === PriceCategory.Platform && price.platformPrice) {
      return (
        <>
          {Object.entries(price.platformPrice).map(([platform, value]) => (
            <TableCell
              key={`price-cell-price-platform-${price.country}-${price.currency}-${platform}`}
              className="h-full py-0"
              data-platform={platform}
              width="40%"
            >
              <PriceTemplateRowPriceInlineEdit
                price={{
                  price: value?.v || price.price,
                  currency: price.currency,
                  is_custom: view.showCustomBadge && price.is_custom,
                }}
                check={value?.check || undefined}
                onChange={onChangePrice(platform as Platform)}
                onDiscard={onDiscard}
                onReset={onReset}
              />
            </TableCell>
          ))}
        </>
      )
    }

    return (
      <TableCell width="40%" data-price={true}>
        <PriceTemplateRowPriceInlineEdit
          price={{ price: price.price, currency: price.currency, is_custom: view.showCustomBadge && price.is_custom }}
          check={price.check || undefined}
          onChange={onChangePrice(undefined)}
          onDiscard={onDiscard}
          onReset={onReset}
        />
      </TableCell>
    )
  }, [price, view.category, view.showCustomBadge, onChangePrice, onDiscard, onReset])

  return (
    <TableRow ref={ref} className="h-[60px]" variant="clickable" a11y={{ onClick: onClickRow }}>
      <TableCell width="100%">
        <div className="flex items-center gap-3 font-medium">
          {price.country && <CountryFlag country={price.country} />}
          <div className="text-text-secondary">{t(price.country as string)}</div>
        </div>
      </TableCell>

      {renderPriceCells()}

      <TableCell width="35%">
        {tax ? (
          <div className="flex flex-col gap-0.5">
            <div className="font-normal leading-[20px] text-text-tertiary">{Number((tax.rate * 100).toFixed(2))}%</div>
            <div className="text-xs font-normal leading-[14px] text-text-quarterary-hover">
              {tax.included ? 'Included' : 'Excluded'}
            </div>
          </div>
        ) : (
          <>
            {tax === null ? (
              <div className="flex flex-col gap-0.5">
                <div className="font-normal leading-[20px] text-text-tertiary">
                  <Skeleton className="h-[18px] w-1/12" />
                </div>
                <div className="text-xs font-normal leading-[14px] text-text-quarterary-hover">
                  <Skeleton className="h-[14px] w-1/2" />
                </div>
              </div>
            ) : (
              '–'
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  )
}
