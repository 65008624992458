import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'

import { getErrorText } from '@/api'
import { LoyaltyTier } from '@/api/dashboard'
import { DeleteOutline, Edit03, PlusContained } from '@/icons'
import { ButtonIcon, ToastSeverity, useToast } from '@/ui'
import { useLoyaltyTiersUpdate } from '@/layouts/engagement/hooks'
import { LoyaltyRankBadge } from '@/layouts/engagement/component'
import { SkuItemsField } from '@/layouts/engagement/widgets'
import { cn } from '@dashboard/ui'
import { AddButton } from '@/layouts/components/AddButton'

export type Tier = Omit<LoyaltyTier, 'id'> & { uid?: string }

export interface LoyaltyTiersFormInput {
  settingsID: string
  tiers: Tier[]
}

interface LoyaltyTiersFormProps {
  id: string
  preview: boolean
  togglePreview: () => void
  setModifyTierIndex: (index: number | null) => void
}

export const LoyaltyTiersForm = ({ id, preview = true, togglePreview, setModifyTierIndex }: LoyaltyTiersFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    clearErrors,
    trigger,
    formState: { isSubmitting, isSubmitted },
  } = useFormContext<LoyaltyTiersFormInput>()
  const { settingsID, tiers } = getValues()

  const remove = async (index: number) => {
    setValue(
      'tiers',
      tiers.filter((_, i) => i !== index),
      { shouldDirty: true },
    )
    if (isSubmitted) {
      clearErrors()
      await trigger('tiers')
    }
  }

  const showToast = useToast()
  const { mutateAsync: updateTiersAsync } = useLoyaltyTiersUpdate(settingsID)

  const onSubmit = handleSubmit(async data => {
    try {
      await updateTiersAsync(
        data.tiers.map(tier => ({
          ...tier,
          id: tier.uid,
          items: tier.items.map(item => ({ id: item.id, count: item.count })),
        })),
      )
      showToast({ message: t('saved'), severity: ToastSeverity.success })
      togglePreview()
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  })

  return (
    <form className="flex flex-col gap-[18px]" id={id} onSubmit={onSubmit}>
      {tiers.map((tier, index) => (
        <div key={`${index}-${tier.items.map(item => item.id).join('-')}`} className="flex flex-col gap-[18px]">
          <div className={cn(!preview && 'rounded-b-2xl rounded-t-xl bg-fg-secondary')}>
            <div className="inline-flex w-full justify-between gap-3 p-1.5">
              <LoyaltyRankBadge
                image_url={tier.image_url}
                rank={t('loyalty.level.title', { lv: index + 1 })}
                points={tier.points}
                name={tier.name}
              />
              {!preview && (
                <div className="h-6 shrink-0">
                  <ButtonIcon
                    size="xs"
                    variant="tertiary-gray"
                    onClick={() => setModifyTierIndex(index)}
                    disabled={isSubmitting}
                  >
                    <Edit03 />
                  </ButtonIcon>
                  <ButtonIcon
                    size="xs"
                    variant="tertiary-destructive"
                    onClick={async () => await remove(index)}
                    disabled={isSubmitting}
                  >
                    <DeleteOutline />
                  </ButtonIcon>
                </div>
              )}
            </div>
            <div
              className={cn(
                'border p-1.5',
                preview ? 'border-transparent' : 'rounded-2xl border-border-primary bg-fg-primary',
              )}
            >
              <SkuItemsField
                {...register(`tiers.${index}.items`, {
                  required: t('loyalty.level.items.required'),
                })}
                countable
                preview={preview}
                addText={t('loyalty.level.add.reward')}
                pickerTitle={t('loyalty.level.add.item')}
              />
            </div>
          </div>
          {!preview && (
            <div className="size-full p-2">
              <AddButton disabled={isSubmitting} icon={PlusContained} onClick={() => setModifyTierIndex(null)}>
                {t('loyalty.level.add')}
              </AddButton>
            </div>
          )}
        </div>
      ))}
      {!preview && tiers.length == 0 && (
        <div className="size-full p-2">
          <AddButton disabled={isSubmitting} icon={PlusContained} onClick={() => setModifyTierIndex(null)}>
            {t('loyalty.level.add')}
          </AddButton>
        </div>
      )}
    </form>
  )
}
