import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
}

export const useRangeOffersQuery = (companyId: string, gameId: string, query: Query = {}) => {
  return useQuery({
    queryKey: ['range_offers', gameId, query],
    select: response => response.data,
    queryFn: () => dashboardClient.v1.getRangeOffers(companyId, gameId, query),
  })
}
