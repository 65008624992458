import { useContext, useEffect } from 'react'
import { MobileContext } from '@/providers'
import { cn } from '@dashboard/ui'

export function MobileOverlay() {
  const { setLeftOpened, setRightOpened, leftOpened, rightOpened } = useContext(MobileContext)

  const handleClick = () => {
    setLeftOpened(false)
    setRightOpened(false)
  }

  const overlayIsOpen = leftOpened || rightOpened

  useEffect(() => {
    if (overlayIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.removeProperty('overflow')
    }
  }, [overlayIsOpen])

  return (
    <div
      onClick={handleClick}
      className={cn('fixed inset-0 z-40 bg-black/40', !overlayIsOpen && 'pointer-events-none opacity-0')}
    />
  )
}
