import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LexicalEditor } from 'lexical'
import { ErrorMessage } from '@hookform/error-message'
import {
  Button,
  FormErrorMessage,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
} from '@dashboard/ui'
import { FieldGroup, ImageUploader } from '@/ui'
import { ImageIndentFull, ImageIndentLeft, ImageIndentRight } from '@/icons'
import { S3Bucket } from '../../../../types'
import { InlineImagePayload } from './ImageNode'
import { INSERT_INLINE_IMAGE_COMMAND } from './ImagePlugin'

interface ImagePluginInsertModalProps extends ModalProps {
  editor: LexicalEditor
}

export const ImagePluginInsertModal = ({ editor, ...rest }: ImagePluginInsertModalProps) => {
  const { t } = useTranslation()

  const {
    formState: { isSubmitting, errors },
    control,
    handleSubmit,
  } = useForm<InlineImagePayload>({
    defaultValues: {
      position: 'full',
      src: '',
    },
  })

  const onSubmit = (data: InlineImagePayload) => {
    editor.dispatchCommand(INSERT_INLINE_IMAGE_COMMAND, { ...data, alt: '' })
    rest.onClose?.()
  }

  return (
    <Modal {...rest}>
      <ModalTitle>{t('lexical.insert-image-modal.title')}</ModalTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <FieldGroup label={t('lexical.insert-image-modal.align.label')}>
            <Controller
              name="position"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  items={[
                    {
                      children: t('lexical.insert-image-modal.align.left'),
                      value: 'left',
                      icon: ImageIndentLeft,
                    },
                    {
                      children: t('lexical.insert-image-modal.align.full'),
                      value: 'full',
                      icon: ImageIndentFull,
                    },
                    {
                      children: t('lexical.insert-image-modal.align.right'),
                      value: 'right',
                      icon: ImageIndentRight,
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup>
            <Controller
              name="src"
              control={control}
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <ImageUploader
                  {...field}
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                    'image/gif': [],
                  }}
                  bucket={S3Bucket.news}
                />
              )}
            />
            <ErrorMessage
              name="src"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </ModalContent>

        <ModalFooter>
          <Button variant="outline" color="secondary" type="button" onClick={rest.onClose}>
            {t('lexical.insert-image-modal.cancel')}
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            {t('lexical.insert-image-modal.save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
