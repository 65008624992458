import { useEffect, useState } from 'react'
import { Link, generatePath, useLocation, useParams } from 'react-router-dom'
import { Archive, Button, ModalPresetConfirm, Skeleton, useModal, useSearchFilter } from '@dashboard/ui'
import { Campaign, ResourceState } from '@/api/dashboard'
import { dashboardClient, getErrorText } from '@/api'
import { CampaignRow } from './CampaignRow'
import getCampaignName from './getCampaignName'
import { LIVEOPS_DETAILS_PATH, LIVEOPS_SETTINGS_PATH } from '@/libs'
import {
  ModalProps,
  PageHeader,
  Pagination,
  Tab,
  TableZeroState,
  Tabs,
  ToastSeverity,
  usePagination,
  useToast,
} from '@/ui'
import { useQuery } from '@tanstack/react-query'
import { campaignsQuery } from '../../api'
import { Plus } from '@/icons'
import CreateCampaignModal from '@/layouts/campaigns/widgets/CreateCampaignModal'
import { useCurrentUser } from '@/api/useCurrentUser'
import { useTranslation } from 'react-i18next'
import { campaignsCountQuery } from '@/layouts/campaigns/api/campaignsCountQuery'

enum TabState {
  all = 'all',
  draft = 'draft',
  archived = 'archived',
}

export default function CampaignsTable() {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
    eventId?: string
  }
  const { t } = useTranslation()
  const location = useLocation()
  const { state } = location
  const { canEdit } = useCurrentUser()
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const showToast = useToast()
  const filter = useSearchFilter<'state'>()
  const { page, query, onPageChange, needShowPagination } = usePagination(10)

  const getResourceQueryParam = (): ResourceState | undefined => {
    switch (filter.values.state) {
      case TabState.all:
        return undefined
      case ResourceState.Archived:
        return ResourceState.Archived
      case TabState.draft:
      default:
        return ResourceState.Active
    }
  }

  const getEnabledQueryParam = (): boolean | undefined => {
    switch (filter.values.state) {
      case TabState.draft:
        return false
      case TabState.all:
      case ResourceState.Archived:
        return undefined
      default:
        return true
    }
  }

  const {
    data: items = [],
    refetch: refetchItems,
    isLoading,
  } = useQuery(
    campaignsQuery(companyId, gameId, {
      ...query,
      enabled: getEnabledQueryParam(),
      resource_state: getResourceQueryParam(),
    }),
  )

  const { data: countData, refetch: refetchCount } = useQuery(campaignsCountQuery(companyId, gameId))

  const refetch = () => {
    refetchCount()
    refetchItems()
  }

  const onConfirmDeleteClick = async (id: string) => {
    await dashboardClient.v1.deleteCampaign(companyId, gameId, id)
    refetch()
  }

  const onEnableChange = async (item: Campaign, value: boolean) => {
    try {
      setLoadingIds(prev => [...prev, item.id])

      await dashboardClient.v1.updateCampaign(companyId, gameId, item.id, {
        enabled: value,
      })
      await refetch()
    } catch (e) {
      showToast({ message: t(`server-errors.${getErrorText(e)}`), severity: ToastSeverity.error })
    } finally {
      setLoadingIds(prev => prev.filter(id => id !== item.id))
    }
  }

  const onArchivedChange = async (item: Campaign) => {
    const update = {
      archived_at: (item.archived_at ? null : new Date().getTime() / 1000) as number,
      enabled: undefined as boolean | undefined,
    }

    if (update.archived_at) {
      update.enabled = false
    }

    await dashboardClient.v1.updateCampaign(companyId, gameId, item.id, update)
    await refetch()
  }

  const onCopy = async (item: Campaign) => {
    const { data: graph } = await dashboardClient.v1.getCampaignGraph(companyId, gameId, item.id)

    await dashboardClient.v1.createCampaign(companyId, gameId, {
      graph: graph,
      enabled: false,
      name: item.name,
      description: item.description ? `${item.description} - copy` : 'Copy',
      type: item.type,
      attributes: item.attributes,
    })

    if (filter.values.state == ResourceState.Archived) {
      filter.onChange({ ...filter.values, state: '' }, { force: true })
    } else {
      await refetch()
    }
  }

  const renderSkeleton = () => {
    return new Array(10).fill('').map((_, idx) => <Skeleton className="h-[84px]" key={idx} />)
  }

  const openCreateModal = useModal<ModalProps>(props => <CreateCampaignModal {...props} />)

  const openConfirmArchive = useModal<{ campaign: Campaign }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t(props.campaign.archived_at ? 'campaign.confirm.unarchive' : 'campaign.confirm.archive', {
          name: getCampaignName(props.campaign.type),
        }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: props.campaign.archived_at ? 'primary' : 'danger',
        callback: () => {
          props.onClose()
          onArchivedChange(props.campaign)
        },
        label: (
          <>
            <Archive />
            {t(props.campaign.archived_at ? 'unarchive' : 'archive')}
          </>
        ),
      }}
    />
  ))

  const openConfirmDelete = useModal<{ campaign: Campaign }>(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t('campaign.confirm.text', { name: getCampaignName(props.campaign.type) }),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: 'danger',
        callback: () => {
          props.onClose()
          onConfirmDeleteClick(props.campaign.id)
        },
        label: t('remove'),
      }}
    />
  ))

  const openConfirmEnabled = useModal<{ campaign: Campaign; value: boolean }>(props => (
    <ModalPresetConfirm
      {...props}
      size="md"
      title={{
        children: t(props.value ? 'campaign.confirm.enable' : 'campaign.confirm.disable', {
          name: getCampaignName(props.campaign.type),
        }),
        subtitle: t(props.value ? 'campaign.confirm.enable.desc' : 'campaign.confirm.disable.desc'),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        color: props.value ? 'primary' : 'danger',
        callback: async () => {
          await onEnableChange(props.campaign, props.value)
          props.onClose()
        },
        label: t(props.value ? 'campaign.confirm.enable.button' : 'campaign.confirm.disable.button'),
      }}
    />
  ))

  useEffect(() => {
    if (state?.openCreateCampaign) {
      state.openCreateCampaign = null
      setTimeout(() => openCreateModal(), 300)
    }
  }, [state])

  const renderZeroState = () => {
    if (filter.values.state == ResourceState.Archived) {
      return (
        <TableZeroState
          title={t('campaigns.empty-table.title.archived')}
          message={t('campaigns.empty-table.title.archived.message')}
        />
      )
    }

    return (
      <TableZeroState
        title={t('campaigns.empty-table.title')}
        message={t('campaigns.empty-table')}
        buttons={
          <>
            {canEdit && (
              <Button size="sm" onClick={() => openCreateModal()}>
                <Plus size={16} />
                <span>{t('campaigns.new')}</span>
              </Button>
            )}
          </>
        }
      />
    )
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader
        extra={
          canEdit && (
            <>
              <Link to={generatePath(LIVEOPS_SETTINGS_PATH, { companyId, gameId })}>
                <Button variant="outline" color="secondary" size="sm">
                  {t('campaign.settings')}
                </Button>
              </Link>
              {!!items.length && (
                <Button size="sm" onClick={() => openCreateModal()}>
                  {t('campaigns.add')}
                </Button>
              )}
            </>
          )
        }
      >
        {t('sidebar.campaigns')}
      </PageHeader>

      <Tabs className="mb-4">
        <Tab
          count={countData?.all}
          isActive={filter.values.state == TabState.all}
          onClick={() => {
            filter.onChange({ ...filter.values, state: TabState.all }, { force: true })
          }}
        >
          {t('campaigns.all')}
        </Tab>
        <Tab
          count={countData?.enabled}
          isActive={!filter.values.state}
          onClick={() => {
            filter.onChange({ ...filter.values, state: undefined }, { force: true })
          }}
        >
          {t('campaigns.active')}
        </Tab>
        <Tab
          count={countData?.disabled}
          isActive={filter.values.state == TabState.draft}
          onClick={() => {
            filter.onChange({ ...filter.values, state: TabState.draft }, { force: true })
          }}
        >
          {t('campaigns.draft')}
        </Tab>
        <Tab
          count={countData?.archived}
          isActive={filter.values.state == TabState.archived}
          onClick={() => {
            filter.onChange({ ...filter.values, state: TabState.archived }, { force: true })
          }}
        >
          {t('campaigns.archived')}
        </Tab>
      </Tabs>

      {isLoading ? (
        <div className="flex flex-col gap-3">{renderSkeleton()}</div>
      ) : items.length === 0 && page == 1 ? (
        renderZeroState()
      ) : (
        <div className="flex flex-col">
          {items.map(it => (
            <CampaignRow
              key={it.id}
              onCopyClick={() => onCopy(it)}
              loading={loadingIds.includes(it.id)}
              to={generatePath(LIVEOPS_DETAILS_PATH, { companyId, gameId, eventId: it.id })}
              campaign={it}
              canEdit={canEdit}
              onArchiveChange={() => openConfirmArchive({ campaign: it })}
              onDeleteClick={() => openConfirmDelete({ campaign: it })}
              onClickToggle={() => openConfirmEnabled({ campaign: it, value: !it.enabled })}
            />
          ))}
        </div>
      )}

      {needShowPagination(isLoading, items) && (
        <Pagination
          hasNext={items.length >= query.limit}
          page={page}
          onPageChange={onPageChange}
          pageItems={items.length}
        />
      )}
    </div>
  )
}
