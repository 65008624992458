import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const NumberedList01: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 11.0054V5.59464C6.6 5.23508 6.28493 5 5.9699 5H5.05983C4.94722 5 4.83491 5.02843 4.73565 5.08452L3.30592 5.89249C3.12621 5.99405 3 6.18241 3 6.40261V6.83298C3 7.08423 3.15678 7.27054 3.33742 7.35976C3.51758 7.44875 3.74885 7.45805 3.95192 7.34443L4.57877 6.99369V11.0054C4.57877 11.3649 4.89385 11.6 5.20888 11.6H5.9699C6.28493 11.6 6.6 11.3649 6.6 11.0054ZM8 6C8 5.44772 8.44772 5 9 5H21C21.5523 5 22 5.44772 22 6C22 6.55229 21.5523 7 21 7L9 7C8.44772 7 8 6.55228 8 6ZM8 12C8 11.4477 8.44772 11 9 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L9 13C8.44772 13 8 12.5523 8 12ZM8 18C8 17.4477 8.44772 17 9 17L21 17C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19L9 19C8.44772 19 8 18.5523 8 18ZM6.81097 15.563C6.72745 15.7963 6.58397 16.0406 6.392 16.295L6.39143 16.2958C6.19989 16.5478 5.93516 16.8439 5.60097 17.1828L5.288 17.5244H6.50192C6.79234 17.5244 7 17.7814 7 18.0614V18.463C7 18.743 6.79234 19 6.50192 19H3.53263C3.24221 19 3.03455 18.743 3.03455 18.463V18.023C3.03455 17.8791 3.08824 17.7381 3.18826 17.6349L4.82964 15.942C4.97157 15.7889 5.08845 15.6535 5.18128 15.5353L5.18256 15.5336C5.27122 15.4225 5.33325 15.3202 5.37339 15.2269C5.40955 15.1412 5.42864 15.047 5.42864 14.9411C5.42864 14.823 5.40477 14.7372 5.36832 14.6728L5.36744 14.6713C5.32723 14.5992 5.27495 14.5471 5.20732 14.51L5.20462 14.5085C5.1353 14.4693 5.05218 14.4467 4.94887 14.4467C4.83939 14.4467 4.75698 14.471 4.69265 14.5099C4.62874 14.5485 4.57846 14.6034 4.54041 14.6826C4.52332 14.7182 4.50915 14.7586 4.49869 14.805C4.45157 15.0138 4.27988 15.2769 3.98045 15.2769H3.48546C3.20294 15.2769 2.96836 15.0204 3.0035 14.7087C3.03463 14.4324 3.11135 14.1784 3.23694 13.9509C3.40513 13.6462 3.6422 13.4096 3.94287 13.2442C4.24364 13.0787 4.58369 13 4.95595 13C5.33445 13 5.67743 13.0748 5.97694 13.2342C6.27445 13.39 6.51212 13.6105 6.68218 13.8957C6.85354 14.183 6.93632 14.509 6.93632 14.865C6.93632 15.1025 6.89395 15.3357 6.81097 15.563Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

NumberedList01.type = 'aghanim-icon';
