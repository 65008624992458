import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Input, Select, cn, formatHookFormErrors, useIsMobile } from '@dashboard/ui'
import { Customer, DashboardLocale } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { AuthContext, IAuthContext } from '@/Context'
import { AghanimLogotype, AghanimWord, AvatarUploader, FieldGroup, ModalProps, ModalRush } from '@/ui'
import UserAvatar from '@/components/UserAvatar'
import { S3Bucket } from '@/types'

interface AccountProfileModalProps extends ModalProps {}

export const AccountProfileModal = (props: AccountProfileModalProps) => {
  const { t, i18n } = useTranslation()
  const context = useContext(AuthContext) as IAuthContext
  const { user } = useAuth0()
  const {
    formState: { errors, isSubmitting },
    control,
    setValue,
    register,
    handleSubmit,
  } = useForm<Customer>({
    values: {
      ...context.customer,
      name: context?.customer?.name || user?.name,
      avatar_url: context.customer.avatar_url || user?.picture,
    },
  })
  const isMobile = useIsMobile()

  const renderLeftText = (text: string, desc: string) => {
    return (
      <div className="max-w-[300px]">
        <div className="text-title-t4 text-text-secondary">{text}</div>
        <div className="mt-3 text-paragraph-sm text-text-tertiary">{desc}</div>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className="flex w-full items-center">
        <div className="flex items-center gap-3">
          <AghanimLogotype />
          <AghanimWord />
        </div>
        <div className="ml-auto flex items-center gap-3">
          <Button type="button" onClick={props.onClose} color="secondary" variant="outline">
            {t('Discard')}
          </Button>
          <Button loading={isSubmitting} type="submit">
            {t('Save2')}
          </Button>
          <UserAvatar url={context?.customer?.avatar_url || user?.picture} />
        </div>
      </div>
    )
  }

  const onSubmit = handleSubmit(async (data: Customer) => {
    await dashboardClient.v1.updateProfile({
      locale: data.locale,
      name: data.name,
      avatar_url: data.avatar_url,
    })

    if (context.customer.locale != data.locale) {
      i18n.changeLanguage(data.locale)
      window.location.reload()
    } else {
      props.onClose?.()
      context.setCustomer({
        ...context.customer,
        locale: data.locale,
        name: data.name,
        avatar_url: data.avatar_url,
      })
    }
  })

  return (
    <ModalRush {...props}>
      <form className="flex h-full flex-col items-center justify-start" onSubmit={onSubmit}>
        {renderHeader()}
        <div className="mt-8 w-full max-w-[1164px]">
          <div className="font-nohemi text-hero-h2 text-text-primary">{t('account-profile.general')}</div>

          <div className="mt-8 flex flex-col gap-6">
            <div
              className={cn(
                'flex rounded-2xl1 border border-border-primary ',
                isMobile ? 'flex-col gap-6 p-6' : 'p-12',
              )}
            >
              {renderLeftText(t('account-profile.details'), t('account-profile.details.desc'))}
              <div className={cn('ml-auto', isMobile ? 'w-full' : 'w-[540px]')}>
                <Controller
                  name="avatar_url"
                  control={control}
                  render={({ field }) => (
                    <AvatarUploader
                      {...field}
                      text={t('account-profile.upload-photo')}
                      accept={{
                        'image/jpeg': ['.jpeg', '.jpg'],
                        'image/png': [],
                        'image/webp': [],
                        'image/gif': [],
                      }}
                      bucket={S3Bucket.dashboard}
                    />
                  )}
                />
                <div className="my-[18px] h-px w-full border border-border-secondary" />
                <FieldGroup label={t('account-profile.name')}>
                  <Input
                    {...register('name', { required: t('validation.required'), setValueAs: v => v.trim() })}
                    maxLength={256}
                    {...formatHookFormErrors(errors, 'name')}
                  />
                </FieldGroup>
              </div>
            </div>
            <div
              className={cn(
                'flex rounded-2xl1 border border-border-primary ',
                isMobile ? 'mb-6 flex-col gap-6 p-6' : 'p-12',
              )}
            >
              {renderLeftText(t('account-profile.lang'), t('account-profile.lang.desc'))}
              <div className={cn('ml-auto', isMobile ? 'w-full' : 'w-[540px]')}>
                <Controller
                  name="locale"
                  control={control}
                  render={({ field }) => (
                    <FieldGroup label={t('account-profile.language')}>
                      <Select
                        value={field.value || 'en'}
                        items={[
                          {
                            children: 'English',
                            value: DashboardLocale.En,
                            extra: () => <span>EN</span>,
                            icon: () => (
                              <img
                                className="w-6"
                                src={`${import.meta.env.VITE_STATIC_IMAGES_URL}/country-flags/US.svg`}
                                loading="lazy"
                                alt=""
                              />
                            ),
                          },
                          {
                            children: '简体中文',
                            value: DashboardLocale.Zh,
                            extra: () => <span>ZH</span>,
                            icon: () => (
                              <img
                                className="w-6"
                                src={`${import.meta.env.VITE_STATIC_IMAGES_URL}/country-flags/CN.svg`}
                                loading="lazy"
                                alt=""
                              />
                            ),
                          },
                          {
                            children: '한국인',
                            value: DashboardLocale.Ko,
                            extra: () => <span>KO</span>,
                            icon: () => (
                              <img
                                className="w-6"
                                src={`${import.meta.env.VITE_STATIC_IMAGES_URL}/country-flags/KO.svg`}
                                loading="lazy"
                                alt=""
                              />
                            ),
                          },
                        ]}
                        onChange={v => setValue('locale', v as DashboardLocale)}
                      />
                    </FieldGroup>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ModalRush>
  )
}
