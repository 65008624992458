import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 * @param id
 */
export const skuItemQuery = (companyId: string, gameId: string, id: string) =>
  queryOptions({
    enabled: !!id,
    queryKey: [gameId, 'sku', 'items', { id }],
    queryFn: () => dashboardClient.v1.getItem(companyId, gameId, id).then(r => r.data),
  })
