import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { CheckboxLabel, Input, Toggle, formatHookFormErrors, useModal } from '@dashboard/ui'
import {
  PriceTemplateFormData,
  PriceTemplateModalConfirmAdjust,
  PriceTemplateModalConfirmPlatform,
  PriceTemplateModalConfirmUnlink,
} from '@/layouts/sku-price-templates'
import { PriceCategory } from '@/api/dashboard'
import { dashboardClientV1 } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { useDebounce } from '@/hooks'

interface OnChangePriceParams {
  adjusts: boolean
  category: PriceCategory
  type: 'init' | 'trigger' | 'reset' | 'change'
}

export const PriceTemplateTableForm = () => {
  const { t: tBase } = useTranslation()
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'form-edit' })
  const { companyId, gameId } = useGenerateCompanyPath()

  const openChangeUnlinkConfirm = useModal<{ onConfirm: () => Promise<void> }>(props => (
    <PriceTemplateModalConfirmUnlink {...props} />
  ))

  const openChangeAdjustConfirm = useModal<{ onConfirm: () => Promise<void> }>(props => (
    <PriceTemplateModalConfirmAdjust {...props} />
  ))

  const openChangePlatformConfirm = useModal<{ action: 'enable' | 'disable'; onConfirm: () => Promise<void> }>(
    props => <PriceTemplateModalConfirmPlatform {...props} />,
  )

  const {
    formState: { errors },
    setValue,
    register,
    control,
    watch,
  } = useFormContext<PriceTemplateFormData>()

  const prices = useWatch({ control, name: 'prices' })
  const [_state, price, category] = watch(['_state', 'price', 'category'])

  const debouncePrice = useDebounce(price, 500)

  const hasCustomPrices = useMemo(() => prices.some(p => p.is_custom), [prices])

  /**
   * Change the price value.
   * @param value
   * @param params
   */
  const onChangeBasePrice = useCallback(
    async (value: number, { adjusts, category, type }: OnChangePriceParams) => {
      setValue('_state.loading', true)

      const { data } = await dashboardClientV1.v1.getDefaultTemplatePrices(companyId, gameId, {
        price: Math.trunc(value * 100),
        category,
      })

      setValue('_state.initialPrices', data)

      if (type === 'init') {
        setValue('_state.loading', false)
        return
      }

      const resetTrigger = type === 'reset'
      const enableTrigger = adjusts && type === 'trigger'
      const customPrices = prices.filter(price => price.is_custom)

      if (resetTrigger) {
        setValue('prices', [], { shouldDirty: true })
        setTimeout(() => {
          setValue('prices', data, { shouldDirty: true })
          setValue('_state.loading', false)
        })

        return
      } else {
        const newPrices = enableTrigger
          ? data
          : data
              .filter(price => {
                return !customPrices.some(
                  p => p.country === price.country && p.currency === price.currency && p.platform === price.platform,
                )
              })
              .map(price => ({ ...price, is_custom: adjusts ? price.is_custom : true }))
              .concat(customPrices)

        setValue('prices', [], { shouldDirty: true })
        setTimeout(() => {
          setValue('prices', newPrices, { shouldDirty: true })
          setValue('_state.loading', false)
        })
      }
    },
    [prices, setValue],
  )

  const isFirstRender = useRef(true)

  useEffect(() => {
    onChangeBasePrice(debouncePrice, {
      adjusts: _state.adjusts,
      category,
      type: isFirstRender.current ? 'init' : 'change',
    })
    isFirstRender.current = false
  }, [debouncePrice])

  return (
    <div className="sticky left-0 top-0 flex flex-col gap-5 rounded-xl border border-border-primary bg-fg-secondary p-6 shadow-sm">
      {_state.mode === 'manage' && (
        <>
          <div className="text-h5">{t('title')}</div>
          <div className="flex flex-col gap-1">
            <label className="text-caption-sm text-text-tertiary">{t('name.label')}</label>
            <div>
              <Input
                {...register('name', { required: tBase('validation.required') })}
                {...formatHookFormErrors(errors, 'name')}
                placeholder={t('name.placeholder')}
                autoFocus={true}
              />
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col gap-1">
        <label className="text-caption-sm text-text-secondary">{t('price.label')}</label>
        <span className="text-caption-sm text-text-tertiary">{t('price.caption')}</span>
        <div>
          <Controller
            control={control}
            name="price"
            rules={{
              required: tBase('validation.required'),
              min: { value: 0.01, message: tBase('validation.min-no-field', { value: 0.01 }) },
              max: { value: 10000, message: tBase('validation.max-no-field', { value: 10000 }) },
            }}
            render={({ field, fieldState: { isDirty } }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'price')}
                value={field.value >= 0 ? field.value : ''}
                type="text"
                onChange={e => {
                  const value = e.target.value
                  if (value === '' || /^(\d+)?(\.\d{0,2})?$/.test(value)) {
                    field.onChange(value)
                  }
                }}
                placeholder={t('price.placeholder')}
                autoFocus={_state.mode === 'apply'}
                extraRight={{ icon: () => <div className="text-text-quarterary-hover">USD</div>, width: 30 }}
                onBlur={() =>
                  isDirty &&
                  category === PriceCategory.None &&
                  !_state.adjusts &&
                  openChangeAdjustConfirm({
                    onConfirm: async () => {
                      await onChangeBasePrice(price, { adjusts: true, category, type: 'trigger' })
                      setValue('_state.adjusts', true)
                    },
                  })
                }
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="_state.adjusts"
        render={({ field }) => (
          <CheckboxLabel
            checkbox={
              <Toggle
                {...field}
                id="adjusts"
                size="sm"
                checked={field.value}
                value="yes"
                indeterminate={hasCustomPrices}
                loading={_state.loading}
                disabled={category === PriceCategory.Platform}
                onChange={async e => {
                  const adjusts = e.target.checked

                  adjusts
                    ? openChangeAdjustConfirm({
                        onConfirm: async () => {
                          await onChangeBasePrice(price, { adjusts: true, category, type: 'trigger' })
                          field.onChange(true)
                        },
                      })
                    : openChangeUnlinkConfirm({
                        onConfirm: async () => {
                          await onChangeBasePrice(price, { adjusts: false, category, type: 'trigger' })
                          field.onChange(false)
                        },
                      })
                }}
              />
            }
            htmlFor="adjusts"
            direction="rtl"
            size="sm"
            caption={t('adjusts.caption')}
          >
            {t('adjusts.label')}
          </CheckboxLabel>
        )}
      />

      <Controller
        control={control}
        name="category"
        render={({ field }) => (
          <CheckboxLabel
            checkbox={
              <Toggle
                {...field}
                id="category"
                checked={field.value === PriceCategory.Platform}
                size="sm"
                loading={_state.loading}
                disabled={_state.adjusts}
                onChange={async e => {
                  const category = e.target.checked ? PriceCategory.Platform : PriceCategory.None
                  openChangePlatformConfirm({
                    action: e.target.checked ? 'enable' : 'disable',
                    onConfirm: async () => {
                      await onChangeBasePrice(price, { adjusts: false, category, type: 'reset' })
                      field.onChange(category)
                    },
                  })
                }}
              />
            }
            htmlFor="category"
            direction="rtl"
            size="sm"
          >
            {t('segregation.label')}
          </CheckboxLabel>
        )}
      />
    </div>
  )
}
