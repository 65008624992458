import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ShoppingBag03: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37735 2H15.6227C16.3682 1.99999 16.9857 1.99998 17.4914 2.03982C18.0183 2.08133 18.5034 2.17037 18.9661 2.3918C19.6872 2.7368 20.2883 3.28992 20.692 3.97981C20.9511 4.42258 21.0801 4.89856 21.1652 5.42018C21.2469 5.92086 21.2982 6.53618 21.3601 7.27916L22.0669 15.7614C22.1391 16.6271 22.1984 17.339 22.1978 17.9195C22.1972 18.5203 22.1349 19.0771 21.9032 19.6056C21.5459 20.4209 20.9268 21.0938 20.1439 21.5176C19.6364 21.7923 19.0868 21.9007 18.4881 21.9512C17.9096 22 17.1953 22 16.3266 22H7.6734C6.80477 22 6.09041 22 5.51196 21.9512C4.91321 21.9007 4.36358 21.7923 3.85613 21.5176C3.07325 21.0938 2.45413 20.4209 2.0968 19.6056C1.86517 19.0771 1.80279 18.5203 1.80219 17.9195C1.80161 17.3389 1.86095 16.6271 1.9331 15.7614L2.63995 7.27916C2.70186 6.53615 2.75312 5.92088 2.83483 5.42017C2.91994 4.89856 3.04896 4.42258 3.30805 3.97981C3.71175 3.28992 4.31287 2.7368 5.0339 2.39179C5.49666 2.17037 5.98171 2.08133 6.50859 2.03982C7.01435 1.99998 7.63177 1.99999 8.37735 2ZM9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 9.32608 7.52678 10.5979 8.46447 11.5355C9.40215 12.4732 10.6739 13 12 13C13.3261 13 14.5979 12.4732 15.5355 11.5355C16.4732 10.5979 17 9.32608 17 8C17 7.44772 16.5523 7 16 7C15.4477 7 15 7.44772 15 8C15 8.79565 14.6839 9.55871 14.1213 10.1213C13.5587 10.6839 12.7956 11 12 11C11.2044 11 10.4413 10.6839 9.87868 10.1213C9.31607 9.55871 9 8.79565 9 8Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

ShoppingBag03.type = 'aghanim-icon';
