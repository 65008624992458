import { Currency, PaymentRead } from '@/api/dashboard'
import { getTransactionTotal } from '@/layouts/transaction/util'
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Textarea,
  formatHookFormErrors,
} from '@dashboard/ui'
import { dashboardClient, getErrorText } from '@/api'
import { ToastSeverity, useToast } from '@/ui'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useGenerateCompanyPath } from '@/libs'

interface RefundModalProps extends ModalProps {
  payment: PaymentRead
  onRefunded: () => void
}

interface RefundModalForm {
  reason: string
}

export const RefundModal = ({ payment, onRefunded, ...rest }: RefundModalProps) => {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { t } = useTranslation()
  const showToast = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RefundModalForm>({
    values: {
      reason: '',
    },
  })

  const onRefundConfirm = async (data: RefundModalForm) => {
    try {
      await dashboardClient.v1.refundOrder(payment.payment_id!, companyId!, gameId!, {
        reason: data.reason,
        order_id: payment.order_id!,
        payment_number: payment.payment_number!,
      })
      onRefunded()
      rest.onClose()
    } catch (e) {
      console.log(e)
      showToast({ severity: ToastSeverity.error, message: getErrorText(e) })
    }
  }

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(onRefundConfirm)}>
        <ModalContent>
          <ModalTitle subtitle={t('transactions-table.refund.confirm')}>
            {t('transactions-table.refund-title', {
              value: payment.currency == Currency.RP ? payment.total + ' RP' : getTransactionTotal(payment),
            })}
          </ModalTitle>
          <Textarea
            autoFocus
            placeholder={t('transaction-details.write-refund-reason')}
            {...register('reason', { required: t('validation.required') })}
            {...formatHookFormErrors(errors, 'reason')}
          />
        </ModalContent>
        <ModalFooter>
          <Button variant="outline" color={'secondary'} size="sm" onClick={rest.onClose} type="button">
            {t('Cancel')}
          </Button>
          <Button color={'danger'} size="sm" type="submit" loading={isSubmitting}>
            {t('transactions-table.refund')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
