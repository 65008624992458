import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, cn, useIsMobile } from '@dashboard/ui'

type ErrorBlockProps = {
  status?: number | string
  title: string
  text?: string
  extraText?: string
}

export default function ErrorBlock(props: ErrorBlockProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  return (
    <div
      style={{
        boxShadow: '0px 24px 48px -12px #1018282E',
        background: 'linear-gradient(273.02deg, rgba(255, 255, 255, 0) 5.02%, #FFFFFF 94.98%)',
        zIndex: 1,
      }}
      className={cn(
        'flex flex-col items-center justify-center gap-10 rounded-[36px] bg-fg-primary',
        isMobile ? 'mx-4 w-full px-4 py-9' : 'h-[360px] w-[690px] p-12',
      )}
    >
      {props.status && (
        <span className="inline-block min-w-[56px] rounded-md border border-border-primary bg-fg-primary px-3 py-2 text-[16px] font-semibold">
          {props.status}
        </span>
      )}

      <span className={cn(' font-semibold', isMobile ? 'text-[22px]' : 'text-[36px]')}>{props.title}</span>

      {props.text && <p>{props.text}</p>}

      <Button onClick={() => navigate('/')} size="md" className="py-5 text-caption-lg">
        {t('go-home')}
      </Button>

      {props.extraText && (
        <span
          className={cn('text-text-secondary', isMobile ? 'text-[14px]' : 'text-[16px]')}
          dangerouslySetInnerHTML={{ __html: props.extraText }}
        />
      )}
    </div>
  )
}
