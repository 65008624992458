import { useState } from 'react'
import { Skeleton, useIsMobile } from '@dashboard/ui'
import { ThemeProvider } from '@mui/material'
import DrawerSkeleton from './DrawerSkeleton'
import { lightTheme } from '../App'
import { TextSkeleton } from './TextSkeleton'
import DashboardSkeleton from './dashboard/DashboardSkeleton'

export default function AppSkeleton() {
  const [theme] = useState(lightTheme)
  const isMobile = useIsMobile()

  const renderHeader = () => {
    return isMobile ? (
      <div className="mb-4 flex w-full items-center justify-between gap-3">
        <div className="flex flex-col gap-1">
          <Skeleton className="h-[5px] w-[30px]" />
          <Skeleton className="h-[5px] w-[30px]" />
          <Skeleton className="h-[5px] w-[30px]" />
        </div>
        <Skeleton className="size-[40px] rounded-full" />
      </div>
    ) : (
      <>
        <div className="mb-4 flex w-full items-center justify-end gap-3">
          <TextSkeleton className="h-32 w-[104px]" variant="text" />
          <TextSkeleton className="h-32 w-[104px]" variant="text" />
          <TextSkeleton className="h-32 w-[104px]" variant="text" />
          <TextSkeleton className="h-32 w-[104px]" variant="text" />
          <TextSkeleton className="size-[20px]" variant="text" />
          <Skeleton className="size-[40px] rounded-full" />
        </div>
      </>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex size-full flex-row">
        {isMobile ? (
          <div className="flex size-full flex-col p-4">
            {renderHeader()}
            {new Array(10).fill('').map((_, idx) => (
              <Skeleton className="mb-4 h-[80px] w-full" key={idx} />
            ))}
          </div>
        ) : (
          <>
            <DrawerSkeleton />
            <div className="flex size-full flex-col items-center overflow-y-auto p-1">
              {renderHeader()}
              <div className="w-full">
                <DashboardSkeleton />
              </div>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  )
}
