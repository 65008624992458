import { Button, DotsHorizontal, Popper, Skeleton } from '@dashboard/ui'
import { PriceTemplateShort } from '@/api/dashboard'
import { TableCell, TableCellDate, TableRow } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'
import { PriceTemplateMenu } from './PriceTemplateMenu'
import { SKU_PRICE_TEMPLATES_EDIT_PATH } from '../paths'
import { useTranslation } from 'react-i18next'

interface PriceTemplateRowProps {
  template: PriceTemplateShort
}

export const PriceTemplateRow = ({ template }: PriceTemplateRowProps) => {
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'table-list.row' })
  const { generatePath } = useGenerateCompanyPath()

  return (
    <TableRow to={generatePath(SKU_PRICE_TEMPLATES_EDIT_PATH, { priceTemplateId: template.id })}>
      <TableCell width="100%">
        <div className="flex flex-col gap-0.5">
          <div className="text-caption-md leading-[18px]">{template.name}</div>
          <div className="text-caption-sm font-normal text-text-tertiary">
            USD ·{' '}
            {(template.price / 100).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        </div>
      </TableCell>
      <TableCell width="30%">{template.items_count || t('not-linked')}</TableCell>
      <TableCell width="40%">
        <TableCellDate timestamp={template.modified_at || template.created_at} options={{ time: true }} />
      </TableCell>
      <TableCell width={52} onClick={e => e.preventDefault()}>
        <Popper
          popper={props => <PriceTemplateMenu {...props} priceTemplate={template} />}
          params={{ placement: 'bottom-end' }}
        >
          <Button className="w-7 px-0" size="sm" variant="tertiary" color="secondary">
            <DotsHorizontal size={14} />
          </Button>
        </Popper>
      </TableCell>
    </TableRow>
  )
}

export const PriceTemplateRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell width="100%">
        <div className="flex flex-col gap-0.5">
          <div className="text-caption-md leading-[18px]">
            <Skeleton className="h-[18px] w-1/2" />
          </div>
          <div className="text-caption-sm font-normal text-text-tertiary">
            <Skeleton className="h-[16px] w-1/12" />
          </div>
        </div>
      </TableCell>
      <TableCell width="30%">
        <Skeleton className="h-[18px] w-1/4" />
      </TableCell>
      <TableCell width="30%">
        <Skeleton className="h-[18px] w-1/4" />
      </TableCell>
      <TableCell width="40%">
        <Skeleton className="h-[18px] w-1/2" />
      </TableCell>
      <TableCell width={52} />
    </TableRow>
  )
}
