import {
  InputHTMLAttributes,
  MutableRefObject,
  ReactElement,
  createElement,
  forwardRef,
  isValidElement,
  useMemo,
} from 'react';
import { AnimatePresence } from 'framer-motion';
import { cva } from 'class-variance-authority';
import { useMergeRefs } from '@floating-ui/react';
import { AghanimIconOrCustom } from '../../icons';
import { cn } from '../../libs';
import { ControlHookFormError } from '../../types';
import { FormErrorMessage } from '../FormErrorMessage';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>, ControlHookFormError {
  refDirect?: MutableRefObject<HTMLInputElement | null>;
  /**
   * Placeholder of the input.
   */
  placeholder?: string;
  /**
   * Color of the input.
   */
  color?: 'primary' | 'danger';
  /**
   * Size of the input.
   */
  size?: 'md';
  /**
   * Extra element to be placed on the left side of the input.
   */
  extraLeft?: { icon: AghanimIconOrCustom | ReactElement; width?: number; pointer?: boolean };
  /**
   * Extra element to be placed on the right side of the input.
   */
  extraRight?: { icon: AghanimIconOrCustom | ReactElement; width?: number; pointer?: boolean };
}

const variants = cva(
  [
    'peer/input',
    'flex h-9 w-full items-center rounded-md border shadow-xs outline-none transition-colors',

    'text-text-secondary',
    'text-paragraph-sm',
    'leading-4',

    'placeholder:text-text-quarterary',
    'placeholder:transition-colors',

    'hover:placeholder:text-text-quarterary-hover',

    // TODO: remove this
    'group-[&[data-open="true"]]/input-popper:border-border-brand-filled',
    'group-[&[data-open="true"]]/input-popper:placeholder:text-text-quarterary-hover',
    'group-[&[data-open="true"]]/input-popper:text-text-primary',
    'group-[&[data-open="true"]]/input-popper:ring-2',
    'group-[&[data-open="true"]]/input-popper:ring-brand',

    '[&[data-open="true"]]:border-border-brand-filled',
    '[&[data-open="true"]]:placeholder:text-text-quarterary-hover',
    '[&[data-open="true"]]:text-text-primary',
    '[&[data-open="true"]]:ring-2',
    '[&[data-open="true"]]:ring-brand',

    'focus:border-border-brand-filled',
    'focus:placeholder:text-text-quarterary-hover',
    'focus:text-text-primary',
    'focus:ring-2',
    'focus:ring-brand',

    'disabled:bg-fg-disabled',
    'disabled:border-border-disabled',
    'disabled:text-text-disabled',
    'disabled:placeholder:text-text-disabled',
    'disabled:pointer-events-none',
    'disabled:ring-0',
    'disabled:shadow-none',
  ],
  {
    variants: {
      color: {
        primary: ['bg-fg-primary', 'border-border-primary', 'hover:border-border-primary-hover'],
        danger: ['bg-fg-primary', 'border-border-error', 'ring-2 ring-error', 'hover:border-border-error-solid'],
      },
      size: {
        md: 'px-3',
      },
      defaultVariants: {
        color: 'primary',
        size: 'md',
      },
    },
  },
);

const extraClasses = cn(
  'absolute top-1/2 h-5 min-w-5 -translate-y-1/2',
  'flex items-center justify-center',
  'shrink-0 text-text-quarterary transition-colors',
  'group-[&[data-open="true"]]/input-popper:text-text-secondary',
  'peer-[:not(:placeholder-shown)]/input:text-text-secondary',
  'peer-hover/input:text-text-quarterary-hover',
  'peer-focus/input:text-text-secondary',
  'peer-disabled/input:!text-text-disabled',
  'peer-disabled/input:pointer-events-none',
);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ refDirect, color = 'primary', size = 'md', extraLeft, extraRight, error, ...rest }, ref) => {
    const mergedRef = useMergeRefs([ref, refDirect]);

    const inputExtraPaddings = useMemo(
      () => ({
        paddingLeft: extraLeft?.width ? `${8 + 6 + extraLeft.width}px` : extraLeft ? 8 + 6 + 20 : 12,
        paddingRight: extraRight?.width ? `${8 + 6 + extraRight.width}px` : extraRight ? 8 + 6 + 20 : 12,
      }),
      [extraLeft, extraRight],
    );

    return (
      <>
        <div className={cn('group/input relative')}>
          <input
            {...rest}
            ref={mergedRef}
            className={cn(variants({ color, size }), rest.className)}
            style={{ ...inputExtraPaddings }}
            onWheel={event => event.currentTarget.blur()}
          />
          {extraLeft && (
            <div
              className={cn('left-2', !extraLeft.pointer && 'pointer-events-none', extraClasses)}
              style={{ width: extraLeft.width }}
            >
              {isValidElement(extraLeft.icon)
                ? extraLeft.icon
                : createElement(extraLeft.icon as AghanimIconOrCustom, { size: 18 })}
            </div>
          )}
          {extraRight && (
            <div className={cn('right-2', !extraRight.pointer && 'pointer-events-none', extraClasses)}>
              {isValidElement(extraRight.icon)
                ? extraRight.icon
                : createElement(extraRight.icon as AghanimIconOrCustom, { size: 18 })}
            </div>
          )}
        </div>
        <AnimatePresence>{error && <FormErrorMessage>{error}</FormErrorMessage>}</AnimatePresence>
      </>
    );
  },
);
