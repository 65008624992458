import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { RangeOfferCreate } from '@/api/dashboard'

interface RangeOfferCreateMutate {
  create: RangeOfferCreate
}

export const useRangeOfferCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: RangeOfferCreateMutate) => dashboardClient.v1.createRangeOffer(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['range_offer', gameId, data.data.id], data.data)
      queryClient.invalidateQueries({ queryKey: ['range_offers', gameId], exact: false })
    },
  })
}
