import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import {
  Button,
  FormErrorMessage,
  Input,
  InputColor,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  formatHookFormErrors,
  isHexColor,
} from '@dashboard/ui'
import { FieldGroup } from '@/ui'
import { ItemRarityRead } from '@/api/dashboard'
import { getErrorText } from '@/api'
import { MAX_NAME_LENGTH } from '@/Settings'
import { useItemRarityCreateMutate } from '@/layouts/sku-settings/api/useItemRarityCreateMutate'
import { useItemRarityUpdateMutate } from '@/layouts/sku-settings/api/useItemRarityUpdateMutate'
import { useGenerateCompanyPath, usePydenticForm } from '@/libs'

interface EditItemCategoryDialogProps extends ModalProps {
  item: ItemRarityRead
  created?: (item: ItemRarityRead) => void
}

export const EditItemRarityModal = ({ item, created, ...rest }: EditItemCategoryDialogProps) => {
  const { t: tMain } = useTranslation()
  const { t } = useTranslation('lootboxes')
  const { companyId, gameId } = useGenerateCompanyPath()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { mutateAsync: createMutateAsync } = useItemRarityCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useItemRarityUpdateMutate(companyId, gameId)

  const {
    formState: { isSubmitting, errors },
    register,
    control,
    handleSubmit,
  } = usePydenticForm<ItemRarityRead>({
    values: { ...item },
  })

  const onSubmit = handleSubmit(async (data: ItemRarityRead) => {
    try {
      if (item.id) {
        await updateMutateAsync({
          id: item.id,
          update: data,
        })
      } else {
        let { data: newCat } = await createMutateAsync({
          create: data,
        })

        created?.(newCat)
      }
    } catch (e) {
      setErrorMessage(getErrorText(e))
      return
    }

    rest.onClose?.()
  })

  return (
    <Modal {...rest}>
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalTitle subtitle={!item.id && t('rarity.modal.desc')}>
            {t(item.id ? 'item.rarity.edit' : 'item.rarity.create')}
          </ModalTitle>

          <FieldGroup label={t('item.rarity.name')}>
            <Input
              {...register('name', {
                required: tMain('validation.required'),
                setValueAs: value => value?.trim(),
              })}
              {...formatHookFormErrors(errors, 'name')}
              autoFocus={true}
              maxLength={MAX_NAME_LENGTH}
            />
          </FieldGroup>

          <FieldGroup label={t('item.rarity.color_background_badge')}>
            <Controller
              name="color_background_badge"
              control={control}
              rules={{
                required: tMain('validation.required'),
                validate: v => {
                  if (!v) {
                    return true
                  }

                  if (!isHexColor(v)) {
                    return t('validation.invalid_hex_color')
                  }

                  return true
                },
              }}
              render={({ field }) => (
                <InputColor {...field} {...formatHookFormErrors(errors, 'color_background_badge')} />
              )}
            />
          </FieldGroup>

          {errorMessage && <FormErrorMessage> {errorMessage} </FormErrorMessage>}
        </ModalContent>
        <ModalFooter>
          <Button variant="outline" color="secondary" size="sm" onClick={rest.onClose}>
            {tMain('Cancel')}
          </Button>
          <Button type="submit" loading={isSubmitting} size="sm">
            {tMain(item.id ? 'Save2' : 'Create')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
