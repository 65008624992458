import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { FormErrorMessage, InputColor, formatHookFormErrors, isHexColor } from '@dashboard/ui'
import { ItemType } from '@/api/dashboard'
import { hubWebsitesQuery } from '@/layouts/hub'
import { FieldGroup, ImageUploader } from '@/ui'
import { aspectRatiosRecommendationMap, getRecommendedSizeByRatio, useGenerateCompanyPath } from '@/libs'
import { S3Bucket } from '@/types'
import { FormSection } from '../components'
import { SkuFormData } from '../types'

export const SkuFormMediaSection = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const {
    watch,
    formState: { errors },
    control,
  } = useFormContext<SkuFormData>()

  const { data: website } = useQuery({
    ...hubWebsitesQuery(companyId, gameId),
    select: websites => websites.at(0),
  })

  const [type] = watch(['type'])

  const recommendedSizeByRatio = getRecommendedSizeByRatio(website?.config?.store_settings?.image_ratio || 0)

  return (
    <FormSection>
      <div className="-m-6 mb-6 rounded-t-2xl1 bg-fg-primary-hover p-6">
        <div className="mb-3 text-t4">{t('sku.item.media-content')}</div>
        <div className="text-text-secondary">{t('sku.item.media-content.desc')}</div>
      </div>

      <FieldGroup label={t('sku.item.media-content.upload')}>
        <Controller
          name="image_url"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              recommendedSize={recommendedSizeByRatio}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="image_url"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>

      {type === ItemType.Currency && (
        <FieldGroup label={t('sku.item.media-content.upload-icon')}>
          <Controller
            name="icon_url"
            control={control}
            render={({ field }) => (
              <ImageUploader
                {...field}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                recommendedSize={aspectRatiosRecommendationMap['icon']}
                bucket={S3Bucket.sku}
              />
            )}
          />
          <ErrorMessage
            name="icon_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      )}

      <FieldGroup label={t('sku.item.media-content.upload-featured')}>
        <Controller
          name="image_url_featured"
          control={control}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              recommendedSize={aspectRatiosRecommendationMap['16:9']}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="image_url_featured"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>

      <FieldGroup label={t('sku.item.media-content.card-background-image-featured')}>
        <Controller
          name="featured_card_background_image_url"
          control={control}
          render={({ field }) => (
            <ImageUploader
              {...field}
              accept={{
                'image/jpeg': ['.jpeg', '.jpg'],
                'image/png': [],
                'image/webp': [],
              }}
              recommendedSize={aspectRatiosRecommendationMap['16:9']}
              bucket={S3Bucket.sku}
            />
          )}
        />
        <ErrorMessage
          name="featured_card_background_image_url"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
      <FieldGroup label={t('sku.item.media-content.upload-background')}>
        <FieldGroup size="sm">
          <Controller
            name="background_image_url"
            control={control}
            render={({ field }) => (
              <ImageUploader
                {...field}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                recommendedSize={aspectRatiosRecommendationMap['16:9']}
                bucket={S3Bucket.sku}
              />
            )}
          />
          <ErrorMessage
            name="background_image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <FieldGroup label={t('sku.item.media-content.upload-background.color.label')}>
          <Controller
            name="background_image_color"
            control={control}
            rules={{
              validate: v => {
                if (!v) {
                  return true
                }

                if (!isHexColor(v)) {
                  return t('validation.invalid_hex_color')
                }

                return true
              },
            }}
            render={({ field }) => (
              <InputColor
                {...field}
                {...formatHookFormErrors(errors, 'background_image_color')}
                className="max-w-72"
                placeholder={t('sku.item.media-content.upload-background.color.placeholder')}
              />
            )}
          />
        </FieldGroup>
      </FieldGroup>
    </FormSection>
  )
}
