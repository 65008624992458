import { PriceTemplateRead } from '@/api/dashboard'
import { ModalRush, ModalRushProps } from '@/ui'
import { UseNavigateBackValue } from '@/libs'
import { PriceTemplateEditSkeleton } from './PriceTemplateEditSkeleton'
import { PriceTemplateForm } from './PriceTemplateForm'

export interface PriceTemplateModalProps extends ModalRushProps {
  back: UseNavigateBackValue
  template: PriceTemplateRead | undefined | null
  mode: 'manage' | 'apply'
  onSubmit: (template: PriceTemplateRead) => Promise<void>
}

export const PriceTemplateModal = ({ back, template, mode, onSubmit, ...rest }: PriceTemplateModalProps) => (
  <ModalRush {...rest} fallback={<PriceTemplateEditSkeleton back={back} />}>
    {template && <PriceTemplateForm back={back} template={template} mode={mode} onSubmit={onSubmit} />}
  </ModalRush>
)
