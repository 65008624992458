import { AttrType, Node, SetAttributeValuesNode } from '@/api/dashboard'
import { BlockError } from '../types'
import SettingsHeader from '@/layouts/campaigns/SettingsHeader'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { Select, Trash } from '@dashboard/ui'
import { useGenerateCompanyPath } from '@/libs'
import { PlayerAttributeValueEditor } from '@/layouts/player/components/PlayerAttributeValueEditor'
import { TokenValue } from '@/layouts/campaigns/expr/types'
import { AddButton } from '@/layouts/components/AddButton'
import { ButtonIcon, FieldSection } from '@/ui'

type Value = string | number | boolean | null

interface FormData {
  values: {
    name: string
    value: Value
  }[]
}

export const SetUserAttributeEditor = (props: {
  node: SetAttributeValuesNode
  error?: BlockError | null
  setNode?: (node: Node) => void
  onClose: () => void
}) => {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { t } = useTranslation()
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const nodeValues = props.node?.values as Record<string, Value>
  const arr = []
  for (const key in nodeValues) {
    arr.push({
      name: key,
      value: nodeValues[key],
    })
  }

  const { handleSubmit, reset, control, watch } = useForm<FormData>({
    values: {
      values: arr,
    },
  })

  const values = useFieldArray({
    control,
    name: 'values',
  })

  const onSubmit = (data: FormData) => {
    const objValues: Record<string, Value> = {}
    for (const item of data.values) {
      if (item.name) {
        objValues[item.name] = item.value
      }
    }

    if (props.setNode) {
      props.setNode({
        ...props.node,
        values: objValues,
      } as unknown as Node)
    }
    reset({ values: [] })
    props.onClose()
  }

  const noAddedAttributes =
    gameSettings?.player_attributes?.filter(attr => values.fields.find(item => item.name === attr.name) == null) || []

  return (
    <>
      <SettingsHeader
        onSave={handleSubmit(onSubmit)}
        onClose={() => {
          reset({ values: [] })
          props.onClose()
        }}
        text={t('campaign.block.SetAttributeValuesNode.name')}
      />
      <div className="p-6">
        <FieldSection
          label={t('campaign.node-type.SetAttributeValuesNode')}
          caption={t('campaign.set-attr-editor.desc')}
        >
          <div>
            <div className="flex flex-col gap-4">
              {values.fields.map((item, index) => (
                <div key={item.name} className="flex gap-2">
                  <div className="w-1/2">
                    <Controller
                      name={`values.${index}.name`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          value={field.value}
                          onChange={v => field.onChange(v)}
                          items={
                            gameSettings?.player_attributes?.map(attribute => ({
                              children: attribute.name,
                              value: attribute.name,
                            })) || []
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="w-1/2">
                    <Controller
                      name={`values.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <PlayerAttributeValueEditor
                          type={
                            gameSettings?.player_attributes?.find(attr => attr.name === watch(`values.${index}.name`))
                              ?.type || AttrType.String
                          }
                          value={field.value as TokenValue}
                          onChange={value => {
                            field.onChange(value)
                          }}
                        />
                      )}
                    />
                  </div>
                  <ButtonIcon variant="tertiary-destructive" onClick={() => values.remove(index)}>
                    <Trash />
                  </ButtonIcon>
                </div>
              ))}
              {noAddedAttributes.length > 0 && (
                <AddButton
                  size="xs"
                  onClick={() => {
                    values.append({
                      name: '',
                      value: null,
                    })
                  }}
                >
                  {t('Add')}
                </AddButton>
              )}
            </div>
          </div>
        </FieldSection>
      </div>
    </>
  )
}
