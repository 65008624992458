import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Unlink: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34314 10.5858C6.73367 10.9763 6.73367 11.6094 6.34314 12L4.92893 13.4142C3.36683 14.9763 3.36683 17.5089 4.92893 19.071C6.49102 20.6331 9.02368 20.6331 10.5858 19.071L12 17.6568C12.3905 17.2663 13.0237 17.2663 13.4142 17.6568C13.8047 18.0473 13.8047 18.6805 13.4142 19.071L12 20.4852C9.65685 22.8284 5.85786 22.8284 3.51471 20.4852C1.17157 18.1421 1.17157 14.3431 3.51471 12L4.92893 10.5858C5.31945 10.1952 5.95262 10.1952 6.34314 10.5858Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.51468C14.3431 1.17154 18.1421 1.17154 20.4853 3.51468C22.8284 5.85783 22.8284 9.65682 20.4853 12L19.0711 13.4142C18.6805 13.8047 18.0474 13.8047 17.6568 13.4142C17.2663 13.0237 17.2663 12.3905 17.6568 12L19.0711 10.5858C20.6332 9.02365 20.6332 6.49099 19.0711 4.9289C17.509 3.3668 14.9763 3.3668 13.4142 4.9289L12 6.34311C11.6095 6.73363 10.9763 6.73363 10.5858 6.34311C10.1953 5.95259 10.1953 5.31942 10.5858 4.9289L12 3.51468Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Unlink.type = 'aghanim-icon';
