import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { pickBy, throttle } from 'es-toolkit';
import { useSearchParams } from 'react-router-dom';
import { MemorySearchParams } from '../libs';

interface UseSearchFilterParams {
  /**
   * Type of search filter:
   * - `search`: Use the URL search params.
   * - `memory`: Use the memory (from state) search params.
   */
  type?: 'search' | 'memory';
  /**
   * Throttle time in milliseconds.
   */
  throttleMs?: number;
}

export type SearchFilterQueryValue = string | number | null | undefined;

type RegisterSingleSelect<T> = (field: T) => {
  multiple: false;
  value: SearchFilterQueryValue;
  onChange: (value: SearchFilterQueryValue) => void;
};

type RegisterMultipleSelect<T> = (
  field: T,
  multiple: true,
) => {
  multiple: true;
  value: SearchFilterQueryValue[];
  onChange: (value: SearchFilterQueryValue[]) => void;
};

type SelectFilterOnChange<T extends string = string> = (
  filter: SearchFilterGeneric<T>['values'],
  params?: {
    /**
     * Force update the input fields with the new values.
     */
    force?: boolean;
  },
) => void;

export interface SearchFilterGeneric<T extends string = string> {
  values: Partial<Record<T, SearchFilterQueryValue>>;
  onChange: SelectFilterOnChange<T>;
  onReset: () => void;
  registerInput: (field: T) => {
    ref: (ref: HTMLInputElement) => void;
    defaultValue: string | number | undefined;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  };
  registerSelect: RegisterSingleSelect<T> & RegisterMultipleSelect<T>;
}

const shouldPick = (value: SearchFilterQueryValue) => !!value;

type Values = Partial<Record<string, SearchFilterQueryValue>>;

const useMemorySearchParams = (): [MemorySearchParams, (params: URLSearchParams) => void] => {
  const [searchParams] = useState(new MemorySearchParams());

  const setSearchParams = (params: URLSearchParams) => {
    if (params instanceof URLSearchParams) {
      params.forEach((value, key) => {
        searchParams.append(key, value);
      });
    } else {
      Object.entries(params).forEach(([key, value]) => {
        searchParams.append(key, value as string);
      });
    }
  };

  return [searchParams, setSearchParams];
};

const useSearchState = (type: UseSearchFilterParams['type']) => {
  const memoryState = useMemorySearchParams();
  const searchState = useSearchParams();

  if (type === 'search') {
    return searchState;
  }

  return memoryState;
};

/**
 * Hook to manage search filters.
 * @param defaultValues
 * @param params
 */
export const useSearchFilter = <T extends string>(
  defaultValues?: Values,
  params?: UseSearchFilterParams,
): SearchFilterGeneric<T> => {
  const [searchParams, setSearchParams] = useSearchState(params?.type || 'search');

  const registeredRefs = useMemo(() => new Map<T, HTMLInputElement>(), []);

  const [values, setValues] = useState(() =>
    pickBy(
      {
        ...(Object.fromEntries(searchParams.entries()) as SearchFilterGeneric<T>['values']),
        ...defaultValues,
      },
      shouldPick,
    ),
  );

  const onChange: SearchFilterGeneric<T>['onChange'] = useCallback(
    (filter, params) => {
      const filteredValues = pickBy(filter, shouldPick);

      if (params?.force) {
        for (const [field, ref] of registeredRefs.entries()) {
          if (ref && filteredValues[field] !== ref.value) {
            ref.value = filteredValues[field]?.toString() || '';
          }
        }
      }

      // Always remove the page from the search params.
      // @ts-ignore
      delete filteredValues.page;

      setSearchParams(filteredValues as unknown as URLSearchParams);
      setValues(filteredValues);
    },
    [registeredRefs, setSearchParams],
  );

  const throttleMs = params?.throttleMs ?? 500;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnChange = useCallback(
    throttleMs ? throttle(onChange, throttleMs, { edges: ['trailing'] }) : onChange,
    [params?.throttleMs, onChange],
  );

  const onReset = useCallback(() => {
    onChange({}, { force: true });
  }, [onChange]);

  const registerInput: SearchFilterGeneric<T>['registerInput'] = useCallback(
    field => {
      return {
        ref: (ref: HTMLInputElement) => {
          registeredRefs.set(field, ref);
        },
        defaultValue: values[field]?.toString() || '',
        onChange: e => {
          throttledOnChange({ ...values, [field]: e.target.value });
        },
      };
    },
    [registeredRefs, values, throttledOnChange],
  );

  // @ts-ignore
  const registerSelect: SearchFilterGeneric<T>['registerSelect'] = useCallback(
    (field, multiple) => {
      return {
        multiple: multiple || false,
        value: multiple ? values[field]?.toString().split(',') || [] : values[field]?.toString() || '',
        onChange: v => {
          if (multiple) {
            onChange({ ...values, [field]: v.join(',') });
          } else {
            onChange({ ...values, [field]: v });
          }
        },
      };
    },
    [values, onChange],
  );

  return {
    values,
    onChange,
    onReset,
    registerInput,
    registerSelect,
  };
};
