export const aspectRatiosRecommendationMap: Record<string, [width: number, height: number]> = {
  '5:2': [1250, 500],
  '7:5': [700, 500],
  '8:10': [500, 620],
  '9:16': [500, 890],
  '10:8': [620, 500],
  '16:9': [890, 500],
  '2:3': [500, 750],
  '2:5': [500, 1250],
  '3:2': [750, 500],
  '5:7': [500, 700],
  '1:1': [500, 500],
  icon: [180, 180],
}

/**
 * Format a decimal aspect ratio to a string.
 * @param decimal
 * @returns
 */
export const formatDecimalAspectRatio = (decimal: number): string => {
  const tolerance = 0.01
  for (const [aspectRatio, [width, height]] of Object.entries(aspectRatiosRecommendationMap)) {
    const calculatedRatio = width / height
    if (Math.abs(calculatedRatio - decimal) < tolerance) {
      return aspectRatio
    }
  }
  return '1:1'
}

/**
 * Get the recommendation size for an aspect ratio.
 * @param aspectRatio
 * @returns
 */
export const getRecommendedSizeByRatio = (aspectRatio: number): [width: number, height: number] => {
  return aspectRatiosRecommendationMap[formatDecimalAspectRatio(aspectRatio)]
}
