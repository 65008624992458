import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { ChevronLeftOutline, XClose } from '../../icons';
import { cn } from '../../libs';
import { Button } from '../Button';

export interface ModalTitleProps {
  /**
   * Title of the modal.
   */
  children: ReactNode;
  /**
   * Back button of the modal.
   */
  back?: {
    /**
     * Label of the back button.
     */
    label: string;
    /**
     * Callback of the back button.
     */
    onClick: () => void;
  };
  /**
   * Subtitle of the modal.
   */
  subtitle?: ReactNode;
  /**
   * Sticky title of the modal.
   */
  sticky?: boolean;
  /**
   * Callback when clicking the close button.
   */
  onClose?: () => void;

  titleClassName?: string;
  className?: string;
}

export const ModalTitle = ({
  children,
  back,
  subtitle,
  sticky = true,
  onClose,
  titleClassName,
  className,
}: ModalTitleProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback((e: Event) => {
    ref.current?.setAttribute('data-sticky', (e.currentTarget as HTMLElement).scrollTop > 6 ? 'true' : 'false');
  }, []);

  useEffect(() => {
    const scrollContainer = ref.current?.parentNode?.parentNode;
    scrollContainer?.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, [ref, handleScroll]);

  return (
    <>
      <div
        ref={ref}
        className={cn(
          'group/modal-title left-0 top-0',
          'relative z-10 -mx-6 flex flex-col items-start gap-1.5 rounded-t-2xl1 px-6 pb-1.5 pt-6 backdrop-blur-xl transition-all',
          'bg-[rgba(255,255,255,0.75)]',
          'border-b border-transparent',
          sticky && [
            'sticky',
            'data-[sticky=true]:pb-3',
            'data-[sticky=true]:border-border-secondary',
            'data-[sticky=true]:gap-1',
          ],
          !subtitle && 'mb-[18px] last:mb-0',
          className,
        )}
      >
        {onClose && (
          <div className="absolute right-[18px] top-[18px] z-10">
            <Button className="px-0" variant="tertiary" color="secondary" size="sm" onClick={onClose}>
              <XClose size={16} />
            </Button>
          </div>
        )}

        {back && (
          <button
            className="inline-flex items-center gap-1 text-caption-md text-text-brand-primary hover:text-text-brand-secondary"
            onClick={back.onClick}
          >
            <ChevronLeftOutline size={14} />
            <span>{back.label}</span>
          </button>
        )}

        <div
          className={cn(
            'pointer-events-none absolute inset-x-0 bottom-[-25px] h-6',
            'bg-gradient-to-t from-transparent to-fg-primary',
            'opacity-0',
            sticky && ['group-[&[data-sticky="true"]]/modal-title:opacity-100'],
          )}
        />

        <h2
          className={cn(
            'text-h3 transition-all',
            'group-[&[data-sticky="true"]]/modal-title:text-h5',
            'group-[&[data-sticky="true"]]/modal-title:-mt-0.5',
            titleClassName,
          )}
        >
          {children}
        </h2>
      </div>

      {subtitle && <div className={cn('mb-[18px] text-paragraph-sm text-text-secondary last:mb-0')}>{subtitle}</div>}
    </>
  );
};
