import { useQuery } from '@tanstack/react-query'
import { Price, PriceTemplateRead } from '@/api/dashboard'
import { dashboardClientV1 } from '@/api'
import { ModalRushPage } from '@/ui'
import { useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { getPriceTemplateDetailsOptions } from '../api'
import { PriceTemplateModal } from '../components'
import { SKU_PRICE_TEMPLATES_PATH } from '../paths'

export const PriceTemplateEditPage = () => {
  const back = useNavigateBack({ fallback: SKU_PRICE_TEMPLATES_PATH })
  const { companyId, gameId, priceTemplateId } = useGenerateCompanyPath<{ priceTemplateId: string }>()

  const { data: template, isFetching } = useQuery({
    ...getPriceTemplateDetailsOptions(companyId, gameId, priceTemplateId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  })

  const onFormSubmit = async (data: PriceTemplateRead) => {
    if (!template) {
      return
    }

    const customs: Price[] = data.prices
      .filter(price => price.is_custom)
      .map(price => ({
        country: price.country,
        currency: price.currency,
        platform: price.platform,
        price: price.price,
      }))

    await dashboardClientV1.v1.updatePriceTemplate(companyId, gameId, template.id, { ...data, customs })
  }

  return (
    <ModalRushPage back={back}>
      {props => (
        <PriceTemplateModal
          {...props}
          template={isFetching ? undefined : template}
          mode="manage"
          onSubmit={onFormSubmit}
        />
      )}
    </ModalRushPage>
  )
}
