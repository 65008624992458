import { useNavigate, useParams } from 'react-router-dom'
import { cn } from '@dashboard/ui'
import { ItemType } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { FullscreenLayout } from '@/components/layouts'
import { SKU_ITEMS_BUNDLES_PATH, SKU_ITEMS_PATH, useGenerateCompanyPath } from '@/libs'
import { useBanner } from '@/libs/hooks/useBanner'
import { useItemCreateMutate } from '../api'
import { SkuForm } from '../widgets/SkuForm'
import { SkuFormData } from '../types'

export const SkuCreatePage = (props: { type: ItemType }) => {
  const navigate = useNavigate()
  const { generatePath } = useGenerateCompanyPath()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string; itemId: string }
  const { mutateAsync: createMutateAsync } = useItemCreateMutate(companyId, gameId)
  const { getStickyHeight } = useBanner()

  const onSubmit = async (data: SkuFormData) => {
    delete data.bonus_percent
    delete data.discount_percent
    const createdItem = await createMutateAsync({
      data: data,
    })

    if (data.properties?.length) {
      await Promise.all(
        data.properties.map(it =>
          dashboardClient.v1.createItemPropertyRelation(companyId, gameId, createdItem.id, {
            position: it.position,
            property_id: it.property_id,
            data: it.data,
          }),
        ),
      )
    }

    if (data.type == ItemType.Bundle) {
      navigate(generatePath(SKU_ITEMS_BUNDLES_PATH), { replace: true })
    } else {
      navigate(generatePath(SKU_ITEMS_PATH), { replace: true })
    }
    return data
  }

  return (
    <FullscreenLayout className="bg-linear-bg-gradient">
      <div className={cn(getStickyHeight())}>
        <SkuForm onSubmit={onSubmit} type={props.type} />
      </div>
    </FullscreenLayout>
  )
}
