import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { DropzoneOptions } from 'react-dropzone'
import { cn } from '@dashboard/ui'
import { ItemViewOption } from '@/api/dashboard'
import { fetchUploadImage } from '@/api/files'
import { PreviewProductCard } from '@/components/ui'
import { getDateTimeFormatParams } from '@/libs/dates'
import { S3Bucket } from '@/types'
import { TooltipAlertCircle } from '@/ui'
import { FormSection } from '../components'
import { SkuFormData } from '../types'

interface RowProps {
  label: ReactNode
  value: ReactNode
  tooltip?: string
  depth?: 0 | 1
}

const Row = ({ label, value, depth = 0, tooltip }: RowProps) => (
  <div
    className={cn(
      'flex items-center justify-between gap-3 border-b border-border-secondary p-3 last:border-b-transparent',
      depth === 1 && 'pl-6',
    )}
  >
    <div className="flex items-center gap-1 text-caption-md text-text-tertiary">
      {label}
      {tooltip && <TooltipAlertCircle message={tooltip} placement="top" />}
    </div>
    <div className="truncate text-caption-lg text-text-secondary">{value}</div>
  </div>
)

export const SkuFormSidebar = () => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext<SkuFormData>()

  const item = watch()

  const onDropImage: DropzoneOptions['onDrop'] = async acceptedFiles => {
    const file = acceptedFiles[0]
    try {
      const data = await fetchUploadImage(file, { bucket: S3Bucket.sku })
      setValue('image_url', data.get_url, { shouldDirty: true })
    } catch (err) {
      console.error(err)
    }
  }

  const onDeleteImage = () => {
    setValue('image_url', '', { shouldDirty: true })
  }

  const onSkeletonChange = (v: boolean) => {
    setValue('view_option', v ? ItemViewOption.Skeleton : ItemViewOption.Default, { shouldDirty: true })
  }

  return (
    <div>
      <FormSection label={t('game-item-page.item-summary')}>
        <div className="flex items-center justify-between rounded-md bg-fg-secondary p-3 pb-6">
          <div className="text-caption-md text-text-tertiary">{t('game-item-page.created_at')}</div>
          <div className="text-caption-lg text-text-secondary">
            {item.created_at ? t('intl.DateTime', getDateTimeFormatParams(item.created_at, 'short')) : '-'}
          </div>
        </div>
        <div className="-mt-3 rounded-md border border-border-primary bg-fg-primary">
          {item.id && <Row label="UID" value={item.id} tooltip={t('sku.item.id.tooltip')} />}
          <Row label={t('sku.item.sku')} value={item.sku || '–'} tooltip={t('sku.item.sku.tooltip')} />
          <Row
            label={t('sku.item.is_stackable')}
            value={item.is_stackable ? t('sku.item.sidebar.on') : t('sku.item.sidebar.off')}
          />
          <Row
            label={t('sku.item.is_currency')}
            value={item.is_currency ? t('sku.item.sidebar.on') : t('sku.item.sidebar.off')}
          />
        </div>
      </FormSection>

      <div className="rounded-2xl1 border border-border-primary bg-bg-primary p-6">
        <PreviewProductCard
          item={item}
          onDropImage={onDropImage}
          onDeleteImage={onDeleteImage}
          onSkeletonChange={onSkeletonChange}
        />
      </div>
    </div>
  )
}
