import { AnimatePresence, motion } from 'framer-motion'
import { cva } from 'class-variance-authority'
import { cn } from '@dashboard/ui'

interface StepProgressBarProps {
  value: number
  size?: 'sm' | 'md'
}

const variants = cva('w-full overflow-hidden rounded-full bg-fg-secondary-alt', {
  variants: {
    size: {
      sm: 'h-2',
      md: 'h-4',
    },
  },
})

export const StepProgressBar = ({ value, size = 'sm' }: StepProgressBarProps) => (
  <AnimatePresence initial={false}>
    <div className={cn(variants({ size }))}>
      <motion.div
        className="flex h-full min-w-2 rounded-full bg-fg-brand-primary"
        initial={{ width: 0 }}
        animate={{ width: `${value}%` }}
      />
    </div>
  </AnimatePresence>
)
