import { CASHBACK_REWARDS_PATH } from 'src/layouts/cashback-rewards'
import { SKU_PRICE_TEMPLATES_PATH } from '@/layouts/sku-price-templates/paths'
import {
  ENGAGEMENT_CREATORS_PATH,
  ENGAGEMENT_DAILY_REWARDS_PATH,
  ENGAGEMENT_LOYALTY_PATH,
  GAME_SEGMENTS_PATH,
  LIVEOPS_PATH_TABLE,
  OFFERS_PATH,
  SKU_CODES_PATH,
  SKU_ITEMS_BUNDLES_PATH,
  SKU_ITEMS_PATH,
  STORE_PATH,
  STORE_PROMOTIONS_PATH,
} from '@/libs/index'

export enum Features {
  sku = 'sku',
  bundle = 'bundle',
  store = 'store',
  store_promotions = 'store_promotions',
  coupon = 'coupon',
  campaign = 'campaign',
  segment = 'segment',
  demo = 'demo',
  sku_cashback_rewards = 'cashback_reward',
  hub_builder = 'hub_builder',
  glossary = 'glossary',
  daily_rewards = 'daily_rewards',
  loyalty = 'loyalty',
  creators = 'creators',
  price_templates = 'price_templates',
  lootboxes = 'lootboxes',
  sequential_offers = 'sequential_offers',
}

const FeatureRouterPath = {
  [STORE_PATH]: Features.store,
  [STORE_PROMOTIONS_PATH]: Features.store_promotions,
  [SKU_ITEMS_PATH]: Features.sku,
  [SKU_ITEMS_BUNDLES_PATH]: Features.bundle,
  [SKU_CODES_PATH]: Features.coupon,
  [CASHBACK_REWARDS_PATH]: Features.sku_cashback_rewards,
  [LIVEOPS_PATH_TABLE]: Features.campaign,
  [GAME_SEGMENTS_PATH]: Features.segment,
  [ENGAGEMENT_DAILY_REWARDS_PATH]: Features.daily_rewards,
  [ENGAGEMENT_LOYALTY_PATH]: Features.loyalty,
  [ENGAGEMENT_CREATORS_PATH]: Features.creators,
  [SKU_PRICE_TEMPLATES_PATH]: Features.price_templates,
  [OFFERS_PATH]: Features.sequential_offers,
} as Record<string, Features>

export function getFeatureByPath(pathname: string, companyId: string, gameId: string): Features | undefined {
  pathname = pathname.replace(companyId, ':companyId').replace(gameId, ':gameId')

  return FeatureRouterPath[pathname]
}
