import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ClipboardDownload: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 20 20" ref={ref}>
      <path
        d="M10.0007 9.16406V14.1641M10.0007 14.1641L7.50065 11.6641M10.0007 14.1641L12.5007 11.6641M13.334 3.33073C14.109 3.33073 14.4964 3.33073 14.8144 3.41591C15.6771 3.64708 16.351 4.32095 16.5821 5.18368C16.6673 5.5016 16.6673 5.88909 16.6673 6.66406V14.3307C16.6673 15.7309 16.6673 16.4309 16.3948 16.9657C16.1552 17.4361 15.7727 17.8186 15.3023 18.0582C14.7675 18.3307 14.0674 18.3307 12.6673 18.3307H7.33398C5.93385 18.3307 5.23379 18.3307 4.69901 18.0582C4.2286 17.8186 3.84615 17.4361 3.60647 16.9657C3.33398 16.4309 3.33398 15.7309 3.33398 14.3307V6.66406C3.33398 5.88909 3.33398 5.5016 3.41917 5.18368C3.65034 4.32095 4.32421 3.64708 5.18694 3.41591C5.50485 3.33073 5.89234 3.33073 6.66732 3.33073M8.00065 4.9974H12.0007C12.4674 4.9974 12.7007 4.9974 12.879 4.90657C13.0358 4.82667 13.1633 4.69919 13.2432 4.54239C13.334 4.36413 13.334 4.13077 13.334 3.66406V2.9974C13.334 2.53069 13.334 2.29733 13.2432 2.11907C13.1633 1.96227 13.0358 1.83478 12.879 1.75489C12.7007 1.66406 12.4674 1.66406 12.0007 1.66406H8.00065C7.53394 1.66406 7.30059 1.66406 7.12233 1.75489C6.96552 1.83478 6.83804 1.96227 6.75815 2.11907C6.66732 2.29733 6.66732 2.53069 6.66732 2.9974V3.66406C6.66732 4.13077 6.66732 4.36413 6.75815 4.54239C6.83804 4.69919 6.96552 4.82667 7.12233 4.90657C7.30059 4.9974 7.53394 4.9974 8.00065 4.9974Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  );
});

ClipboardDownload.type = 'aghanim-icon';
