import { infiniteQueryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

export interface allCompaniesInfiniteQueryParams {
  /**
   * Locale id
   * Filter by locale
   */
  locale_id?: string
  /**
   * Q
   * Filter by search query
   */
  search_string?: string
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 300
   * @default 10
   */
  limit?: number
  /**
   * Starting After
   * The cursor for pagination
   */
  starting_after?: string
  /**
   * Ending Before
   * The cursor for pagination
   */
  ending_before?: string
}

/**
 * @param params
 * @returns
 */
export const allCompaniesInfiniteQuery = ({ ...params }: allCompaniesInfiniteQueryParams) =>
  infiniteQueryOptions({
    queryKey: ['all_companies', params],
    queryFn: async ({ pageParam }) => {
      const { data } = await dashboardClient.v1.getAllCompanyGames({
        ...params,
        starting_after: pageParam || undefined,
      })
      return data
    },
    initialPageParam: '',
    getNextPageParam: lastPage => {
      return lastPage.next_cursor || null
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
