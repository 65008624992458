import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { Clock, FormErrorMessage, Input, formatHookFormErrors } from '@dashboard/ui'
import { gameItemsQueryOptions } from '@/api/useGameItems'
import { Currency, ItemType } from '@/api/dashboard'
import { MAX_QUANTITY } from '@/Settings'
import { Badge, FieldGroup } from '@/ui'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { SkuFormData } from '../types'
import { useFeatureFlags, useGenerateCompanyPath } from '@/libs'
import { CollapseFormSection } from '@/layouts/game-items/components/CollapseFormSection'
import { ToggleProp } from '@/layouts/game-items/components/ToggleProp'
import { SelectRarity } from '@/layouts/components/SelectRarity'

export const SkuAdditionalProps = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { featureFlags } = useFeatureFlags()

  const {
    formState: { errors },
    control,
    setValue,
    register,
    watch,
  } = useFormContext<SkuFormData>()

  // @ts-ignore
  const [fallback_item_id, currency, id, enable_reward_points_redemption, duration, rarity_id] = watch([
    'fallback_item_id',
    'currency',
    'id',
    'enable_reward_points_redemption',
    'duration',
    'rarity_id',
  ])

  const { data: items = [] } = useQuery({
    ...gameItemsQueryOptions(companyId, gameId, {
      ids: fallback_item_id,
    }),
    enabled: !!fallback_item_id,
  })

  const renderRPRedemption = () => {
    // @ts-ignore
    return (
      <ToggleProp
        label={t('item-props.sku.enable_reward_points_redemption')}
        initialValue={!!enable_reward_points_redemption}
        onChange={value => setValue('enable_reward_points_redemption', value, { shouldDirty: true })}
      />
    )
  }

  const renderFallbackItem = () => {
    return (
      <ToggleProp
        label={t('game-item-edit-dialog.fallback-item')}
        onChange={v => {
          if (!v) {
            setValue('fallback_item_id', null as unknown as string, { shouldDirty: true })
          }
        }}
        initialValue={!!fallback_item_id}
      >
        <div className="mb-2 flex items-start justify-between">
          <div className="w-1/3 text-caption-md font-normal text-text-secondary">
            {t('game-item-edit-dialog.fallback-item.desc')}
          </div>
          <div className="w-1/2">
            <FieldGroup label={t('sku.item.sku')}>
              <Controller
                control={control}
                name="fallback_item_id"
                rules={{
                  required: t('validation.required'),
                }}
                render={({ field }) => (
                  <SelectSkuItemV2
                    types={[ItemType.Item, ItemType.Currency]}
                    hideIds={[id]}
                    value={field.value}
                    onChange={(v, selectedItem) => {
                      field.onChange(v)
                      setValue(
                        'fallback_item_quantity',
                        (selectedItem?.is_stackable ? selectedItem.quantity : null) as number,
                        { shouldDirty: true },
                      )
                    }}
                  />
                )}
              />
              <ErrorMessage
                name="fallback_item_id"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            {fallback_item_id && items[0]?.is_stackable && (
              <FieldGroup label={t('game-item-edit-dialog.quantity')}>
                <Input
                  type="number"
                  min={0}
                  {...register('fallback_item_quantity', {
                    required: t('validation.required'),
                    min: { value: 0, message: t('game-item-page.price-point-min', { min: 0 }) },
                    max: {
                      value: MAX_QUANTITY,
                      message: t('validation.max', { field: 'Quantity', value: MAX_QUANTITY }),
                    },
                    setValueAs: value => {
                      if (value == null) {
                        return null
                      }
                      return parseFloat(value)
                    },
                  })}
                  {...formatHookFormErrors(errors, 'fallback_item_quantity')}
                />
              </FieldGroup>
            )}
          </div>
        </div>
      </ToggleProp>
    )
  }

  const renderDuration = () => (
    <ToggleProp
      label={t('item-props.sku.properties.duration')}
      initialValue={duration != null}
      onChange={v => setValue('duration', v ? 60 : (null as unknown as number), { shouldDirty: true })}
    >
      <div className="mb-2 flex items-center justify-between">
        <div className="w-1/3 text-caption-md font-normal text-text-secondary">
          {t('item-props.sku.properties.duration.desc')}
        </div>
        <div className="w-1/3">
          <Input
            value={duration}
            type={'number'}
            onChange={e => {
              const v = parseInt(e.target.value)
              const newValue = isNaN(v) || v <= 0 ? null : v
              setValue('duration', newValue as number, { shouldDirty: true })
            }}
            extraRight={{
              icon: (
                <Badge variant="brand-blue-tertiary">
                  <Clock size={14} />
                  <span>{t('seconds')}</span>
                </Badge>
              ),
            }}
          />
        </div>
      </div>
    </ToggleProp>
  )

  const renderRarity = () => (
    <ToggleProp
      label={t('item-props.sku.properties.rarity')}
      initialValue={rarity_id != null}
      onChange={v => {
        if (!v) {
          setValue('rarity_id', null as unknown as string, { shouldDirty: true })
        }
      }}
    >
      <div className="mb-2 flex items-center justify-between">
        <div className="w-1/3 text-caption-md font-normal text-text-secondary">
          {t('item-props.sku.properties.rarity.desc')}
        </div>
        <div className="w-1/3">
          <SelectRarity
            value={rarity_id}
            onChange={v => {
              setValue('rarity_id', v as string, { shouldDirty: true })
            }}
          />
        </div>
      </div>
    </ToggleProp>
  )

  return (
    <CollapseFormSection label={t('game-item.additional-props')}>
      <div className="px-6">
        {renderRarity()}
        {renderDuration()}
        {renderFallbackItem()}
        {currency != Currency.RP && featureFlags.reward_points_pay_enabled && renderRPRedemption()}
      </div>
    </CollapseFormSection>
  )
}
