import { InputHTMLAttributes, forwardRef, useId } from 'react';
import { tv } from 'tailwind-variants';
import { cn } from '../../libs';
import { Spinner } from '../Spinner';

export interface ToggleProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'children' | 'size'> {
  indeterminate?: boolean;
  /**
   * Size of the toggle.
   */
  size?: 'sm' | 'md';
  /**
   * Color of the toggle.
   */
  color?: 'primary' | 'danger';
  /**
   * Loading state of the toggle.
   */
  loading?: boolean;
  /**
   * Disables the toggle.
   */
  disabled?: boolean;
}

const toggle = tv({
  base: 'group/checkbox relative inline-flex',
  slots: {
    surface: [
      'cursor-pointer rounded-full shadow-[0_0_4px_0_rgba(0,0,0,0.06)_inset] ring-offset-1 transition-all',
      'peer-focus-visible/checkbox:ring-2',
      'peer-focus-visible/checkbox:ring-brand',
      'peer-focus-visible/checkbox:border-border-brand-solid-alt',
      'peer-disabled/checkbox:bg-fg-disabled',
      'peer-disabled/checkbox:border-border-disabled',
      'peer-disabled/checkbox:shadow-none',
    ],
    trigger:
      'pointer-events-none absolute left-0.5 top-0.5 flex items-center justify-center rounded-full bg-fg-primary transition-all',
    spinner: 'absolute left-0 top-0 flex size-full items-center justify-center transition-all delay-[50ms]',
    indicator: 'translate-x-0.5 opacity-0 transition-all',
  },
  variants: {
    size: {
      sm: {
        surface: 'h-5 w-[38px]',
        trigger: ['size-4', 'peer-checked/checkbox:translate-x-[calc(100%+0.1rem)]'],
        spinner: 'p-0.5',
        indicator: 'h-0.5 w-2 rounded-sm',
      },
      md: {
        surface: 'h-7 w-14',
        trigger: ['size-6', 'peer-checked/checkbox:translate-x-[calc(100%+0.25rem)]'],
        spinner: 'p-1',
        indicator: 'h-1 w-3.5 rounded',
      },
    },
    color: {
      primary: {
        surface: ['bg-fg-secondary-alt', 'peer-checked/checkbox:bg-fg-brand-primary'],
        spinner: 'text-text-quarterary',
        indicator: 'bg-fg-brand-primary',
      },
      danger: {
        surface: ['bg-fg-secondary-alt', 'peer-checked/checkbox:bg-fg-error-solid'],
        spinner: 'text-text-quarterary',
        indicator: 'bg-fg-error-solid',
      },
    },
    indeterminate: {
      true: {
        indicator: [
          'group-has-[input:checked]/checkbox:translate-x-0',
          'group-has-[input:checked]/checkbox:opacity-100',
        ],
      },
      false: {},
    },
    loading: {
      true: {
        spinner: 'opacity-100',
      },
      false: {
        spinner: 'opacity-0',
      },
    },
  },
  compoundVariants: [
    {
      size: 'sm',
      indeterminate: true,
      class: {
        trigger: 'peer-checked/checkbox:translate-x-[calc(50%+0.1rem)]',
      },
    },
    {
      size: 'md',
      indeterminate: true,
      class: {
        trigger: 'peer-checked/checkbox:translate-x-[calc(50%+.25rem)]',
      },
    },
    {
      indeterminate: true,
      loading: true,
      class: {
        indicator: '!translate-x-0.5 !opacity-0',
      },
    },
    {
      loading: true,
      class: {
        base: 'pointer-events-none',
      },
    },
  ],
});

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>(function Toggle(
  { size = 'md', color = 'primary', indeterminate, loading, className, ...rest },
  ref,
) {
  const id = useId();
  const { base, surface, trigger, spinner, indicator } = toggle({ size, color, indeterminate, loading });

  return (
    <div className={cn(base(), className)}>
      <input
        {...rest}
        ref={ref}
        className={cn(
          'peer/checkbox absolute left-0 top-0 size-0 shrink-0 cursor-pointer appearance-none',
          'disabled:pointer-events-none',
        )}
        type="checkbox"
        data-indeterminate={indeterminate}
        id={rest.id || id}
      />
      <label className={cn(surface())} htmlFor={rest.id || id} />
      <div className={cn(trigger())}>
        <Spinner className={cn(spinner())} />
        <div className={cn(indicator())} />
      </div>
    </div>
  );
});
