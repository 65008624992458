import { countries } from 'country-data'

/**
 * Get the country by currency code.
 * @param currency
 * @returns
 */
export const getCountryByCurrency = (currency: string) => {
  switch (currency) {
    case 'USD':
      return 'US'
    case 'EUR':
      return 'EU'
    default:
      const country = countries.all.find(c => c.currencies.includes(currency))
      return country ? country.alpha2 : undefined
  }
}
