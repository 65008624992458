import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'

interface PriceTemplateModalConfirmPlatformProps extends ModalProps {
  action: 'enable' | 'disable'
  onConfirm: () => Promise<void>
}

export const PriceTemplateModalConfirmPlatform = ({
  action,
  onConfirm,
  ...rest
}: PriceTemplateModalConfirmPlatformProps) => {
  const { t } = useTranslation('sku-price-templates')

  const onClickCancel = useCallback(() => {
    rest.onClose()
  }, [rest.onClose])

  const onClickConfirm = useCallback(async () => {
    await onConfirm()
    rest.onClose()
  }, [rest.onClose, onConfirm])

  return (
    <ModalPresetConfirm
      {...rest}
      size="md"
      title={{
        children: t(`modal-confirm-platform.${action}.title`),
        subtitle: t(`modal-confirm-platform.${action}.subtitle`),
      }}
      cancel={{ label: t(`modal-confirm-platform.${action}.cancel`), callback: onClickCancel }}
      submit={{
        label: t(`modal-confirm-platform.${action}.confirm`),
        color: action === 'enable' ? 'primary' : 'danger',
        callback: onClickConfirm,
      }}
    />
  )
}
