import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/ui'
import { GetStartedStepState } from '../types'
import { useGetStarted } from './useGetStarted'
import EditInviteDialog from '@/layouts/users/EditInviteDialog'
import { useModal } from '@dashboard/ui'
import { CustomerCompanyRead, InviteRead, Role } from '@/api/dashboard'
import { WebhookTasks } from './useWebhookStep'

/**
 *
 */
export const useInviteStep = (): GetStartedStepState<WebhookTasks> => {
  const { t } = useTranslation()
  const { check, complete } = useGetStarted()

  const id = 'invite_user'

  const status = check('invite_user') ? 'completed' : 'idle'

  useEffect(() => {
    if (status !== 'completed') {
      return
    }
    complete('invite_user')
  }, [complete, status])

  const openInviteModal = useModal<{ item: InviteRead | CustomerCompanyRead }>(props => (
    <EditInviteDialog
      {...props}
      item={props.item}
      onClose={() => {
        props.onClose?.()
      }}
    />
  ))

  return {
    id,
    loading: false,
    status,
    available_on: ['mobile'],
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    cta: (
      <Button
        className="min-w-40"
        variant={status === 'completed' ? 'outline' : 'secondary-brand'}
        size="lg"
        onClick={() => openInviteModal({ item: { role: Role.User } as InviteRead })}
      >
        {t(`get-started.steps.${id}.cta.label`)}
      </Button>
    ),
  }
}
