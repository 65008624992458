import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'

interface PriceTemplateModalConfirmAdjustProps extends ModalProps {
  onConfirm: () => Promise<void>
}

export const PriceTemplateModalConfirmAdjust = ({ onConfirm, ...rest }: PriceTemplateModalConfirmAdjustProps) => {
  const { t } = useTranslation('sku-price-templates')

  const onClickCancel = useCallback(() => {
    rest.onClose()
  }, [rest.onClose])

  const onClickConfirm = useCallback(async () => {
    await onConfirm()
    rest.onClose()
  }, [rest.onClose, onConfirm])

  return (
    <ModalPresetConfirm
      {...rest}
      size="md"
      title={{
        children: t('modal-confirm-adjust.title'),
        subtitle: t('modal-confirm-adjust.subtitle'),
      }}
      cancel={{ label: t('modal-confirm-adjust.cancel'), callback: onClickCancel }}
      submit={{ label: t('modal-confirm-adjust.confirm'), color: 'primary', callback: onClickConfirm }}
    />
  )
}
