import { FunctionComponent, createElement, useCallback } from 'react';
import cuid from 'cuid';
import { useModalStore } from './useModalStore';
import { ModalBaseCloseFn, ModalBaseProps } from './types';

/**
 * This hook is used to show modal windows.
 * @param component
 */
export const useModal = <T>(component: FunctionComponent<ModalBaseProps & T>) => {
  const { push, shift, reset } = useModalStore();

  return useCallback(
    (props: T = {} as T) => {
      const id = cuid();

      const close: ModalBaseCloseFn = (_, params) => {
        if (params?.closeAll) {
          reset();
        } else {
          shift(id);
        }
      };

      // @ts-ignore
      const isPropsEvent = !!props._reactName;
      const computedProps = isPropsEvent ? { id, onClose: close } : { ...props, id, onClose: close };

      requestAnimationFrame(() => {
        // @ts-ignore
        push(id, createElement<ModalBaseProps>(component, computedProps));
      });
    },
    [component, push, reset, shift],
  );
};
