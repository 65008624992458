import { SelectStoreItem } from '@/layouts/components/SelectSkuItem'
import { FieldGroup, Tab, Tabs } from '@/ui'
import { useStoresQuery } from '@/layouts/store/api'
import { ReactNode, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useStoreItemsQuery } from '@/layouts/store/api/useStoreItemsQuery'
import { SelectSkuItemV2 } from './SelectSkuItemV2'
import { useTranslation } from 'react-i18next'
import { Select } from '@dashboard/ui'

interface SelectSkuPanel {
  itemId: string | null | undefined
  storeItemId: string | null | undefined
  storeId: string | null | undefined
  children?: ReactNode
  onChange: (
    item_id: string | null | undefined,
    store_id: string | null | undefined,
    store_item_id: string | null | undefined,
  ) => void
}

/**
 * Use this component to select sku item from store or base
 * @param props
 * @constructor
 */
const SelectSkuPanel = (props: SelectSkuPanel) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const storeId = props.storeId

  const { data: storeItems = [] } = useStoreItemsQuery(companyId, gameId, storeId!)

  const [fromStore, setFromStore] = useState(!!storeId)

  return (
    <div className="mb-3">
      <Tabs>
        <Tab
          data-testid="tab-item-base"
          isActive={!fromStore}
          onClick={() => {
            setFromStore(false)
            props.onChange(null, null, null)
          }}
        >
          {t('select.sku.base')}
        </Tab>
        <Tab
          data-testid="tab-item-store"
          isActive={fromStore}
          onClick={() => {
            setFromStore(true)
            props.onChange(null, null, null)
          }}
        >
          {t('select.sku.from-store')}
        </Tab>
      </Tabs>

      {fromStore && (
        <FieldGroup label={t('store.store')}>
          <Select
            value={storeId}
            onChange={v => props.onChange(null, v as string, null)}
            items={stores.map(s => ({
              value: s.id,
              children: s.name,
            }))}
          />
        </FieldGroup>
      )}

      {fromStore ? (
        <SelectStoreItem
          storeItems={storeItems}
          value={props.itemId}
          onChange={v => {
            props.onChange(v as string, props.storeId, storeItems.find(it => it.item.id === v)?.id)
          }}
        />
      ) : (
        <SelectSkuItemV2
          value={props.itemId}
          onChange={v => {
            props.onChange(v as string, null, null)
          }}
        />
      )}

      {props.children}
    </div>
  )
}

export default SelectSkuPanel
