import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { BackButton, PageHeader, ToastSeverity, useToast } from '@/ui'
import { Button, FormErrorMessage } from '@dashboard/ui'
import { OFFERS_PATH, usePydenticForm } from '@/libs'
import { RangeOfferRead, RangeOfferTier } from '@/api/dashboard'
import { URL_NEW } from '@/types'
import { useTranslation } from 'react-i18next'
import { PlusContained } from '@/icons'
import { AddButton } from '@/layouts/components/AddButton'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { useRangeOfferQuery } from '@/layouts/offers/range-api/useRangeOfferQuery'
import { useRangeOfferCreateMutate, useRangeOfferUpdateMutate } from '@/layouts/offers/range-api'
import { TierForm } from '@/layouts/offers/range-offer-widgets/TierForm'
import { TierItem } from '@/layouts/offers/range-offer-widgets/TierItem'
import { generateUid } from '@/util'
import { GeneralSettings } from '@/layouts/offers/range-offer-widgets/GeneralSettings'
import { Summary } from '@/layouts/offers/range-offer-widgets/Summary'
import { useGameItem } from '@/api/useGameItems'

export const RangeOfferFormPage = () => {
  const { companyId, gameId, offerId } = useParams() as {
    companyId: string
    gameId: string
    offerId: string
  }
  const showToast = useToast()
  const { mutateAsync: createMutateAsync } = useRangeOfferCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useRangeOfferUpdateMutate(companyId, gameId)

  const { t } = useTranslation()
  const { t: tOffer } = useTranslation('offers')
  const navigate = useNavigate()
  const { data: offer } = useRangeOfferQuery(companyId, gameId, offerId, offerId != URL_NEW)
  const [settingsOpen, setSettingsOpen] = useState(offerId == URL_NEW)
  const [currentTier, setCurrentTier] = useState<null | RangeOfferTier>(null)

  const {
    formState: { isDirty, isSubmitting, errors },
    reset,
    getValues,
    watch,
    handleSubmit,
    setValue,
  } = usePydenticForm<RangeOfferRead>({
    values: offer
      ? {
          ...offer,
        }
      : undefined,
    defaultValues: {
      settings: {
        quantity_for_one_cent: 1,
      },
    },
  })

  const tiers = watch('settings.tiers') || []
  const item_id = watch('item_id')
  const { data: item } = useGameItem(companyId, gameId, item_id)

  const onSubmit = async (data: RangeOfferRead) => {
    if (!data.name || !data.description || !data.item_id) {
      setSettingsOpen(true)
      return
    }

    if (tiers.length === 0) {
      showToast({ message: tOffer('tiers.required'), severity: ToastSeverity.error })
      return
    }

    if (!data.settings.background_image_url) {
      data.settings.background_image_url = null as unknown as string
    }

    if (data.id) {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } else {
      await createMutateAsync({
        create: data,
      })
    }

    reset(data)
    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  const getNextDefaultTier = () => {
    const lastTier = tiers[tiers.length - 1]
    if (!lastTier) {
      return {
        id: generateUid('tier'),
        bonus_percent: 10,
        from_usd: 0,
        to_usd: 10000,
      }
    }

    return {
      id: generateUid('tier'),
      bonus_percent: lastTier.bonus_percent + 10,
      from_usd: lastTier.to_usd + 1,
      to_usd: lastTier.to_usd + 10000,
    }
  }

  return (
    <div className="flex size-full flex-col">
      <DrawerSidebar open={settingsOpen || !!currentTier} width="720px" position="right">
        {settingsOpen && (
          <GeneralSettings
            offer={getValues()}
            onApply={data => {
              // @ts-ignore
              setValue('name', data.name, { shouldDirty: true })
              setValue('description', data.description, { shouldDirty: true })
              setValue('start_at', data.start_at, { shouldDirty: true })
              setValue('end_at', data.end_at, { shouldDirty: true })
              setValue('settings', data.settings, { shouldDirty: true })
              setValue('item_id', data.item_id, { shouldDirty: true })
            }}
            onClose={() => {
              setSettingsOpen(false)
            }}
          />
        )}
        {currentTier && (
          <TierForm
            title={
              tiers.indexOf(currentTier) > -1
                ? tOffer('tier') + ' ' + (tiers.indexOf(currentTier) + 1)
                : tOffer('add-tier')
            }
            tier={currentTier}
            onSubmit={data => {
              const existingPack = tiers.find(p => p.id === data.id)
              if (existingPack) {
                setValue(
                  'settings.tiers',
                  tiers.map(p => (p.id === data.id ? data : p)),
                  { shouldDirty: true },
                )
              } else {
                setValue('settings.tiers', [...tiers, data], { shouldDirty: true })
              }
            }}
            onClose={() => {
              setCurrentTier(null)
            }}
          />
        )}
      </DrawerSidebar>
      <PageHeader
        sticky
        extra={
          <>
            <Button variant="outline" size="sm" color="primary" onClick={() => setSettingsOpen(true)}>
              {tOffer('settings')}
            </Button>
            {isDirty && offer?.id && (
              <Button variant="outline" color="secondary" size="sm" onClick={() => reset()}>
                {t('Discard')}
              </Button>
            )}
            <Button size="sm" disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
              {t('Save')}
            </Button>
          </>
        }
      >
        <BackButton
          text={watch('name') || tOffer('create-range')}
          onClick={() => navigate(generatePath(OFFERS_PATH, { companyId: companyId, gameId: gameId }))}
        />
      </PageHeader>

      <div className="flex gap-3">
        <div className="flex grow flex-col gap-3">
          {tiers.map((tier, index) => {
            return (
              <TierItem
                image_url={item?.image_url}
                key={tier.id}
                num={index + 1}
                tier={tier}
                onEdit={() => setCurrentTier(tier)}
                onDelete={() => {
                  setValue(
                    'settings.tiers',
                    tiers.filter(p => p.id !== tier.id),
                    { shouldDirty: true },
                  )
                }}
              />
            )
          })}

          <ErrorMessage
            name={`settings.tiers`}
            errors={errors}
            render={({ message }) => {
              return message ? <FormErrorMessage>{message}</FormErrorMessage> : ''
            }}
          />

          <div className="h-14">
            <AddButton
              size="md"
              disabled={isSubmitting}
              icon={PlusContained}
              onClick={() => {
                setCurrentTier(getNextDefaultTier())
              }}
            >
              {tOffer('add-tier')}
            </AddButton>
          </div>
          <ErrorMessage
            name="settings"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </div>
        <Summary offer={getValues()} />
      </div>
    </div>
  )
}
