import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  DRAGSTART_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  LexicalEditor,
  NodeKey,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { mergeRegister } from '@lexical/utils'
import { cn } from '@/libs'
import { ImageLazy, ImageResizer } from '../../components'
import { $isInlineImageNode, Position } from './ImageNode'

interface ImageNodeRenderProps {
  nodeKey: NodeKey
  src: string
  alt: string
  width: string | number
  height: string | number
  position: Position
}

export const ImageNodeRender = ({ nodeKey, src, alt, width, height }: ImageNodeRenderProps) => {
  const imageRef = useRef<null | HTMLImageElement>(null)
  const activeEditorRef = useRef<LexicalEditor | null>(null)

  const [editor] = useLexicalComposerContext()
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)
  const [_, setIsResizing] = useState(false)
  const [selection, setSelection] = useState<BaseSelection | null>(null)

  const $onEnter = useCallback(() => {
    if (isSelected) {
      $getNodeByKey(nodeKey)?.insertAfter($createParagraphNode(), true)?.selectStart()
      return true
    }
    return false
  }, [nodeKey])

  const $onDelete = useCallback(
    (e: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        e.preventDefault()
        const node = $getNodeByKey(nodeKey)
        node?.remove()
        return true
      }
      return false
    },
    [isSelected, nodeKey],
  )

  useEffect(() => {
    let isMounted = true
    const unregister = mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        if (isMounted) {
          setSelection(editorState.read(() => $getSelection()))
        }
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_, activeEditor) => {
          activeEditorRef.current = activeEditor
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        payload => {
          const event = payload
          if (event.target === imageRef.current) {
            if (event.shiftKey) {
              setSelected(!isSelected)
            } else {
              clearSelection()
              setSelected(true)
            }
            return true
          }
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        event => {
          if (event.target === imageRef.current) {
            event.preventDefault()
            return true
          }
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(KEY_ENTER_COMMAND, $onEnter, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_DELETE_COMMAND, $onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, $onDelete, COMMAND_PRIORITY_LOW),
    )
    return () => {
      isMounted = false
      unregister()
    }
  }, [clearSelection, editor, isSelected, nodeKey, $onEnter, $onDelete, setSelected])

  const draggable = isSelected && $isNodeSelection(selection)

  const onResizeStart = () => {
    setIsResizing(true)
  }

  const onResizeEnd = (width: string | number, height: string | number) => {
    setTimeout(() => {
      setIsResizing(false)
    }, 200)

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isInlineImageNode(node)) {
        node.setWidthAndHeight(width, height)
      }
    })
  }

  return (
    <Suspense fallback={null}>
      <div className={cn('relative ring-brand', isSelected && 'ring-2')} draggable={draggable}>
        <ImageLazy ref={imageRef} className="mx-auto block" src={src} alt={alt} style={{ width, height }} />

        {isSelected && (
          <ImageResizer editor={editor} imageRef={imageRef} onResizeStart={onResizeStart} onResizeEnd={onResizeEnd} />
        )}
      </div>
    </Suspense>
  )
}
