import { useUiContext } from '../../providers';

interface CountryFlagProps {
  country: string;
}

export const CountryFlag = ({ country }: CountryFlagProps) => {
  const { staticImagesPath } = useUiContext();
  return (
    <img
      className="h-[18px] w-[26px] rounded bg-bg-primary shadow-xs"
      src={`${staticImagesPath}/images/locale-flags/${(country === 'VN' ? 'VI' : country).toUpperCase()}.svg`}
      alt={country}
    />
  );
};
