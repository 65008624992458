import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { RangeOfferUpdate } from '@/api/dashboard'

interface RangeOfferMutate {
  id: string
  update: RangeOfferUpdate
}

export const useRangeOfferUpdateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, update }: RangeOfferMutate) =>
      dashboardClient.v1.updateRangeOffer(companyId, gameId, id, update),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['range_offer', gameId, variables.id], data.data)
      queryClient.invalidateQueries({ queryKey: ['range_offers', gameId], exact: false })
    },
  })
}
