import { FieldGroup, ImageUploader } from '@/ui'
import { ErrorMessage } from '@hookform/error-message'
import { Controller, useForm } from 'react-hook-form'
import { GameStoreName, SocialMediaName } from '../../../api/dashboard'
import { StoreSocialMode } from '../tyles'
import { urlRegexp } from '@/libs'
import { useEffect } from 'react'
import { ImageSize } from '@/components/ui/ImageUploader/ValueContainer'
import { S3Bucket } from '@/types'
import {
  Button,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

interface ConfirmDialogProps extends ModalProps {
  onAdd: (item: Item) => void
  mode: StoreSocialMode
}

interface Item {
  name: SocialMediaName | GameStoreName
  url: string
  /** Custom Name */
  custom_name?: string
  /** Custom Icon Url */
  custom_icon_url?: string
}

export const SocialModal = (props: ConfirmDialogProps) => {
  const { ...rest } = props
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<Item>({})

  const [url, name] = watch(['url', 'name'])

  const getSocialStoreName = (url: string) => {
    if (!url || !url.startsWith('https://')) {
      return null
    }

    if (props.mode == StoreSocialMode.social) {
      if (url.includes('facebook.com')) {
        return SocialMediaName.Facebook
      } else if (url.includes('instagram.com')) {
        return SocialMediaName.Instagram
      } else if (url.includes('twitter.com') || url.startsWith('https://x.com')) {
        return SocialMediaName.Twitter
      } else if (url.includes('youtube.com')) {
        return SocialMediaName.Youtube
      } else if (url.includes('tiktok.com')) {
        return SocialMediaName.Tiktok
      } else if (url.includes('discord.com') || url.includes('discord.gg')) {
        return SocialMediaName.Discord
      }
      if (url.includes('t.me')) {
        return SocialMediaName.Telegram
      } else {
        return null
      }
    }
    if (props.mode == StoreSocialMode.store) {
      if (url.includes('apple.com')) {
        return GameStoreName.AppStore
      } else if (url.includes('play.google.com')) {
        return GameStoreName.GooglePlay
      } else if (url.includes('steampowered.com')) {
        return GameStoreName.Steam
      } else if (url.includes('amazon.')) {
        return GameStoreName.Amazon
      } else if (url.includes('appg.al') || url.includes('appgallery.huawei.com')) {
        return GameStoreName.Huawei
      } else if (url.includes('onestore.net') || url.includes('onestore.co')) {
        return GameStoreName.OneStore
      } else {
        return null
      }
    }
  }

  const onSubmit = async (data: Item) => {
    data.url = data.url.replace(/\s+/g, '')
    data.name = (getSocialStoreName(data.url) as SocialMediaName | GameStoreName) || SocialMediaName.Custom
    if (data.name != SocialMediaName.Custom) {
      data.custom_name = undefined
      data.custom_icon_url = undefined
    }
    rest.onAdd(data)
    rest.onClose?.()
  }

  const onCancel = async () => {
    rest.onClose?.()
  }

  useEffect(() => {
    setValue('name', getSocialStoreName(url) || SocialMediaName.Custom)
  }, [url])

  return (
    <Modal {...rest} onClose={() => props.onClose?.()}>
      <ModalContent>
        <ModalTitle>
          {t(props.mode == StoreSocialMode.store ? 'game-settings.store-store-add' : 'game-settings.store-media-add')}
        </ModalTitle>

        <FieldGroup label={t('game-settings.add.enter-url')}>
          <Input
            autoFocus
            {...register('url', {
              required: t('validation.required'),
              pattern: { value: urlRegexp, message: t('validation.invalid_url') },
            })}
            maxLength={500}
          />
          <ErrorMessage
            name="url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        {props.mode == StoreSocialMode.social && (
          <>
            <FieldGroup label={t('game-settings.add.custom-name')}>
              <Input
                {...register('custom_name', {
                  required: name == SocialMediaName.Custom ? t('validation.required') : false,
                })}
                disabled={name != SocialMediaName.Custom}
                maxLength={50}
              />
              <ErrorMessage
                name="custom_name"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            <FieldGroup label={t('game-settings.add.custom-url')}>
              <Controller
                name="custom_icon_url"
                control={control}
                rules={{
                  pattern: { value: urlRegexp, message: t('validation.invalid_url') },
                  required: name == SocialMediaName.Custom ? t('validation.required') : false,
                }}
                render={({ field }) => (
                  <ImageUploader
                    disabled={name != SocialMediaName.Custom}
                    imageSize={ImageSize.contain}
                    {...field}
                    accept={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': [],
                      'image/webp': [],
                      'image/gif': [],
                      'image/svg+xml': [],
                    }}
                    bucket={S3Bucket.hub}
                  />
                )}
              />
              <ErrorMessage
                name="custom_icon_url"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant="outline" color="secondary" type="button" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>{t('Add')}</Button>
      </ModalFooter>
    </Modal>
  )
}
