import { CheckFn, CheckLevel, CheckParams, CheckResult } from '@/layouts/sku-price-templates'

export const buildPriceChecker =
  (...fns: CheckFn[]): CheckFn =>
  (params: CheckParams) => {
    const results = fns.map(checkFn => checkFn(params))
    return (
      results.filter((result): result is CheckResult => !!result?.value).sort((a, b) => b.level - a.level)[0] ?? null
    )
  }

const deviation = (current: number, initial: number) =>
  (current < initial ? -1 : 1) * (Math.abs(current - initial) / initial) * 100

export const makeAnomalyPriceCheck =
  (level = CheckLevel.Warn, threshold: number = 50): CheckFn =>
  ({ initial, current }) => {
    if (!initial) {
      return null
    }

    const d = deviation(current, initial)

    return {
      level: level,
      name: 'anomalyPriceCheck',
      value: Math.abs(d) >= threshold,
      deviation: parseInt(Math.fround(d).toFixed(0)),
    }
  }

export const makeExistingPriceCheck =
  (level = CheckLevel.Info, threshold: number = 50): CheckFn =>
  ({ previous, current }) => {
    if (!previous) {
      return null
    }

    const d = deviation(current, previous)

    return {
      level: level,
      name: 'existingPriceCheck',
      target: previous,
      value: Math.abs(d) > threshold,
      deviation: parseInt(Math.fround(d).toFixed(0)),
    }
  }
