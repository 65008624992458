import { useTranslation } from 'react-i18next'
import { ItemRead, ItemType, LootboxGroup, LootboxNestedItem, LootboxRead, ResourceState } from '@/api/dashboard'
import { SkuImageName } from '@/layouts/game-items/components/SkuImageName'
import { Badge, ButtonIcon, Table, TableCell, TableRow } from '@/ui'
import { SelectSkuModal } from '@/layouts/components/SelectSkuModal/SelectSkuModal'
import { Trash } from '@/icons'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import {
  Button,
  DotsHorizontal,
  FormErrorMessage,
  Input,
  Menu,
  MenuItem,
  ModalProps,
  Popper,
  useModal,
} from '@dashboard/ui'
import { useEffect, useState } from 'react'
import { dashboardClient } from '@/api'
import { useGenerateCompanyPath } from '@/libs'
import { FormSection } from '../components'
import { AddButton } from '@/layouts/components/AddButton'
import { uuid4 } from '@/util'

export const LootboxGroupEditor = ({
  onChange,
  value,
  canDelete,
  groupIndex,
}: {
  onChange: (value: LootboxGroup | null) => void
  value: LootboxGroup
  canDelete?: boolean
  groupIndex: number
}) => {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { t } = useTranslation('lootboxes')
  const { t: tMain } = useTranslation()
  const [items, setItems] = useState<ItemRead[]>([])

  const nestedItems = value.nested_items.filter(it => it.model_type == 'LootboxNestedItem') as LootboxNestedItem[]

  const ids = nestedItems
    ? value.nested_items
        .map(it => it.id)
        .sort()
        .join(',')
    : ''

  useEffect(() => {
    const loadItems = async () => {
      if (!ids) {
        return
      }

      const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
        state: ResourceState.Active,
        ids: ids,
      })
      setItems(data as ItemRead[])
    }

    loadItems()
  }, [ids])

  const openPicker = useModal<ModalProps>(({ ...rest }) => (
    <SelectSkuModal
      {...rest}
      size="sm"
      title={t('form.add-items')}
      buttonText={t('Add')}
      types={[ItemType.Item, ItemType.Currency, ItemType.Bundle]}
      picked={nestedItems.map(it => items.find(itemRead => itemRead.id == it.id)).filter(it => !!it)}
      onSubmit={items => {
        onChangeItems(
          items.map(
            it =>
              nestedItems.find(v => v.id == it.id) ||
              ({
                id: it.id,
                weight: 100,
                count: 1,
                sku: it.sku,
                model_type: 'LootboxNestedItem',
              } as LootboxNestedItem),
          ),
        )
        rest?.onClose?.()
      }}
    />
  ))

  // const openEditGroup = useModal<ModalProps>(({ ...rest }) => (
  //   <EditNameModal
  //     {...rest}
  //     name={value.name}
  //     onChange={name => {
  //       onChange({
  //         ...value,
  //         name,
  //       })
  //       rest.onClose?.()
  //     }}
  //   />
  // ))

  const onChangeItems = (newitems: LootboxNestedItem[]) => {
    onChange({
      ...value,
      nested_items: newitems,
    })
  }

  const renderNestedItem = (nestedItem: LootboxNestedItem | LootboxGroup) => {
    const item = items.find(it => it.id == nestedItem.id)
    if (!item) {
      return null
    }

    return (
      <TableRow key={nestedItem.id} className="mb-0.5 rounded-md border-border-secondary bg-fg-primary">
        <TableCell width="100%" className="flex select-none justify-between">
          <div className="grow truncate">{item && <SkuImageName item={item} hideQuantity={true} />}</div>
          {item.item_rarity && (
            <Badge
              style={{
                backgroundColor: item.item_rarity.color_background_badge,
              }}
            >
              {item.item_rarity.name}
            </Badge>
          )}
        </TableCell>
        <TableCell width={190}>
          {nestedItem.model_type == 'LootboxNestedItem' && (
            <Input
              type="number"
              value={nestedItem.count}
              onChange={e =>
                onChangeItems(
                  nestedItems.map(it =>
                    it.id == nestedItem.id
                      ? {
                          ...it,
                          count: e.target.value == '' ? ('' as unknown as number) : parseInt(e.target.value),
                        }
                      : it,
                  ),
                )
              }
            />
          )}
        </TableCell>
        <TableCell width={120}>
          <Input
            type="number"
            value={nestedItem.weight}
            onChange={e => {
              const v = (e.target.value == '' ? undefined : parseInt(e.target.value)) as number
              onChangeItems(
                nestedItems.map(it =>
                  it.id == nestedItem.id
                    ? {
                        ...it,
                        weight: v,
                      }
                    : it,
                ),
              )
            }}
          />
        </TableCell>
        <TableCell width={60}>
          <ButtonIcon
            variant="tertiary-destructive"
            onClick={() => onChangeItems(nestedItems.filter(it => it.id !== nestedItem.id))}
          >
            <Trash />
          </ButtonIcon>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <div>
      <Table>
        <TableRow variant="header" className="mb-1 border-none">
          <TableCell width="100%">{t('form.item')}</TableCell>
          <TableCell width={190}>{t('form.item.quantity')}</TableCell>
          <TableCell width={120}>{t('form.weight')}</TableCell>
          <TableCell width={60} />
        </TableRow>
      </Table>
      <div className="rounded-xl bg-fg-secondary p-1.5">
        <Table>
          <TableRow className="border-none">
            <TableCell width="100%">Group {groupIndex}</TableCell>
            <TableCell width={190} />
            <TableCell width={120}>
              <Input
                type="number"
                value={value.weight}
                onChange={e => {
                  const v = (e.target.value == '' ? undefined : parseInt(e.target.value)) as number
                  onChange({ ...value, weight: v })
                }}
              />
            </TableCell>
            <TableCell width={60}>
              {canDelete && (
                <Popper
                  popper={props => (
                    <Menu {...props}>
                      <MenuItem
                        icon={() => <Trash className="text-text-error-primary" />}
                        variant="danger"
                        onClick={() => {
                          props.onClose()
                          onChange(null)
                        }}
                      >
                        {tMain('delete')}
                      </MenuItem>
                    </Menu>
                  )}
                >
                  <Button variant="tertiary" color="secondary">
                    <DotsHorizontal />
                  </Button>
                </Popper>
              )}
            </TableCell>
          </TableRow>
          {nestedItems.map(it => renderNestedItem(it)).filter(it => it)}
          <div className="h-2" />
          <AddButton size="xs" onClick={() => openPicker()}>
            {t('form.add-items')}
          </AddButton>
        </Table>
      </div>
    </div>
  )
}

export const LootboxItemsEditorForm = () => {
  const {
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext<LootboxRead>()
  const { t } = useTranslation('lootboxes')

  const groups = (watch('lootbox_settings.groups') || [
    {
      id: 'default',
      name: 'Default group',
      model_type: 'LootboxGroup',
      nested_items: [],
      weight: 100,
    },
  ]) as LootboxGroup[]

  // const openEditGroup = useModal<ModalProps>(({ ...rest }) => (
  //   <EditNameModal
  //     {...rest}
  //     name={''}
  //     onChange={name => {
  //       clearErrors('lootbox_settings.groups')
  //       setValue(
  //         'lootbox_settings.groups',
  //         [
  //           ...groups,
  //           {
  //             id: uuid4(),
  //             name,
  //             model_type: 'LootboxGroup',
  //             nested_items: [],
  //             weight: 100,
  //           },
  //         ],
  //         { shouldDirty: true },
  //       )
  //       rest.onClose?.()
  //     }}
  //   />
  // ))

  const addNextGroup = () => {
    clearErrors('lootbox_settings.groups')
    setValue(
      'lootbox_settings.groups',
      [
        ...groups,
        {
          id: uuid4(),
          name: 'Group',
          model_type: 'LootboxGroup',
          nested_items: [],
          weight: 100,
        },
      ],
      { shouldDirty: true },
    )
  }

  return (
    <FormSection label={t('item.sku-items')}>
      <div className="flex flex-col gap-3">
        {groups
          .filter(it => it.model_type == 'LootboxGroup')
          .map((group, idx) => {
            return (
              <LootboxGroupEditor
                groupIndex={idx + 1}
                canDelete={groups.length > 1}
                value={group as LootboxGroup}
                onChange={value => {
                  clearErrors('lootbox_settings.groups')
                  if (value) {
                    setValue(
                      'lootbox_settings.groups',
                      groups.map(it => (it.id == group.id ? value : it)),
                      { shouldDirty: true },
                    )
                  } else {
                    setValue(
                      'lootbox_settings.groups',
                      groups.filter(it => it.id !== group.id),
                      { shouldDirty: true },
                    )
                  }
                }}
              />
            )
          })}

        {groups.length > 0 && (
          <AddButton size="xs" onClick={addNextGroup}>
            {t('form.add-group')}
          </AddButton>
        )}

        <ErrorMessage
          name="lootbox_settings.groups"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </div>
    </FormSection>
  )
}
