import { useContext, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { EMPTY_VALUE } from '../expr/types'
import { CampaignContext, ICampaignContext } from '../Context'
import { EmailActionNode, Node, ResourceState } from '../../../api/dashboard'
import { BlockError } from '../types'
import { formatMoney } from '../../../util'
import { useParams } from 'react-router-dom'
import { KeyValue } from '../../../types'
import { TEMPLATE_PROPS, findPrevEntityNode, getMergedPreviewProps, renderTitleBodyProps } from '../util'
import { InputVars } from '../components/InputVars/InputVars'
import { FieldGroup, IFrame, Tab, Tabs } from '@/ui'
import SelectMessageContentType from '../components/SelectMessageContentType/SelectMessageContentType'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'
import { useEmailTemplatesQuery } from '../../../api/useEmailTemplatesQuery'
import { DescContext } from '../blocks/descriptions/types'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../api/useGameItems'
import { useTranslation } from 'react-i18next'
import { useCurrentGame } from '@/api/useCompaniesQuery'

let updateId: ReturnType<typeof setTimeout> | null = null

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export default function EmailNodeEditor(props: {
  node: EmailActionNode
  error?: BlockError | null
  setNode: (node: Node) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { game } = useCurrentGame()
  const context = useContext(CampaignContext) as ICampaignContext
  const [activeEmailName, setActiveEmailName] = useState<string>('')
  const { t } = useTranslation()
  const [html, setHtml] = useState('')
  const { data: templates = [] } = useEmailTemplatesQuery(companyId, gameId)
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })

  let typeItem = EMPTY_VALUE
  if (props.node.item_id) {
    typeItem = 'item'
  } else if (props.node.link_created_offer) {
    typeItem = 'offer'
  }

  const calcHtml = () => {
    let result = templates.find(it => it.name == activeEmailName)?.html || ''

    const c = {
      items: items,
      segments: [],
      stores: [],
      graph: context.graph,
      type: context.campaign.type,
    } as DescContext

    let { merged_props, item, bonus_percent, discount_percent } = getMergedPreviewProps(props.node, c)
    if (!item) {
      return ''
    }

    let html_props = {
      game_logo_url: game?.logo_url || '',
      image_item_url: merged_props['item.image_url'] || item.image_url,
      caption: '',
      price: formatMoney(item.price, item.currency),
      price_after_discount: merged_props['item.price_after_discount']
        ? formatMoney(merged_props['item.price_after_discount'] as number, item.currency)
        : '',
      sale: '',
      body: renderTitleBodyProps(props.node.body, merged_props),
      buy_button: 'Buy',
      buy_button_url: '',
      hub_name: 'Game Hub',
      tagline: '',
      heading: renderTitleBodyProps(props.node.title, merged_props),
      unsubscribe: '',
      bonus: bonus_percent,
      discount_percent: discount_percent,
      item_name: merged_props['item.name'] || item.name,
    } as KeyValue

    result = renderTitleBodyProps(result, html_props)

    setHtml(result)
  }

  useEffect(() => {
    if (updateId) {
      clearTimeout(updateId)
    }

    updateId = setTimeout(calcHtml, 1000)
  }, [props.node, activeEmailName])

  useEffect(() => {
    if (!activeEmailName && templates.length) {
      setActiveEmailName(templates[0].name)
    }
  }, [templates])

  const prevEntityNode = useMemo(
    () => findPrevEntityNode(context.graph.nodes!, props.node.id),
    [context.graph.nodes, props.node.id],
  )

  return (
    <StyledBox>
      <InputVars
        value={props.node.title || ''}
        onChange={e => {
          props.setNode({
            ...props.node,
            title: e.target.value,
          } as Node)
        }}
        items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
        label={t('campaign.block.EmailActionNode.title')}
      />

      <InputVars
        value={props.node.body || ''}
        onChange={e => {
          props.setNode({
            ...props.node,
            body: e.target.value,
          } as Node)
        }}
        items={[...TEMPLATE_PROPS.ITEM, ...TEMPLATE_PROPS.COUPON]}
        label={t('campaign.block.EmailActionNode.body')}
      />

      <SelectMessageContentType node={props.node} setNode={props.setNode} showLinkCreatedOffer={!!prevEntityNode} />

      {typeItem == 'item' && (
        <SelectSkuItem
          onChange={v => {
            props.setNode({
              ...props.node,
              item_id: v as string,
            } as Node)
          }}
          value={props.node.item_id}
        />
      )}

      {html && (
        <div>
          <Tabs>
            {templates.map(it => (
              <Tab key={it.name} isActive={it.name == activeEmailName} onClick={() => setActiveEmailName(it.name)}>
                {t(`campaign.block.EmailActionNode.template.${it.name}`)}
              </Tab>
            ))}
          </Tabs>

          <FieldGroup label={t('campaign.block.EmailActionNode.preview')}>
            <div className="mt-3 flex w-full items-center justify-center">
              <IFrame html={html} className="w-[600px] overflow-y-auto" />
            </div>
          </FieldGroup>
        </div>
      )}
    </StyledBox>
  )
}
