import { getStringInitials } from '@/libs'
import { Skeleton } from '@mui/material'
import { CompanyGame } from '@/api/dashboard'
import { cn } from '@dashboard/ui'

export const ImageCompany = (props: { company: CompanyGame | undefined; className?: string }) => {
  const company = props.company

  if (!company?.logo_url) {
    return (
      <div
        className={cn(
          'flex size-[38px] shrink-0 items-center justify-center rounded bg-fg-brand-tertiary-hover bg-linear-company-gradient font-semibold text-fg-primary-hover shadow-inner-img',
          props.className,
        )}
      >
        {company ? getStringInitials(company.name) : <Skeleton width="50%" />}
      </div>
    )
  }

  return (
    <div
      className={cn(
        'flex size-[38px] shrink-0 items-center justify-center rounded bg-cover bg-center bg-no-repeat',
        props.className,
      )}
      style={{
        backgroundImage: `url(${company.logo_url})`,
      }}
    />
  )
}
