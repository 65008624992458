import { ReactNode } from 'react'

interface PriceTemplateRowDividerProps {
  children: ReactNode
}

export const PriceTemplateRowDivider = ({ children }: PriceTemplateRowDividerProps) => {
  return (
    <div className="border-y border-border-secondary bg-[#F6F8FA] px-3 py-2 text-caption-sm text-text-secondary">
      {children}
    </div>
  )
}
