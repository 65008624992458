import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ItemBulkUpdate, ItemType, LootboxBulkUpdate } from '@/api/dashboard'
import { dashboardClient } from '@/api'

interface ItemBulkUpdateMutate {
  data: ItemBulkUpdate[] | LootboxBulkUpdate[]
}

/**
 *
 * @param companyId
 * @param gameId
 * @param itemType
 */
export const useItemBulkUpdateMutate = (companyId: string, gameId: string, itemType: ItemType) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: ItemBulkUpdateMutate) => {
      if (itemType === ItemType.Lootbox) {
        return dashboardClient.v1
          .bulkUpdateLootboxes(companyId, gameId, data as LootboxBulkUpdate[])
          .then(() => ({ data: [] }))
      } else {
        return dashboardClient.v1.bulkUpdateItems(companyId, gameId, data).then(() => ({ data: [] }))
      }
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false })
      await queryClient.refetchQueries({ queryKey: [gameId, 'infinity-items'], exact: false })
    },
  })
}
