import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Button, Checkbox, CheckboxLabel, ChevronDown, Menu, MenuItem, Popper, cn } from '@dashboard/ui'
import { useQuery } from '@tanstack/react-query'
import {
  PriceTemplateApplyModal,
  SKU_PRICE_TEMPLATES_PATH,
  getPriceTemplatesInfinityOptions,
} from '@/layouts/sku-price-templates'
import { ItemRead, PriceTemplateShort } from '@/api/dashboard'
import { useFeatureFlags, useGenerateCompanyPath } from '@/libs'
import { useModal } from '@/ui'
import { formatMoney } from '@/util'

interface PriceInputProps {
  item: ItemRead
  value: string | undefined
  onApplyPrice: (price: number) => void
  onChange: (priceTemplateId: PriceTemplateShort | null) => void
}

export const PriceInput = ({ item, value, onApplyPrice, onChange }: PriceInputProps) => {
  const { t } = useTranslation()
  const { companyId, gameId, generatePath } = useGenerateCompanyPath()
  const {
    featureFlags: { price_templates_enabled },
  } = useFeatureFlags()

  const { data: priceTemplates = [], isLoading: isPriceTemplatesLoading } = useQuery({
    queryKey: [...getPriceTemplatesInfinityOptions(companyId, gameId).queryKey, 'input-price'],
    queryFn: getPriceTemplatesInfinityOptions(companyId, gameId).queryFn,
    select: data => data.data,
  })

  const { register, watch } = useForm<{ type: 'price' | 'price-template' }>({
    defaultValues: {
      type: item.price_template_id ? 'price-template' : 'price',
    },
  })

  const [type] = watch(['type'])
  const prevType = useRef(type)

  useEffect(() => {
    if (prevType.current !== type) {
      onChange(null)

      prevType.current = type
    }
  }, [type, onChange])

  const priceTemplate = priceTemplates.find(template => template.id === value)

  const open = useModal(
    props => (
      <PriceTemplateApplyModal
        {...props}
        item={item}
        back={{
          to: '',
          onClick: e => {
            e?.preventDefault()
            props.onClose?.()
          },
        }}
        onSubmit={async price => {
          onApplyPrice(price)
          props.onClose?.()
        }}
      />
    ),
    {
      mode: 'rush',
    },
  )

  const onChangePriceTemplate = (priceTemplate: PriceTemplateShort) => {
    onChange(priceTemplate)
  }

  return (
    <div className={cn('flex flex-col', { 'gap-6': !!price_templates_enabled })}>
      <div className={cn('flex flex-col gap-4', { hidden: !price_templates_enabled })}>
        <CheckboxLabel
          checkbox={<Checkbox {...register('type')} type="radio" id="price-type-price" value="price" />}
          htmlFor="price-type-price"
          caption={t('game-item-page.price.type.price.desc')}
        >
          {t('game-item-page.price.type.price')}
        </CheckboxLabel>

        <CheckboxLabel
          checkbox={
            <Checkbox
              {...register('type')}
              type="radio"
              id="price-type-template"
              value="price-template"
              disabled={priceTemplates.length === 0}
            />
          }
          htmlFor="price-type-template"
          caption={t('game-item-page.price.type.price-template.desc')}
        >
          {t('game-item-page.price.type.price-template')}
        </CheckboxLabel>
      </div>

      <div className="-mx-6 h-px bg-border-secondary" />

      <div className={cn('flex items-center rounded-md bg-fg-secondary p-1.5 transition-colors')}>
        {type === 'price' && (
          <>
            <div className="flex items-center gap-1 px-4 text-paragraph-sm font-medium text-text-tertiary">
              USD <span className="tabular-nums">{formatMoney(item.price, item.currency)}</span>
            </div>
            <Button variant="outline" color="secondary" size="md" onClick={() => open()}>
              {t('game-item-page.price.type.price.button')}
            </Button>
          </>
        )}

        {type === 'price-template' && (
          <>
            {priceTemplate && (
              <div className="flex items-center gap-1 px-4 text-paragraph-sm font-medium text-text-tertiary">
                USD <span className="tabular-nums">{formatMoney(priceTemplate.price, item.currency)}</span>
              </div>
            )}

            <Popper
              popper={props => (
                <Menu
                  {...props}
                  footer={
                    <Link to={generatePath(SKU_PRICE_TEMPLATES_PATH)} target="_blank">
                      <Button className="w-full" variant="outline">
                        {t('game-item-page.price.type.price-template.button')}
                      </Button>
                    </Link>
                  }
                >
                  <div className="mb-3 text-caption-md font-semibold text-text-primary">
                    {t('game-item-page.price.type.price-template.choose')}
                  </div>
                  {priceTemplates.map(template => (
                    <MenuItem
                      onClick={() => {
                        onChangePriceTemplate(template)
                        props.onClose()
                      }}
                    >
                      USD <span className="tabular-nums">{formatMoney(template.price, 'USD')}</span> – {template.name}
                    </MenuItem>
                  ))}
                </Menu>
              )}
              focusManager={{
                initialFocus: priceTemplates.findIndex(template => template.id === item.price_template_id),
              }}
              params={{
                width: 'auto',
              }}
            >
              <Button className="group" variant="outline" size="md" loading={isPriceTemplatesLoading}>
                {priceTemplate ? (
                  <span>{t('game-item-page.price.type.price-template.selected', { name: priceTemplate?.name })}</span>
                ) : (
                  <span>{t('game-item-page.price.type.price-template.use')}</span>
                )}
                <ChevronDown className="size-4 transition-transform group-data-[open='true']:rotate-180" />
              </Button>
            </Popper>
          </>
        )}
      </div>
    </div>
  )
}
