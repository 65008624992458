import { useTranslation } from 'react-i18next'

import { Badge, TableCell, TableRow } from '@/ui'

import { REWARD_POINTS_EDIT_PATH } from 'src/layouts/cashback-rewards'

import { useGenerateCompanyPath } from '@/libs'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useParams } from 'react-router-dom'
import { RewardPointOutline } from '@dashboard/ui'

export const RewardPointsRow = () => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { t } = useTranslation('sku-cashback')
  const { generatePath } = useGenerateCompanyPath()

  const isActive = settings?.enable_reward_points

  return (
    <TableRow to={generatePath(REWARD_POINTS_EDIT_PATH)}>
      <TableCell width="100%" className="flex items-center gap-3">
        <div className="rounded-md border border-border-primary bg-fg-primary-alt p-1.5">
          <RewardPointOutline className="text-text-tertiary" />
        </div>
        <div className="text-caption-md font-medium text-text-secondary">{t('table.row.reward-points')}</div>
      </TableCell>
      <TableCell width="60%" className="text-text-disabled">
        {t('table.row.no-linked-item')}
      </TableCell>
      <TableCell width="60%" className={!isActive ? 'text-text-disabled' : ''}>
        {isActive ? t('form.rp.desc2') : t('table.row.no-exchange-rate')}
      </TableCell>
      <TableCell width="50%" className="text-text-disabled">
        {t('table.row.system')}
      </TableCell>
      <TableCell width="25%">
        <Badge variant={isActive ? 'lime-primary' : 'gray-secondary'} className="whitespace-nowrap">
          {isActive ? t('table.row.static-status') : t('table.row.static-status.not-active')}
        </Badge>
      </TableCell>
      <TableCell width={52} />
    </TableRow>
  )
}
