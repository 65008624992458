import { LootboxRead, LootboxSettings } from '@/api/dashboard'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FormSection } from '@/layouts/game-items'

import { Input, cn, formatHookFormErrors } from '@dashboard/ui'
import { ToggleTabsButton } from '@dashboard/ui/src/components/ToggleTabs/ToggleTabsButton'
import { AnimatePresence } from 'framer-motion'
import { FieldGroup } from '@/ui'
import { URL_PATTERN } from '@/components/lexical/libs'

type EditTab = LootboxSettings['claim_video_url'] | LootboxSettings['claimed_video_url']

export const LootboxStateFields = () => {
  const { t } = useTranslation('lootboxes')
  const { t: tMain } = useTranslation()

  const [activeTab, setActiveTab] = useState<EditTab>('claim_video_url')
  const tabs: EditTab[] = ['claim_video_url', 'claimed_video_url']

  const {
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext<LootboxRead>()

  const videoUrl = watch(`lootbox_settings.${activeTab}`)

  useEffect(() => {
    if (!errors.lootbox_settings) {
      return
    }

    const videoErrors = Object.keys(errors.lootbox_settings)
    if (!videoErrors.length) {
      return
    }

    const firstError = videoErrors[0]
    if (tabs.includes(firstError)) {
      setActiveTab(firstError)
    }
  }, [isSubmitting, errors.lootbox_settings])

  return (
    <FormSection label={t('item.animation')}>
      <FieldGroup label={t('item.animation.states')}>
        <div className={cn('flex w-full  items-center gap-0.5 rounded-lg bg-fg-secondary p-0.5')}>
          <AnimatePresence>
            {tabs.map(tab => (
              <ToggleTabsButton key={tab} selected={activeTab == tab} onClick={() => setActiveTab(tab)}>
                {t(`item.${tab}`)}
              </ToggleTabsButton>
            ))}
          </AnimatePresence>
        </div>

        {videoUrl?.endsWith('.mov') ? (
          <video className="mt-3 h-[428px] w-full rounded-md" controls src={videoUrl.replace('.mov', '.webm')} />
        ) : (
          <video className="mt-3 h-[428px] w-full rounded-md" controls src={videoUrl || ''} />
        )}

        <Input
          className={cn('mt-3', activeTab != 'claim_video_url' && 'hidden')}
          {...register(`lootbox_settings.claim_video_url`, {
            required: tMain('validation.required'),
            validate: value => (URL_PATTERN.test(value) ? true : tMain('validation.invalid_url')),
          })}
          {...(activeTab == 'claim_video_url' && formatHookFormErrors(errors, `lootbox_settings.claim_video_url`))}
          placeholder={t('item.video_url_place_holder')}
        />

        <Input
          className={cn('mt-3', activeTab != 'claimed_video_url' && 'hidden')}
          {...register(`lootbox_settings.claimed_video_url`, {
            required: false,
            validate: value => (!value || URL_PATTERN.test(value) ? true : tMain('validation.invalid_url')),
          })}
          {...(activeTab == 'claimed_video_url' && formatHookFormErrors(errors, `lootbox_settings.claimed_video_url`))}
          placeholder={t('item.video_url_place_holder')}
        />
      </FieldGroup>
    </FormSection>
  )
}
