export interface CheckParams {
  initial: number
  previous?: number
  current: number
}

export enum CheckLevel {
  Info = 1,
  Warn = 2,
}

export interface CheckResult {
  level: CheckLevel
  name: string
  target?: number
  value: boolean
  deviation: number
}

export type CheckFn = (params: CheckParams) => CheckResult | null
