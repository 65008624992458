import {
  HTMLAttributes,
  MouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { cva } from 'class-variance-authority';
import { motion, useInView } from 'framer-motion';
import { useMergeRefs } from '@floating-ui/react';
import { AghanimIconOrCustom } from '../../icons';
import { cn, getMotionProps, isAghanimIcon } from '../../libs';
import { renderItemIcon } from './renderItemIcon';

export interface MenuItemBaseProps {
  /**
   * Content of the menu item.
   */
  children: ReactNode;
  /**
   * Variant of the menu item.
   */
  variant?: 'primary' | 'danger';
  /**
   * Render the menu item only in view.
   */
  optimized?: boolean;
  /**
   * Icon to display on the left side of the menu item.
   */
  icon?: AghanimIconOrCustom;
  /**
   * Icon to display on the right side of the menu item.
   */
  extra?: AghanimIconOrCustom;
  /**
   * Caption to display below the content of the menu item.
   */
  caption?: ReactNode;
  /**
   * Menu item is disabled.
   */
  disabled?: boolean;
}

/**
 * Don't use this type in your components, use `MenuItemBaseProps` instead.
 */
export type MenuItemProps = HTMLAttributes<HTMLLIElement> & MenuItemBaseProps;

const menuItemVariants = cva(
  [
    'mb-0.5 min-h-[30px] w-full cursor-pointer select-none rounded-md bg-[--bg-color] p-1.5 transition-colors',
    'focus-visible:ring-2',
    'data-[disabled=true]:text-text-disabled',
    'data-[disabled=true]:pointer-events-none',
    'data-[disabled=true]:opacity-50',
    'last:mb-0',
  ],
  {
    variants: {
      variant: {
        primary: [
          '[--bg-color:theme(colors.fg-primary)]',
          '[--label-color:theme(colors.text-secondary)]',
          '[--caption-color:theme(colors.text-tertiary)]',
          '[--extra-color:theme(colors.text-quarterary)]',

          'hover:[--bg-color:theme(colors.fg-primary-hover)]',
          'hover:[--label-color:theme(colors.text-secondary-hover)]',
          'hover:[--caption-color:theme(colors.text-tertiary-hover)]',
          'hover:[--extra-color:theme(colors.text-quarterary-hover)]',

          'focus:[--bg-color:theme(colors.fg-primary-hover)]',
          'focus:[--label-color:theme(colors.text-secondary-hover)]',
          'focus:[--caption-color:theme(colors.text-tertiary-hover)]',
          'focus:[--extra-color:theme(colors.text-quarterary-hover)]',

          'focus-visible:[--bg-color:theme(colors.fg-primary-hover)]',
          'focus-visible:[--label-color:theme(colors.text-secondary-hover)]',
          'focus-visible:[--caption-color:theme(colors.text-tertiary-hover)]',
          'focus-visible:[--extra-color:theme(colors.text-quarterary-hover)]',
          'focus-visible:ring-border-brand',

          'active:[--bg-color:theme(colors.fg-primary-alt)]',
          'active:[--label-color:theme(colors.text-primary)]',
          'active:[--caption-color:theme(colors.text-tertiary-hover)]',
          'active:[--extra-color:theme(colors.text-quarterary-hover)]',

          'data-[open=true]:[&:not(:hover)]:[--bg-color:theme(colors.fg-primary-alt)]',
          'data-[open=true]:[&:not(:hover)]:[--label-color:theme(colors.text-primary)]',
          'data-[open=true]:[&:not(:hover)]:[--caption-color:theme(colors.text-tertiary-hover)]',
          'data-[open=true]:[&:not(:hover)]:[--extra-color:theme(colors.text-quarterary-hover)]',
        ],
        danger: [
          '[--bg-color:theme(colors.fg-primary)]',
          '[--label-color:theme(colors.text-error-primary)]',
          '[--caption-color:theme(colors.text-error-primary)]',
          '[--extra-color:theme(colors.text-error-primary)]',

          'hover:[--bg-color:theme(colors.fg-error-tertiary-alt)]',

          'focus:[--bg-color:theme(colors.fg-error-tertiary-alt)]',
          'focus-visible:[--bg-color:theme(colors.fg-error-tertiary-alt)]',
          'focus-visible:ring-border-error',

          'active:[--bg-color:theme(colors.fg-error-tertiary-hover)]',
          'active:[--label-color:theme(colors.text-error-solid)]',

          'data-[open=true]:[&:not(:hover)]:[--bg-color:theme(colors.fg-error-tertiary-hover)]',
          'data-[open=true]:[&:not(:hover)]:[--label-color:theme(colors.text-error-solid)]',
        ],
      },
      defaultVariants: {
        variant: 'primary',
      },
    },
  },
);

const innerMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
});

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  (
    { children, variant = 'primary', optimized = false, icon, extra, caption, disabled = false, onClick, ...rest },
    ref,
  ) => {
    const liRef = useRef<HTMLLIElement>(null);
    const referenceMergedRef = useMergeRefs([liRef, ref]);

    const isInView = useInView(liRef, { once: !optimized });
    const computedIsInView = useMemo(() => (optimized ? isInView : true), [isInView, optimized]);

    const onClickOrPressEnter = useCallback(
      (e: MouseEvent<HTMLLIElement> | ReactKeyboardEvent<HTMLLIElement>) => {
        const keyEvent = e as ReactKeyboardEvent<HTMLLIElement>;
        if (!keyEvent.key || keyEvent.key === 'Enter') {
          onClick?.(e as MouseEvent<HTMLLIElement>);
          e.target.dispatchEvent(new Event('mousemove'));
          return;
        }
      },
      [onClick],
    );

    return (
      <li
        {...rest}
        ref={referenceMergedRef}
        className={cn(menuItemVariants({ variant }), rest.className)}
        role={!disabled ? 'option' : ''}
        data-disabled={disabled}
        tabIndex={0}
        onClick={onClickOrPressEnter}
        onKeyDown={onClickOrPressEnter}
      >
        {computedIsInView && (
          <motion.div {...innerMotionProps} className={cn('flex gap-2', caption ? 'items-start' : 'items-center')}>
            {icon &&
              (isAghanimIcon(icon as unknown as ReactNode) ? (
                <div className="flex h-[18px] min-w-[18px] shrink-0 items-center justify-center text-[--label-color]">
                  {renderItemIcon(icon, 18)}
                </div>
              ) : (
                renderItemIcon(icon, 18)
              ))}

            <div className="w-full">
              <div className="flex w-full items-start gap-1.5">
                <div
                  className="w-full break-words text-caption-md leading-[18px] text-[--label-color]"
                  style={{ wordBreak: 'break-word' }}
                >
                  {children}
                </div>
                {extra && (
                  <div className="ml-auto flex h-[18px] min-w-[18px] shrink-0 items-center justify-center text-caption-md leading-[18px] text-[--extra-color] transition-colors">
                    {renderItemIcon(extra, 18)}
                  </div>
                )}
              </div>
              {caption && (
                <div
                  className="mt-0.5 break-words text-paragraph-xs text-[--caption-color]"
                  style={{ wordBreak: 'break-word' }}
                >
                  {caption}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </li>
    );
  },
);
