import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const RefreshIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        d="M15.2097 4.38656C15.311 4.00869 15.0867 3.62028 14.7088 3.51903C14.331 3.41778 13.9426 3.64203 13.8413 4.0199L13.7643 4.30715C11.7251 1.74018 8.05992 0.983881 5.13537 2.67238C3.19772 3.79108 2.02735 5.72849 1.80764 7.79261C1.76624 8.18162 2.04802 8.53053 2.43702 8.57194C2.82603 8.61335 3.17494 8.33156 3.21635 7.94256C3.3902 6.30933 4.31478 4.78197 5.8437 3.89924C8.07918 2.60859 10.8628 3.12729 12.4987 5.00097L12.2551 4.9357C11.8772 4.83445 11.4888 5.05869 11.3876 5.43657C11.2863 5.81444 11.5106 6.20284 11.8884 6.30409L13.8237 6.82263C14.2015 6.92388 14.5899 6.69963 14.6912 6.32176L15.2097 4.38656Z"
        fill="currentColor"
      />
      <path
        d="M15.1922 9.20741C15.2336 8.81841 14.9519 8.46949 14.5628 8.42808C14.1738 8.38668 13.8249 8.66846 13.7835 9.05747C13.6097 10.6907 12.6851 12.2181 11.1562 13.1008C8.92069 14.3914 6.13707 13.8727 4.50113 11.999L4.74479 12.0643C5.12266 12.1656 5.51106 11.9413 5.61232 11.5634C5.71357 11.1856 5.48932 10.7972 5.11145 10.6959L3.17625 10.1774C2.79837 10.0761 2.40997 10.3004 2.30872 10.6782L1.79018 12.6135C1.68893 12.9913 1.91318 13.3797 2.29105 13.481C2.66892 13.5822 3.05733 13.358 3.15858 12.9801L3.23554 12.6929C5.27479 15.2598 8.93996 16.0161 11.8645 14.3276C13.8022 13.2089 14.9725 11.2715 15.1922 9.20741Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

RefreshIcon.type = 'aghanim-icon';
