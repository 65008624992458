import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Filter: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 17" ref={ref}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4 8.5H12M2 4.5H14M6 12.5H10"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconBase>
  );
});

Filter.type = 'aghanim-icon';
