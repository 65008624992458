import { Campaign } from '@/api/dashboard'
import { Link } from 'react-router-dom'
import { Badge, TableRowEditButton, Toggle } from '@/ui'
import { isCustomEvent } from '../../Settings'
import TimeLimitInfo from './TimeLimitInfo'
import getCampaignName from './getCampaignName'
import { getCampaignIconUrl } from '../../util'
import { Trash } from '@/icons'
import { ByAghanim } from '@/layouts/campaigns/pages/CampaignTable/ByAghanim'
import { Archive, Copy03, Unarchive } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

interface CampaignTableRowProps {
  campaign: Campaign
  to: string
  onDeleteClick?: () => void | undefined
  onClickToggle?: () => void
  onCopyClick: () => Promise<void>
  onArchiveChange: (value: 'archive' | 'unarchive') => void
  canEdit: boolean
  loading: boolean
}

export const CampaignRow = ({
  campaign,
  to,
  onClickToggle,
  onDeleteClick,
  onCopyClick,
  canEdit,
  loading,
  onArchiveChange,
}: CampaignTableRowProps) => {
  const isCustom = isCustomEvent(campaign.type)
  const { t } = useTranslation()
  const renderArchivedAt = () => {
    if (!campaign.archived_at) {
      return
    }

    return (
      <div className="flex items-center whitespace-nowrap rounded-md bg-fg-gray-secondary text-caption-sm text-text-quarterary-hover">
        <Badge variant="gray-primary">{t('archived')}</Badge>
        <div className="ml-1 flex gap-1.5 pr-1 text-caption-sm">
          {t('intl.DateTime', {
            val: new Date(campaign.archived_at * 1000),
            formatParams: { val: { dateStyle: 'short', timeStyle: 'medium' } },
          })}
        </div>
      </div>
    )
  }

  const renderStatus = () => {
    if (campaign.archived_at) {
      return t('archived')
    }

    return t(campaign.enabled ? 'campaigns.active' : 'campaign.enabled.draft')
  }

  return (
    <Link
      to={to}
      key={campaign.id}
      className="box-border flex items-center border-b border-border-secondary p-3 hover:bg-fg-primary-hover"
    >
      <div className="flex gap-3">
        <img
          className="size-[60px] rounded-md border border-border-primary"
          src={getCampaignIconUrl(campaign.type)}
          alt={campaign.type}
        />
        <div className="flex flex-col gap-1">
          <div className="flex gap-3">
            <div className="text-caption-md font-semibold  text-text-secondary">{getCampaignName(campaign.type)}</div>
            {isCustom && <Badge variant="gray-secondary">{t('campaign.type.desc.custom_event_title')}</Badge>}
            {renderArchivedAt()}
          </div>
          <div className="text-caption-sm text-text-tertiary">
            {campaign.description
              ? campaign.description
              : isCustom
                ? campaign.name
                : t(`campaign.type.desc.${campaign.type}`)}
          </div>

          {!isCustom && <ByAghanim />}
        </div>
      </div>
      <div className="ml-auto flex items-center gap-9" onClick={e => e.stopPropagation()}>
        {campaign.time_limit && (campaign.end_at || campaign.start_at) && <TimeLimitInfo campaign={campaign} />}
        {!campaign.archived_at && (
          <div className="mt-4">
            <Toggle
              sizev="md"
              checked={campaign.enabled}
              onChange={onClickToggle}
              disabled={!canEdit || !!campaign.archived_at}
              loading={loading}
            />
            <div className="text-center text-caption-xs font-semibold uppercase text-text-tertiary">
              {renderStatus()}
            </div>
          </div>
        )}
        {canEdit && onDeleteClick && (
          <TableRowEditButton
            onChange={value => {
              switch (value) {
                case 'copy':
                  onCopyClick()
                  break
                case 'archive':
                case 'unarchive':
                  onArchiveChange(value)
                  break
                case 'remove':
                  onDeleteClick()
                  break
              }
            }}
            options={[
              {
                icon: <Copy03 />,
                children: <span> {t('copy')} </span>,
                value: 'copy',
              },
              campaign.archived_at && {
                icon: <Unarchive />,
                children: t('unarchive'),
                value: 'unarchive',
              },
              !campaign.archived_at && {
                icon: <Archive />,
                children: t('archive'),
                value: 'archive',
              },
              campaign.archived_at && {
                icon: <Trash className="text-text-error-primary" />,
                children: <span className="text-text-error-primary"> {t('grid.remove')} </span>,
                value: 'remove',
              },
            ].filter(it => !!it)}
          />
        )}
      </div>
    </Link>
  )
}
