import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const CalendarOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C8.55228 2 9 2.44772 9 3V4H15V3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3V4C19.0398 4 20.723 5.52684 20.9691 7.5H21V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5H3.03095C3.277 5.52684 4.96019 4 7 4V3C7 2.44772 7.44772 2 8 2ZM15 6V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6C18.1046 6 19 6.89543 19 8V9C19 9.55228 18.5523 10 18 10H6C5.44772 10 5 9.55228 5 9V8C5 6.89543 5.89543 6 7 6V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V6H15ZM19 11.8293C18.6872 11.9398 18.3506 12 18 12H6C5.64936 12 5.31278 11.9398 5 11.8293V18C5 19.1046 5.89543 20 7 20H17C18.1046 20 19 19.1046 19 18V11.8293Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

CalendarOutline.type = 'aghanim-icon';
