import { StoreItemRead } from '@/api/dashboard'

import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { getItemName } from '@/api/getItemName'
import { useTranslation } from 'react-i18next'
import { PriceView } from '@/layouts/game-items/components/PriceView'
import { Select, SelectItem, SelectProps } from '@dashboard/ui'

interface SelectSkuProps extends Omit<SelectProps, 'items'> {
  onChange: (value: SelectItem['value']) => void
  caption?: string
  storeItems: StoreItemRead[]
}

export const SelectStoreItem = (props: SelectSkuProps) => {
  const { t } = useTranslation()

  let options = props.storeItems.map(it => {
    let price = it.is_free_item ? t('coupon.type.free_item') : <PriceView item={it.item} />

    return {
      value: it.item.id as string,
      icon: () => (
        <div
          className="size-[28px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${it.item.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
      ),
      caption: (
        <div className="w-full truncate text-xs	font-medium leading-none text-text-quarterary-hover">{it.item.sku}</div>
      ),
      extraRight: price as unknown as string,
      children: getItemName(it.item),
    }
  })

  return (
    <div data-testid="select-sku">
      <Select {...props} items={options} placeholder={t('select-skus')} />
    </div>
  )
}
