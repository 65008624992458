import { CSSProperties } from 'react'
import { EmailIcon } from '../../icons/Icons'
import { EventActionNodeType } from './types'
import { LogicIcon } from './icons/LogicIcon'

function TimerIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0007 12.6641V17.9974L12.6673 19.9974M16.0007 6.66406C22.2599 6.66406 27.334 11.7382 27.334 17.9974C27.334 24.2566 22.2599 29.3307 16.0007 29.3307C9.74142 29.3307 4.66732 24.2566 4.66732 17.9974C4.66732 11.7382 9.74142 6.66406 16.0007 6.66406ZM16.0007 6.66406V2.66406M18.6673 2.66406H13.334M4.89535 7.45345L6.89535 5.45345L5.89535 6.45345M27.106 7.45345L25.106 5.45345L26.106 6.45345"
        stroke="#9B60D4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function OctagonIcon(props: { style?: CSSProperties; shadowColor?: string }) {
  return (
    <svg width="69" height="68" viewBox="0 0 69 68" fill="none" style={props.style}>
      <path
        d="M23.6642 9.17062C23.9294 9.17062 24.1838 9.06526 24.3713 8.87772L30.7512 2.49778C32.7483 0.500742 35.9861 0.50074 37.9831 2.49777L44.3631 8.87772C44.5506 9.06526 44.805 9.17062 45.0702 9.17062H54.086C56.9102 9.17062 59.1997 11.4601 59.1997 14.2843V23.3001C59.1997 23.5653 59.3051 23.8197 59.4926 24.0072L65.8694 30.384C67.8664 32.3811 67.8664 35.6189 65.8694 37.616L59.4926 43.9928C59.3051 44.1803 59.1997 44.4347 59.1997 44.6999V53.7215C59.1997 56.5457 56.9102 58.8352 54.086 58.8352H45.0644C44.7991 58.8352 44.5448 58.9406 44.3573 59.1281L37.9831 65.5022C35.9861 67.4993 32.7483 67.4993 30.7512 65.5022L24.3771 59.1281C24.1896 58.9406 23.9352 58.8352 23.67 58.8352H14.6488C11.8246 58.8352 9.53509 56.5457 9.53509 53.7215V44.7003C9.53509 44.4351 9.42973 44.1807 9.2422 43.9932L2.86497 37.616C0.867929 35.6189 0.867928 32.3811 2.86496 30.384L9.2422 24.0068C9.42973 23.8193 9.53509 23.5649 9.53509 23.2997V14.2843C9.53509 11.4601 11.8246 9.17062 14.6488 9.17062H23.6642Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
        fill={'#fff'}
        filter={props.shadowColor ? `drop-shadow(1px 1px 2px ${props.shadowColor})` : ''}
      />
    </svg>
  )
}

function MobileIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" style={props.style}>
      <path
        d="M20.4205 3.08594V4.9525C20.4205 5.69919 20.4205 6.07254 20.2752 6.35774C20.1473 6.60861 19.9434 6.81257 19.6925 6.94039C19.4073 7.08571 19.0339 7.08571 18.2873 7.08571H14.5541C13.8074 7.08571 13.4341 7.08571 13.1489 6.94039C12.898 6.81257 12.6941 6.60861 12.5662 6.35774C12.4209 6.07254 12.4209 5.69919 12.4209 4.9525V3.08594M11.3543 29.7511H21.4871C22.9805 29.7511 23.7272 29.7511 24.2976 29.4605C24.7993 29.2048 25.2072 28.7969 25.4629 28.2952C25.7535 27.7248 25.7535 26.9781 25.7535 25.4847V7.35236C25.7535 5.85897 25.7535 5.11228 25.4629 4.54188C25.2072 4.04014 24.7993 3.63222 24.2976 3.37657C23.7272 3.08594 22.9805 3.08594 21.4871 3.08594H11.3543C9.86093 3.08594 9.11423 3.08594 8.54383 3.37657C8.0421 3.63222 7.63417 4.04014 7.37852 4.54188C7.08789 5.11228 7.08789 5.85897 7.08789 7.35236V25.4847C7.08789 26.9781 7.08789 27.7248 7.37852 28.2952C7.63417 28.7969 8.0421 29.2048 8.54383 29.4605C9.11423 29.7511 9.86093 29.7511 11.3543 29.7511Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function DBIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M17.3337 6.66667C17.3337 8.13943 14.0504 9.33333 10.0003 9.33333C5.95024 9.33333 2.66699 8.13943 2.66699 6.66667M17.3337 6.66667C17.3337 5.19391 14.0504 4 10.0003 4C5.95024 4 2.66699 5.19391 2.66699 6.66667M17.3337 6.66667V8.66667M2.66699 6.66667V22.6667C2.66699 24.1394 5.95024 25.3333 10.0003 25.3333M10.0003 14.6667C9.7756 14.6667 9.55323 14.663 9.33366 14.6558C5.59599 14.5333 2.66699 13.391 2.66699 12M10.0003 20C5.95024 20 2.66699 18.8061 2.66699 17.3333M29.3337 15.3333C29.3337 16.8061 26.0504 18 22.0003 18C17.9502 18 14.667 16.8061 14.667 15.3333M29.3337 15.3333C29.3337 13.8606 26.0504 12.6667 22.0003 12.6667C17.9502 12.6667 14.667 13.8606 14.667 15.3333M29.3337 15.3333V25.3333C29.3337 26.8061 26.0504 28 22.0003 28C17.9502 28 14.667 26.8061 14.667 25.3333V15.3333M29.3337 20.3333C29.3337 21.8061 26.0504 23 22.0003 23C17.9502 23 14.667 21.8061 14.667 20.3333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function SendMobileAppPushIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" style={props.style}>
      <path
        d="M6.66602 7.77424C6.66602 6.28085 6.66602 5.53415 6.95665 4.96376C7.2123 4.46202 7.62022 4.05409 8.12196 3.79845C8.69236 3.50781 9.43905 3.50781 10.9324 3.50781H21.0652C22.5586 3.50781 23.3053 3.50781 23.8757 3.79845C24.3774 4.05409 24.7853 4.46202 25.041 4.96376C25.3316 5.53415 25.3316 6.28085 25.3316 7.77424V25.9065C25.3316 27.3999 25.3316 28.1466 25.041 28.717C24.7853 29.2188 24.3774 29.6267 23.8757 29.8823C23.3053 30.173 22.5586 30.173 21.0652 30.173H10.9324C9.43905 30.173 8.69236 30.173 8.12196 29.8823C7.62022 29.6267 7.2123 29.2188 6.95665 28.717C6.66602 28.1466 6.66602 27.3999 6.66602 25.9065V7.77424Z"
        stroke="#FF462D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6699 10.2086C22.6699 10.7686 22.6699 11.0487 22.5609 11.2626C22.465 11.4507 22.3121 11.6037 22.1239 11.6996C21.91 11.8086 21.63 11.8086 21.0699 11.8086L10.9309 11.8086C10.3708 11.8086 10.0908 11.8086 9.87687 11.6996C9.68871 11.6037 9.53573 11.4507 9.43986 11.2626C9.33086 11.0487 9.33086 10.7686 9.33086 10.2086L9.33086 7.91188C9.33086 7.35183 9.33086 7.0718 9.43986 6.85789C9.53573 6.66973 9.68871 6.51675 9.87687 6.42088C10.0908 6.31188 10.3708 6.31188 10.9309 6.31188L21.0699 6.31188C21.6299 6.31188 21.91 6.31188 22.1239 6.42088C22.312 6.51675 22.465 6.66973 22.5609 6.85789C22.6699 7.0718 22.6699 7.35182 22.6699 7.91187L22.6699 10.2086Z"
        fill="currentColor"
      />
    </svg>
  )
}

function SendGameHubPushIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M22.9327 28C25.1729 28 26.293 28 27.1486 27.564C27.9013 27.1805 28.5132 26.5686 28.8967 25.816C29.3327 24.9603 29.3327 23.8402 29.3327 21.6V10.4C29.3327 8.15979 29.3327 7.03969 28.8967 6.18404C28.5132 5.43139 27.9013 4.81947 27.1486 4.43598C26.293 4 25.1729 4 22.9327 4L9.06602 4C6.82581 4 5.7057 4 4.85005 4.43597C4.09741 4.81947 3.48548 5.43139 3.10199 6.18404C2.66602 7.03968 2.66602 8.15979 2.66602 10.4L2.66602 21.6C2.66602 23.8402 2.66602 24.9603 3.10199 25.816C3.48548 26.5686 4.0974 27.1805 4.85005 27.564C5.7057 28 6.8258 28 9.06601 28H22.9327Z"
        stroke="#FF462D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.73633 11.2695H2.66602"
        stroke="#FF462D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2461 15.7405C26.2461 16.3005 26.2461 16.5805 26.1371 16.7944C26.0412 16.9826 25.8882 17.1356 25.7001 17.2315C25.4862 17.3405 25.2061 17.3405 24.6461 17.3405L12.3871 17.3405C11.8271 17.3405 11.547 17.3405 11.3331 17.2315C11.145 17.1356 10.992 16.9826 10.8961 16.7945C10.7871 16.5805 10.7871 16.3005 10.7871 15.7405L10.7871 9.23281C10.7871 8.67276 10.7871 8.39273 10.8961 8.17882C10.992 7.99066 11.145 7.83768 11.3331 7.74181C11.547 7.63281 11.8271 7.63281 12.3871 7.63281L24.646 7.63281C25.2061 7.63281 25.4861 7.63281 25.7 7.74181C25.8882 7.83768 26.0412 7.99066 26.137 8.17882C26.246 8.39273 26.246 8.67275 26.246 9.2328L26.2461 15.7405Z"
        fill="currentColor"
      />
    </svg>
  )
}

function ShowMobileAppPopupIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M19.9986 2.66797V4.53453C19.9986 5.28122 19.9986 5.65457 19.8533 5.93977C19.7255 6.19064 19.5215 6.3946 19.2706 6.52243C18.9854 6.66774 18.6121 6.66774 17.8654 6.66774H14.1323C13.3856 6.66774 13.0122 6.66774 12.727 6.52243C12.4761 6.3946 12.2722 6.19064 12.1444 5.93977C11.999 5.65457 11.999 5.28122 11.999 4.53453V2.66797M10.9324 29.3331H21.0652C22.5586 29.3331 23.3053 29.3331 23.8757 29.0425C24.3774 28.7868 24.7853 28.3789 25.041 27.8772C25.3316 27.3068 25.3316 26.5601 25.3316 25.0667V6.93439C25.3316 5.441 25.3316 4.69431 25.041 4.12391C24.7853 3.62217 24.3774 3.21425 23.8757 2.9586C23.3053 2.66797 22.5586 2.66797 21.0652 2.66797H10.9324C9.43905 2.66797 8.69236 2.66797 8.12196 2.9586C7.62022 3.21425 7.2123 3.62217 6.95665 4.12391C6.66602 4.69431 6.66602 5.44101 6.66602 6.93439V25.0667C6.66602 26.5601 6.66602 27.3068 6.95665 27.8772C7.2123 28.3789 7.62022 28.7868 8.12196 29.0425C8.69236 29.3331 9.43905 29.3331 10.9324 29.3331Z"
        stroke="#FF462D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 24.068C22 24.628 22 24.908 21.891 25.122C21.7951 25.3101 21.6422 25.4631 21.454 25.559C21.2401 25.668 20.96 25.668 20.4 25.668L11.6008 25.668C11.0407 25.668 10.7607 25.668 10.5468 25.559C10.3586 25.4631 10.2057 25.3101 10.1098 25.122C10.0008 24.908 10.0008 24.628 10.0008 24.068L10.0008 12.4392C10.0008 11.8792 10.0008 11.5991 10.1098 11.3852C10.2057 11.1971 10.3586 11.0441 10.5468 10.9482C10.7607 10.8392 11.0407 10.8392 11.6008 10.8392L20.4 10.8392C20.96 10.8392 21.24 10.8392 21.4539 10.9482C21.6421 11.0441 21.7951 11.1971 21.891 11.3852C22 11.5991 22 11.8792 22 12.4392L22 24.068Z"
        fill="currentColor"
      />
    </svg>
  )
}

function AddSegmentIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M26.6663 19.9974C26.6663 19.261 26.0694 18.6641 25.333 18.6641C24.5966 18.6641 23.9997 19.261 23.9997 19.9974V22.6641H21.333C20.5966 22.6641 19.9997 23.261 19.9997 23.9974C19.9997 24.7338 20.5966 25.3307 21.333 25.3307H23.9997V27.9974C23.9997 28.7338 24.5966 29.3307 25.333 29.3307C26.0694 29.3307 26.6663 28.7338 26.6663 27.9974V25.3307H29.333C30.0694 25.3307 30.6663 24.7338 30.6663 23.9974C30.6663 23.261 30.0694 22.6641 29.333 22.6641H26.6663V19.9974Z"
        fill="#FF462D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.241 18.8821C16.6173 19.0738 16.9233 19.3798 17.115 19.7561C17.3191 20.1566 17.3332 20.5942 17.333 21.0332C17.333 21.0436 17.333 21.0539 17.333 21.0641C17.333 23.0299 17.3323 24.9957 17.333 26.9616C17.3331 27.1204 17.3332 27.3101 17.3195 27.4772C17.3037 27.6708 17.2631 27.9481 17.115 28.2387C16.9233 28.615 16.6173 28.921 16.241 29.1127C15.9504 29.2608 15.6731 29.3014 15.4795 29.3172C15.3124 29.3309 15.1227 29.3308 14.9639 29.3307C11.2099 29.3294 7.45602 29.3294 3.7021 29.3307C3.54308 29.3308 3.35299 29.3309 3.18549 29.3171C2.99119 29.3012 2.71331 29.2603 2.42228 29.1115C2.04547 28.9189 1.73871 28.6107 1.54779 28.233C1.40072 27.9421 1.36084 27.6649 1.34568 27.4702C1.33266 27.3029 1.3336 27.1133 1.33438 26.9553C1.34126 25.5696 1.29995 24.0845 1.84049 22.7795C2.51711 21.146 3.81494 19.8482 5.44846 19.1715C6.12806 18.89 6.84577 18.7733 7.65742 18.7179C8.44677 18.664 9.41665 18.6641 10.619 18.6641C12.0673 18.6641 13.5156 18.6646 14.9639 18.6641C15.4029 18.6639 15.8404 18.678 16.241 18.8821Z"
        fill="#FF462D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4304 3.88479C19.7067 3.20221 20.4841 2.87286 21.1666 3.14916C23.6074 4.13714 25.333 6.53081 25.333 9.33073C25.333 12.1306 23.6074 14.5243 21.1666 15.5123C20.4841 15.7886 19.7067 15.4592 19.4304 14.7767C19.1541 14.0941 19.4835 13.3168 20.1661 13.0405C21.6344 12.4461 22.6663 11.0073 22.6663 9.33073C22.6663 7.65414 21.6344 6.21537 20.1661 5.62099C19.4835 5.34469 19.1541 4.56737 19.4304 3.88479Z"
        fill="#FF462D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99968 9.33073C5.99968 5.64883 8.98445 2.66406 12.6663 2.66406C16.3482 2.66406 19.333 5.64883 19.333 9.33073C19.333 13.0126 16.3482 15.9974 12.6663 15.9974C8.98445 15.9974 5.99968 13.0126 5.99968 9.33073Z"
        fill="#FF462D"
      />
    </svg>
  )
}

function SendGameHubPopupIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M29.3327 12H2.66602M2.66602 10.4L2.66602 21.6C2.66602 23.8402 2.66602 24.9603 3.10199 25.816C3.48548 26.5686 4.09741 27.1805 4.85005 27.564C5.7057 28 6.8258 28 9.06601 28H22.9327C25.1729 28 26.293 28 27.1486 27.564C27.9013 27.1805 28.5132 26.5686 28.8967 25.816C29.3327 24.9603 29.3327 23.8402 29.3327 21.6V10.4C29.3327 8.15979 29.3327 7.03969 28.8967 6.18404C28.5132 5.43139 27.9013 4.81947 27.1486 4.43598C26.293 4 25.1729 4 22.9327 4L9.06602 4C6.82581 4 5.7057 4 4.85005 4.43597C4.09741 4.81947 3.48548 5.43139 3.10199 6.18404C2.66602 7.03968 2.66602 8.15979 2.66602 10.4Z"
        stroke="#FF462D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6467 14.6797C25.2068 14.6797 25.4868 14.6797 25.7007 14.7887C25.8889 14.8846 26.0418 15.0375 26.1377 15.2257C26.2467 15.4396 26.2467 15.7196 26.2467 16.2797V23.6805C26.2467 24.2405 26.2467 24.5205 26.1377 24.7345C26.0418 24.9226 25.8889 25.0756 25.7007 25.1715C25.4868 25.2805 25.2068 25.2805 24.6467 25.2805L7.27188 25.2805C6.71182 25.2805 6.4318 25.2805 6.21788 25.1715C6.02972 25.0756 5.87674 24.9226 5.78087 24.7345C5.67188 24.5205 5.67188 24.2405 5.67188 23.6805L5.67188 16.2797C5.67188 15.7197 5.67188 15.4396 5.78087 15.2257C5.87674 15.0376 6.02972 14.8846 6.21788 14.7887C6.43179 14.6797 6.71182 14.6797 7.27187 14.6797L24.6467 14.6797Z"
        fill="#FF462D"
      />
    </svg>
  )
}

function SortIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
      <path
        d="M6 6L18 18M18 18V10M18 18H10"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export function getNodeColor(modelType: string | undefined) {
  switch (modelType) {
    case EventActionNodeType.TimerNode:
      return '#CFA9F3'

    case EventActionNodeType.ConditionNode:
    case EventActionNodeType.SplitNode:
      return '#FF9F1C'

    case EventActionNodeType.BrowserPopupNotificationActionNode:
    case EventActionNodeType.BrowserPushNotificationActionNode:
    case EventActionNodeType.WebhookActionNode:
    case EventActionNodeType.EmailActionNode:
    case EventActionNodeType.MobilePopupActionNode:
    case EventActionNodeType.MobilePushActionNode:
    case EventActionNodeType.AddToSegmentNode:
      return '#FF462D'

    case EventActionNodeType.AddStoreItemsNode:
      return '#22C55E'
    case EventActionNodeType.RemoveItemsStoreNode:
      return '#B91C1C'
    case EventActionNodeType.SortStoreItemsNode:
    case EventActionNodeType.AddStoreNode:
      return '#0097E4'
    case EventActionNodeType.ItemDiscountOfferActionNode:
    case EventActionNodeType.ItemBonusOfferActionNode:
    case EventActionNodeType.ItemOfferActionNode:
    case EventActionNodeType.CreateItemBannerActionNode:
    case EventActionNodeType.CreateCouponNode:
    case EventActionNodeType.CreateVirtualSKUNode:
    case EventActionNodeType.CreateUserStoreSettingsNode:
    case EventActionNodeType.SetAttributeValuesNode:
      return '#FF6CAF'
  }

  return ''
}

export function getNodeIcon(modelType: string | undefined | EventActionNodeType) {
  switch (modelType) {
    case EventActionNodeType.TimerNode:
      return <TimerIcon />
    case EventActionNodeType.ConditionNode:
    case EventActionNodeType.SplitNode:
      return <LogicIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.BrowserPopupNotificationActionNode:
      return <SendGameHubPopupIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.BrowserPushNotificationActionNode:
      return <SendGameHubPushIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.MobilePopupActionNode:
      return <ShowMobileAppPopupIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.MobilePushActionNode:
      return <SendMobileAppPushIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.WebhookActionNode:
      return <MobileIcon style={{ color: getNodeColor(modelType) }} />
    case EventActionNodeType.EmailActionNode:
      return <EmailIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.AddToSegmentNode:
      return <AddSegmentIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.CreateVirtualSKUNode:
    case EventActionNodeType.ItemOfferActionNode:
    case EventActionNodeType.AddStoreItemsNode:
    case EventActionNodeType.RemoveItemsStoreNode:
    case EventActionNodeType.AddStoreNode:
    case EventActionNodeType.CreateCouponNode:
    case EventActionNodeType.ItemDiscountOfferActionNode:
    case EventActionNodeType.ItemBonusOfferActionNode:
    case EventActionNodeType.CreateItemBannerActionNode:
    case EventActionNodeType.CreateUserStoreSettingsNode:
    case EventActionNodeType.SetAttributeValuesNode:
      return <DBIcon style={{ color: getNodeColor(modelType) }} />

    case EventActionNodeType.SortStoreItemsNode:
      return <SortIcon style={{ color: getNodeColor(modelType) }} />

    default:
      return null
  }
}
