import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const LinkedinIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.53 2.625C3.03018 2.625 2.625 3.03018 2.625 3.53V14.47C2.625 14.9698 3.03018 15.375 3.53 15.375H14.47C14.9698 15.375 15.375 14.9698 15.375 14.47V3.53C15.375 3.03018 14.9698 2.625 14.47 2.625H3.53ZM5.48679 6.58452C6.09765 6.58452 6.59285 6.08932 6.59285 5.47846C6.59285 4.8676 6.09765 4.3724 5.48679 4.3724C4.87593 4.3724 4.38073 4.8676 4.38073 5.47846C4.38073 6.08932 4.87593 6.58452 5.48679 6.58452ZM7.60581 7.40193H9.43891V8.24168C9.43891 8.24168 9.93634 7.24681 11.2898 7.24681C12.4971 7.24681 13.4973 7.84157 13.4973 9.65446V13.4773H11.5977V10.1177C11.5977 9.04825 11.0267 8.93063 10.5917 8.93063C9.68875 8.93063 9.53417 9.70945 9.53417 10.2572V13.4773H7.60581V7.40193ZM6.45097 7.40193H4.52261V13.4773H6.45097V7.40193Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
