import { Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'

export type mobileContextType = {
  leftOpened: boolean
  setLeftOpened: Dispatch<SetStateAction<boolean>>
  rightOpened: boolean
  setRightOpened: Dispatch<SetStateAction<boolean>>
}

export const MobileContext = createContext<mobileContextType>({
  leftOpened: false,
  setLeftOpened: state => state,
  rightOpened: false,
  setRightOpened: state => state,
})

export const MobileProvider = ({ children }: { children: ReactNode }): ReactElement<mobileContextType> => {
  const [leftOpened, setLeftOpened] = useState<boolean>(false)
  const [rightOpened, setRightOpened] = useState<boolean>(false)

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      const element = e.target as HTMLElement
      const origin = element.closest(`a`)

      if (origin && !origin.hasAttribute('data-open')) {
        setLeftOpened(false)
        setRightOpened(false)
      }
    }
    document.addEventListener('click', handleClick)
    return () => window.removeEventListener('click', handleClick)
  }, [])

  return (
    <MobileContext.Provider
      value={{
        leftOpened,
        setLeftOpened,
        rightOpened,
        setRightOpened,
      }}
    >
      {children}
    </MobileContext.Provider>
  )
}
