import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '../IconBase'

export const Hamburger: AghanimIcon = forwardRef(function ({ strokeWidth = 0, ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </IconBase>
  )
})
