import { CSSProperties } from 'react'
import { GameStoreType } from '../types'
import { GameStoreName, SocialMediaName } from '@/api/dashboard'

export function SettingsIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 8L15 8M15 8C15 9.65686 16.3431 11 18 11C19.6569 11 21 9.65685 21 8C21 6.34315 19.6569 5 18 5C16.3431 5 15 6.34315 15 8ZM9 16L21 16M9 16C9 17.6569 7.65685 19 6 19C4.34315 19 3 17.6569 3 16C3 14.3431 4.34315 13 6 13C7.65685 13 9 14.3431 9 16Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function InfoCircleIcon() {
  return (
    <svg
      width="17"
      height="17"
      style={{ minWidth: '17px' }}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2034_36)">
        <path
          d="M8.49984 11.3334V8.50008M8.49984 5.66675H8.50692M15.5832 8.50008C15.5832 12.4121 12.4119 15.5834 8.49984 15.5834C4.58782 15.5834 1.4165 12.4121 1.4165 8.50008C1.4165 4.58806 4.58782 1.41675 8.49984 1.41675C12.4119 1.41675 15.5832 4.58806 15.5832 8.50008Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2034_36">
          <rect width="17" height="17" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function WarningIcon() {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 10C0 4.47715 4.47715 0 10 0H27C32.5228 0 37 4.47715 37 10V27C37 32.5228 32.5228 37 27 37H10C4.47715 37 0 32.5228 0 27V10Z"
        fill="#C2410C"
      />
      <g clipPath="url(#clip0_2724_11891)">
        <path
          d="M18.5 15V18.5M18.5 22H18.5088M9.75 15.4574V21.5426C9.75 21.7566 9.75 21.8636 9.77418 21.9643C9.79561 22.0536 9.83096 22.139 9.87894 22.2172C9.93305 22.3056 10.0087 22.3812 10.1601 22.5326L14.4674 26.8399C14.6188 26.9913 14.6944 27.0669 14.7828 27.1211C14.861 27.169 14.9464 27.2044 15.0357 27.2258C15.1364 27.25 15.2434 27.25 15.4574 27.25H21.5426C21.7566 27.25 21.8636 27.25 21.9643 27.2258C22.0536 27.2044 22.139 27.169 22.2172 27.1211C22.3056 27.0669 22.3812 26.9913 22.5325 26.8399L26.8399 22.5325C26.9913 22.3812 27.0669 22.3056 27.1211 22.2172C27.169 22.139 27.2044 22.0536 27.2258 21.9643C27.25 21.8636 27.25 21.7566 27.25 21.5426V15.4574C27.25 15.2434 27.25 15.1364 27.2258 15.0357C27.2044 14.9464 27.169 14.861 27.1211 14.7828C27.0669 14.6944 26.9913 14.6188 26.8399 14.4674L22.5325 10.1601C22.3812 10.0087 22.3056 9.93305 22.2172 9.87894C22.139 9.83096 22.0536 9.79561 21.9643 9.77418C21.8636 9.75 21.7566 9.75 21.5426 9.75H15.4574C15.2434 9.75 15.1364 9.75 15.0357 9.77418C14.9464 9.79561 14.861 9.83096 14.7828 9.87894C14.6944 9.93305 14.6188 10.0087 14.4674 10.1601L10.1601 14.4674C10.0087 14.6188 9.93305 14.6944 9.87894 14.7828C9.83096 14.861 9.79561 14.9464 9.77418 15.0357C9.75 15.1364 9.75 15.2434 9.75 15.4574Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2724_11891">
          <rect width="21" height="21" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function CopyIcon(props: { style?: CSSProperties }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none" style={props.style}>
      <path
        d="M8.75 1.41841V4C8.75 4.35003 8.75 4.52505 8.81812 4.65874C8.87804 4.77635 8.97365 4.87196 9.09126 4.93188C9.22495 5 9.39997 5 9.75 5H12.3316M12.5 6.24264V10.75C12.5 11.8001 12.5 12.3251 12.2956 12.7262C12.1159 13.079 11.829 13.3659 11.4762 13.5456C11.0751 13.75 10.5501 13.75 9.5 13.75H5.5C4.4499 13.75 3.92485 13.75 3.52377 13.5456C3.17096 13.3659 2.88413 13.079 2.70436 12.7262C2.5 12.3251 2.5 11.8001 2.5 10.75V4.25C2.5 3.1999 2.5 2.67485 2.70436 2.27377C2.88413 1.92096 3.17096 1.63413 3.52377 1.45436C3.92485 1.25 4.4499 1.25 5.5 1.25H7.50736C7.96597 1.25 8.19527 1.25 8.41106 1.30181C8.60238 1.34774 8.78527 1.4235 8.95303 1.5263C9.14225 1.64225 9.3044 1.8044 9.62868 2.12868L11.6213 4.12132C11.9456 4.4456 12.1077 4.60775 12.2237 4.79697C12.3265 4.96473 12.4023 5.14762 12.4482 5.33894C12.5 5.55473 12.5 5.78403 12.5 6.24264Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function SectionIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 3H3.01M7.5 3H7.51M16.5 3H16.51M12 3H12.01M21 3H21.01M3 21H3.01M7.5 21H7.51M16.5 21H16.51M12 21H12.01M21 21H21.01M5 12C5 11.0681 5 10.6022 5.15224 10.2346C5.35523 9.74458 5.74458 9.35523 6.23463 9.15224C6.60218 9 7.06812 9 8 9L16 9C16.9319 9 17.3978 9 17.7654 9.15224C18.2554 9.35523 18.6448 9.74458 18.8478 10.2346C19 10.6022 19 11.0681 19 12C19 12.9319 19 13.3978 18.8478 13.7654C18.6448 14.2554 18.2554 14.6448 17.7654 14.8478C17.3978 15 16.9319 15 16 15L8 15C7.06812 15 6.60218 15 6.23463 14.8478C5.74458 14.6448 5.35523 14.2554 5.15224 13.7654C5 13.3978 5 12.9319 5 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function IPhoneIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 3.9375C8.75 3.69588 8.94588 3.5 9.1875 3.5H11.8125C12.0541 3.5 12.25 3.69588 12.25 3.9375C12.25 4.17912 12.0541 4.375 11.8125 4.375H9.1875C8.94588 4.375 8.75 4.17912 8.75 3.9375Z"
        fill="#475467"
      />
      <path
        d="M8.96875 17.0625C8.84794 17.0625 8.75 17.1604 8.75 17.2812C8.75 17.4021 8.84794 17.5 8.96875 17.5H12.0312C12.1521 17.5 12.25 17.4021 12.25 17.2812C12.25 17.1604 12.1521 17.0625 12.0312 17.0625H8.96875Z"
        fill="#475467"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.875 1.75C6.42525 1.75 5.25 2.92525 5.25 4.375V16.625C5.25 18.0747 6.42525 19.25 7.875 19.25H13.125C14.5747 19.25 15.75 18.0747 15.75 16.625V4.375C15.75 2.92525 14.5747 1.75 13.125 1.75H7.875ZM13.125 3.0625H7.875C7.15013 3.0625 6.5625 3.65013 6.5625 4.375V16.625C6.5625 17.3499 7.15013 17.9375 7.875 17.9375H13.125C13.8499 17.9375 14.4375 17.3499 14.4375 16.625V4.375C14.4375 3.65013 13.8499 3.0625 13.125 3.0625Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function IPadIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 3.9375C8.75 3.69588 8.94588 3.5 9.1875 3.5H11.8125C12.0541 3.5 12.25 3.69588 12.25 3.9375C12.25 4.17912 12.0541 4.375 11.8125 4.375H9.1875C8.94588 4.375 8.75 4.17912 8.75 3.9375Z"
        fill="#475467"
      />
      <path
        d="M8.96875 17.0625C8.84794 17.0625 8.75 17.1604 8.75 17.2812C8.75 17.4021 8.84794 17.5 8.96875 17.5H12.0312C12.1521 17.5 12.25 17.4021 12.25 17.2812C12.25 17.1604 12.1521 17.0625 12.0312 17.0625H8.96875Z"
        fill="#475467"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.125 1.75C4.67525 1.75 3.5 2.92525 3.5 4.375V16.625C3.5 18.0747 4.67525 19.25 6.125 19.25H14.875C16.3247 19.25 17.5 18.0747 17.5 16.625V4.375C17.5 2.92525 16.3247 1.75 14.875 1.75H6.125ZM14.875 3.0625H6.125C5.40013 3.0625 4.8125 3.65013 4.8125 4.375V16.625C4.8125 17.3499 5.40013 17.9375 6.125 17.9375H14.875C15.5999 17.9375 16.1875 17.3499 16.1875 16.625V4.375C16.1875 3.65013 15.5999 3.0625 14.875 3.0625Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function DesktopIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 1.75C2.5335 1.75 1.75 2.5335 1.75 3.5V14C1.75 14.9665 2.5335 15.75 3.5 15.75H7.875V18.375H6.5625C6.32088 18.375 6.125 18.5709 6.125 18.8125C6.125 19.0541 6.32088 19.25 6.5625 19.25H14.4375C14.6791 19.25 14.875 19.0541 14.875 18.8125C14.875 18.5709 14.6791 18.375 14.4375 18.375H13.125V15.75H17.5C18.4665 15.75 19.25 14.9665 19.25 14V3.5C19.25 2.5335 18.4665 1.75 17.5 1.75H3.5ZM17.5 3.0625H3.5C3.25838 3.0625 3.0625 3.25838 3.0625 3.5V14C3.0625 14.2416 3.25838 14.4375 3.5 14.4375H17.5C17.7416 14.4375 17.9375 14.2416 17.9375 14V3.5C17.9375 3.25838 17.7416 3.0625 17.5 3.0625Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function ResponsiveIcon() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.375 3.0625H16.625C17.3499 3.0625 17.9375 3.65013 17.9375 4.375V6.125H19.25V4.375C19.25 2.92525 18.0747 1.75 16.625 1.75H4.375C2.92525 1.75 1.75 2.92525 1.75 4.375V6.125H3.0625V4.375C3.0625 3.65013 3.65013 3.0625 4.375 3.0625Z"
        fill="currentColor"
      />
      <path
        d="M3.0625 14.875H1.75V16.625C1.75 18.0747 2.92525 19.25 4.375 19.25H16.625C18.0747 19.25 19.25 18.0747 19.25 16.625V14.875H17.9375V16.625C17.9375 17.3499 17.3499 17.9375 16.625 17.9375H4.375C3.65013 17.9375 3.0625 17.3499 3.0625 16.625V14.875Z"
        fill="currentColor"
      />
      <path
        d="M5.71404 7.41096C5.97032 7.66724 5.97032 8.08276 5.71404 8.33904L4.20933 9.84375H16.7907L15.286 8.33904C15.0297 8.08276 15.0297 7.66724 15.286 7.41096C15.5422 7.15468 15.9578 7.15468 16.214 7.41096L18.839 10.036C19.0953 10.2922 19.0953 10.7078 18.839 10.964L16.214 13.589C15.9578 13.8453 15.5422 13.8453 15.286 13.589C15.0297 13.3328 15.0297 12.9172 15.286 12.661L16.7907 11.1562H4.20933L5.71404 12.661C5.97032 12.9172 5.97032 13.3328 5.71404 13.589C5.45776 13.8453 5.04224 13.8453 4.78596 13.589L2.16096 10.964C1.90468 10.7078 1.90468 10.2922 2.16096 10.036L4.78596 7.41096C5.04224 7.15468 5.45776 7.15468 5.71404 7.41096Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const SOCIAL_ICONS_MAP = {
  [GameStoreType.app_store]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/app-store.png`,
  [GameStoreType.google_play]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/google-play.png`,
  [SocialMediaName.Facebook]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/facebook.png`,
  [SocialMediaName.Instagram]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/instagram.png`,
  [SocialMediaName.Twitter]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/x.png`,
  [SocialMediaName.Discord]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/discord.png`,
  [SocialMediaName.Youtube]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/youtube.png`,
  [SocialMediaName.Tiktok]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/tiktok.png`,
  [SocialMediaName.Telegram]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/telegram.webp`,
  amazon: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/social-media/amazon.png`,
}

export const STORES_ICONS_MAP = {
  [GameStoreName.AppStore]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/app-store.svg`,
  [GameStoreName.GooglePlay]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/google-play.svg`,
  [GameStoreName.Amazon]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/amazon-appstore.svg`,
  [GameStoreName.Huawei]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/huawei-appgallery.svg`,
  [GameStoreName.OneStore]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/onestore.svg`,
  [GameStoreName.Steam]: `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/stores/steam.svg`,
}
export function BackIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M9.00079 4.04221C9.27741 3.76559 9.27741 3.3171 9.00079 3.04047C8.72416 2.76385 8.27567 2.76385 7.99905 3.04047L3.04072 7.99881C2.7641 8.27543 2.7641 8.72392 3.04072 9.00054L7.99905 13.9589C8.27567 14.2355 8.72416 14.2355 9.00079 13.9589C9.27741 13.6823 9.27741 13.2338 9.00079 12.9571L5.25165 9.20801L13.4583 9.20801C13.8495 9.20801 14.1666 8.89088 14.1666 8.49967C14.1666 8.10847 13.8495 7.79134 13.4583 7.79134L5.25165 7.79134L9.00079 4.04221Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function EmailIcon(props: { style?: CSSProperties }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" style={props.style}>
      <path
        d="M2.66699 9.33203L13.5536 16.9526C14.4351 17.5697 14.8759 17.8783 15.3553 17.9978C15.7789 18.1033 16.2218 18.1033 16.6453 17.9978C17.1248 17.8783 17.5655 17.5697 18.4471 16.9526L29.3337 9.33203M9.06699 26.6654H22.9337C25.1739 26.6654 26.294 26.6654 27.1496 26.2294C27.9023 25.8459 28.5142 25.234 28.8977 24.4813C29.3337 23.6257 29.3337 22.5056 29.3337 20.2654V11.732C29.3337 9.49182 29.3337 8.37172 28.8977 7.51607C28.5142 6.76342 27.9023 6.1515 27.1496 5.76801C26.294 5.33203 25.1739 5.33203 22.9337 5.33203H9.06699C6.82678 5.33203 5.70668 5.33203 4.85103 5.76801C4.09838 6.1515 3.48646 6.76342 3.10297 7.51607C2.66699 8.37172 2.66699 9.49182 2.66699 11.732V20.2654C2.66699 22.5056 2.66699 23.6257 3.10297 24.4813C3.48646 25.234 4.09838 25.8459 4.85103 26.2294C5.70668 26.6654 6.82678 26.6654 9.06699 26.6654Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function PlusIcon(props: { size?: number; style?: CSSProperties }) {
  return (
    <svg width={props.size || '24'} height={props.size || '24'} viewBox="0 0 24 24" fill="none" style={props.style}>
      <path d="M12 5V19M5 12H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export function TrashIcon(props: { style?: CSSProperties; onClick?: () => void }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" style={props.style} onClick={props.onClick}>
      <path
        d="M10.25 6.75H13.75M6.75 8.5H17.25M16.0833 8.5L15.6742 14.6363C15.6129 15.5569 15.5822 16.0172 15.3833 16.3663C15.2083 16.6735 14.9442 16.9206 14.626 17.0748C14.2645 17.25 13.8031 17.25 12.8805 17.25H11.1195C10.1969 17.25 9.73552 17.25 9.37403 17.0748C9.05577 16.9206 8.79172 16.6735 8.61666 16.3663C8.41781 16.0172 8.38713 15.5569 8.32575 14.6363L7.91667 8.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function EditIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M11.25 6.25019L8.75002 3.75019M1.5625 13.4377L3.67773 13.2027C3.93616 13.174 4.06538 13.1596 4.18615 13.1205C4.29331 13.0858 4.39528 13.0368 4.4893 12.9748C4.59528 12.9049 4.68722 12.813 4.87108 12.6291L13.125 4.37519C13.8154 3.68483 13.8154 2.56555 13.125 1.87519C12.4347 1.18483 11.3154 1.18483 10.625 1.87519L2.37108 10.1291C2.18722 10.313 2.09528 10.4049 2.0254 10.5109C1.9634 10.6049 1.91438 10.7069 1.8797 10.814C1.8406 10.9348 1.82624 11.064 1.79753 11.3225L1.5625 13.4377Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export function AlertTriangleIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99976 6.00003V8.6667M7.99976 11.3334H8.00642M7.07663 2.59451L1.59338 12.0656C1.28924 12.5909 1.13717 12.8536 1.15965 13.0692C1.17925 13.2572 1.27776 13.4281 1.43067 13.5392C1.60597 13.6667 1.90948 13.6667 2.5165 13.6667H13.483C14.09 13.6667 14.3935 13.6667 14.5688 13.5392C14.7217 13.4281 14.8203 13.2572 14.8399 13.0692C14.8623 12.8536 14.7103 12.5909 14.4061 12.0656L8.92288 2.59451C8.61983 2.07107 8.46831 1.80935 8.27062 1.72145C8.09818 1.64477 7.90133 1.64477 7.72889 1.72145C7.5312 1.80935 7.37968 2.07107 7.07663 2.59451Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
