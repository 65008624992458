import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Button, Skeleton } from '@dashboard/ui'
import {
  PriceTemplateRow,
  PriceTemplateRowSkeleton,
  PriceTemplateZeroState,
  SKU_PRICE_TEMPLATES_CREATE_PATH,
  getPriceTemplatesInfinityOptions,
} from 'src/layouts/sku-price-templates'
import { Page, PageHeader, TableCell, TableRow } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'

export const PriceTemplatesPage = () => {
  const { companyId, gameId, generatePath } = useGenerateCompanyPath()
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'table-list' })
  const { data: templates = [], isLoading } = useInfiniteQuery(getPriceTemplatesInfinityOptions(companyId, gameId))

  if (isLoading) {
    return (
      <Page>
        <PageHeader>{t('title')}</PageHeader>

        <TableRow variant="header">
          <TableCell width="100%">
            <Skeleton className="h-[16px] w-1/2" />
          </TableCell>
          <TableCell width="30%">
            <Skeleton className="h-[16px] w-1/2" />
          </TableCell>
          <TableCell width="30%">
            <Skeleton className="h-[16px] w-1/2" />
          </TableCell>
          <TableCell width="40%">
            <Skeleton className="h-[16px] w-1/2" />
          </TableCell>
          <TableCell width={52} />
        </TableRow>

        <PriceTemplateRowSkeleton />
        <PriceTemplateRowSkeleton />
        <PriceTemplateRowSkeleton />
        <PriceTemplateRowSkeleton />
        <PriceTemplateRowSkeleton />
      </Page>
    )
  }

  if (templates.length === 0 && !isLoading) {
    return (
      <Page>
        <PageHeader>{t('title')}</PageHeader>
        <PriceTemplateZeroState />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          <Link to={generatePath(SKU_PRICE_TEMPLATES_CREATE_PATH)}>
            <Button>{t('action-add')}</Button>
          </Link>
        }
      >
        {t('title')}
      </PageHeader>

      <TableRow variant="header">
        <TableCell width="100%">{t('header.name')}</TableCell>
        <TableCell width="30%">{t('header.items')}</TableCell>
        <TableCell width="40%">{t('header.last-update')}</TableCell>
        <TableCell width={52} />
      </TableRow>

      <div>
        {templates?.map(template => <PriceTemplateRow key={`price-template-row-${template.id}`} template={template} />)}
      </div>
    </Page>
  )
}
