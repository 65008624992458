import { HTMLAttributes, ReactNode, useRef } from 'react'
import { cn } from '@dashboard/ui'

export interface ShadowHoverBlockProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  color: string
  selected?: boolean
}

export const ShadowHoverBlock = ({ children, selected, color, className }: ShadowHoverBlockProps) => {
  const ref = useRef<HTMLDivElement>(null)
  return (
    <div
      ref={ref}
      style={{
        borderColor: color,
        boxShadow: selected ? `1px 4px 22px 0px ${color}` : '',
      }}
      onMouseOver={() => {
        if (ref.current) {
          ref.current.style.boxShadow = `1px 4px 22px 0px ${color}`
          if (ref.current.lastChild) {
            ;(ref.current.lastChild as HTMLDivElement).style.boxShadow = `1px 4px 22px 0px ${color}`
          }
        }
      }}
      onMouseOut={() => {
        if (selected) {
          return
        }
        if (ref.current) {
          ref.current.style.boxShadow = ''
          if (ref.current.lastChild) {
            ;(ref.current.lastChild as HTMLDivElement).style.boxShadow = ''
          }
        }
      }}
      className={cn(
        `relative flex size-[68px] cursor-pointer items-center justify-center rounded-md border-2 bg-fg-primary p-3.5`,
        className,
      )}
    >
      {children}
    </div>
  )
}
