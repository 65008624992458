import { AddStoreNode, Node } from '@/api/dashboard'
import { Badge, FieldGroup } from '@/ui'
import { useParams } from 'react-router-dom'
import { BlockError } from '../../campaigns/types'
import { useStoresQuery } from '../api'
import { useTranslation } from 'react-i18next'
import { Select } from '@dashboard/ui'

export default function AddStoreEditor(props: {
  node: AddStoreNode
  error?: BlockError | null
  setNode: (node: Node) => void
}) {
  const { t } = useTranslation()

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const selectedStore = stores.find(s => s.id === props.node.store_id)
  return (
    <FieldGroup label={t('store.AddStoreNode.select')}>
      <Select
        items={stores
          .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          .map(s => ({
            value: s.id,
            children: (
              <div className="flex items-center gap-2">
                {s.name}
                <Badge variant="gray-secondary">{s.item_count}</Badge>
              </div>
            ) as unknown as string,
            extraRight: t(`store.type.${s.type}`),
          }))}
        onChange={v => {
          props.setNode({
            ...props.node,
            model_type: 'AddStoreNode',
            store_id: v as string,
          })
        }}
        value={selectedStore?.id || ''}
      />
    </FieldGroup>
  )
}
