import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import { Blocker } from 'react-router-dom'
import { toast } from 'sonner'
import { Button, useModal, useModalBlocker } from '@dashboard/ui'
import {
  PriceTemplateFormData,
  PriceTemplateModalConfirmClose,
  PriceTemplateTable,
  PriceTemplateTableForm,
} from '@/layouts/sku-price-templates'
import { PriceCategory, PriceTemplateRead } from '@/api/dashboard'
import { Badge, ModalRushTitle } from '@/ui'
import { UseNavigateBackValue, usePydenticForm } from '@/libs'

interface PriceTemplateFormProps {
  back: UseNavigateBackValue
  template: PriceTemplateRead
  mode: 'manage' | 'apply'
  onSubmit: (template: PriceTemplateRead) => Promise<void>
}

export const PriceTemplateForm = ({ back, template, mode, onSubmit }: PriceTemplateFormProps) => {
  const { t: tBase } = useTranslation()
  const { t } = useTranslation('sku-price-templates')

  const methods = usePydenticForm<PriceTemplateFormData>({
    defaultValues: {
      ...template,
      price: template.price ? template.price / 100 : 0,
      _state: {
        initialPrices: [],
        adjusts: template?.category === PriceCategory.None ? !template.prices.every(p => p.is_custom) : false,
        loading: true,
        mode,
      },
    },
    mode: 'onChange',
  })
  const {
    formState: { isSubmitting, isDirty },
    watch,
    reset,
    handleSubmit,
  } = methods

  const openConfirmClose = useModal<{ blocker: Blocker }>(props => <PriceTemplateModalConfirmClose {...props} />)

  useModalBlocker(isDirty, blocker => openConfirmClose({ blocker }))

  const [name] = watch(['name', 'price', 'category'])

  const onFormSubmit = handleSubmit(async data => {
    await onSubmit({
      ...data,
      price: Math.trunc(data.price * 100),
    })
    reset(data)
    toast.success(t('form-edit.toast.saved'))
  })

  return (
    <FormProvider {...methods}>
      <form className="flex h-full flex-col" onSubmit={onFormSubmit}>
        <ModalRushTitle
          back={back}
          extra={
            <>
              <Button variant="outline" onClick={back.onClick}>
                {tBase('Cancel')}
              </Button>
              <Button loading={isSubmitting} disabled={!isDirty} type="submit">
                {mode === 'manage'
                  ? template?.id
                    ? t('form-edit.action-update')
                    : t('form-edit.action-create')
                  : t('form-edit.action-apply')}
              </Button>
            </>
          }
        >
          <span>
            {mode === 'manage'
              ? template?.id
                ? t('form-edit.title-update')
                : t('form-edit.title-create')
              : t('form-edit.title-apply')}
          </span>
          {name && <span>·</span>}
          <span>{name}</span>

          {isDirty && <Badge variant="orange-tertiary">{t('form-edit.unsaved-changes')}</Badge>}
        </ModalRushTitle>

        <div className="-mx-4 grid h-full grid-cols-[320px_1fr] items-start gap-[18px] overflow-y-auto px-4">
          <PriceTemplateTableForm />
          <PriceTemplateTable template={template} />
        </div>
      </form>
    </FormProvider>
  )
}
