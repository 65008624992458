import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { ItemRarityCreate } from '@/api/dashboard'

interface ItemRarityCreateMutate {
  create: ItemRarityCreate
}

export const useItemRarityCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ create }: ItemRarityCreateMutate) => dashboardClient.v1.createItemRarity(companyId, gameId, create),
    onSuccess: data => {
      queryClient.setQueryData(['item-rarity', gameId], data.data)
      queryClient.invalidateQueries({ queryKey: ['item-rarity', gameId] })
    },
  })
}
