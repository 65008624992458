import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { removeItemQueries } from '@/layouts/game-items/api'

interface ItemRarityDeleteMutate {
  id: string
}

export const useItemRarityDeleteMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: ItemRarityDeleteMutate) => dashboardClient.v1.deleteItemRarity(companyId, gameId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['item-rarity', gameId] })

      removeItemQueries(queryClient, gameId)
    },
  })
}
