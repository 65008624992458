import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Role } from '@/api/dashboard'
import { HOME_PATH, useGenerateCompanyPath } from '@/libs'
import { AghanimLogotype } from '@/components/ui'
import { GetStartedCallout } from '@/layouts/get-started'
import { SidebarCompanySwitch } from './SidebarCompanySwitch'
import { SidebarGameSwitch } from './SidebarGameSwitch'
import { SidebarLink } from './SidebarLink'
import { SidebarSection } from './SidebarSection'
import { getFooterItems, getHubItems, getPrimaryItems } from './MenuData'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { MenuList } from './MenuList'
import { Dashboard } from './icons'
import { CreateGameModalConfirm } from './CreateGameModalConfirm'
import { useBanner } from '@/libs/hooks/useBanner'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { useHubGeneratingStatus } from '@/layouts/get-started/hooks'
import { useCurrentUser } from '@/api/useCurrentUser'
import { DashboardAccessLevel } from '@/api/DashboardAccessLevel'
import { XClose, cn, useIsMobile, useModal } from '@dashboard/ui'
import { MobileContext } from '@/providers'

export const AppSidebar = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { data: dataCompanies } = useCompaniesQuery()
  const { bannerMode } = useBanner()
  const { t, ready } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()
  const { data: settings } = useGameSettingsQuery(companyId, gameId)
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const { customer, hasFeatureAccess, isAdmin } = useCurrentUser()

  const defaultWebsiteUrl = websites.length ? websites[0]?.url : undefined
  const navigate = useNavigate()
  const { isHubGenerated } = useHubGeneratingStatus()

  const primaryItems = useMemo(() => {
    return getPrimaryItems(customer, generatePath, t, hasFeatureAccess)
  }, [settings, customer, gameId, ready, hasFeatureAccess])

  const hubItems = useMemo(() => {
    return getHubItems(customer, generatePath, t, defaultWebsiteUrl, !isHubGenerated)
  }, [settings, customer, gameId, defaultWebsiteUrl, isHubGenerated, ready])

  const footerItems = useMemo(() => {
    return getFooterItems(customer, generatePath, t)
  }, [settings, customer, gameId, ready])

  const openCreateGameModal = useModal(props => <CreateGameModalConfirm {...props} />)

  const onAddGameClick = () => {
    let companies = dataCompanies?.data || []
    companies = companies.filter(it => it.role == Role.Admin)
    setLeftOpened(false)

    if (companies.length == 0) {
      navigate('/onboarding', { state: { company_id: null } })
    } else {
      openCreateGameModal()
    }
  }

  const isMobile = useIsMobile()
  const { setLeftOpened } = useContext(MobileContext)

  const handleMenuClose = () => {
    setLeftOpened(false)
  }

  return (
    <nav className="flex h-full flex-col justify-between overflow-y-auto bg-linear-bg-gradient py-3">
      <section className="flex flex-col">
        {isMobile && (
          <div className={cn('mb-4 ml-3 flex', bannerMode === 'demo' || bannerMode === 'test' ? 'mt-6' : '')}>
            <XClose className="cursor-pointer text-text-secondary" size={32} onClick={handleMenuClose} />
          </div>
        )}
        <div className="mx-3 mb-3 flex items-center gap-2.5">
          <div className="shrink-0">
            <AghanimLogotype />
          </div>
          <SidebarGameSwitch onAddGameClick={() => onAddGameClick()} />
        </div>

        {isAdmin && !bannerMode && customer?.mode != DashboardAccessLevel.BLOCKED && <GetStartedCallout />}

        <SidebarSection>
          <SidebarLink to={generatePath(HOME_PATH)} end={true} icon={Dashboard}>
            {t('sidebar.home')}
          </SidebarLink>

          <MenuList items={primaryItems} />
        </SidebarSection>

        {hubItems.length > 0 && (
          <SidebarSection label={t('sidebar.section.hub')}>
            <MenuList items={hubItems} />
          </SidebarSection>
        )}
      </section>

      <SidebarSection>
        <MenuList items={footerItems} />
        <div className="mx-3 my-2.5 h-px bg-border-secondary" />
        <SidebarCompanySwitch />
      </SidebarSection>
    </nav>
  )
}
