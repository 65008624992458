import { ReactNode, forwardRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import {
  MENU_ITEMS_OPTIMIZED_THRESHOLD,
  Menu,
  MenuGroup,
  MenuItemCheckbox,
  MenuItemDivider,
  MenuSearch,
} from '../Menu';
import { PopperMenuProps } from '../Popper';
import { useSelectSearch } from '../Select/useSelectSearch';
import { SelectFilterBaseProps, SelectFilterMultipleProps, SelectFilterParams } from './SelectFilter';
import { SelectFilterGroup, SelectFilterItem } from './types';
import { cn } from '../../libs';

export interface SelectFilterMultipleMenuProps
  extends PopperMenuProps,
    Pick<SelectFilterBaseProps, 'items' | 'groups' | 'title'>,
    Pick<SelectFilterMultipleProps, 'multiple' | 'value' | 'onChange'> {
  params: SelectFilterParams;
  className?: string;
  footer?: ReactNode;
}

interface GroupWithItems extends SelectFilterGroup {
  items: SelectFilterItem[];
}

export const SelectFilterMultipleMenu = forwardRef<HTMLDivElement, SelectFilterMultipleMenuProps>(
  ({ value, items, groups, title, onChange, params, ...rest }, ref) => {
    const { t } = useTranslation('ui');

    const { filteredItems, searchInput, hasQuery, reset } = useSelectSearch(
      {
        fn: (query, item) => {
          const itemGroup = query.length
            ? // eslint-disable-next-line @typescript-eslint/no-base-to-string
              groups?.find(group => group.label?.toString().toLowerCase().includes(query.toLowerCase()))
            : null;

          return (
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            item.children?.toString().toLowerCase().includes(query.toLowerCase()) ||
            item.value?.toString().toLowerCase().includes(query.toLowerCase()) ||
            itemGroup?.values.some(value => value === item.value) ||
            false
          );
        },
        placeholder: 'Search...',
      },
      items,
    );

    const needOptimization = useMemo(() => items.length > MENU_ITEMS_OPTIMIZED_THRESHOLD, [items.length]);

    const isAllItemsChecked = useMemo(() => items.every(item => value.includes(item.value)), [items, value]);

    const groupedItems: GroupWithItems[] = useMemo(() => {
      return groups
        ? groups.map(group => ({
            ...group,
            items: filteredItems.filter(item => group.values.includes(item.value)),
          }))
        : [];
    }, [filteredItems, groups]);

    const ungroupedItems = useMemo(
      () => filteredItems.filter(item => !groups?.some(group => group.values.includes(item.value))),
      [filteredItems, groups],
    );

    const onClickSelectAll = useCallback(() => {
      if (isAllItemsChecked) {
        onChange([]);
      } else {
        onChange(items.map(item => item.value));
      }
    }, [isAllItemsChecked, items, onChange]);

    const onClickMenuItem = useCallback(
      (itemValue: SelectFilterItem['value']) => {
        const newValue = value.includes(itemValue) ? value.filter(v => v !== itemValue) : [...value, itemValue];
        onChange(newValue);
        reset(hasQuery);
      },
      [value, onChange, reset, hasQuery],
    );

    const onClickGroupCheckbox = useCallback(
      (group: GroupWithItems) => {
        const allItemsChecked = group.items.every(item => value.includes(item.value));

        const newValue = allItemsChecked
          ? value.filter(item => !group.items.map(item => item.value).includes(item))
          : [...value, ...group.items.map(item => item.value)];
        onChange(newValue);

        reset(hasQuery);
      },
      [value, onChange, reset, hasQuery],
    );

    const renderGroup = (group: GroupWithItems) => {
      const allItemsChecked = group.items.every(item => value.includes(item.value));

      if (!group.items.length) {
        return null;
      }

      return (
        <MenuGroup
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          key={group.label?.toString()}
          label={group.label}
          variant={group.variant}
          state={{ checked: allItemsChecked, onClick: () => onClickGroupCheckbox(group) }}
        >
          {group.items.map(({ children, value: itemValue, ...item }) => (
            <MenuItemCheckbox
              {...item}
              key={`menu-item-${itemValue}`}
              optimized={needOptimization}
              checked={value.includes(itemValue)}
              onClick={() => onClickMenuItem(itemValue)}
            >
              {children}
            </MenuItemCheckbox>
          ))}
        </MenuGroup>
      );
    };

    return (
      <Menu ref={ref} {...rest}>
        {title && (
          <div className="mb-2.5 flex items-center justify-between">
            <div className="text-caption-md font-semibold">{title}</div>
            <Button tabIndex={-1} variant="link" onClick={onClickSelectAll}>
              {t(isAllItemsChecked ? 'select-filter.multiple.clear-all' : 'select-filter.multiple.select-all')}
            </Button>
          </div>
        )}

        {params?.search && <MenuSearch {...searchInput} />}

        <div id="list-results" role="listbox" className={cn('w-full', params.size === 'lg' && 'w-80')}>
          {groupedItems.map(renderGroup)}
          {groupedItems.length && ungroupedItems.length ? <MenuItemDivider /> : null}
          {ungroupedItems.map(({ children, value: itemValue, ...item }) => (
            <MenuItemCheckbox
              {...item}
              key={`menu-item-${itemValue}`}
              optimized={needOptimization}
              checked={value.includes(itemValue)}
              onClick={() => onClickMenuItem(itemValue)}
            >
              {children}
            </MenuItemCheckbox>
          ))}
        </div>
      </Menu>
    );
  },
);
