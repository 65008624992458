import { ButtonIcon } from '@/ui'
import { DeleteOutline } from '@/icons'
import { cn } from '@dashboard/ui'
import { Pack } from '@/api/dashboard'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { useTranslation } from 'react-i18next'

interface DailyRewardsDayFrameProps {
  num: number
  pack: Pack
  onDelete: () => void
  onEdit: () => void
}

export const PackItem = ({ num, pack, onDelete, onEdit }: DailyRewardsDayFrameProps) => {
  const { t } = useTranslation('offers')
  return (
    <div className="flex w-full gap-1.5">
      <div
        className={cn(
          'flex min-h-14 w-[76px] flex-col items-center justify-center ',
          'rounded-xl p-1.5',
          'bg-fg-secondary',
        )}
      >
        <span className="text-paragraph-sm font-semibold text-text-secondary">{num}</span>
        <span className="text-caption-sm font-medium text-text-quarterary-hover">{t('Pack')}</span>
      </div>
      <div
        className="flex grow cursor-pointer items-center gap-3 rounded-xl border border-border-primary p-3 hover:bg-fg-primary-alt"
        onClick={onEdit}
      >
        <div
          className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${pack.image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
        <div className="text-text-secondary">
          <div>
            {t('pack.base_quantity.short')} <span className="font-medium text-text-primary">{pack.base_quantity} </span>
          </div>
          <div>
            {t('pack.max_purchase.short')} <span className="font-medium text-text-primary">{pack.max_purchase} </span>
          </div>
        </div>
        <ButtonIcon
          className="ml-auto"
          variant="tertiary-destructive"
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <DeleteOutline />
        </ButtonIcon>
      </div>
    </div>
  )
}
