import { dashboardClient } from '@/api'
import { queryOptions } from '@tanstack/react-query'

export const getPriceTemplateDetailsOptions = (companyId: string, gameId: string, priceTemplateId: string) =>
  queryOptions({
    queryKey: [gameId, 'sku-price-templates', priceTemplateId],
    queryFn: async () => {
      const { data } = await dashboardClient.v1.getPriceTemplate(companyId, gameId, priceTemplateId)
      return data
    },
  })
