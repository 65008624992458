import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const XCircleFill: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0.75C4.44365 0.75 0.75 4.44365 0.75 9C0.75 13.5563 4.44365 17.25 9 17.25C13.5563 17.25 17.25 13.5563 17.25 9C17.25 4.44365 13.5563 0.75 9 0.75ZM11.7803 6.21967C12.0732 6.51256 12.0732 6.98744 11.7803 7.28033L10.0607 9L11.7803 10.7197C12.0732 11.0126 12.0732 11.4874 11.7803 11.7803C11.4874 12.0732 11.0126 12.0732 10.7197 11.7803L9 10.0607L7.28033 11.7803C6.98744 12.0732 6.51256 12.0732 6.21967 11.7803C5.92678 11.4874 5.92678 11.0126 6.21967 10.7197L7.93934 9L6.21967 7.28033C5.92678 6.98744 5.92678 6.51256 6.21967 6.21967C6.51256 5.92678 6.98744 5.92678 7.28033 6.21967L9 7.93934L10.7197 6.21967C11.0126 5.92678 11.4874 5.92678 11.7803 6.21967Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

XCircleFill.type = 'aghanim-icon';
