import i18next from 'i18next'
import { cn, useIsMobile } from '@dashboard/ui'
import AnimatedDots from '../../../../components/animated-dots/AnimatedDots'
import { MeasureValue } from '../../../../api/dashboard'
import { formatMoney, formatNumber } from '../../../../util'
import { ArrowsUpIcon } from '../../icons/ArrowsUpIcon'
import { ArrowsDownIcon } from '../../icons/ArrowsDownIcon'
import { TooltipAlertCircle } from '@/ui'

export enum SingleValueType {
  value,
  money,
  percent,
}

export default function SingleValue(props: {
  value: MeasureValue | undefined
  label: string
  width?: string
  tooltip?: string
  type?: SingleValueType
}) {
  let percentChange = 0
  const isMobile = useIsMobile()
  if (props.value && props.value.prev_value) {
    if (props.value.prev_value) {
      percentChange = (100 * (props.value.value - props.value.prev_value)) / props.value.prev_value
    } else {
      percentChange = 100
    }
  }

  let valueStr: JSX.Element | string = <AnimatedDots />
  if (props.value) {
    switch (props.type) {
      case SingleValueType.money:
        valueStr = formatMoney(props.value.value) as string
        break
      case SingleValueType.percent:
        valueStr = props.value.value.toFixed(2) + '%'
        break
      case SingleValueType.value:
      default:
        valueStr = formatNumber(props.value.value)
        break
    }
  }

  return (
    <div className="flex w-[240px] flex-col gap-2 overflow-hidden whitespace-nowrap">
      {props.label && (
        <div className={cn('inline-flex items-center gap-1 text-[16px] font-medium leading-7 text-text-disabled')}>
          {props.label}
          {props.tooltip && <TooltipAlertCircle message={props.tooltip} />}
        </div>
      )}
      <div style={{ height: props.value?.prev_value ? '50px' : '' }}>
        <div className={cn('ph-no-capture text-[24px] font-semibold')}>{valueStr}</div>
        {props.value?.prev_value != undefined && (
          <div className="mt-[4px] flex items-center gap-1">
            <div className="flex items-center">
              <div className="w-[15px]">
                {percentChange >= 0 ? (
                  <ArrowsUpIcon className="text-text-success-secondary" size={10} />
                ) : (
                  <ArrowsDownIcon className="text-text-error-primary" size={10} />
                )}
              </div>

              <div
                className={cn(
                  'ph-no-capture text-[14px] font-medium',
                  percentChange >= 0 ? 'text-text-success-secondary' : 'text-text-error-primary',
                )}
              >
                {percentChange.toFixed(2) + '%'}
              </div>
            </div>

            {!isMobile && (
              <div className="w-[200px] truncate" title={i18next.t('dashboard.vs-prev')}>
                {i18next.t('dashboard.vs-prev')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
