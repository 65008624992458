import { Controller, useFormContext } from 'react-hook-form'
import { Block } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { FieldGroup } from '@/ui'
import { FormErrorMessage, Select } from '@dashboard/ui'
import { useGenerateCompanyPath } from '@/libs'
import { DEFAULT_LOAD_LIMIT } from '@/api/useGameItems'
import { ErrorMessage } from '@hookform/error-message'
import { useRangeOffersQuery } from '@/layouts/offers/range-api'

export const RangeOfferBlockEditor = () => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()

  const { data: items = [] } = useRangeOffersQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
  })

  const {
    control,
    formState: { errors },
  } = useFormContext<Block>()

  return (
    <FieldGroup label={t('hub-editor.select-offer')}>
      <Controller
        control={control}
        name="range_offer_id"
        rules={{ required: t('validation.required') }}
        render={({ field }) => (
          <FieldGroup>
            <Select
              {...field}
              items={items.map(it => ({
                children: it.name,
                value: it.id,
              }))}
            />
          </FieldGroup>
        )}
      />
      <ErrorMessage
        name="range_offer_id"
        errors={errors}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FieldGroup>
  )
}
