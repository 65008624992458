import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query'
import { dashboardClient, dashboardClientV1 } from './index'
import { AghanimGameSchemasGame, CompanyGame, HttpResponse, HTTPValidationError } from './dashboard'
import { HUB_API } from '../HubAPI'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { getPinnedGamesGlobal } from '@/api/pinGame'

export const useCompaniesQuery = () => {
  return useQuery({
    queryKey: ['companies'],
    // select: response => {
    //   console.log('xxx', response.error)
    //   return response.data
    // },
    queryFn: async () => {
      if (!HUB_API.getToken()) {
        return new Promise(resolve => {
          resolve({ data: null })
        }) as unknown as Promise<HttpResponse<CompanyGame[], HTTPValidationError>>
      }

      try {
        let customerGamesResponse = await dashboardClient.v1.getCompanyGames()

        let pinnedGamesIds = getPinnedGamesGlobal()

        if (!pinnedGamesIds.length) {
          return customerGamesResponse
        }

        const loadedGames = customerGamesResponse.data.map(it => it.games).flat()
        const notLoadedPinnedGamesIds = pinnedGamesIds.filter(id => !loadedGames.find(g => g!.id == id))
        if (!notLoadedPinnedGamesIds.length) {
          return customerGamesResponse
        }

        let { data: pinnedCompanyGames } = await dashboardClient.v1.getCompanyGames({
          ids: notLoadedPinnedGamesIds.join(','),
        })

        if (!pinnedCompanyGames.length) {
          return customerGamesResponse
        }

        pinnedCompanyGames = pinnedCompanyGames.filter(c => customerGamesResponse.data.find(it => it.id != c.id))

        return {
          data: [...customerGamesResponse.data, ...pinnedCompanyGames].sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          ),
          error: null,
        } as unknown as HttpResponse<CompanyGame[], HTTPValidationError>
      } catch (e) {
        return {
          data: null,
          error: e,
        } as unknown as HttpResponse<CompanyGame[], HTTPValidationError>
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}

export const loadCompanyAndGame = async (queryClient: QueryClient, companyId: string, gameId: string) => {
  try {
    let { data: company } = await dashboardClientV1.v1.getCompany(companyId)
    let { data: games } = await dashboardClient.v1.getGames(companyId, { limit: 100 })
    addCompanyToCache(queryClient, {
      ...company,
      games: games,
    })

    return games.find(game => game.id === gameId)
  } catch (e) {
    //not found
    return null
  }
}

export const addCompanyToCache = (
  queryClient: QueryClient,
  newCompany: CompanyGame,
  clearInfinitiQueryCache = true,
) => {
  queryClient.setQueryData(['companies'], (prev: { data: CompanyGame[] }) => {
    let existCompany = prev.data?.find(company => company.id === newCompany.id)

    if (existCompany) {
      let update = { ...existCompany, ...newCompany }
      let games = existCompany.games || []

      if (newCompany.games?.length) {
        for (let g of newCompany.games) {
          if (!games.find(game => game.id === g.id)) {
            games.push(g)
          }
        }
      }

      update.games = games

      return { ...prev, data: prev.data.map(company => (company.id === newCompany.id ? update : company)) }
    }

    return {
      ...prev,
      data: [...(prev.data || []), newCompany],
    }
  })

  if (clearInfinitiQueryCache) {
    queryClient.invalidateQueries({ queryKey: ['all_companies'], exact: false })
    queryClient.refetchQueries({ queryKey: ['all_companies'] })
  }
}

export const updateGameInCache = (queryClient: QueryClient, newGame: AghanimGameSchemasGame) => {
  queryClient.setQueryData(['companies'], (prev: { data: CompanyGame[] }) => {
    let existCompany = prev.data?.find(company => company.id === newGame.company_id)

    if (existCompany?.games) {
      existCompany.games = existCompany.games.map(game => (game.id === newGame.id ? newGame : game))
    }

    return {
      ...prev,
      data: prev.data,
    }
  })
}

export const useCurrentGame = () => {
  const queryClient = useQueryClient()
  const { gameId, companyId } = useParams() as { companyId: string; gameId: string }
  const context = useContext(AuthContext) as IAuthContext
  const companiesData = queryClient.getQueryData(['companies']) as { data: CompanyGame[] }

  const company = companiesData?.data?.find(it => it.id == companyId)
  const game = company?.games?.find(it => it.id == gameId)

  const getKey = (gameId: string) => {
    const cId = context?.customer?.id || ''
    return `sandbox-${gameId}-${cId}`
  }

  const getSandboxGame = () => {
    return company?.games?.find(it => it.live_game_id == gameId)
  }

  const getSandboxGameState = (gameId: string) => {
    return !!localStorage.getItem(getKey(gameId))
  }

  const setSandboxGameState = (gameId: string, sandbox: boolean) => {
    if (sandbox) {
      localStorage.setItem(getKey(gameId), 'true')
    } else {
      localStorage.removeItem(getKey(gameId))
    }
  }

  return { game, company, getSandboxGame, getSandboxGameState, setSandboxGameState }
}
