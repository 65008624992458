import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import { Item } from '@/api/dashboard'
import { itemCategoriesQuery } from '@/layouts/item-category/api'
import { useItemBulkUpdateMutate } from '../api'
import { Button, FormErrorMessage, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'
import { FieldGroup, SelectMulti } from '@/ui'
import { useGenerateCompanyPath } from '@/libs'

interface BulkEditCategoryModalProps extends ModalProps {
  items: Item[]
  onResetItems?: (items: string[]) => void
}

interface BulkEditCategoryData {
  categories: string[] | undefined
}

export const BulkEditCategoryModal = ({ items, onResetItems, ...rest }: BulkEditCategoryModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { mutateAsync } = useItemBulkUpdateMutate(companyId, gameId, items[0].type)

  const { data: categories = [] } = useQuery({
    ...itemCategoriesQuery(companyId, gameId, { limit: 100 }),
  })

  const {
    formState: { isSubmitting, errors },
    control,
    handleSubmit,
  } = useForm<BulkEditCategoryData>({
    values: {
      categories: items[0].categories,
    },
  })

  const onSubmit = async (data: BulkEditCategoryData) => {
    await mutateAsync({ data: items.map(item => ({ id: item.id, categories: data.categories })) })
    rest.onClose?.()
    onResetItems?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <ModalTitle>{t('sku.bulk-edit-category.title', { count: items.length })}</ModalTitle>

          <div className="mb-6">
            <p className="text-paragraph-md text-text-secondary">
              {t('sku.bulk-edit-category.desc', { count: items.length })}
            </p>
          </div>

          <FieldGroup label={t('sku.bulk-edit-category.select.label')}>
            <Controller
              control={control}
              name="categories"
              render={({ field }) => (
                // <Select
                //   {...field}
                //   disabled={isLoading}
                //   placeholder={t('sku.bulk-edit-category.select.placeholder')}
                //   items={categories.map(category => ({ children: category.name, value: category.id }))}
                // />
                //
                <SelectMulti
                  items={categories.map(item => ({
                    children: item.name,
                    value: item.id,
                  }))}
                  value={field.value || []}
                  onChange={v => {
                    field.onChange(v)
                  }}
                  placeholder={t('sku.bulk-edit-category.select.placeholder')}
                />
              )}
            />
            <ErrorMessage
              name="category"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </ModalContent>

        <ModalFooter>
          <Button type="button" size="sm" onClick={rest.onClose} variant="outline" color="secondary">
            {t('sku.bulk-edit-category.cancel')}
          </Button>
          <Button type="submit" size="sm" loading={isSubmitting}>
            {t('sku.bulk-edit-category.submit')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
