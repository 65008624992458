import { useQuery } from '@tanstack/react-query'
import { ExpertFeatures, ExpertHub, ExpertLanguages, ExpertSocial, ExpertSocialNetworks, ParamsType } from '../types'
import { ExpertRead, ExpertReadSimpified } from '../interfaces'
import { uuid4 } from '@/util'

const defaultAvatarURL = 'https://static-platform.aghanim.com/sku/15dc65f5-0fd2-4feb-a201-c6c355185cca.webp'

async function getExpertsList(): Promise<ExpertRead[]> {
  const SHEETDB_API_URL = import.meta.env.VITE_EXPERTS_GOOGLE_SHEET_FILE
  if (!SHEETDB_API_URL) {
    return []
  }
  const data: ExpertReadSimpified[] = await fetch(SHEETDB_API_URL).then(res => res.json())
  return data.map((item: ExpertReadSimpified) => {
    return {
      id: uuid4(),
      name: item.name || '',
      type: item.type || 'personal',
      description: item.description || '',
      certification: item.certification || '',
      avatar_url: item.avatar_url || defaultAvatarURL,
      link_to_connect: item.link_to_connect || null,
      location: {
        country: item.location_country || '',
        city: item.location_city || '',
      },
      available_languages: item.available_languages.split(',').map((l: string) => {
        return l as ExpertLanguages
      }),
      features: item.features.split(',').map(feature => {
        return feature as ExpertFeatures
      }),
      hubs: item.hubs.split('\n').reduce((result: ExpertHub[], hub) => {
        const [link, thumbinail_url] = hub.split('|')
        if (link && thumbinail_url) {
          result.push({
            thumbinail_url: thumbinail_url,
            link: link,
          } as ExpertHub)
        }
        return result
      }, []),
      social: item.social.split('\n').reduce((result: ExpertSocial[], social) => {
        const splitted = social.split('|')
        result.push({
          type: splitted[0] as ExpertSocialNetworks,
          link: splitted[1],
        } as ExpertSocial)
        return result
      }, []),
    }
  })
}

function filter(data: ExpertRead[], query: ParamsType) {
  return data.filter(item => {
    let isValid = true
    if (query.type && item.type !== query.type && item) {
      isValid = false
    }
    if (query.certification && item.certification !== query.certification && item) {
      isValid = false
    }
    if (query.languages && item.available_languages.indexOf(query.languages) === -1) {
      isValid = false
    }
    if (
      query.features &&
      query.features?.split(',').filter(ft => item.features.includes(ft as ExpertFeatures)).length <
        query.features?.split(',').length
    ) {
      isValid = false
    }
    if (
      query.q &&
      !item.name.toLowerCase().includes(query.q.toLowerCase()) &&
      !item.description.toLowerCase().includes(query.q.toLowerCase()) &&
      !item.location.country.toLowerCase().includes(query.q.toLowerCase()) &&
      !item.location.city.toLowerCase().includes(query.q.toLowerCase())
    ) {
      isValid = false
    }
    return isValid
  })
}

export const useExpertsQuery = (params: ParamsType) => {
  return useQuery<ExpertRead[]>({
    queryKey: ['experts', params],
    queryFn: () => getExpertsList().then(data => filter(data, params)),
    refetchOnMount: true,
  })
}
