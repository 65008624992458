import UserAvatar from '@/components/UserAvatar'
import { LocationIcon } from './icons/LocationIcon'
import { ExpertRead } from '../interfaces'
import { LanguageIcon } from './icons/LanguageIcon'
import { ArrowUpRight, Button, cn, useIsMobile } from '@dashboard/ui'
import { Badge } from '@/ui'
import { t } from 'i18next'
import { BehanceIcon, DribbleIcon, LinkedinIcon, XIcon } from './icons/social'
import { VerifiedIcon } from './icons/VerifiedIcon'
import { useState } from 'react'
import { ExpertFeatures, ExpertLanguages, ExpertSocialNetworks } from '../types'
import { Tooltip } from '@/components/ui'

const skillsInRow = 3

const SocialLinks = (props: { expert: ExpertRead }) =>
  props.expert.social.map(social => (
    <a
      key={`expert-${props.expert.id}-social-${social.type}`}
      className="size-[20px]"
      href={social.link}
      target="_blank"
    >
      <SocialIcon type={social.type} />
    </a>
  ))

const SocialIcon = (props: { type: ExpertSocialNetworks }) => {
  switch (props.type) {
    case 'linkedin':
      return <LinkedinIcon color={'#000'} size={20} />
    case 'x':
      return <XIcon color={'#000'} size={20} />
    case 'dribble':
      return <DribbleIcon color={'#000'} size={20} />
    case 'behance':
      return <BehanceIcon color={'#000'} size={20} />
  }
}

function getBadgeVariant(feature: ExpertFeatures) {
  switch (feature) {
    case 'design':
      return 'violet-secondary'
    case 'marketing':
      return 'brand-pink-tertiary'
    case 'integration':
      return 'red-tertiary'
    case 'migration':
      return 'lime-tertiary'
    case 'customization':
      return 'gray-tertiary'
    case 'development':
      return 'brand-blue-tertiary'
    case 'monetization':
      return 'green-tertiary'
    case '3rd-party-integrations':
      return 'gray-tertiary'
  }
}

export const ExpertRow = (props: { expert: ExpertRead; setFeatureFilter: (value: ExpertFeatures) => void }) => {
  const { expert, setFeatureFilter } = props
  const [skillsExpanded, setSkillsExpanded] = useState<boolean>(false)
  // Take only 3 first hubs to show iin card
  const slicedHubs = expert.hubs.slice(0, 3)
  const isMobile = useIsMobile()
  return (
    <div className="flex flex-col rounded-2xl1 border border-border-primary bg-fg-primary p-3 shadow-xs hover:border-border-primary-hover">
      <div className={isMobile ? 'mb-4 flex flex-col gap-3' : 'flex flex-row items-center justify-between gap-3 pb-3'}>
        <div className="flex flex-row items-center gap-3">
          {expert.type === 'individual' ? (
            <UserAvatar url={expert.avatar_url} size={'48px'} />
          ) : (
            <div
              className="aspect-[1/1] size-[48px] rounded-md bg-cover bg-center bg-no-repeat"
              style={{ backgroundImage: `url(${expert.avatar_url})` }}
            />
          )}
          <div>
            <h1 className="flex flex-row items-baseline gap-2 text-text-secondary hover:text-text-secondary-hover">
              <span className="font-nohemi text-[16px]">{expert.name}</span>
              {expert.certification !== '' && (
                <Tooltip message={expert.certification === 'expert' ? 'Certified Expert Partner' : 'Certified Partner'}>
                  <VerifiedIcon size={16} color={expert.certification === 'expert' ? '#FF6CAF' : '#0097E4'} />
                </Tooltip>
              )}
            </h1>
            <div className="flex flex-row flex-wrap gap-1 text-xs text-text-tertiary hover:text-text-tertiary-hover ">
              <p className="mr-3 flex flex-row items-center">
                {expert.location.country !== '' && expert.location.city !== '' && (
                  <>
                    <div className="size-[15px]">
                      <LocationIcon width={20} />
                    </div>
                    {expert.location.country}, {expert.location.city}
                  </>
                )}
              </p>
              {expert.available_languages.length && (
                <p className="flex flex-row items-center">
                  <div className="size-[15px]">
                    <LanguageIcon size={14} width={20} />
                  </div>
                  {expert.available_languages &&
                    expert.available_languages.map((locale: ExpertLanguages) => t('locales.' + locale)).join(', ')}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className={cn('flex flex-row items-center gap-3')}>
          <SocialLinks expert={expert} />
          {expert.link_to_connect && (
            <a href={expert.link_to_connect} target="_blank">
              <Button variant="outline">{t('experts.actions.get-in-touch')}</Button>
            </a>
          )}
        </div>
      </div>

      {expert.hubs && (
        <section className="mb-4 grid grid-cols-3 gap-4">
          {slicedHubs.map((hub, index: number) => (
            <div
              key={`expert-${expert.id}-hub-${index}`}
              className="relative aspect-[4/3] overflow-hidden rounded-md border-border-primary hover:border-border-primary-hover"
            >
              <div
                className="size-full bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${hub.thumbinail_url})`,
                }}
              >
                <a
                  href={hub.link}
                  target="_blank"
                  className="absolute left-0 top-0 z-10 size-full bg-black/0 opacity-0 transition-opacity duration-300 hover:bg-black/40 hover:opacity-100"
                >
                  <span className="absolute bottom-1 right-2 z-20 flex flex-row items-center text-white">
                    {t('experts.open-game-hub')} <ArrowUpRight />
                  </span>
                </a>
              </div>
            </div>
          ))}
        </section>
      )}
      {expert.features && (
        <section className="flex flex-row flex-wrap gap-2">
          {expert.features.map((feature: ExpertFeatures, index: number) => (
            <Badge
              className={cn('cursor-pointer', index < skillsInRow ? '' : skillsExpanded ? 'block' : 'hidden')}
              variant={getBadgeVariant(feature)}
              key={`expert-${expert.id}-features-${feature}`}
              size={'sm'}
              onClick={() => setFeatureFilter(feature)}
            >
              {t(`experts.feature.${feature}`)}
            </Badge>
          ))}
          {expert.features.length > skillsInRow && (
            <Badge
              onClick={() => setSkillsExpanded(!skillsExpanded)}
              className="cursor-pointer"
              size={'sm'}
              variant={'gray-tertiary'}
            >
              {skillsExpanded
                ? t('experts.fetures.less')
                : `+ ${expert.features.length - skillsInRow} ${t('experts.fetures.more')}`}
            </Badge>
          )}
        </section>
      )}
    </div>
  )
}
