import { ButtonHTMLAttributes, ReactNode, forwardRef, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import shouldForwardProp from '@emotion/is-prop-valid'
import { Spinner } from '@/components/ui'
import { cn } from '@/libs'

export type ButtonVariant =
  | 'primary'
  | 'outline'
  | 'error'
  | 'text'
  | 'text-brand'
  | 'secondary'
  | 'secondary-brand'
  | 'tertiary-gray'
  | 'tertiary-destructive'
  | 'tertiary-gray-dashed'

type ButtonSize = 'xl' | 'lg' | 'md' | 'sm'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  variant?: ButtonVariant
  size?: ButtonSize
  isLoading?: boolean
}

const StyledButton = styled('button', { shouldForwardProp })<{ $variant: ButtonVariant; $size: ButtonSize }>`
  position: relative;
  border-radius: 6px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  font-family: 'InterAg', serif;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.07px;

  ${p =>
    p.$variant === 'primary' &&
    css`
      background-color: #0ea5e9;
      border-color: transparent;
      color: #ffffff;

      &:hover {
        background-color: #0284c7;
      }

      &:active {
        background-color: #0ea5e9;
        box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
      }

      &[disabled] {
        background-color: ${p.theme.palette.background.fgskeleton};
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'error' &&
    css`
      background-color: #ef4444;
      border-color: transparent;
      color: #ffffff;

      &:hover {
        background-color: #b91c1c;
      }

      &:active {
        background-color: #991b1b;
        box-shadow: 0 0 0 3px #f0443824;
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'outline' &&
    css`
      background-color: #ffffff;
      border-color: ${p.theme.palette.grey.borderPrimary};
      color: #475569;

      &:hover {
        background-color: ${p.theme.palette.background.fgSecondary};
      }

      &:active {
        background-color: ${p.theme.palette.background.primary};
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'secondary' &&
    css`
      background-color: transparent;
      border-color: ${p.theme.palette.primary.main};
      color: ${p.theme.palette.text.primary};

      &:hover:not([disabled]) {
        background-color: #38bdf8;
        color: #ffffff;
        border-color: transparent;
      }

      &:active:not([disabled]) {
        background-color: #7dd3fc;
        color: #ffffff;
        border-color: transparent;
      }

      &[disabled] {
        color: ${p.theme.palette.text.disabled};
        border-color: #e2e8f0;
      }
    `}

  ${p =>
    p.$variant === 'secondary-brand' &&
    css`
      background-color: #fff;
      border-color: #0ea5e9;
      color: #000000;

      &:hover:not([disabled]) {
        background-color: #38bdf8;
        color: #ffffff;
        border-color: transparent;
      }

      &:active:not([disabled]) {
        background-color: #7dd3fc;
        color: #ffffff;
        border-color: transparent;
      }

      &[disabled] {
        color: ${p.theme.palette.text.disabled};
        border-color: #e2e8f0;
      }
    `}

  ${p =>
    p.$variant === 'text' &&
    css`
      color: ${p.theme.palette.text.primary};

      &:hover {
        background-color: ${p.theme.palette.background.fgSecondary};
      }

      &:active {
        background-color: ${p.theme.palette.background.primary};
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'text-brand' &&
    css`
      color: #0ea5e9;

      &:hover {
        background-color: ${p.theme.palette.background.fgSecondary};
      }

      &:active {
        background-color: ${p.theme.palette.background.primary};
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'error' &&
    css`
      background-color: #ef4444;
      border-color: transparent;
      color: #ffffff;

      &:hover {
        background-color: #b91c1c;
      }

      &:active {
        background-color: #991b1b;
        box-shadow: 0 0 0 3px #f0443824;
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'tertiary-gray' &&
    css`
      background-color: transparent;
      border-color: transparent;
      color: #64748b;

      &:hover {
        border-color: #e2e8f0;
      }

      &:active {
        background-color: #f1f5f9;
        border-color: #e2e8f0;
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'tertiary-gray-dashed' &&
    css`
      background-color: transparent;
      border-style: dashed;
      border-color: #e2e8f0;
      color: #64748b;

      &:hover {
        border-color: #e2e8f0;
      }

      &:active {
        background-color: #f1f5f9;
        border-color: #e2e8f0;
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$variant === 'tertiary-destructive' &&
    css`
      background-color: transparent;
      border-color: transparent;
      color: #dc2626;

      &:hover {
        border-color: #f87171;
      }

      &:active {
        background-color: #fecaca;
        border-color: #f87171;
      }

      &[disabled] {
        background-color: transparent;
        color: ${p.theme.palette.text.disabled};
      }
    `}

  ${p =>
    p.$size === 'xl' &&
    css`
      padding: 0 24px;
      gap: 12px;
      height: 48px;
      font-size: 18px;
    `}

  ${p =>
    p.$size === 'lg' &&
    css`
      padding: 0 20px;
      gap: 10px;
      height: 40px;
      font-size: 16px;
    `}

  ${p =>
    p.$size === 'md' &&
    css`
      padding: 0 16px;
      gap: 8px;
      height: 32px;
      font-size: 14px;
    `}

  ${p =>
    p.$size === 'sm' &&
    css`
      padding: 0 16px;
      gap: 8px;
      height: 30px;
      font-size: 14px;
    `}
    
  & svg {
    flex-shrink: 0;
  }

  &[disabled] {
    pointer-events: none;
  }
`

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  // TODO: change to primary after complete component @ds.pankov
  { children, variant = 'outline', size = 'md', isLoading, ...rest },
  ref,
) {
  const [clicked, setClicked] = useState(false)
  let sizeLoader = 'size-4'
  switch (size) {
    case 'xl':
      sizeLoader = 'size-6'
      break
    case 'lg':
      sizeLoader = 'size-5'
      break
  }

  return (
    <StyledButton
      {...rest}
      ref={ref}
      className={cn('transition-colors', rest.className)}
      $variant={variant}
      $size={size}
      onClick={e => {
        if (isLoading || rest.disabled || clicked) {
          return
        }
        let res = rest.onClick?.(e) as unknown
        if (res) {
          if (res instanceof Promise) {
            setClicked(true)
            ;(res as Promise<unknown>).finally(() => {
              setClicked(false)
            })
          }
        }
      }}
    >
      {(isLoading || clicked) && (
        <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center">
          <Spinner className={sizeLoader} />
        </div>
      )}
      <div
        className={cn(
          'flex items-center justify-center gap-2 whitespace-nowrap',
          (isLoading || clicked) && 'opacity-0',
        )}
      >
        {children}
      </div>
    </StyledButton>
  )
})
