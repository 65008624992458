import { useEffect, useState } from 'react'
import { ItemRead, PriceCategory, PriceTemplateRead } from '@/api/dashboard'
import { dashboardClientV1 } from '@/api'
import { ModalRushProps } from '@/ui'
import { UseNavigateBackValue, useGenerateCompanyPath } from '@/libs'
import { PriceTemplateModal } from '../components'

interface PriceTemplateApplyModalProps extends ModalRushProps {
  back: UseNavigateBackValue
  item: ItemRead
  onSubmit: (price: number) => void
}

const getEmptyPriceTemplate = (defaultPrice: number): PriceTemplateRead => ({
  id: '',
  name: '',
  category: PriceCategory.None,
  price: defaultPrice,
  prices: [],
  created_at: new Date().getTime() / 100,
})

export const PriceTemplateApplyModal = ({ item, onSubmit, ...props }: PriceTemplateApplyModalProps) => {
  const { companyId, gameId } = useGenerateCompanyPath()

  const [emptyPriceTemplate, setEmptyPriceTemplate] = useState<PriceTemplateRead | null>(null)

  useEffect(() => {
    const empty = getEmptyPriceTemplate(item.price)

    dashboardClientV1.v1
      .getPrices(item.id, companyId, gameId)
      .then(({ data }) => {
        setEmptyPriceTemplate({
          ...empty,
          category: data.category,
          prices: data.prices.map(p => ({
            ...p,
            is_custom: !!p.is_custom,
          })),
        })
      })
      .catch(() => {
        dashboardClientV1.v1
          .getDefaultTemplatePrices(companyId, gameId, {
            price: Math.trunc(item.price) || undefined,
            category: empty.category,
          })
          .then(({ data: prices }) => {
            setEmptyPriceTemplate({
              ...empty,
              prices,
            })
          })
      })
  }, [companyId, gameId, item.id])

  const onFormSubmit = async (data: PriceTemplateRead) => {
    await dashboardClientV1.v1.createOrUpdatePrices(
      item.id,
      companyId,
      gameId,
      data.prices.filter(p => p.is_custom),
      { category: data.category },
    )
    onSubmit(data.price)
  }

  return <PriceTemplateModal {...props} template={emptyPriceTemplate} mode="apply" onSubmit={onFormSubmit} />
}
