import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const VerifiedIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 14 14" ref={ref}>
      <g clip-path="url(#clip0_10152_5387)">
        <path
          d="M10.811 2.97944C10.944 3.30101 11.1992 3.55662 11.5206 3.69007L12.6475 4.15685C12.9691 4.29006 13.2246 4.54557 13.3578 4.86717C13.491 5.18877 13.491 5.55011 13.3578 5.87171L12.8914 6.99781C12.7581 7.31955 12.7579 7.68126 12.8918 8.00284L13.3574 9.1286C13.4235 9.28789 13.4575 9.45862 13.4575 9.63105C13.4575 9.80349 13.4236 9.97423 13.3576 10.1335C13.2916 10.2928 13.1949 10.4376 13.0729 10.5595C12.951 10.6814 12.8062 10.7781 12.6469 10.844L11.5208 11.3105C11.1992 11.4434 10.9436 11.6987 10.8102 12.02L10.3434 13.1469C10.2102 13.4685 9.95466 13.724 9.63306 13.8572C9.31147 13.9905 8.95012 13.9905 8.62852 13.8572L7.50243 13.3908C7.18082 13.2579 6.8196 13.2582 6.4982 13.3916L5.37129 13.8577C5.04987 13.9906 4.68884 13.9905 4.3675 13.8574C4.04617 13.7243 3.7908 13.469 3.6575 13.1478L3.19058 12.0206C3.05762 11.699 2.80239 11.4434 2.48102 11.3099L1.35411 10.8431C1.03265 10.71 0.777222 10.4546 0.643962 10.1332C0.510701 9.81181 0.510521 9.45064 0.643459 9.12909L1.1099 8.00299C1.24279 7.68139 1.24251 7.32017 1.10915 6.99876L0.643374 5.87105C0.577337 5.71177 0.543333 5.54103 0.543305 5.3686C0.543278 5.19617 0.577227 5.02542 0.643213 4.86612C0.7092 4.70681 0.80593 4.56207 0.927876 4.44016C1.04982 4.31826 1.1946 4.22157 1.35392 4.15564L2.48002 3.6892C2.80131 3.55635 3.05677 3.30144 3.19031 2.98044L3.65709 1.85354C3.7903 1.53194 4.04581 1.27643 4.36741 1.14322C4.68901 1.01001 5.05036 1.01001 5.37196 1.14322L6.49805 1.60966C6.81966 1.74254 7.18087 1.74227 7.50228 1.6089L8.62966 1.14394C8.95121 1.0108 9.31248 1.01083 9.63402 1.14402C9.95555 1.2772 10.211 1.53264 10.3443 1.85415L10.8112 2.98139L10.811 2.97944Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.7416 5.66499C9.83513 5.51809 9.86646 5.34006 9.82872 5.17006C9.79098 5.00006 9.68725 4.85201 9.54035 4.75849C9.39346 4.66497 9.21543 4.63363 9.04542 4.67137C8.87542 4.70911 8.72738 4.81284 8.63385 4.95974L6.06398 8.99786L4.88798 7.52786C4.77926 7.39187 4.62097 7.30464 4.44793 7.28536C4.27489 7.26608 4.10128 7.31633 3.96529 7.42505C3.8293 7.53377 3.74207 7.69206 3.72279 7.8651C3.70351 8.03814 3.75376 8.21175 3.86248 8.34774L5.61248 10.5352C5.6777 10.6169 5.76146 10.6817 5.85681 10.7245C5.95216 10.7672 6.05633 10.7865 6.16065 10.7809C6.26498 10.7752 6.36646 10.7448 6.45663 10.692C6.54681 10.6392 6.62308 10.5657 6.6791 10.4775L9.7416 5.66499Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10152_5387">
          <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </IconBase>
  )
})
