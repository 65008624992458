import { useContext } from 'react'
import { AuthContext, IAuthContext } from '@/Context'
import { isSuperAdmin } from '@/util'
import { canEdit, isAdmin } from '@/security'
import { Role } from '@/api/dashboard'
import { Features } from '@/libs/features'
import { useFeatureFlags } from '@/libs'
import { DashboardAccessLevel } from '@/api/DashboardAccessLevel'

export const isStaff = (email?: string) => {
  return email?.endsWith('@aghanim.com')
}

export const useCurrentUser = () => {
  const context = useContext(AuthContext) as IAuthContext
  const { featureFlags } = useFeatureFlags()
  const isSuperAdm = isSuperAdmin(context.customer)
  const isStaffUser = isSuperAdm || isStaff(context.customer?.email)

  const getFeatureAccessType = (feature: Features): number | undefined | null => {
    if (!context.customer) return null

    switch (feature) {
      case Features.sku:
      case Features.coupon:
      case Features.store:
      case Features.campaign:
      case Features.segment:
      case Features.demo:
      case Features.hub_builder:
        return context.customer.mode

      case Features.glossary:
        if (!featureFlags.glossary_enabled) return null
        break

      case Features.store_promotions:
        if (!featureFlags.promotion_enabled) return null
        break

      case Features.bundle:
        if (!featureFlags.bundle_enabled) return null
        return context.customer.mode

      case Features.sku_cashback_rewards:
        if (!featureFlags.cashback_reward_enabled) return null
        return context.customer.mode

      case Features.price_templates:
        if (!featureFlags.price_templates_enabled) return null
        return context.customer.mode

      case Features.loyalty:
        if (!featureFlags.loyalty_program_enabled) return null
        return context.customer.mode

      case Features.lootboxes:
        if (!featureFlags.lootbox_enabled) return null
        return context.customer.mode

      case Features.creators:
        if (!featureFlags.creator_program_enabled) return null
        return context.customer.mode

      case Features.daily_rewards:
        if (!featureFlags.daily_rewards_enabled) return null
        return context.customer.mode
      case Features.sequential_offers:
        if (!featureFlags.sequential_offer_enabled) return null
        return context.customer.mode
    }

    return DashboardAccessLevel.ALLOWED
  }

  const hasFeatureAccess = (feature: Features): boolean => {
    const f = getFeatureAccessType(feature)
    return f == DashboardAccessLevel.ALLOWED || f == DashboardAccessLevel.LIMITED
  }

  return {
    customer: context.customer,
    isSuperAdmin: isSuperAdm,
    isAdmin: isSuperAdm || isAdmin(context.customer),
    canEdit: canEdit(context.customer),
    isDeveloper: context.customer?.role === Role.Developer || isAdmin(context.customer),
    isStaff: isStaffUser,
    getFeatureAccessType: getFeatureAccessType,
    hasFeatureAccess: hasFeatureAccess,
  }
}
