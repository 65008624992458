import { useQuery, queryOptions } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { ItemOrderType, ItemType, ResourceState } from './dashboard'
import { useParams } from 'react-router-dom'
import { SKUItem } from '@/api/types'

export const DEFAULT_LOAD_LIMIT = 300

export type Query = {
  /**
   * Limit
   * A limit on the number of objects to be returned
   * @exclusiveMin 1
   * @max 100
   * @default 10
   */
  limit?: number
  /**
   * Offset
   * The number of objects to skip
   * @min 0
   */
  offset?: number
  /** Filter by item state */
  state?: ResourceState

  ids?: string

  /** Search String */
  search_string?: string
  /** Category Id */
  category_id?: string

  order_by?: ItemOrderType

  types?: ItemType[]
}

export const useGameItemsQuery = (companyId: string, gameId: string, query: Query = {}) =>
  useQuery(gameItemsQueryOptions(companyId, gameId, query))

export const gameItemsQueryOptions = (companyId: string, gameId: string, query: Query = {}) =>
  queryOptions({
    queryKey: ['items', gameId, { query }],
    queryFn: () =>
      dashboardClient.v1
        .getItems(companyId, gameId, {
          ...query,
          types: query.types ? query.types.join(',') : '',
          ids: query.ids || '',
        })
        .then(r => r.data as SKUItem[]),
    staleTime: 60000,
  })

export const useGameItems = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { isLoading, data: items = [] } = useQuery(
    gameItemsQueryOptions(companyId, gameId, {
      limit: DEFAULT_LOAD_LIMIT,
    }),
  )
  return { isLoading, items: items as SKUItem[] }
}

export const useGameItem = (companyId: string, gameId: string, id: string) =>
  useQuery({
    queryKey: ['item', gameId, id],
    queryFn: (): Promise<SKUItem | null> => {
      if (!id) return new Promise(resolve => resolve(null))
      return dashboardClient.v1
        .getItems(companyId, gameId, {
          ids: id,
        })
        .then(r => r.data[0] || null)
    },
    enabled: !!id,
  })
