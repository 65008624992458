import { UseNavigateBackValue } from '@/libs'
import { Button } from '@dashboard/ui'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface ModalRushTitleProps {
  children: ReactNode
  back?: UseNavigateBackValue
  extra?: ReactNode
}

export const ModalRushTitle = ({ children, back, extra }: ModalRushTitleProps) => (
  <div className="mb-5 flex items-start justify-between last:mb-0">
    <div className="flex w-full flex-col items-start gap-2 overflow-hidden">
      {back && (
        <Link className="inline-flex" {...back}>
          <Button variant="link" size="xs">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <g clipPath="url(#clip0_2599_139137)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.5 9.03757 0.5 6C0.5 2.96243 2.96243 0.5 6 0.5C9.03757 0.5 11.5 2.96243 11.5 6ZM7.10355 4.35355C7.29882 4.15829 7.29882 3.84171 7.10355 3.64645C6.90829 3.45118 6.59171 3.45118 6.39645 3.64645L4.39645 5.64645C4.20118 5.84171 4.20118 6.15829 4.39645 6.35355L6.39645 8.35355C6.59171 8.54882 6.90829 8.54882 7.10355 8.35355C7.29882 8.15829 7.29882 7.84171 7.10355 7.64645L5.45711 6L7.10355 4.35355Z"
                  fill="#0EA5E9"
                />
              </g>
              <defs>
                <clipPath id="clip0_2599_139137">
                  <rect width="12" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>Back</span>
          </Button>
        </Link>
      )}
      <h3 className="flex w-full max-w-[65%] items-center gap-1.5 truncate font-nohemi text-hero-h3">{children}</h3>
    </div>

    {extra && <div className="flex items-center gap-3">{extra}</div>}
  </div>
)
