import { Node, RemoveItemsStoreNode } from '../../../api/dashboard'
import { BlockError } from '../../campaigns/types'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'

export default function RemoveItemsNodeEditor(props: {
  node: RemoveItemsStoreNode
  error?: BlockError | null
  setNode: (node: Node) => void
}) {
  return (
    <SelectSkuItem
      value={props.node.item_id}
      onChange={v => {
        props.setNode({
          ...props.node,
          item_id: v as string,
        } as Node)
      }}
    />
  )
}
