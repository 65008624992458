import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const BehanceIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 17 17" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.19706 4.50005C6.56551 4.49321 6.93344 4.53099 7.29281 4.61255C7.59539 4.67862 7.88267 4.80154 8.13938 4.9748C8.37646 5.14709 8.56405 5.37872 8.68331 5.64643C8.82038 5.97563 8.8865 6.33002 8.87738 6.68649C8.89294 7.07791 8.79298 7.46525 8.58994 7.80024C8.37634 8.11664 8.08109 8.36932 7.7355 8.53149C8.20448 8.65793 8.61297 8.94754 8.8875 9.34824C9.15105 9.77282 9.28351 10.2657 9.26831 10.7652C9.27701 11.1634 9.19466 11.5584 9.02756 11.92C8.8731 12.2354 8.64695 12.5103 8.36719 12.7227C8.07916 12.9327 7.75505 13.0881 7.41094 13.1811C7.05409 13.2798 6.68544 13.3294 6.31519 13.3285H2.25V4.50005H6.19706ZM5.9625 8.07081C6.252 8.08274 6.53697 7.99607 6.77081 7.82499C6.8828 7.72529 6.96992 7.60078 7.0252 7.46141C7.08048 7.32203 7.10239 7.17166 7.08919 7.02231C7.09668 6.84786 7.06195 6.67419 6.98794 6.51606C6.92516 6.39015 6.82754 6.28491 6.70669 6.21287C6.58428 6.13356 6.44676 6.08051 6.30281 6.05705C6.14662 6.0268 5.98769 6.01304 5.82863 6.01599H4.09163V8.07868H5.9625V8.07081ZM6.06375 11.8294C6.23882 11.8318 6.41354 11.8129 6.58406 11.7732C6.7399 11.7388 6.88769 11.6748 7.01944 11.5847C7.14851 11.4947 7.25279 11.3736 7.32263 11.2326C7.4036 11.0528 7.44081 10.8564 7.43119 10.6594C7.44874 10.4799 7.42497 10.2988 7.36169 10.1299C7.29841 9.96107 7.19731 9.80893 7.06613 9.68518C6.78808 9.48039 6.44734 9.37894 6.10256 9.39831H4.09163V11.8294H6.06375Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8913 11.7968C12.0358 11.9308 12.2061 12.034 12.3917 12.1001C12.5774 12.1663 12.7746 12.194 12.9713 12.1816C13.2831 12.1894 13.5888 12.0946 13.8414 11.9116C14.0469 11.7779 14.2026 11.5802 14.2841 11.3491H15.7528C15.5963 12.0071 15.2142 12.5895 14.6728 12.9949C14.1535 13.3396 13.5397 13.5142 12.9167 13.4944C12.472 13.5003 12.0307 13.4167 11.619 13.2486C11.2466 13.0933 10.9125 12.8585 10.6403 12.5607C10.369 12.2486 10.1606 11.887 10.0266 11.4959C9.8761 11.0586 9.80245 10.5987 9.80888 10.1363C9.80614 9.6817 9.88227 9.23007 10.0339 8.80149C10.2376 8.19856 10.627 7.67564 11.1464 7.3078C11.6657 6.93995 12.2882 6.74607 12.9246 6.75399C13.3944 6.74417 13.859 6.85412 14.2746 7.07349C14.6502 7.27948 14.9743 7.56796 15.2224 7.91724C15.4762 8.28374 15.6581 8.69509 15.7584 9.12943C15.8685 9.59584 15.9078 10.0762 15.8749 10.5542H11.5003C11.4649 11.0034 11.605 11.4488 11.8913 11.7968ZM13.8105 8.43924C13.6881 8.3168 13.5409 8.22201 13.3787 8.16127C13.2166 8.10053 13.0433 8.07524 12.8706 8.08712C12.6414 8.07838 12.4139 8.12915 12.2102 8.23449C12.0487 8.32339 11.9062 8.44301 11.7906 8.58662C11.6881 8.72126 11.6118 8.87389 11.5656 9.03662C11.5239 9.17234 11.4978 9.31237 11.4879 9.45399H14.1975C14.1739 9.0848 14.0394 8.73132 13.8116 8.4398L13.8105 8.43924Z"
        fill="currentColor"
      />
      <path d="M11.1386 5.08955H14.5339V5.95749H11.1386V5.08955Z" fill="currentColor" />
    </IconBase>
  )
})
