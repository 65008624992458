import { useInfiniteQuery } from '@tanstack/react-query'
import { dashboardClient } from './index'
import { ItemOrderType, ItemType, ResourceState } from './dashboard'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { SKUItem } from '@/api/types'

const LOAD_LIMIT = 50

const fetchPage = async (
  companyId: string,
  gameId: string,
  pageParam: number,
  search_string: string,
  types?: ItemType[],
  isStackable?: boolean,
  withPrice?: boolean,
  rarityId?: string,
) => {
  const { data } = await dashboardClient.v1.getItems(companyId, gameId, {
    limit: LOAD_LIMIT,
    offset: pageParam * LOAD_LIMIT,
    state: ResourceState.Active,
    search_string: search_string,
    order_by: ItemOrderType.Name,
    types: types ? types.join(',') : '',
    is_stackable: isStackable,
    with_price: withPrice,
    rarity_id: rarityId,
  })
  return data
}

export const useInfiniteGameItems = (
  search: string,
  types?: ItemType[],
  isStackable?: boolean,
  withPrice?: boolean,
  rarityId?: string,
) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const [loadAllResolve, setLoadAllResolve] = useState<{ resolve: ((v: SKUItem[]) => void) | null }>({
    resolve: null,
  })

  const result = useInfiniteQuery({
    queryKey: [gameId, 'infinity-items', search, types, isStackable, withPrice, rarityId],
    initialPageParam: 0,
    queryFn: ({ pageParam = 0 }) =>
      fetchPage(companyId, gameId, pageParam, search, types, isStackable, withPrice, rarityId),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 0 || lastPage.length < LOAD_LIMIT) {
        return undefined
      }
      return pages.length
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const hasNext = useCallback(() => {
    const lastPage = result.data?.pages[result.data.pages.length - 1] || []
    return lastPage.length > 0 && lastPage.length === LOAD_LIMIT
  }, [result.data])

  useEffect(() => {
    if (!loadAllResolve.resolve || result.isLoading) return
    if (hasNext()) {
      result.fetchNextPage()
      return
    }

    setTimeout(() => {
      loadAllResolve.resolve?.(result.data?.pages.flat() || [])
      setLoadAllResolve({
        resolve: null,
      })
    }, 0)
  }, [loadAllResolve.resolve, result.data?.pages])

  const loadAll = async () => {
    return new Promise<SKUItem[]>(resolve => {
      setLoadAllResolve({
        resolve: resolve,
      })
    })
  }

  return {
    items: result.data?.pages.flat() || [],
    hasNext: hasNext(),
    loadAll,
    ...result,
  }
}
