import { useTranslation } from 'react-i18next'
import { Button, Popper } from '@dashboard/ui'
import { Badge, TableCell, TableRow } from '@/ui'
import { CashbackRewardRead } from '@/api/dashboard'
import { SkuImageName } from '@/layouts/game-items'
import { CASHBACK_REWARDS_EDIT_PATH, SkuCashbackRewardsMenu } from 'src/layouts/cashback-rewards'
import { DotsHorizontal } from '@/icons'
import { useGenerateCompanyPath } from '@/libs'
import { formatMoney } from '@/util'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'
import { useCurrentUser } from '@/api/useCurrentUser'

interface SkuCashbackRewardsRowProps {
  reward: CashbackRewardRead
}

export const SkuCashbackRewardsRow = ({ reward }: SkuCashbackRewardsRowProps) => {
  const { t } = useTranslation('sku-cashback')
  const { generatePath } = useGenerateCompanyPath()
  const { canEdit } = useCurrentUser()

  return (
    <TableRow to={generatePath(CASHBACK_REWARDS_EDIT_PATH, { rewardId: reward.id })}>
      <TableCell width="100%">
        <div className="flex items-center gap-3">
          <div
            className="size-8 overflow-hidden rounded-md bg-cover bg-center bg-no-repeat"
            style={{ backgroundImage: `url(${DEFAULT_IMAGE_PLACEHOLDER_ITEM})` }}
          >
            {reward.item.icon_url && <img className="block size-full object-cover" src={reward.item.icon_url} alt="" />}
          </div>
          <div>{reward.item.name}</div>
        </div>
      </TableCell>
      <TableCell width="60%">
        <SkuImageName item={reward.item} hideQuantity={true} />
      </TableCell>
      <TableCell width="60%">
        {reward.reward_points} {reward.item.name} = {formatMoney(reward.from_usd)}
      </TableCell>
      <TableCell width="50%">{t('table.row.static-type')}</TableCell>
      <TableCell width="25%">
        <Badge variant="lime-primary">{t('table.row.static-status')}</Badge>
      </TableCell>
      <TableCell width={52} onClick={e => e.preventDefault()}>
        {canEdit && (
          <Popper
            popper={props => <SkuCashbackRewardsMenu reward={reward} {...props} />}
            params={{ placement: 'bottom-end' }}
          >
            <Button className="w-7 px-0" size="sm" variant="tertiary" color="secondary">
              <DotsHorizontal size={14} />
            </Button>
          </Popper>
        )}
      </TableCell>
    </TableRow>
  )
}
