import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Item } from '@/api/dashboard'
import { useItemBulkUpdateMutate } from '../api'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'

interface BulkArchiveModalProps extends ModalProps {
  items: Item[]
  onResetItems?: (items: string[]) => void
}

export const BulkArchiveModal = ({ items, onResetItems, ...rest }: BulkArchiveModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { mutateAsync, isPending } = useItemBulkUpdateMutate(companyId, gameId, items[0].type)

  const onSubmit = async () => {
    await mutateAsync({ data: items.map(i => ({ id: i.id, archived_at: new Date().getTime() / 1000 })) })
    rest.onClose?.()
    onResetItems?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalContent>
        <ModalTitle>
          {items.length > 1
            ? t('sku.bulk-archive.modal.title-multiple', { count: items.length })
            : t('sku.bulk-archive.modal.title-single', { itemName: items.at(0)?.name })}
        </ModalTitle>
        <div>
          <p className="text-paragraph-md text-text-secondary">{t('sku.bulk-archive.modal.desc')}</p>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" size="sm" onClick={rest.onClose} variant="outline" color="secondary">
          {t('sku.bulk-archive.modal.cancel')}
        </Button>
        <Button type="submit" size="sm" loading={isPending} autoFocus={true} onClick={onSubmit} color="danger">
          {t('sku.bulk-archive.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
