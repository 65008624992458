import { NavProps } from 'react-day-picker';
import { cn } from '../../libs';
import { ChevronLeftOutline, ChevronRightOutline } from '../../icons';
import { Button } from '../Button';

type CalendarNavProps = NavProps;

export const CalendarNav = ({ className, onPreviousClick, onNextClick }: CalendarNavProps) => {
  return (
    <div className={cn('absolute inset-x-0 top-0 flex h-6 items-center justify-between ', className)}>
      <Button className="px-0" variant="tertiary" color="secondary" size="xs" onClick={onPreviousClick}>
        <ChevronLeftOutline size={14} />
      </Button>
      <Button className="px-0" variant="tertiary" color="secondary" size="xs" onClick={onNextClick}>
        <ChevronRightOutline size={14} />
      </Button>
    </div>
  );
};
