import { ButtonIcon } from '@/ui'
import { DeleteOutline } from '@/icons'
import { cn } from '@dashboard/ui'
import { RangeOfferTier } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { formatMoney } from '@/util'
import { DEFAULT_IMAGE_PLACEHOLDER_ITEM } from '@/Settings'

interface DailyRewardsDayFrameProps {
  num: number
  tier: RangeOfferTier
  onDelete: () => void
  onEdit: () => void
  image_url?: string
}

export const TierItem = ({ num, tier, onDelete, onEdit, image_url }: DailyRewardsDayFrameProps) => {
  const { t } = useTranslation('offers')
  return (
    <div className="flex w-full gap-1.5">
      <div
        className={cn(
          'flex min-h-14 w-[76px] flex-col items-center justify-center ',
          'rounded-xl p-1.5',
          'bg-fg-secondary',
        )}
      >
        <span className="text-paragraph-sm font-semibold text-text-secondary">{num}</span>
        <span className="text-caption-sm font-medium text-text-quarterary-hover">{t('tier')}</span>
      </div>
      <div
        className="flex grow cursor-pointer items-center gap-3 rounded-xl border border-border-primary p-3 hover:bg-fg-primary-alt"
        onClick={onEdit}
      >
        <div
          className="size-[38px] shrink-0 rounded-md bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${image_url || DEFAULT_IMAGE_PLACEHOLDER_ITEM})`,
          }}
        />
        <div>
          <div className="text-caption-md font-semibold text-text-secondary">
            {t('tier.bonus_percent')} {tier.bonus_percent}%
          </div>
          <div className="mt-1.5  text-caption-md font-normal text-text-tertiary-hover">
            {t('range')}
            {' · '}
            {formatMoney(tier.from_usd)} - {formatMoney(tier.to_usd)}
          </div>
        </div>
        <ButtonIcon
          className="ml-auto"
          variant="tertiary-destructive"
          onClick={e => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <DeleteOutline />
        </ButtonIcon>
      </div>
    </div>
  )
}
