import { Skeleton } from '@dashboard/ui'
import { UseNavigateBackValue } from '@/libs'
import { ModalRushTitle } from '@/ui'

interface PriceTemplateEditSkeletonProps {
  back: UseNavigateBackValue
}

export const PriceTemplateEditSkeleton = ({ back }: PriceTemplateEditSkeletonProps) => {
  return (
    <>
      <ModalRushTitle back={back}>
        <Skeleton className="w-1/12" />
        <Skeleton className="size-2" />
        <Skeleton className="w-1/6" />
      </ModalRushTitle>
      <div className="-mx-4 grid h-full grid-cols-[320px_1fr] items-start gap-[18px] overflow-y-auto px-4">
        <div>
          <Skeleton className="h-64 w-full rounded-xl" />
        </div>
        <div>
          <div className="flex items-center gap-4">
            <Skeleton className="h-8 w-full" />
            <Skeleton className="h-8 w-1/6" />
            <Skeleton className="h-8 w-1/6" />
            <Skeleton className="h-8 w-1/12" />
          </div>
        </div>
      </div>
    </>
  )
}
