const allowSymbols: Set<string> = new Set<string>('abcdefghijklmnopqrstuvwxyz0123456789_-.'.split(''))

/**
 * Slugify a SKU ID.
 * @param name
 */
export function slugifySkuID(name: string): string {
  let res: string[] = []

  const trimmedName = name.trim()

  for (let i = 0; i < trimmedName.length; i++) {
    let c: string = trimmedName[i].toLowerCase()
    if (!allowSymbols.has(c)) {
      if (c == ' ') {
        res.push('-')
      }
    } else {
      res.push(trimmedName[i])
    }
  }

  return res.join('')
}
