import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { ExternalLink, Menu, useIsMobile } from '@dashboard/ui'
import { AuthContext, IAuthContext } from '@/Context'
import UserAvatar from '@/components/UserAvatar'
import { FloatingMenuProps, MenuItem, MenuItemDivider, useModal } from '@/ui'
import { useFeatureFlags } from '@/libs'
import { Exit, UserOutline } from '@/icons'
import { isEmail } from '@/util'
import { AccountProfileModal } from '../widgets'
import { initFeedbackIntegration } from '@/libs/sentry'

interface UserProfileMenuProps extends FloatingMenuProps {}

export const UserProfileMenu = ({ onClose }: UserProfileMenuProps) => {
  const context = useContext(AuthContext) as IAuthContext
  const { logout, user } = useAuth0()
  const { featureFlags } = useFeatureFlags()

  const { t } = useTranslation()

  const openAccountProfileModal = useModal(props => <AccountProfileModal {...props} />, { mode: 'rush' })

  const computedUsername = useMemo(() => {
    const username = context?.customer?.name || user?.name || user?.email || ''
    return isEmail(username) ? username.split('@')[0] : username
  }, [context?.customer?.name, user?.name, user?.email])

  const onClickAccountProfile = () => {
    openAccountProfileModal()
    onClose?.()
  }

  const onClickLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  useEffect(() => {
    initFeedbackIntegration(user?.email, context.customer?.locale, '#bug-report')
  }, [])

  const isMobile = useIsMobile()

  return (
    <Menu>
      <div className="mb-3 flex w-64 flex-col items-center justify-center gap-3 pt-1">
        <UserAvatar url={context?.customer?.avatar_url || user?.picture} size="48px" />
        <div className="flex grow flex-col overflow-hidden text-center">
          <div className="mb-1 w-full truncate text-caption-md font-semibold text-text-primary">{computedUsername}</div>
          {user?.email && (
            <div className="w-full truncate text-caption-sm font-normal text-text-brand-primary">{user.email}</div>
          )}
        </div>
      </div>

      {featureFlags.account_settings_enabled && (
        <MenuItem icon={UserOutline} onClick={onClickAccountProfile}>
          {t('profile-button.account-profile')}
        </MenuItem>
      )}

      <MenuItemDivider />

      {isMobile && (
        <>
          <MenuItem id="bug-report">{t('header.bug-report')}</MenuItem>
          <MenuItem
            extra={ExternalLink}
            data-testid="logout-button"
            onClick={() => window.open('https://docs.aghanim.com/')}
          >
            {t('header.documentation')}
          </MenuItem>
        </>
      )}
      <MenuItem data-testid="logout-button" icon={Exit} onClick={onClickLogout}>
        {t('profile-button.logout')}
      </MenuItem>
    </Menu>
  )
}
