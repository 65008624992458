/**
 * The function will return the size of the file by url.
 * @param url
 */
export const getFileSizeFromURL = async (url: string) => {
  try {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      return null
    }

    const response = await fetch(url)
    const contentLength = response.headers.get('content-length')

    if (contentLength) {
      return parseInt(contentLength, 10)
    } else {
      return null
    }
  } catch {
    return null
  }
}
