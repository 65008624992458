import { ForwardRefExoticComponent, FunctionComponent, ReactNode, RefAttributes, SVGProps, forwardRef } from 'react';
import { cn } from '../../libs';

export interface AghanimIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
  viewBox?: string;
}

export type AghanimIcon = ForwardRefExoticComponent<AghanimIconProps & RefAttributes<SVGSVGElement>> & {
  type?: 'aghanim-icon';
};

export type AghanimIconOrCustom = AghanimIcon | FunctionComponent<{ size?: number }>;

interface IconBaseProps extends AghanimIconProps {
  children: ReactNode;
}

export const IconBase = forwardRef<SVGSVGElement, IconBaseProps>(function (
  { children, size = 16, viewBox = '0 0 16 16', ...rest },
  ref,
) {
  return (
    <svg
      {...rest}
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={cn('shrink-0', rest.className)}
      width={size}
      height={size}
      fill="none"
    >
      {children}
    </svg>
  );
});
