import { useParams } from 'react-router-dom'
import { useSegmentsQuery } from '../../segment/api/useSegmentsQuery'
import { SelectMulti, SelectMultiProps, SelectMultipleOption, SelectOption } from '@/ui'
import SegmentBadge from '@/layouts/segment/components/SegmentBadge'
import { Select, SelectProps } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

interface SelectSkuProps extends Omit<SelectProps, 'items' | 'value'> {
  multiselect?: boolean
  value: string | string[] | undefined | null
  segmentGroupId?: string
  mode?: 'slug' | 'id'
  onChange: (value: SelectOption['value'] | SelectMultipleOption['value'][]) => void
}

export const SelectSegment = (props: SelectSkuProps) => {
  const { t } = useTranslation()

  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: items = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
    segment_group_id: props.segmentGroupId,
  })

  let visibleItems = items

  if (props.multiselect) {
    let selectedIds = props.value as string[]

    let addedGroups: string[] = []
    selectedIds.forEach(it => {
      let item = items.find(i => i.id == it)
      if (item && item.segment_group_id) {
        addedGroups.push(item.segment_group_id)
      }
    })

    visibleItems = items.filter(it => {
      if (it.segment_group_id && !selectedIds.includes(it.id)) {
        let hasSameGroup = addedGroups.includes(it.segment_group_id)

        if (hasSameGroup) {
          return false
        }
      }

      return true
    })
  }

  let options = visibleItems.map(it => ({
    value: (props.mode == 'slug' ? it.slug : it.id) as string,
    children: <SegmentBadge segment={it} />,
  }))

  if (props.multiselect) {
    return (
      <SelectMulti
        // filter={(opt, search) => {
        //   let id = opt.value
        //   let item = visibleItems.find(it => (props.mode == 'slug' ? it.slug == id : it.id == id))
        //   return !!item?.name.toLowerCase().includes(search)
        // }}
        {...(props as SelectMultiProps)}
        items={options}
        placeholder={t('users.segments.select')}
      />
    )
  }

  return <Select {...props} value={props.value as string} items={options} placeholder={t('users.segment.select')} />
}
