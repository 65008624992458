import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { gameQueryOptions } from '@/layouts/game'
import { useGenerateCompanyPath } from '@/libs'
import { localesQueryOptions } from '../api'

/**
 * Navigate to the default locale for the game.
 * @param toPath
 */
export const useNavigateToGameLocale = (toPath: string) => {
  const navigate = useNavigate()
  const { companyId, gameId, localeId, generatePath } = useGenerateCompanyPath<{ localeId: string }>()
  const { data: locales = [] } = useQuery(localesQueryOptions(companyId, gameId))

  const { data: game } = useQuery(gameQueryOptions(companyId, gameId))

  const localeForCompany = useMemo(() => {
    if (localeId || !game) {
      return null
    }
    return locales.length ? locales.find(locale => locale.locale === game.locale_default) || locales.at(0) : null
  }, [localeId, locales, game])

  useEffect(() => {
    if (localeForCompany) {
      navigate(generatePath(toPath, { localeId: localeForCompany.id }), { replace: true })
    }
  }, [toPath, navigate, localeForCompany])
}
