import { ButtonIcon, FieldGroup, Header, ImageUploader } from '@/ui'

import { Controller, useForm } from 'react-hook-form'
import { RangeOfferTier } from '@/api/dashboard'
import { FieldSection } from 'src/components/ui/FieldSection'

import { useTranslation } from 'react-i18next'
import { Button, FormErrorMessage, Input, formatHookFormErrors } from '@dashboard/ui'
import { CloseOutline } from '@/icons'
import Divider from '@/components/Divider'
import { useEffect } from 'react'
import { PRICE_MAX } from '@/layouts/game-items/data'
import { useNumberFormat } from '@react-input/number-format'
import { ErrorMessage } from '@hookform/error-message'

interface GeneralSettingsProps {
  onClose: () => void
  onSubmit: (tier: RangeOfferTier) => void
  tier: RangeOfferTier
  title: string
}

export const TierForm = (props: GeneralSettingsProps) => {
  const { t } = useTranslation('offers')
  const { t: tMain } = useTranslation()

  const {
    control,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    setError,
    reset,
    setValue,
  } = useForm<RangeOfferTier>({
    defaultValues: {
      bonus_percent: 10,
    },
  })

  useEffect(() => {
    reset({
      ...props.tier,
      from_usd: props.tier.from_usd / 100,
    })
    setValue('to_usd', props.tier.to_usd / 100, { shouldDirty: true })
  }, [props.tier])

  const fromRef = useNumberFormat({
    locales: 'en',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const toRef = useNumberFormat({
    locales: 'en',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const onSubmit = (data: RangeOfferTier) => {
    if (typeof data.from_usd == 'string') {
      data.from_usd = parseInt((data.from_usd as string).replace(',', '').replace('.', ''))
    } else {
      data.from_usd = data.from_usd * 100
    }

    if (typeof data.to_usd == 'string') {
      data.to_usd = parseInt((data.to_usd as string).replace(',', '').replace('.', ''))
    } else {
      data.to_usd = data.to_usd * 100
    }

    if (data.from_usd >= data.to_usd) {
      setError('from_usd', { message: t('tier.from_usd_error') })
      return
    }

    props.onClose()
    props.onSubmit({
      ...data,
    })
  }

  const renderGeneral = () => {
    return (
      <FieldSection label={t('general')}>
        <FieldGroup label={t('tier.bonus_percent')}>
          <Controller
            control={control}
            rules={{
              required: tMain('validation.required'),
              min: { value: 1, message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
            }}
            name="bonus_percent"
            render={({ field }) => (
              <Input
                min={1}
                type={'number'}
                value={field.value}
                onChange={e => {
                  let num = parseInt(e.target.value)
                  field.onChange(isNaN(num) ? '' : num)
                }}
                {...formatHookFormErrors(errors, 'bonus_percent')}
              />
            )}
          />
        </FieldGroup>
        <FieldGroup label={t('background_image_url')}>
          <Controller
            control={control}
            name="background_image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="background_image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderPrice = () => {
    return (
      <FieldSection label={t('range.pricing')} caption={t('range.pricing.desc')}>
        <div className="grid grid-cols-2 gap-4 ">
          <div>
            <FieldGroup label={t('tier.from')}>
              <Controller
                control={control}
                rules={{
                  required: tMain('validation.required'),
                  min: { value: 0, message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
                  max: {
                    value: PRICE_MAX * 100,
                    message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }),
                  },
                }}
                name="from_usd"
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={fromRef}
                    {...formatHookFormErrors(errors, 'from_usd')}
                    extraLeft={{ icon: () => <div className="text-text-quarterary-hover">USD</div>, width: 30 }}
                    extraRight={{ icon: () => <div className="text-text-quarterary-hover">$</div> }}
                  />
                )}
              />
            </FieldGroup>
          </div>
          <div>
            <FieldGroup label={t('tier.to')}>
              <Controller
                control={control}
                rules={{
                  required: tMain('validation.required'),
                  min: { value: 0, message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
                  max: {
                    value: PRICE_MAX * 100,
                    message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }),
                  },
                }}
                name="to_usd"
                render={({ field }) => (
                  <Input
                    {...field}
                    ref={toRef}
                    {...formatHookFormErrors(errors, 'to_usd')}
                    extraLeft={{ icon: () => <div className="text-text-quarterary-hover">USD</div>, width: 30 }}
                    extraRight={{ icon: () => <div className="text-text-quarterary-hover">$</div> }}
                  />
                )}
              />
            </FieldGroup>
          </div>
        </div>
      </FieldSection>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex h-full flex-col bg-fg-primary">
        <Header title={props.title} className="sticky top-0 z-10 p-4" minWidth="auto">
          <div className="flex items-center gap-4">
            <Button disabled={!isDirty} loading={isSubmitting} type="submit">
              {tMain('Apply')}
            </Button>
            <ButtonIcon onClick={props.onClose} type="button" size="xs" variant="secondary-gray">
              <CloseOutline />
            </ButtonIcon>
          </div>
        </Header>

        <div className="flex size-full flex-col p-4">
          {renderGeneral()}
          <Divider />

          {renderPrice()}
        </div>
      </div>
    </form>
  )
}
