import { useMutation, useQueryClient } from '@tanstack/react-query'
import { LootboxCreate } from '@/api/dashboard'
import { dashboardClient } from '@/api'

interface LootboxCreateMutate {
  data: LootboxCreate
}

/**
 *
 * @param companyId
 * @param gameId
 */
export const useLootboxCreateMutate = (companyId: string, gameId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ data }: LootboxCreateMutate) =>
      dashboardClient.v1.createLootbox(companyId, gameId, data).then(r => r.data),
    onSuccess: async _ => {
      await queryClient.refetchQueries({ queryKey: ['items', gameId], exact: false, stale: false })
    },
  })
}
