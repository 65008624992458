import { cn, useIsMobile } from '@dashboard/ui'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [showTermly, setShowTermly] = useState(false)

  const isMobile = useIsMobile()

  useEffect(() => {
    setTimeout(() => {
      setShowTermly(true)
    }, 250)
  }, [setShowTermly])

  return (
    <div className={cn('bg-fg-primary', isMobile ? 'pl-0' : '')}>
      <div
        className={cn(
          'flex justify-between text-[12px] text-text-tertiary',
          isMobile ? 'mt-4 flex-col gap-1 pb-3' : 'min-h-[39px] items-center',
        )}
      >
        <span>© 2023–{new Date().getFullYear()} Aghanim, Inc. All rights reserved.</span>
        <div className={cn('flex gap-[10px]')}>
          {showTermly && (
            <span className="cursor-pointer text-text-brand-primary termly-display-preferences">
              Consent Preferences
            </span>
          )}
          <Link target="_blank" to={'https://aghanim.com/privacy'}>
            Privacy Policy
          </Link>
          <Link target="_blank" to={'https://aghanim.com/developer-terms'}>
            Terms of Service
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
