import { Platform, PriceCategory, TemplatePrice } from '@/api/dashboard'
import { CheckFn, CheckLevel, PriceTemplateExtendedPrice } from 'src/layouts/sku-price-templates'
import {
  buildPriceChecker,
  makeAnomalyPriceCheck,
  makeExistingPriceCheck,
} from '@/layouts/sku-price-templates/libs/priceChecker'

interface UseExtendedPricesParams {
  initial: TemplatePrice[]
  configured: TemplatePrice[]
  category: PriceCategory
}

const groupByPlatformToExtended = (
  prices: TemplatePrice[],
  params: UseExtendedPricesParams,
  checker?: CheckFn,
): PriceTemplateExtendedPrice[] => {
  return Object.values(
    prices.reduce(
      (acc: { [key: string]: PriceTemplateExtendedPrice }, price) => {
        const key = `${price.country}-${price.currency}`

        if (!acc[key]) {
          acc[key] = {
            country: price.country,
            currency: price.currency,
            price: price.price,
            is_custom: price.is_custom,
            platformPrice: { ios: undefined, android: undefined, other: undefined },
            check: null,
          }
        }
        // @ts-ignore
        acc[key].platformPrice![price.platform] = {
          v: price.price,
          check:
            checker?.({
              initial:
                params.initial.find(
                  p => p.country === price.country && p.currency === price.currency && p.platform === price.platform,
                )?.price ?? price.price,
              previous: params.configured.find(
                p =>
                  p.country === price.country &&
                  p.currency === price.currency &&
                  (p.platform === Platform.Any || p.platform === price.platform),
              )?.price,
              current: price.price,
            }) ?? null,
        }
        return acc
      },
      {} as { [key: string]: PriceTemplateExtendedPrice },
    ),
  )
}

/**
 * Get extended prices (with `checks`, `platformPrice`).
 * @param prices
 * @param params
 * @returns
 */
export const getExtendedPrices = (
  prices: TemplatePrice[],
  params: UseExtendedPricesParams,
): PriceTemplateExtendedPrice[] => {
  const checker = buildPriceChecker(
    makeAnomalyPriceCheck(CheckLevel.Warn, 50),
    makeExistingPriceCheck(CheckLevel.Info, 0),
  )

  if (params.category === PriceCategory.Platform) {
    return groupByPlatformToExtended(prices, params, checker)
  }

  return prices.map(price => ({
    ...price,
    check: checker({
      initial:
        params.initial.find(
          p =>
            p.country === price.country &&
            p.currency === price.currency &&
            (p.platform === Platform.Any || p.platform === Platform.Other),
        )?.price ?? price.price,
      previous: params.configured.find(
        p =>
          p.country === price.country &&
          p.currency === price.currency &&
          (p.platform === Platform.Any || p.platform === Platform.Other),
      )?.price,
      current: price.price,
    }),
    platformPrice: undefined,
  }))
}
