import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { dashboardClient } from '@/api'
import { Button, Modal, ModalClose, ModalProps, Spinner } from '@/ui'
import { HOME_PATH, getMotionProps, useGenerateCompanyPath } from '@/libs'
import { getNow } from '@/util'
import { hubWebsitesQuery } from '@/layouts/hub/api'

interface HubFirstPublishModalProps extends ModalProps {}

const publishingLoadingMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const HubFirstPublishModal = ({ ...rest }: HubFirstPublishModalProps) => {
  const ql = useQueryClient()
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: website, isLoading } = useQuery({
    ...hubWebsitesQuery(companyId, gameId),
    select: websites => websites.at(0),
  })

  const [isPublishing, setIsPublishing] = useState(false)
  const [isDone, setIsDone] = useState(false)

  const onClickPublish = async () => {
    if (!website) {
      return
    }
    setIsPublishing(true)
    await dashboardClient.v1.updateWebsite(companyId, gameId, website.id, { published_at: getNow() })
    await new Promise(r => setTimeout(r, 2000))
    await ql.refetchQueries({ queryKey: hubWebsitesQuery(companyId, gameId).queryKey })
    setIsDone(true)
  }

  if (isDone && website) {
    return (
      <Modal className="max-w-[690px]" isLoading={isLoading} {...rest}>
        <img
          src={website?.config?.favicon_url}
          className="mx-auto mb-6 size-24 rounded-xl bg-red-500"
          data-categories="essential"
          alt=""
        />
        <h3 className="mx-auto mb-6 max-w-[500px] text-center text-title-t3">
          <Trans
            i18nKey="hub.first-publish-modal.done.title"
            values={{ link: website.url }}
            components={{ a: <a /> }}
          />
        </h3>
        <p className="mb-6 text-center text-paragraph-md text-text-secondary">
          {t('hub.first-publish-modal.done.text')}
        </p>
        <div className="flex justify-center">
          <Link to={generatePath(HOME_PATH)}>
            <Button variant="primary" type="button" size="xl">
              {t('hub.first-publish-modal.done.go-to-dashboard')}
            </Button>
          </Link>
        </div>
      </Modal>
    )
  }

  return (
    <Modal className="max-w-[690px]" isLoading={isLoading} {...rest}>
      {rest.onClose && <ModalClose onClick={rest.onClose} />}
      <AnimatePresence>
        {isPublishing && (
          <motion.div
            {...publishingLoadingMotionProps}
            className="absolute left-0 top-0 z-10 flex size-full flex-col items-center justify-center bg-fg-primary"
          >
            <div className="mb-6 text-[#0097E4]">
              <Spinner size={124} />
            </div>
            <div className="text-center text-caption-lg font-normal text-text-tertiary">
              {t('hub.first-publish-modal.publishing-loading')}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <img className="mx-auto mb-6 size-24 rounded-xl bg-red-500" src={website?.config?.favicon_url} alt="" />
      <h3 className="mx-auto mb-6 max-w-[500px] text-center text-title-t3">
        {t('hub.first-publish-modal.idle.title')}
      </h3>
      <p className="mb-6 text-center text-paragraph-md text-text-secondary">{t('hub.first-publish-modal.idle.text')}</p>
      <div className="flex justify-center">
        <Button variant="primary" type="button" size="xl" isLoading={isPublishing} onClick={onClickPublish}>
          {t('hub.first-publish-modal.idle.publish')}
        </Button>
      </div>
    </Modal>
  )
}
