import { useTranslation } from 'react-i18next';
import { SelectFilterGroup } from '../components';

export const countryGroups: SelectFilterGroup[] = [
  {
    label: 'Africa',
    variant: 'checkbox',
    values: [
      'DZ',
      'EG',
      'LY',
      'MA',
      'SD',
      'TN',
      'IO',
      'BI',
      'KM',
      'DJ',
      'ER',
      'ET',
      'KE',
      'MG',
      'MW',
      'MU',
      'MZ',
      'RE',
      'RW',
      'SC',
      'SO',
      'SS',
      'TZ',
      'UG',
      'ZM',
      'ZW',
      'AO',
      'CM',
      'CF',
      'TD',
      'CG',
      'CD',
      'GQ',
      'GA',
      'ST',
      'BW',
      'LS',
      'NA',
      'SZ',
      'ZA',
      'BJ',
      'BF',
      'CV',
      'CI',
      'GM',
      'GH',
      'GN',
      'GW',
      'LR',
      'ML',
      'MR',
      'NE',
      'NG',
      'SH',
      'SN',
      'SL',
      'TG',
    ],
  },
  {
    label: 'Central America',
    variant: 'checkbox',
    values: ['BZ', 'CR', 'SV', 'GT', 'HN', 'MX', 'NI', 'PA'],
  },
  {
    label: 'South America',
    variant: 'checkbox',
    values: ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GF', 'GY', 'PE', 'PY', 'SR', 'UY', 'VE'],
  },
  {
    label: 'North America',
    variant: 'checkbox',
    values: ['BM', 'CA', 'GL', 'PM', 'US'],
  },
  {
    label: 'Asia',
    variant: 'checkbox',
    values: ['KZ', 'KG', 'TJ', 'TM', 'UZ'],
  },
  {
    label: 'Eastern Asia',
    variant: 'checkbox',
    values: ['CN', 'HK', 'JP', 'KP', 'KR', 'MN', 'MO', 'TW'],
  },
  {
    label: 'South-eastern Asia',
    variant: 'checkbox',
    values: ['BN', 'KH', 'ID', 'LA', 'MY', 'MM', 'PH', 'SG', 'TH', 'TL', 'VN'],
  },
  {
    label: 'Southern Asia',
    variant: 'checkbox',
    values: ['AF', 'BD', 'BT', 'IN', 'IR', 'MV', 'NP', 'PK', 'LK'],
  },
  {
    label: 'Western Asia',
    variant: 'checkbox',
    values: [
      'AM',
      'AZ',
      'BH',
      'CY',
      'GE',
      'IQ',
      'IL',
      'JO',
      'KW',
      'LB',
      'OM',
      'PS',
      'QA',
      'SA',
      'SY',
      'TR',
      'AE',
      'YE',
    ],
  },
  {
    label: 'Europe',
    variant: 'checkbox',
    values: [
      'BY',
      'BG',
      'CZ',
      'HU',
      'MD',
      'PL',
      'RO',
      'RU',
      'SK',
      'UA',
      'GG',
      'JE',
      'AX',
      'DK',
      'EE',
      'FO',
      'FI',
      'IS',
      'IE',
      'IM',
      'LV',
      'LT',
      'NO',
      'SJ',
      'SE',
      'GB',
      'AL',
      'AD',
      'BA',
      'HR',
      'GI',
      'GR',
      'VA',
      'IT',
      'MT',
      'ME',
      'MK',
      'PT',
      'SM',
      'RS',
      'SI',
      'ES',
      'AT',
      'BE',
      'FR',
      'DE',
      'LI',
      'LU',
      'MC',
      'NL',
      'CH',
    ],
  },
  {
    label: 'Australia and New Zealand',
    variant: 'checkbox',
    values: ['AU', 'CX', 'CC', 'NF', 'NZ'],
  },
  {
    label: 'Caribbean',
    variant: 'checkbox',
    values: [
      'AI',
      'AG',
      'AW',
      'BS',
      'BB',
      'BQ',
      'KY',
      'CU',
      'CW',
      'DM',
      'DO',
      'GD',
      'GP',
      'HT',
      'JM',
      'MQ',
      'MS',
      'PR',
      'BL',
      'KN',
      'LC',
      'MF',
      'PM',
      'VC',
      'TT',
      'TC',
      'VG',
      'VI',
    ],
  },
  {
    label: 'Melanesia',
    variant: 'checkbox',
    values: ['FJ', 'NC', 'PG', 'SB', 'VU'],
  },
  {
    label: 'Micronesia',
    variant: 'checkbox',
    values: ['GU', 'KI', 'MH', 'FM', 'NR', 'MP', 'PW'],
  },
  {
    label: 'Polynesia',
    variant: 'checkbox',
    values: ['AS', 'CK', 'PF', 'NU', 'PN', 'TK', 'TO', 'TV', 'WF', 'WS'],
  },
];

interface CountryFilter {
  include?: string[];
  exclude?: string[];
}

/**
 * Returns country groups with translated labels.
 */
export const useCountryGroups = (filter?: CountryFilter): SelectFilterGroup[] => {
  const { t } = useTranslation('ui');

  return countryGroups.map(group => {
    let filteredValues = [...group.values];

    if (filter?.include && filter.include.length > 0) {
      filteredValues = filteredValues.filter(code => filter.include?.includes(code as string));
    }

    if (filter?.exclude && filter.exclude.length > 0) {
      filteredValues = filteredValues.filter(code => !filter.exclude?.includes(code as string));
    }

    return {
      ...group,
      values: filteredValues,
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      label: t(`country-groups.${group.label?.toString().toLowerCase().replaceAll(' ', '-')}`),
    };
  });
};
