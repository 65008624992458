import { useTranslation } from 'react-i18next'

interface TableCellDateProps {
  /**
   * Timestamp in double precision floating point format.
   */
  timestamp: number
  options?: Partial<{
    /**
     * Show time.
     */
    time: boolean
  }>
}

export const TableCellDate = ({ timestamp, options }: TableCellDateProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center gap-1.5">
      <span className="text-caption-md leading-none">
        {t('intl.DateTime', {
          val: new Date(timestamp * 1000),
          formatParams: { val: { dateStyle: 'medium' } },
        })}
      </span>
      {options?.time && (
        <span className="font-normal leading-none text-text-tertiary">
          {t('intl.DateTime', {
            val: new Date(timestamp * 1000),
            formatParams: { val: { timeStyle: 'short' } },
          })}
        </span>
      )}
    </div>
  )
}
