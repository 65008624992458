import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { hubWebsitesQuery } from '@/layouts/hub/api'
import { Button } from '@/ui'
import { HUB_PATH, useGenerateCompanyPath } from '@/libs'
import { cn, useIsMobile } from '@dashboard/ui'
import { calcGenerationProgress, getGenerationProgressIsStuck } from '../libs'
import { GetStartedStepState } from '../types'
import { useGenerationProgressListener } from './useGenerationProgressListener'
import { useGetStarted } from './useGetStarted'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'

type HubStepTasks =
  | 'news'
  | 'updates'
  | 'events'
  | 'store'
  | 'offers'
  | 'bundles'
  | 'achievements'
  | 'leaderboards'
  | 'wiki'
  | 'daily_rewards'
  | 'loyalty_program'
  | 'cashback_rewards'

/**
 * One minute (was two)
 */
const MAX_WAITING_TIME_MS = 60 * 1000

export const useHubStep = (): GetStartedStepState<HubStepTasks> => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: progressState, isLoading } = useGenerationProgressListener()
  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const sortedSites = websites.sort((a, b) => (a.created_at > b.created_at ? 1 : -1))
  const webSite = sortedSites[0]
  const { data: website } = useQuery({
    ...hubWebsitesQuery(companyId, gameId),
    select: websites => websites.at(0),
  })

  const { check, complete } = useGetStarted()
  const isMobile = useIsMobile()

  const id = 'hub'
  const progress = useMemo(() => (!isLoading ? calcGenerationProgress(progressState) : 0), [isLoading, progressState])
  const status = check('visit_game_hub')
    ? 'completed'
    : !isLoading
      ? progress === 100
        ? 'idle'
        : 'generating'
      : 'loading'

  const isStuck = progressState ? getGenerationProgressIsStuck(progressState, MAX_WAITING_TIME_MS) : false

  const onComplete = async () => {
    await complete('visit_game_hub')
  }

  return {
    id,
    loading: false,
    status,
    progress: !isLoading ? { value: progress, isStuck } : undefined,
    available_on: ['desktop', 'mobile'],
    img: website?.config?.favicon_url,
    title: isMobile ? t(`get-started.steps.${id}.mobile.title`) : t(`get-started.steps.${id}.title`),
    desc: isMobile ? t(`get-started.steps.${id}.mobile.desc`) : t(`get-started.steps.${id}.desc`),
    tasks: {
      news: {
        label: t(`get-started.steps.${id}.tasks.news`),
        status: progress >= 8 ? 'completed' : 'loading',
      },
      updates: {
        label: t(`get-started.steps.${id}.tasks.updates`),
        status: progress >= 16 ? 'completed' : 'loading',
      },
      events: {
        label: t(`get-started.steps.${id}.tasks.events`),
        status: progress >= 24 ? 'completed' : 'loading',
      },
      store: {
        label: t(`get-started.steps.${id}.tasks.store`),
        status: progress >= 32 ? 'completed' : 'loading',
      },
      offers: {
        label: t(`get-started.steps.${id}.tasks.offers`),
        status: progress >= 40 ? 'completed' : 'loading',
      },
      bundles: {
        label: t(`get-started.steps.${id}.tasks.bundles`),
        status: progress >= 48 ? 'completed' : 'loading',
      },
      achievements: {
        label: t(`get-started.steps.${id}.tasks.achievements`),
        status: progress >= 56 ? 'completed' : 'loading',
      },
      leaderboards: {
        label: t(`get-started.steps.${id}.tasks.leaderboards`),
        status: progress >= 72 ? 'completed' : 'loading',
      },
      daily_rewards: {
        label: t(`get-started.steps.${id}.tasks.daily_rewards`),
        status: progress >= 80 ? 'completed' : 'loading',
      },
      wiki: {
        label: t(`get-started.steps.${id}.tasks.wiki`),
        status: progress >= 88 ? 'completed' : 'loading',
      },
      loyalty_program: {
        label: t(`get-started.steps.${id}.tasks.loyalty_program`),
        status: progress >= 96 ? 'completed' : 'loading',
      },
      cashback_rewards: {
        label: t(`get-started.steps.${id}.tasks.cashback_rewards`),
        status: progress >= 100 ? 'completed' : 'loading',
      },
    },
    cta: (
      <Link
        to={isMobile ? (webSite ? webSite.url : generatePath(HUB_PATH)) : generatePath(HUB_PATH)}
        target={isMobile ? '_blank' : '_self'}
        className={cn((isLoading || progress !== 100) && 'pointer-events-none')}
      >
        <Button
          className="min-w-40"
          variant={status === 'completed' ? 'outline' : 'secondary-brand'}
          size="lg"
          disabled={isLoading || progress !== 100}
          onClick={onComplete}
        >
          {isMobile ? t(`get-started.steps.${id}.mobile.cta.label`) : t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
