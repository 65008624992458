import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { Currency, ItemType, LootboxGroup, LootboxRead, LootboxRootGroup } from '@/api/dashboard'
import { SKU_ITEMS_LOOTBOX_PATH, useBanner, usePydenticForm } from '@/libs'
import { SkuGeneralFields } from '@/layouts/game-items/widgets/SkuGeneralFields'
import { ToastSeverity, useToast } from '@/ui'
import { useTranslation } from 'react-i18next'
import { cn } from '@dashboard/ui'
import { FullscreenLayout } from '@/components/layouts'
import { useQuery } from '@tanstack/react-query'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { SkuFormMediaSection, SkuFormPriceSection, SkuFormSidebar, SkuFormTopbar } from '@/layouts/game-items/widgets'
import { lootboxItemQuery } from '@/layouts/game-items/api/lootboxItemQuery'
import { useLootboxCreateMutate } from '@/layouts/game-items/api/useLootboxCreateMutate'
import { useLootboxUpdateMutate } from '@/layouts/game-items/api/useLootboxUpdateMutate'
import { LootboxItemsEditorForm } from '@/layouts/game-items/lootboxes/LootboxItemsEditor'
import { LootboxStateFields } from '@/layouts/game-items/lootboxes/LootboxStateFields'

export const LootboxFormPage = () => {
  const { t } = useTranslation()
  const { t: tLoot } = useTranslation('lootboxes')
  const { getStickyHeight } = useBanner()
  const navigate = useNavigate()
  const { companyId, gameId, itemId } = useParams() as { companyId: string; gameId: string; itemId: string }
  const { mutateAsync: updateMutateAsync } = useLootboxUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useLootboxCreateMutate(companyId, gameId)

  const { data: item, isLoading } = useQuery(lootboxItemQuery(companyId, gameId, itemId, !!itemId))

  const showToast = useToast()
  const methods = usePydenticForm<LootboxRead>({
    defaultValues: {
      name: '',
      description: '',
      currency: Currency.USD,
      price: 0,
      sku: '',
      image_url: '',
      type: ItemType.Lootbox,
      is_currency: false,
      is_stackable: false,
      quantity: 1,
      lootbox_settings: {
        template_id: '',
        purchased_video_url: '',
        claim_video_url: 'https://static.aghanim.com/images/hub/lootboxes/mid/preset_3_opening.mov',
        claimed_video_url: 'https://static.aghanim.com/images/hub/lootboxes/mid/preset_3_opened.mov',
      },
    },
    values: {
      ...(item as LootboxRead),
    },
  })

  const {
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = methods

  useEffect(() => {
    const firstError = Object.keys(errors)[0]
    if (firstError) {
      methods.setFocus(firstError as 'image_url')
    }
  }, [methods.formState.errors, methods.setFocus])

  const validateGroup = (group: LootboxGroup) => {
    if (!group.nested_items?.length) {
      setError('lootbox_settings.groups', { message: tLoot('form.group.no-items') })
      return false
    }

    const invalidItems = group.nested_items.filter(
      item => item.model_type === 'LootboxNestedItem' && (!item.count || !item.weight),
    )
    if (invalidItems.length) {
      setError('lootbox_settings.groups', { message: t('validation.empty-values') })
      return false
    }

    return true
  }

  const validateGroupFields = (groups: LootboxRootGroup[]) => {
    if (!groups?.length) {
      setError('lootbox_settings.groups', { message: tLoot('form.group.no-items') })
      return false
    }

    if (groups.find(g => g.model_type === 'LootboxGroup' && !validateGroup(g as LootboxGroup))) {
      return false
    }

    return true
  }

  const onFormSubmit = async (data: LootboxRead) => {
    const payload = {
      ...data,
      lootbox_settings: {
        ...data.lootbox_settings,
        template_id: '',
      },
    }

    if (payload.currency != Currency.RP) {
      payload.reward_points_price = null as unknown as number
    }

    if (!validateGroupFields(payload.lootbox_settings.groups)) {
      return
    }

    if (itemId) {
      await updateMutateAsync({ data: payload, id: itemId })
      reset(payload)
    } else {
      await createMutateAsync({ data: payload })
      navigate(generatePath(SKU_ITEMS_LOOTBOX_PATH, { companyId: companyId, gameId: gameId }), { replace: true })
    }

    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  return (
    <FullscreenLayout className="bg-linear-bg-gradient" isLoading={isLoading}>
      <div className={cn(getStickyHeight())}>
        <FormProvider {...methods}>
          <form className="mb-6" onSubmit={handleSubmit(onFormSubmit)}>
            <SkuFormTopbar />
            <div className="container grid grid-cols-[auto_380px] gap-10">
              <div className="max-w-[900px]">
                <SkuGeneralFields />
                <LootboxStateFields />
                <LootboxItemsEditorForm />
                <SkuFormPriceSection originalItem={item} />

                <SkuFormMediaSection />
              </div>

              <div className="sticky top-[4.75rem] h-fit">
                <SkuFormSidebar />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </FullscreenLayout>
  )
}
