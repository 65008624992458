import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Calendar2Solid, Check, InputSearch, Pause, Play, useModal, useSearchFilter } from '@dashboard/ui'
import { Badge, PageHeader, Pagination, Table, TableCell, TableRow, TableRowSkeleton, usePagination } from '@/ui'
import { useDailyRewards } from '@/layouts/engagement/hooks'
import { DailyRewardsCreateModal } from '@/layouts/engagement/widgets/DailyRewardsCreateModal'
import { TableZeroStateFilter, hasZeroState } from '@/layouts/components/TableZeroStateFilter'
import { DateTimeValue } from '@/components/ui/DateTimeValue'
import { DailyRewardsQueryParams } from '@/layouts/engagement/api'
import { DailyRewardStatus, DailyRewardType } from '@/api/dashboard'
import { generatePath, useParams } from 'react-router-dom'
import { ENGAGEMENT_DAILY_REWARDS_PROGRAM_PATH } from '@/libs'
import { useCurrentUser } from '@/api/useCurrentUser'

export const DailyRewardsPage = () => {
  const { t } = useTranslation()
  const { gameId, companyId } = useParams() as { companyId: string; gameId: string }
  const { canEdit } = useCurrentUser()

  const openCreateModal = useModal(props => <DailyRewardsCreateModal {...props} />)

  const filter = useSearchFilter<'q' | 'status'>()
  const hasFilter = useMemo(() => !!(filter.values.status || filter.values.q), [filter.values.status, filter.values.q])
  const { page, query: pagination, onPageChange, needShowPagination } = usePagination(10)
  const seasonal = useDailyRewards({
    ...pagination,
    ...(filter.values as Partial<DailyRewardsQueryParams>),
    type: DailyRewardType.Seasonal,
  })
  const { data: seasonalPrograms = [], has_next: hasNext = false } = seasonal.data || {}
  const basic = useDailyRewards({
    ...(filter.values as Partial<DailyRewardsQueryParams>),
    type: DailyRewardType.Basic,
  })
  const { data: basicPrograms = [] } = basic.data || {}
  const isLoading = useMemo(() => seasonal.isLoading || basic.isLoading, [seasonal.isLoading, basic.isLoading])
  const programs = useMemo(() => [...basicPrograms, ...seasonalPrograms], [basicPrograms, seasonalPrograms])
  const isZero = useMemo(() => hasZeroState(programs, page, hasFilter), [programs, page, hasFilter])

  const renderZeroState = () => {
    if (isLoading || !isZero) {
      return null
    }

    return (
      <TableZeroStateFilter
        page={page}
        items={programs}
        hasFilter={hasFilter}
        createClick={() => openCreateModal()}
        zeroStateMessage={t('daily-rewards.empty.title')}
        zeroStateSubMessage={t('daily-rewards.empty.message')}
        addButtonText={t('daily-rewards.program.create.button')}
        notFoundMessage={t('daily-rewards.empty.not-found')}
        clearFilterClick={filter.onReset}
      />
    )
  }

  const renderTable = () => {
    return (
      <div className="flex size-full flex-col">
        <div className="inline-flex justify-between gap-4">
          {(programs.length > 0 || hasFilter) && (
            <>
              <InputSearch {...filter.registerInput('q')} placeholder={t('search')} />
            </>
          )}
        </div>

        {renderZeroState() || (
          <Table className="size-full">
            <TableRow variant="header">
              <TableCell>{t('daily-rewards.table.name')}</TableCell>
              <TableCell width={146}>{t('daily-rewards.table.start_at')}</TableCell>
              <TableCell width={146}>{t('daily-rewards.table.end_at')}</TableCell>
              <TableCell width={146}>{t('daily-rewards.table.status')}</TableCell>
            </TableRow>

            {isLoading && <TableRowSkeleton rowCount={10} columnCount={3} />}

            {!isLoading &&
              programs.length !== 0 &&
              programs.map((program, index) => (
                <Fragment key={program.id}>
                  {index === 0 && basicPrograms.length > 0 && (
                    <TableRow>
                      <div className="size-full bg-fg-primary-hover p-3">
                        <span className="text-caption-md font-semibold">{t('daily-rewards.table.basic')}</span>
                      </div>
                    </TableRow>
                  )}
                  {index === basicPrograms.length && (
                    <TableRow>
                      <div className="size-full bg-fg-primary-hover p-3">
                        <span className="text-caption-md font-semibold">{t('daily-rewards.table.seasonal')}</span>
                      </div>
                    </TableRow>
                  )}
                  <TableRow
                    key={program.id}
                    to={generatePath(ENGAGEMENT_DAILY_REWARDS_PROGRAM_PATH, {
                      companyId,
                      gameId,
                      programId: program.id,
                    })}
                  >
                    <TableCell>{program.name}</TableCell>
                    <TableCell width={146}>
                      {program.start_at ? <DateTimeValue value={program.start_at} /> : t('daily-rewards.type.no-date')}
                    </TableCell>
                    <TableCell width={146}>
                      {program.end_at ? <DateTimeValue value={program.end_at} /> : t('daily-rewards.type.no-date')}
                    </TableCell>
                    <TableCell width={146}>
                      {program.status === DailyRewardStatus.Active && (
                        <Badge variant="lime-secondary">
                          <Play />
                          <span>{t('daily-rewards.status.active')}</span>
                        </Badge>
                      )}
                      {program.status === DailyRewardStatus.Paused && (
                        <Badge variant="orange-tertiary">
                          <Pause />
                          <span>{t('daily-rewards.status.paused')}</span>
                        </Badge>
                      )}
                      {program.status === DailyRewardStatus.Stopped && (
                        <Badge variant="gray-secondary">
                          <Pause />
                          <span>{t('daily-rewards.status.stopped')}</span>
                        </Badge>
                      )}
                      {program.status === DailyRewardStatus.Scheduled && (
                        <Badge variant="brand-blue-tertiary">
                          <Calendar2Solid />
                          <span>{t('daily-rewards.status.scheduled')}</span>
                        </Badge>
                      )}
                      {program.status === DailyRewardStatus.Completed && (
                        <Badge variant="gray-tertiary">
                          <Check strokeWidth={2} />
                          <span>{t('daily-rewards.status.completed')}</span>
                        </Badge>
                      )}
                    </TableCell>
                  </TableRow>
                </Fragment>
              ))}
          </Table>
        )}

        {needShowPagination(isLoading, programs) && (
          <Pagination hasNext={hasNext} page={page} onPageChange={onPageChange} pageItems={programs.length} />
        )}
      </div>
    )
  }

  return (
    <div className="flex size-full flex-col">
      <PageHeader
        sticky
        extra={
          canEdit &&
          !isZero && (
            <Button color="primary" size="sm" onClick={() => openCreateModal()}>
              {t('daily-rewards.program.create.button')}
            </Button>
          )
        }
      >
        {t('sidebar.daily-rewards')}
      </PageHeader>

      <div className="size-full">{renderTable()}</div>
    </div>
  )
}
