import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@dashboard/ui'
import {
  CASHBACK_REWARDS_CREATE_PATH,
  SkuCashbackRewardsRow,
  SkuCashbackSkeleton,
  skuCashbackRewardsQueryOpts,
} from 'src/layouts/cashback-rewards'
import { Page, PageHeader, TableCell, TableRow } from '@/ui'
import { useFeatureFlags, useGenerateCompanyPath } from '@/libs'
import { RewardPointsRow } from '@/layouts/cashback-rewards/components/RewardPointsRow'
import { LoyaltyPointsRow } from '@/layouts/cashback-rewards/components/LoyaltyPointsRow'
import { useCurrentUser } from '@/api/useCurrentUser'

export const SkuCashbackRewardsPage = () => {
  const { t } = useTranslation('sku-cashback')
  const { featureFlags } = useFeatureFlags()
  const { canEdit } = useCurrentUser()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: rewards = [], isLoading } = useQuery(skuCashbackRewardsQueryOpts(companyId, gameId))

  if (isLoading) {
    return (
      <Page>
        <PageHeader>{t('title')}</PageHeader>
        <SkuCashbackSkeleton />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader
        extra={
          canEdit && (
            <Link to={generatePath(CASHBACK_REWARDS_CREATE_PATH)}>
              <Button>{t('add')}</Button>
            </Link>
          )
        }
      >
        {t('title')}
      </PageHeader>

      <TableRow variant="header">
        <TableCell width="100%">{t('table.name')}</TableCell>
        <TableCell width="60%">{t('table.linked-item')}</TableCell>
        <TableCell width="60%">{t('table.exchange-rate')}</TableCell>
        <TableCell width="50%">{t('table.category')}</TableCell>
        <TableCell width="25%">{t('table.status')}</TableCell>
        <TableCell width={52} />
      </TableRow>

      <RewardPointsRow />
      {featureFlags?.loyalty_program_enabled && <LoyaltyPointsRow />}

      {rewards.map(reward => (
        <SkuCashbackRewardsRow reward={reward} key={reward.id} />
      ))}
    </Page>
  )
}
