import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Price, PriceCategory, PriceTemplateRead } from '@/api/dashboard'
import { dashboardClientV1 } from '@/api'
import { ModalRushPage } from '@/ui'
import { useGenerateCompanyPath, useNavigateBack } from '@/libs'
import { SKU_PRICE_TEMPLATES_EDIT_PATH, SKU_PRICE_TEMPLATES_PATH } from '../paths'
import { PriceTemplateModal } from '../components'

export const PriceTemplateCreatePage = () => {
  const back = useNavigateBack({ fallback: SKU_PRICE_TEMPLATES_PATH })
  const navigate = useNavigate()
  const { companyId, gameId, generatePath } = useGenerateCompanyPath()

  const [emptyTemplate, setEmptyTemplate] = useState<PriceTemplateRead | null>(null)

  useEffect(() => {
    const emptyPriceTemplate: Omit<PriceTemplateRead, 'price'> = {
      id: '',
      name: '',
      category: PriceCategory.None,
      prices: [],
      created_at: new Date().getTime() / 100,
    }

    dashboardClientV1.v1
      .getDefaultTemplatePrices(companyId, gameId, {
        price: undefined,
        category: emptyPriceTemplate.category,
      })
      .then(({ data: prices }) => {
        setEmptyTemplate({
          ...emptyPriceTemplate,
          price: 0,
          prices,
        })
      })
  }, [])

  const onFormSubmit = async (data: PriceTemplateRead) => {
    const customs: Price[] = data.prices
      .filter(price => price.is_custom)
      .map(price => ({
        country: price.country,
        currency: price.currency,
        platform: price.platform,
        price: price.price,
      }))

    const { data: priceTemplate } = await dashboardClientV1.v1.createPriceTemplate(companyId, gameId, {
      ...data,
      customs,
    })

    setTimeout(() => {
      navigate(generatePath(SKU_PRICE_TEMPLATES_EDIT_PATH, { priceTemplateId: priceTemplate.id }), { replace: true })
    }, 1)
  }

  return (
    <ModalRushPage back={back}>
      {props => <PriceTemplateModal {...props} template={emptyTemplate} mode="manage" onSubmit={onFormSubmit} />}
    </ModalRushPage>
  )
}
