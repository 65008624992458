import { ReactNode, useState } from 'react'
import { ChevronDown, ChevronUp, getMotionProps } from '@dashboard/ui'
import { AnimatePresence, motion } from 'framer-motion'

interface FormSectionProps {
  children: ReactNode
  label?: ReactNode
  action?: ReactNode
}

const entryMotionProps = getMotionProps({
  initial: { paddingTop: 0, height: 0, opacity: 0 },
  animate: { paddingTop: 0, height: 'auto', opacity: 1 },
  exit: { paddingTop: 0, height: 0, opacity: 0 },
})

export const CollapseFormSection = ({ children, label }: FormSectionProps) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className="mb-2.5 flex flex-col rounded-2xl1 border border-border-primary bg-fg-primary shadow-xs last:mb-0">
      {label && (
        <div
          className={'flex cursor-pointer select-none items-center border-b border-border-secondary p-6 text-t4'}
          onClick={() => setIsOpened(!isOpened)}
        >
          {label}
          <div className="ml-auto">
            {isOpened ? (
              <ChevronUp className="text-text-brand-secondary" size={18} />
            ) : (
              <ChevronDown className="text-text-brand-secondary" size={18} />
            )}
          </div>
        </div>
      )}
      <AnimatePresence>{isOpened && <motion.div {...entryMotionProps}>{children}</motion.div>}</AnimatePresence>
    </div>
  )
}
