import { useContext } from 'react'
import { CampaignContext, ICampaignContext } from '../Context'
import { AddToSegmentNode, Node, SegmentRead } from '@/api/dashboard'
import { BlockError } from '../types'
import EditSegmentDialog from '../../segment/widgets/EditSegmentDialog'
import { COLORS, FieldGroup } from '@/ui'
import { SelectSegment } from '../../components/SelectSegment/SelectSegment'
import { useSegmentsQuery } from '../../segment/api/useSegmentsQuery'
import { useParams } from 'react-router-dom'
import { Button, MOTION_ANIMATION_SPEED_MD, useModal } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

export default function AddToSegmentEditor(props: {
  node: AddToSegmentNode
  error?: BlockError | null
  setNode: (node: Node) => void
}) {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { data: segments = [] } = useSegmentsQuery(companyId, gameId, {
    limit: 100,
  })
  const context = useContext(CampaignContext) as ICampaignContext

  const selectedSegment = segments.find(s => s.slug === props.node.segment_slug)

  const openAddSegmentModal = useModal<{ item: SegmentRead; onClose?: () => void }>(props => (
    <EditSegmentDialog {...props} onClose={props.onClose!} />
  ))

  return (
    <div>
      <FieldGroup label={t('campaign.block.AddToSegmentNode.add-user-to-segment')}>
        <SelectSegment
          segmentGroupId={context.segmentGroupId}
          onChange={v => {
            let s = segments.find(s => s.id === v)
            props.setNode({
              ...props.node,
              model_type: 'AddToSegmentNode',
              segment_slug: s?.slug || '',
            })
          }}
          value={selectedSegment?.id || ''}
          renderMenuFooter={state => (
            <div className="flex w-full justify-center">
              <Button
                className="w-1/2 "
                variant="outline"
                size="md"
                color="primary"
                onClick={() => {
                  state.setIsOpen(false)
                  setTimeout(() => {
                    openAddSegmentModal({
                      item: {
                        id: '',
                        segment_group_id: context.segmentGroupId,
                        name: '',
                        slug: '',
                        color: COLORS[0],
                      } as SegmentRead,
                    })
                  }, MOTION_ANIMATION_SPEED_MD * 500)
                }}
              >
                {t('campaign.type.add_segment')}
              </Button>
            </div>
          )}
        />
      </FieldGroup>
    </div>
  )
}
