import { Trans, useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { CalendarOutline, ClockOutline, InfoCircle, LighThing, useModal } from '@dashboard/ui'
import {
  Badge,
  PageHeader,
  Pagination,
  Table,
  TableCell,
  TableRow,
  TableRowSkeleton,
  TableZeroState,
  usePagination,
} from '@/ui'
import { formatMoney } from '@/util'
import { DashboardLocale, Payout, PayoutRequestStatus } from '@/api/dashboard'
import { useCurrentUser } from '@/api/useCurrentUser'
import { PayoutsChart } from '../widgets/PayoutsChart'
import { addTimezoneOffset } from '@/libs/dates'
import { PayoutStatus, PayoutType } from '@/layouts/payouts/types'
import { BalanceCard } from '@/layouts/dashboard'
import { usePayoutsByMonthQuery, usePayoutsQuery } from '../api'
import { InstantPayoutModal } from '../widgets/InstantPayoutModal'
import { PayoutResultModal } from '@/layouts/payouts/widgets/PayoutResultModal'
import { Check } from '@/layouts/transaction/icons/Check'
import { useFeatureFlags, useGenerateCompanyPath } from '@/libs'
import { useCurrentGame } from '@/api/useCompaniesQuery'
import { RequestInstantPayoutModal } from '../widgets/RequestInstantPayoutModal'

export const PayoutsPage = () => {
  const { t } = useTranslation()
  const { isAdmin, customer } = useCurrentUser()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { company } = useCurrentGame()
  const { data: payouts = [], isLoading, isError, refetch } = usePayoutsQuery(companyId, gameId)
  const { refetch: refetchChart } = usePayoutsByMonthQuery(companyId, gameId)
  const { page, query, onPageChange, needShowPagination } = usePagination(20)
  const { featureFlags } = useFeatureFlags()

  const openResultPayoutModal = useModal<{ total: number }>(props => <PayoutResultModal {...props} />)

  const openInstantPayoutModal = useModal(props => (
    <InstantPayoutModal
      onCreated={total => {
        refetch()
        refetchChart()
        openResultPayoutModal({ total })
      }}
      {...props}
    />
  ))

  const openRequestInstantPayoutModal = useModal(props => <RequestInstantPayoutModal {...props} />)

  const getStatus = (p: Payout) => {
    switch (p.payout_status) {
      case PayoutStatus.pending:
      case PayoutStatus.draft:
      case PayoutStatus.approved:
        return (
          <Badge variant="gray-secondary">
            <ClockOutline />
            {t('payouts.table.status.pending')}
          </Badge>
        )
      case PayoutStatus.paid:
        return (
          <Badge variant="green-primary" className="whitespace-nowrap">
            {t('payouts.table.status.completed')} <Check />
          </Badge>
        )
    }
  }

  const getType = (p: Payout) => {
    switch (p.payout_type) {
      case PayoutType.instant:
        return (
          <div className="flex items-center gap-1.5">
            <LighThing />
            {t(`payouts.table.type.instant`)}
          </div>
        )
      case PayoutType.regular:
        return (
          <div className="flex items-center gap-1.5">
            <CalendarOutline />
            {t(`payouts.table.type.regular`)}
          </div>
        )
    }
  }

  const getPeriod = (p: Payout) => {
    if (p.payout_type == PayoutType.instant) {
      return '– / –'
    }

    const start = dayjs(addTimezoneOffset(new Date(p.start_date / 1000)))
    const end = dayjs(addTimezoneOffset(new Date(p.ending_date / 1000)))

    if (customer.locale == DashboardLocale.En) {
      if (start.month() == end.month()) {
        return `${start.format('D')} - ${end.format('D')} ${t('intl.DateTime', {
          val: end.toDate(),
          formatParams: {
            val: { month: 'short', year: 'numeric' },
          },
        })}`
      }
    }
    if (start.year() == end.year()) {
      return `${t('intl.DateTime', {
        val: start.toDate(),
        formatParams: { val: { dateStyle: 'long' } },
      })} - ${t('intl.DateTime', {
        val: end.toDate(),
        formatParams: { val: { dateStyle: 'long' } },
      })}`
    }

    return `${t('intl.DateTime', {
      val: start.toDate(),
      formatParams: {
        val: { month: 'short', year: 'numeric' },
      },
    })} - ${t('intl.DateTime', {
      val: end.toDate(),
      formatParams: {
        val: { month: 'short', year: 'numeric' },
      },
    })}`
  }

  const getPayoutDate = (p: Payout) => {
    if (p.payout_status != PayoutStatus.paid) {
      return '– / –'
    }

    return t('intl.DateTime', {
      val: addTimezoneOffset(new Date(p.paid_date / 1000)),
      formatParams: { val: { dateStyle: 'long' } },
    })
  }

  const onPayoutClick = () => {
    if (!company?.instant_payout_status) {
      openRequestInstantPayoutModal()
    } else if (company?.instant_payout_status == PayoutRequestStatus.Approved) {
      openInstantPayoutModal()
    }
  }

  return (
    <div className="flex h-full flex-col">
      <PageHeader>{t('sidebar.payouts')}</PageHeader>

      <div className="mb-5 flex gap-5">
        <BalanceCard
          payoutClick={isAdmin && featureFlags.instant_payouts_enabled ? onPayoutClick : undefined}
          status={company?.instant_payout_status}
        />
        <PayoutsChart />
      </div>

      {isLoading ? (
        <TableRowSkeleton rowCount={query.limit} columnCount={3} />
      ) : payouts.length === 0 ? (
        <div className="grow">
          <TableZeroState
            title={t(isError ? 'service-unavailable' : 'payouts.empty-table.title')}
            message={t(isError ? 'SomethingWentWrong' : 'payouts.empty-table.desc')}
          />
        </div>
      ) : (
        <>
          <Table data-testid="payout-table">
            <TableRow variant="header">
              <TableCell width="100%">{t('payouts.table.amount')}</TableCell>
              <TableCell width="30%">{t('payouts.table.type')}</TableCell>
              <TableCell width="30%">{t('payouts.table.status')}</TableCell>
              <TableCell width="40%">{t('payouts.table.payout-date')}</TableCell>
              <TableCell width="50%">{t('payouts.table.period')}</TableCell>
            </TableRow>

            {payouts.map((payout, idx) => (
              <TableRow key={idx} className="h-[64px]">
                <TableCell width="100%">{formatMoney(payout.payout_amount)}</TableCell>
                <TableCell width="30%">{getType(payout)}</TableCell>
                <TableCell width="30%">{getStatus(payout)}</TableCell>
                <TableCell width="40%">{getPayoutDate(payout)}</TableCell>
                <TableCell width="50%">{getPeriod(payout)}</TableCell>
              </TableRow>
            ))}
          </Table>

          {needShowPagination(isLoading, payouts) ? (
            <Pagination
              hasNext={payouts.length >= query.limit}
              page={page}
              onPageChange={onPageChange}
              pageItems={payouts.length}
            />
          ) : (
            <div className="border-t border-border-secondary" />
          )}
        </>
      )}

      <div className="mt-auto pt-6">
        <div className="flex items-start gap-3 rounded-md border border-border-secondary bg-fg-secondary px-3 py-2 font-[12px] leading-[14px] text-text-secondary">
          <InfoCircle size={14} />
          <div>
            <Trans
              i18nKey="payouts.callout.text"
              values={{ email: 'partners@aghanim.com' }}
              components={{
                a: <a href="mailto:partners@aghanim.com" target="_blank" />,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
