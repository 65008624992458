import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'
import { PriceTemplateShort } from '@/api/dashboard'

interface PriceTemplateModalConfirmDeleteProps extends ModalProps {
  template: PriceTemplateShort
}

export const PriceTemplateModalCannotDelete = ({ template, ...rest }: PriceTemplateModalConfirmDeleteProps) => {
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'modal-cannot-delete' })

  return (
    <Modal {...rest}>
      <ModalContent>
        <ModalTitle
          onClose={rest.onClose}
          subtitle={<span className="whitespace-pre-line">{t('subtitle', { count: template.items_count })}</span>}
        >
          {t('title')}
        </ModalTitle>
      </ModalContent>
      <ModalFooter>
        <Button color="secondary" size="sm" variant="outline" onClick={rest.onClose}>
          <span className="px-5">{t('action.cancel')}</span>
        </Button>
      </ModalFooter>
    </Modal>
  )
}
