import { Position } from 'reactflow'
import { DescBlock, IOHandle, hasNextNode } from './common'
import { getNodeColor, getNodeIcon } from '../icons'
import { NextNode } from '../components/NextNode/NextNode'
import { IBlockProps } from '../types'
import { ShadowHoverBlock } from '@/layouts/campaigns/components/ShadowHoverBlock'

export default function ItemDiscountNode(props: IBlockProps) {
  const nodeColor = getNodeColor(props.data.node.model_type)
  return (
    <>
      <IOHandle color={nodeColor} position={Position.Top} type="target" id="input" />
      <ShadowHoverBlock selected={props.selected} color={nodeColor}>
        {getNodeIcon(props.data.node.model_type)}
        <DescBlock node={props.data.node} width={'224px'} selected={props.selected} />
      </ShadowHoverBlock>
      <NextNode {...props} />
      <IOHandle
        color={nodeColor}
        hasNext={hasNextNode(props.data.graph, props.data.node)}
        position={Position.Bottom}
        type="source"
        id="output"
      />
    </>
  )
}
