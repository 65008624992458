import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from 'lexical'
import { $isCodeHighlightNode } from '@lexical/code'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { mergeRegister } from '@lexical/utils'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { getDOMRangeRect, getSelectedNode, setFloatingElemPosition } from '../../libs'
import { Bold02, Code02, Italic, Underline, Unlink } from '@/icons'
import { getMotionProps } from '@/libs'
import { ToolbarButton } from '../ToolbarPlugin/ToolbarButton'

interface FloatingToolbarProps {
  editor: LexicalEditor
  anchorElem: HTMLElement
  isBold: boolean
  isItalic: boolean
  isUnderline: boolean
  isCode: boolean
  isLink: boolean
  setIsLinkEditMode: Dispatch<boolean>
}

const toolbarMotionProps = getMotionProps({
  initial: { y: 1, scale: 0.95, opacity: 0 },
  animate: { y: 0, scale: 1, opacity: 1, transition: { delay: 0.1 } },
  exit: { y: -1, scale: 0.95, opacity: 0, transition: { delay: 0.1 } },
})

const FloatingToolbar = ({
  editor,
  anchorElem,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isLink,
  setIsLinkEditMode,
}: FloatingToolbarProps) => {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)

  const mouseMoveListener = (e: MouseEvent) => {
    if (popupCharStylesEditorRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)
        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          popupCharStylesEditorRef.current.style.pointerEvents = 'none'
        }
      }
    }
  }

  const mouseUpListener = () => {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto'
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener)
      document.addEventListener('mouseup', mouseUpListener)

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener)
        document.removeEventListener('mouseup', mouseUpListener)
      }
    }
  }, [popupCharStylesEditorRef])

  const $updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()

    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)

      setFloatingElemPosition(rangeRect, popupCharStylesEditorElem, anchorElem, isLink)
    }
  }, [editor, anchorElem])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        $updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener('resize', update)
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update)
    }

    return () => {
      window.removeEventListener('resize', update)
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update)
      }
    }
  }, [editor, $updateTextFormatFloatingToolbar, anchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextFormatFloatingToolbar()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, $updateTextFormatFloatingToolbar])

  const onClickButton = (payload: TextFormatType) => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, payload)
  }

  const onClickInsertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      setIsLinkEditMode(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, setIsLinkEditMode, isLink])

  if (!editor.isEditable()) {
    return
  }

  return (
    <div ref={popupCharStylesEditorRef} className="absolute left-0 top-0 z-[100] transition-all will-change-transform">
      <motion.div
        {...toolbarMotionProps}
        className="flex items-center gap-1 rounded-xl border border-border-primary bg-fg-primary p-2 shadow-xl"
      >
        <ToolbarButton data-selected={isBold} onClick={() => onClickButton('bold')}>
          <Bold02 size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isItalic} onClick={() => onClickButton('italic')}>
          <Italic size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isUnderline} onClick={() => onClickButton('underline')}>
          <Underline size={16} />
        </ToolbarButton>
        <ToolbarButton data-selected={isCode} onClick={() => onClickButton('code')}>
          <Code02 size={16} />
        </ToolbarButton>

        <ToolbarButton data-selected={isLink} onClick={onClickInsertLink}>
          <Unlink size={16} />
        </ToolbarButton>
      </motion.div>
    </div>
  )
}

interface FloatingToolbarPluginProps {
  anchorElem?: HTMLElement
  setIsLinkEditMode: Dispatch<boolean>
}

export const FloatingToolbarPlugin = ({
  anchorElem = document.body,
  setIsLinkEditMode,
}: FloatingToolbarPluginProps): JSX.Element | null => {
  const [editor] = useLexicalComposerContext()

  const [isText, setIsText] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [isLink, setIsLink] = useState(false)

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      if (editor.isComposing()) {
        return
      }

      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) || rootElement === null || !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = getSelectedNode(selection)

      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsCode(selection.hasFormat('code'))

      // Update links
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (!$isCodeHighlightNode(selection.anchor.getNode()) && selection.getTextContent() !== '') {
        setIsText($isTextNode(node) || $isParagraphNode(node))
      } else {
        setIsText(false)
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '')
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsText(false)
        return
      }
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup)
    return () => {
      document.removeEventListener('selectionchange', updatePopup)
    }
  }, [updatePopup])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      }),
    )
  }, [editor, updatePopup])

  return createPortal(
    <AnimatePresence>
      {isText && (
        <FloatingToolbar
          editor={editor}
          anchorElem={anchorElem}
          isBold={isBold}
          isItalic={isItalic}
          isUnderline={isUnderline}
          isCode={isCode}
          isLink={isLink}
          setIsLinkEditMode={setIsLinkEditMode}
        />
      )}
    </AnimatePresence>,
    anchorElem,
  )
}
