import { queryOptions } from '@tanstack/react-query'
import { dashboardClient } from '@/api'

/**
 *
 * @param companyId
 * @param gameId
 * @param id
 * @param enabled
 */
export const lootboxItemQuery = (companyId: string, gameId: string, id: string, enabled = true) =>
  queryOptions({
    enabled: enabled,
    queryKey: [gameId, 'lootbox-item', { id }],
    queryFn: () => dashboardClient.v1.getLootbox(companyId, gameId, id).then(r => r.data),
  })
