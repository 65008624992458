import { forwardRef, useId } from 'react';
import { ToggleTabsButton, ToggleTabsButtonProps } from './ToggleTabsButton';
import { cn } from '../../libs';
import { AnimatePresence } from 'framer-motion';

export interface ToggleTabsTabProps<T> extends Omit<ToggleTabsButtonProps, 'selected'> {
  value: T;
}

interface ToggleTabsProps<T = string | number | null> {
  tabs: ToggleTabsTabProps<T>[];
  value: T;
  stretch?: boolean;
  onChange?: (value: T) => void;
}

export const ToggleTabs = forwardRef<HTMLDivElement, ToggleTabsProps>(({ tabs, value, stretch, onChange }, ref) => {
  const id = useId();

  return (
    <div
      ref={ref}
      className={cn('flex w-fit items-center gap-0.5 rounded-lg bg-fg-secondary p-0.5', stretch && 'w-full')}
    >
      <AnimatePresence>
        {tabs.map(tab => (
          <ToggleTabsButton
            {...tab}
            key={`toggle-tabs-tab-${id}-${tab.value as string}`}
            selected={tab.value === value}
            onClick={() => onChange?.(tab.value)}
          >
            {tab.children}
          </ToggleTabsButton>
        ))}
      </AnimatePresence>
    </div>
  );
});
