import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@/ui'
import { LIVEOPS_PATH_TABLE, useGenerateCompanyPath } from '@/libs'
import { campaignsQuery } from '../../campaigns/api'
import { GetStartedStepState } from '../types'
import { useGetStarted } from './useGetStarted'

/**
 *
 */
export const useLiveopsStep = (): GetStartedStepState<'publishCampaign'> => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const navigate = useNavigate()
  const { data: campaigns = [], isLoading } = useQuery(campaignsQuery(companyId, gameId))
  const { check, complete } = useGetStarted()

  const id = 'liveops'

  const status = useMemo(
    () => (check('liveops_published') ? 'completed' : campaigns.filter(c => c.enabled).length ? 'completed' : 'idle'),
    [check, campaigns],
  )

  useEffect(() => {
    if (status !== 'completed') {
      return
    }
    complete('liveops_published')
  }, [complete, status])

  const onClick = () => {
    navigate(generatePath(LIVEOPS_PATH_TABLE), { state: { openCreateCampaign: true } })
  }

  return {
    id,
    loading: isLoading,
    status,
    available_on: ['desktop'],
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    tasks: {
      publishCampaign: {
        label: t(`get-started.steps.${id}.tasks.publishCampaign`),
        status: campaigns.filter(c => c.enabled).length ? 'completed' : 'idle',
      },
    },
    cta: (
      <div onClick={onClick}>
        <Button className="min-w-40" variant={status === 'completed' ? 'outline' : 'secondary-brand'} size="lg">
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </div>
    ),
  }
}
