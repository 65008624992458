/**
 *
 * @param currency
 * @param locale
 */
export const getCurrencyName = (currency: string, locale: string) => {
  return (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'name',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .formatToParts(1)
      .find(part => part.type === 'currency')?.value || ''
  )
}
