import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import CircularProgress from '@mui/material/CircularProgress'
import { ArrowNarrowRight, ClockOutline } from '@/icons'
import { GET_STARTED_PATH, getMotionProps, getPercentFrom, useGenerateCompanyPath } from '@/libs'
import { useGenerationProgressListener } from '../hooks'
import { calcStatesProgress } from '../libs/calcStatesProgress'
import { useSteps } from '../hooks/useSteps'

const calloutMotionProps = getMotionProps(
  {
    initial: { y: -4, marginBottom: 0, height: 0, opacity: 0 },
    animate: { y: 0, marginBottom: 12, height: 'auto', opacity: 1 },
    exit: { y: -4, marginBottom: 0, height: 0, opacity: 0 },
  },
  { delay: 0.2 },
)

export const GetStartedCallout = () => {
  useGenerationProgressListener()

  const { t } = useTranslation()
  const { generatePath } = useGenerateCompanyPath()

  const states = useSteps()

  const allDataLoaded = useMemo(() => Object.values(states).every(s => !s.loading), [states])

  const progress = useMemo(() => calcStatesProgress(states), [states])

  const onlyVerifyReview = useMemo(
    () =>
      progress.total - progress.completed === 1 &&
      Object.entries(states).some(([key, value]) => key === 'verifyAccount' && value.status === 'review'),
    [progress, states],
  )

  return (
    <AnimatePresence>
      {allDataLoaded && progress.completed !== progress.total && (
        <motion.div {...calloutMotionProps} className="group relative mx-3">
          <div className="pointer-events-none absolute inset-[-2px] z-0 rounded-[calc(0.5rem+2px)] bg-gradient-to-r from-blue-300 to-pink-300 p-px opacity-20 transition-all group-hover:opacity-50" />
          <NavLink
            to={generatePath(GET_STARTED_PATH)}
            className="group/callout relative z-10 flex rounded-lg bg-gradient-to-r from-blue-300 to-pink-300 p-px"
          >
            <div className="w-full rounded-[calc(0.5rem-1px)] bg-bg-primary p-2 transition-colors group-hover:bg-bg-secondary group-[.active]/callout:bg-bg-secondary">
              <div className="mb-1.5 flex items-center justify-between text-caption-sm text-text-secondary">
                <span>{t('get-started.callout.title')}</span>
                <ArrowNarrowRight className="text-text-tertiary" size={12} strokeWidth={3} />
              </div>
              <div className="mb-2 text-caption-sm font-normal text-text-tertiary">{t('get-started.callout.text')}</div>

              {onlyVerifyReview ? (
                <div className="flex items-center gap-1.5  text-text-tertiary">
                  <ClockOutline size={14} />
                  <div className="text-caption-sm">{t('get-started.callout.under-review')}</div>
                </div>
              ) : (
                <div className="flex items-center gap-1.5">
                  <CircularProgress
                    className="flex"
                    variant="determinate"
                    thickness={6}
                    size={14}
                    value={getPercentFrom(progress.completed, progress.total)}
                  />
                  <div className="text-caption-sm text-text-tertiary">
                    {[progress.completed, progress.total].join('/')}
                  </div>
                  <div className="text-caption-sm text-text-tertiary">{t('get-started.callout.completed-count')}</div>
                </div>
              )}
            </div>
          </NavLink>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
