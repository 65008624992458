import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, ScrollRestoration, useLocation, useMatch, useParams } from 'react-router-dom'
import { Button, Modal, ModalContent, ModalFooter, UIProvider, cn, useIsMobile } from '@dashboard/ui'
import i18n from 'i18next'
import { AppSidebar, ModalProvider, TableZeroState } from '@/ui'
import Footer from './components/Footer/Footer'
import AppHeader from './header/AppHeader'
import { AuthContext, IAuthContext } from '@/Context'
import { ResponseError, dashboardClient } from '@/api'
import { goToLogout } from '@/util'
import { GET_STARTED_VERIFY_ACCOUNT_PATH, HOME_PATH, useGenerateCompanyPath } from '@/libs'
import { MobileContext, SocketProvider } from '@/providers'
import { useBanner } from '@/libs/hooks/useBanner'
import { useCompaniesQuery } from '@/api/useCompaniesQuery'
import { getFeatureByPath } from '@/libs/features'
import { useCurrentUser } from '@/api/useCurrentUser'
import { DashboardAccessLevel } from '@/api/DashboardAccessLevel'
import ErrorPage from '@/layouts/errors/ErrorPage'
import ErrorBoundary from '@/components/ErrorBoundary'
import { MobileOverlay } from '@/components/ui/MobileOverlay/MobileOverlay'
import ModalUiOutlet from '@/auth/ModalUiOutlet'
import FullScreenActionBackground from './components/FullScreenActionBackground/FullScreenActionBackground'

export const MainOutlet = () => {
  const { companyId, gameId } = useParams() as { companyId: string; gameId: string }
  const { leftOpened } = useContext(MobileContext)
  const [isForceMobileView, setForceMobileView] = useState(false)
  const context = useContext(AuthContext) as IAuthContext
  const { getFeatureAccessType } = useCurrentUser()
  const { getStickyTop, bannerMode, getStickyHeight } = useBanner()
  const { data: dataCompanies } = useCompaniesQuery()
  const { generatePath } = useGenerateCompanyPath()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const match = useMatch('/company/:companyId/:gameId/*')
  const location = useLocation()

  const mobileOptimizedPaths = ['', 'get-started', 'experts']

  const notMobileOptimized = match && match.params['*'] && mobileOptimizedPaths.indexOf(match?.params['*']) === -1
  const isMobilePlaceholder = isMobile && notMobileOptimized && !isForceMobileView

  useEffect(() => {
    if (isMobile) {
      setForceMobileView(false)
      window.scroll(0, 0)
    }
  }, [location, isMobile])

  const MobilePlaceholder = () => (
    <ModalUiOutlet>
      <FullScreenActionBackground>
        <Modal className="mx-6" onClose={() => setForceMobileView(true)} onResize={() => {}}>
          <ModalContent>
            <h1 className="pt-6 text-[14px]">{t('mobile.dialog.text')}</h1>
          </ModalContent>
          <ModalFooter>
            <Button onClick={() => setForceMobileView(true)}>{t('mobile.dialog.accept')}</Button>
            <Link className="text-center" to={generatePath(HOME_PATH, { companyId: companyId, gameId: gameId })}>
              <Button variant="link">{t('mobile.dialog.decline')}</Button>
            </Link>
          </ModalFooter>
        </Modal>
      </FullScreenActionBackground>
    </ModalUiOutlet>
  )

  const loadCustomer = async () => {
    try {
      let { data } = await dashboardClient.v1.getCompanyProfile(companyId)
      if (data) {
        context?.setCustomer(data)
      }
    } catch (e) {
      if ((e as ResponseError).status == 401) {
        goToLogout()
      }
    }
  }

  useEffect(() => {
    if (companyId) {
      loadCustomer()
    }
  }, [companyId])

  if (!companyId) {
    return (
      <ModalProvider>
        <div className="container flex h-screen w-full flex-col items-center justify-between">
          <AppHeader />
          <Outlet />
          <Footer />
        </div>
      </ModalProvider>
    )
  }

  const renderBanner = () => {
    if (bannerMode == 'test') {
      return (
        <div
          className={
            'sticky top-0 z-[100] flex h-banner w-full items-center justify-center bg-fg-warning-solid text-caption-sm font-medium uppercase text-text-inverse'
          }
        >
          Sandbox
        </div>
      )
    }

    if (bannerMode == 'demo') {
      const companies = dataCompanies?.data || []
      let nonDemoCompany = companies.find(it => !it.is_demo && it.games && it.games.length > 0)

      return (
        <div
          className={
            'sticky top-0 z-[100] flex h-banner w-full items-center justify-center gap-3 bg-fg-brand-primary text-caption-sm text-text-inverse'
          }
        >
          <span>{t(isMobile ? 'banner.demo-text-mobile' : 'banner.demo-text')}</span>
          {nonDemoCompany && nonDemoCompany?.games && nonDemoCompany?.games?.length > 0 && (
            <Link
              to={generatePath(HOME_PATH, { companyId: nonDemoCompany.id, gameId: nonDemoCompany.games![0].id })}
              className="font-medium underline"
            >
              {t(isMobile ? 'banner.demo.exit-mobile' : 'banner.demo.exit')}
            </Link>
          )}
        </div>
      )
    }
  }

  const renderOutlet = () => {
    if (!match) {
      return <Outlet />
    }
    const feature = getFeatureByPath(match.pathname, match.params.companyId!, match.params.gameId!)
    if (!feature) {
      return <Outlet />
    }

    const at = getFeatureAccessType(feature)

    if (at == DashboardAccessLevel.ALLOWED) {
      return <Outlet />
    }

    if (!at || at == DashboardAccessLevel.BLOCKED) {
      return null
    }

    return (
      <TableZeroState
        className="mb-3"
        title={t('feature.check')}
        message={t('feature.check.desc')}
        buttons={
          <Link to={generatePath(GET_STARTED_VERIFY_ACCOUNT_PATH)}>
            <Button className="min-w-40">{t(`get-started.steps.verifyAccount.title`)}</Button>
          </Link>
        }
      />
    )
  }

  if (isMobilePlaceholder) {
    return <MobilePlaceholder />
  }

  return (
    <SocketProvider>
      <UIProvider i18n={i18n} staticImagesPath={import.meta.env.VITE_STATIC_IMAGES_URL}>
        <ErrorBoundary fallback={<ErrorPage />}>
          <ModalProvider>
            {renderBanner()}
            <div
              className="group/main-outlet flex bg-fg-primary transition-[margin_opacity] [&[data-modal-rush='true']]:mt-1 [&[data-modal-rush='true']]:opacity-0"
              data-id="main-outlet-root"
            >
              <div
                className={cn(
                  'sticky w-[256px] transition-[top]',
                  getStickyTop(),
                  getStickyHeight(),
                  "group-[&[data-modal-rush='true']]/main-outlet:top-1",
                  isMobile
                    ? `fixed left-[-256px] top-0 z-50 h-dvh overflow-x-auto transition duration-300 ease-in-out`
                    : '',
                  leftOpened ? 'translate-x-full' : 'translate-x-0',
                )}
              >
                <AppSidebar />
              </div>
              <div
                className={cn(
                  'flex w-full  flex-col items-center bg-fg-primary',
                  !isMobile ? 'max-w-[calc(100%_-_256px)]' : 'min-h-screen',
                  notMobileOptimized && isMobile ? 'min-w-[800px]' : '',
                )}
              >
                <AppHeader />
                <div
                  className={cn('flex size-full max-w-[1440px] flex-col justify-between', isMobile ? 'px-4' : 'px-9')}
                >
                  <div className="size-full">
                    <ScrollRestoration />
                    {renderOutlet()}
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
            <MobileOverlay />
          </ModalProvider>
        </ErrorBoundary>
      </UIProvider>
    </SocketProvider>
  )
}
