import { ReactNode } from 'react'
import { VariantProps } from 'class-variance-authority'
import { Tooltip } from '@/components/ui'
import { AghanimIcon, AlertCircleSolid } from '@/icons'

interface TooltipBoxProps {
  size?: 'sm' | 'md' | 'lg'
  message: ReactNode
  placement?: 'top' | 'bottom'
}

const iconSizeMap = new Map<TooltipBoxProps['size'], VariantProps<AghanimIcon>['size']>([
  ['sm', 14],
  ['md', 16],
  ['lg', 18],
])

export const TooltipAlertCircle = ({ size = 'md', message, placement }: TooltipBoxProps) => (
  <Tooltip
    message={message}
    options={{
      placement: placement || 'bottom',
    }}
  >
    <AlertCircleSolid
      size={iconSizeMap.get(size) || 16}
      className="cursor-help text-text-quarterary hover:text-text-quarterary-hover"
    />
  </Tooltip>
)
