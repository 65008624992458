import { forwardRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Edit03, Menu, MenuItem, MenuItemDivider, PopperMenuProps, Trash, useModal } from '@dashboard/ui'
import { PriceTemplateShort } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import {
  PriceTemplateModalCannotDelete,
  PriceTemplateModalConfirmDelete,
  SKU_PRICE_TEMPLATES_EDIT_PATH,
} from 'src/layouts/sku-price-templates'

interface PriceTemplateMenuProps extends PopperMenuProps {
  priceTemplate: PriceTemplateShort
}

export const PriceTemplateMenu = forwardRef<HTMLDivElement, PriceTemplateMenuProps>(
  ({ priceTemplate, ...rest }, ref) => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { generatePath } = useGenerateCompanyPath()

    const openDeleteModal = useModal(props => (
      <PriceTemplateModalConfirmDelete {...props} priceTemplate={priceTemplate} />
    ))

    const openCannotDeleteModal = useModal(props => (
      <PriceTemplateModalCannotDelete {...props} template={priceTemplate} />
    ))

    const onClickEdit = () => {
      navigate(generatePath(SKU_PRICE_TEMPLATES_EDIT_PATH, { priceTemplateId: priceTemplate.id }))
      rest.onClose()
    }

    const onClickDelete = useCallback(() => {
      priceTemplate.items_count === 0 ? openDeleteModal() : openCannotDeleteModal()
      rest.onClose()
    }, [priceTemplate.items_count, openDeleteModal, openCannotDeleteModal, rest])

    return (
      <Menu ref={ref} {...rest}>
        <MenuItem icon={Edit03} onClick={onClickEdit}>
          {t('edit')}
        </MenuItem>
        <MenuItemDivider />
        <MenuItem variant="danger" icon={Trash} onClick={onClickDelete}>
          {t('delete')}
        </MenuItem>
      </Menu>
    )
  },
)
