import { useEffect } from 'react'
import { initFeedbackIntegration } from '@/libs/sentry'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { i18n, t } = useTranslation()

  useEffect(() => {
    initFeedbackIntegration(undefined, i18n.language, '#bug-report')
  }, [])

  return (
    <div className="relative z-10 flex h-[56px] w-full shrink-0 items-center justify-center pr-9">
      <div className={'w-full'}>
        <div className="grow" />
        <div className="flex items-center justify-end gap-2 text-caption-md font-medium text-text-primary">
          <div
            className="cursor-pointer px-4"
            date-testid="header/documentation"
            onClick={() => window.open('https://docs.aghanim.com/')}
          >
            {t('header.documentation')}
          </div>
          <div id="bug-report" date-testid="header/bug-report" className="cursor-pointer px-4">
            {t('header.bug-report')}
          </div>
        </div>
      </div>
    </div>
  )
}
