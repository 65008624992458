import { QueryClient, UseMutationOptions } from '@tanstack/react-query'
import { dashboardClientV1 } from '@/api'
import { getPriceTemplateDetailsOptions } from './getPriceTemplateDetailsOptions'
import { getPriceTemplatesInfinityOptions } from './getPriceTemplatesInfinityOptions'

export const deletePriceTemplateItemMutation = (companyId: string, gameId: string, ql: QueryClient) => {
  const mutationOptions: Partial<UseMutationOptions<void, undefined, { id: string }>> = {
    mutationFn: async ({ id }) => {
      await dashboardClientV1.v1.deletePriceTemplate(companyId, gameId, id)
    },
    onSuccess: async (_, { id }) => {
      ql.removeQueries({ queryKey: getPriceTemplateDetailsOptions(companyId, gameId, id).queryKey })
      await ql.refetchQueries({ queryKey: getPriceTemplatesInfinityOptions(companyId, gameId).queryKey })
    },
  }
  return mutationOptions
}
