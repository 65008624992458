import { useEffect, useId, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { AlertCircleSolid, Check } from '@/icons'
import { getMotionProps } from '@/libs'
import { GetStartedStepState } from '../types'
import { StepStatus } from './StepStatus'
import { StepProgressBar } from './StepProgressBar'
import { useTranslation } from 'react-i18next'
import { Button, Spinner, cn, useIsMobile } from '@dashboard/ui'
import AnimatedDots from '@/components/animated-dots/AnimatedDots'
import { t } from 'i18next'

interface StepCardProps {
  pos: number
  isCurrent: boolean
  step: GetStartedStepState
  nextStep: GetStartedStepState | undefined
}

const progressContainerMotionProps = getMotionProps({
  initial: { marginTop: 0, height: 0, opacity: 0 },
  animate: { marginTop: 18, height: 'auto', opacity: 1 },
  exit: { marginTop: 0, height: 0, opacity: 0 },
})

const stuckCalloutMotionProps = getMotionProps({
  initial: { marginTop: 0, height: 0, opacity: 0 },
  animate: { marginTop: 6, height: 'auto', opacity: 1 },
  exit: { marginTop: 0, height: 0, opacity: 0 },
})

const Countdown = () => {
  const [countDown, setCountdown] = useState<number>(45)
  useEffect(() => {
    countDown >= 0 && setTimeout(() => setCountdown(countDown - 1), 1000)
    if (countDown < 0) {
      setCountdown(5)
    }
  }, [countDown])

  return (
    <div className="flex gap-1">
      <span className="whitespace-nowrap">
        00:{String(countDown).padStart(2, '0')} {t('seconds').toLowerCase()}
        <AnimatedDots />
      </span>
    </div>
  )
}

export const StepCard = ({ pos, isCurrent, step, nextStep }: StepCardProps) => {
  const id = useId()
  const { t } = useTranslation()
  const tasks = useMemo(() => Object.values(step.tasks || {}), [step.tasks])

  const lineStatus = [step.status, nextStep?.status].filter(Boolean).join('~')

  const isMobile = useIsMobile()

  return (
    <div className="group relative">
      <div
        className={cn(
          'absolute bottom-[-48px] left-12 top-[78px] w-0.5 bg-border-secondary bg-gradient-to-b transition-all group-last:hidden',
          lineStatus === 'idle~generating' && 'from-transparent to-border-brand',
          lineStatus === 'idle~review' && 'from-transparent to-border-warning',
          lineStatus === 'idle~completed' && 'from-transparent to-border-success',
          lineStatus === 'loading~generating' && 'from-transparent to-border-brand',
          lineStatus === 'loading~review' && 'from-transparent to-border-warning',
          lineStatus === 'loading~completed' && 'from-transparent to-border-success',
          lineStatus === 'generating~idle' && 'from-border-brand to-transparent ',
          lineStatus === 'generating~completed' && 'from-border-brand to-border-success ',
          lineStatus === 'completed~idle' && 'from-border-success to-transparent',
          lineStatus === 'completed~loading' && 'from-border-success to-transparent',
          lineStatus === 'completed~review' && 'from-border-success to-border-warning',
          lineStatus === 'completed~completed' && 'from-border-success to-border-success',
        )}
      />

      <div
        className={cn(
          'relative z-10 flex items-start gap-6 rounded-xl p-6 transition-all',
          isCurrent && '-mx-0.5 border-2 border-border-primary bg-fg-primary shadow-ring-gray-xs',
        )}
      >
        <div className="relative shrink-0 ">
          <div className="absolute -top-1.5 left-6 h-1.5 w-0.5 bg-fg-primary" />
          <div className="absolute -bottom-1.5 left-6 h-1.5 w-0.5 bg-fg-primary" />
          <StepStatus pos={pos} img={step.img} status={step.status} />
        </div>

        <div className="w-full">
          <div className={cn('flex w-full items-start gap-6', isMobile ? 'flex-col' : '')}>
            <div className="mr-auto">
              <div className={cn('mb-3 text-t6 text-text-secondary', isCurrent && 'text-text-primary')}>
                {step.title}
              </div>
              <div className="text-paragraph-sm text-text-secondary">
                {step.desc}
                {step.link && (
                  <>
                    &nbsp;
                    <a href={step.link.href} target="_blank">
                      {step.link.label}
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="shrink-0">{step.cta}</div>
          </div>

          <AnimatePresence initial={false}>
            {!!step.progress && step.progress.value !== 100 && (
              <motion.div {...progressContainerMotionProps} className="overflow-hidden">
                <div className="flex justify-between">
                  <div className="mb-1 text-caption-sm">
                    {step.progress.value === 0 ? (
                      t('get-started.generating-requested')
                    ) : (
                      <p className="flex gap-1">
                        <span>{t('get-started.generating-game-hub')}</span>
                        <Countdown />
                      </p>
                    )}
                  </div>
                </div>
                <StepProgressBar value={step.progress.value || 1} />
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence initial={false}>
            {!!step.progress &&
              step.progress.value !== 100 &&
              step.progress?.isStuck &&
              import.meta.env.VITE_DEMO_GAME_ID &&
              import.meta.env.VITE_DEMO_COMPANY_ID && (
                <motion.div {...stuckCalloutMotionProps} className="overflow-hidden">
                  <div className="grid grid-cols-[auto_1fr_auto] items-center gap-3 rounded-xl border border-[#0EA5E91A] bg-fg-brand-tertiary p-3 text-text-brand-solid">
                    <AlertCircleSolid size={16} />
                    <div>
                      <div className="mb-1 text-caption-sm">{t('get-started.callout.generate-longer')}</div>
                      <div className="text-paragraph-xs">{t('get-started.callout.generate-longer2')}</div>
                    </div>
                    <Link to={`/company/${import.meta.env.VITE_DEMO_COMPANY_ID}/${import.meta.env.VITE_DEMO_GAME_ID}`}>
                      <Button>{t('get-started.view-demo')}</Button>
                    </Link>
                  </div>
                </motion.div>
              )}
          </AnimatePresence>

          {!!tasks.length && (
            <div className="mt-[18px] rounded-xl border border-border-secondary bg-fg-primary px-3">
              {tasks.map((task, index) => (
                <div key={`task-row-${id}-${index}`} className="group/tasks flex items-center gap-3">
                  <div className="shrink-0">
                    {task.status === 'idle' && (
                      <div className="size-[18px] text-center font-nohemi text-caption-md leading-normal text-text-quarterary">
                        {index + 1}
                      </div>
                    )}
                    {task.status === 'loading' && (
                      <div className="size-[18px] text-text-quarterary">
                        <Spinner size={18} />
                      </div>
                    )}
                    {task.status === 'completed' && (
                      <div className="text-text-success-primary">
                        <Check size={18} strokeWidth={3} />
                      </div>
                    )}
                  </div>
                  <div className="w-full border-b border-b-border-secondary py-2 group-last/tasks:border-none">
                    <div className="text-paragraph-sm text-text-secondary">{task.label}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
