import { SequentialOfferRead } from '@/api/dashboard'
import { useTranslation } from 'react-i18next'
import { useGameItem } from '@/api/useGameItems'
import { useGenerateCompanyPath } from '@/libs'

export const Summary = ({ offer }: { offer: SequentialOfferRead }) => {
  const { t } = useTranslation('offers')
  const { t: tMain } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { data: item } = useGameItem(companyId, gameId, offer ? offer.item_id : '')

  const renderProp = (name: string, value: string | number | undefined) => {
    return (
      <div className="flex border-b border-border-secondary py-2">
        <span className="text-caption-sm text-text-tertiary">{name}</span>
        <span className="ml-auto w-1/2 truncate text-right text-caption-sm text-text-primary">{value}</span>
      </div>
    )
  }

  return (
    <div className=" w-[332px] rounded-xl border border-border-primary bg-fg-secondary p-[18px]">
      <div className="mb-3 text-caption-lg font-semibold text-text-secondary">{t('summary')}</div>
      {renderProp(t('name'), offer.name)}
      {renderProp(t('description'), offer.description)}
      {renderProp(t('linked-item'), item?.name || '')}
      {renderProp(
        tMain('datetime.start_at'),
        offer.start_at
          ? tMain('intl.DateTime', {
              val: new Date(offer.start_at * 1000),
              formatParams: { val: { dateStyle: 'medium' } },
            })
          : '',
      )}
      {renderProp(
        tMain('datetime.end_at'),
        offer.end_at
          ? tMain('intl.DateTime', {
              val: new Date(offer.end_at * 1000),
              formatParams: { val: { dateStyle: 'medium' } },
            })
          : '',
      )}
    </div>
  )
}
