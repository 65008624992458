import { Toggle } from '@/ui'
import { useTranslation } from 'react-i18next'

export const ToggleSection = (props: {
  value: boolean | undefined
  label: string
  onChange: (value: boolean) => void
  disabled?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      }}
      className="mb-[18px] flex items-center justify-between rounded-md border border-border-primary p-[18px]"
    >
      <span className="h-6 text-base font-semibold text-text-secondary">{props.label}</span>
      <div className="flex items-center gap-3">
        <span className="text-caption-sm font-normal text-text-quarterary-hover ">
          {t(props.value ? 'toggle.enabled' : 'toggle.disabled')}
        </span>
        <Toggle
          sizev="md"
          disabled={props.disabled}
          checked={props.value}
          onChange={e => props.onChange(e.target.checked)}
        />
      </div>
    </div>
  )
}
