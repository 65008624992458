import { ButtonIcon, FieldGroup, Header, ImageUploader } from '@/ui'

import { Controller, useForm } from 'react-hook-form'
import { ItemRead, Pack } from '@/api/dashboard'
import { FieldSection } from 'src/components/ui/FieldSection'

import { useTranslation } from 'react-i18next'
import { Button, FormErrorMessage, Input, formatHookFormErrors } from '@dashboard/ui'
import { CloseOutline, Trash } from '@/icons'
import Divider from '@/components/Divider'
import { generateUid, uuid4 } from '@/util'
import { ErrorMessage } from '@hookform/error-message'
import { AddButton } from '@/layouts/components/AddButton'
import { useEffect } from 'react'
import { PRICE_MAX } from '@/layouts/game-items/data'
import { useNumberFormat } from '@react-input/number-format'

interface GeneralSettingsProps {
  onClose: () => void
  onSubmit: (pack: Pack) => void
  pack: Pack
  title: string
  baseItem: ItemRead
}

export const PackForm = (props: GeneralSettingsProps) => {
  const { t } = useTranslation('offers')
  const { t: tMain } = useTranslation()

  const {
    control,
    register,
    setError,
    clearErrors,
    formState: { errors, isSubmitting, isDirty },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<Pack>({
    defaultValues: {
      id: generateUid('pck'),
      base_quantity: 100,
      max_purchase: 5,
      bonuses: [],
      price_point: 0,
      price: props.baseItem.price / 100,
    },
  })

  useEffect(() => {
    if (props.pack.id) {
      reset({
        ...props.pack,
        price: props.pack.price / 100,
      })
    }
  }, [props.pack])

  const inputRef = useNumberFormat({
    locales: 'en',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })

  const bonuses = watch('bonuses') || []

  const onSubmit = (data: Pack) => {
    if (bonuses.length === 0) {
      setError('bonuses', { message: t('pack.bonuses.error') })
      return
    }
    props.onClose()
    props.onSubmit({
      ...data,
      price: data.price * 100,
    })
  }

  const renderGeneral = () => {
    return (
      <FieldSection label={t('general')}>
        <FieldGroup label={t('pack.base_quantity')}>
          <Input
            min={1}
            type={'number'}
            {...register('base_quantity', {
              required: tMain('validation.required'),
            })}
            autoFocus
            {...formatHookFormErrors(errors, 'base_quantity')}
          />
        </FieldGroup>
        <FieldGroup label={t('pack.max_purchase')}>
          <Input
            min={1}
            type={'number'}
            {...register('max_purchase', {
              required: tMain('validation.required'),
            })}
            {...formatHookFormErrors(errors, 'max_purchase')}
          />
        </FieldGroup>
        <FieldGroup label={t('pack.image_url')}>
          <Controller
            control={control}
            name="image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
        <FieldGroup label={t('background_image_url')}>
          <Controller
            control={control}
            name="background_image_url"
            render={({ field }) => (
              <ImageUploader
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                {...field}
              />
            )}
          />
          <ErrorMessage
            name="background_image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderPrice = () => {
    return (
      <FieldSection label={t('pricing')}>
        <Controller
          control={control}
          rules={{
            required: tMain('validation.required'),
            min: { value: 0, message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }) },
            max: {
              value: PRICE_MAX * 100,
              message: tMain('game-item-page.price-min-max', { min: 0, max: PRICE_MAX }),
            },
          }}
          name="price"
          render={({ field }) => (
            <Input
              {...field}
              ref={inputRef}
              {...formatHookFormErrors(errors, 'price')}
              extraLeft={{ icon: () => <div className="text-text-quarterary-hover">USD</div>, width: 30 }}
              extraRight={{ icon: () => <div className="text-text-quarterary-hover">$</div> }}
            />
          )}
        />
      </FieldSection>
    )
  }

  const renderBonuses = () => {
    return (
      <FieldSection label={t('pack.bonuses')}>
        {bonuses.length > 0 && (
          <div className="mb-1.5 rounded-md border border-border-secondary bg-fg-secondary p-1.5">
            <div className="flex items-center gap-2 text-caption-sm font-medium text-text-tertiary">
              <div className="w-1/2">{t('pack.bonus-percent')}</div>
              <div className="w-1/2">{t('pack.weight')}</div>
              <ButtonIcon variant="tertiary-destructive" className="opacity-0">
                <Trash />
              </ButtonIcon>
            </div>
            {bonuses.map((bonus, index) => {
              return (
                <div className="mt-1.5" key={bonus.id}>
                  <div key={bonus.id} className="flex items-start gap-2">
                    <Controller
                      rules={{
                        required: tMain('validation.required'),
                      }}
                      render={({ field }) => (
                        <div>
                          <Input
                            min={1}
                            type="number"
                            value={field.value}
                            onChange={e => {
                              const value = e.target.value == '' ? ('' as unknown as number) : parseInt(e.target.value)
                              field.onChange(value)
                            }}
                          />
                          <ErrorMessage
                            name={`bonuses.${index}.bonus_percent`}
                            errors={errors}
                            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                          />
                        </div>
                      )}
                      name={`bonuses.${index}.bonus_percent`}
                      control={control}
                    />

                    <Controller
                      rules={{
                        required: tMain('validation.required'),
                      }}
                      render={({ field }) => (
                        <div>
                          <Input
                            min={1}
                            type="number"
                            value={field.value}
                            onChange={e => {
                              const value = e.target.value == '' ? ('' as unknown as number) : parseInt(e.target.value)
                              field.onChange(value)
                            }}
                          />
                          <ErrorMessage
                            name={`bonuses.${index}.weight`}
                            errors={errors}
                            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                          />
                        </div>
                      )}
                      name={`bonuses.${index}.weight`}
                      control={control}
                    />

                    <ButtonIcon
                      variant="tertiary-destructive"
                      onClick={() => {
                        setValue(
                          'bonuses',
                          bonuses.filter((_, i) => i !== index),
                          {
                            shouldDirty: true,
                          },
                        )
                      }}
                    >
                      <Trash />
                    </ButtonIcon>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <AddButton
          size="xs"
          onClick={() => {
            setValue('bonuses', [...bonuses, { id: uuid4(), bonus_percent: 100, weight: 100 }], {
              shouldDirty: true,
            })
            clearErrors('bonuses')
          }}
        >
          {t('add-bonus')}
        </AddButton>

        <ErrorMessage
          name={`bonuses`}
          errors={errors}
          render={({ message }) => {
            return message ? <FormErrorMessage>{message}</FormErrorMessage> : ''
          }}
        />
      </FieldSection>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex h-full flex-col bg-fg-primary">
        <Header title={props.title} className="sticky top-0 z-50 p-4" minWidth="auto">
          <div className="flex items-center gap-4">
            <Button disabled={!isDirty} loading={isSubmitting} type="submit">
              {tMain('Apply')}
            </Button>
            <ButtonIcon onClick={props.onClose} type="button" size="xs" variant="secondary-gray">
              <CloseOutline />
            </ButtonIcon>
          </div>
        </Header>

        <div className="flex size-full flex-col p-4">
          {renderGeneral()}
          <Divider />

          {renderPrice()}
          <Divider />

          {renderBonuses()}
        </div>
      </div>
    </form>
  )
}
