import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const RewardPoint: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1783 0.48807C11.3054 -0.16269 12.6941 -0.16269 13.8213 0.48807L21.0586 4.66657C22.1858 5.31733 22.8801 6.51998 22.8801 7.8215V16.1785C22.8801 17.48 22.1858 18.6827 21.0586 19.3334L13.8213 23.5119C12.6941 24.1627 11.3054 24.1627 10.1783 23.5119L2.94089 19.3334C1.81374 18.6827 1.11938 17.48 1.11938 16.1785V7.8215C1.11938 6.51998 1.81374 5.31733 2.94089 4.66657L10.1783 0.48807ZM13.1154 1.71069C12.425 1.31212 11.5745 1.31212 10.8841 1.71069L3.64677 5.88919C2.95642 6.28776 2.53115 7.02436 2.53115 7.8215V16.1785C2.53115 16.9756 2.95642 17.7122 3.64677 18.1108L10.8841 22.2893C11.5745 22.6879 12.425 22.6879 13.1154 22.2893L20.3528 18.1108C21.0431 17.7122 21.4684 16.9756 21.4684 16.1785V7.8215C21.4684 7.02436 21.0431 6.28776 20.3528 5.88919L13.1154 1.71069Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1783 0.48807C11.3054 -0.16269 12.6941 -0.16269 13.8213 0.48807L21.0586 4.66657C22.1858 5.31733 22.8801 6.51998 22.8801 7.8215V16.1785C22.8801 17.48 22.1858 18.6827 21.0586 19.3334L13.8213 23.5119C12.6941 24.1627 11.3054 24.1627 10.1783 23.5119L2.94089 19.3334C1.81374 18.6827 1.11938 17.48 1.11938 16.1785V7.8215C1.11938 6.51998 1.81374 5.31733 2.94089 4.66657L10.1783 0.48807ZM13.1154 1.71069C12.425 1.31212 11.5745 1.31212 10.8841 1.71069L3.64677 5.88919C2.95642 6.28776 2.53115 7.02436 2.53115 7.8215V16.1785C2.53115 16.9756 2.95642 17.7122 3.64677 18.1108L10.8841 22.2893C11.5745 22.6879 12.425 22.6879 13.1154 22.2893L20.3528 18.1108C21.0431 17.7122 21.4684 16.9756 21.4684 16.1785V7.8215C21.4684 7.02436 21.0431 6.28776 20.3528 5.88919L13.1154 1.71069Z"
        fill="currentColor"
      />
      <path
        d="M13.0518 7.96094H16.8749C17.1779 7.96094 17.465 7.99105 17.7361 8.05129C18.0071 8.10964 18.2565 8.19435 18.4843 8.30541C18.7121 8.41647 18.9172 8.552 19.0998 8.712C19.2824 8.87011 19.4377 9.04894 19.5657 9.24847C19.6937 9.448 19.7916 9.66635 19.8594 9.90353C19.9271 10.1388 19.961 10.3892 19.961 10.6546C19.961 10.92 19.9262 11.1713 19.8565 11.4085C19.7888 11.6438 19.6909 11.8621 19.5629 12.0635C19.4368 12.2631 19.2824 12.4428 19.0998 12.6028C18.9172 12.7628 18.7111 12.8993 18.4815 13.0122C18.2537 13.1233 18.0043 13.2089 17.7332 13.2692C17.4641 13.3275 17.1779 13.3567 16.8749 13.3567H14.8363V16.0362H13.0518V7.96094ZM16.7789 11.8828C16.9954 11.8828 17.1902 11.8546 17.3634 11.7981C17.5365 11.7416 17.6834 11.6607 17.8038 11.5553C17.9243 11.4499 18.0165 11.3219 18.0805 11.1713C18.1445 11.0188 18.1765 10.8466 18.1765 10.6546C18.1765 10.4645 18.1445 10.2941 18.0805 10.1435C18.0165 9.99294 17.9252 9.86494 17.8067 9.75953C17.6881 9.65411 17.5431 9.57411 17.3718 9.51953C17.2005 9.46305 17.0076 9.43482 16.793 9.43482H14.8363V11.8828H16.7789Z"
        fill="currentColor"
      />
      <path
        d="M13.0518 7.96094H16.8749C17.1779 7.96094 17.465 7.99105 17.7361 8.05129C18.0071 8.10964 18.2565 8.19435 18.4843 8.30541C18.7121 8.41647 18.9172 8.552 19.0998 8.712C19.2824 8.87011 19.4377 9.04894 19.5657 9.24847C19.6937 9.448 19.7916 9.66635 19.8594 9.90353C19.9271 10.1388 19.961 10.3892 19.961 10.6546C19.961 10.92 19.9262 11.1713 19.8565 11.4085C19.7888 11.6438 19.6909 11.8621 19.5629 12.0635C19.4368 12.2631 19.2824 12.4428 19.0998 12.6028C18.9172 12.7628 18.7111 12.8993 18.4815 13.0122C18.2537 13.1233 18.0043 13.2089 17.7332 13.2692C17.4641 13.3275 17.1779 13.3567 16.8749 13.3567H14.8363V16.0362H13.0518V7.96094ZM16.7789 11.8828C16.9954 11.8828 17.1902 11.8546 17.3634 11.7981C17.5365 11.7416 17.6834 11.6607 17.8038 11.5553C17.9243 11.4499 18.0165 11.3219 18.0805 11.1713C18.1445 11.0188 18.1765 10.8466 18.1765 10.6546C18.1765 10.4645 18.1445 10.2941 18.0805 10.1435C18.0165 9.99294 17.9252 9.86494 17.8067 9.75953C17.6881 9.65411 17.5431 9.57411 17.3718 9.51953C17.2005 9.46305 17.0076 9.43482 16.793 9.43482H14.8363V11.8828H16.7789Z"
        fill="currentColor"
      />
      <path
        d="M4.5192 16.0363V7.95818H8.51449C8.96814 7.95818 9.37473 8.01559 9.73426 8.13041C10.0957 8.24524 10.4016 8.409 10.6519 8.62171C10.9041 8.83253 11.0971 9.08853 11.2307 9.38971C11.3644 9.69089 11.4312 10.0278 11.4312 10.4005C11.4312 10.7262 11.3785 11.0226 11.2731 11.2899C11.1677 11.5554 11.0171 11.7841 10.8213 11.9761C10.6274 12.1662 10.3921 12.3158 10.1154 12.425C9.84061 12.5323 9.53285 12.5916 9.19214 12.6029C9.51402 12.6255 9.79732 12.7441 10.042 12.9586C10.2886 13.1714 10.5004 13.4763 10.6773 13.8735L11.6486 16.0363H9.66932L8.68955 13.8763C8.61802 13.7182 8.54555 13.5874 8.47214 13.4838C8.39873 13.3784 8.31591 13.2947 8.22367 13.2325C8.13144 13.1704 8.02508 13.1271 7.90461 13.1026C7.78602 13.0782 7.64297 13.0659 7.47544 13.0659H6.30367V16.0363H4.5192ZM8.30838 11.6259C8.5192 11.6259 8.70744 11.6015 8.87308 11.5525C9.03873 11.5017 9.17802 11.4292 9.29096 11.3351C9.40579 11.2391 9.49332 11.1224 9.55355 10.985C9.61379 10.8457 9.64391 10.6876 9.64391 10.5106C9.64391 10.1605 9.52908 9.89324 9.29944 9.70877C9.06979 9.5243 8.73849 9.43206 8.30555 9.43206H6.30367V11.6259H8.30838Z"
        fill="currentColor"
      />
      <path
        d="M4.5192 16.0363V7.95818H8.51449C8.96814 7.95818 9.37473 8.01559 9.73426 8.13041C10.0957 8.24524 10.4016 8.409 10.6519 8.62171C10.9041 8.83253 11.0971 9.08853 11.2307 9.38971C11.3644 9.69089 11.4312 10.0278 11.4312 10.4005C11.4312 10.7262 11.3785 11.0226 11.2731 11.2899C11.1677 11.5554 11.0171 11.7841 10.8213 11.9761C10.6274 12.1662 10.3921 12.3158 10.1154 12.425C9.84061 12.5323 9.53285 12.5916 9.19214 12.6029C9.51402 12.6255 9.79732 12.7441 10.042 12.9586C10.2886 13.1714 10.5004 13.4763 10.6773 13.8735L11.6486 16.0363H9.66932L8.68955 13.8763C8.61802 13.7182 8.54555 13.5874 8.47214 13.4838C8.39873 13.3784 8.31591 13.2947 8.22367 13.2325C8.13144 13.1704 8.02508 13.1271 7.90461 13.1026C7.78602 13.0782 7.64297 13.0659 7.47544 13.0659H6.30367V16.0363H4.5192ZM8.30838 11.6259C8.5192 11.6259 8.70744 11.6015 8.87308 11.5525C9.03873 11.5017 9.17802 11.4292 9.29096 11.3351C9.40579 11.2391 9.49332 11.1224 9.55355 10.985C9.61379 10.8457 9.64391 10.6876 9.64391 10.5106C9.64391 10.1605 9.52908 9.89324 9.29944 9.70877C9.06979 9.5243 8.73849 9.43206 8.30555 9.43206H6.30367V11.6259H8.30838Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

RewardPoint.type = 'aghanim-icon';
