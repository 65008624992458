import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useKey } from 'react-use'
import { Button, FormErrorMessage, Input, LoyaltyPoint, XClose } from '@dashboard/ui'
import { LoyaltySettingsUpdate } from '@/api/dashboard'

import { Drawer, DrawerContent, DrawerTopbar, FieldGroup, FieldSection, ToastSeverity, useToast } from '@/ui'
import { useGenerateCompanyPath, usePydenticForm } from '@/libs/hooks'
import { CASHBACK_REWARDS_PATH } from 'src/layouts/cashback-rewards'
import { generatePath, useNavigate } from 'react-router-dom'
import { useLoyaltySettings, useLoyaltySettingsUpdate } from '@/layouts/engagement/hooks'
import { ErrorMessage } from '@hookform/error-message'
import { ToggleSection } from '@/layouts/components/ToggleSection'
import { useCurrentUser } from '@/api/useCurrentUser'
import { DollarRounded } from '@/layouts/cashback-rewards/icons'

export const LoyaltyPointsEditForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('sku-cashback')
  const { t: tDefault } = useTranslation()
  const { canEdit } = useCurrentUser()
  const { companyId, gameId } = useGenerateCompanyPath()
  const showToast = useToast()
  const { settings } = useLoyaltySettings()
  const { mutateAsync: updateProgramAsync } = useLoyaltySettingsUpdate(settings.id)

  const {
    control,
    handleSubmit,
    clearErrors,
    watch,
    formState: { isSubmitting, isDirty, errors },
  } = usePydenticForm<LoyaltySettingsUpdate>({
    mode: 'onChange',
    values: {
      active: !!settings.activated_at,
      points_rate: settings.points_rate,
    },
  })

  const [active, points_rate] = watch(['active', 'points_rate'])

  const onClose = () => {
    navigate(generatePath(CASHBACK_REWARDS_PATH, { companyId, gameId }))
  }

  useKey('Escape', onClose, undefined, [onClose])

  const onFormSubmit = handleSubmit(async (data: LoyaltySettingsUpdate) => {
    updateProgramAsync(data)
    showToast({ message: tDefault('saved'), severity: ToastSeverity.success })
    onClose()
  })

  const lpValue = 100 * (points_rate || 0)

  return (
    <Drawer open={!!settings}>
      <form className="flex h-full flex-col overflow-y-auto" onSubmit={onFormSubmit}>
        <DrawerTopbar>
          <div className="mr-auto font-nohemi text-[20px] font-medium tracking-[1px]">{t('form.lp.title')}</div>
          <div className="flex items-start gap-3">
            <Button type="submit" loading={isSubmitting} disabled={!isDirty}>
              {tDefault('Save')}
            </Button>
            <Button className="w-7 px-0" variant="outline" color="secondary" onClick={onClose}>
              <XClose />
            </Button>
          </div>
        </DrawerTopbar>

        <DrawerContent>
          <Controller
            control={control}
            name="active"
            render={({ field }) => (
              <ToggleSection
                disabled={!canEdit}
                label={t('form.lp.enable')}
                value={field.value}
                onChange={v => {
                  clearErrors()
                  field.onChange(v)
                }}
              />
            )}
          />

          <FieldSection label={t('form.section.exchange-rate.label')}>
            <div className="flex flex-col gap-3 rounded-md bg-fg-secondary p-3">
              <div className="grid grid-cols-2 gap-4 ">
                <div>
                  <FieldGroup label={t('form.exchange-rate.from.label')}>
                    <Input
                      extraRight={{ icon: () => <span>USD</span> }}
                      disabled
                      extraLeft={{ icon: () => <DollarRounded /> }}
                      value={1}
                    />
                  </FieldGroup>
                </div>
                <div>
                  <FieldGroup label={t('form.exchange-rate.to.label')}>
                    <Controller
                      control={control}
                      name="points_rate"
                      rules={{
                        required: tDefault('validation.required'),
                        min: {
                          value: 0.01,
                          message: tDefault('validation.min', {
                            field: t('form.lp.points_rate'),
                            value: 1,
                          }),
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="number"
                          autoFocus
                          disabled={!active || !canEdit}
                          extraRight={{ icon: () => <span>LP</span> }}
                          extraLeft={{ icon: () => <LoyaltyPoint /> }}
                          onChange={e => {
                            let num = parseFloat(e.target.value)
                            if (isNaN(num)) {
                              return field.onChange('')
                            }
                            field.onChange(num / 100)
                          }}
                          value={field.value ? field.value * 100 : ''}
                        />
                      )}
                    />
                  </FieldGroup>
                </div>
              </div>
              <ErrorMessage
                name="points_rate"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
              <span className="text-caption-sm font-normal text-text-tertiary">
                {t('form.lp.desc', { value: lpValue })}
              </span>
            </div>
          </FieldSection>
        </DrawerContent>
      </form>
    </Drawer>
  )
}
