import { AnimationProps, Transition, Variant } from 'framer-motion';

type TypedVariants = Record<'initial' | 'animate' | 'exit', Variant>;

export const MOTION_ANIMATION_SPEED_SM = 0.05;

export const MOTION_ANIMATION_SPEED_MD = 0.15;

/**
 * This function will return props for the motion element.
 * @param variants
 * @param transition
 */
export const getMotionProps = (
  variants: TypedVariants,
  transition: Transition = {
    duration: MOTION_ANIMATION_SPEED_MD,
    type: 'tween',
  },
): AnimationProps => ({
  variants,
  initial: 'initial',
  animate: 'animate',
  exit: 'exit',
  transition,
});
