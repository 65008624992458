import { Badge, ButtonIcon, FieldGroup, Header, InputExtraMark, SelectMultiple, ToastSeverity, useToast } from '@/ui'
import { useEffect, useState } from 'react'
import { Controller, FormProvider } from 'react-hook-form'
import { StorePromotionRead } from '@/api/dashboard'
import { useParams } from 'react-router-dom'
import { FieldSection } from 'src/components/ui/FieldSection'
import { ErrorMessage } from '@hookform/error-message'
import { SelectDateTime } from '@/components/ui/SelectDateTime'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  FormErrorMessage,
  Input,
  ModalPresetConfirm,
  Select,
  formatHookFormErrors,
  useModal,
} from '@dashboard/ui'
import { usePydenticForm } from '@/libs/hooks/usePydenticForm'
import { useStorePromotionCreateMutate, useStorePromotionUpdateMutate } from '@/layouts/store-promotion/api'
import { CloseOutline } from '@/icons'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import Divider from '@/components/Divider'
import { MAX_NAME_LENGTH } from '@/Settings'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { validateInterval } from '@/layouts/components/ItemTimelimit'
import { getNowStartOfHour } from '@/util'
import { useStorePromotionQuery } from '@/layouts/store-promotion/api/useStorePromotionQuery'
import { URL_NEW } from '@/types'
import { useStoresQuery } from '@/layouts/store/api'
import { SelectSkuTable } from '@/layouts/components/SelectSkuTable'
import { BonusItemBenefitEditor } from '@/layouts/components/BonusItemBenefitEditor'

interface StorePromotionsSettingsProps {
  onClose: () => void
}

enum PromotionType {
  All = 'all',
  Items = 'items',
  Stores = 'stores',
}

export default function StorePromotionsSettings(props: StorePromotionsSettingsProps) {
  const { t } = useTranslation()
  const showToast = useToast()
  const { companyId, gameId, promotionId } = useParams() as { companyId: string; gameId: string; promotionId: string }
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useStorePromotionUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = useStorePromotionCreateMutate(companyId, gameId)
  const { data: storePromotion } = useStorePromotionQuery(companyId, gameId, promotionId)

  const [promotionType, setPromotionType] = useState<PromotionType>(PromotionType.All)

  const { data: stores = [] } = useStoresQuery(companyId, gameId, {
    limit: 100,
  })

  const defaultValue = {
    discount_percent: null,
    bonus_percent: null,
    reward_points_percent: null,
    start_at: getNowStartOfHour(),
    end_at: getNowStartOfHour() + 84600,
    active: false,
  } as unknown as StorePromotionRead

  const methods = usePydenticForm<StorePromotionRead>({
    mode: 'onChange',
    defaultValues: {
      ...defaultValue,
      ...storePromotion,
    },
  })

  const {
    register,
    reset,
    watch,
    formState: { errors, isSubmitting, isDirty },
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
  } = methods

  const [max_purchase_count, discount_percent, active] = watch(['max_purchase_count', 'discount_percent', 'active'])

  useEffect(() => {
    reset({ ...storePromotion })

    if (storePromotion?.store_ids) {
      setPromotionType(PromotionType.Stores)
    }

    if (storePromotion?.item_ids) {
      setPromotionType(PromotionType.Items)
    }
  }, [storePromotion])

  const publishConfirm = async () => {
    if (!storePromotion) {
      return
    }

    await updateMutateAsync({
      id: promotionId,
      update: {
        active: !active,
      },
    })

    if (active) {
      showToast({
        message: t('edit-news-dialog.unpublished'),
        severity: ToastSeverity.info,
      })
    } else {
      showToast({
        message: t('edit-news-dialog.published'),
        severity: ToastSeverity.success,
      })
    }

    setValue('active', !active, { shouldDirty: false })
  }

  const openPublishConfirm = useModal(props => (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t('confirm.title'),
        subtitle: t(storePromotion?.active ? 'store-promotions.unpublish-confirm' : 'store-promotions.publish-confirm'),
      }}
      cancel={{
        label: t('no'),
      }}
      submit={{
        color: storePromotion?.active ? 'danger' : 'primary',
        callback: () => {
          props.onClose()
          publishConfirm()
        },
        label: t(storePromotion?.active ? 'store-promotions.unpublish' : 'store-promotions.publish'),
      }}
    />
  ))

  const onSubmit = async (data: StorePromotionRead) => {
    const err = validateInterval(data.start_at, data.end_at, t)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    if (!data.bonus_item_id && !data.bonus_percent && !data.discount_percent && !data.reward_points_percent) {
      setError('discount_percent', { message: t('validation.required') })
      return
    }

    clearErrors('discount_percent')

    const arr = ['bonus_percent', 'discount_percent', 'reward_points_percent']
    arr.forEach(field => {
      const r = data as unknown as Record<string, number | null>
      if (!r[field]) {
        r[field] = null
      }
    })

    if (promotionId != URL_NEW) {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } else {
      await createMutateAsync({
        create: data,
      })
    }

    props.onClose()
  }

  useEffect(() => {
    clearErrors(['store_ids', 'item_ids'])
  }, [promotionType])

  const renderStoreIds = () => {
    return (
      <FieldGroup label={t('store.store')}>
        <Controller
          name="store_ids"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <SelectMultiple
              options={stores.map(s => ({
                value: s.id,
                children: (
                  <div className="flex items-center gap-2">
                    {s.name}
                    <Badge variant="gray-secondary">{s.item_count}</Badge>
                  </div>
                ) as unknown as string,
                extraRight: t(`store.type.${s.type}`),
              }))}
              onChange={v => {
                clearErrors('store_ids')
                field.onChange(v)
              }}
              value={field.value || []}
            />
          )}
        />
        <ErrorMessage
          name="store_ids"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </FieldGroup>
    )
  }

  const renderItemsIds = () => {
    return (
      <div>
        <Controller
          name="item_ids"
          control={control}
          rules={{ required: t('validation.required') }}
          render={({ field }) => (
            <SelectSkuTable
              onChange={v => {
                clearErrors('item_ids')
                field.onChange(v)
              }}
              value={field.value || []}
            />
          )}
        />
        <ErrorMessage
          name="item_ids"
          errors={errors}
          render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
        />
      </div>
    )
  }

  const renderGeneral = () => {
    return (
      <FieldSection label={t('store-promotions.general')}>
        <FieldGroup label={t('store-promotions.name')}>
          <Input
            {...register('name', {
              required: t('validation.required'),
              maxLength: {
                message: t('validation.maxLength', {
                  field: t('store-promotions.name'),
                  value: MAX_NAME_LENGTH,
                }),
                value: MAX_NAME_LENGTH,
              },
              setValueAs: v => v?.trim(),
            })}
            autoFocus
          />
          <ErrorMessage
            name="name"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderItemsType = () => {
    return (
      <FieldSection label={t('store-promotions.item-type')} caption={t('store-promotions.item-caption')}>
        <FieldGroup label={t('store-promotions.type')}>
          <Select
            items={[
              ...Object.values(PromotionType).map(item => ({
                children: t(`store-promotions.type.${item}`),
                value: item,
              })),
            ]}
            value={promotionType}
            onChange={v => {
              setPromotionType(v as PromotionType)
              if (v !== PromotionType.Stores) {
                setValue('store_ids', null as unknown as string[], { shouldDirty: true })
              }
              if (v !== PromotionType.Items) {
                setValue('item_ids', null as unknown as string[], { shouldDirty: true })
              }
            }}
          />
        </FieldGroup>

        {promotionType === PromotionType.Stores && renderStoreIds()}
        {promotionType === PromotionType.Items && renderItemsIds()}
      </FieldSection>
    )
  }

  const renderBenefits = () => {
    return (
      <FieldSection label={t('campaign.block.CreateUserStoreSettingsNode.Benefits')}>
        <FieldGroup label={t('store-promotions.discount_percent')} className="flex flex-col gap-1.5">
          <Input
            type="number"
            {...register('discount_percent', {
              min: {
                value: 1,
                message: t('validation.min', { field: t('store-promotions.discount_percent'), value: 1 }),
              },
              max: {
                value: 99,
                message: t('validation.max', { field: t('store-promotions.discount_percent'), value: 100 }),
              },
            })}
            extraLeft={{
              icon: <InputExtraMark>%</InputExtraMark>,
            }}
            {...formatHookFormErrors(errors, 'discount_percent')}
          />
          <Checkbox {...register('exclude_items_for_reward_points')} disabled={!discount_percent}>
            {t('campaign.block.CreateUserStoreSettingsNode.exclude_items_for_reward_points')}
          </Checkbox>
        </FieldGroup>

        <FieldGroup
          label={t('campaign.virtual-sku-editor.bonus')}
          caption={t('campaign.block.CreateUserStoreSettingsNode.bonus')}
        >
          <Input
            type="number"
            {...register('bonus_percent')}
            extraLeft={{
              icon: <InputExtraMark>%</InputExtraMark>,
            }}
            {...formatHookFormErrors(errors, 'bonus_percent')}
          />
        </FieldGroup>

        {gameSettings?.enable_reward_points && (
          <FieldGroup label={t('campaign.virtual-sku-editor.reward_point_percent')}>
            <Input
              type="number"
              {...register('reward_points_percent', {
                min: { value: 1, message: t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                max: {
                  value: 100,
                  message: t('campaign.virtual-sku-editor.reward_point_percent.validation'),
                },
              })}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
              {...formatHookFormErrors(errors, 'reward_points_percent')}
            />
          </FieldGroup>
        )}
      </FieldSection>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <FieldSection label={t('store-promotions.time-limitations')}>
        <FieldGroup label={t('datetime.start_at')}>
          <Controller
            control={control}
            rules={{ required: t('validation.required') }}
            name="start_at"
            render={({ field }) => <SelectDateTime {...field} disableClear={true} />}
          />
          <ErrorMessage
            name="start_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
        <FieldGroup label={t('datetime.end_at')}>
          <Controller
            control={control}
            name="end_at"
            rules={{ required: t('validation.required') }}
            render={({ field }) => <SelectDateTime {...field} disableClear={true} />}
          />
          <ErrorMessage
            name="end_at"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>
      </FieldSection>
    )
  }

  const renderQuantityLimitations = () => {
    return (
      <FieldSection label={t('campaign.virtual-sku-editor.quantity-limitations')}>
        <CheckBoxContainer
          checked={max_purchase_count != undefined}
          onChange={v => {
            setValue('max_purchase_count', v.target.checked ? 1 : (null as unknown as undefined), { shouldDirty: true })
          }}
          label={t('campaign.virtual-sku-editor.limit-quantity')}
        >
          <FieldGroup
            label={t('campaign.virtual-sku-editor.max_purchases')}
            caption={t('campaign.virtual-sku-editor.max_purchases.desc')}
          >
            <Input
              type="number"
              {...register('max_purchase_count', { min: 1 })}
              placeholder={t('campaign.virtual-sku-editor.max_purchases.ph')}
            />
          </FieldGroup>
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex h-full flex-col bg-fg-primary">
        <Header
          title={t(promotionId == URL_NEW ? 'store-promotions.create-store' : 'store-promotions.edit-store')}
          className="sticky top-0 z-10 p-4"
          minWidth="auto"
        >
          <div className="flex items-center gap-4">
            {storePromotion?.id && (
              <Button variant="outline" type="button" onClick={() => openPublishConfirm()}>
                {t(active ? 'store-promotions.unpublish' : 'store-promotions.publish')}
              </Button>
            )}
            <Button disabled={!isDirty} loading={isSubmitting} type="submit">
              {t('Save')}
            </Button>
            <ButtonIcon onClick={props.onClose} type="button" size="xs" variant="secondary-gray">
              <CloseOutline />
            </ButtonIcon>
          </div>
        </Header>

        <div className="flex size-full flex-col p-4">
          {renderGeneral()}
          <Divider />

          {renderItemsType()}
          <Divider />

          {renderBenefits()}
          <Divider />

          <FormProvider {...methods}>
            <BonusItemBenefitEditor />
          </FormProvider>
          <Divider />

          {renderTimeLimitations()}
          <Divider />
          {renderQuantityLimitations()}
        </div>
      </div>
    </form>
  )
}
