import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'
import { PriceTemplateShort } from '@/api/dashboard'
import { useGenerateCompanyPath } from '@/libs'
import { deletePriceTemplateItemMutation } from '../api'

interface PriceTemplateModalConfirmDeleteProps extends ModalProps {
  priceTemplate: PriceTemplateShort
}

export const PriceTemplateModalConfirmDelete = ({ priceTemplate, ...rest }: PriceTemplateModalConfirmDeleteProps) => {
  const ql = useQueryClient()
  const { t } = useTranslation('ui', { keyPrefix: 'confirm-delete-modal' })
  const { companyId, gameId } = useGenerateCompanyPath()

  const { mutateAsync } = useMutation(deletePriceTemplateItemMutation(companyId, gameId, ql))

  const onClickConfirm = useCallback(async () => {
    await mutateAsync({ id: priceTemplate.id })
    rest.onClose()
  }, [priceTemplate, mutateAsync])

  return (
    <ModalPresetConfirm
      {...rest}
      title={{ children: t('title'), subtitle: t('subtitle', { name: priceTemplate.name }) }}
      cancel={{ label: t('cancel') }}
      submit={{ label: t('submit'), color: 'danger', callback: onClickConfirm }}
    />
  )
}
