import { useParams } from 'react-router-dom'
import { SelectMulti, SelectMultiProps } from '@/ui'
import { usePaymentMethods } from '@/api/usePaymentMethods'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface SelectPMProps extends Omit<SelectMultiProps, 'items'> {}

export const SelectPaymentMethod = (props: SelectPMProps) => {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const { t } = useTranslation()
  const { data: items = [] } = usePaymentMethods(companyId, gameId)

  let options = useMemo(() => {
    return items
      .filter(it => it.settings.global_enabled && it.dashboard_show)
      .map(it => ({
        value: it.id as string,
        icon: () => <img src={it.logo_url} style={{ width: '32px', height: '32px' }} alt={it.caption} />,
        children: it.caption,
      }))
  }, [items])

  return <SelectMulti {...props} items={options} placeholder={t('select-payment-methods')} />
}
