/**
 * Convert a string to a slug.
 * @param str
 * @returns
 */
export const slugifyString = (str: string) => {
  if (typeof str !== 'string') {
    throw new Error('slugify: string argument expected')
  }

  return str
    .replace(/\s+/g, ' ')
    .replaceAll(' ', '-')
    .replace(/[\s~`!@#$%^№&*()_+={[}\]|\\:;"'<,>.?/]+/g, '')
    .replace(/-+/g, '-')
    .toLowerCase()
}
