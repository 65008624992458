import { useTranslation } from 'react-i18next'
import { Badge, TableCell, TableRow } from '@/ui'
import { LOYALTY_POINTS_EDIT_PATH } from 'src/layouts/cashback-rewards'
import { useGenerateCompanyPath } from '@/libs'
import { useLoyaltySettings } from '@/layouts/engagement/hooks'
import { LoyaltyPoint } from '@dashboard/ui'

export const LoyaltyPointsRow = () => {
  const { settings } = useLoyaltySettings()
  const { t } = useTranslation('sku-cashback')
  const { generatePath } = useGenerateCompanyPath()

  const isActive = settings?.activated_at
  const points_rate = settings?.points_rate

  return (
    <TableRow to={generatePath(LOYALTY_POINTS_EDIT_PATH)}>
      <TableCell width="100%" className="flex items-center gap-3">
        <div className="rounded-md border border-border-primary bg-fg-primary-alt p-1.5">
          <LoyaltyPoint className="text-text-tertiary" />
        </div>
        <div className="text-caption-md font-medium text-text-secondary">{t('table.row.loyalty-points')}</div>
      </TableCell>
      <TableCell width="60%" className="text-text-disabled">
        {t('table.row.no-linked-item')}
      </TableCell>
      <TableCell width="60%" className={!isActive ? 'text-text-disabled' : ''}>
        {isActive ? t('form.lp.exchange-rate', { value: 100 * points_rate }) : t('table.row.no-exchange-rate')}
      </TableCell>
      <TableCell width="50%" className="text-text-disabled">
        {t('table.row.system')}
      </TableCell>
      <TableCell width="25%">
        <Badge variant={isActive ? 'lime-primary' : 'gray-secondary'} className="whitespace-nowrap">
          {isActive ? t('table.row.static-status') : t('table.row.static-status.not-active')}
        </Badge>
      </TableCell>
      <TableCell width={52} />
    </TableRow>
  )
}
