import HubEditorHeader from './HubEditorHeader'
import { useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { LeftSideBar } from './LeftSideBar'
import CenteredProgress from '../components/CenteredProgress'
import { EditorMode, HubEditorEventMessage, Side } from './editor-types'
import { MAX_WIDTH } from '../Settings'
import { uuid4 } from '../util'
import EditBlockModal from './dialogs/EditBlockModal'
import { FullscreenLayout } from '@/components/layouts'
import { ToastSeverity, useToast } from '@/ui'
import { AghanimTypesWebsiteConfig, Block, PageRead, WebsiteRead, WebsiteSection } from '@/api/dashboard'
import { KeyValue } from '@/types'
import { dashboardClient } from '@/api'
import { invalidatePages, usePagesQuery } from '@/layouts/pages/api/usePagesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useBanner } from '@/libs/hooks/useBanner'
import { useTranslation } from 'react-i18next'
import { cn, useModal } from '@dashboard/ui'

export default function HubEditor() {
  const { getStickyTop, getStickyHeight } = useBanner()
  const { companyId, gameId, wbId } = useParams() as { companyId: string; gameId: string; wbId: string }
  const [editorMode, setEditorMode] = useState<EditorMode>(EditorMode.Responsive)
  const [webSite, setWebSite] = useState<WebsiteRead | null>(null)
  const [previewConfig, setPreviewConfig] = useState<AghanimTypesWebsiteConfig>({})
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [page, setPage] = useState<PageRead | null>(null)
  const [slug, setSlug] = useState('')
  const [iframeVersion, setIframeVersion] = useState(0)
  const [isInit, setIsInit] = useState(false)

  const { data: pages = [] } = usePagesQuery(companyId, gameId, {
    limit: 100,
  })
  const [previewMode, setPreviewMode] = useState(false)
  const [websitePreviewLink, setWebsitePreviewLink] = useState<string>()

  const queryClient = useQueryClient()
  const showToast = useToast()
  const { t } = useTranslation()

  useEffect(() => {
    loadSite()
  }, [gameId])

  let default_props = {
    left_aside_width: '17em',
    right_aside_width: '20em',
    base_bg_color: '',
    text_color: '',
    border_radius: '8px',
    font_size: '14px',
    font_weight: '300',
    base_padding: '1em',
    accent_color: '',
    button_color: '',

    card_bg_color: '',
    ui_elements_color: '',
    text_muted: '',
    left_menu_item_color: '',
    login_button_bg: '',
    login_button_color: '',

    background_image_size: 'auto',
  } as AghanimTypesWebsiteConfig

  let webSiteUrl = webSite?.url ? webSite?.url + '?preview=on' : ''

  useEffect(() => {
    if (webSite && webSiteUrl) {
      const url = new URL(webSiteUrl)
      url.pathname = slug
      url.searchParams.set('share_token', webSite.share_token)
      url.searchParams.set('embedded', 'true')
      setWebsitePreviewLink(url.href)
    }
  }, [webSite, webSiteUrl, slug])

  const loadSite = async () => {
    let { data: site } = await dashboardClient.v1.getWebsite(companyId, gameId, wbId)
    if (site) {
      setWebSite(site)
    }
  }

  const saveClick = async () => {
    if (webSite) {
      await dashboardClient.v1.updateWebsite(companyId, gameId, webSite.id, webSite)
    }

    if (page?.layout) {
      if (page.id.startsWith('new')) {
        page.id = ''
        let { data: newPage } = await dashboardClient.v1.createPage(companyId, gameId, page)
        page.id = newPage.id

        invalidatePages(queryClient, gameId)
      } else {
        await dashboardClient.v1.updatePage(companyId, gameId, page.id, page)
      }
    }
    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  const moveBlock = (prevPage: PageRead | null, id: string, moveTo: 'up' | 'down'): PageRead | null => {
    if (!prevPage || !prevPage.layout.blocks?.length) {
      return null
    }

    let newBLocks = [...prevPage.layout.blocks] as Block[][]

    let idx = newBLocks.findIndex(it => it.find(it => it.id == id))
    if (idx == -1) {
      return prevPage
    }

    if (idx == 0 && moveTo == 'up') {
      return prevPage
    }

    if (idx == newBLocks.length - 1 && moveTo == 'down') {
      return prevPage
    }

    let row = newBLocks[idx]
    newBLocks.splice(idx, 1)
    if (moveTo == 'up') {
      newBLocks.splice(idx - 1, 0, row)
    } else {
      newBLocks.splice(idx + 1, 0, row)
    }

    return {
      ...prevPage,
      layout: {
        blocks: newBLocks,
      },
    } as PageRead
  }

  const removeBlock = (prevPage: PageRead | null, id: string): PageRead | null => {
    if (!prevPage || !prevPage.layout.blocks?.length) {
      return null
    }

    let newBLocks = [...prevPage.layout.blocks] as Block[][]

    let idx = newBLocks.findIndex(it => it.find(it => it.id == id))
    if (idx == -1) {
      return prevPage
    }

    let row = newBLocks[idx] as Block[]
    if (row.length == 1) {
      newBLocks.splice(idx, 1)
    } else {
      newBLocks[idx] = row.filter(it => it.id != id)
    }

    return {
      ...prevPage,
      layout: {
        blocks: newBLocks,
      },
    } as PageRead
  }

  const addBlock = (prevPage: PageRead | null, block: Block, side: Side, fromId: string): PageRead | null => {
    if (!prevPage || !side) {
      return null
    }

    block.id = uuid4()

    let blocks = prevPage.layout.blocks ? prevPage.layout.blocks : []
    let sourceIdx = blocks.findIndex((it: Block[]) => it.find(it => it.id == fromId))
    let newBlockIndex = sourceIdx

    let newBLocks: Block[][] = []

    switch (side) {
      case 'left':
        newBLocks = blocks
        newBLocks[sourceIdx].splice(0, 0, block)
        break
      case 'right':
        newBLocks = blocks
        newBLocks[sourceIdx].push(block)
        break
      default:
        if (side == 'down') {
          newBlockIndex++
        }
        let idx = 0
        let added = false
        while (idx < blocks.length) {
          if (idx == newBlockIndex && !added) {
            newBLocks.push([block])
            added = true
          } else {
            newBLocks.push(blocks[idx])
            idx++
          }
        }

        if (!added) {
          newBLocks.push([block])
        }
        break
    }

    return {
      ...prevPage,
      layout: {
        blocks: newBLocks,
      },
    } as PageRead
  }

  const showEditBlockModal = useModal<{ page: PageRead; block?: Block; side: Side; fromId: string }>(props => (
    <EditBlockModal
      {...props}
      side={props.side}
      block={props.block}
      onSave={(block: Block) => {
        if (props.block) {
          let section = props.page.layout.blocks.find((it: Block[]) => it.find(x => x.id == props.block?.id)) as Block[]
          if (section) {
            let idx = section.findIndex(x => x.id == props.block?.id)
            if (idx > -1) {
              block.id = section[idx].id
              section[idx] = block

              setPage({ ...props.page } as PageRead)
            }
          }
        } else {
          setPage(prev => addBlock(prev, block, props.side, props.fromId))
        }
      }}
    />
  ))

  const onGetMessage = (ev: MessageEvent) => {
    //context is lost
    try {
      const data = JSON.parse(ev.data) as HubEditorEventMessage
      switch (data.command) {
        case 'start-add-block':
          setPage(prev => {
            showEditBlockModal({ page: prev!, side: data.addTo, fromId: data.fromId })
            return prev
          })
          break
        case 'move-block':
          setPage(prev => moveBlock(prev, data.id, data.moveTo))
          break
        case 'edit-block': {
          setPage(prev => {
            let block = prev?.layout.blocks.flat().find((it: Block) => it.id == data.id)
            if (block) {
              showEditBlockModal({ page: prev!, side: data.addTo, fromId: data.fromId, block: block })
            }

            return prev
          })

          break
        }
        case 'remove-block':
          setPage(prev => removeBlock(prev, data.id))
          break
        // case 'ai':
        //   setCurrentAiTextBlock(prevId => {
        //     if (!prevId) {
        //       setTextAi('')
        //       refAIDesc.startGenerate()
        //     }
        //     return data.id
        //   })
        //   break
        case 'page-switched': {
          const page = pages.find(page => page.slug === data.page.slug)
          setPage(page || null)
          break
        }
      }
    } catch (e) {
      return false
    }
  }

  const sendIframeMessage = ({ menuSections }: { menuSections?: WebsiteSection[] }) => {
    if (webSiteUrl) {
      iframeRef.current?.contentWindow?.postMessage(JSON.stringify({ menuSections }), webSiteUrl)
    }
  }

  useEffect(() => {
    updatePageConfig()

    window.addEventListener('message', onGetMessage, false)

    return () => {
      window.removeEventListener('message', onGetMessage)
    }
  }, [webSite, pages, previewConfig])

  const updatePageConfig = () => {
    let config = {
      ...webSite?.config,
    } as KeyValue

    let keys = Object.keys(config)
    for (let key of keys) {
      if (!config[key]) {
        delete config[key]
      }
    }

    const notNullPreviewConfig = { ...previewConfig } as KeyValue
    keys = Object.keys(notNullPreviewConfig)
    for (let key of keys) {
      if (!notNullPreviewConfig[key]) {
        delete notNullPreviewConfig[key]
      }
    }

    const props = {
      ...default_props,
      ...config,
      ...notNullPreviewConfig,
    } as KeyValue

    if (webSiteUrl) {
      iframeRef.current?.contentWindow?.postMessage(
        JSON.stringify({
          config: props,
        }),
        webSiteUrl,
      )
    }
  }

  const updatePage = (p: PageRead) => {
    if (webSiteUrl) {
      iframeRef.current?.contentWindow?.postMessage(
        JSON.stringify({
          page: p,
        }),
        webSiteUrl,
      )
    }
  }

  useEffect(() => {
    if (page) {
      updatePage(page)
    }
  }, [page])

  useEffect(() => {
    if (isInit) {
      return
    }
    const homePage = pages.find(it => it.slug == 'home')
    if (homePage) {
      setPage(homePage)
      setIsInit(true)
    }
  }, [pages])

  let siteWidth = '100%'

  switch (editorMode) {
    case EditorMode.Desktop:
      siteWidth = MAX_WIDTH
      break
    case EditorMode.Responsive:
      siteWidth = '100%'
      break
    case EditorMode.Tablet:
      siteWidth = '768px'
      break
    case EditorMode.Mobile:
      siteWidth = '430px'
      break
  }

  const previewClick = () => {
    if (page) {
      updatePage(page)
    }
    setPreviewMode(prev => !prev)
  }

  return (
    <div className={cn('absolute left-0 z-50 size-full bg-white', getStickyTop(), getStickyHeight())}>
      <div className={cn('flex size-full flex-col justify-start')}>
        {!webSite ? (
          <FullscreenLayout>
            <CenteredProgress />
          </FullscreenLayout>
        ) : (
          <>
            <HubEditorHeader
              webSite={webSite}
              pages={pages || []}
              setSelectedPage={(p: PageRead | null) => {
                // update slug and reload iframe if previous page is not defined (readonly) or type is not blocks (not editable pages)
                if ((!page || page.type !== 'blocks') && p) {
                  const slug = p.slug === 'home' ? '' : p.slug
                  setSlug(slug)
                  setIframeVersion(iframeVersion + 1)
                }
                setPage(p)
              }}
              selectedPage={page}
              editorMode={editorMode}
              previewMode={previewMode}
              previewClick={previewClick}
              saveClick={saveClick}
              setEditorMode={setEditorMode}
            />

            <div className="flex w-full grow overflow-auto">
              {!previewMode && (
                <LeftSideBar
                  sendIframeMessage={sendIframeMessage}
                  previewConfig={previewConfig}
                  setPreviewConfig={setPreviewConfig}
                  webSite={webSite}
                  setWebSite={setWebSite}
                />
              )}
              <div className="relative flex size-full grow items-center justify-center bg-fg-tertiary-hover">
                <iframe
                  key={iframeVersion}
                  frameBorder={0}
                  src={websitePreviewLink}
                  ref={iframeRef}
                  onLoad={() => setTimeout(() => updatePageConfig(), 500)}
                  className="h-full"
                  style={{
                    width: siteWidth,
                    backgroundColor: '#000',
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
