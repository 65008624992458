import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Play: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46278 3.8289C9.47311 3.83598 9.48348 3.84309 9.49388 3.85021L18.6116 10.0978C18.8754 10.2785 19.1201 10.4461 19.3079 10.6018C19.504 10.7643 19.7351 10.9897 19.8681 11.3194C20.044 11.7551 20.044 12.2449 19.8681 12.6806C19.7351 13.0103 19.504 13.2357 19.3079 13.3982C19.1201 13.5539 18.8755 13.7215 18.6117 13.9022L9.46281 20.1711C9.14033 20.3921 8.85037 20.5908 8.60432 20.7278C8.35809 20.865 8.02013 21.0217 7.62566 20.9975C7.1211 20.9665 6.65499 20.7102 6.35005 20.2958C6.11164 19.9719 6.05104 19.596 6.02549 19.3093C5.99995 19.0229 5.99998 18.6647 6 18.2663L6 5.77209C6 5.75924 6 5.74643 6 5.73367C5.99998 5.33532 5.99995 4.97712 6.02549 4.69069C6.05104 4.40405 6.11164 4.02808 6.35005 3.70417C6.65499 3.28985 7.1211 3.03346 7.62566 3.00249C8.02013 2.97829 8.35809 3.13501 8.60432 3.27215C8.85036 3.4092 9.14032 3.60791 9.46278 3.8289Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Play.type = 'aghanim-icon';
