import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const H1: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path d="M3 19V5H5.63121V10.9268H12.3617V5H15V19H12.3617V13.0527H5.63121V19H3Z" fill="currentColor" />
      <path d="M20 12V19H18.7088V13.2339H18.6671L17 14.2798V13.1177L18.7715 12H20Z" fill="currentColor" />
    </IconBase>
  );
});

H1.type = 'aghanim-icon';
