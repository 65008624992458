import { ModalPresetConfirm, ModalProps } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { dashboardClient } from '@/api'
import { useParams } from 'react-router-dom'
import { GraphRoot, StoreGraph } from '@/api/dashboard'

interface ConfirmPublishModalProps extends ModalProps {
  storeGraph: StoreGraph
  onChange: () => void
  graph: GraphRoot
}

export const ConfirmPublishModal = (props: ConfirmPublishModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const storeGraph = props.storeGraph

  const confirmPublishChange = async () => {
    await dashboardClient.v1.createOrUpdateStoreGraph(companyId, gameId, {
      enabled: !storeGraph.enabled,
      group_by_categories: storeGraph.group_by_categories,
      graph: props.graph,
    })

    props.onChange()
  }

  return (
    <ModalPresetConfirm
      {...props}
      title={{
        children: t(storeGraph.enabled ? 'store-rules.confirm.unpublish' : 'store-rules.confirm.publish'),
        subtitle: t(
          storeGraph.enabled ? 'store-rules.confirm.unpublish.message' : 'store-rules.confirm.publish.message',
        ),
      }}
      cancel={{
        label: t('Cancel'),
      }}
      submit={{
        color: storeGraph.enabled ? 'danger' : 'primary',
        callback: async () => {
          props.onClose()
          await confirmPublishChange()
        },
        label: t(storeGraph.enabled ? 'unpublish' : 'publish'),
      }}
    />
  )
}
