import { useTranslation } from 'react-i18next'
import { useItemBulkUpdateMutate } from '../api'
import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle } from '@dashboard/ui'
import { SKUItem } from '@/api/types'
import { useGenerateCompanyPath } from '@/libs'

interface BulkUnarchiveModalProps extends ModalProps {
  items: SKUItem[]
  onResetItems?: (items: string[]) => void
}

export const BulkUnarchiveModal = ({ items, onResetItems, ...rest }: BulkUnarchiveModalProps) => {
  const { t } = useTranslation()
  const { companyId, gameId } = useGenerateCompanyPath()
  const { mutateAsync, isPending } = useItemBulkUpdateMutate(companyId, gameId, items[0].type)

  const onSubmit = async () => {
    await mutateAsync({ data: items.map(i => ({ id: i.id, archived_at: null as unknown as undefined })) })
    rest.onClose?.()
    onResetItems?.([])
  }

  return (
    <Modal className="max-w-[600px]" {...rest}>
      <ModalContent>
        <ModalTitle>
          {items.length > 1
            ? t('sku.bulk-unarchive.modal.title-multiple', { count: items.length })
            : t('sku.bulk-unarchive.modal.title-single', { itemName: items.at(0)?.name })}
        </ModalTitle>
        <div>
          <p className="text-paragraph-md text-text-secondary">{t('sku.bulk-unarchive.modal.desc')}</p>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" size="sm" onClick={rest.onClose} variant="outline" color="secondary">
          {t('sku.bulk-unarchive.modal.cancel')}
        </Button>
        <Button type="submit" size="sm" loading={isPending} onClick={onSubmit}>
          {t('sku.bulk-unarchive.modal.submit')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
