import { useEffect } from 'react'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { S3Bucket } from '@/types'
import { LoyaltyTier } from '@/api/dashboard'
import { Button, ButtonIcon, FieldGroup, Header, ImageUploader, Input, InputExtraSection } from '@/ui'
import { CloseOutline, LoyaltyPointOutline } from '@/icons'
import { AlertMessage, EngagementInfoBlock } from '@/layouts/engagement/component'

const MAX_NAME_LENGTH = 255
const MIN_POINTS = 1
const MAX_POINTS = 1_000_000

type LoyaltyTierModify = Omit<LoyaltyTier, 'id' | 'points' | 'items'> & { points: number | null }
export type LoyaltyTierSubmit = Omit<LoyaltyTierModify, 'points'> & { points: number }
type LoyaltyTierFormInput = LoyaltyTierModify

interface LoyaltyTierFormProps {
  tier: LoyaltyTierModify
  onSubmit: (input: LoyaltyTierSubmit) => void
  onClose: () => void
}

export const LoyaltyTierForm = ({ tier, onSubmit, onClose }: LoyaltyTierFormProps) => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm<LoyaltyTierFormInput>()
  const { field: image } = useController({ name: 'image_url', control })
  useEffect(() => reset(tier), [tier, reset])

  const { t } = useTranslation()

  return (
    <form
      onSubmit={handleSubmit(input => {
        onSubmit({ ...input, points: input.points ?? 0 })
        onClose()
      })}
    >
      <Header
        title={<span className="font-nohemi text-paragraph-md font-medium">{t('loyalty.level.editor.title')}</span>}
        className="p-4"
        minWidth="auto"
      >
        <div className="flex gap-4">
          <Button variant="primary" disabled={!isDirty} type="submit">
            {t('loyalty.level.editor.save')}
          </Button>
          <ButtonIcon onClick={onClose} type="reset" size="sm" variant="secondary-gray">
            <CloseOutline />
          </ButtonIcon>
        </div>
      </Header>

      <div className="flex size-full flex-col gap-4 p-4">
        <FieldGroup
          size="sm"
          label={
            <span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.level.editor.name')}</span>
          }
        >
          <Input
            size="sm"
            errors={errors}
            {...register('name', {
              required: t('validation.required'),
              validate: value => (value && value.trim().length > 0) || t('validation.required'),
              maxLength: {
                value: MAX_NAME_LENGTH,
                message: t('loyalty.level.editor.name.validation', { max: MAX_NAME_LENGTH }),
              },
            })}
          />
        </FieldGroup>

        <FieldGroup
          size="sm"
          label={
            <span className="text-caption-sm font-normal text-text-tertiary">{t('loyalty.level.editor.image')}</span>
          }
        >
          <ImageUploader
            {...image}
            bucket={S3Bucket.hub}
            accept={{
              'image/jpeg': ['.jpeg', '.jpg'],
              'image/png': [],
              'image/webp': [],
            }}
            recommendedSize={[512, 512]}
          />
        </FieldGroup>

        <div className="divider" />

        <div className="flex gap-16">
          <div className="flex-[2_2_0%]">
            <EngagementInfoBlock
              title={t('loyalty.level.editor.level.title')}
              desc={t('loyalty.level.editor.level.description')}
            />
          </div>

          <div className="flex-[3_3_0%]">
            <FieldGroup
              size="sm"
              label={
                <span className="text-caption-sm font-normal text-text-tertiary">
                  {t('loyalty.level.editor.points')}
                </span>
              }
            >
              <Input
                size="sm"
                type="number"
                extraLeft={<LoyaltyPointOutline size={20} />}
                extraRight={
                  <InputExtraSection side="right">
                    <span className="text-paragraph-xs">{t('loyalty.level.editor.points.suffix')}</span>
                  </InputExtraSection>
                }
                errors={errors}
                {...register('points', {
                  required: t('validation.required'),
                  min: {
                    value: MIN_POINTS,
                    message: t('loyalty.level.editor.points.validation.min', { min: MIN_POINTS }),
                  },
                  max: {
                    value: MAX_POINTS,
                    message: t('loyalty.level.editor.points.validation.max', { max: MAX_POINTS }),
                  },
                  valueAsNumber: true,
                })}
              />
            </FieldGroup>
            <AlertMessage type="info" message={t('loyalty.level.editor.points.info')} />
          </div>
        </div>
      </div>
    </form>
  )
}
