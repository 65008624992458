import { PageHeader, TableZeroState } from '@/ui'
import { Button, CloseOutline, useIsMobile, useSearchFilter } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useExpertsQuery } from '../api'
import { ExpertListToolbar } from '../components/ExpertListToolbar'
import { ExpertFeatures, ExpertListFilter, ParamsType } from '../types'
import { ExpertRow } from '../components/ExpertRow'
import { useFeatureFlags } from '@/libs'
import ComingSoon from '@/components/ComingSoon'
import { ExpertItemsSkeleton } from '../components/ExpertItemsSkeleton'

export const ExpertsListPage = () => {
  const { t } = useTranslation()
  const filter = useSearchFilter<ExpertListFilter>()
  const { featureFlags } = useFeatureFlags()
  const isMobile = useIsMobile()

  const { data: items = [], isLoading } = useExpertsQuery({
    limit: 100,
    ...filter.values,
  } as ParamsType)

  if (!featureFlags.experts_enabled) {
    return <ComingSoon />
  }

  const setFeatureFilter = (value: ExpertFeatures) => {
    filter.onChange({ ...filter.values, features: value }, { force: true })
  }

  const hasFilter = Object.values(filter.values).some(value => !!value)

  return (
    <>
      <PageHeader>{t('experts.title')}</PageHeader>
      <ExpertListToolbar filter={filter} />
      {isLoading ? (
        <ExpertItemsSkeleton />
      ) : items.length === 0 ? (
        <TableZeroState
          title={t('experts.search.zero.title')}
          message={t('experts.search.zero.text')}
          buttons={
            hasFilter && (
              <Button
                variant="outline"
                color="secondary"
                size="sm"
                onClick={() => {
                  filter.onReset()
                }}
              >
                <CloseOutline size={16} />
                <span>{t('experts.search.clear-filters')}</span>
              </Button>
            )
          }
        />
      ) : (
        <section className={isMobile ? 'flex flex-col gap-2' : 'grid-cols-2 gap-4 md:grid'}>
          {items.map(expert => (
            <ExpertRow key={expert.id} expert={expert} setFeatureFilter={setFeatureFilter} />
          ))}
        </section>
      )}
    </>
  )
}
