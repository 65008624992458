import { useQuery } from '@tanstack/react-query'
import { dashboardClient } from '../../../api'

/**
 * @param companyId
 * @param gameId
 */
export const useSegmentGroupsQuery = (companyId: string, gameId: string) => {
  return useQuery({
    queryKey: ['segment_groups', gameId],
    queryFn: () => dashboardClient.v1.getSegmentGroups(companyId, gameId).then(r => r.data),
  })
}
