import { useContext } from 'react'
import { Node } from '@/api/dashboard'
import { getNodeIcon } from './icons'
import { CampaignContext, ICampaignContext } from './Context'
import { Button, cn } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'

export default function SettingsHeader(props: {
  node?: Node | undefined | null
  text?: string
  onClose: () => void
  onSave?: () => void
  className?: string
}) {
  const { t } = useTranslation()
  const context = useContext(CampaignContext) as ICampaignContext
  return (
    <div
      className={cn(
        'sticky top-0 z-[300] flex h-[56px] min-h-[56px] items-center justify-between border-b border-border-primary bg-fg-primary px-6',
        props.className,
      )}
    >
      <div className="flex items-center gap-2 text-caption-lg font-semibold">
        {props.node && getNodeIcon(props.node.model_type)}
        {props.node ? t(`campaign.block.${props.node.model_type}.name`) : props.text}
      </div>
      <div className="flex gap-3">
        <Button
          onClick={props.onClose}
          data-testid="settings-close-button"
          size="sm"
          color="secondary"
          variant="outline"
        >
          {t('close')}
        </Button>
        {!context.readOnly && props.onSave && (
          <Button color="primary" data-testid="sku/save" onClick={props.onSave} size="sm">
            {t('Save2')}
          </Button>
        )}
      </div>
    </div>
  )
}
