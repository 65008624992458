import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const CurrencyOutline: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6 2C12.6165 2 10.1121 4.04059 9.40169 6.80074C9.26402 7.3356 9.58601 7.88078 10.1209 8.01844C10.6557 8.1561 11.2009 7.83411 11.3386 7.29926C11.8271 5.40129 13.5511 4 15.6 4C18.0301 4 20 5.96995 20 8.4C20 10.4488 18.5987 12.1729 16.7008 12.6614C16.1659 12.7991 15.844 13.3443 15.9816 13.8791C16.1193 14.414 16.6645 14.736 17.1993 14.5983C19.9594 13.8879 22 11.3835 22 8.4C22 4.86538 19.1346 2 15.6 2ZM4 15.6C4 13.1699 5.96995 11.2 8.4 11.2C10.8301 11.2 12.8 13.1699 12.8 15.6C12.8 18.0301 10.8301 20 8.4 20C5.96995 20 4 18.0301 4 15.6ZM8.4 9.2C4.86538 9.2 2 12.0654 2 15.6C2 19.1346 4.86538 22 8.4 22C11.9346 22 14.8 19.1346 14.8 15.6C14.8 12.0654 11.9346 9.2 8.4 9.2ZM5.58066 8.19706C5.73522 8.19706 5.8608 8.12599 6.01214 7.96964L8.4175 5.46808C8.53664 5.34726 8.57528 5.21223 8.57528 5.09853C8.57528 4.98127 8.53664 4.84979 8.4175 4.72543L6.01214 2.24518C5.8447 2.07462 5.74166 2 5.5871 2C5.36492 2 5.20714 2.19543 5.20714 2.42996V3.68074H5.12342C2.95957 3.68074 2 5.21223 2 7.68182C2 8.01228 2.15456 8.18995 2.33166 8.18995C2.47012 8.18995 2.6118 8.15086 2.72128 7.927C3.23971 6.86099 3.96421 6.52342 5.12342 6.52342H5.20714V7.78487C5.20714 8.01939 5.36492 8.19706 5.58066 8.19706ZM18.4193 15.8029C18.2648 15.8029 18.1392 15.874 17.9879 16.0304L15.5825 18.5319C15.4634 18.6527 15.4247 18.7878 15.4247 18.9015C15.4247 19.0187 15.4634 19.1502 15.5825 19.2746L17.9879 21.7548C18.1553 21.9254 18.2583 22 18.4129 22C18.6351 22 18.7929 21.8046 18.7929 21.57V20.3193H18.8766C21.0404 20.3193 22 18.7878 22 16.3182C22 15.9877 21.8454 15.8101 21.6683 15.8101C21.5299 15.8101 21.3882 15.8491 21.2787 16.073C20.7603 17.139 20.0358 17.4766 18.8766 17.4766H18.7929V16.2151C18.7929 15.9806 18.6351 15.8029 18.4193 15.8029Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

CurrencyOutline.type = 'aghanim-icon';
