import { HTMLAttributes, ReactNode } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { getMotionProps } from '@/libs'
import { cn, useIsMobile } from '@dashboard/ui'

interface TableZeroStateProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title: ReactNode
  message?: ReactNode
  buttons?: ReactNode
  image?: ReactNode
  contentClassName?: string
}

const buttonMotionProps = getMotionProps({
  initial: { y: 4, marginTop: 0, height: 0, opacity: 0 },
  animate: { y: 0, marginTop: 24, height: 'auto', opacity: 1 },
  exit: { y: -4, marginTop: 0, height: 0, opacity: 0 },
})

export const TableZeroState = ({ title, image, message, buttons, contentClassName, ...rest }: TableZeroStateProps) => {
  const isMobile = useIsMobile()

  return (
    <div {...rest} className={cn('h-full', rest.className, isMobile ? 'pb-4' : '')}>
      <div
        className={cn(
          'flex h-full  justify-center gap-6 rounded-lg bg-fg-primary-hover',
          image ? 'items-start py-12' : 'items-center',
        )}
      >
        {image}
        <div className={cn('flex flex-col', contentClassName, isMobile ? 'px-9' : 'w-[430px]')}>
          <span className="text-[16px] font-semibold text-text-primary">{title}</span>
          {message && <span className="mt-3 text-[14px] text-text-tertiary">{message}</span>}
          <AnimatePresence initial={false}>
            {buttons && (
              <motion.div {...buttonMotionProps} className="flex shrink-0 gap-2">
                {buttons}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}
