import { GameStore, SocialMedia, SocialMediaName } from '@/api/dashboard'
import { SOCIAL_ICONS_MAP, STORES_ICONS_MAP } from '../../../icons/Icons'
import { PlusContained, Trash } from '@/icons'
import { SocialModal } from './SocialModal'
import { StoreSocialMode } from '../tyles'
import { useTranslation } from 'react-i18next'
import { useModal } from '@dashboard/ui'
import { ButtonIcon } from '@/ui'
import { ReactSortable } from 'react-sortablejs'
import { KeyValue } from '@/types'

export const SocialMediaList = (props: {
  value: SocialMedia[] | GameStore[]
  onChange?: (items: SocialMedia[] | GameStore[]) => void
  mode: StoreSocialMode
}) => {
  const { t } = useTranslation()

  const onAdd = (item: SocialMedia | GameStore) => {
    if (item.name && item.url && !props.value.find(it => it.url === item.url)) {
      props.onChange?.([...props.value, item] as SocialMedia[])
    }
  }

  const openAddItem = useModal(p => (
    <SocialModal mode={props.mode} onAdd={it => onAdd(it as SocialMedia | GameStore)} {...p} />
  ))

  const onDelete = (delItem: SocialMedia | GameStore) => {
    props.onChange?.(props.value.filter(it => it.url !== delItem.url) as SocialMedia[])
  }

  const icons: Record<string, string> = props.mode == StoreSocialMode.social ? SOCIAL_ICONS_MAP : STORES_ICONS_MAP
  const list = (props.value || []).map(it => ({
    ...it,
    id: it.url,
  }))

  return (
    <div className="flex flex-col">
      <ReactSortable
        animation={200}
        delay={2}
        disabled={!props.onChange}
        filter=".non-drag"
        list={list}
        setList={v => {
          let changed = false
          for (let i = 0; i < v.length; i++) {
            if (v[i].id != list[i].id) {
              changed = true
              break
            }
          }
          if (changed && props.onChange) {
            console.log(v)
            props.onChange(
              v.map(it => {
                const item = { ...it } as KeyValue
                delete item['id']
                return item as unknown as SocialMedia
              }),
            )
          }
        }}
      >
        {props.value.map(it => (
          <div
            key={it.url}
            className="mb-1.5 flex w-full items-center gap-3 rounded-xl border-2 border-border-primary bg-fg-primary p-3 shadow-xs"
          >
            <img
              src={it.name == SocialMediaName.Custom ? it.custom_icon_url : icons[it.name] || ''}
              alt={it.name}
              className="size-6"
            />
            <div className="overflow-hidden whitespace-nowrap">
              <div className="flex h-[24px] items-center font-medium capitalize text-text-secondary">
                {it.name == SocialMediaName.Custom
                  ? it.custom_name
                  : props.mode == StoreSocialMode.social
                    ? it.name
                    : t(`game-settings.${it.name}`)}
              </div>
              <a href={it.url} target="_blank" className="flex h-[24px] items-center font-[14px] text-text-tertiary">
                {it.url}
              </a>
            </div>

            {props.onChange && (
              <ButtonIcon variant="tertiary-destructive" className="ml-auto" size="sm" onClick={() => onDelete(it)}>
                <Trash />
              </ButtonIcon>
            )}
          </div>
        ))}
      </ReactSortable>

      {props.onChange && (
        <div
          onClick={() => openAddItem()}
          className="flex h-[72px] cursor-pointer items-center justify-center rounded-xl border-2 border-dashed border-border-primary hover:bg-fg-secondary-hover"
        >
          <PlusContained color="#CBD5E1" />
        </div>
      )}
    </div>
  )
}
