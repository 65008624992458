import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { SpinnerAghanim } from '@/ui'
import { useForm } from 'react-hook-form'
import { OutdatedAgreements } from '@/api/dashboard'
import { dashboardClient } from '@/api'
import { postLogin } from '@/api/postLogin'
import { goToLogout } from '@/util'
import {
  Button,
  Checkbox,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  cn,
  useIsMobile,
} from '@dashboard/ui'
import { myLeadQueryOptions } from '@/auth/api/myLeadQueryOptions'
import ModalUiOutlet from '@/auth/ModalUiOutlet'
import FullScreenActionBackground from '@/layouts/components/FullScreenActionBackground/FullScreenActionBackground'

const AcceptModal = (props: ModalProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState<OutdatedAgreements>({
    privacy: false,
    terms: false,
  })
  const { register, handleSubmit, watch } = useForm<OutdatedAgreements>()
  const { data: myLead, isLoading } = useQuery(myLeadQueryOptions())
  const isMobile = useIsMobile()

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await dashboardClient.v1.getOutdatedAgreements()
      setInitialValues({
        privacy: data.privacy,
        terms: data.terms,
      })
    }
    fetchData()
  }, [])

  const [privacy, terms] = watch(['privacy', 'terms'])

  let enableAccept = true

  if (initialValues.privacy && !privacy) {
    enableAccept = false
  }

  if (initialValues.terms && !terms) {
    enableAccept = false
  }

  const onSubmit = handleSubmit(async () => {
    if (loading) {
      return
    }
    setLoading(true)
    await dashboardClient.v1.acceptAgreements()
    await postLogin()
    props.onClose()
  })

  const onDecline = () => {
    goToLogout()
  }

  return (
    <Modal {...props} size="lg" className="mx-9">
      {!myLead || isLoading ? (
        <div className={cn('flex h-80 items-center justify-center', isMobile ? '' : 'min-w-[532px]')}>
          <SpinnerAghanim />
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <ModalContent>
            <ModalTitle
              subtitle={
                <p className="text-caption-md font-medium text-text-secondary">
                  {myLead.completed ? t('terms-dialog.update-subtitle') : t('terms-dialog.first-subtitle')}
                </p>
              }
            >
              {myLead.completed ? t('terms-dialog.update-title') : t('terms-dialog.first-title')}
            </ModalTitle>

            <div className="mt-3 flex flex-col gap-3">
              <div
                className="text-caption-sm text-text-secondary"
                dangerouslySetInnerHTML={{ __html: t('terms-dialog.subtitle2') }}
              />
              <div className="flex flex-col gap-3">
                {initialValues.privacy && (
                  <Checkbox {...register('privacy', { required: true })}>
                    <span className="text-caption-sm font-medium leading-5 text-text-primary">
                      {t('terms-dialog.pp')}
                    </span>
                  </Checkbox>
                )}

                {initialValues.terms && (
                  <Checkbox {...register('terms', { required: true })}>
                    <span className="text-caption-sm font-medium leading-5 text-text-primary">
                      {t('terms-dialog.dt')}
                    </span>
                  </Checkbox>
                )}
              </div>
            </div>
          </ModalContent>
          <ModalFooter>
            <Button type="submit" size="md" disabled={!enableAccept}>
              {t('terms-dialog.accept')}
            </Button>
            <Button
              variant="link"
              color="secondary"
              size="md"
              onClick={onDecline}
              className="text-text-disabled underline"
            >
              {t('terms-dialog.decline')}
            </Button>
          </ModalFooter>
        </form>
      )}
    </Modal>
  )
}

export const AcceptPageOutlet = (props: { onClose: () => void }) => {
  return (
    <ModalUiOutlet>
      <FullScreenActionBackground>
        <AcceptModal
          onClose={() => {
            props.onClose()
          }}
          onResize={() => {}}
        />
      </FullScreenActionBackground>
    </ModalUiOutlet>
  )
}
