// export const PAYPAL_HACK = [
//   {
//     id: 'paypal',
//     name: 'PayPal',
//     settings: { enabled: true, global_enabled: true },
//     supported_countries: [],
//     fee: [{ fee_fix: 30, fee_prc: 3, country_code: '', currency: '' }],
//     logo_url: 'https://static.aghanim.com/payment-methods/paypal.svg',
//   },
//   {
//     id: 'paypal',
//     name: 'PayPal (micro..) USA',
//     settings: { enabled: true, global_enabled: true },
//     supported_countries: [],
//     fee: [{ fee_fix: 9, fee_prc: 4.59, country_code: '', currency: '' }],
//     logo_url: 'https://static.aghanim.com/payment-methods/paypal.svg',
//   },
//   {
//     id: 'paypal',
//     name: 'PayPal (micro..) other',
//     settings: { enabled: true, global_enabled: true },
//     supported_countries: [],
//     fee: [{ fee_fix: 9, fee_prc: 6.09, country_code: '', currency: '' }],
//     logo_url: 'https://static.aghanim.com/payment-methods/paypal.svg',
//   },
// ]

import { PaymentMethod } from '@/api/dashboard'

export const HACK_METHODS = [
  {
    id: 'paypal',
    name: 'PayPal',
    caption: 'PayPal',
    logo_url: 'https://static.aghanim.com/payment-methods/paypal.svg',
    fee: [
      {
        fee_fix: 30,
        fee_prc: 3,
        country_code: 'game-settings.ps-table.regions.global',
        currency: '',
        first_column: 'PayPal',
      },
      { fee_fix: 9, fee_prc: 4.59, country_code: 'US', currency: '', first_column: 'PayPal micropayments' },
      {
        fee_fix: 9,
        fee_prc: 6.09,
        country_code: 'countries.other',
        currency: '',
        first_column: 'PayPal micropayments',
      },
    ],
    settings: {
      enabled: true,
      global_enabled: true,
    },
    supported_countries: [],
    dashboard_show: false,
  },
  {
    id: 'pm_eDGkVDPmiokhDOgMtdpoSwJoEmlMs',
    name: 'adyen_plaid',
    caption: 'Pay by Bank',
    logo_url: 'https://static.aghanim.com/payment-methods/bank_placeholder.svg',
    fee: [
      {
        fee_fix: '+$0.365',
        fee_prc: 0.6,
        country_code: '',
        currency: '',
        first_column: 'Initial Transaction',
      },
      {
        fee_fix: '+$0.165',
        fee_prc: 0.6,
        country_code: '',
        currency: '',
        first_column: 'Recurring Transaction',
      },
    ],
    settings: {
      enabled: true,
      global_enabled: true,
    },
    supported_countries: ['US'],
    dashboard_show: true,
  },
] as unknown as PaymentMethod[]
