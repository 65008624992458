import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FieldGroup, ToastSeverity, useToast } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { CustomerCompanyRead, InviteRead, Role } from '@/api/dashboard'
import { ResponseError, dashboardClient, getErrorText } from '@/api'
import { isEmail } from '@/util'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  Email,
  FormErrorMessage,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalProps,
  ModalTitle,
  Select,
  formatHookFormErrors,
} from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from '@/api/useCurrentUser'

interface EditInviteDialogProps extends ModalProps {
  item: InviteRead | CustomerCompanyRead
}

interface EditCustomerForm {
  email: string
  role: Role
}

const EditInviteDialog = (props: EditInviteDialogProps) => {
  const { ...rest } = props
  const { companyId } = useParams() as { companyId: string }
  const { isSuperAdmin } = useCurrentUser()
  const { t } = useTranslation()
  const [helperText, setHelperText] = useState('')
  const showToast = useToast()
  const queryClient = useQueryClient()
  const createdId = (props.item as CustomerCompanyRead).customer?.id

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EditCustomerForm>({
    defaultValues: createdId
      ? { email: (props.item as CustomerCompanyRead).customer?.email, role: (props.item as CustomerCompanyRead).role }
      : {
          ...props.item,
        },
  })

  const onSaveClick = async (data: EditCustomerForm) => {
    setHelperText('')
    try {
      if (createdId) {
        await dashboardClient.v1.updateMember(createdId, companyId, {
          role: data.role,
        })
      } else {
        let emails = data.email.split(' ').filter(it => !!it)
        await dashboardClient.v1.getOrCreateInvites(
          companyId,
          emails.map(email => ({
            email,
            role: data.role,
          })),
        )
        if (data.role != Role.Viewer) {
          showToast({ message: t('users.invite-sent'), severity: ToastSeverity.success })
        }
        queryClient.invalidateQueries({ queryKey: ['invite', companyId] })
      }
      props.onClose?.()
      return true
    } catch (e) {
      setHelperText(getErrorText(e as ResponseError))
    }
  }

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(onSaveClick)}>
        <ModalContent>
          <ModalTitle>{t(createdId ? 'users.edit.role' : 'users.invite')}</ModalTitle>
          <FieldGroup label={t('users.email')}>
            <Input
              id="email"
              data-testid="email"
              disabled={!!createdId}
              autoFocus
              {...register('email', {
                required: t('validation.required'),
                validate: v => {
                  let arr = v.split(' ')
                  if (arr.find(it => !isEmail(it))) {
                    return t('validation.invalid_email')
                  }
                  return true
                },
                setValueAs: value => value?.trim(),
              })}
              {...formatHookFormErrors(errors, 'email')}
              extraLeft={{ icon: Email }}
              placeholder={'example@email.com'}
            />
          </FieldGroup>

          <FieldGroup label={t('users.role')}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  items={[
                    isSuperAdmin
                      ? {
                          value: Role.Viewer,
                          children: t('users.role.viewer'),
                          caption: t('users.role.viewer.desc'),
                        }
                      : undefined,
                    {
                      value: Role.User,
                      children: t('users.role.user'),
                      caption: t('users.role.user.desc'),
                    },
                    {
                      value: Role.Editor,
                      children: t('users.role.editor'),
                      caption: t('users.role.editor.desc'),
                    },
                    {
                      value: Role.Developer,
                      children: t('users.role.developer'),
                      caption: t('users.role.developer.desc'),
                    },
                    {
                      value: Role.Admin,
                      children: t('users.role.admin'),
                      caption: t('users.role.admin.desc'),
                    },
                  ].filter(it => !!it)}
                />
              )}
            />
          </FieldGroup>

          {helperText && <FormErrorMessage>{helperText}</FormErrorMessage>}
        </ModalContent>
        <ModalFooter>
          <Button size="sm" variant="outline" color="secondary" onClick={props.onClose} type="button">
            {t('Cancel')}
          </Button>
          <Button size="sm" type="submit">
            {t(createdId ? 'Save2' : 'users.invite-dialog-button')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default EditInviteDialog
