import { JobFunctionName } from '@/api/dashboard'

export interface ErrorDetail {
  loc: string[]
  msg: string
  type: 'value_error.missing' | string
}

export interface HubApiError {
  detail: string
}

export type KeyValue = {
  [key: string]: string | number | null | undefined | unknown
}

export const URL_NEW = 'new'

export interface TimestampModel {
  created_at: number
  modified_at?: number | null
}

export interface Company {
  name: string
  size: number
  url: string
  id: string //uuid
  domains: string[]
  games: Game[]
  viewer_domains?: string[]
}

export enum GameStoreType {
  app_store = 'app_store',
  google_play = 'google_play',
}

export enum SocialMediaType {
  facebook = 'facebook',
  instagram = 'instagram',
  twitter = 'twitter',
  discord = 'discord',
  youtube = 'youtube',
  tiktok = 'tiktok',
}

export interface GameMediaItem {
  name: SocialMediaType | GameStoreType
  url: string
}

export interface Game {
  id: string
  company_id: string
  name: string
  description: string
  url: string
  logo_url: string
  social_medias: GameMediaItem[]
  game_stores: GameMediaItem[]
  login_type: LoginType
}

export enum LoginType {
  auth0 = 'auth0',
  userId = 'user_id',
}

export interface ImageSize {
  width: number
  height: number
}

export interface Rect {
  left: number
  top: number
  width: number
  height: number
}

export enum ImageUsageContext {
  store = 'store',
  featured_banner_main = 'featured_banner_main',
  featured_banner_store = 'featured_banner_store',
  payment_receipt = 'payment_receipt',
}

export interface ImageFlip {
  horizontal: boolean
  vertical: boolean
}

export interface ImageTransforms {
  rotate: number
  flip: ImageFlip
}

export interface ImageCrop {
  aspect_ratio: '4:3' | '1:1'
  image_size?: ImageSize
  transforms?: ImageTransforms | null
  coordinates?: Rect | null
  visible_area?: Rect | null
}

export interface GameItemImage extends TimestampModel {
  original_url: string
  id: string
  cropped_url: string
  usage_context: ImageUsageContext
  crop: ImageCrop
  isChanged?: boolean
}

export interface GameItem extends TimestampModel {
  name: string
  description: string
  price: number
  price_point?: number
  discount_percent: number | null
  bonus_percent: number | null
  currency: string
  sku: string
  image_url: string
  game_id: string
  id: string
  type: GameItemType
  category: string
  published: boolean
}

export enum GameItemType {
  item = 'item',
  currency = 'currency',
}

export interface FieldInfo {
  i18: string
  property: string
  type: 'text' | 'number'
  fullWidth?: boolean
  min?: number
  max?: number
  disabled?: boolean
  maxRows?: number
  rows?: number
  placeholder?: string
  maxLength?: number
}

export interface Payout {
  id: string
  created_at: number
  status: string
  currency: string
  amount: number
  net: number
}

export interface StatusTransition {
  time: number
  to_status: string
}

export interface Fees {
  amount: number
  details: FeesDetails
}

export interface FeesDetails {
  fee_name: string
  fee_prc: number
  fee_fx: number
  fee_amount: number
}

export interface Taxes {
  amount: number
  details: TaxesDetail[]
}

export interface TaxesDetail {
  fee_name: string
  fee_prc: number
  fee_amount: number
}

export enum NewsCategory {
  bugfix = 'bugfix',
  company_news = 'company_news',
  update = 'update',
  maintenance = 'maintenance',
  event = 'event',
}

export enum NewsGroup {
  event = 'event',
  update = 'update',
  other = 'other',
}

export interface SortModel {
  field: string | null
  order: 'asc' | 'desc' | null
}

export interface Achievement {
  game_id: string
  name: string
  description: string
  image_url: string
  type: AchievementType
  id: string
}

export interface AchievementPlayerRead {
  id: string
  achievement_id: string
  user: Customer
  created_at: number
}

export interface IPersistentPlayerAttributes {
  level: number
  saved_ach: boolean
  saved_card: boolean
  soft_currency_amount: number
  hard_currency_amount: number

  custom_attributes: { [key: string]: string }
}

export enum AchievementType {
  first_visit = 'first_visit',
  first_purchase = 'first_purchase',
  regular_visit = 'regular_visit',
}

export interface Coupon {
  name: string
  id: string
  code: string
  item_id: string
  type: CouponType
  discount_percent: number
  bonus_percent: number
  item: GameItem
}

export enum CouponType {
  bonus = 'bonus',
  discount = 'discount',
  free_item = 'free_item',
  non_benefit = 'non_benefit',
}

export interface Leaderboard {
  name: string
  description: string
  id: string
  ranked_at: number
}

export interface LeaderboardMember {
  member_id: string
  member_data: string
  image_url: string
  rank: number
  score: number
  id: string
  created_at: number
  modified_at: number
}

export enum SectionType {
  home = 'home',
  store = 'store',
  redeem_code = 'redeem_code',
  news = 'news',
  updates = 'updates',
  events = 'events',
  //watch = 'watch',
  //play = 'play',
  leaderboard = 'leaderboard',
  achievement = 'achievement',
  about = 'about',
  //chat = 'chat',
  //forum = 'forum',
  //merch = 'merch',
}

export enum SectionObjectType {
  Page = 'page',
}

export interface WebsiteSection {
  name: SectionType
  visible: boolean
  /** An enumeration. */
  object_type?: SectionObjectType
  /** Object Id */
  object_id?: string
  icon?: string
}

export interface WebSite extends TimestampModel {
  id: string
  title: string
  description: string
  url: string
  slug: string
  image_url: string
  config: WebsiteConfig
  featured_videos: VideoInterface[]
  featured_items: GameItem[]
  template_id: string
  game?: Game
  share_token: string
  published_at?: number | null
}

export interface AgeSettings {
  min_age: number
  icon_url: string
}

export enum ScreenshotFormFactor {
  wide = 'wide',
  narrow = 'narrow',
}

export interface Screenshot {
  src: string
  type: string
  sizes: string
  form_factor: ScreenshotFormFactor
}

export interface BannerConfig {
  title: string | null
  description: string | null
  image_url: string | null
  background_image_size: string | null
}

export interface NewsBannerConfig extends BannerConfig {
  featured_news_id: string
}

export interface FeaturedBannerConfig extends BannerConfig {
  featured_item_id: string
}

export interface StoreSettings {
  default_image_url: string
  custom_texts?: {
    item_text?: string
    currency_text?: string
  }
}

export interface NewsSettings {
  default_image_urls: { [key: string]: string }
}

export interface WebsiteConfig {
  favicon_url: string
  base_bg_color: string
  base_bg_image: string
  background_image_size: string
  background_image_opacity: number
  accent_color: string
  button_color: string
  dark_bg_color: string
  featured_bg_color: string

  card_bg_color: string
  ui_elements_color: string
  text_muted: string
  left_menu_item_color: string
  login_button_bg: string
  login_button_color: string

  text_color: string
  border_radius: string
  font_size: string
  twitch_video_url: string
  youtube_video_url: string

  left_aside_width: string
  right_aside_width: string
  font_weight: string
  base_padding: string
  sections: WebsiteSection[]
  age_settings: AgeSettings
  screenshots: Screenshot[]
  trademarks: string
  banner_config: NewsBannerConfig | null
  featured_banner_config: FeaturedBannerConfig | null
  mode: WebSiteMode
  demo_mode?: boolean

  store_settings: StoreSettings | null
  news_settings: NewsSettings | null
}

export enum WebSiteMode {
  dark = 'dark',
  light = 'light',
  auto = 'auto',
}

export interface OnBoardingResponse {
  company: Company
  game: Game
  website: WebSite
}

export interface CreateUploadLinkResponse {
  upload_url: string
  get_url: string
}

export enum GameTaskType {
  configure_api_keys = 'configure_api_keys',
  configure_webhooks = 'configure_webhooks',
  integrate_game = 'integrate_game',
}

export enum TaskStatus {
  TODO = 'todo',
  COMPLETED = 'completed',
}

export interface GameTask {
  type: GameTaskType
  status: TaskStatus
}

export enum S3Bucket {
  hub = 'hub',
  news = 'news',
  pages = 'pages',
  achievement = 'achievement',
  sku = 'sku',
  dashboard = 'dashboard',
}

export interface Customer {
  id: string
  name: string
  email: string
  created_at: number
}

export enum Role {
  admin = 'admin',
  user = 'user',
  editor = 'editor',
  viewer = 'viewer',
}

export interface CustomerCompany {
  id: string
  customer: Customer
  role: Role
}

export interface Invite extends Customer {
  id: string
  email: string
  created_at: number
  role: Role
  created_by_user: Customer
}

export interface Page {
  id: string
  title: string
  text: string
  description: string
  slug: string
  layout: {
    blocks: BlockInterface[][]
  }
}

export interface JWTPayload {
  exp: number
  game_id: string
  iat: number
  user_id: string
}

export enum WSEventType {
  website_generate_start = 'website_generate_start',
  website_generate_status = 'website.generate_status',
  payment_status = 'payment_status_updated',
  translation_progress = 'translation.progress',
  translation_complete = 'translation.complete',
  translation_sync_complete = 'translation.sync_complete',
  locale_status_change = 'locale.status_change',
  job_progress = 'job.progress',
}

export interface JobContext {
  job_id: string
  company_id: string
  game_id: string
  total: number
  done: number
  last_updated_at: number
  progress_name: string
  function_name: JobFunctionName
  function_args: Record<string, string>
  is_cancelled?: boolean
  error_text?: string
}

export interface WSMessage<T> {
  type: WSEventType
  payload: T
}

export enum VideoPlatform {
  youtube = 'youtube',
  twitch = 'twitch',
}

export interface YouTubeData {
  video_id: string
}

export interface TwitchData {
  channel: string
}

export interface VideoInterface {
  title: string
  description: string
  thumbnail_url?: string
  platform: VideoPlatform
  data: YouTubeData | TwitchData
}

export type YoutubeBlockInterface = {
  id: string
  video: VideoInterface
  block: 'YoutubeBlock'
}

export type TwitchBlockInterface = {
  id: string
  video: VideoInterface
  block: 'TwitchBlock'
}

export type FeaturedNewsBlockInterface = {
  id: string
  news_id: string
  block: 'FeaturedNewsBlock'
}

export type FullNewsBlockInterface = {
  id: string
  news_id: string
  block: 'FullNewsBlock'
}

export type TextBlockInterface = {
  id: string
  title: string
  text: string
  block: 'TextBlock'
}

export type BannerBlockInterface = {
  id: string
  block: 'BannerBlock'
  description: string | null
  image_url: string | null
  title: string | null
  background_image_size: string | null
}

export type FeaturedItemBlockInterface = {
  id: string
  item_id: string
  background_image_size?: string | null
  block: 'FeaturedItemBlock'
}

export type RedeemCouponBlockInterface = {
  id: string
  block: 'RedeemCouponBlock'
}

export type LatestNewsBlockInterface = {
  id: string
  block: 'LatestNewsBlock'
}

export type LeaderboardFullBlock = {
  id: string
  leaderboard_id: string
  block: 'LeaderboardFullBlock'
}

export type LeaderboardCollectionInterface = {
  id: string
  block: 'LeaderboardCollection'
}

export type LeaderboardCardBlock = {
  id: string
  leaderboard_id: string
  block: 'LeaderboardCardBlock'
}

export type StoreBlock = {
  id: string
  block: 'StoreBlock'
}

export type BlockInterface =
  | YoutubeBlockInterface
  | TwitchBlockInterface
  | LatestNewsBlockInterface
  | FullNewsBlockInterface
  | BannerBlockInterface
  | TextBlockInterface
  | RedeemCouponBlockInterface
  | FeaturedItemBlockInterface
  | FeaturedNewsBlockInterface
  | LeaderboardCardBlock
  | LeaderboardFullBlock
  | LeaderboardCollectionInterface
  | StoreBlock
