export class MemorySearchParams {
  private params: Map<string, string[]>;

  constructor(init?: URLSearchParams) {
    this.params = new Map<string, string[]>();

    if (typeof init === 'string') {
      this.parseString(init);
    } else if (init instanceof URLSearchParams) {
      init.forEach((value, key) => this.append(key, value));
    } else if (Array.isArray(init)) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument
      init.forEach(([key, value]) => this.append(key, value));
    } else if (init && typeof init === 'object') {
      // @ts-ignore
      Object.entries(init).forEach(([key, value]) => this.append(key, value));
    }
  }

  private parseString(queryString: string) {
    queryString
      .replace(/^\?/, '')
      .split('&')
      .forEach(pair => {
        const [key, value] = pair.split('=').map(decodeURIComponent);
        this.append(key, value);
      });
  }

  append(name: string, value: string) {
    if (!this.params.has(name)) {
      this.params.set(name, []);
    }
    this.params.get(name)?.push(value);
  }

  delete(name: string) {
    this.params.delete(name);
  }

  get(name: string): string | null {
    const values = this.params.get(name);
    return values ? values[0] : null;
  }

  getAll(name: string): string[] {
    return this.params.get(name) || [];
  }

  has(name: string): boolean {
    return this.params.has(name);
  }

  set(name: string, value: string) {
    this.params.set(name, [value]);
  }

  toString(): string {
    const queryString: string[] = [];
    this.params.forEach((values, key) => {
      values.forEach(value => {
        queryString.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      });
    });
    return queryString.join('&');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forEach(callback: (value: string, key: string, parent: this) => void, thisArg?: any) {
    this.params.forEach((values, key) => {
      values.forEach(value => {
        callback.call(thisArg, value, key, this);
      });
    });
  }

  keys(): IterableIterator<string> {
    return this.params.keys();
  }

  values(): IterableIterator<string> {
    const allValues: string[] = [];
    this.params.forEach(values => {
      allValues.push(...values);
    });
    return allValues.values();
  }

  entries(): IterableIterator<[string, string]> {
    const allEntries: [string, string][] = [];
    this.params.forEach((values, key) => {
      values.forEach(value => {
        allEntries.push([key, value]);
      });
    });
    return allEntries.values();
  }

  [Symbol.iterator](): IterableIterator<[string, string]> {
    return this.entries();
  }
}
