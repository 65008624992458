import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import CircularProgress from '@mui/material/CircularProgress'
import { cn } from '../../../libs/cn'
import { Image01, Trash } from '@/icons'
import { getMotionProps } from '../../../libs'
import { getFileNameFromURL } from './getFileNameFromURL'
import { getFileSizeFromURL } from './getFileSizeFromURL'
import { containerMotionProps } from './containerMotionProps'

export enum ImageSize {
  cover = 'cover',
  contain = 'contain',
}

interface ValueContainerProps {
  file: File | { src: string }
  progress?: number
  imageSize?: ImageSize
  disabled?: boolean
  onDelete?: () => void
  showName?: boolean
}

const previewMotionProps = getMotionProps(
  {
    initial: { y: 4, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -4, opacity: 0 },
  },
  { delay: 0.1 },
)

const usePreview = (file: ValueContainerProps['file']) => {
  const [loaded, setLoaded] = useState(false)
  const [size, setSize] = useState('size' in file ? file.size : null)

  const src = useMemo(() => ('src' in file ? file.src : URL.createObjectURL(file)), [file])

  useEffect(() => {
    if (!src) {
      return
    }
    setLoaded(false)

    getFileSizeFromURL(src).then(setSize)

    try {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setLoaded(true)
      }
    } catch (e) {
      console.error(e)
    }
  }, [src, setLoaded, setSize])

  return { src, size, loaded }
}

export const ValueContainer = ({
  file,
  progress,
  disabled,
  onDelete,
  showName = true,
  imageSize = ImageSize.cover,
}: ValueContainerProps) => {
  const { t } = useTranslation()
  const { src, size, loaded } = usePreview(file)

  return (
    <motion.div
      {...containerMotionProps}
      className={cn(
        'bg:bg-fg-primary relative overflow-hidden rounded-md border-2 p-3 shadow-xs transition-colors',
        progress ? 'border-border-brand ring-2 ring-brand' : 'border-border-primary',
        disabled && 'pointer-events-none bg-fg-disabled',
      )}
    >
      {!!progress && (
        <div
          className="absolute left-0 top-0 z-0 h-full bg-fg-brand-tertiary transition-all ease-linear"
          style={{ width: `${progress}%` }}
        />
      )}
      <div className={cn('relative z-10 flex items-center gap-6', !showName && 'w-full justify-center')}>
        <div className="flex h-[102px] w-36 shrink-0 items-center justify-center overflow-hidden rounded-md border border-border-primary bg-fg-primary text-text-quarterary-hover">
          <AnimatePresence>
            {loaded ? (
              <motion.img
                {...previewMotionProps}
                className={cn('block size-full', imageSize == ImageSize.cover ? 'object-cover' : 'object-contain')}
                src={src}
                alt=""
              />
            ) : (
              <motion.div {...previewMotionProps}>
                <Image01 size={24} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {showName && (
          <>
            <div className="w-full overflow-hidden">
              <div className="break-all text-sm text-text-secondary">
                {'name' in file ? file.name : getFileNameFromURL(file.src)}
              </div>
              <div className="mt-2 text-xs font-medium text-text-quarterary-hover">
                {loaded
                  ? t('intl.Number', {
                      val: size,
                      notation: 'compact',
                      style: 'unit',
                      unit: 'byte',
                      unitDisplay: 'narrow',
                    })
                  : '–'}
              </div>
            </div>

            {!disabled && (
              <div className="mr-3 flex shrink-0">
                {progress ? (
                  <CircularProgress variant="determinate" thickness={4} size={48} value={progress} />
                ) : (
                  <button
                    className="flex size-9 cursor-pointer items-center justify-center rounded-md transition-colors hover:bg-fg-brand-tertiary hover:text-text-brand-primary"
                    type="button"
                    onClick={onDelete}
                  >
                    <Trash size={18} />
                  </button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </motion.div>
  )
}
