import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const WebhookIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M8.85518 10.6714C8.76595 10.5776 8.67754 10.4847 8.59527 10.3864C8.07811 9.76947 7.7641 9.05759 7.63286 8.25978C7.53721 7.67999 7.54574 7.10058 7.66437 6.52793C7.75854 6.07386 7.93945 5.64906 8.16745 5.24527C8.60046 4.47898 9.20475 3.89094 9.97512 3.48265C10.5776 3.1633 11.2226 3.00306 11.9051 3.00006C12.5002 2.99743 13.0778 3.08749 13.632 3.31603C14.3038 3.59335 14.8839 3.99826 15.3518 4.56341C15.8604 5.1781 16.2134 5.87084 16.3657 6.66002C16.4636 7.16625 16.4903 7.68187 16.4047 8.18848C16.3424 8.55774 16.1125 8.79678 15.7188 8.8846C15.2554 8.98779 14.7486 8.58964 14.7527 8.0485C14.7533 7.96119 14.7578 7.8738 14.7623 7.78644C14.7683 7.67105 14.7742 7.55569 14.7712 7.44057C14.7516 6.67466 14.4706 6.01607 13.9345 5.47906C13.5249 5.06889 13.0236 4.82122 12.4538 4.72515C11.9174 4.63471 11.395 4.68913 10.8975 4.91729C10.2695 5.20512 9.80274 5.66369 9.50282 6.29339C9.32635 6.66377 9.22143 7.0533 9.21402 7.46534C9.20512 7.97645 9.32857 8.45379 9.58697 8.8951C9.80941 9.27487 10.1008 9.58747 10.4623 9.83402C10.5905 9.92145 10.7184 10.0119 10.7918 10.1556C10.9576 10.4791 10.9457 10.7954 10.7533 11.1062C10.2357 11.9424 9.7192 12.7794 9.20262 13.6166L8.64118 14.5265C8.40883 14.9033 8.17654 15.28 7.94316 15.6559C7.91387 15.7032 7.90683 15.7388 7.93389 15.7914C8.21268 16.3329 8.1415 16.8616 7.85641 17.3716C7.58467 17.8579 7.14387 18.1049 6.61187 18.16C5.76661 18.2478 4.98994 17.7033 4.81755 16.7881C4.68779 16.0987 5.05963 15.2089 5.85744 14.941C5.98349 14.8986 6.11565 14.8755 6.24802 14.8523C6.31923 14.8399 6.39057 14.8274 6.46099 14.8119C6.4925 14.8052 6.53217 14.7879 6.54811 14.7624C7.35868 13.4503 8.16702 12.137 8.97537 10.8238L8.97712 10.821C8.97813 10.8192 8.97879 10.8171 8.98004 10.8131L8.98231 10.806C8.94027 10.7605 8.89742 10.7158 8.85518 10.6714Z"
        fill="currentColor"
      />
      <path
        d="M2 16.3888C2.00927 15.2697 2.42782 14.2441 3.25343 13.3882C3.67458 12.9517 4.16431 12.6121 4.7167 12.3716C5.33952 12.1002 5.81369 12.5386 5.87226 13.0605C5.91045 13.4009 5.7666 13.6478 5.4893 13.8208C5.40605 13.8728 5.31943 13.9194 5.23278 13.9659C5.14232 14.0146 5.05181 14.0632 4.96509 14.118C4.28035 14.5503 3.86921 15.1812 3.71722 15.9749C3.59376 16.6199 3.69201 17.241 4.00564 17.8242C4.33819 18.4422 4.82829 18.8779 5.47224 19.135C5.94344 19.323 6.43317 19.3631 6.92772 19.2652C7.97577 19.0569 8.68238 18.428 9.07387 17.4316C9.1888 17.1389 9.21994 16.8263 9.22735 16.5126C9.23551 16.1625 9.39492 15.9073 9.69855 15.7426C9.8335 15.6694 9.98068 15.6574 10.1297 15.6574L11.1049 15.6573C12.7309 15.6572 14.3567 15.6571 15.9828 15.6596C16.074 15.6596 16.1266 15.6319 16.177 15.5564C16.4744 15.1102 16.8966 14.8768 17.4219 14.831C17.925 14.7871 18.3636 14.9556 18.7243 15.2986C19.009 15.5692 19.1614 15.9249 19.2055 16.3186C19.2641 16.8443 19.0917 17.3022 18.7421 17.6816C18.503 17.9409 18.1886 18.0914 17.8412 18.145C17.1717 18.2486 16.6182 18.0369 16.2089 17.4834C16.1337 17.3817 16.0651 17.3359 15.9338 17.3363C14.267 17.3419 12.5999 17.3408 10.9327 17.3378C10.8471 17.3378 10.8115 17.3585 10.7907 17.447C10.6369 18.1056 10.364 18.7075 9.95658 19.2475C9.55174 19.7842 9.0494 20.2022 8.45476 20.4979C8.14631 20.6514 7.81859 20.77 7.49012 20.8747C7.32502 20.9275 7.14771 20.9453 6.97233 20.9629C6.9382 20.9663 6.90414 20.9697 6.87026 20.9734C6.31565 21.0331 5.76734 20.994 5.22942 20.8394C4.63106 20.6672 4.0935 20.3771 3.61193 19.9774C2.89383 19.3811 2.4408 18.6137 2.17128 17.7236C2.04708 17.3134 2.0063 16.8931 2 16.3888Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9468 12.295C15.9485 12.2972 15.948 12.2965 15.9468 12.295C15.9444 12.2919 15.9386 12.2837 15.9335 12.2755C15.1197 10.9722 14.306 9.66853 13.4948 8.36336C13.4763 8.33409 13.4744 8.2778 13.4896 8.24665C13.7046 7.80984 13.7061 7.3674 13.5512 6.91145C13.272 6.09112 12.3793 5.69897 11.6834 5.85658C11.3468 5.93314 11.0365 6.07199 10.8081 6.33167C10.4192 6.77373 10.2558 7.2916 10.3963 7.88377C10.4708 8.19711 10.6105 8.47969 10.8411 8.70185C11.16 9.00881 11.5529 9.15442 11.9889 9.18031C12.0556 9.18406 12.0912 9.20658 12.1265 9.26324C13.0763 10.7943 14.0279 12.3243 14.98 13.8535C15.1724 14.1627 15.5145 14.2997 15.8619 14.1924C15.9906 14.1529 16.1136 14.0912 16.2356 14.0302C16.2574 14.0192 16.2791 14.0083 16.3009 13.9976C16.8414 13.7304 17.4082 13.6152 18.0066 13.7049C18.6301 13.7987 19.1614 14.0869 19.587 14.5515C20.0322 15.0378 20.2836 15.6184 20.3299 16.2871C20.3893 17.1446 20.1182 17.888 19.5395 18.5C19.1025 18.9624 18.5452 19.2153 17.908 19.2772C17.3048 19.3357 16.7354 19.2359 16.1985 18.9451C16.0799 18.8805 15.952 18.8265 15.8222 18.792C15.5286 18.7136 15.2721 18.7965 15.0645 19.0186C14.8532 19.2449 14.7905 19.5215 14.8606 19.8172C14.9314 20.1148 15.1383 20.2994 15.4022 20.4432C16.3939 20.9839 17.442 21.1186 18.5323 20.8788C19.6052 20.6428 20.4879 20.0897 21.1359 19.1763C21.8188 18.2133 22.0987 17.1442 21.9693 15.9662C21.884 15.1917 21.6008 14.4959 21.1526 13.864C20.6929 13.2163 20.0938 12.7438 19.3827 12.4128C18.8986 12.1877 18.3836 12.0762 17.8561 12.0241C17.548 11.9937 17.2381 11.9926 16.9341 12.0567C16.7098 12.1039 16.4874 12.1598 16.2587 12.2174C16.1563 12.2431 16.0525 12.2693 15.9468 12.295Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

WebhookIcon.type = 'aghanim-icon';
