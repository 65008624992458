import { Button, Modal, ModalContent, ModalFooter, ModalProps, ModalTitle, Select } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { FieldGroup } from '@/ui'

type OfferType = 'range' | 'sequential'

interface SelectOfferTypeModalModalProps extends Omit<ModalProps, 'onSubmit'> {
  onSubmit: (type: OfferType) => void
}

interface SelectTypeModalForm {
  type: OfferType
}

export const SelectOfferTypeModal = ({ onSubmit, ...rest }: SelectOfferTypeModalModalProps) => {
  const { t } = useTranslation('offers')
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SelectTypeModalForm>({
    values: {
      type: 'sequential',
    },
  })

  const onRefundConfirm = async (data: SelectTypeModalForm) => {
    onSubmit(data.type)
  }

  return (
    <Modal {...rest}>
      <form onSubmit={handleSubmit(onRefundConfirm)}>
        <ModalContent>
          <ModalTitle>{t('select-offer-type')}</ModalTitle>
          <FieldGroup label={t('type')}>
            <Controller
              control={control}
              name="type"
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <Select
                    items={[
                      {
                        children: t('type.sequential'),
                        value: 'sequential',
                      },
                      {
                        children: t('type.range'),
                        value: 'range',
                      },
                    ]}
                    value={field.value}
                    onChange={v => {
                      field.onChange(v)
                    }}
                  />
                </>
              )}
            />
          </FieldGroup>
        </ModalContent>
        <ModalFooter>
          <Button variant="outline" color={'secondary'} size="sm" onClick={rest.onClose} type="button">
            {t('Cancel')}
          </Button>
          <Button size="sm" type="submit" loading={isSubmitting}>
            {t('select')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
