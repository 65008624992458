import { generatePath, useSearchParams } from 'react-router-dom'

/**
 *
 * @param take
 */
export const usePagination = (take = 10) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || '1')

  const query = { offset: (page - 1) * take, limit: take }

  const needShowPagination = (isLoading: boolean, items: Array<unknown>) => {
    if (isLoading) {
      return false
    }
    if (items.length === 0 && page == 1) {
      return false
    }

    if (items.length < take && page == 1) {
      return false
    }
    return true
  }

  const onPageChange = (page: number) => {
    const params = new URLSearchParams(searchParams)
    params.set('page', page.toString())
    setSearchParams(params)
  }

  return { page, query, onPageChange, needShowPagination }
}

export const generatePathWithFilter = (
  path: string,
  params: Record<string, string>,
  filter: Record<string, string>,
) => {
  let res = generatePath(path, params)

  let sanitizedFilter = Object.fromEntries(Object.entries(filter).filter(([_, value]) => value !== undefined))

  if (sanitizedFilter.page === '1') {
    delete sanitizedFilter.page
  }

  if (Object.keys(sanitizedFilter).length) {
    res += `?${new URLSearchParams(sanitizedFilter).toString()}`
  }

  return res
}
