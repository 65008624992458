import './index.css'
import { useEffect, useState } from 'react'
import { GameStoreType } from '@/types'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { getLastCompanyId, getLastGameId, setCompanyGameId, setLastGameId } from '@/Settings'
import { addCompanyToCache } from '@/api/useCompaniesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { ResponseError, dashboardClient, getErrorText } from '@/api'
import { SOCIAL_ICONS_MAP } from '../../icons/Icons'
import { AghanimGameSchemasGame, Company, OnBoardingRequest, Role } from '@/api/dashboard'
import { Button, ButtonIcon, FieldGroup, ImageUploader, Page } from '@/ui'
import { GET_STARTED_PATH, HOME_PATH, usePydenticForm } from '@/libs'
import { XClose } from '@/icons'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import { usePaletteQuery } from '@/layouts/onboarding/api/usePaletteQuery'
import { useAuthToken } from '@/auth/useAuthToken'
import { isStaff } from '@/api/useCurrentUser'
import { ErrorMessage } from '@hookform/error-message'

export const OnBoardingAdvancedPage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const location = useLocation()
  const { state } = location
  const [game, setGame] = useState<AghanimGameSchemasGame | null>(null)
  const [mainCompany, setMainCompany] = useState<Company | null>(null)
  const navigate = useNavigate()
  const { data: palettes = [] } = usePaletteQuery()
  const { user } = useAuthToken()

  useEffect(() => {
    if (user?.email && !isStaff(user?.email)) {
      navigate('/onboarding')
    }
  }, [user])

  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = usePydenticForm<OnBoardingRequest>({
    defaultValues: {
      company_id: state?.company_id,
      url: '',
      setting: {
        news_count: 6,
        items_count: 8,
        bundles_count: 4,
        achievements_count: 9,
        wiki_sections_count: 3,
        wiki_articles_count: 3,
        daily_rewards_count: 7,
        loyalty_levels_count: 5,
      },
    },
  })

  const palette = watch('setting.palette')

  const onSubmit = async (data: OnBoardingRequest) => {
    if (data.setting) {
      if (!data.setting.news_ref_image_url) {
        data.setting.news_ref_image_url = null as unknown as string
      }
      if (!data.setting.item_ref_image_url) {
        data.setting.item_ref_image_url = null as unknown as string
      }
      if (!data.setting.bundle_ref_image_url) {
        data.setting.bundle_ref_image_url = null as unknown as string
      }
    }

    try {
      let {
        data: { company, game },
      } = await dashboardClient.v1.onboardGame(data)

      setGame(game)
      setMainCompany(company)

      setLastGameId(game.id)
      setCompanyGameId(game.company_id)
    } catch (e) {
      if ((e as ResponseError).status == 404) {
        const text = getErrorText(e)
        if (text) {
          setError('url', { message: text })
        }
      } else {
        throw e
      }
    }
  }

  const doneClick = () => {
    if (mainCompany && game) {
      addCompanyToCache(queryClient, {
        ...mainCompany,
        role: Role.Admin,
        games: [game],
      })

      navigate(generatePath(GET_STARTED_PATH, { companyId: mainCompany.id, gameId: game.id }))
    }
  }

  const onClose = () => {
    const lastCompanyId = getLastCompanyId()
    const lastGameId = getLastGameId()

    if (lastCompanyId && lastGameId) {
      navigate(generatePath(HOME_PATH, { companyId: lastCompanyId, gameId: lastGameId }))
    } else {
      window.location.href = '/'
    }
  }

  const getStep0 = () => {
    return (
      <div className="flex size-full h-[720px] flex-col items-center bg-fg-primary">
        <ButtonIcon className="absolute right-5 top-5" variant="secondary-gray" onClick={onClose} size="sm">
          <XClose />
        </ButtonIcon>

        <div className="text-[24px] font-medium">{t('onboarding.add-your-game')}</div>
        <div className="flex h-[640px] w-full justify-center overflow-auto">
          <div className="w-full">
            <FieldGroup label={'Url to game'}>
              <Input
                {...register('url', {
                  required: t('validation.required'),
                })}
                {...formatHookFormErrors(errors, 'url')}
              />
            </FieldGroup>

            <FieldGroup
              label={'Palette'}
              extraRight={
                palette && (
                  <a href={`https://${palette}.aghanim.app`} target="_blank" rel="noreferrer">
                    Open Hub
                  </a>
                )
              }
            >
              <Controller
                name="setting.palette"
                control={control}
                render={({ field }) => (
                  <Select
                    search={{
                      fn: (search, item) =>
                        item.value?.toString().toLowerCase().includes(search.toLowerCase()) || false,
                      placeholder: t('search'),
                    }}
                    value={field.value}
                    onChange={field.onChange}
                    {...formatHookFormErrors(errors, 'setting.palette')}
                    items={palettes.map(p => ({
                      children: p.slug,
                      value: p.slug,
                      extra: () => (
                        <div className="flex gap-1">
                          <div
                            className="size-4 rounded-full border border-border-primary"
                            style={{ backgroundColor: p.base_bg_color }}
                          />
                          <div
                            className="size-4 rounded-full border border-border-primary"
                            style={{ backgroundColor: p.accent_color }}
                          />
                          <div
                            className="size-4 rounded-full border border-border-primary"
                            style={{ backgroundColor: p.text_color }}
                          />
                        </div>
                      ),
                    }))}
                  />
                )}
              />
            </FieldGroup>

            <FieldGroup label={'Style keywords'}>
              <Input {...register('setting.style_keywords')} {...formatHookFormErrors(errors, 'style_keywords')} />
            </FieldGroup>

            <div className="grid grid-cols-3 gap-x-2">
              <FieldGroup label={'News count'}>
                <Input
                  type="number"
                  {...register('setting.news_count')}
                  {...formatHookFormErrors(errors, 'news_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Items count'}>
                <Input
                  type="number"
                  {...register('setting.items_count')}
                  {...formatHookFormErrors(errors, 'setting.items_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Bundles count'}>
                <Input
                  type="number"
                  {...register('setting.bundles_count')}
                  {...formatHookFormErrors(errors, 'setting.bundles_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Achievements count'}>
                <Input
                  type="number"
                  {...register('setting.achievements_count')}
                  {...formatHookFormErrors(errors, 'setting.achievements_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Wiki sections count'}>
                <Input
                  type="number"
                  {...register('setting.wiki_sections_count')}
                  {...formatHookFormErrors(errors, 'setting.wiki_sections_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Wiki articles count'}>
                <Input
                  type="number"
                  {...register('setting.wiki_articles_count')}
                  {...formatHookFormErrors(errors, 'setting.wiki_articles_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Daily rewards count'}>
                <Input
                  type="number"
                  {...register('setting.daily_rewards_count')}
                  {...formatHookFormErrors(errors, 'setting.daily_rewards_count')}
                />
              </FieldGroup>

              <FieldGroup label={'Loyalty rewards count'}>
                <Input
                  type="number"
                  {...register('setting.loyalty_levels_count')}
                  {...formatHookFormErrors(errors, 'setting.loyalty_levels_count')}
                />
              </FieldGroup>
            </div>

            <FieldGroup label={'News Ref Image Url'}>
              <Controller
                control={control}
                name="setting.news_ref_image_url"
                render={({ field }) => (
                  <ImageUploader
                    accept={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': [],
                      'image/webp': [],
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                name="setting.news_ref_image_url"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            <FieldGroup label={'Item Ref Image Url'}>
              <Controller
                control={control}
                name="setting.item_ref_image_url"
                render={({ field }) => (
                  <ImageUploader
                    accept={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': [],
                      'image/webp': [],
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                name="setting.item_ref_image_url"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>

            <FieldGroup label={'Bundle Ref Image Url'}>
              <Controller
                control={control}
                name="setting.bundle_ref_image_url"
                render={({ field }) => (
                  <ImageUploader
                    accept={{
                      'image/jpeg': ['.jpeg', '.jpg'],
                      'image/png': [],
                      'image/webp': [],
                    }}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                name="setting.bundle_ref_image_url"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FieldGroup>
          </div>
        </div>

        <Button
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          className="mt-auto w-1/2"
          variant="primary"
          size="lg"
        >
          {t('AddGame')}
        </Button>
      </div>
    )
  }

  const getStep2 = () => {
    return (
      <div className="flex size-full flex-col items-center justify-center">
        <div className="text-center">
          <img
            src={game?.logo_url || `${import.meta.env.VITE_STATIC_IMAGES_URL}/images/placeholder.png`}
            data-categories="essential"
            alt="game logo"
            style={{
              objectFit: 'cover',
              display: 'inline-block',
              width: '124px',
              height: '124px',
              borderRadius: '24px',
              marginBottom: '24px',
            }}
          />
          <div className="text-[24px] font-medium">{game?.name || 'Game'}</div>
          <span className="mt-2 text-caption-sm text-text-tertiary">{mainCompany?.name || 'Company'}</span>
        </div>

        <Button onClick={doneClick} className="mt-10 w-1/2" variant="primary" size="lg">
          {t('onboarding.done-button')}
        </Button>
      </div>
    )
  }

  const getBackground = () => {
    return (
      <div className="pointer-events-none absolute left-0 top-0 flex size-full items-center justify-center overflow-hidden">
        <svg width="1074" height="1047" viewBox="0 0 1074 1047" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="537.342" cy="536.658" r="334.712" stroke="#0097E4" strokeWidth="1.39755" />
          <circle
            cx="537.342"
            cy="536.658"
            r="401.794"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.6 }}
          />
          <circle
            cx="537.342"
            cy="536.658"
            r="468.877"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.4 }}
          />
          <circle
            cx="537.342"
            cy="536.658"
            r="535.959"
            stroke="#0097E4"
            strokeWidth="1.39755"
            style={{ opacity: 0.2 }}
          />
        </svg>
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.app_store]}
          alt={'app store'}
          className="onboarding-image--app-store"
        />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.google_play]}
          alt={'google_play'}
          className="onboarding-image--youtube"
        />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.app_store]}
          alt={'app_store'}
          className="onboarding-image--google-play"
        />
        <img src={SOCIAL_ICONS_MAP[GameStoreType.app_store]} alt={'app_store'} className="onboarding-image--discord" />
        <img
          src={SOCIAL_ICONS_MAP[GameStoreType.google_play]}
          alt={'google_play'}
          className="onboarding-image--amazon"
        />
      </div>
    )
  }

  return (
    <Page className="items-center justify-center">
      {getBackground()}

      <div className="relative w-full max-w-[690px] rounded-[36px] border border-border-primary bg-fg-primary px-10 pb-5 pt-10 shadow-2xl">
        {game ? getStep2() : getStep0()}
      </div>
    </Page>
  )
}
