import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const ListViewSolid: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M4 7C5.10457 7 6 6.10457 6 5C6 3.89543 5.10457 3 4 3C2.89543 3 2 3.89543 2 5C2 6.10457 2.89543 7 4 7Z"
        fill="currentColor"
      />
      <path
        d="M9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6L21 6C21.5523 6 22 5.55229 22 5C22 4.44772 21.5523 4 21 4L9 4Z"
        fill="currentColor"
      />
      <path
        d="M8 12C8 11.4477 8.44772 11 9 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L9 13C8.44772 13 8 12.5523 8 12Z"
        fill="currentColor"
      />
      <path
        d="M9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20L21 20C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18L9 18Z"
        fill="currentColor"
      />
      <path
        d="M6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C5.10457 10 6 10.8954 6 12Z"
        fill="currentColor"
      />
      <path
        d="M4 21C5.10457 21 6 20.1046 6 19C6 17.8954 5.10457 17 4 17C2.89543 17 2 17.8954 2 19C2 20.1046 2.89543 21 4 21Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

ListViewSolid.type = 'aghanim-icon';
