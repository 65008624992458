import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Pause: AghanimIcon = forwardRef(function (props, ref) {
  return (
    <IconBase {...props} viewBox="0 0 24 24" ref={ref}>
      <path
        d="M6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H9C9.55229 19 10 18.5523 10 18V6C10 5.44772 9.55229 5 9 5H6Z"
        fill="currentColor"
      />
      <path
        d="M15 5C14.4477 5 14 5.44772 14 6V18C14 18.5523 14.4477 19 15 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H15Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Pause.type = 'aghanim-icon';
