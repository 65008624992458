import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { GenerationProgressState } from '@/api/dashboard'
import { Button } from '@/ui'
import { SKU_ITEMS_PATH, getPercentFrom, useGenerateCompanyPath } from '@/libs'
import { getGenerationProgressQuery } from '../api'
import { GetStartedStepState } from '../types'
import { useGetStarted } from './useGetStarted'

const getSkuProgress = (progressState: GenerationProgressState | undefined) => {
  return getPercentFrom(progressState?.sku_items?.current || 0, progressState?.sku_items?.total || 0)
}

/**
 *
 */
export const useSkuStep = (): GetStartedStepState => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: progressState } = useQuery(getGenerationProgressQuery(companyId, gameId))
  const { check, complete } = useGetStarted()

  const id = 'sku'

  const status = check('setup_item_store') && getSkuProgress(progressState) === 100 ? 'completed' : 'idle'

  const onComplete = () => {
    complete('setup_item_store')
  }

  return {
    id,
    loading: false,
    status,
    available_on: ['desktop'],
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    cta: (
      <Link to={generatePath(SKU_ITEMS_PATH)}>
        <Button
          className="min-w-40"
          variant={status === 'completed' ? 'outline' : 'secondary-brand'}
          size="lg"
          onClick={onComplete}
        >
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
