import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { useQuery } from '@tanstack/react-query'
import { ErrorMessage } from '@hookform/error-message'
import dayjs from 'dayjs'
import {
  Button,
  Checkbox,
  FormErrorMessage,
  Input,
  InputDate,
  Select,
  Textarea,
  formatHookFormErrors,
} from '@dashboard/ui'
import { PageLayoutType, PageRead } from '@/api/dashboard'
import {
  ButtonIcon,
  ConfirmDialog,
  DrawerContent,
  DrawerTopbar,
  FieldGroup,
  ImageUploader,
  ToastSeverity,
  useToast,
} from '@/ui'
import { PagesSlugCaption } from './PagesSlugCaption'
import { getRecommendedSizeByRatio, slugifyString, useFeatureFlags } from '@/libs'
import { XClose } from '@/icons'
import { S3Bucket } from '@/types'
import { usePageUpdateMutate } from '../api/usePageUpdateMutate'
import { usePageCreateMutate } from '../api/usePageCreateMutate'
import { LexicalController } from '@/components/lexical'
import { useParentLookup } from '@/layouts/pages/hooks'
import { getErrorText } from '@/api'
import { MAX_SLUG_LENGTH, MIN_SLUG_LENGTH } from '@/Settings'
import { hubWebsitesQuery } from '@/layouts/hub'

interface PageFormProps {
  websiteUrl?: string
  item?: PageRead
  parents: PageRead[]
  onClose: () => void
  createType?: PageLayoutType
}

export const PagesForm = ({ websiteUrl, item, parents, onClose }: PageFormProps) => {
  const { t } = useTranslation()
  const [imageLoading, setImageLoading] = useState<boolean>(false)

  const { companyId, gameId, parent, child } = useParams() as {
    companyId: string
    gameId: string
    parent?: string
    child?: string
  }
  const { parent: currentParent } = useParentLookup(parents, item)

  const { data: website } = useQuery({
    ...hubWebsitesQuery(companyId, gameId),
    select: websites => websites.at(0),
  })

  const { mutateAsync: updateMutateAsync } = usePageUpdateMutate(companyId, gameId)
  const { mutateAsync: createMutateAsync } = usePageCreateMutate(companyId, gameId)

  const showToast = useToast()
  const [confirmPublish, setConfirmPublish] = useState<boolean | null>(null)

  const {
    setValue,
    formState: { errors, dirtyFields, isSubmitting },
    setError,
    control,
    register,
    watch,
    handleSubmit,
    reset,
  } = useForm<PageRead>({
    defaultValues: {
      title: '',
      date: dayjs().format('YYYY-MM-DD'),
      type: PageLayoutType.Article,
      slug: '',
      html: '',
      description: '',
      image_url: '',
      text: '',
      parent_id: currentParent?.id,
      ...item,
    },
  })

  const { featureFlags } = useFeatureFlags()

  const [id, title, slug, published_at] = watch(['id', 'title', 'slug', 'published_at'])

  const recommendedSizeByRatio = getRecommendedSizeByRatio(website?.config?.news_settings?.image_ratio || 1.77)

  const publishClick = async () => {
    let p = published_at ? undefined : new Date().getTime() / 1000
    setValue('published_at', p, { shouldDirty: false })
    setConfirmPublish(null)

    try {
      await updateMutateAsync({
        pageId: item!.id,
        update: {
          published_at: p ? p : (null as unknown as number),
        },
      })
      p
        ? showToast({
            message: t('edit-news-dialog.published'),
            severity: ToastSeverity.success,
          })
        : showToast({
            message: t('edit-news-dialog.unpublished'),
            severity: ToastSeverity.info,
          })
    } catch (e) {
      showToast({ message: getErrorText(e), severity: ToastSeverity.error })
    }
  }

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<PageRead, 'title'>) => {
    if (!item?.id) {
      setValue('slug', slugifyString(e.target.value))
    }
    field.onChange(e.target.value)
  }

  const onFormSubmit = async (data: PageRead) => {
    try {
      if (item?.id) {
        await updateMutateAsync({
          pageId: data.id,
          update: data,
        })
      } else {
        await createMutateAsync({
          create: data,
        })
      }

      reset(data)
      onClose()
    } catch (responseError) {
      // @ts-ignore
      if (responseError.error.detail === `This 'Page' resource already exists`) {
        setError('slug', { message: t('validation.page-slug-already-exists') })
      }
    }
  }

  return (
    <form className="flex h-full flex-col overflow-y-auto" onSubmit={handleSubmit(onFormSubmit)}>
      {confirmPublish !== null && (
        <ConfirmDialog
          color={published_at ? 'error' : 'primary'}
          message={t(published_at ? 'pages.confirm.unpublish' : 'pages.confirm.publish')}
          confirmButtonText={t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
          subMessage={t(published_at ? 'pages.confirm.unpublish.message' : 'pages.confirm.publish.message', {
            name: title,
          })}
          onCancel={() => setConfirmPublish(null)}
          onConfirm={publishClick}
        />
      )}

      <DrawerTopbar>
        <ButtonIcon variant="secondary-gray" size="sm" type="button" onClick={onClose}>
          <XClose />
        </ButtonIcon>
        <h2 className="mr-auto text-title-t5">{t(item?.id ? 'pages.edit.header' : 'pages.edit.header.create')}</h2>
        <div className="flex gap-2">
          {item?.id && (
            <Button
              variant="outline"
              type="button"
              onClick={() => (published_at ? setConfirmPublish(false) : publishClick())}
            >
              {t(published_at ? 'edit-news-dialog.unpublish' : 'edit-news-dialog.publish')}
            </Button>
          )}
          <Button type="submit" loading={isSubmitting} disabled={Object.keys(dirtyFields).length === 0 || imageLoading}>
            {t(item?.id ? 'Save2' : 'Create')}
          </Button>
        </div>
      </DrawerTopbar>

      <DrawerContent className="flex h-full flex-col overflow-y-auto">
        <FieldGroup label={t('pages.edit.title')}>
          <Controller
            control={control}
            name="title"
            rules={{ required: t('validation.required') }}
            render={({ field }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'title')}
                autoFocus={true}
                onChange={e => onChangeTitle(e, field)}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup label={t('pages.edit.description')}>
          <Textarea {...register('description')} {...formatHookFormErrors(errors, 'description')} rows={6} />
        </FieldGroup>

        <FieldGroup
          tooltip={t('sku.sku-desc')}
          label={t('pages.edit.slug')}
          caption={<PagesSlugCaption base={websiteUrl} slug={slug} parent={parent} child={child} />}
        >
          <Controller
            control={control}
            name="slug"
            rules={{
              required: t('validation.required'),
              maxLength: {
                message: t('validation.max', { value: MAX_SLUG_LENGTH, field: t('pages.edit.slug') }),
                value: MAX_SLUG_LENGTH,
              },
              minLength: {
                message: t('validation.min', { value: MIN_SLUG_LENGTH, field: t('pages.edit.slug') }),
                value: MIN_SLUG_LENGTH,
              },
            }}
            render={({ field }) => (
              <Input
                {...field}
                {...formatHookFormErrors(errors, 'slug')}
                onChange={e => {
                  field.onChange(slugifyString(e.target.value))
                }}
              />
            )}
          />
        </FieldGroup>

        <FieldGroup>
          <Controller
            control={control}
            name="image_url"
            render={({ field }) => (
              <ImageUploader
                {...field}
                onLoadingChanged={setImageLoading}
                accept={{
                  'image/jpeg': ['.jpeg', '.jpg'],
                  'image/png': [],
                  'image/webp': [],
                }}
                recommendedSize={recommendedSizeByRatio}
                bucket={S3Bucket.pages}
              />
            )}
          />
          <ErrorMessage
            name="image_url"
            errors={errors}
            render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
          />
        </FieldGroup>

        <div className="grid grid-cols-3 gap-5">
          <FieldGroup label={t('pages.edit.type')}>
            <Controller
              name="type"
              control={control}
              rules={{ required: t('validation.required') }}
              disabled={!!id}
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'type')}
                  items={[
                    {
                      children: t(`pages.type.${PageLayoutType.Article}`),
                      caption: t(`pages.type.${PageLayoutType.Article}.desc`),
                      value: PageLayoutType.Article,
                    },
                    {
                      children: t(`pages.type.${PageLayoutType.Content}`),
                      caption: t(`pages.type.${PageLayoutType.Content}.desc`),
                      value: PageLayoutType.Content,
                    },
                  ]}
                />
              )}
            />
          </FieldGroup>

          <FieldGroup label={t('pages.edit.parent')}>
            <Controller
              control={control}
              name="parent_id"
              render={({ field }) => (
                <Select
                  {...field}
                  {...formatHookFormErrors(errors, 'parent_id')}
                  items={[
                    { value: null, children: t('pages.edit.uncategorized') },
                    ...parents.map(p => ({ value: p.id, children: p.title })),
                  ]}
                />
              )}
            />
          </FieldGroup>
          <FieldGroup label={t('pages.edit.date')}>
            <Controller
              control={control}
              name="date"
              rules={{ required: t('validation.required') }}
              render={({ field }) => (
                <InputDate
                  {...field}
                  {...formatHookFormErrors(errors, 'date')}
                  value={field.value ? new Date(field.value) : null}
                  onChange={value => {
                    field.onChange(value ? dayjs(value).format('YYYY-MM-DD') : null)
                  }}
                />
              )}
            />
          </FieldGroup>
        </div>
        {featureFlags.article_as_promo_store_enabled && (
          <Checkbox className="mb-4" {...register('render_options.is_promo_store')}>
            {t('pages.edit.is-promo-store')}
          </Checkbox>
        )}
        <FieldGroup label={t('pages.edit.content')}>
          <ErrorMessage
            name="html"
            errors={errors}
            render={({ message }) => (
              <div className="mb-1">
                <FormErrorMessage>{message}</FormErrorMessage>
              </div>
            )}
          />
          <LexicalController
            control={control}
            name="html"
            rules={{ required: t('validation.required') }}
            stickyTopOffset={-20}
          />
        </FieldGroup>
      </DrawerContent>
    </form>
  )
}
