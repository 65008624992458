import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CenteredProgress from '../components/CenteredProgress'
import { useAuthToken } from '@/auth/useAuthToken'
import { dashboardClient } from '@/api'

export const FindGame = () => {
  const navigate = useNavigate()
  const { isLoading, authDone } = useAuthToken()

  const loadSite = async () => {
    try {
      let gameId = window.location.pathname.split('/game/')[1]

      const { data } = await dashboardClient.v1.getAllCompanyGames({
        search_string: gameId,
        limit: 2,
      })

      if (!data.data?.length) {
        navigate('/404')
        return
      }

      const game = data.data[0].games?.find(g => g.id === gameId)

      if (!game) {
        navigate('/404')
        return
      }

      navigate(`/company/${game.company_id}/${game.id}`, { state: { skipGoBack: true } })
    } catch (e) {
      console.log(e)
      navigate('/404')
    }
  }

  useEffect(() => {
    if (authDone) {
      loadSite()
    }
  }, [authDone])

  if (isLoading) {
    return (
      <div>
        {' '}
        <CenteredProgress />
      </div>
    )
  }

  return <></>
}
