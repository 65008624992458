import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button, useModal } from '@dashboard/ui'
import { Page, PageHeader } from '@/ui'
import { LOCALIZATION_GLOSSARY_LOCALE_PATH, useGenerateCompanyPath } from '@/libs'
import {
  L10nLayout,
  L10nSidebar,
  L10nZeroState,
  localesQueryOptions,
  useNavigateToGameLocale,
} from '@/layouts/localization'
import { L10nGlossaryItemModalCreate, L10nGlossarySkeleton } from '../components'

export const L10nGlossaryOutlet = () => {
  const { t } = useTranslation()
  const { companyId, gameId, localeId } = useGenerateCompanyPath<{ localeId: string }>()
  const { data: locales = [], isLoading: isLoadingLocales } = useQuery(localesQueryOptions(companyId, gameId))

  const openCreateModal = useModal(props => <L10nGlossaryItemModalCreate localeId={localeId} {...props} />)

  useNavigateToGameLocale(LOCALIZATION_GLOSSARY_LOCALE_PATH)

  if (isLoadingLocales) {
    return (
      <Page>
        <L10nGlossarySkeleton />
      </Page>
    )
  }

  if (locales.length === 0) {
    return (
      <Page>
        <PageHeader>{t('l10n.glossary.title')}</PageHeader>
        <L10nZeroState type="glossary" />
      </Page>
    )
  }

  return (
    <Page>
      <PageHeader extra={<Button onClick={openCreateModal}>{t('l10n.glossary.add-button')}</Button>}>
        {t('l10n.glossary.title')}
      </PageHeader>
      <L10nLayout sidebar={<L10nSidebar type="glossary" />}>
        <Outlet />
      </L10nLayout>
    </Page>
  )
}
