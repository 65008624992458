import { Box, Typography } from '@mui/material'
import i18next from 'i18next'
import { VisibilityOff } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import { APIKeyRedacted, APIKeyRevealed, AttrType, Campaign, CampaignEventType } from '../../../api/dashboard'
import { dashboardClient } from '../../../api'
import { useParams } from 'react-router-dom'
import { SxProps } from '@mui/system'
import { CopyIcon } from '../../../icons/Icons'
import { KeyValue } from '../../../types'
import { isCustomEvent } from '../Settings'
import dayjs from 'dayjs'
import { Button } from '@/ui'

const EVENT_TO_CAMPAIGN_MAPPING = {
  [CampaignEventType.GameLogin]: 'game_login',
  [CampaignEventType.RegisteredInGame]: 'game_register',
  [CampaignEventType.PurchasedInGame]: 'purchase',
  [CampaignEventType.LevelUp]: 'level_up',
} as KeyValue

const getEventDataExample = (campaign: Campaign) => {
  let data = {
    player_id: 'player_id',
  } as KeyValue

  if (isCustomEvent(campaign.type)) {
    campaign.attributes?.custom_attributes?.forEach(it => {
      switch (it.type) {
        case AttrType.Number:
          data[it.name] = 0
          break
        case AttrType.String:
          data[it.name] = 'test'
          break
        case AttrType.Boolean:
          data[it.name] = true
          break
        case AttrType.Date:
          data[it.name] = dayjs().format('DD-MM-YYYY') + '.date'
          break
      }
    })

    return data
  }

  switch (campaign.type) {
    case CampaignEventType.LevelUp:
      data.level = 80
      break
    case CampaignEventType.PurchasedInGame:
      data.amount = 9900
      data.sku = 'sku_item_id'
      break
    case CampaignEventType.RegisteredInGame:
      data.email = 'player@game.com'
      data.name = 'player'
      data.avatar_url = 'https://avatar.com/avatar.png'
      break
  }
  return data
}

export default function CampaignIntegrationBlock(props: { campaign: Campaign; sx?: SxProps<Theme> }) {
  const theme = useTheme()
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const [apiAPIKeyRedacted, setAPIKeyRedacted] = useState<APIKeyRedacted | null>(null)
  const [secretVis, setSecretVis] = useState(false)
  const [apiKeyRevealed, setAPIKeyRevealed] = useState<APIKeyRevealed | null>(null)

  const loadApiKey = async () => {
    const { data } = await dashboardClient.v1.getApiKeys(companyId, gameId)
    if (data?.length) {
      setAPIKeyRedacted(data[0])
    }
  }

  useEffect(() => {
    loadApiKey()
  }, [gameId])

  const reveal = async () => {
    if (!apiAPIKeyRedacted) {
      return
    }

    let { data } = await dashboardClient.v1.revealApiKey(companyId, gameId, apiAPIKeyRedacted.id)
    setAPIKeyRevealed(data)
  }

  useEffect(() => {
    if (secretVis && !apiKeyRevealed) {
      reveal()
    }
  }, [secretVis])

  const getCurlCode = () => {
    let apiBody = {
      event_time: 1711366555685,
      event_type: EVENT_TO_CAMPAIGN_MAPPING[props.campaign.type] || props.campaign.type,
      event_data: getEventDataExample(props.campaign),
    }

    let secret = apiKeyRevealed?.secret || '[secret]'

    return `curl -u ${secret}: -X POST ${
      import.meta.env.VITE_HUB_API_URL
    }/s2s/v1/events -H "Content-Type: application/json" -d '${JSON.stringify(apiBody)}'`
  }

  return (
    <Box sx={props.sx}>
      <Box
        display="flex"
        pb={theme.spacing(2)}
        sx={{
          border: `1px solid ${theme.palette.grey.borderSecondary}`,
          borderRadius: theme.spacing(1.5),
        }}
        gap={theme.spacing(1.5)}
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={theme.spacing(0, 2)}
          sx={{
            borderBottom: `1px solid ${theme.palette.grey.borderSecondary}`,
          }}
          height={theme.spacing(8)}
        >
          <div className="text-text-secondary">{i18next.t('campaign.rest-api-desc')}</div>
          <Button
            onClick={() => navigator.clipboard.writeText(getCurlCode())}
            style={{
              gap: theme.spacing(1),
            }}
          >
            <CopyIcon />
            {i18next.t('copy-to-clipboard')}
          </Button>
        </Box>

        <Typography
          variant="subtitle2"
          fontWeight="600"
          component="code"
          sx={{ wordBreak: 'break-word', margin: theme.spacing(0, 2) }}
        >
          {getCurlCode()}
        </Typography>
      </Box>

      {!secretVis && (
        <Box mt={theme.spacing(3)}>
          <Button style={{ gap: theme.spacing(1) }} onClick={() => setSecretVis(true)}>
            <VisibilityOff />
            {i18next.t('campaign.show-secret')}
          </Button>
        </Box>
      )}
    </Box>
  )
}
