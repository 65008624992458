import { IconBase } from '@dashboard/ui'
import { AghanimIcon } from '@/icons'
import { forwardRef } from 'react'

export const DollarRounded: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 16 16" ref={ref}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.70174 11.2877C7.70174 11.4641 7.82381 11.5795 7.99336 11.5795C8.15613 11.5795 8.28498 11.4641 8.28498 11.2877V10.8602C9.33619 10.772 10.1161 10.1681 10.1161 9.19102C10.1161 8.3157 9.55999 7.8068 8.44097 7.59645L8.28498 7.56252V6.02223C8.67156 6.09687 8.94962 6.32758 9.07169 6.69399C9.16664 6.91112 9.31584 7.03326 9.55321 7.03326C9.82449 7.03326 10.0076 6.85684 10.0076 6.59899C10.0076 6.54301 9.99616 6.48703 9.98471 6.43105C9.9809 6.41239 9.97708 6.39373 9.97369 6.37508C9.79736 5.71689 9.15308 5.2487 8.28498 5.1537V4.73301C8.28498 4.55659 8.15613 4.43445 7.99336 4.43445C7.82381 4.43445 7.70174 4.55659 7.70174 4.73301V5.1537C6.67766 5.25548 5.95199 5.87296 5.95199 6.77542C5.95199 7.61681 6.51489 8.10536 7.57966 8.34963L7.70174 8.37677V10.0053C7.20665 9.93063 6.91503 9.666 6.8133 9.30637C6.71835 9.06888 6.56915 8.95353 6.33856 8.95353C6.05372 8.95353 5.87061 9.13674 5.87061 9.40137C5.87061 9.47023 5.88792 9.5391 5.90658 9.61329C5.90815 9.61951 5.90972 9.62577 5.9113 9.63207C6.10119 10.297 6.77261 10.7788 7.70174 10.8602V11.2877ZM7.70174 6.02223V7.42003L7.66104 7.40646C7.16596 7.26397 6.93537 7.04683 6.93537 6.71435C6.93537 6.36829 7.227 6.09687 7.70174 6.02223ZM8.28498 10.0053V8.51927L8.40028 8.54641C8.93605 8.69569 9.13273 8.91282 9.13273 9.26566C9.13273 9.65922 8.84789 9.93742 8.28498 10.0053Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00016 1.3335C4.31826 1.3335 1.3335 4.31826 1.3335 8.00016C1.3335 11.6821 4.31826 14.6668 8.00016 14.6668C11.6821 14.6668 14.6668 11.6821 14.6668 8.00016C14.6668 4.31826 11.6821 1.3335 8.00016 1.3335ZM2.66683 8.00016C2.66683 5.05464 5.05464 2.66683 8.00016 2.66683C10.9457 2.66683 13.3335 5.05464 13.3335 8.00016C13.3335 10.9457 10.9457 13.3335 8.00016 13.3335C5.05464 13.3335 2.66683 10.9457 2.66683 8.00016Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
