import { Outlet, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { ItemType } from '@/api/dashboard'
import { saveItemProperties } from '@/layouts/game-items/api/saveItemProperties'
import { useBanner } from '@/libs/hooks/useBanner'
import { useToast } from '../../../components/ui/Toast/useToast'
import { FullscreenLayout } from '../../../components/layouts'
import { ToastSeverity } from '../../../components/ui/Toast/types'
import { skuItemQuery, useItemUpdateMutate } from '../api'
import { SkuForm } from '../widgets'
import { SkuFormData } from '../types'
import { useTranslation } from 'react-i18next'
import { cn } from '@dashboard/ui'

export const SkuEditPage = (props: { type: ItemType }) => {
  const { t } = useTranslation()
  const { companyId, gameId, itemId } = useParams() as { companyId: string; gameId: string; itemId: string }
  const { data: item, isLoading } = useQuery(skuItemQuery(companyId, gameId, itemId))
  const { mutateAsync: updateMutateAsync } = useItemUpdateMutate(companyId, gameId)
  const showToast = useToast()
  const { getStickyHeight } = useBanner()

  const onSubmit = async (data: SkuFormData) => {
    await saveItemProperties(companyId, gameId, item!, data)

    delete data.price_point
    delete data.bonus_percent
    delete data.discount_percent
    await updateMutateAsync({ data })
    showToast({ message: t('saved'), severity: ToastSeverity.success })
    return data
  }

  return (
    <FullscreenLayout className="bg-linear-bg-gradient" isLoading={isLoading}>
      <Outlet />
      <div className={cn(getStickyHeight())}>
        {item && <SkuForm item={item} onSubmit={onSubmit} type={props.type} />}
      </div>
    </FullscreenLayout>
  )
}
