import { Outlet } from 'react-router-dom'
import { Page } from '@/ui'

export const PriceTemplatesOutlet = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  )
}
