import { useTranslation } from 'react-i18next'
import { cn } from '@dashboard/ui'

interface LangKnobProps {
  locale: string
  target?: boolean
}

const formatLocaleForChinese = (locale: string) => {
  return new Intl.DisplayNames(['zh-Hant'], {
    type: 'language',
    style: 'short',
    languageDisplay: 'standard',
  }).of(locale.toUpperCase())
}

export const LocaleKnob = ({ locale, target }: LangKnobProps) => {
  const { i18n } = useTranslation()
  return (
    <div
      className={cn(
        'flex h-[18px] min-w-6 shrink-0 items-center justify-center rounded bg-text-quarterary px-1 text-center text-[12px] font-semibold uppercase text-text-inverse',
        target && 'bg-text-quarterary-hover',
      )}
    >
      {i18n.language === 'zh' ? formatLocaleForChinese(locale) : locale}
    </div>
  )
}
