import { useSequentialOfferQuery } from '@/layouts/offers/sequential-api/useSequentialOfferQuery'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { BackButton, PageHeader, ToastSeverity, useToast } from '@/ui'
import { Button, FormErrorMessage } from '@dashboard/ui'
import { OFFERS_PATH, usePydenticForm } from '@/libs'
import { ItemRead, Pack, SequentialOfferRead } from '@/api/dashboard'
import { URL_NEW } from '@/types'
import { useTranslation } from 'react-i18next'
import { PlusContained } from '@/icons'
import { AddButton } from '@/layouts/components/AddButton'
import { DrawerSidebar } from '@/components/ui/DrawerSidebar'
import { useState } from 'react'
import { PackForm } from '@/layouts/offers/sequential-widgets/PackForm'
import { PackItem } from '@/layouts/offers/sequential-widgets/PackItem'
import { useSequentialOfferCreateMutate, useSequentialOfferUpdateMutate } from 'src/layouts/offers/sequential-api'
import { ErrorMessage } from '@hookform/error-message'
import { useGameItem } from '@/api/useGameItems'
import { GeneralSettings } from '@/layouts/offers/sequential-widgets/GeneralSettings'
import { Summary } from '@/layouts/offers/sequential-widgets/Summary'

export const SequentialOfferFormPage = () => {
  const { companyId, gameId, offerId } = useParams() as {
    companyId: string
    gameId: string
    offerId: string
  }
  const showToast = useToast()
  const { mutateAsync: createMutateAsync } = useSequentialOfferCreateMutate(companyId, gameId)
  const { mutateAsync: updateMutateAsync } = useSequentialOfferUpdateMutate(companyId, gameId)

  const { t } = useTranslation()
  const { t: tOffer } = useTranslation('offers')
  const navigate = useNavigate()
  const { data: offer } = useSequentialOfferQuery(companyId, gameId, offerId, offerId != URL_NEW)
  const [settingsOpen, setSettingsOpen] = useState(offerId == URL_NEW)
  const [currentPack, setCurrentPack] = useState<null | Pack>(null)

  const {
    formState: { isDirty, isSubmitting, errors },
    reset,
    getValues,
    watch,
    handleSubmit,
    setValue,
  } = usePydenticForm<SequentialOfferRead>({
    values: offer
      ? {
          ...offer,
        }
      : undefined,
  })

  const packs = watch('settings.packs') || []
  const item_id = watch('item_id')

  const { data: item } = useGameItem(companyId, gameId, item_id)

  const onSubmit = async (data: SequentialOfferRead) => {
    if (!data.name || !data.description || !data.item_id) {
      setSettingsOpen(true)
      return
    }

    if (packs.length === 0) {
      showToast({ message: tOffer('packs.required'), severity: ToastSeverity.error })
      return
    }

    if (!data.settings.background_image_url) {
      data.settings.background_image_url = null as unknown as string
    }

    if (data.id) {
      await updateMutateAsync({
        id: data.id,
        update: data,
      })
    } else {
      await createMutateAsync({
        create: data,
      })
    }

    reset(data)
    showToast({ message: t('saved'), severity: ToastSeverity.success })
  }

  return (
    <div className="flex size-full flex-col">
      <DrawerSidebar open={settingsOpen || !!currentPack} width="720px" position="right">
        {settingsOpen && (
          <GeneralSettings
            offer={getValues()}
            onApply={data => {
              setValue('name', data.name)
              setValue('description', data.description)
              setValue('start_at', data.start_at)
              setValue('end_at', data.end_at)
              setValue('item_id', data.item_id, { shouldDirty: true })
              setValue('settings', data.settings, { shouldDirty: true })
            }}
            onClose={() => {
              setSettingsOpen(false)
            }}
          />
        )}
        {currentPack && (
          <PackForm
            baseItem={item as ItemRead}
            title={currentPack.id ? tOffer('Pack') + ' ' + (packs.indexOf(currentPack) + 1) : tOffer('add-pack')}
            pack={currentPack}
            onSubmit={data => {
              const existingPack = packs.find(p => p.id === data.id)
              if (existingPack) {
                setValue(
                  'settings.packs',
                  packs.map(p => (p.id === data.id ? data : p)),
                  { shouldDirty: true },
                )
              } else {
                setValue('settings.packs', [...packs, data], { shouldDirty: true })
              }
            }}
            onClose={() => {
              setCurrentPack(null)
            }}
          />
        )}
      </DrawerSidebar>
      <PageHeader
        sticky
        extra={
          <>
            <Button variant="outline" size="sm" color="primary" onClick={() => setSettingsOpen(true)}>
              {tOffer('settings')}
            </Button>
            {isDirty && offer?.id && (
              <Button variant="outline" color="secondary" size="sm" onClick={() => reset()}>
                {t('Discard')}
              </Button>
            )}
            <Button size="sm" disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
              {t('Save')}
            </Button>
          </>
        }
      >
        <BackButton
          text={offer?.name || tOffer('create-sequential')}
          onClick={() => navigate(generatePath(OFFERS_PATH, { companyId: companyId, gameId: gameId }))}
        />
      </PageHeader>

      <div className="flex gap-3">
        <div className="flex grow flex-col gap-3">
          {packs.map((pack, index) => {
            return (
              <PackItem
                key={pack.id}
                num={index + 1}
                pack={pack}
                onEdit={() => setCurrentPack(pack)}
                onDelete={() => {
                  setValue(
                    'settings.packs',
                    packs.filter(p => p.id !== pack.id),
                    { shouldDirty: true },
                  )
                }}
              />
            )
          })}

          <ErrorMessage
            name={`settings.packs`}
            errors={errors}
            render={({ message }) => {
              return message ? <FormErrorMessage>{message}</FormErrorMessage> : ''
            }}
          />

          <div className="h-14">
            <AddButton
              size="md"
              disabled={isSubmitting}
              icon={PlusContained}
              onClick={() => {
                if (!item_id) {
                  showToast({ message: tOffer('item.required'), severity: ToastSeverity.error })
                  setSettingsOpen(true)
                } else {
                  setCurrentPack({} as Pack)
                }
              }}
            >
              {tOffer('add-pack')}
            </AddButton>
          </div>
        </div>
        <Summary offer={getValues()} />
      </div>
    </div>
  )
}
