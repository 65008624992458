import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { currencies as currencyData } from 'country-data'
import {
  CountryFlag,
  InputSearch,
  SearchFilterGeneric,
  SelectFilter,
  SelectItem,
  useCountryGroups,
} from '@dashboard/ui'
import { getCountries } from '@/translates'
import { PriceTemplatesEditFilter } from '../types'
import { getCountryByCurrency } from '../libs'
import { Country, Currency } from '@/api/dashboard'
import { getTaxesOptions } from '@/layouts/tax'

interface PriceTemplateTableFiltersProps {
  countries?: Country[]
  currencies?: Currency[]
  filter: SearchFilterGeneric<PriceTemplatesEditFilter>
}

export const PriceTemplateTableFilters = ({ countries, currencies, filter }: PriceTemplateTableFiltersProps) => {
  const { t: tBase } = useTranslation()
  const { t } = useTranslation('sku-price-templates', { keyPrefix: 'table.filters' })
  const countryGroups = useCountryGroups({ include: countries })

  const { data: taxes = [] } = useQuery(getTaxesOptions())

  const countrySet = useMemo(() => (countries ? new Set(countries) : null), [countries])
  const countryItems: SelectItem[] = useMemo(
    () =>
      Object.entries(getCountries(true))
        .filter(([value]) => countrySet?.has(value as Country) ?? true)
        .map(([value, children]) => ({
          children: children as string,
          value: value as string,
          icon: () => <CountryFlag country={value} />,
        })),
    [countryGroups],
  )

  const currencyCodeSet = useMemo(() => (currencies ? new Set(currencies) : null), [currencies])
  const currenciesItems: SelectItem[] = useMemo(() => {
    return currencyData.all
      .filter(currency => currencyCodeSet?.has(currency.code as Currency) ?? true)
      .map(currency => ({
        children: currency.name,
        value: currency.code,
        icon: () => (
          <>
            <CountryFlag country={getCountryByCurrency(currency.code) || ''} />
            <span className="w-8 font-medium text-text-secondary">{currency.code}</span>
          </>
        ),
      }))
  }, [])

  const taxItems: SelectItem[] = useMemo(() => {
    return [
      {
        children: 'Included',
        value: taxes
          .filter(rate => rate.included)
          .map(rate => rate.country)
          .join('|'),
      },
      {
        children: 'Excluded',
        value: taxes
          .filter(rate => !rate.included)
          .map(rate => rate.country)
          .join('|'),
      },
    ]
  }, [taxes])

  return (
    <div className="sticky left-0 top-0 z-20 -mx-2 flex items-center gap-3 bg-fg-primary px-2 pb-4">
      <InputSearch {...filter.registerInput('q')} placeholder={tBase('search')} />
      <div className="flex gap-2">
        <SelectFilter
          {...filter.registerSelect('country', true)}
          items={countryItems}
          groups={countryGroups}
          title={t('country')}
          params={{ size: 'lg' }}
        >
          {t('country')}
        </SelectFilter>
        <SelectFilter
          {...filter.registerSelect('currency', true)}
          title={t('currency')}
          items={currenciesItems}
          params={{ size: 'lg' }}
        >
          {t('currency')}
        </SelectFilter>
        <SelectFilter
          {...filter.registerSelect('tax', true)}
          title={t('tax')}
          items={taxItems}
          params={{ search: false }}
        >
          {t('tax')}
        </SelectFilter>
      </div>
    </div>
  )
}
