import { Platform, TemplatePrice } from '@/api/dashboard'

/**
 * Find the price index.
 * @param findPrice
 * @param platform
 * @returns
 */
export const findPriceIndex =
  (findPrice: Pick<TemplatePrice, 'currency' | 'country'>, platform?: Platform) => (price: TemplatePrice) => {
    return (
      price.country === findPrice.country &&
      price.currency === findPrice.currency &&
      (platform ? price.platform === platform : true)
    )
  }
