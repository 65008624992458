import i18next from 'i18next'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import {
  PageLayoutType,
  PageRead,
  SectionObjectType,
  SectionType,
  SectionType as SectionTypeGen,
  WebsiteSection,
} from '../../api/dashboard'
import { Dialog } from '@mui/material'
import { FieldGroup, ModalFooter, ModalTitle, SelectIcon, TableZeroState } from '@/ui'
import { Controller, ControllerRenderProps } from 'react-hook-form'
import { prepareSLUG } from '@/util'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { dashboardClient, getErrorText } from '@/api'
import { invalidatePages, usePagesQuery } from '@/layouts/pages/api/usePagesQuery'
import { useQueryClient } from '@tanstack/react-query'
import { PagesSlugCaption } from '@/layouts/pages/components'
import { useWebsitesQuery } from '@/api/useWebsitesQuery'
import { Button, Input, InputSearch, Select, formatHookFormErrors } from '@dashboard/ui'
import { HUB_SECTION_AVAILABLE_ICONS, HubSectionIconKey, getHubSectionIcon } from '@/layouts/hub'
import { CloseOutline } from '@/icons'
import { useDebounce } from '@/hooks'
import { slugifyString, usePydenticForm } from '@/libs'

const NEW_PAGE_ID = 'new'

const SERVICE_PAGES_SLUGS = ['daily-rewards', 'loyalty-program']

type SectionFormData = {
  id: string
  title: string
  slug: string
}

const ChangeSectionDialog = (props: {
  sectionsList?: WebsiteSection[]
  section?: WebsiteSection
  getPageBySection: (section_id?: string) => PageRead | undefined
  onClose: (section?: WebsiteSection) => void
}) => {
  const { sectionsList } = props
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }

  const { data: websites = [] } = useWebsitesQuery(companyId, gameId)
  const [search, setSearch] = useState<string>()
  const debouncedSearch = useDebounce(search?.trim(), 200)

  const { data: pages = [], isLoading } = usePagesQuery(companyId, gameId, {
    limit: 100,
    ignore_parent_id: true,
    types: [PageLayoutType.Article, PageLayoutType.Collection, PageLayoutType.Content, PageLayoutType.Blocks],
    search_string: debouncedSearch,
  })

  const pagesAvailable = useMemo(() => {
    const p = pages.filter(p => !sectionsList?.find(s => s.object_id === p.id || s.name === p.slug))
    if (props.section) {
      const currentPage = props.getPageBySection(props.section?.object_id)
      currentPage && p.push(currentPage)
    }
    return p
  }, [props.sectionsList, pages])

  const sections = useMemo(() => {
    // use search for filter
    return pagesAvailable.filter(
      it => !debouncedSearch || it.title.toLowerCase().includes(debouncedSearch.toLowerCase()),
    )
  }, [debouncedSearch, pagesAvailable])

  const [section, setSection] = useState({
    visible: props.section?.visible || true,
    name: props.section?.name || (SectionTypeGen.Page as unknown as SectionType),
    object_type: props.section?.object_type || SectionObjectType.Page,
    object_id: props.section?.object_id,
    icon: props.section?.icon || 'page',
  } as WebsiteSection)

  const [page, setPage] = useState<PageRead>()

  useEffect(() => {
    if (section?.object_id) {
      setPage(sections.find(it => it.id == section?.object_id))
    }
  }, [section.object_id])

  const {
    formState: { errors, isDirty, isValid, isSubmitting },
    control,
    setValue,
    watch,
    setError,
    handleSubmit,
  } = usePydenticForm<SectionFormData>({
    defaultValues: {
      id: props.section?.object_id || '',
      title: '',
      slug: '',
    },
  })
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [pageID, slug, title] = watch(['id', 'slug', 'title'])

  useEffect(() => {
    setValue('title', page?.title || '', { shouldDirty: false })
    setValue('slug', page?.slug || '', { shouldDirty: false })
  }, [page])

  useEffect(() => {
    setSection(prev => ({ ...prev, object_id: pageID }))
  }, [pageID])

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>, field: ControllerRenderProps<SectionFormData, 'title'>) => {
    if (!page?.id) {
      setValue('slug', slugifyString(e.target.value), { shouldValidate: true })
    }
    field.onChange(e.target.value)
  }

  const onSubmit = async () => {
    try {
      if (section.object_id === NEW_PAGE_ID) {
        const { data: created } = await dashboardClient.v1.createPage(companyId, gameId, {
          title: title,
          slug: slug,
          text: '',
          description: '',
          layout: {
            blocks: [[]],
          },
          type: PageLayoutType.Blocks,
          published_at: new Date().getTime() / 1000,
        })
        section.object_id = created.id
        setSection(section)
        invalidatePages(queryClient, gameId)
      } else if (isDirty && section.object_id) {
        // update page fields
        await dashboardClient.v1.updatePage(companyId, gameId, section.object_id, {
          title: title,
          slug: slug,
        })
        invalidatePages(queryClient, gameId)
      }

      props.onClose(section)
    } catch (responseError) {
      const text = getErrorText(responseError)
      if (text === `This 'Page' resource already exists`) {
        setError('slug', { message: t('validation.page-slug-already-exists') })
      } else {
        throw responseError
      }
    }
  }

  const extraTop = (
    <div className="border-b border-border-secondary p-3">
      <InputSearch
        autoFocus
        value={search}
        onFocus={e => {
          e.stopPropagation()
        }}
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        placeholder={t('hub-settings.sections.add-dialog.search-placeholder')}
      />
    </div>
  )

  const extraDown = () =>
    sections.length === 0 &&
    search &&
    !isLoading && (
      <div className="mt-2 h-[200px]">
        <TableZeroState
          title={t('hub-settings.sections.add-dialog.search-zero-title')}
          message={t('hub-settings.sections.add-dialog.search-zero-text')}
          onClick={e => e.stopPropagation()}
          buttons={
            <Button
              variant="outline"
              onClick={e => {
                e.stopPropagation()
                setSearch('')
              }}
            >
              <CloseOutline size={14} />
              <span>{t('hub-settings.sections.add-dialog.search-zero-clear')}</span>
            </Button>
          }
        />
      </div>
    )

  return (
    <Dialog
      open={true}
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiDialog-paper': { padding: '40px', borderRadius: '24px', margin: 0 } }}
      onClose={() => props.onClose()}
    >
      <ModalTitle>
        {props.section
          ? i18next.t('hub-settings.sections.change-dialog.title')
          : i18next.t('hub-settings.sections.add-dialog.title')}
      </ModalTitle>

      <FieldGroup>
        <div className="flex w-full items-start gap-4">
          <div className="shrink-0">
            <FieldGroup label={t('hub-settings.sections.add-dialog.icon')}>
              <SelectIcon
                items={HUB_SECTION_AVAILABLE_ICONS.map(icon => ({
                  children: (
                    <img
                      className="size-[18px]"
                      src={getHubSectionIcon(icon as HubSectionIconKey) as string}
                      alt={icon}
                    />
                  ),
                  value: icon,
                }))}
                value={section.icon || 'page'}
                onChange={v =>
                  setSection({
                    ...section,
                    icon: v as string,
                  })
                }
              />
            </FieldGroup>
          </div>

          <div className="w-full">
            <FieldGroup label={t('hub-settings.sections.form.id')}>
              <Controller
                name="id"
                control={control}
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Select
                    {...field}
                    {...formatHookFormErrors(errors, 'id')}
                    items={[
                      { children: t('hub-settings.sections.add-dialog.create-new'), value: NEW_PAGE_ID },
                      ...sections.map(p => ({ children: p.title, value: p.id })),
                    ]}
                    header={extraTop}
                    renderMenuFooter={extraDown}
                    onChange={v => {
                      setValue('id', v as string, { shouldDirty: false })
                    }}
                  />
                )}
              />
            </FieldGroup>
            <FieldGroup label={i18next.t('hub-settings.sections.form.title')}>
              <Controller
                name="title"
                control={control}
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Input
                    {...field}
                    {...formatHookFormErrors(errors, 'title')}
                    disabled={!pageID}
                    onChange={e => {
                      onChangeTitle(e, field)
                    }}
                  />
                )}
              />
            </FieldGroup>

            <FieldGroup
              tooltip={i18next.t('sku.sku-desc')}
              label={i18next.t('hub-settings.sections.form.slug')}
              caption={<PagesSlugCaption base={websites?.[0].url} slug={slug} parent={page?.parent?.slug} />}
            >
              <Controller
                name="slug"
                control={control}
                rules={{ required: t('validation.required') }}
                render={({ field }) => (
                  <Input
                    {...field}
                    {...formatHookFormErrors(errors, 'slug')}
                    disabled={!pageID || SERVICE_PAGES_SLUGS.includes(slug)}
                    onChange={e => {
                      field.onChange(prepareSLUG(e.target.value))
                    }}
                  />
                )}
              />
            </FieldGroup>
          </div>
        </div>
      </FieldGroup>

      <ModalFooter>
        <Button variant="outline" color="secondary" onClick={() => props.onClose()}>
          {i18next.t('hub-settings.sections.add-dialog.cancel')}
        </Button>
        <Button
          loading={isSubmitting}
          disabled={!section.object_id || (!isValid && isDirty)}
          onClick={handleSubmit(onSubmit)}
        >
          {props.section ? i18next.t('Save') : i18next.t('Add')}
        </Button>
      </ModalFooter>
    </Dialog>
  )
}

export default ChangeSectionDialog
