import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const XIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 28 28" ref={ref}>
      <path
        d="M6.77539 7.25L12.0972 14.8555L7.05518 20.75H9.03564L12.9834 16.1226L16.2207 20.75H21.4033L15.8369 12.7812L20.5552 7.25H18.6055L14.9536 11.5156L11.9741 7.25H6.77539ZM9.65527 8.75H11.1919L18.5249 19.25H17.0015L9.65527 8.75Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
