import { TextareaHTMLAttributes, forwardRef } from 'react'
import styled from '@emotion/styled'
import shouldForwardProp from '@emotion/is-prop-valid'
import { css } from '@emotion/react'
import { FieldErrors } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { FieldValidationMessage } from '@/components/ui'
import { cn } from '@/libs'

interface TextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'size'> {
  size?: 'xs' | 'sm' | 'md' | 'lg'
  color?: 'default' | 'success' | 'warning' | 'error'
  errors?: FieldErrors
}

const StyledTextarea = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`

const TextField = styled('textarea', { shouldForwardProp })<{
  $color: TextareaProps['color']
}>`
  border: 1px solid ${p => p.theme.palette.grey.borderPrimary};
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  outline: none;
  font-family:
    'InterAg',
    'SF Pro Display',
    -apple-system,
    BlinkMacSystemFont,
    'Open Sans',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  resize: vertical;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  transition:
    border-color ease-in-out 160ms,
    box-shadow ease-in-out 160ms;

  ${p =>
    p.$color === 'default' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.grey.borderPrimary};
      color: ${p.theme.palette.text.secondary};

      &:hover,
      &:focus {
        border-color: #cbd5e1;
        box-shadow: 0 0 0 2px rgba(0, 151, 228, 0.14);
      }

      &:focus {
        border-color: ${p.theme.palette.primary.main};
      }
    `}
  ${p =>
    p.$color === 'success' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.success.main};
      color: ${p.theme.palette.text.secondary};

      &:hover,
      &:focus {
        box-shadow: 0 0 0 3px rgba(34, 197, 94, 0.14);
      }

      &:focus {
        border-color: ${p.theme.palette.success.main};
      }
    `}
    ${p =>
    p.$color === 'warning' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.warning.main};
      color: ${p.theme.palette.text.secondary};

      &:hover,
      &:focus {
        box-shadow: 0 0 0 3px rgba(251, 146, 60, 0.14);
      }

      &:focus {
        border-color: ${p.theme.palette.warning.main};
      }
    `}
    ${p =>
    p.$color === 'error' &&
    css`
      background: ${p.theme.palette.background.fgPrimary};
      border-color: ${p.theme.palette.error.main};
      color: ${p.theme.palette.text.secondary};

      &:hover,
      &:focus {
        box-shadow: 0 0 0 3px rgba(240, 68, 56, 0.14);
      }

      &:focus {
        border-color: ${p.theme.palette.error.main};
      }
    `}
    &::placeholder {
    color: #64748b;
  }

  &[readonly] {
    cursor: pointer;
  }

  &[disabled] {
    background-color: #f1f5f9;
    border-color: #e2e8f0;
    color: #94a3b8;
    pointer-events: none;

    &::placeholder {
      color: inherit;
    }
  }
`

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Textarea(
  { size = 'md', color = 'default', rows = 6, errors, ...rest },
  ref,
) {
  return (
    <StyledTextarea>
      <TextField
        {...rest}
        className={cn(
          size === 'xs' && 'text-paragraph-xs',
          size === 'sm' && 'text-paragraph-sm',
          size === 'lg' && 'text-paragraph-lg',
          size === 'md' && 'text-paragraph-md',
        )}
        rows={rows}
        $color={color}
        ref={ref}
      />
      {rest.name && errors && (
        <ErrorMessage
          name={rest.name}
          errors={errors}
          render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
        />
      )}
    </StyledTextarea>
  )
})
