import { forwardRef } from 'react'
import { AghanimIcon, IconBase } from '@/icons'

export const LocationIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 14 14" ref={ref}>
      <path
        d="M6 4C5.4477 4 5 4.44772 5 5C5 5.5523 5.4477 6 6 6C6.5523 6 7 5.5523 7 5C7 4.44772 6.5523 4 6 4Z"
        fill="currentColor"
      />
      <path
        d="M8.12196 9.0607C7.66311 9.61004 7.20542 10.0681 6.86276 10.3887C6.69116 10.5493 6.54769 10.676 6.4464 10.7632L6.42736 10.7798C6.30448 10.8866 6.17409 11 6 11C5.82626 11 5.69494 10.886 5.5721 10.7793L5.5536 10.7632C5.45231 10.676 5.30884 10.5493 5.13724 10.3887C4.79458 10.0681 4.33689 9.61004 3.87804 9.0607C2.97786 7.98291 2 6.46822 2 4.91304C2 2.75193 3.79084 1 6 1C8.20916 1 10 2.75193 10 4.91304C10 6.46822 9.02213 7.98291 8.12196 9.0607ZM5.82048 9.6585C5.85976 9.69525 5.8974 9.73005 5.9332 9.76281C5.97103 9.79743 6.02897 9.79743 6.0668 9.76281C6.10261 9.73004 6.14026 9.69523 6.17954 9.65848C6.50107 9.35764 6.92855 8.92956 7.35444 8.41966C8.23034 7.37095 9 6.09297 9 4.91304C9 3.32482 7.6777 2 6 2C4.3223 2 3 3.32482 3 4.91304C3 6.09298 3.76964 7.37094 4.64554 8.41964C5.07144 8.92954 5.49895 9.35767 5.82048 9.6585Z"
        fill="currentColor"
      />
    </IconBase>
  )
})
