import { forwardRef } from 'react';
import { AghanimIcon, IconBase } from '../IconBase';

export const Globe1: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 18 18" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99967 1.2085C13.3029 1.2085 16.7913 4.69694 16.7913 9.00016C16.7913 13.3034 13.3029 16.7918 8.99967 16.7918C4.69646 16.7918 1.20801 13.3034 1.20801 9.00016C1.20801 4.69694 4.69646 1.2085 8.99967 1.2085ZM2.66358 8.29183C2.95618 5.64495 4.86924 3.48648 7.38872 2.83046C6.30737 4.4558 5.6523 6.33457 5.49349 8.29183H2.66358ZM2.66358 9.7085C2.95618 12.3554 4.86925 14.5138 7.38872 15.1699C6.30737 13.5445 5.6523 11.6658 5.49349 9.7085H2.66358ZM10.6106 15.1699C13.1301 14.5138 15.0432 12.3554 15.3358 9.7085H12.5059C12.347 11.6658 11.692 13.5445 10.6106 15.1699ZM15.3358 8.29183H12.5059C12.347 6.33457 11.692 4.4558 10.6106 2.83046C13.1301 3.48648 15.0432 5.64495 15.3358 8.29183ZM8.99967 3.01531C10.1824 4.53903 10.9059 6.37 11.0839 8.29183H6.91547C7.0934 6.37 7.81699 4.53903 8.99967 3.01531ZM8.99967 14.985C7.81699 13.4613 7.0934 11.6303 6.91547 9.7085H11.0839C10.9059 11.6303 10.1824 13.4613 8.99967 14.985Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

Globe1.type = 'aghanim-icon';
