import Divider from '../../../components/Divider'
import { FieldSection } from 'src/components/ui/FieldSection'
import { Checkbox, FieldGroup, ImageUploader, InputExtraMark, PropertyCheckboxControl } from '@/ui'
import { CreateVirtualSKUNode, Currency, ItemType, Node, ResourceState, StoreCardType } from '@/api/dashboard'
import { BlockError } from '../types'
import SettingsHeader from '../SettingsHeader'
import { CheckBoxContainer } from '@/components/ui/CheckBoxContainer'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '@/api/useGameItems'
import { ErrorMessage } from '@hookform/error-message'
import { SelectPaymentMethod } from '@/layouts/campaigns/components/SelectPaymentMethod/SelectPaymentMethod'
import { ItemTimelimit, validateInterval } from '@/layouts/components/ItemTimelimit'
import { clearStoreItemEmptyFields } from '@/layouts/store/util'
import { useGameSettingsQuery } from '@/api/useGameSettingsQuery'
import { CONTEXT_ITEM, getContextItemText } from '@/layouts/campaigns/editors/common'
import { useContext, useEffect } from 'react'
import { CampaignContext, ICampaignContext } from '@/layouts/campaigns/Context'
import { S3Bucket } from '@/types'
import { MAX_NAME_LENGTH } from '@/Settings'
import DurationSelectorDHMS from '@/components/DurationSelectorDHMS'
import { FormErrorMessage, Input, Select, formatHookFormErrors } from '@dashboard/ui'
import { useTranslation } from 'react-i18next'
import { BonusItemBenefitEditor } from '@/layouts/components/BonusItemBenefitEditor'
import { SelectSkuItemV2 } from '@/layouts/components/SelectSkuItemV2'
import { useGenerateCompanyPath } from '@/libs'

interface VirtualSKUSettingsProps {
  onClose: () => void
  setNode: (node: Node) => void
  node: CreateVirtualSKUNode
  error?: BlockError | null
}

export default function VirtualSKUEditor(props: VirtualSKUSettingsProps) {
  const { companyId, gameId } = useGenerateCompanyPath()
  const { t } = useTranslation()
  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const context = useContext(CampaignContext) as ICampaignContext
  const { data: gameSettings } = useGameSettingsQuery(companyId, gameId)

  const methods = useForm<CreateVirtualSKUNode>({
    mode: 'onChange',
    values: { ...props.node },
  })

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors,
  } = methods

  const onSaveClick = (data: CreateVirtualSKUNode) => {
    const err = validateInterval(data.start_at, data.end_at, t)
    if (err) {
      setError('start_at', { message: err })
      return
    } else {
      clearErrors('start_at')
    }

    clearStoreItemEmptyFields(data, !!isStackable || selectedItem?.type == ItemType.Bundle)

    if (!data.item_image_url) {
      data.item_image_url = null as unknown as string
    }

    props.setNode(data as Node)
    props.onClose()
  }

  useEffect(() => {
    if (props.error?.errors?.length) {
      let er = props.error.errors[0]
      setError(er.field as 'item_id', { message: er.message })
    }
  }, [props.error])

  const itemId = watch('item_id')
  const min_amount = watch('min_amount')
  const payment_method_ids = watch('payment_method_ids')
  const max_reward_point_percent = watch('max_reward_points_percent')
  const max_purchases = watch('max_purchases')
  const start_at = watch('start_at')
  const end_at = watch('end_at')
  const duration = watch('duration')
  const is_free_item = watch('is_free_item')
  const [use_event_item, card_type, bonus_item_id] = watch(['use_event_item', 'card_type', 'bonus_item_id'])

  const selectedItem = items.find(it => it.id == itemId)
  const isStackable = use_event_item || selectedItem?.is_stackable

  const renderGeneral = () => {
    return (
      <div className="flex gap-4">
        <div className="w-[421px]">
          <FieldGroup
            caption={t('campaign.virtual-sku-editor.select-sku')}
            label={t('campaign.virtual-sku-editor.SKU')}
          >
            <Controller
              name="item_id"
              rules={{ required: t('validation.required') }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectSkuItemV2
                  contextItemText={getContextItemText(context.campaign) as string}
                  onChange={v => {
                    clearErrors('item_id')
                    if (v == CONTEXT_ITEM) {
                      setValue('use_event_item', true)
                      onChange(null)
                    } else {
                      onChange(v)
                      setValue('use_event_item', false)
                    }

                    if (v == bonus_item_id) {
                      setValue('bonus_item_id', null as unknown as string)
                    }
                  }}
                  value={use_event_item ? CONTEXT_ITEM : value}
                />
              )}
            />
            <ErrorMessage
              name="item_id"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        </div>
        {isStackable != undefined && (
          <div className="w-[335px]">
            <FieldGroup
              caption={t('campaign.virtual-sku-editor.sku-type-desc')}
              label={t('campaign.virtual-sku-editor.sku-type')}
            >
              <Input
                disabled
                value={t(
                  isStackable ? 'campaign.virtual-sku-editor.stackable' : 'campaign.virtual-sku-editor.non-stackable',
                )}
              />
            </FieldGroup>
          </div>
        )}
      </div>
    )
  }

  const renderGeneralLimitations = () => {
    return (
      <FieldSection label={t('campaign.virtual-sku-editor.general-limitations')}>
        <CheckBoxContainer
          disabled={true}
          checked={!!payment_method_ids}
          onChange={v => {
            setValue('payment_method_ids', v.target.checked ? [] : undefined)
          }}
          label={`${t('coupon.payment-bound')} (${t('coming-soon.title')})`}
          caption={t('coupon.payment-bound-desc')}
        >
          <SelectPaymentMethod
            value={(payment_method_ids || []) as string[]}
            onChange={v => {
              setValue('payment_method_ids', v as string[])
            }}
          />
        </CheckBoxContainer>

        <Divider />

        <Controller
          name="min_amount"
          rules={{ required: min_amount == null ? false : t('validation.required') }}
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer
              disabled={is_free_item}
              checked={value != null}
              onChange={v => {
                onChange(v.target.checked ? 100 : null)
              }}
              label={t('campaign.virtual-sku-editor.min_amount')}
            >
              <Input
                disabled={is_free_item}
                type="number"
                value={isNaN(value!) ? '' : value! / 100}
                extraLeft={{
                  icon: <InputExtraMark>$</InputExtraMark>,
                }}
                onChange={e => {
                  let v = Math.abs(parseFloat(e.target.value))
                  onChange(Math.max(1, v * 100))
                }}
              />
              <ErrorMessage
                name="min_amount"
                errors={errors}
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </CheckBoxContainer>
          )}
        />
      </FieldSection>
    )
  }

  const renderTimeLimitations = () => {
    return (
      <FieldSection label={t('campaign.settings.limit-limitations')}>
        <ItemTimelimit
          useSettingsContainer={false}
          setValue={(prop, value) => {
            clearErrors('duration')
            clearErrors('start_at')
            setValue(prop, value)
          }}
          error={errors.start_at?.message}
          start_at={start_at}
          end_at={end_at}
        />

        <CheckBoxContainer
          className="mt-4"
          checked={duration != undefined}
          onChange={v => {
            setValue('duration', v.target.checked ? 3600 : undefined)
            if (v.target.checked) {
              clearErrors('start_at')
              setValue('start_at', null as unknown as undefined)
              setValue('end_at', null as unknown as undefined)
            }
          }}
          label={t('campaign.virtual-sku-editor.limit-by-timer')}
        >
          <DurationSelectorDHMS
            duration={duration || 0}
            size="small"
            onChange={v => {
              clearErrors('duration')
              setValue('duration', v)
            }}
          />
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderBenefitsCompatibility = () => {
    return (
      <FieldSection label={t('campaign.virtual-sku-editor.benefits-compatibility')}>
        <CheckBoxContainer
          disabled
          checked={max_reward_point_percent != undefined}
          onChange={v => {
            setValue('max_reward_points_percent', v.target.checked ? 0 : undefined)
          }}
          label={`${t('campaign.virtual-sku-editor.stackable-with-cashback')} (${t('coming-soon.title')})`}
        >
          <FieldGroup label={t('campaign.virtual-sku-editor.max_reward_point_percent')}>
            <Input type="number" {...register('max_reward_points_percent')} />
          </FieldGroup>
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderQuantityLimitations = () => {
    return (
      <FieldSection label={t('campaign.virtual-sku-editor.quantity-limitations')}>
        <CheckBoxContainer
          checked={max_purchases != undefined}
          onChange={v => {
            setValue('max_purchases', v.target.checked ? 1 : undefined)
          }}
          label={t('campaign.virtual-sku-editor.limit-quantity')}
        >
          <FieldGroup
            label={t('campaign.virtual-sku-editor.max_purchases')}
            caption={t('campaign.virtual-sku-editor.max_purchases.desc')}
          >
            <Input
              type="number"
              {...register('max_purchases', { min: 1 })}
              placeholder={t('campaign.virtual-sku-editor.max_purchases.ph')}
            />
          </FieldGroup>
        </CheckBoxContainer>
      </FieldSection>
    )
  }

  const renderDisplaySettings = () => {
    return (
      <>
        <FieldSection label={t('store.item.display_settings')}>
          <FieldGroup label={t('store.item.custom_badge_text')}>
            <Input {...register('custom_badge')} maxLength={30} />
            <ErrorMessage
              name="custom_badge"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>

          <FieldGroup label={t('store.item.featured_type')}>
            <Controller
              control={control}
              name="card_type"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value || StoreCardType.Default}
                  items={Object.values(StoreCardType).map(value => ({
                    children: t(`store.item.featured_type.${value}`),
                    value: value,
                  }))}
                />
              )}
            />
          </FieldGroup>

          <Checkbox {...register('show_first_on_category_list')} disabled={card_type != StoreCardType.Featured}>
            {t('store.item.show_first_on_category_list')}
          </Checkbox>
        </FieldSection>

        <Divider />

        <FieldSection label={t('campaign.block.offer.item-override')}>
          <FieldGroup label={t('campaign.block.offer.item-name')}>
            <Input {...register('item_name', {})} maxLength={MAX_NAME_LENGTH} />
          </FieldGroup>

          <FieldGroup label={t('campaign.block.offer.item-desc')}>
            <Input {...register('item_description', {})} maxLength={MAX_NAME_LENGTH} />
          </FieldGroup>

          <FieldGroup label={t('campaign.block.offer.item-image')}>
            <Controller
              control={control}
              name="item_image_url"
              render={({ field }) => (
                <ImageUploader
                  accept={{
                    'image/jpeg': ['.jpeg', '.jpg'],
                    'image/png': [],
                    'image/webp': [],
                  }}
                  bucket={S3Bucket.hub}
                  {...field}
                />
              )}
            />
          </FieldGroup>
        </FieldSection>
      </>
    )
  }

  const renderBenefits = () => {
    return (
      <FieldSection label={t('campaign.block.ItemDiscountOfferActionNode.Benefits')}>
        <div className="mb-3">
          <div className="mb-3 flex gap-3 p-0.5">
            <PropertyCheckboxControl
              size="md"
              className="w-1/2"
              value={!is_free_item}
              type="radio"
              label={t('store.item.paid_item')}
              desc={t('store.item.paid_item.desc')}
              onChange={() => {
                setValue('is_free_item', false, { shouldDirty: true })
              }}
            />

            <PropertyCheckboxControl
              size="md"
              type="radio"
              className="w-1/2"
              value={!!is_free_item}
              label={t('store.item.free_item')}
              desc={t('store.item.free_item.desc')}
              onChange={() => {
                setValue('is_free_item', true, { shouldDirty: true })
                if (!max_purchases) {
                  setValue('max_purchases', 1)
                  setValue('bonus_item_id', null as unknown as string)
                }
              }}
            />
          </div>
        </div>

        {!is_free_item && (
          <FieldGroup label={t('campaign.virtual-sku-editor.discount')}>
            <Input
              type="number"
              {...register('discount_percent', {
                min: {
                  value: 1,
                  message: t('validation.min', { field: t('campaign.virtual-sku-editor.discount'), value: 1 }),
                },
                max: {
                  value: 99,
                  message: t('validation.max', { field: t('campaign.virtual-sku-editor.discount'), value: 100 }),
                },
              })}
              {...formatHookFormErrors(errors, 'discount_percent')}
              disabled={is_free_item}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
          </FieldGroup>
        )}

        {(isStackable || selectedItem?.type == ItemType.Bundle) && (
          <FieldGroup label={t('campaign.virtual-sku-editor.bonus')}>
            <Input
              type="number"
              {...register('bonus_percent')}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
          </FieldGroup>
        )}

        {gameSettings?.enable_reward_points && !is_free_item && selectedItem?.currency != Currency.RP && (
          <FieldGroup label={t('campaign.virtual-sku-editor.reward_point_percent')}>
            <Input
              type="number"
              {...register('reward_points_percent', {
                min: { value: 1, message: t('campaign.virtual-sku-editor.reward_point_percent.validation') },
                max: { value: 100, message: t('campaign.virtual-sku-editor.reward_point_percent.validation') },
              })}
              extraLeft={{
                icon: <InputExtraMark>%</InputExtraMark>,
              }}
            />
            <ErrorMessage
              name="reward_points_percent"
              errors={errors}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FieldGroup>
        )}
      </FieldSection>
    )
  }

  return (
    <div>
      <SettingsHeader
        onSave={handleSubmit(onSaveClick)}
        onClose={props.onClose}
        text={t('campaign.virtual-sku-editor.title')}
      />

      <div className="p-6">
        {renderGeneral()}

        {(itemId || use_event_item) && (
          <>
            <div className="font-bold">
              {t(
                isStackable
                  ? 'campaign.virtual-sku-editor.stackable.desc'
                  : 'campaign.virtual-sku-editor.non-stackable.desc',
              )}
            </div>

            <Divider />

            {renderBenefits()}

            <Divider />

            {!is_free_item && (
              <FormProvider {...methods}>
                <BonusItemBenefitEditor />
              </FormProvider>
            )}

            <Divider />

            {renderGeneralLimitations()}
            <Divider />

            {renderTimeLimitations()}
            <Divider />

            {renderQuantityLimitations()}
            <Divider />

            {gameSettings?.enable_reward_points && (
              <>
                {renderBenefitsCompatibility()}
                <Divider />
              </>
            )}

            {renderDisplaySettings()}
          </>
        )}
      </div>
    </div>
  )
}
