import { queryOptions } from '@tanstack/react-query'
import { dashboardClientV1 } from '@/api'

export const getTaxesOptions = () => {
  return queryOptions({
    queryKey: ['taxes'],
    queryFn: async () => {
      const { data } = await dashboardClientV1.v1.getTaxes()
      return data
    },
  })
}
