import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@/ui'
import { GAME_WEBHOOKS_NEW_PATH, useGenerateCompanyPath } from '@/libs'
import { webhooksQuery } from '@/layouts/webhooks'
import { GetStartedStepState, GetStartedStepTask } from '../types'
import { useGetStarted } from './useGetStarted'

export type WebhookTasks = 'playerVerification' | 'itemAdd' | 'itemRemove'

/**
 *
 */
export const useWebhookStep = (): GetStartedStepState<WebhookTasks> => {
  const { t } = useTranslation()
  const { generatePath, companyId, gameId } = useGenerateCompanyPath()
  const { data: webhooks = [], isLoading } = useQuery(webhooksQuery(companyId, gameId))
  const { check, complete } = useGetStarted()

  const id = 'webhook'

  const tasks = useMemo(
    () => ({
      playerVerification: {
        label: t(`get-started.steps.${id}.tasks.playerVerification`),
        status: webhooks.some(w => w.events.some(e => e === 'player.verify')) ? 'completed' : 'idle',
      },
      itemAdd: {
        label: t(`get-started.steps.${id}.tasks.itemAdd`),
        status: webhooks.some(w => w.events.some(e => e === 'item.add')) ? 'completed' : 'idle',
      },
      itemRemove: {
        label: t(`get-started.steps.${id}.tasks.itemRemove`),
        status: webhooks.some(w => w.events.some(e => e === 'item.remove')) ? 'completed' : 'idle',
      },
    }),
    [t, id, webhooks],
  ) satisfies Record<WebhookTasks, GetStartedStepTask>

  const status = useMemo(
    () =>
      check('setup_webhook')
        ? 'completed'
        : Object.values(tasks).every(task => task.status === 'completed')
          ? 'completed'
          : 'idle',
    [check, tasks],
  )

  useEffect(() => {
    if (status !== 'completed') {
      return
    }
    complete('setup_webhook')
  }, [complete, status])

  return {
    id,
    loading: isLoading,
    status,
    available_on: ['desktop'],
    title: t(`get-started.steps.${id}.title`),
    desc: t(`get-started.steps.${id}.desc`),
    link: t(`get-started.steps.${id}.link`)
      ? {
          label: t(`get-started.steps.${id}.link`),
          href: 'https://docs.aghanim.com/webhooks/overview',
        }
      : undefined,
    tasks,
    cta: (
      <Link to={generatePath(GAME_WEBHOOKS_NEW_PATH)}>
        <Button className="min-w-40" variant={status === 'completed' ? 'outline' : 'secondary-brand'} size="lg">
          {t(`get-started.steps.${id}.cta.label`)}
        </Button>
      </Link>
    ),
  }
}
