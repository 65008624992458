import { HTMLAttributes } from 'react'
import { VariantProps, cva } from 'class-variance-authority'

import { Tooltip } from '@/components/ui'
import { CopyButton } from '@/components/ui/LabelCopyButton/CopyButton'
import { cn } from '@dashboard/ui'

const variants = cva('flex items-center ', {
  variants: {
    variant: {
      default: 'h-[33px] w-[443px]  rounded-md bg-fg-secondary-hover px-3 text-text-secondary',
      secondary: 'w-full truncate text-caption-sm font-semibold text-text-secondary',
      third: 'w-full truncate text-caption-sm font-normal text-text-secondary',
    },
    icon: {
      default: '',
      secondary: 'text-text-quarterary',
    },
  },
})

export interface LabelCopyButtonProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof variants> {
  value: string
  tooltip?: string
}

export const LabelCopyButton = ({ value, tooltip, variant = 'default', ...rest }: LabelCopyButtonProps) => {
  return (
    <div {...rest} className={cn(variants({ variant }), rest.className)}>
      {tooltip ? (
        <Tooltip message={tooltip}>
          <div className={cn('grow truncate')}>{value}</div>
        </Tooltip>
      ) : (
        <div className={cn('grow truncate')}>{value}</div>
      )}
      <CopyButton text={value} className="ml-auto" />
    </div>
  )
}
