import { HTMLAttributes, ReactElement, ReactNode, useId } from 'react'
import { useKey } from 'react-use'
import { AnimatePresence, motion } from 'framer-motion'
import { cn, getMotionProps, useBodyScrollBlockEffect, useIsMobile } from '@dashboard/ui'
import { ModalProps } from './types'

export interface ModalRushProps
  extends Omit<ModalProps, 'children' | 'isLoading'>,
    Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  children?: ReactNode
  /**
   * Show fallback element if children is not provided.
   */
  fallback?: ReactElement
}

const entryMotionProps = getMotionProps({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})

export const ModalRush = ({ children, fallback, onClose, ...rest }: ModalRushProps) => {
  const id = useId()
  const showFallback = !children && fallback
  const isMobile = useIsMobile()

  useBodyScrollBlockEffect()
  useKey('Escape', onClose)

  return (
    <div
      className={cn(
        'relative m-auto flex size-full flex-col rounded-t-2xl1 bg-fg-primary p-5',
        rest.className,
        isMobile ? 'overflow-y-auto' : 'overflow-hidden',
      )}
    >
      <AnimatePresence initial={false} mode="popLayout">
        {showFallback ? (
          <motion.div {...entryMotionProps} key={`${id}-fallback`} className="flex size-full flex-col">
            {fallback}
          </motion.div>
        ) : (
          <motion.div {...entryMotionProps} key={`${id}-children`} className="flex size-full flex-col">
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
