import i18next from 'i18next'
import { useContext, useMemo, useState } from 'react'
import DurationSelectorDHMS from '../../../components/DurationSelectorDHMS'
import Divider from '../../../components/Divider'
import SettingsHeader from '../SettingsHeader'
import { ItemOfferActionNode, Node, ResourceState } from '../../../api/dashboard'
import { BlockError } from '../types'
import { CONTEXT_ITEM, getContextItemText } from './common'
import { SelectSkuItem } from '@/layouts/components/SelectSkuItem'
import { FieldSection } from '../../../components/ui/FieldSection'
import { FieldGroup, FieldValidationMessage, ImageUploader, Input } from '@/ui'
import { Controller, useForm } from 'react-hook-form'
import { S3Bucket } from '../../../types'
import { CheckBoxContainer } from '../../../components/ui/CheckBoxContainer'
import styled from '@emotion/styled'
import { useParams } from 'react-router-dom'
import { DEFAULT_LOAD_LIMIT, useGameItemsQuery } from '../../../api/useGameItems'
import { CampaignContext, ICampaignContext } from '../Context'
import { ErrorMessage } from '@hookform/error-message'
import { MAX_NAME_LENGTH } from '@/Settings'

const ItemSettingsRoot = styled.div`
  flex-direction: column;
  display: flex;
`

export default function ItemOfferEditor(props: {
  node: ItemOfferActionNode
  error?: BlockError | null
  onClose: () => void
  setNode: (node: Node) => void
}) {
  const { companyId, gameId } = useParams() as {
    companyId: string
    gameId: string
  }
  const context = useContext(CampaignContext) as ICampaignContext
  const [overrideItem, setOverrideItem] = useState<boolean>(
    !!(props.node.item_name || props.node.item_description || props.node.item_image_url),
  )

  const { data: items = [] } = useGameItemsQuery(companyId, gameId, {
    limit: DEFAULT_LOAD_LIMIT,
    state: ResourceState.Active,
  })
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ItemOfferActionNode>({
    mode: 'onChange',
    values: { ...props.node },
  })

  const onSaveClick = (data: ItemOfferActionNode) => {
    if (!overrideItem) {
      data.item_name = undefined
      data.item_description = undefined
      data.item_image_url = undefined
    }

    if (!data.discount_percent) {
      data.discount_percent = null as unknown as undefined
    }

    if (!data.bonus_percent) {
      data.bonus_percent = null as unknown as undefined
    }

    props.setNode(data as Node)
    props.onClose()
  }

  const duration = watch('duration')
  const use_event_item = watch('use_event_item')
  const item_id = watch('item_id')
  const selectedItem = useMemo(() => {
    return items.find(i => i.id == item_id)
  }, [item_id])

  const bonus_percent = watch('bonus_percent')
  //const discount_percent = watch('discount_percent')

  return (
    <div>
      <SettingsHeader
        node={
          {
            ...props.node,
            model_type: 'ItemOfferActionNode',
          } as Node
        }
        onSave={handleSubmit(onSaveClick)}
        onClose={props.onClose}
      />

      <div className="p-6">
        <Controller
          control={control}
          name="item_id"
          rules={{
            validate: (v: string | undefined) => {
              if (v == CONTEXT_ITEM) {
                return true
              }
              if (!v) {
                return i18next.t('campaign.field-required')
              }
              return true
            },
          }}
          render={({ field }) => (
            <>
              <SelectSkuItem
                contextItemText={getContextItemText(context.campaign) as string}
                showLinkToCreateNewSKU={true}
                label={i18next.t('campaign.block.ItemDiscountOfferActionNode.item')}
                onChange={v => {
                  if (v == CONTEXT_ITEM) {
                    setValue('use_event_item', true)
                    setValue('item_id', null as unknown as string)
                  } else {
                    setValue('item_id', v as string)
                    setValue('use_event_item', false)

                    let item = items.find(i => i.id == item_id)
                    if (!item?.is_stackable) {
                      setValue('bonus_percent', null as unknown as number)
                    }
                  }
                }}
                value={use_event_item ? CONTEXT_ITEM : field.value}
              />
              <ErrorMessage
                name="item_id"
                errors={errors}
                render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
              />
            </>
          )}
        />

        {(item_id || use_event_item) && (
          <>
            <Divider />

            <FieldSection label={i18next.t('campaign.block.ItemDiscountOfferActionNode.Benefits')}>
              {selectedItem?.is_stackable && (
                <FieldGroup label={i18next.t('campaign.block.ItemBonusOfferActionNode.bonus')}>
                  <Input
                    type={'number'}
                    {...register('bonus_percent', {
                      required: false,
                      min: 1,
                      max: 1000,
                    })}
                  />
                  <ErrorMessage
                    name="bonus_percent"
                    errors={errors}
                    render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                  />
                </FieldGroup>
              )}

              <FieldGroup label={i18next.t('campaign.block.ItemDiscountOfferActionNode.discount')}>
                <Input
                  type={'number'}
                  {...register('discount_percent', {
                    validate: value => {
                      if (value) {
                        if (value < 0 || value > 99) {
                          return i18next.t('campaign.block.ItemDiscountOfferActionNode.discount-percent.error')
                        }
                        return true
                      }

                      if (!bonus_percent) {
                        return i18next.t('campaign.field-required')
                      }
                    },
                  })}
                />
                <ErrorMessage
                  name="discount_percent"
                  errors={errors}
                  render={({ message }) => <FieldValidationMessage>{message}</FieldValidationMessage>}
                />
              </FieldGroup>
            </FieldSection>

            <Divider />

            <FieldSection label={i18next.t('campaign.block.ItemDiscountOfferActionNode.time-limitations')}>
              <Controller
                control={control}
                name="duration"
                render={({ field }) => (
                  <CheckBoxContainer
                    checked={duration != null}
                    label={i18next.t('campaign.block.ItemDiscountOfferActionNode.limit-timer')}
                    onChange={e => {
                      setValue('duration', e.target.checked ? 3600 : (null as unknown as number))
                    }}
                  >
                    <DurationSelectorDHMS
                      duration={field.value || 0}
                      size="small"
                      onChange={v => {
                        setValue('duration', v)
                      }}
                    />
                  </CheckBoxContainer>
                )}
              />
            </FieldSection>

            <Divider />

            <FieldSection label={i18next.t('campaign.block.offer.item-settings')}>
              <CheckBoxContainer
                checked={overrideItem}
                label={i18next.t('campaign.block.offer.item-override')}
                onChange={v => setOverrideItem(v.target.checked)}
              >
                <ItemSettingsRoot>
                  <FieldGroup label={i18next.t('campaign.block.offer.item-name')}>
                    <Input {...register('item_name', {})} maxLength={MAX_NAME_LENGTH} />
                  </FieldGroup>

                  <FieldGroup label={i18next.t('campaign.block.offer.item-desc')}>
                    <Input {...register('item_description', {})} maxLength={MAX_NAME_LENGTH} />
                  </FieldGroup>

                  <FieldGroup label={i18next.t('campaign.block.offer.item-image')}>
                    <Controller
                      control={control}
                      name="item_image_url"
                      render={({ field }) => (
                        <ImageUploader
                          accept={{
                            'image/jpeg': ['.jpeg', '.jpg'],
                            'image/png': [],
                            'image/webp': [],
                          }}
                          bucket={S3Bucket.hub}
                          {...field}
                        />
                      )}
                    />
                  </FieldGroup>
                </ItemSettingsRoot>
              </CheckBoxContainer>
            </FieldSection>
          </>
        )}
      </div>
    </div>
  )
}
