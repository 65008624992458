import { AghanimIcon, IconBase } from '../IconBase';
import { forwardRef } from 'react';

export const FolderIcon: AghanimIcon = forwardRef(function ({ ...rest }, ref) {
  return (
    <IconBase {...rest} viewBox="0 0 24 24" ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.30568 18.5363C2.34204 18.629 2.38322 18.7202 2.43 18.81C2.54965 19.0461 2.69309 19.2684 2.85688 19.4743C3.58935 20.4035 4.72501 21 6 21H18C20.2091 21 22 19.2091 22 17V10.5C22 9.95564 21.8913 9.43667 21.6943 8.96364C21.658 8.87103 21.6168 8.77981 21.57 8.69C21.4504 8.45388 21.3069 8.23163 21.1432 8.02575C20.4107 7.09655 19.275 6.5 18 6.5H6.19053C6.08252 6.46843 6 6.36609 6 6.25C6 6.11 6.11 6 6.24 5.99L12.53 5.6C12.74 5.59 12.88 5.37 12.8 5.17L12.73 5.03C12.7179 5.00629 12.7059 4.98267 12.6939 4.95917C12.4685 4.51686 12.2628 4.11335 11.94 3.8C11.64 3.51 11.29 3.29 10.89 3.16C10.45 3 9.97 3 9.44 3H5.84C5.37 3 4.96 3 4.61 3.03C4.25 3.06 3.88 3.12 3.53 3.3C3 3.56 2.57 3.99 2.3 4.51C2.12 4.87 2.06 5.24 2.03 5.59C2 5.93 2 6.34 2 6.8C2 6.8 2 8.40272 2.00261 10.3543C2.00087 10.4026 2 10.4512 2 10.5V17C2 17.5444 2.10874 18.0633 2.30568 18.5363Z"
        fill="currentColor"
      />
    </IconBase>
  );
});

FolderIcon.type = 'aghanim-icon';
