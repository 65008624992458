import { useEffect } from 'react';
import { Blocker, useBlocker } from 'react-router-dom';

export const useModalBlocker = (value: boolean, callback: (blocker: Blocker) => void) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => value && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      callback(blocker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state]);
};
