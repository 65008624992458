import { HTMLAttributes, MouseEvent, ReactNode, forwardRef, useCallback, useId, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { MOTION_ANIMATION_SPEED_MD, cn, getMotionProps } from '../../libs';
import { ChevronDown, FilterLines, XClose } from '../../icons';
import { Popper, PopperMenuProps } from '../Popper';
import { SelectFilterInnerButton } from './SelectFilterInnerButton';
import { SelectFilterMultipleMenu } from './SelectFilterMultipleMenu';
import { SelectFilterGroup, SelectFilterItem, SelectFilterValue } from './types';
import { SelectFilterSingleMenu } from './SelectFilterSingleMenu';

export interface SelectFilterParams {
  /**
   * Show search input in multiple select.
   * @default true
   */
  search: boolean;
  /**
   * Size of the select filter.
   * @default 'md'
   */
  size: 'md' | 'lg';
}

export interface SelectFilterBaseProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'label' | 'title' | 'onChange'> {
  /**
   * Label for the select filter.
   */
  children: ReactNode;
  /**
   * Items for the select filter.
   */
  items: SelectFilterItem[];
  /**
   * Groups for the select filter.
   */
  groups?: SelectFilterGroup[];
  /**
   * Title of menu in multiple select.
   */
  title?: ReactNode;
  /**
   * Params for the select filter.
   * > ! Only for multiple select.
   */
  params?: Partial<SelectFilterParams>;
}

export interface SelectFilterSingleProps {
  /**
   * Multiple select filter.
   */
  multiple: false;
  /**
   * Value for the select filter.
   */
  value: SelectFilterValue;
  /**
   * Callback when the select filter value changes.
   * @param value
   */
  onChange: (value: SelectFilterValue) => void;
}

export interface SelectFilterMultipleProps {
  /**
   * Multiple select filter.
   */
  multiple: true;
  /**
   * Value for the select filter.
   */
  value: SelectFilterValue[];
  /**
   * Callback when the select filter value changes.
   * @param value
   */
  onChange: (value: SelectFilterValue[]) => void;
}

type UnionProps = SelectFilterSingleProps | SelectFilterMultipleProps;

export type SelectFilterProps = SelectFilterBaseProps & UnionProps;

const valueWrapperMotionProps = getMotionProps({
  initial: { x: -1, width: 0, opacity: 0 },
  animate: { x: 0, width: 'auto', opacity: 1 },
  exit: { x: 0, width: 0, opacity: 1 },
});

const valueInnerMotionProps = getMotionProps(
  {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  },
  { delay: MOTION_ANIMATION_SPEED_MD / 2 },
);

const defaultParams: SelectFilterParams = {
  search: true,
  size: 'md',
};

export const SelectFilter = forwardRef<HTMLDivElement, SelectFilterProps>(
  ({ children, multiple, items, groups, title, value, onChange, params: _params, ...rest }, ref) => {
    const id = useId();
    const { t } = useTranslation('ui');

    const params = useMemo(() => ({ ...defaultParams, ..._params }), [_params]);

    const initialFocus = useMemo(() => {
      if (multiple) {
        // TODO: find index in groups
        return 0;
      }

      return Math.max(
        items.findIndex(item => item.value === value),
        0,
      );
    }, [items, multiple, value]);

    const hasValue = multiple ? value.length : value;

    const renderPopper = (props: PopperMenuProps) => {
      if (multiple) {
        return (
          <SelectFilterMultipleMenu
            {...props}
            multiple={multiple}
            value={value}
            items={items}
            groups={groups}
            title={title}
            params={params}
            onChange={onChange}
          />
        );
      }

      return (
        <SelectFilterSingleMenu
          {...props}
          multiple={multiple}
          value={value}
          items={items}
          groups={groups}
          title={title}
          onChange={onChange}
        />
      );
    };

    const onClickLabel = useCallback(
      (e: MouseEvent) => {
        if (!hasValue) return;
        e.stopPropagation();
        if (multiple) {
          onChange([]);
        } else {
          onChange(null);
        }
      },
      [multiple, onChange, hasValue],
    );

    return (
      <Popper popper={renderPopper} params={{ placement: 'bottom-end', width: 'auto' }} focusManager={{ initialFocus }}>
        <div
          {...rest}
          ref={ref}
          className={cn(
            'group/select-filter inline-flex h-8 items-stretch rounded-md border border-dashed border-border-primary bg-fg-primary transition-all',
            'pointer-events-none',
            hasValue
              ? 'border-solid shadow-xs'
              : [
                  'text-text-tertiary',
                  'hover:border-border-primary-hover',
                  'hover:bg-fg-primary-hover',
                  'hover:text-text-secondary',
                ],
            rest.className,
          )}
        >
          <div className="flex h-full items-center truncate p-[2px]">
            <SelectFilterInnerButton variant={hasValue ? 'clear' : 'empty'} onClick={onClickLabel}>
              {hasValue ? <XClose className="shrink-0" size={16} /> : <FilterLines className="shrink-0" size={16} />}
              <span>{children}</span>
            </SelectFilterInnerButton>
          </div>

          <AnimatePresence initial={false}>
            {hasValue && (
              <motion.div {...valueWrapperMotionProps} key={`value-${id}-${JSON.stringify(value)}`}>
                <motion.div
                  {...valueInnerMotionProps}
                  className="flex h-full items-center truncate border-l border-border-secondary p-[2px]"
                >
                  <SelectFilterInnerButton variant="value">
                    {multiple ? (
                      <span>
                        {value.length > 1 ? (
                          <Trans
                            t={t}
                            i18nKey="select-filter.multiple.value"
                            components={{ num: <span className="tabular-nums" /> }}
                            values={{ count: value.length }}
                          />
                        ) : (
                          <>{items.find(item => item.value === value[0])?.children}</>
                        )}
                      </span>
                    ) : (
                      <span>{items.find(item => item.value === value)?.children}</span>
                    )}
                    <ChevronDown
                      className="shrink-0 transition-all group-data-[open=true]/select-filter:rotate-180"
                      strokeWidth={2.5}
                      size={16}
                    />
                  </SelectFilterInnerButton>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </Popper>
    );
  },
);
