import { InputHTMLAttributes, ReactNode, forwardRef, useId } from 'react';
import { Check } from '../../icons';
import { cn } from '../../libs';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Label for the checkbox.
   * @deprecated please use `CheckboxLabel` instead.
   */
  children?: ReactNode;
  /**
   * Additional text to display below the checkbox.
   * @deprecated please use `CheckboxLabel` instead.
   */
  caption?: ReactNode;
  /**
   * Type of the checkbox or radio.
   */
  type?: 'checkbox' | 'radio';
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { children, caption, type = 'checkbox', className, ...rest },
  ref,
) {
  const id = useId();
  return (
    <div className={cn('relative', className)}>
      <div className="relative flex gap-3">
        <input
          {...rest}
          ref={ref}
          className={cn(
            'peer/checkbox absolute left-0 top-0 size-[18px] shrink-0 cursor-pointer appearance-none',
            'disabled:pointer-events-none',
          )}
          type={type}
          id={rest.id || id}
        />
        <div
          className={cn(
            'pointer-events-none flex size-[18px] shrink-0 items-center justify-center rounded-md border border-border-primary bg-fg-primary text-transparent shadow-xs transition-colors',
            type === 'checkbox' && 'rounded',
            type === 'radio' && 'rounded-full',
            'peer-hover/checkbox:bg-fg-primary-hover',
            'peer-hover/checkbox:border-border-primary-hover',
            'group-hover/checkbox-inner:bg-fg-primary-hover',
            'group-hover/checkbox-inner:border-border-primary-hover',
            'peer-checked/checkbox:!bg-fg-brand-primary',
            'peer-checked/checkbox:!border-border-brand-solid-alt',
            'peer-checked/checkbox:!text-text-inverse',
            'peer-checked/checkbox:ring-2',
            'peer-checked/checkbox:ring-brand',
            'peer-focus-visible/checkbox:ring-2',
            'peer-focus-visible/checkbox:ring-brand',
            'peer-focus-visible/checkbox:!border-border-brand-solid-alt',
            'peer-disabled/checkbox:bg-fg-disabled',
            'peer-disabled/checkbox:border-border-disabled',
            'peer-disabled/checkbox:shadow-none',
          )}
        >
          {type === 'checkbox' && <Check size={12} strokeWidth={4} />}
          {type === 'radio' && (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none">
              <path
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill="currentColor"
              />
            </svg>
          )}
        </div>

        {children && (
          <div
            className={cn(
              'flex flex-col items-start gap-1 text-text-tertiary transition-colors',
              'peer-checked/checkbox:text-text-primary',
              'peer-disabled/checkbox:text-text-disabled',
              'peer-disabled/checkbox:pointer-events-none',
            )}
          >
            <label htmlFor={rest.id || id} className="flex cursor-pointer select-none items-center text-caption-md">
              {children}
            </label>
            {caption && <div className="text-paragraph-sm">{caption}</div>}
          </div>
        )}
      </div>
    </div>
  );
});
